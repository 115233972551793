import { format } from 'date-fns'
import { Collapse, Grid, Stack } from '@mui/material'
import styled from 'styled-components'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStore, useAppDispatch } from '../../../../redux/store'
import HolidaysEntitlementSearchForm from './HolidaysEntitlementSearchForm'
import { Search, HolidaysEntitlementCSV, Props } from './types'
import HolidaysEntitlementGrid from './HolidaysEntitlementGrid'
import LoadingIndicator from '../../../../shared/UI/LoadingIndicator'
import { BaseResponse } from '../../../../types/base-response'
import { showErrorMessage } from '../../../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../../../utils/errorFilter'
import { DrawerComponentProps } from '../../types'
import Drawer from '../../../../shared/UI/Drawer'
import { ssrsHolidayReportService } from '../../../../services/ssrsHolidayReportService'
import {
  HolidayEntitlementItem,
  HolidayEntitlementResponse,
} from '../../../../types/holiday-entitlement-response'
import HolidaysEntitlementDetail from './HolidaysEntitlementDetail'
import RefreshButton from '../../../../shared/UI/RefreshButton'
import DownloadButton from '../../../../shared/UI/DownloadButton'
import FilterButton from '../../../../shared/UI/FilterButton'
import { ddMMyyyy } from '../../../../utils/date-utils'

export const LoadingIndicatorContainer = styled.div`
  padding-top: 70px;
  padding-bottom: 70px;
`

const headers: string[] = [
  'EmployeeName',
  'Department',
  'JobTitle',
  'Team',
  'EmploymentStartDate',
  'EmploymentEndDate',
  'HasBookedBirthday',
  'DefaultShiftLength',
  'HolidayEntitlement',
  'DefaultAllowance',
  'ServiceDays',
  'FireMarshall',
  'FirstAider',
  'Adjustment',
  'HolidaysBought',
  'HolidaysSold',
  'HolidayTaken',
  'HolidayRemaining',
  'HolidayTakenPc',
]

function HolidaysEntitlementSearch({ userDepartments }: Props) {
  const dispatch = useAppDispatch()
  const [resetForm, setResetForm] = useState(false)
  const [showHeaderButtons, setShowHeaderButtons] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(true)
  const [showGrid, setShowGrid] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<HolidayEntitlementItem[]>([])
  const [openDrawer, setOpenDrawer] = useState(false)

  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)

  const handleClose = useCallback(() => {
    setOpenDrawer(false)
  }, [])

  const [drawerComponent, setDrawerComponent] = useState<DrawerComponentProps>({
    title: '',
    component: <HolidaysEntitlementDetail row={null} onClose={handleClose} />,
  })

  const handleSearch = useCallback(
    (search: Search) => {
      setIsLoading(true)
      setShowGrid(false)

      setTimeout(() => {
        setShowSearch(false)
      }, 500)

      ssrsHolidayReportService
        .getHolidaysEntitlementReports(
          search.departmentIds!,
          search.fromDateRangeFrom !== ''
            ? search.fromDateRangeFrom!
            : format(new Date(currentEntitlementPeriodResponse!.startDate), 'yyyy-MM-dd'),
          search.fromDateRangeTo !== ''
            ? search.fromDateRangeTo!
            : format(new Date(currentEntitlementPeriodResponse!.endDate), 'yyyy-MM-dd'),
          search.remainingEntitlement!
        )
        .then((res: HolidayEntitlementResponse) => {
          const result: HolidayEntitlementItem[] = res.entitlementReport.map(
            (row: HolidayEntitlementItem) => {
              const holidayRemaining: number = row.holidayEntitlement! - row.holidayTaken!
              let holidayTakenPc = 0
              if (row.holidayEntitlement !== 0) {
                holidayTakenPc = (row.holidayTaken! / row.holidayEntitlement!) * 100
              }

              return { ...row, holidayRemaining, holidayTakenPc: holidayTakenPc!.toFixed(2) }
            }
          )

          setData(
            result.sort((a, b) =>
              a.displayName!.toLowerCase() > b.displayName!.toLowerCase() ? 1 : -1
            )
          )
          setShowHeaderButtons(true)
          setShowGrid(true)
          setIsLoading(false)
        })
        .catch(err => {
          setShowHeaderButtons(true)
          setShowGrid(false)
          setIsLoading(false)
          setTimeout(() => {
            setShowSearch(true)
          }, 500)

          const response: BaseResponse = err.response.data
          if (response.errors) {
            response.errors.forEach(error => {
              dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
            })
          } else {
            dispatch(showErrorMessage(<UserErrorMessage name="CurrentlyUnavailable" />))
          }
        })
    },
    [currentEntitlementPeriodResponse, dispatch]
  )

  const handleFilterClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setShowSearch(!showSearch)
    },
    [showSearch]
  )

  const handleRefreshClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setResetForm(!resetForm)
      setShowSearch(true)
      setShowHeaderButtons(false)
      setShowGrid(false)
    },
    [resetForm]
  )

  const handleViewDetail = useCallback(
    (row: HolidayEntitlementItem) => {
      setDrawerComponent({
        ...drawerComponent,
        title: `${row.displayName}`,
        component: <HolidaysEntitlementDetail row={row} onClose={handleClose} />,
      })

      setOpenDrawer(true)
    },
    [drawerComponent, handleClose]
  )

  const makeCsv = useCallback(
    async (filename: string) => {
      const csvData: HolidaysEntitlementCSV[] = data.map((row: HolidayEntitlementItem) => {
        const item: HolidaysEntitlementCSV = {
          EmployeeName: row.displayName!,
          Department: row.department,
          JobTitle: row.position,
          Team: row.team,
          EmploymentStartDate: format(new Date(row.employmentStartDate), ddMMyyyy),
          EmploymentEndDate: row.employmentEndDate
            ? format(new Date(row.employmentEndDate), ddMMyyyy)
            : '',
          HasBookedBirthday: row.hasBookedBirthday,
          DefaultShiftLength: row.defaultShiftLength,
          HolidayEntitlement: row.holidayEntitlement,
          DefaultAllowance: row.defaultAllowance,
          ServiceDays: row.serviceDays,
          FireMarshall: row.fireMarshall,
          FirstAider: row.firstAider,
          Adjustment: row.adjustment,
          HolidaysBought: row.holidaysBought,
          HolidaysSold: row.holidaysSold,
          HolidayTaken: row.holidayTaken,
          HolidayRemaining: row.holidayRemaining,
          HolidayTakenPc: row.holidayTakenPc,
        }
        return item
      })

      const keys: string[] = Object.keys(csvData[0])

      const csvContent: string = csvData
        .map((row: any) =>
          keys
            .map(k => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k]

              cell =
                cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""')

              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`
              }
              return cell
            })
            .join(',')
        )
        .join('\n')

      const csvHeaders: string = headers.join(',')

      const blob = new Blob([`${csvHeaders}\n`, csvContent], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')

      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    [data]
  )

  const handleDownloadClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      makeCsv('HolidaysEntitlement.csv')
    },
    [makeCsv]
  )

  return (
    <>
      {showHeaderButtons && (
        <Grid container style={{ border: '0px red solid' }}>
          <Stack
            direction="row"
            style={{ border: '0px green solid', width: '100%' }}
            spacing={2}
            display="flex"
            justifyContent="flex-end"
          >
            <FilterButton
              onClick={handleFilterClick}
              title="Change Filters"
              dataTestId="ReportingHoliday-FilterBtn"
            />
            {data && data.length >= 1 && (
              <DownloadButton
                onClick={handleDownloadClick}
                title="Download Report"
                dataTestId="ReportingHoliday-DownloadBtn"
              />
            )}
            <RefreshButton
              onClick={handleRefreshClick}
              title="Refresh"
              dataTestId="ReportingHoliday-RefreshBtn"
            />
          </Stack>
        </Grid>
      )}
      <Collapse orientation="vertical" in={showSearch}>
        <HolidaysEntitlementSearchForm
          resetForm={resetForm}
          userDepartments={userDepartments}
          onSearch={handleSearch}
        />
      </Collapse>
      <Grid item xs={12}>
        {!isLoading && showGrid && (
          <HolidaysEntitlementGrid data={data} onViewDetail={handleViewDetail} />
        )}
        {isLoading && (
          <LoadingIndicatorContainer>
            <LoadingIndicator show={isLoading} />
          </LoadingIndicatorContainer>
        )}
      </Grid>
      <Drawer
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        title={drawerComponent?.title || ''}
        showOptions={false}
      >
        {drawerComponent?.component}
      </Drawer>
    </>
  )
}

export default HolidaysEntitlementSearch
