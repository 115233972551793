import { useSelector } from 'react-redux'
import { LocationGroups } from '../../../types/location-groups'
import AbsenceForm from './absenceForm'
import { AbsenceFormFactoryProps } from './types'
import UKAbsenceForm from './ukAbsenceForm'
import { RootStore } from '../../../redux/store'

function AbsenceFormFactory({
  locationGroupName,
  absenceFormProps,
  locationAbsenceTypesList,
}: AbsenceFormFactoryProps) {
  const { territoryAttendanceUK } = useSelector((state: RootStore) => state.featureToggles)

  let form = AbsenceForm(absenceFormProps)
  if (territoryAttendanceUK) {
    switch (locationGroupName) {
      case LocationGroups.UK:
        form = UKAbsenceForm({ ...absenceFormProps, absenceTypesList: locationAbsenceTypesList })
        break
      default:
        break
    }
  }
  return form
}

export default AbsenceFormFactory
