import { Grid, TextField } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'
import Button from '../../shared/UI/Button'
import DrawerFooter from '../../shared/UI/DrawerFooter'
import DatePicker from '../../shared/UI/DatePicker'
import CheckBox from '../../shared/UI/CheckBox'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { settingsService } from '../../services/settingsService'
import { requestFieldsModelRestrictions, requestFieldsStateRestrictions } from '../../models'
import theme from '../../theme/theme'
import Paragraph from '../../shared/UI/Paragraph'
import UserErrorMessage from '../../utils/errorFilter'
import { BaseResponse } from '../../types/base-response'

type Props = {
  onClose: () => void
  refresh: () => void
  deptTeamId: number
}

function AddRestriction({ onClose, refresh, deptTeamId }: Props) {
  const [comments, setComments] = useState<string>('')
  const [holiday, setHoliday] = useState<boolean>(false)
  const [dayOff, setDayOff] = useState<boolean>(false)
  const [shift, setShift] = useState<boolean>(false)
  const [lieuDay, setLieuDay] = useState<boolean>(false)
  const [wfh, setWfh] = useState<boolean>(false)
  const [fromDate, setFromDate] = useState<Date | null>(null)
  const [toDate, setToDate] = useState<Date | null>(null)
  const [fieldsTouched, setFieldsTouched] = useState<requestFieldsModelRestrictions>(
    requestFieldsStateRestrictions
  )
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const dispatch = useDispatch()

  const handleFromDateChange = (selectedDate: Date | null) => {
    setFromDate(selectedDate)
    setToDate(selectedDate)
  }

  const handleToDateChange = (selectedDate: Date | null) => {
    if (selectedDate !== null && fromDate !== null) {
      if (selectedDate.getTime() >= fromDate.getTime()) {
        setToDate(selectedDate)
      } else {
        dispatch(showErrorMessage('Date From must be earlier or equal to Date To'))
        setToDate(null)
      }
    }
  }

  const anyChecksTicked = useCallback(
    (): boolean => shift || dayOff || holiday || lieuDay || wfh,
    [dayOff, holiday, lieuDay, shift, wfh]
  )
  const anyChecksTouched = useCallback(
    (): boolean =>
      fieldsTouched.shift ||
      fieldsTouched.dayOff ||
      fieldsTouched.holiday ||
      fieldsTouched.lieuDay ||
      fieldsTouched.wfh,
    [fieldsTouched]
  )

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (fromDate !== null && toDate !== null && anyChecksTicked()) {
      setSubmitLoading(true)
      settingsService
        .postRestriction({
          departmentTeamId: deptTeamId,
          dateFrom: format(new Date(fromDate), `yyyy-MM-dd`),
          dateTo: format(new Date(toDate), `yyyy-MM-dd`),
          restrictShift: shift,
          restrictDayOff: dayOff,
          restrictHoliday: holiday,
          restrictLieuDay: lieuDay,
          restrictWFH: wfh,
          comment: comments,
        })
        .then(e => {
          setSubmitLoading(false)
          dispatch(showSuccessMessage('Restriction added.'))
          onClose()
          refresh()
        })
        .catch(err => {
          setSubmitLoading(false)
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    }
  }

  const isFormValid = useCallback(() => {
    setFieldsTouched({
      ...fieldsTouched,
      date: true,
      comments: true,
      holiday: true,
      shift: true,
      dayOff: true,
      wfh: true,
      lieuDay: true,
    })

    const checks = [
      {
        test: !fromDate,
        output: 'noFromDate',
      },
      {
        test: !toDate,
        output: 'noToDate',
      },
      {
        test: !comments,
        output: 'noComments',
      },
      {
        test: !anyChecksTicked(),
        output: 'noChecksTicked',
      },
      {
        test: true,
        output: 'valid',
      },
    ]

    for (let i = 0; i < checks.length; i += 1) {
      if (checks[i].test) {
        return checks[i].output
      }
    }
  }, [anyChecksTicked, comments, fieldsTouched, fromDate, toDate])

  return (
    <Grid
      component="form"
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const formStatus = isFormValid()

        if (formStatus === 'valid') {
          handleSubmit(event)
          return
        }

        if (formStatus === 'noFromDate') {
          dispatch(showErrorMessage('Please enter a From date'))
        }
        if (formStatus === 'noToDate') {
          dispatch(showErrorMessage('Please enter a To date'))
        }
        if (formStatus === 'noComments') {
          dispatch(showErrorMessage('Please enter your comments'))
        }
        if (formStatus === 'noChecksTicked') {
          dispatch(showErrorMessage('Please tick at least one type'))
        }
      }}
    >
      <Grid container columnSpacing={4} rowSpacing={2}>
        <Grid item xs={12} pb={2}>
          <Paragraph weight="bold">Details</Paragraph>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <DatePicker
                label="Date from"
                value={fromDate}
                onChange={e => {
                  setFieldsTouched({ ...fieldsTouched, date: true })
                  handleFromDateChange(e)
                }}
                helperText="Required"
                error={fieldsTouched.date && fromDate === null}
                disablePast
                dataTestId="Settings-Restrictions-DateFrom"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <DatePicker
                label="Date to"
                value={toDate}
                onChange={e => {
                  setFieldsTouched({ ...fieldsTouched, date: true })
                  handleToDateChange(e)
                }}
                helperText="Required"
                error={fieldsTouched.date && toDate === null}
                disablePast
                dataTestId="Settings-Restrictions-DateTo"
              />
            </Grid>
            <Grid item xs={12} pb={2}>
              <TextField
                fullWidth
                label="Comments"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldsTouched({ ...fieldsTouched, comments: true })
                  setComments(e.target.value)
                }}
                helperText="Required"
                rows={5}
                multiline
                error={fieldsTouched.comments && comments === ''}
                data-testid="Settings-Restrictions-Comments"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <CheckBox
                label="Holiday"
                onChange={e => {
                  setHoliday(e.target.checked)
                  setFieldsTouched({ ...fieldsTouched, holiday: true })
                }}
                color={anyChecksTouched() && !anyChecksTicked() ? theme.palette.error.main : ''}
                dataTestId="Settings-Restrictions-HolidayBtn"
              />
            </Grid>
            <Grid item xs={12}>
              <CheckBox
                label="Day Off"
                onChange={e => {
                  setDayOff(e.target.checked)
                  setFieldsTouched({ ...fieldsTouched, dayOff: true })
                }}
                color={anyChecksTouched() && !anyChecksTicked() ? theme.palette.error.main : ''}
                dataTestId="Settings-Restrictions-DayOffBtn"
              />
            </Grid>
            <Grid item xs={12}>
              <CheckBox
                label="Shift"
                onChange={e => {
                  setShift(e.target.checked)
                  setFieldsTouched({ ...fieldsTouched, shift: true })
                }}
                color={anyChecksTouched() && !anyChecksTicked() ? theme.palette.error.main : ''}
                dataTestId="Settings-Restrictions-ShiftBtn"
              />
            </Grid>
            <Grid item xs={12}>
              <CheckBox
                label="Lieu Day"
                onChange={e => {
                  setLieuDay(e.target.checked)
                  setFieldsTouched({ ...fieldsTouched, lieuDay: true })
                }}
                color={anyChecksTouched() && !anyChecksTicked() ? theme.palette.error.main : ''}
                dataTestId="Settings-Restrictions-LieuDayBtn"
              />
            </Grid>
            <Grid item xs={12}>
              <CheckBox
                label="Work From Home"
                onChange={e => {
                  setWfh(e.target.checked)
                  setFieldsTouched({ ...fieldsTouched, wfh: true })
                }}
                color={anyChecksTouched() && !anyChecksTicked() ? theme.palette.error.main : ''}
                dataTestId="Settings-Restrictions-WorkFromHomeBtn"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DrawerFooter>
        <Button
          color="secondary"
          label="Cancel"
          onClick={onClose}
          dataTestId="Settings-Restrictions-CancelBtn"
        />
        <Button
          label="Submit"
          type="submit"
          loading={submitLoading}
          dataTestId="Settings-Restrictions-SubmitBtn"
        />
      </DrawerFooter>
    </Grid>
  )
}

export default AddRestriction
