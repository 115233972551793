import { Button, CircularProgress, useMediaQuery } from '@mui/material'
import { isMobile } from 'react-device-detect'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { useState, useRef, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootStore } from '../../../redux/store'
import { EmployeeDetailsResponse } from '../../../types/employee'
import NotificationContent from './notificationContent'
import { notificationService } from '../../../services/notificationService'
import { showErrorMessage } from '../../../redux/reducers/snackbarReducer'
import {
  AnimatedBadge,
  AnimatedBadgeContainer,
  AnimatedBox,
  AnimatedIconButton,
  StyledPopover,
} from './components'
import UserErrorMessage from '../../../utils/errorFilter'
import { BaseResponse } from '../../../types/base-response'
import { isMobileDown } from '../../../theme/deviceChecks'
import { NotificationResponse } from './type'

function Notifications() {
  const dispatch = useDispatch()

  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse>(
    (state: RootStore) => state.appSettings.employeeDetails
  )

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [hasNewNotification, setHasNewNotification] = useState<boolean>(false)
  const [hasNotification, setHasNotification] = useState<boolean>(false)
  const [lastNotificationId, setLastNotificationId] = useState<number>(0)
  const [unreadLength, setUnreadLength] = useState<number>(0)
  const [prevUnreadLength, setPrevUnreadLength] = useState<number>(0)

  const refButton = useRef<HTMLSpanElement>(null)
  const refSpinner = useRef<HTMLSpanElement>(null)
  const mobile = useMediaQuery(isMobileDown())

  const open = Boolean(anchorEl)

  const checkHasNotification = useCallback(() => {
    notificationService
      .getNotifications()
      .then((res: NotificationResponse) => {
        if (res.notifications.length === 0) {
          setHasNotification(false)
          return
        }

        const currentNotificationId = res.notifications[0].id

        setLastNotificationId(currentNotificationId)

        setUnreadLength(res.notifications.length)

        if (unreadLength > prevUnreadLength) {
          setHasNewNotification(true)
        }

        setPrevUnreadLength(unreadLength)

        if (unreadLength > 0) {
          setHasNotification(true)
        } else {
          setHasNotification(false)
        }
      })
      .catch(err => {
        const response: BaseResponse = err.response?.data
        response?.errors?.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [dispatch, prevUnreadLength, unreadLength, hasNotification, hasNewNotification])

  const checkHasNewNotification = useCallback(() => {
    if (lastNotificationId === 0) {
      return
    }

    notificationService
      .getNewNotifications(lastNotificationId)
      .then((res: NotificationResponse) => {
        if (res.notifications.length > 0) {
          setHasNewNotification(true)
          setLastNotificationId(res.notifications[0].id)
        } else {
          setHasNotification(false)
        }
      })
      .catch(err => {
        const response: BaseResponse = err.response?.data
        response?.errors?.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [dispatch, lastNotificationId, hasNewNotification, hasNotification])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    checkHasNotification()
  }
  // prettier-ignore
  const handleLoad = (count: number) => {
    if (count > 0) {
      (refButton.current! as HTMLSpanElement).style!.display = 'inline'
    }
  }
  // prettier-ignore
  const handleClearAll = () => {
    (refButton.current! as HTMLSpanElement).style!.display = 'none';
    (refSpinner.current! as HTMLSpanElement).style!.display = 'inline'

    notificationService
      .clearAllNotifications(employeeDetails.employeeId, employeeDetails.employeeId)
      .then(res => {
        setAnchorEl(null)
        setUnreadLength(0)
        checkHasNotification()
      })
      .catch(err => {
        const response: BaseResponse = err.response?.data
        response?.errors?.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  // Poll notification service for notifications every 30 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!open) {
        if (lastNotificationId === 0) {
          checkHasNotification()
        } else {
          checkHasNewNotification()
        }
      }
    }, 30000)

    if (!open && lastNotificationId === 0) {
      checkHasNotification()
    }
    notificationService
      .getNotifications()
      .then((res: NotificationResponse) => {
        setUnreadLength(res.notifications.length)
      })
      .catch(err => {
        const response: BaseResponse = err.response?.data
        response?.errors?.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })

    return () => clearInterval(intervalId)
  }, [checkHasNewNotification, checkHasNotification, lastNotificationId, open])

  // remove animation class
  useEffect(() => {
    if (hasNewNotification) {
      const int = setTimeout(() => {
        setHasNewNotification(false)
      }, 1000)
      return () => clearTimeout(int)
    }
  }, [hasNewNotification])

  return (
    <>
      <AnimatedIconButton
        id="notifications-button"
        aria-controls={open ? 'user' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        data-testid="Dashboard-notificationsbtn"
      >
        {hasNotification || unreadLength ? (
          <AnimatedBox className={hasNewNotification ? 'hasNotification' : ''}>
            <AnimatedBadgeContainer className={hasNewNotification ? 'hasNotification' : ''}>
              <AnimatedBadge />
            </AnimatedBadgeContainer>
            <NotificationsNoneIcon
              fontSize="large"
              color={isMobile || mobile ? 'action' : 'primary'}
            />
          </AnimatedBox>
        ) : (
          <NotificationsNoneIcon
            fontSize="large"
            color={isMobile || mobile ? 'action' : 'primary'}
          />
        )}
      </AnimatedIconButton>
      <StyledPopover
        id="user"
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <div className="container">
          <div className="header">
            Notifications
            <span ref={refSpinner} className="spinner" style={{ display: 'none' }}>
              <CircularProgress color="inherit" />
            </span>
            <span ref={refButton} className="button" style={{ display: 'none' }}>
              <Button color="inherit" variant="text" onClick={handleClearAll}>
                Clear All
              </Button>
            </span>
          </div>
          <div className="items">
            <NotificationContent onClose={handleClose} onLoad={handleLoad} />
          </div>
        </div>
      </StyledPopover>
    </>
  )
}

export default Notifications
