import { Grid, Stack } from '@mui/material'
import Button from '../../../shared/UI/Button'
import Paragraph from '../../../shared/UI/Paragraph'
import { AbsenceDay } from '../../../types/absence-day'

type Props = {
  row: AbsenceDay
  onClose: () => void
  manager: string
  department: string | undefined
  position: string
}

function ViewOccurrence({ row, onClose, manager, department, position }: Props) {
  return (
    <Grid container xl={12} rowSpacing={4} columnSpacing={4} pb={4}>
      {/* Left Column */}
      <Grid item xs={12} lg={6} display="flex" pt={1}>
        <Grid container xl={12} alignContent="flex-start">
          <Grid item xs={12} mb={1}>
            <Paragraph weight="bold">Absence Date</Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Paragraph>{row && new Date(row.date).toLocaleDateString()}</Paragraph>
          </Grid>
          <Grid item xs={12} mt={4} mb={1}>
            <Paragraph weight="bold">Reason</Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Paragraph>{row && row.absenceType.decryptedReason}</Paragraph>
          </Grid>
          <Grid item xs={12} mt={4} mb={1}>
            <Paragraph weight="bold">Line Manager</Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Paragraph>{manager}</Paragraph>
          </Grid>
          <Grid item xs={12} display="flex" mt={4} mb={1}>
            <Paragraph weight="bold">Contacted By</Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Paragraph>{row && row.createdBy}</Paragraph>
          </Grid>
          <Grid item xs={12} display="flex" mt={4} mb={1}>
            <Paragraph weight="bold">Contacted Date/Time</Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Paragraph>{row && `${new Date(row.contactDateTime).toLocaleString()}`}</Paragraph>
          </Grid>
        </Grid>
      </Grid>
      {/* Right Column */}
      <Grid item xs={12} lg={6} pt={1}>
        <Grid container xl={12} alignContent="flex-start">
          <Grid item xs={12} mb={1}>
            <Paragraph weight="bold">Hours Absent</Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Paragraph>{row && row.hoursLost}</Paragraph>
          </Grid>
          <Grid item xs={12} mt={4} mb={1}>
            <Paragraph weight="bold">Expected Shift</Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Paragraph>{row && row.expectedShift.shiftString}</Paragraph>
          </Grid>
          <Grid item xs={12} mt={4} mb={1}>
            <Paragraph weight="bold">Department</Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Paragraph>{department}</Paragraph>
          </Grid>
          <Grid item xs={12} mt={4} mb={1}>
            <Paragraph weight="bold">Position</Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Paragraph>{position}</Paragraph>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Stack direction="row" gap={4} justifyContent="flex-end">
          <Button label="Close" color="secondary" variant="outlined" onClick={onClose} />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default ViewOccurrence
