import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { availabilityService } from '../../services/availabilityService'
import { MyEntitlementResponse } from '../../types/my-entitlement-response'
import { RootStore } from '../../redux/store'
import { BaseResponse } from '../../types/base-response'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../utils/errorFilter'

export const useEntitlement = () => {
  const dispatch = useDispatch()
  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)
  const [myEntitlement, setMyEntitlement] = useState<MyEntitlementResponse>()
  const [isEntitlementLoading, setIsEntitlementLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!currentEntitlementPeriodResponse) {
      return
    }

    availabilityService
      .getEntitlement(currentEntitlementPeriodResponse.year)
      .then(response => {
        setMyEntitlement(response)
        setIsEntitlementLoading(false)
      })
      .catch(err => {
        setIsEntitlementLoading(false)
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [currentEntitlementPeriodResponse, currentEntitlementPeriodResponse?.year, dispatch])

  return {
    myEntitlement,
    isEntitlementLoading,
  }
}
