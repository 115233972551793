import { REQUIRED_MESSAGE } from '../../utils/constants'
import { Validations } from '../../utils/useForm'

export interface LateRequestModel {
  employeeId: number | null
  lateDate: Date | null | string
  shiftDescription: string | null
  lateMinutes: string | null
  lateTypeId: number | null
  contactByType: string | null
  contactByTypeId: number | null
  toWorkBack: boolean
  contactComments: string | null
  comments: string | null
  contactDateTime: Date | null | string
}

export const lateRequestModelDefaultState = (): LateRequestModel => ({
  employeeId: null,
  lateDate: null,
  shiftDescription: '9am - 5:30pm',
  lateMinutes: null,
  lateTypeId: null,
  contactByType: 'NoContactMade',
  contactByTypeId: 0,
  toWorkBack: false,
  contactComments: '',
  comments: '',
  contactDateTime: new Date(),
})

export const LateComponentValidationSchema: Validations<LateRequestModel> = {
  employeeId: { required: { value: true, message: REQUIRED_MESSAGE } },
  lateDate: { required: { value: true, message: REQUIRED_MESSAGE } },
  shiftDescription: { required: { value: true, message: REQUIRED_MESSAGE } },
  lateMinutes: {
    required: { value: true, message: REQUIRED_MESSAGE },
    custom: { isValid: value => Number(value) > 0, message: REQUIRED_MESSAGE },
  },
  lateTypeId: { required: { value: true, message: REQUIRED_MESSAGE } },
  contactByTypeId: { custom: { isValid: value => Number(value) >= 0, message: REQUIRED_MESSAGE } },
}
