import * as React from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, PickersDay, PickersDayProps } from '@mui/x-date-pickers-pro'
import enLocale from 'date-fns/locale/en-GB'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Grid } from '@mui/material'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import {
  CustomPickersDay,
  CustomCalendarPicker,
  CalendarLabel,
  StandardPickersDay,
  CalendarBox,
} from './components'
import { PlottingCalendarProps, PlottingProps } from './types'
import { MONTHS } from '../../utils/constants'
import { RootStore } from '../../redux/store'
import { EventPopup } from '../../shared/UI/EventPopup'
import { PopupFields, popupFieldsDefault } from '../../shared/UI/EventPopup/types'
import { statusTranslation, typeTranslation } from '../../shared/UI/EventPopup/utils'
import { DrawerComponentProps } from '../MyRequests/MyRequestsGrid/types'
import ViewEditOccurrence from '../MyRequests/ViewEditOccurrence'
import Drawer from '../../shared/UI/Drawer'
import { PageContext } from '../../context/MyRequestsPageContext'
import { availabilityService } from '../../services/availabilityService'
import { WallChartItemProps } from '../WallChart/types'

const weekendDays = [0, 6]

export function PlottingCalendar({
  calendarDate = new Date(),
  dates = [],
  disableDates = [],
  onClickEvent,
  onDayClick,
  isComingUp,
  newRequestData,
}: PlottingCalendarProps) {
  const [value, setValue] = useState<Date>(calendarDate)
  const [popupProps, setPopupProps] = useState<PopupFields[]>([popupFieldsDefault])
  const [drawerComponent, setDrawerComponent] = useState<DrawerComponentProps | null>(null)
  const [showViewEditDrawer, setShowViewEditDrawer] = useState<boolean>(false)
  const [currentRequestId, setCurrentRequestId] = useState<number>()
  const [closeDrawerLoading, setCloseDrawerLoading] = useState<boolean>(false)

  useEffect(() => {
    setValue(calendarDate)
  }, [calendarDate])

  const { getRequestsData } = useContext(PageContext)

  const closePopup = useCallback(() => {
    setPopupProps([popupFieldsDefault])
  }, [])

  const { filtersOff } = useSelector((state: RootStore) => state.myRequests)

  const { bankHolidays } = useSelector((state: RootStore) => state.appSettings)

  const bankHolidayStyle = useMemo(() => {
    const style = {
      backgroundColor: 'unset',
      color: '#707070',
      '&:hover': {
        fontWeight: 'normal',
      },
      '&:before': {
        content: "''",
        fontSize: 0,
        position: 'absolute',
        height: '80%',
        width: '80%',
        left: '10%',
        top: '10%',
        backgroundColor: 'unset',
        border: '1px solid rgb(112, 112, 112)',
        boxSizing: 'border-box',
        borderRadius: '100%',
      },
    }
    return style
  }, [])

  const weekendStyle = useMemo(() => {
    const style = {
      backgroundColor: 'unset',
    }
    return style
  }, [])

  const getStyle = useCallback(
    (isWeekend: boolean, isBankHoliday: boolean) => {
      if (isWeekend) {
        return weekendStyle
      }
      if (isBankHoliday) {
        return bankHolidayStyle
      }
      return {
        backgroundColor: isComingUp ? '#f8fdfe' : 'white',
      }
    },
    [bankHolidayStyle, isComingUp, weekendStyle]
  )

  const openDrawer = (id: number, absenceType: string, eventStatus: string | undefined) => {
    setCurrentRequestId(id)
    setShowViewEditDrawer(true)
    const isCancellationExists = newRequestData?.some(res => res.id === id && res.isCancellation)

    setDrawerComponent({
      title: absenceType,
      component: (
        <ViewEditOccurrence
          id={id}
          requestType={absenceType}
          closeViewEditDrawer={async () => {
            setCloseDrawerLoading(true)
            await availabilityService.deleteRequestLock(id)
            setCloseDrawerLoading(false)
            setShowViewEditDrawer(false)
          }}
          isCancellation={isCancellationExists}
          closeLoading={closeDrawerLoading}
        />
      ),
      status: isCancellationExists ? 'Cancellation Requested' : eventStatus,
    })
  }

  const absoluteDate = useCallback((d: Date): string => {
    if (!d.setHours) {
      return format(new Date(new Date(d).setHours(1)), 'yyyy-MM-dd')
    }
    return format(new Date(d.setHours(1)), 'yyyy-MM-dd')
  }, [])

  const disabled = useCallback(
    (date: Date) => {
      if (!disableDates.length) {
        return false
      }
      return disableDates.some(dt => absoluteDate(dt) === absoluteDate(date))
    },
    [absoluteDate, disableDates]
  )

  const renderWeekPickerDay = useCallback(
    (date: Date, selectedDates: Array<any | null>, pickersDayProps: PickersDayProps<any>) => {
      const dayisWeekend = weekendDays.some(d => d === date.getDay())
      const dayIsBankHoliday = bankHolidays.some(
        d => new Date(d.holidayDate!).toISOString() === new Date(date).toISOString()
      )
      if (!value) {
        return (
          <PickersDay
            {...pickersDayProps}
            disabled={disabled(date)}
            sx={dayisWeekend ? weekendStyle : {}}
            onMouseLeave={closePopup}
            disableRipple
          />
        )
      }

      const absDefaultDate = '0001-01-01'
      const absDate = absoluteDate(date)

      const dateSet = dates.find(d => {
        const endDate = absoluteDate(d.end)
        const startDate = absoluteDate(d.start)
        return absDefaultDate === endDate
          ? startDate === absDate
          : startDate <= absDate && endDate >= absDate
      })

      function standardDay() {
        return (
          <StandardPickersDay
            {...pickersDayProps}
            disabled={disabled(date)}
            onClick={() => onDayClick?.(new Date(absDate))}
            selected={false}
            sx={getStyle(dayisWeekend, dayIsBankHoliday)}
            onMouseLeave={closePopup}
            disableRipple
          />
        )
      }

      if (!dateSet || filtersOff.includes(dateSet.eventType)) {
        return standardDay()
      }

      const dayIsBetween = // day is between dateSet.start && dateSet.end
        (absDate >= absoluteDate(dateSet.start) && absDate <= absoluteDate(dateSet.end)) ||
        absoluteDate(dateSet.end) === absDefaultDate

      function isDeselectedDay(testDate: string) {
        return Boolean(
          dayIsBetween &&
            dateSet?.days?.length &&
            !dateSet.days.some(d => testDate === absoluteDate(d.date))
        )
      }

      // if day is between and not in days array
      const deselectedDay = isDeselectedDay(absDate)

      if (deselectedDay) {
        return standardDay()
      }

      const calculateStatusLabel = (found: PlottingProps) => {
        if (!found.status) return

        if (found.isQueried) {
          return 'Queried'
        }

        if (found.status.toLowerCase() === 'change requested' && found.isCancellation) {
          return 'Cancellation Requested'
        }

        return statusTranslation(found.status)
      }

      const isFirstDay = absDate === absoluteDate(dateSet.start)
      const isLastDay =
        absDate === absoluteDate(dateSet.end) || absoluteDate(dateSet.end) === absDefaultDate

      const getPopupProps = (e: React.MouseEvent<HTMLButtonElement>, clickedDate: string) => {
        const coincidingDates = dateSet.coincidingDates?.filter(
          s => format(s.date, 'yyyy-MM-dd') === clickedDate
        )

        const events: PopupFields[] = [
          {
            open: true,
            anchorEl: e.currentTarget,
            id: dateSet.requestId || 0,
            eventType: typeTranslation(dateSet.eventType),
            hours: dateSet.hours || 0,
            eventStatus: dateSet.isCancellation
              ? 'Cancellation Requested'
              : calculateStatusLabel(dateSet),
          },
        ]
        coincidingDates?.forEach(fe => {
          const found = dates.find(f => f.requestId === fe.requestId)
          if (found) {
            events.push({
              open: true,
              anchorEl: e.currentTarget,
              id: found.requestId || 0,
              eventType: typeTranslation(found.eventType),
              hours: found.hours || 0,
              eventStatus: dateSet.isCancellation
                ? 'Cancellation Requested'
                : calculateStatusLabel(found),
            })
          }
        })
        return events
      }

      return (
        <CustomPickersDay
          {...pickersDayProps}
          disableMargin
          dayIsBetween={dayIsBetween}
          isFirstDay={isFirstDay}
          isLastDay={isLastDay}
          hasCoincidingDates={dateSet?.coincidingDates?.some(
            s => format(s.date, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
          )}
          className={dateSet.pending ? 'selectedDate_pending' : ''}
          onClick={e => {
            setPopupProps(getPopupProps(e, format(date, 'yyyy-MM-dd')))
          }}
          eventType={dateSet.eventType}
          disableRipple
          disableHighlightToday
          sx={dayisWeekend ? weekendStyle : {}}
          isComingUp={Boolean(isComingUp)}
        />
      )
    },
    [
      absoluteDate,
      bankHolidays,
      closePopup,
      dates,
      disabled,
      filtersOff,
      getStyle,
      onDayClick,
      value,
    ]
  )

  return (
    <>
      <CalendarLabel>
        {isComingUp && <CalendarTodayIcon />}
        {MONTHS[calendarDate.getMonth()]}
        {isComingUp && `, ${calendarDate.getFullYear()}`}
      </CalendarLabel>
      <CalendarBox onMouseLeave={closePopup}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
          <CustomCalendarPicker
            date={value}
            onChange={() => undefined}
            renderDay={renderWeekPickerDay}
            readOnly
          />
        </LocalizationProvider>
        <EventPopup
          fields={popupProps}
          baseUrl="myavailability"
          newRequestData={newRequestData}
          onClose={closePopup}
          onDrawerOpen={(id, absenceType, status) => openDrawer(id, absenceType, status)}
        />
      </CalendarBox>
      <Drawer
        isOpen={showViewEditDrawer}
        onClose={async () => {
          await availabilityService.deleteRequestLock(currentRequestId!)
          setCurrentRequestId(undefined)
          getRequestsData()
          setShowViewEditDrawer(false)
        }}
        title={drawerComponent?.title || ''}
        status={drawerComponent?.status || ''}
        showOptions={false}
        mobileHeight={
          drawerComponent?.title === 'Day Off' || drawerComponent?.title === 'Shift'
            ? '620px'
            : undefined
        }
      >
        <Grid item p={4}>
          {drawerComponent?.component}
        </Grid>
      </Drawer>
    </>
  )
}
