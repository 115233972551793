import theme from './theme'

// screen width is less than 600px
export function isMobilePortrait() {
  return theme.breakpoints.down('sm')
}

// screen width is greater than 600px
export function isMobilePortraitUp() {
  return theme.breakpoints.up('sm')
}

// screen width is less than 900px
export function isMobileDown() {
  return theme.breakpoints.down('md')
}

// screen size between 600px and 900px
export function isMobileLandscapeOnly() {
  return theme.breakpoints.between('sm', 'md')
}

// screen size between 900px and 1200px
export function isTabletOnly() {
  return theme.breakpoints.between('md', 'lg')
}

// screen size between 600px and 1200px
export function isMobileLandscapeAndTablet() {
  return theme.breakpoints.between('sm', 'lg')
}

// screen width is less than 1200px
export function isTabletDown() {
  return theme.breakpoints.down('lg')
}

// screen width is greater than 900px
export function isTabletUp() {
  return theme.breakpoints.up('md')
}

// screen width is less than 1536px
export function isDesktopDown() {
  return theme.breakpoints.down('xl')
}

// screen width is between 1536px and 2000px (arbitrary max screen size)
export function isDesktopUp() {
  return theme.breakpoints.between('xl', 2000)
}
