import { ReactElement } from 'react'

export type DrawerComponentProps = {
  title: string
  component: ReactElement
}

export type HolidayHistoryProps = {
  requestId: number
  onClose: () => void
}

export type HolidaySearchGridProps = {
  data: any
  onViewHistory: (requestId: number, employeeName: string) => void
}
export interface HolidayReportSearch {
  employeeId: number
  departmentId?: number
  teamId?: number
  requestTypeId?: number
  manualRequestTypeId?: number
  actionBy: number
  statusId?: number
  fromDateRangeFrom?: string
  fromDateRangeTo?: string
  submittedDateRangeFrom?: string
  submittedDateRangeTo?: string
}

export type HolidaySearchFormProps = {
  resetForm: boolean
  onSearch: (search: HolidayReportSearch) => void
}

export interface RequestType {
  key: string
  id: number
  value: string
  displayValue: string
}

export const RequestTypeData: RequestType[] = [
  { key: 'ALL', id: 0, value: 'All', displayValue: 'All' },
  { key: 'HOLIDAY', id: 1, value: 'Holiday', displayValue: 'Holiday' },
  { key: 'LIEU_DAY', id: 5, value: 'LieuDay', displayValue: 'Lieu' },
  { key: 'DAY_OFF', id: 2, value: 'DayOff', displayValue: 'Day Off' },
  { key: 'SHIFT', id: 3, value: 'Shift', displayValue: 'Shift' },
  { key: 'WORK_FROM_HOME', id: 6, value: 'WorkFromHome', displayValue: 'Work From Home' },
  { key: 'BUY', id: 8, value: 'Buy', displayValue: 'Buy' },
  { key: 'SELL', id: 9, value: 'Sell', displayValue: 'Sell' },
  { key: 'BIRTHDAY', id: 7, value: 'Birthday', displayValue: 'Birthday' },
  { key: 'MANUAL', id: 4, value: 'Manual', displayValue: 'Manual' },
]

export const ManualRequestTypeData: RequestType[] = [
  { key: 'ALL', id: 0, value: 'All', displayValue: 'All' },
  { key: 'ADJUSTMENT', id: 1, value: 'Adjustment', displayValue: 'Adjustments' },
  { key: 'HOLIDAY', id: 9, value: 'Holiday', displayValue: 'Holiday' },
  { key: 'LIEU', id: 3, value: 'Lieu', displayValue: 'Lieu' },
  { key: 'PATERNITY', id: 5, value: 'Paternity', displayValue: 'Paternity' },
  { key: 'MATERNITY', id: 4, value: 'Maternity', displayValue: 'Maternity' },
  { key: 'COURSE', id: 2, value: 'Course', displayValue: 'Course' },
  { key: 'NIGHT_COVER', id: 7, value: 'NightCover', displayValue: 'Night Cover' },
  { key: 'TWILIGHT_COVER', id: 8, value: 'TwilightCover', displayValue: 'Twilight Cover' },
  { key: 'OTHER', id: 6, value: 'Other', displayValue: 'Other' },
]
