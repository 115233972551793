import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../../redux/store'
import { DashboardDataGrid } from '../../shared/UI/DashboardDataGrid'
import { CustomFooterStatus, WhosAwayContainer } from '../WhoIsAway/components'
import { columns, columnsManager, getTargetDateString, getTabProps, isNoData } from './utils'
import { useWhosAway } from './useWhosAway'
import { getFilteredRows } from './utils/getFilteredRows'
import Paragraph from '../../shared/UI/Paragraph'
import NoResult from '../../shared/UI/NoResult'

interface Rows {
  colleague: string
  dateTo: Date
  hours: number
  absenceType?: string
}

function NoRowsOverlay() {
  return <NoResult message="No one is away" />
}

function WhoIsAway() {
  const isManager = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  ).includes('ViewWhoAwayManager')

  const [activeLink, setActiveLink] = useState<string>('Today')
  const [rows, setRows] = useState<Rows[]>([])
  const { whosAway, isLoading } = useWhosAway()

  useEffect(() => {
    if (whosAway) {
      setRows(getFilteredRows({ activeLink, whosAway }))
    }
  }, [whosAway, activeLink])

  return (
    <WhosAwayContainer whosAway={whosAway} isLoading={isLoading} setActiveLink={setActiveLink}>
      <DashboardDataGrid
        getRowId={row => `${row.colleague}${row.dateTo}${row.absenceType}${Math.random()}`}
        rows={rows}
        columns={isManager ? columnsManager : columns}
        disableSelectionOnClick
        headerHeight={0}
        autoHeight
        pageSize={3}
        density={isManager ? 'comfortable' : 'standard'}
        components={{
          Footer: CustomFooterStatus,
          NoRowsOverlay,
        }}
        sx={{
          '.MuiDataGrid-row': {
            '&:hover': {
              backgroundColor: '#ffffff',
            },
          },
        }}
      />
    </WhosAwayContainer>
  )
}

export default WhoIsAway
