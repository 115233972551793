import { Grid, Stack, TextField, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { renderToString } from 'react-dom/server'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import Button from '../../../../shared/UI/Button'
import Paragraph from '../../../../shared/UI/Paragraph'
import { getLocalDateString } from '../../../../utils/date-utils'
import { manualRequestsService } from '../../../../services/myActionsService'
import { RootStore, useAppDispatch } from '../../../../redux/store'
import { showErrorMessage, showSuccessMessage } from '../../../../redux/reducers/snackbarReducer'
import { ScaMaintenanceRequest } from '../../../../types/absence-sca'
import UserErrorMessage from '../../../../utils/errorFilter'
import { AbsenceOccurrence } from '../../../../types/absence'
import { BaseResponse } from '../../../../types/base-response'
import NoDataFound from '../../../../shared/UI/NoDataFound'
import LoadingIndicator from '../../../../shared/UI/LoadingIndicator'
import { EmployeeDetailsResponse } from '../../../../types/employee'
import SCAPrint from '../../SCAPrint'
import { sicknessTypeSortedData } from '../../../../utils/app-utils'
import { SelectOption } from '../../../../services/dashboardService'
import { AbsenceStatus } from '../../../../utils/constants'
import { DateFormats } from '../../../../api/absence/tempTypes/generalprops'

type Props = {
  absenceOccurrence: AbsenceOccurrence
  onSubmit: () => void
  onCancel?: () => void
  setIsScaComplete?: (res: boolean) => void
}

interface scaResponseType {
  nonWorkingDays?: number
  normalWeekWorkingDays?: number
  absenceReasonText?: string
  medicalPractitionerConsultedType: string
  medicalPractitionerComments?: string
  displayName: string
  date: string
  ip: string
}

interface editableStateType {
  nonWorkingDays?: string | number
  normalWeekWorkingDays?: string | number
  absenceReasonText?: string
  absenceTypeId?: number
  medicalPractitionerConsultedType: string
  medicalPractitionerComments?: string
}

const editableStateDefaults = {
  nonWorkingDays: '',
  normalWeekWorkingDays: '',
  absenceReasonText: '',
  medicalPractitionerConsultedType: 'Na',
  medicalPractitionerComments: '',
}
const REGEX = /^[0-9\b]+$/

function AddSCA({ absenceOccurrence, onSubmit, onCancel, setIsScaComplete }: Props) {
  const [scaSubmission, setScaSubmission] = useState<ScaMaintenanceRequest>()
  const [nonWorkingDaysError, setNonWorkingDaysError] = useState<boolean>(false)
  const [normalWeekWorkingDaysError, setNormalWeekWorkingDaysError] = useState<boolean>(false)
  const [absenceReasonTextError, setAbsenceReasonTextError] = useState<boolean>(false)
  const [medicalPractitionerCommentsError, setMedicalPractitionerCommentsError] =
    useState<boolean>(false)
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [scaResponse, setScaResponse] = useState<scaResponseType>()
  const [initialEditableState, setInitialEditableState] = useState<editableStateType>()
  const [changeableFields, setChangeableFields] = useState<editableStateType>(editableStateDefaults)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)
  const [sicknessTypes, setSicknessTypes] = useState<SelectOption[]>([])

  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse>(
    (state: RootStore) => state.appSettings.employeeDetails
  )

  const dispatch = useAppDispatch()

  const totalHoursLost = () => {
    let result = 0
    absenceOccurrence.absenceDays.forEach(day => {
      result += day.hoursLost
    })
    return result
  }

  const awaitingSCA =
    absenceOccurrence.scaRequired && (absenceOccurrence.absenceStatus.name === AbsenceStatus.AWAITING_ED ||
    absenceOccurrence.absenceStatus.name === AbsenceStatus.AWAITING_SCA)

  const scaButtonLabel = awaitingSCA ? 'Save and Sign' : 'Save and Re-submit'

  const consultedMedicalProfessional = (type: string) => {
    switch (type) {
      case 'MedicalPractitionerConsulted':
        return 'Yes'
      case 'MedicalPractitionerNotConsulted':
        return 'No'
      default:
        return 'Na'
    }
  }

  useEffect(() => {
    if (!awaitingSCA) {
      setIsLoading(true)
      manualRequestsService
        .getSCA(absenceOccurrence.id)
        .then(e => {
          setChangeableFields({
            nonWorkingDays: Number(e.nonWorkingDays),
            normalWeekWorkingDays: e.normalWeekWorkingDays,
            absenceReasonText: e.absenceReasonText,
            medicalPractitionerConsultedType: consultedMedicalProfessional(
              e.medicalPractitionerConsultType
            ),
            medicalPractitionerComments: e.medicalPractitionerComments,
          })
          setInitialEditableState({
            nonWorkingDays: Number(e.nonWorkingDays),
            normalWeekWorkingDays: e.normalWeekWorkingDays,
            absenceReasonText: e.absenceReasonText,
            medicalPractitionerConsultedType: consultedMedicalProfessional(
              e.medicalPractitionerConsultType
            ),
            medicalPractitionerComments: e.medicalPractitionerComments,
          })
          setScaResponse({
            nonWorkingDays: e.nonWorkingDays,
            normalWeekWorkingDays: e.normalWeekWorkingDays,
            absenceReasonText: e.absenceReasonText,
            medicalPractitionerConsultedType: consultedMedicalProfessional(
              e.medicalPractitionerConsultType
            ),
            medicalPractitionerComments: e.medicalPractitionerComments,
            displayName: e.signedByEmployeeName,
            date: new Date(e.signedDateTime).toLocaleString(),
            ip: e.signedOnMachine,
          })
          setIsLoading(false)
        })
        .catch(err => {
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    }

    sicknessTypeSortedData(employeeDetails.employeeId).then(res => setSicknessTypes(res))
  }, [])

  const scaDetailHasChanged = useCallback((): boolean => {
    if (changeableFields && initialEditableState) {
      return Object.keys(changeableFields).some(
        key =>
          changeableFields[key as keyof editableStateType] !==
          initialEditableState[key as keyof editableStateType]
      )
    }

    return false
  }, [initialEditableState, changeableFields])

  useEffect(() => {
    setHasChanges(scaDetailHasChanged())
  }, [scaDetailHasChanged])

  const isManager = absenceOccurrence.employeeResponse.id !== employeeDetails.employeeId
  const isDisabledReSubmitButton = (!awaitingSCA && !hasChanges) || isManager

  const handleSubmit = () => {
    if (!changeableFields) {
      return
    }

    if (changeableFields.medicalPractitionerConsultedType.toLowerCase() !== 'yes') {
      setMedicalPractitionerCommentsError(false)
    }

    if (
      !changeableFields.normalWeekWorkingDays ||
      !changeableFields.absenceReasonText ||
      (!changeableFields.medicalPractitionerComments &&
        changeableFields.medicalPractitionerConsultedType.toLowerCase() === 'yes')
    ) {
      if (changeableFields.nonWorkingDays === undefined) {
        setNonWorkingDaysError(true)
      }
      if (
        changeableFields.normalWeekWorkingDays === undefined ||
        changeableFields.normalWeekWorkingDays === 0 ||
        !changeableFields.normalWeekWorkingDays
      ) {
        setNormalWeekWorkingDaysError(true)
      }
      if (!changeableFields.absenceReasonText) {
        setAbsenceReasonTextError(true)
      }
      if (
        !changeableFields.medicalPractitionerComments &&
        changeableFields.medicalPractitionerConsultedType.toLowerCase() === 'yes'
      ) {
        setMedicalPractitionerCommentsError(true)
      }
      return
    }

    setScaSubmission({
      ...scaSubmission,
      absenceId: absenceOccurrence.id,
      absenceTypeId: Number(absenceOccurrence.absenceStatus.id),
      employee: {
        employeeId: absenceOccurrence.employeeResponse.id,
        displayName: absenceOccurrence.employeeResponse.displayName,
      },
      department: {
        departmentId: absenceOccurrence.departmentId,
        departmentName: String(absenceOccurrence.departmentName),
      },
      dateFrom: absenceOccurrence.startDate,
      dateTo: absenceOccurrence.endDate,
      totalDaysOfAbsence: absenceOccurrence.absenceDays.length,
      daysAbsent: absenceOccurrence.absenceDays.length,
      nonWorkingDays: Number(changeableFields.nonWorkingDays)
        ? Number(changeableFields.nonWorkingDays)
        : 0,
      normalWeekWorkingDays: Number(changeableFields.normalWeekWorkingDays)
        ? Number(changeableFields.normalWeekWorkingDays)
        : 0,
      hoursOfAbsence: totalHoursLost(),
      absenceType: {
        absenceTypeId: Number(absenceOccurrence.absenceDays[0].absenceType.absenceTypeId),
        reason: String(absenceOccurrence.reason),
        reasonType: String(absenceOccurrence.absenceDays[0].absenceType.reasonType),
      },
      absenceReasonText: changeableFields.absenceReasonText,
      medicalPractitionerConsultedType: changeableFields.medicalPractitionerConsultedType,
      medicalPractitionerComments: changeableFields.medicalPractitionerComments
        ? changeableFields.medicalPractitionerComments
        : '',
      signedDateTime: format(new Date(), DateFormats.DATE_AND_TIME),
    })
    setIsScaComplete?.(true)
  }

  useEffect(() => {
    if (!scaSubmission) {
      return
    }

    const updateSCA = () => {
      setIsSubmitLoading(true)
      manualRequestsService
        .resubmitSCA(scaSubmission)
        .then(res => {
          setIsSubmitLoading(false)
          dispatch(showSuccessMessage('SCA update submitted'))
          onSubmit()
        })
        .catch(err => {
          setIsSubmitLoading(false)
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    }

    const createSCA = () => {
      setIsSubmitLoading(true)
      manualRequestsService
        .createSCA(scaSubmission)
        .then(res => {
          setIsSubmitLoading(false)
          dispatch(showSuccessMessage('SCA submitted'))
          onSubmit()
        })
        .catch(err => {
          setIsSubmitLoading(false)
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    }

    if (awaitingSCA) {
      createSCA()
    } else {
      updateSCA()
    }
  }, [dispatch, scaSubmission])

  const handlePrint = () => {
    const content = renderToString(
      <SCAPrint absenceOccurrence={absenceOccurrence!} sca={scaResponse!} />
    )
    const winPrint: any = window.open(
      '',
      '',
      'left=0,top=0,width=384,height=900,toolbar=0,scrollbars=0,status=0'
    )
    winPrint.document.write('<html><head>')
    winPrint.document.write(
      `<title>Self-Certification of Absence ${absenceOccurrence.employeeResponse.displayName}</title>`
    )
    winPrint.document.write('<link rel="stylesheet" href="../../../print.css">')
    winPrint.document.write('</head><body>')
    winPrint.document.write(content)
    winPrint.document.write('</body></html>')
    setTimeout(() => {
      winPrint.print()
    }, 500)
    setTimeout(() => {
      winPrint.close()
    }, 700)
  }

  return (
    <Grid container xl={12} rowSpacing={4} columnSpacing={4} pb={4}>
      {absenceOccurrence ? (
        <>
          <Grid item sm={12}>
            <Paragraph>
              This form should be completed upon your return to work following any period of absence
              of up to 7 days, and digitally signed using the provided controls. If you are
              returning to work after a period of more than 7 days absence you should provide a
              supporting Medical Certificate to your Departmental Manager.
            </Paragraph>
          </Grid>
          {/* Left Column */}
          <Grid item xs={12} lg={6} display="flex" pt={1}>
            <Grid container xl={12} alignContent="flex-start">
              {/* Name */}
              <Grid item xs={12} mb={1}>
                <Paragraph weight="bold">Name</Paragraph>
              </Grid>
              <Grid item xs={12}>
                <Paragraph>
                  {absenceOccurrence && absenceOccurrence.employeeResponse.displayName}
                </Paragraph>
              </Grid>
              {/* Period Of Absence */}
              <Grid item xs={12} display="flex" mt={4} mb={1}>
                <Paragraph weight="bold">
                  Period Of Absence (Including non-working days from the first day of absence)
                </Paragraph>
              </Grid>
              <Grid item xs={12}>
                {absenceOccurrence && (
                  <Paragraph>
                    {getLocalDateString(absenceOccurrence && absenceOccurrence.startDate)}
                    {' - '}
                    {getLocalDateString(absenceOccurrence && absenceOccurrence.endDate)}
                  </Paragraph>
                )}
              </Grid>
              {/* Total Hours Lost */}
              <Grid item xs={12} mt={4} mb={1}>
                <Paragraph weight="bold">Total number of hours absent</Paragraph>
              </Grid>
              <Grid item xs={12}>
                <Paragraph>{absenceOccurrence && totalHoursLost()}</Paragraph>
              </Grid>
              {/* Absence Reason */}
              <Grid item xs={12} mt={4} mb={1}>
                <Paragraph weight="bold">Absence Reason</Paragraph>
              </Grid>
              <Grid item xs={12}>
                <Paragraph>{absenceOccurrence && absenceOccurrence.reason}</Paragraph>
              </Grid>
              {/* Absence Classification */}
              <Grid item xs={12} display="flex" mt={4} mb={1}>
                <Paragraph weight="bold">Absence Classification</Paragraph>
              </Grid>
              <Grid item xs={12}>
                <Paragraph>
                  {absenceOccurrence && absenceOccurrence.absenceDays[0].absenceType.reasonType}
                </Paragraph>
              </Grid>
            </Grid>
          </Grid>
          {/* Right Column */}
          <Grid item xs={12} lg={6} pt={1}>
            <Grid container xl={12} alignContent="flex-start">
              {/* Total Number of days absent */}
              <Grid item xs={12} mb={1}>
                <Paragraph weight="bold">Total Number of days absent</Paragraph>
              </Grid>
              <Grid item xs={12}>
                <Paragraph>{absenceOccurrence && absenceOccurrence.absenceDays.length}</Paragraph>
              </Grid>
              {/* Total Number of days absent */}
              <Grid item xs={12} mt={4} mb={1}>
                <Paragraph weight="bold">Of which resulted in absence from work</Paragraph>
              </Grid>
              <Grid item xs={12}>
                <Paragraph>{absenceOccurrence && absenceOccurrence.absenceDays.length}</Paragraph>
              </Grid>
              {/* of which were during non-working days off */}
              <Grid item xs={12} mt={5}>
                <TextField
                  id="non-working-days"
                  label="of which were during non-working days off"
                  variant="outlined"
                  value={changeableFields?.nonWorkingDays}
                  fullWidth
                  onChange={e => {
                    setNonWorkingDaysError(false)
                    setChangeableFields({
                      ...changeableFields,
                      nonWorkingDays:
                        e.target.value !== null && REGEX.test(e.target.value) ? e.target.value : '',
                    })
                    setHasChanges(true)
                  }}
                  placeholder="0"
                  disabled={isManager}
                  error={nonWorkingDaysError}
                  InputLabelProps={{ shrink: true }}
                  onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
                />
              </Grid>
              {/* Usual number of days worked per week */}
              <Grid item xs={12} mt={5}>
                <TextField
                  id="working-days"
                  label="Usual number of days worked per week"
                  variant="outlined"
                  value={changeableFields?.normalWeekWorkingDays}
                  fullWidth
                  onChange={e => {
                    setNormalWeekWorkingDaysError(false)
                    setChangeableFields({
                      ...changeableFields,
                      normalWeekWorkingDays:
                        e.target.value !== null && REGEX.test(e.target.value) ? e.target.value : '',
                    })
                    setHasChanges(true)
                  }}
                  placeholder="0"
                  disabled={isManager}
                  error={normalWeekWorkingDaysError}
                  InputLabelProps={{ shrink: true }}
                  onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Middle Column */}
          <Grid item xs={12}>
            {/* Reason for absence */}
            <Grid item xs={12}>
              <Paragraph weight="bold" padding="0 0 14px 0">
                Reason for absence
              </Paragraph>
              <TextField
                id="reason-for-absence"
                label="Please note only general information needs to be provided"
                variant="outlined"
                value={changeableFields?.absenceReasonText}
                rows={3}
                multiline
                fullWidth
                disabled={isManager}
                onChange={e => {
                  setAbsenceReasonTextError(false)
                  setChangeableFields({
                    ...changeableFields,
                    absenceReasonText: e.target.value,
                    absenceTypeId: sicknessTypes.find(
                      x => x.displayValue === e.target.value.toString()
                    )?.value,
                  })
                  setHasChanges(true)
                }}
                error={absenceReasonTextError}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {/* Did you consult a medical practitioner? */}
            <Grid container>
              <Grid item xs={12}>
                <Paragraph weight="bold" padding="10px 0 0 0">
                  Did you consult a medical practitioner?
                </Paragraph>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-start">
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={changeableFields?.medicalPractitionerConsultedType}
                  onChange={e => {
                    setChangeableFields({
                      ...changeableFields,
                      medicalPractitionerConsultedType: e.target.value,
                    })
                    setHasChanges(true)
                    if (
                      e.target.value.toLowerCase() === 'yes' &&
                      !changeableFields?.medicalPractitionerComments
                    ) {
                      setMedicalPractitionerCommentsError(true)
                    } else {
                      setMedicalPractitionerCommentsError(false)
                    }
                  }}
                >
                  <FormControlLabel
                    value="Yes"
                    disabled={isManager}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    disabled={isManager}
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value="Na"
                    disabled={isManager}
                    control={<Radio />}
                    label="N/A"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={6} mt="33px">
                <TextField
                  id="practitioner-details"
                  label="Please provide your Doctors Name, Address, Date of Visit, 
                Treatment Received and any Current Treatment:"
                  variant="outlined"
                  value={changeableFields?.medicalPractitionerComments}
                  rows={3}
                  multiline
                  fullWidth
                  disabled={isManager}
                  onChange={e => {
                    setMedicalPractitionerCommentsError(false)
                    setChangeableFields({
                      ...changeableFields,
                      medicalPractitionerComments: e.target.value,
                    })
                    setHasChanges(true)
                  }}
                  error={medicalPractitionerCommentsError}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} mt="33px">
              <Paragraph weight="medium">
                I certify that I have been incapable of work because of the reasons stated above, on
                the dates shown and that this information is true and accurate. I acknowledge that
                false information will result in disciplinary action.I hereby give my employer
                permission to verify the above information.
              </Paragraph>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
              {scaButtonLabel !== 'Save and Sign' && (
                <>
                  {scaResponse && (
                    <Paragraph weight="medium">
                      {`Digitally signed by ${scaResponse.displayName} on ${scaResponse.date} on machine ${scaResponse.ip}`}
                    </Paragraph>
                  )}
                  <Button
                    label="Print"
                    color="secondary"
                    variant="outlined"
                    onClick={handlePrint}
                  />
                </>
              )}
              <Button label="Cancel" color="secondary" variant="outlined" onClick={onCancel} />
              {!isManager && (
                <Button
                  label={scaButtonLabel}
                  color="primary"
                  variant="outlined"
                  onClick={handleSubmit}
                  disabled={isDisabledReSubmitButton}
                  loading={isSubmitLoading}
                />
              )}
            </Stack>
          </Grid>
        </>
      ) : (
        <>
          <NoDataFound show={!isLoading && !absenceOccurrence} />
          <LoadingIndicator show={isLoading} />
        </>
      )}
    </Grid>
  )
}

export default AddSCA
