import { Grid, Box } from '@mui/material'
import { useState, useEffect } from 'react'
import Drawer from '../../../shared/UI/Drawer'
import { MyRequestsSummaryItems } from '../MyRequestsSummaryItems'
import ButtonSmall from '../../../shared/UI/ButtonSmall'
import mySummaryOverlay from '../../../assets/mySummaryOverlay.svg'
import { MySummaryCard, MySummaryImg } from './components'

export function MyRequestsBanner() {
  const [title, setTitle] = useState<string>('')
  const [showDrawer, setShowDrawer] = useState<boolean>(false)
  const [drawerType, setDrawerType] = useState<React.ReactElement>()

  useEffect(() => {
    setTitle('My Summary')
    setDrawerType(<MyRequestsSummaryItems onCancel={() => setShowDrawer(false)} />)
  }, [])

  return (
    <Grid container mb="23px">
      <Grid item xs={12}>
        <MySummaryCard>
          <MySummaryImg src={mySummaryOverlay} alt="my summary" />
          <ButtonSmall
            label="My Summary"
            onClick={() => setShowDrawer(true)}
            sx={{
              position: 'absolute',
              right: '4%',
              zIndex: 2,
            }}
            dataTestId="MyAvailability-SummaryBtn"
          />
        </MySummaryCard>
      </Grid>
      <Drawer
        isOpen={showDrawer}
        onClose={() => {
          setShowDrawer(false)
        }}
        title={title}
        showOptions={false}
        narrow
      >
        <Box sx={{ p: 4, pt: 0 }}>{drawerType}</Box>
      </Drawer>
    </Grid>
  )
}
