import { Card, Grid } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from '../../shared/UI/Button'
import { getDateTime, getDateTime24HrFormat } from '../../utils/date-utils'
import { HolidayHistoryProps } from './types'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import { manualRequestsService } from '../../services/myActionsService'
import { BaseResponse } from '../../types/base-response'
import UserErrorMessage from '../../utils/errorFilter'
import { useAppDispatch } from '../../redux/store'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'

function HolidayHistory({ requestId, onClose }: HolidayHistoryProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [pageSize, setPageSize] = useState<number>(10)
  const [historyData, setHistoryData] = useState<any>([])
  const dispatch = useAppDispatch()

  const LoadingContainer = styled.div`
    margin-top: 100px;
    height: 200px;
  `

  useEffect(() => {
    manualRequestsService
      .getHolidayRequestHistory(requestId)
      .then((data: any) => {
        setHistoryData(data.items)
        setIsLoading(false)
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date/Time',
      headerClassName: 'header',
      type: 'date',
      flex: 1,
      maxWidth: 150,
      renderCell: cellValues =>
        cellValues.row.actionedDate != null
          ? getDateTime24HrFormat(new Date(cellValues.row.actionedDate))
          : getDateTime24HrFormat(new Date(cellValues.row.dateSubmitted)),
    },
    {
      field: 'actionby',
      headerName: 'Actioned By',
      headerClassName: 'header',
      type: 'string',
      flex: 1,
      maxWidth: 180,
      renderCell: cellValues =>
        cellValues.row.actionedByEmployee != null
          ? cellValues.row.actionedByEmployee.detail
          : cellValues.row.employee.displayName,
    },
    {
      field: 'requestStatusStr',
      headerName: 'Request Status',
      headerClassName: 'header',
      type: 'string',
      flex: 1,
      maxWidth: 180,
    },
    {
      field: 'actionDescription',
      headerName: 'Action',
      headerClassName: 'header',
      type: 'string',
      flex: 1,
      maxWidth: 180,
    },
    {
      field: 'details',
      headerName: 'Details',
      headerClassName: 'header',
      type: 'string',
      flex: 1,
      maxWidth: 5000,
    },
  ]
  return (
    <>
      {isLoading && (
        <LoadingContainer>
          <LoadingIndicator show={isLoading} />
        </LoadingContainer>
      )}
      {!isLoading && (
        <Grid container mt={7} mb={5} pl={4} pr={4}>
          <Grid item xs={12}>
            <Card title="History">
              <DataGrid
                getRowId={row => row.auditId}
                rows={historyData}
                columns={columns}
                disableSelectionOnClick
                rowsPerPageOptions={[10, 25, 50, 100]}
                pageSize={pageSize}
                onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                getRowHeight={() => 'auto'}
                autoHeight
                density="standard"
                sx={{
                  '&& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
                    whiteSpace: 'pre-wrap',
                    paddingTop: '5px',
                  },
                  '&.MuiDataGrid-root': {
                    border: 'none !important ',
                    overflow: 'none',
                  },
                  '&& .MuiDataGrid-cell': {
                    paddingTop: '10px',
                    paddingBottom: '10px',
                  },
                  '& .header': {
                    backgroundColor: '#F4F5F7',
                  },
                }}
              />
            </Card>
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <Grid container xl={12} spacing={4}>
          <Grid item xs={12} gap={4} display="flex" justifyContent="flex-end">
            <Button label="Close" color="secondary" variant="outlined" onClick={onClose} />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default HolidayHistory
