import { useState, useEffect, useMemo, useCallback } from 'react'
import { Grid } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { DrawerComponentProps, SummaryProps } from '../../Absence/types'
import InstanceStatusBanner from '../../../shared/UI/InstanceStatusBanner'
import Drawer from '../../../shared/UI/Drawer'
import { formatDateWithTimeZone, getLocalDateString } from '../../../utils/date-utils'
import { RootStore, useAppDispatch } from '../../../redux/store'
import { manualRequestsService } from '../../../services/myActionsService'
import AbsenceDetail from '../../AbsenceDetail'
import { AbsenceDetailsResponse, AbsenceOccurrence } from '../../../types/absence'
import { AbsenceDay } from '../../../types/absence-day'
import { AttendanceCalendarItem, AttendanceCalendarResponse } from '../../../types/calendar-items'
import { showErrorMessage } from '../../../redux/reducers/snackbarReducer'
import { BaseResponse } from '../../../types/base-response'
import SummaryTwoColumn from '../../Late/SummaryTwoColumn'
import PageHeader from '../../../shared/layout/PageHeader/PageHeader'
import ViewOccurrence from '../../Absence/ViewOccurrence/index'
import { PlottingCalendars } from '../../PlottingCalendar'
import OccurrenceSubNav from '../../../shared/UI/OccurrenceSubNav'
import NoDataFound from '../../../shared/UI/NoDataFound'
import { EmployeeDetailsResponse } from '../../../types/employee'
import LoadingIndicator from '../../../shared/UI/LoadingIndicator'
import UserErrorMessage from '../../../utils/errorFilter'
import { availabilityService } from '../../../services/availabilityService'
import { setActiveRoute } from '../../../redux/reducers/appSettingsReducer'
import { LocationGroups } from '../../../types/location-groups'
import AddEDUK from '../../Absence/Actions/AddED-UK'
import AddSCA from '../../Absence/Actions/AddSCA'
import { getScaEdDrawerTitle } from '../../../utils/app-utils'

function MyRequestsAbsence() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [absenceOccurrence, setAbsenceOccurrence] = useState<AbsenceOccurrence>()
  const [summaryLeft, setSummaryLeft] = useState<Array<SummaryProps>>([])
  const [summaryRight, setSummaryRight] = useState<Array<SummaryProps>>([])
  const [drawerComponent, setDrawerComponent] = useState<DrawerComponentProps | null>(null)
  const [view, setView] = useState('Overview')
  const [calendarEvents, setCalendarEvents] = useState<AttendanceCalendarItem[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isData, setIsData] = useState<boolean>(false)
  const [showScaDrawer, setShowScaDrawer] = useState<boolean>(false)
  const [scaEdTitle, setScaEdTitle] = useState<string>('Self-Certification of Absence')

  const { refreshurlepoch } = useParams<{ refreshurlepoch: string }>()
  const { id: absenceId } = useParams<{ id: string }>()

  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse>(
    (state: RootStore) => state.appSettings.employeeDetails
  )

  const { territoryAttendanceUK } = useSelector((state: RootStore) => state.featureToggles)

  const isAllowedToEditAbsence = useMemo(
    () => Boolean(userPermissions.find(x => x === 'EditAttendance')),
    [userPermissions]
  )

  const lostHours = useCallback(
    (param: keyof AbsenceDay) => {
      let totalHoursLost = 0
      absenceOccurrence?.absenceDays.forEach((day: AbsenceDay) => {
        totalHoursLost += +(day[param] || 0)
      })

      return totalHoursLost
    },
    [absenceOccurrence?.absenceDays]
  )

  const currentYear = absenceOccurrence?.startDate
    ? new Date(absenceOccurrence?.startDate).getFullYear()
    : new Date().getFullYear()

  const getAbsenceData = useCallback(() => {
    setIsLoading(true)
    availabilityService
      .getMyAbsenceDetail(Number(absenceId))
      .then((result: AbsenceDetailsResponse) => {
        setAbsenceOccurrence(result.absenceOccurrence)
        setIsLoading(false)
        setIsData(true)
      })
      .catch(err => {
        setIsData(false)
        setIsLoading(false)
        navigate('/dashboard')
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [absenceId, dispatch, navigate])

  const handleFormSubmission = useCallback(() => {
    setShowScaDrawer(false)
    getAbsenceData()
  }, [getAbsenceData])

  useEffect(() => {
    getAbsenceData()
  }, [getAbsenceData])

  const hideViewEditScaButton = useCallback(
    () =>
      !absenceOccurrence?.hasValidRtw ||
      (absenceOccurrence?.scaRequired &&
        !absenceOccurrence.hasValidSca &&
        absenceOccurrence.absenceDays.some(
          day => day.absenceType.reasonType?.toLowerCase() === 'sickness'
        )),
    [
      absenceOccurrence?.absenceDays,
      absenceOccurrence?.hasValidRtw,
      absenceOccurrence?.hasValidSca,
      absenceOccurrence?.scaRequired,
    ]
  )

  const isBtnHidden = useMemo(() => {
    if (hideViewEditScaButton()) {
      return false
    }
    return true
  }, [hideViewEditScaButton])

  const edFormat = () => {
    switch (absenceOccurrence?.employeeResponse.locationGroupName) {
      case territoryAttendanceUK && LocationGroups.UK:
        setScaEdTitle('Employee Declaration')
        return (
          <Grid container pr={4}>
            <AddEDUK
              absenceOccurrence={absenceOccurrence!}
              onSubmit={handleFormSubmission}
              onCancel={() => setShowScaDrawer(false)}
            />
          </Grid>
        )
      default:
        setScaEdTitle('Self-Certification of Absence')
        return (
          <Grid container pr={4}>
            <AddSCA
              absenceOccurrence={absenceOccurrence!}
              onSubmit={handleFormSubmission}
              onCancel={() => setShowScaDrawer(false)}
            />
          </Grid>
        )
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const params = new URLSearchParams(window.location.search)
      const show = params.get('showDrawer')
      if (isData && show !== null) {
        if (show === 'addSCA' && !absenceOccurrence?.hasValidSca) {
          setShowScaDrawer(true)
          setDrawerComponent({
            title: getScaEdDrawerTitle(
              absenceOccurrence?.employeeResponse.locationGroupName,
              territoryAttendanceUK
            ),
            component: edFormat(),
            isNarrow: false,
          })
        }
      }
    }, 500)
  }, [absenceOccurrence, handleFormSubmission, isData, refreshurlepoch])

  const getCalendarYear = (): [Date, Date] => {
    const year = new Date(absenceOccurrence!.startDate).getFullYear()
    return [new Date(year, 0, 1), new Date(year, 11, 31)]
  }

  useEffect(() => {
    if (!absenceOccurrence || view !== 'Calendar' || calendarEvents.length || !currentYear) {
      return
    }
    setIsLoading(true)
    manualRequestsService
      .getCalendarItems(absenceOccurrence.employeeResponse.id, currentYear)
      .then((result: AttendanceCalendarResponse) => {
        const items = result.items.sort((a, b) => (a.dateFrom > b.dateFrom ? 0 : -1))
        setCalendarEvents(items)
        setView('Calendar')
        setIsLoading(false)
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [absenceOccurrence, calendarEvents.length, currentYear, dispatch, view])

  useEffect(() => {
    if (!absenceOccurrence) {
      return
    }
    const summaryItems = [] as Array<SummaryProps>
    summaryItems.push({
      id: 1,
      title: 'Expected Return',
      value: getLocalDateString(absenceOccurrence.expectedReturn || new Date()),
    })
    summaryItems.push({ id: 2, title: 'Absent Days', value: absenceOccurrence.absenceDays?.length })
    summaryItems.push({ id: 3, title: 'Hours Lost', value: lostHours('hoursLost') })
    summaryItems.push({
      id: 4,
      title: 'Sick pay hours taken',
      value: lostHours('sickHoursToBePaid'),
    })
    summaryItems.push({
      id: 5,
      title: 'Department',
      value: absenceOccurrence.departmentName,
    })
    summaryItems.push({
      id: 6,
      title: 'Position ',
      value: absenceOccurrence.position?.positionName,
    })
    summaryItems.push({
      id: 7,
      title: 'Line Manager',
      value: absenceOccurrence.manager?.displayName,
    })

    setSummaryLeft(summaryItems.slice(0, 4))
    const summaryRemainder = summaryItems.slice(4)
    setSummaryRight(summaryRemainder)
  }, [absenceOccurrence, isAllowedToEditAbsence, lostHours])

  if (!absenceOccurrence) {
    return null
  }

  const getDocumentNameByLocationGroup = (locationGroup: LocationGroups | string): string => {
    switch (locationGroup) {
      case territoryAttendanceUK && LocationGroups.UK:
        return 'ED'
      default:
        return 'SCA'
    }
  }

  const getActionByStatus = (hasCompleted: boolean): string => {
    if (hasCompleted) return 'View/Edit'

    return 'Complete'
  }

  const scaButtonLabel = (): string => {
    const documentName = getDocumentNameByLocationGroup(
      absenceOccurrence.employeeResponse.locationGroupName
    )

    const action = getActionByStatus(absenceOccurrence.hasValidSca)

    return `${action} ${documentName}`
  }

  const handleViewClick = (row: AbsenceDay) => {
    setDrawerComponent({
      title: 'View Occurrence',
      component: (
        <Grid container pr={4}>
          <ViewOccurrence
            row={row}
            onClose={() => setShowScaDrawer(false)}
            manager={absenceOccurrence.manager.displayName}
            department={absenceOccurrence.departmentName}
            position={absenceOccurrence.position.positionName}
          />
        </Grid>
      ),
      isNarrow: true,
    })
    setShowScaDrawer(true)
  }

  const subNav = [
    {
      label: 'Overview',
      onClick: () => setView('Overview'),
    },
    {
      label: 'View All Absences',
      onClick: () => {
        dispatch(setActiveRoute(`/myavailability`))
        navigate('/myavailability', {
          state: { calendar: true, calendarFilters: ['Absence'] },
        })
      },
    },
    {
      label: scaButtonLabel(),
      onClick: () => {
        setShowScaDrawer(true)
        setDrawerComponent({
          title: getScaEdDrawerTitle(
            absenceOccurrence.employeeResponse.locationGroupName,
            territoryAttendanceUK
          ),
          component: edFormat(),
          isNarrow: false,
        })
      },
      hidden: isBtnHidden,
    },
  ]
  
  return (
    <>
      <Drawer
        isOpen={showScaDrawer}
        onClose={() => {
          setShowScaDrawer(false)
        }}
        title={drawerComponent?.title || ''}
        narrow={drawerComponent?.isNarrow}
      >
        <Grid item p={4} pr={0}>
          {drawerComponent?.component}
        </Grid>
      </Drawer>
      <PageHeader title="Absence Occurrence" />
      <Grid container spacing={4} mb={3}>
        <Grid item xs={12} md={6}>
          {absenceOccurrence && (
            <>
              <InstanceStatusBanner
                showBackButton
                firstname={absenceOccurrence.employeeResponse?.firstName || ''}
                surname={absenceOccurrence.employeeResponse?.lastName || ''}
                status={absenceOccurrence.absenceStatus.name || ''}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6} display="flex" justifyContent="flex-end" alignItems="center">
          <OccurrenceSubNav
            navItems={
              !absenceOccurrence.scaRequired ||
              (absenceOccurrence.scaRequired && absenceOccurrence.absenceStatus.isComplete)
                ? subNav.slice(0, 2)
                : subNav
            }
          />
        </Grid>
      </Grid>
      {absenceOccurrence ? (
        <>
          {view === 'Calendar' ? (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <PlottingCalendars
                  defineRange={getCalendarYear()}
                  dates={calendarEvents.map(event => ({
                    start: formatDateWithTimeZone(event.dateFrom),
                    end: formatDateWithTimeZone(event.dateTo),
                    eventType: event.recordType,
                  }))}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={4}>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <SummaryTwoColumn summaryItemsLeft={summaryLeft} summaryItemsRight={summaryRight} />
              </Grid>
              <Grid item xs={12}>
                <AbsenceDetail
                  isLoading={isLoading}
                  isData={isData}
                  absenceDays={absenceOccurrence?.absenceDays}
                  absenceId={absenceOccurrence?.id}
                  handleUpdate={e => e}
                  viewOnly
                  handleViewClick={handleViewClick}
                />
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <>
          <NoDataFound show={!isLoading && !absenceOccurrence} />
          <LoadingIndicator show={isLoading} />
        </>
      )}
    </>
  )
}

export default MyRequestsAbsence
