import { AbsenceOccurrence } from '../../types/absence'
import { RtwMaintenanceRequest } from '../../types/return-to-work'
import { getLocalDateString } from '../../utils/date-utils'

type Props = {
  signedByHtml: string
  absenceData: AbsenceOccurrence
  rtwData: RtwMaintenanceRequest
}

function UKRTWPrint({ signedByHtml, absenceData, rtwData }: Props) {
  const signedBys: string[] = signedByHtml.split('<br/>')

  return (
    <div>
      <h1>Return to Work Consultation Questionnaire</h1>
      <hr />
      <br />
      <h3>Employee Name</h3>
      <p>{absenceData.employeeResponse.displayName}</p>
      <br />
      <h3>Date of Absence</h3>
      <p>
        {getLocalDateString(absenceData.startDate)}
        {' - '}
        {getLocalDateString(absenceData.endDate)}
      </p>
      <br />
      <h3>Total number of hours absent</h3>
      <p>{absenceData.absenceDays.reduce((a: any, b: any) => a + b.hoursLost, 0)}</p>
      <br />
      <h3>Total number of sick pay hours used</h3>
      <p>{absenceData.absenceDays.reduce((a: any, b: any) => a + (b.sickHoursToBePaid || 0), 0)}</p>
      <br />
      <h3>Absence Classification</h3>
      <p>{absenceData.absenceDays[0].absenceType.reasonType}</p>
      <br />
      <h3>Absence Reason</h3>
      <p>{absenceData.reason}</p>
      <br />
      <hr />

      <div className="line">
        <div className="item" style={{ flexGrow: 7 }}>
          Return to work date
        </div>
        <div className="item" style={{ flexGrow: 2 }}>
          &nbsp;
        </div>
        <div className="item" style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
          &nbsp;
        </div>
      </div>
      <div className="input small">{new Date(rtwData.returnDate).toLocaleDateString()}</div>
      <br />
      <hr />

      <div className="line">
        <div className="item" style={{ flexGrow: 7 }}>
          Have all procedures been followed?
        </div>
        <div className="item" style={{ flexGrow: 2 }}>
          &nbsp;
        </div>
        <div className="item" style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
          <input type="checkbox" defaultChecked={rtwData.proceduresFollowed} />
        </div>
      </div>
      <div className="input">{rtwData.proceduresComments}</div>
      <br />
      <hr />

      <div className="line">
        <div className="item" style={{ flexGrow: 7 }}>
          Is this absence reason recurrent?
        </div>
        <div className="item" style={{ flexGrow: 2 }}>
          &nbsp;
        </div>
        <div className="item" style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
          <input type="checkbox" defaultChecked={rtwData.recurrent} />
        </div>
      </div>
      <div className="input">{rtwData.recurrentComments}</div>
      <br />
      <hr />

      <div className="line">
        <div className="item" style={{ flexGrow: 7 }}>
          Is the absence work related?
        </div>
        <div className="item" style={{ flexGrow: 2 }}>
          &nbsp;
        </div>
        <div className="item" style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
          <input type="checkbox" defaultChecked={rtwData.workRelatedAbsence} />
        </div>
      </div>
      <div className="input">{rtwData.workRelatedComments}</div>
      <br />
      <hr />

      {rtwData.workRelatedAbsence && (
        <>
          <div className="line">
            <div className="item" style={{ flexGrow: 7 }}>
              Is there anything bet365 can do to prevent this from happening again?
            </div>
            <div className="item" style={{ flexGrow: 2 }}>
              &nbsp;
            </div>
            <div className="item" style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
              <input type="checkbox" defaultChecked={rtwData.companyAction} />
            </div>
          </div>
          <div className="input">{rtwData.companyActionComments}</div>
          <br />
          <hr />
        </>
      )}
      <div className="line">
        <div className="item" style={{ flexGrow: 7 }}>
          Medical Certificate Provided?
        </div>
        <div className="item" style={{ flexGrow: 2 }}>
          &nbsp;
        </div>
        <div className="item" style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
          <input
            type="checkbox"
            defaultChecked={rtwData.medicalPractitionerConsultedType === 'yes'}
          />
        </div>
      </div>
      <div className="input">{rtwData.medicalAdviceComments}</div>
      <br />
      <hr />
      <div className="line">
        <div className="item" style={{ flexGrow: 7 }}>
          Further Comments
        </div>
        <div className="item" style={{ flexGrow: 2 }}>
          &nbsp;
        </div>
        <div className="item" style={{ flexGrow: 1 }} />
      </div>
      <div className="input">{rtwData.additionalComments}</div>
      <br />
      <p>
        {signedBys.map(item => (
          <>
            {item}
            <br />
          </>
        ))}
      </p>
    </div>
  )
}
export default UKRTWPrint
