import { format } from 'date-fns'
import { FormControlLabel, Grid } from '@mui/material'
import { DateRange } from '@mui/x-date-pickers-pro'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../../../../redux/store'

import Button from '../../../../shared/UI/Button'
import AutocompleteList from '../../../../shared/UI/AutocompleteList'
import DateRangePicker from '../../../../shared/UI/DateRangePicker'

import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { FormProps, Search } from './types'
import {
  CurrentEntitlementPeriodResponse,
  SelectOption,
} from '../../../../services/dashboardService'
import DropdownMenu from '../../../../shared/UI/DropdownMenu'

function HolidaysEmployeeRequestSearchForm({ resetForm, employees, onSearch }: FormProps) {
  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)
  const { calendarDetails } = useSelector(
    (state: RootStore) => state.appSettings.calendarDetailsResponse!
  )

  const [selectDateRange, setSelectDateRange] = useState<DateRange<Date>>([null, null])
  const [selectedEmployees, setSelectedEmployees] = useState<SelectOption[]>([])
  const [selectedYear, setSelectedYear] = useState<CurrentEntitlementPeriodResponse | null>(
    currentEntitlementPeriodResponse !== undefined ? currentEntitlementPeriodResponse : null
  )

  const onHandleEmployeesChange = (event: unknown, items: SelectOption[]) => {
    setSelectedEmployees(items)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const search: Search = {
      employeeIds: selectedEmployees?.map(p => p.value),
      allEmployees: false,
      fromDateRangeFrom: format(new Date(selectedYear!.startDate), 'yyyy-MM-dd'),
      fromDateRangeTo: format(new Date(selectedYear!.endDate), 'yyyy-MM-dd'),
      selectDateRangeFrom: selectDateRange[0] ? format(selectDateRange[0], 'yyyy-MM-dd') : '',
      selectDateRangeTo: selectDateRange[1] ? format(selectDateRange[1], 'yyyy-MM-dd') : '',
    }

    if (selectedEmployees.length === 0) {
      search.employeeIds = employees?.map(p => p.value)
    }

    onSearch(search)
  }

  useDidMountEffect(() => {
    setSelectedEmployees([])
    setSelectedYear(currentEntitlementPeriodResponse!)
    setSelectDateRange([null, null])
  }, [resetForm])

  return (
    <Grid container spacing={4} component="form" noValidate mt={0}>
      {/* Main Layout 4 Col */}
      <Grid item xs={12} lg={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <DropdownMenu
              label="Year"
              id="year"
              textField="year"
              valueField="year"
              value={selectedYear ? selectedYear!.year : null}
              data={calendarDetails}
              onChange={e => {
                setSelectedYear(calendarDetails.find(p => p.year === parseInt(e.target.value, 10))!)
              }}
            />
          </Grid>
          <Grid item xs={4} style={{ flex: 1 }}>
            <Grid item xs={12} style={{ flex: 1 }}>
              <AutocompleteList
                multiple
                id="employees"
                label="All Employees"
                data={employees}
                textField="displayValue"
                value={selectedEmployees || null}
                onChange={onHandleEmployeesChange}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} lg={4}>
            <DateRangePicker
              arrange="Horizontal"
              startText="Date from"
              endText="Date to"
              onChange={(date: DateRange<Date>, keyboardInputValue?: string | undefined): void =>
                setSelectDateRange(date)
              }
              value={selectDateRange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} pt={0} mt={0} display="flex" justifyContent="flex-end">
        <Button label="Search" type="submit" onClick={handleSubmit} />
      </Grid>
    </Grid>
  )
}

export default HolidaysEmployeeRequestSearchForm
