import { Collapse, Grid, Stack } from '@mui/material'
import { format } from 'date-fns'
import styled from 'styled-components'

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStore, useAppDispatch } from '../../redux/store'
import Drawer from '../../shared/UI/Drawer'

import { myReportingService } from '../../services/myReportingService'
import { HolidaySearchItem } from '../../types/holiday-search'

import HolidaySearchForm from './HolidaySearchForm'
import HolidaySearchGrid from './HolidaySearchGrid'
import { DrawerComponentProps, HolidayReportSearch } from './types'

import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import FilterButton from '../../shared/UI/FilterButton'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import RefreshButton from '../../shared/UI/RefreshButton'
import { BaseResponse } from '../../types/base-response'
import UserErrorMessage from '../../utils/errorFilter'
import HolidayHistory from './HolidayHistory'

export const LoadingIndicatorContainer = styled.div`
  padding-top: 70px;
  padding-bottom: 70px;
`

function HolidaySearch() {
  const dispatch = useAppDispatch()
  const [resetForm, setResetForm] = useState(false)
  const [showHeaderButtons, setShowHeaderButtons] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(true)
  const [showGrid, setShowGrid] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openDrawer, setOpenDrawer] = useState(false)

  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)

  const handleClose = () => {
    setOpenDrawer(false)
  }

  const [drawerComponent, setDrawerComponent] = useState<DrawerComponentProps>({
    title: 'History',
    component: <HolidayHistory requestId={0} onClose={handleClose} />,
  })

  const [data, setData] = useState<HolidaySearchItem[]>([])

  const handleSearch = (search: HolidayReportSearch) => {
    setIsLoading(true)
    setShowGrid(false)

    setTimeout(() => {
      setShowSearch(false)
    }, 500)

    myReportingService
      .getHolidaySearchReports(
        search.departmentId!,
        search.teamId!,
        search.employeeId!,
        search.actionBy!,
        format(new Date(search.fromDateRangeFrom!), 'yyyy-MM-dd'),
        format(new Date(search.fromDateRangeTo!), 'yyyy-MM-dd'),
        search.submittedDateRangeFrom !== ''
          ? format(new Date(search.submittedDateRangeFrom!), 'yyyy-MM-dd')
          : '',
        search.submittedDateRangeTo !== ''
          ? format(new Date(search.submittedDateRangeTo!), 'yyyy-MM-dd')
          : '',
        search.requestTypeId!,
        search.manualRequestTypeId!,
        search.statusId!
      )
      .then(res => {
        setData(res.requestHistory)
        setShowHeaderButtons(true)
        setShowGrid(true)
        setIsLoading(false)
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  const handleViewHistory = (requestId: number, employeeName: string) => {
    setDrawerComponent({
      ...drawerComponent,
      title: `History: ${employeeName}`,
      component: <HolidayHistory requestId={requestId} onClose={handleClose} />,
    })

    setOpenDrawer(true)
  }

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowSearch(!showSearch)
  }

  const handleRefreshClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setResetForm(!resetForm)

    setShowSearch(true)
    setShowHeaderButtons(false)
    setShowGrid(false)
  }

  return (
    <>
      {showHeaderButtons && (
        <Grid container style={{ border: '0px red solid' }}>
          <Stack
            direction="row"
            style={{ border: '0px green solid', width: '100%' }}
            spacing={2}
            display="flex"
            justifyContent="flex-end"
          >
            <FilterButton
              title="Change Filters"
              onClick={handleFilterClick}
              dataTestId="ReportingSearch-FilterBtn"
            />
            <RefreshButton onClick={handleRefreshClick} dataTestId="ReportingSearch-RefreshBtn" />
          </Stack>
        </Grid>
      )}
      <Collapse orientation="vertical" in={showSearch}>
        <HolidaySearchForm onSearch={handleSearch} resetForm={resetForm} />
      </Collapse>
      <Grid item xs={12}>
        {!isLoading && showGrid && (
          <HolidaySearchGrid onViewHistory={handleViewHistory} data={data} />
        )}
        {isLoading && (
          <LoadingIndicatorContainer>
            <LoadingIndicator show={isLoading} />
          </LoadingIndicatorContainer>
        )}
      </Grid>

      <Drawer
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        title={drawerComponent?.title || ''}
        showOptions={false}
      >
        {drawerComponent?.component}
      </Drawer>
    </>
  )
}

export default HolidaySearch
