import { useState } from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import { RootStore, useAppDispatch } from '../../redux/store'
import DateRangePicker from '../../shared/UI/DateRangePicker'
import Button from '../../shared/UI/Button'
import { RequestFieldsModal, requestFieldsDefaultState } from '../../models'
import { setDateRange } from '../../redux/reducers/timeOffRequestsReducer'
import { isMobilePortrait } from '../../theme/deviceChecks'
import { myReportingService } from '../../services/myReportingService'
import { AttendanceHistoryByDateItem } from '../../types/attendance-history-by-date-response'
import { BaseResponse } from '../../types/base-response'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../utils/errorFilter'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import HistoryGrid from '../HistoryGrid'

function History() {
  const dispatch = useAppDispatch()
  const [validationErrors, setValidationErrors] = useState<any>({})
  const { dateRange, selectedDays } = useSelector((state: RootStore) => state.timeOff)
  const [fieldsTouched, setFieldsTouched] = useState<RequestFieldsModal>(requestFieldsDefaultState)
  const mobilePortrait = useMediaQuery(isMobilePortrait())
  const [attendanceHistoryItems, setAttendanceHistoryItems] = useState<
    AttendanceHistoryByDateItem[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showGrid, setShowGrid] = useState<boolean>(false)

  const isFormValid = () => {
    setValidationErrors({})
    const errors: any = {}

    if (dateRange[0] == null) {
      errors.fromDate = true
    }

    if (dateRange[1] == null) {
      errors.toDate = true
    }

    if (Object.keys(errors).length !== 0) {
      setValidationErrors(errors)
      return
    }
    setFieldsTouched({ ...fieldsTouched, date: true, comments: true })

    if (!dateRange[0] || !dateRange[1]) {
      return false
    }
    if (
      selectedDays &&
      selectedDays.filter((day: any) => day.checked && (!day.hours || day.hours === 0)).length > 0
    ) {
      return false
    }

    return true
  }

  const handleSearch = () => {
    if (isFormValid()) {
      setIsLoading(true)
      setShowGrid(true)
      myReportingService
        .getAttendanceHistory(
          format(dateRange[0]!, 'yyyy-MM-dd'),
          format(dateRange[1]!, 'yyyy-MM-dd')
        )
        .then(res => {
          setIsLoading(false)
          setAttendanceHistoryItems(res.items)
        })
        .catch(err => {
          setIsLoading(false)
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    }
  }

  return (
    <Grid item xs={12} lg={12}>
      <Grid container spacing={4} component="form" noValidate mt={2}>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={8}>
              <DateRangePicker
                value={dateRange}
                onChange={newValue => {
                  setFieldsTouched({ ...fieldsTouched, date: true })
                  dispatch(setDateRange(newValue))
                }}
                fromDateError={validationErrors.fromDate}
                toDateError={validationErrors.toDate}
                arrange={mobilePortrait ? 'Stack' : 'Horizontal'}
              />
            </Grid>
            <Grid item xs={12} lg={4} display="flex" justifyContent="flex-end">
              <Button
                label="Search"
                loading={isLoading}
                onClick={handleSearch}
                style={{ margin: 0 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <LoadingIndicator show={isLoading} />
          {!isLoading && showGrid && <HistoryGrid items={attendanceHistoryItems} />}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default History
