import { Box, Container, useMediaQuery } from '@mui/material'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import {
  setSelectedDepartment,
  setSelectedSettings,
  setUserSettings,
} from '../../redux/reducers/appSettingsReducer'
import { RootStore } from '../../redux/store'
import { settingsService } from '../../services/settingsService'
import Sidebar, { refreshFeatureToggles } from '../../shared/layout/Sidebar'
import SnackBar from '../../shared/UI/SnackBar'
import { SettingsMaintenanceRequest } from '../../types/settings-maintenance-request'
import { isDesktopUp, isMobileDown, isTabletUp } from '../../theme/deviceChecks'
import { setSelectedDepartmentFilter } from '../../redux/reducers/myActionsReducer'

import AppRoutes from '../../routes/AppRoutes'
import { SelectOption } from '../../services/dashboardService'

interface AppBodyDivProps {
  showSideDrawer: boolean
  isMob: boolean
}

interface AppBodyContainerProps {
  isMob: boolean
}

const AppBodyDiv = styled(Box)<AppBodyDivProps>`
  background-color: #f4f5f7;
  display: flex;

  ${isMobileDown()} {
    height: ${({ showSideDrawer }) => (showSideDrawer ? '100vh' : 'auto')};
  }

  ${isTabletUp()} {
    width: ${({ isMob }) => (isMob ? '100%' : 'calc(100vw - 34px)')};
  }
`

const AppBodyContainer = styled(Container)<AppBodyContainerProps>`
  &.MuiContainer-root {
    padding: 0;
  }

  ${isTabletUp()} {
    &.MuiContainer-root {
      padding: ${({ isMob }) => (isMob ? '0' : '32px')};
    }

    &.MuiContainer-root > .MuiGrid-root:not(.mobilePageHeader) {
      padding: ${({ isMob }) => (isMob ? '0 32px 32px' : '0')};
    }
  }

  ${isDesktopUp()} {
    &.MuiContainer-root {
      padding-bottom: 16px;
    }
  }
`

export function AppBody() {
  useEffect(() => {
    refreshFeatureToggles()
  }, [])

  const dispatch = useDispatch()
  const mobile = useMediaQuery(isMobileDown())

  const showSideDrawer = useSelector<RootStore, boolean>(
    (state: RootStore) => state.appSettings.sideBarExpanded
  )

  const { departments, selectedDepartment, activeRoute, employeeDetails } = useSelector(
    (state: RootStore) => state.appSettings
  )

  const userDepartment = useSelector<RootStore, SelectOption | null>(
    (state: RootStore) => state.appSettings.loggedInEmployeeDepartment
  )

  const isUserUnallocatedDepartment = useCallback(
    (): boolean => userDepartment?.displayValue === 'Unallocated',
    [userDepartment]
  )

  useEffect(() => {
    if (!userDepartment) {
      return
    }
    if (
      departments.length > 1 &&
      departments.findIndex(dept => dept.associatedValue === 0 && dept.displayValue === 'All') < 0
    ) {
      departments.unshift({ ...departments[0], displayValue: 'All', value: 0 })
    }

    const allOption = departments.find(dept => dept.displayValue === 'All' && dept.value === 0)
    const toBeSelected = isUserUnallocatedDepartment()
      ? allOption ?? null
      : allOption ?? userDepartment

    dispatch(setSelectedDepartmentFilter(toBeSelected))
    dispatch(setSelectedDepartment(toBeSelected))
  }, [departments, dispatch, isUserUnallocatedDepartment, userDepartment])

  useEffect(() => {
    if (!employeeDetails || employeeDetails.departmentId === 0) {
      return
    }

    settingsService.getSettings(employeeDetails.departmentId).then(response => {
      const requestType: SettingsMaintenanceRequest = {
        errors: [],
        departmentId: employeeDetails.departmentId,
        isHolidayRequestAllowed: response.isHolidayRequestAllowed,
        isDayOffRequestAllowed: response.isDayOffRequestAllowed,
        isShiftRequestAllowed: response.isShiftRequestAllowed,
        hasEmailNotification: response.hasEmailNotification,
        hasEntitlementSeasonalSplit: response.hasEntitlementSeasonalSplit,
        isLieuDayRequestAllowed: response.isLieuDayRequestAllowed,
        isWfhRequestAllowed: response.isWfhRequestAllowed,
        applyHolidayChangesToRota: response.applyHolidayChangesToRota,
        isBirthdayRequestAllowed: response.isBirthdayRequestAllowed,
        isBuyRequestAllowed: response.isBuyRequestAllowed,
        isSellRequestAllowed: response.isSellRequestAllowed,
        areWeekendsWorked: response.areWeekendsWorked,
        hasEnhancements: response.hasEnhancements,
      }
      dispatch(setUserSettings(requestType))
    })
  }, [dispatch, employeeDetails, employeeDetails?.departmentId])

  useEffect(() => {
    if (!settingsService) {
      return
    }
    if (!selectedDepartment?.value) {
      return
    }
    settingsService.getSettings(selectedDepartment?.value).then(response => {
      const requestType: SettingsMaintenanceRequest = {
        errors: [],
        departmentId: selectedDepartment?.value,
        isHolidayRequestAllowed: response.isHolidayRequestAllowed,
        isDayOffRequestAllowed: response.isDayOffRequestAllowed,
        isShiftRequestAllowed: response.isShiftRequestAllowed,
        hasEmailNotification: response.hasEmailNotification,
        hasEntitlementSeasonalSplit: response.hasEntitlementSeasonalSplit,
        isLieuDayRequestAllowed: response.isLieuDayRequestAllowed,
        isWfhRequestAllowed: response.isWfhRequestAllowed,
        applyHolidayChangesToRota: response.applyHolidayChangesToRota,
        isBirthdayRequestAllowed: response.isBirthdayRequestAllowed,
        isBuyRequestAllowed: response.isBuyRequestAllowed,
        isSellRequestAllowed: response.isSellRequestAllowed,
        areWeekendsWorked: response.areWeekendsWorked,
        hasEnhancements: response.hasEnhancements,
      }
      dispatch(setSelectedSettings(requestType))
    })
  }, [dispatch, selectedDepartment])

  return (
    <AppBodyDiv showSideDrawer={showSideDrawer} isMob={isMobile}>
      <>
        <Sidebar />
        <AppBodyContainer isMob={isMobile} maxWidth="xl">
          <AppRoutes mobile={mobile || isMobile} activeRoute={activeRoute} />
        </AppBodyContainer>
      </>
      <SnackBar />
    </AppBodyDiv>
  )
}
