import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import Card from '../../../shared/layout/Card'
import Toolbar from './Toolbar/Toolbar'
import { BookingDesignerDims } from './types'
import { BookingFeature } from '../../../services/booking/types'
import DesignerDesk from './DesignerDesk/DesignerDesk'
import { BookingAvailabilityColors } from '../consts'

const DEFAULT_OFFICE_WIDTH = 30
const DEFAULT_OFFICE_HEIGHT = 30
const DEFAULT_DESK_WIDTH = 2
const DEFAULT_DESK_HEIGHT = 1
const DRAWIMG_WIDTH = 1436

// TODO: Need to calibrate desks to floorplan dimensions
const calculateProportion = (div: number, by: number) => {
  throw new Error('YOU WAS HERE')
  return (div / by) * 100
}

function Designer() {
  const [dims, setDims] = useState<BookingDesignerDims>()
  const [desks, setDesks] = useState<BookingFeature[]>([])

  return (
    <>
      <Card padding="20px">
        <Toolbar
          defaultDims={{ floorWidth: 30, floorHeight: 20, deskWidth: 2, deskHeight: 1 }}
          onDimsChange={toolbarDims => setDims(toolbarDims)}
          onSelected={(cols, rows) => {
            const desksLocal: BookingFeature[] = []
            Array(cols)
              .fill(0)
              .forEach((c, cIdx) =>
                Array(rows)
                  .fill(0)
                  .forEach((r, rIdx) => {
                    desksLocal.push({
                      id: -1,
                      floorPlanId: 0,
                      floorPlanCategoryId: 0,
                      locationId: 0,
                      typeId: 0,
                      type: '',
                      statusId: 0,
                      status: '',
                      label: '',
                      x: 225 + 75 * cIdx,
                      y: 100 * rIdx,
                      width: 50,
                      height: 75,
                      fill: '',
                      borderRadius: 10,
                      ports: [
                        {
                          id: -1,
                          featureId: -1,
                          radius: 16,
                          x: '2',
                          y: '0',
                          fill: BookingAvailabilityColors.AVAILABLE,
                        },
                      ],
                      createdById: 0,
                      additionalInfo: '',
                      zone: { id: 0, name: '', floorPlanId: 0, belongsTo: 0, additionalInfo: '' },
                    })
                  })
              )
            setDesks(desksLocal)
          }}
        />
        <Box height="1432px">
          <Box
            display="flex"
            width={`${DRAWIMG_WIDTH}px`}
            height={`${Math.round((Number(dims?.floorHeight) / Number(dims?.floorWidth)) * 100)}%`}
            border="2px solid #e1e1e1"
            borderRadius="10px"
            bgcolor="#f9f9f9"
          >
            <svg
              width="100%"
              viewBox="0 0 900 450"
              xmlns="http://www.w3.org/2000/svg"
              style={{ border: '1px solid #f00' }}
            >
              {desks.length &&
                desks.map(m => (
                  <DesignerDesk
                    featureDetail={m}
                    isActive
                    requestedSlot={{ bookingRange: [new Date(), new Date()] }}
                    isBlockBooking={false}
                    availabilityColor={BookingAvailabilityColors.AVAILABLE}
                  />
                ))}
            </svg>
          </Box>
        </Box>
      </Card>
    </>
  )
}

export default Designer
