import { Divider, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material'
import { format } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { showErrorMessage, showSuccessMessage } from '../../../../redux/reducers/snackbarReducer'
import { useAppDispatch } from '../../../../redux/store'
import { manualRequestsService } from '../../../../services/myActionsService'
import Button from '../../../../shared/UI/Button'
import DatePicker from '../../../../shared/UI/DatePicker'
import Paragraph from '../../../../shared/UI/Paragraph'
import { AbsenceOccurrence } from '../../../../types/absence'
import { AbsenceDay } from '../../../../types/absence-day'
import { BaseResponse } from '../../../../types/base-response'
import { RtwMaintenanceRequest } from '../../../../types/return-to-work'
import { getLocalDateString } from '../../../../utils/date-utils'
import UserErrorMessage from '../../../../utils/errorFilter'
import RTWPrint from '../../RTWPrint'
import { DateFormats } from '../../../../api/absence/tempTypes/generalprops'

type Props = {
  absenceData: AbsenceOccurrence
  onClose: () => void
  onCancel: () => void
  onPrint: () => void
}

function boolToString(val: boolean | string) {
  if (typeof val === 'string') {
    if (val === 'Yes') {
      return true
    }
    return false
  }
  return val ? 'Yes' : 'No'
}

function CompleteRTW({ absenceData, onClose, onCancel, onPrint }: Props) {
  const [rtwData, setRtwData] = useState<RtwMaintenanceRequest>({
    absenceId: absenceData.id,
    employee: {
      employeeId: absenceData.employeeResponse.id,
      displayName: absenceData.employeeResponse.displayName,
    },
    absenceType: {
      absenceTypeId:
        absenceData.absenceDays[absenceData.absenceDays.length - 1].absenceType.absenceTypeId || 0,
      reason:
        absenceData.absenceDays[absenceData.absenceDays.length - 1].absenceType.decryptedReason ||
        '',
      reasonType:
        absenceData.absenceDays[absenceData.absenceDays.length - 1].absenceType.reasonType ||
        'Unspecified',
    },
    returnDate: format(new Date(), 'yyyy-MM-dd'),
    proceduresFollowed: false,
    proceduresComments: '',
    recurrent: false,
    recurrentComments: '',
    medicalPractitionerConsultedType: 'Na',
    medicalAdviceComments: '',
    workRelatedAbsence: false,
    workRelatedComments: '',
    companyAction: false,
    companyActionComments: '',
    absenceEffects: '',
    additionalComments: '',
    missedShifts: absenceData.absenceDays.map((day: AbsenceDay) => ({
      absenceDate: format(new Date(day.date), 'yyyy-MM-dd'),
      hoursLost: day.hoursLost,
      shiftDescription: day.expectedShift.shiftString || '',
      sickHoursToBePaid: day.sickHoursToBePaid,
    })),
    signedDateTime: format(new Date(), DateFormats.DATE_AND_TIME),
  })
  const [savedRtwData, setSavedRtwData] = useState<RtwMaintenanceRequest>(rtwData)
  const [submissible, setSubmissible] = useState<boolean>(true)
  const [signedByHtml, setSignedByHtml] = useState<string>('')
  const didMountRef = useRef(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (didMountRef.current) {
      setSubmissible(true)
    }
    didMountRef.current = true
  }, [rtwData])

  const handleSubmit = () => {
    if (absenceData.hasValidRtw) {
      manualRequestsService
        .saveRTW(rtwData)
        .then(result => {
          dispatch(showSuccessMessage('RTW has been successfully saved'))
          onClose()
        })
        .catch(err => {
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    } else {
      manualRequestsService
        .insertRTW(rtwData)
        .then(result => {
          dispatch(showSuccessMessage('RTW has been successfully saved'))
          onClose()
        })
        .catch(err => {
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    }
  }

  useEffect(() => {
    if (absenceData.hasValidRtw) {
      manualRequestsService
        .getRTW(absenceData.id)
        .then(result => {
          setRtwData(item => ({
            ...item,
            returnDate: result.returnDate!.toLocaleString().substring(0, 10),
            medicalPractitionerConsultedType: result.medicalPractitionerConsultedType!,
            medicalAdviceComments: result.medicalAdviceComments!,
            workRelatedComments: result.workRelatedComments!,
            workRelatedAbsence: result.workRelatedAbsence!,
            proceduresComments: result.proceduresComments!,
            proceduresFollowed: result.proceduresFollowed!,
            recurrentComments: result.recurrentComments!,
            recurrent: result.recurrent!,
            companyActionComments: result.companyActionComments!,
            companyAction: result.companyAction!,
            additionalComments: result.additionalComments!,
          }))

          setSavedRtwData(item => ({
            ...item,
            returnDate: result.returnDate!.toLocaleString().substring(0, 10),
            medicalPractitionerConsultedType: result.medicalPractitionerConsultedType!,
            medicalAdviceComments: result.medicalAdviceComments!,
            workRelatedComments: result.workRelatedComments!,
            workRelatedAbsence: result.workRelatedAbsence!,
            proceduresComments: result.proceduresComments!,
            proceduresFollowed: result.proceduresFollowed!,
            recurrentComments: result.recurrentComments!,
            recurrent: result.recurrent!,
            companyActionComments: result.companyActionComments!,
            companyAction: result.companyAction!,
            additionalComments: result.additionalComments!,
          }))

          setSignedByHtml(result.signedBy!)
        })
        .catch(err => {
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    }
  }, [absenceData.hasValidRtw, absenceData.id, dispatch])

  const handlePrint = () => {
    const content = renderToString(
      <RTWPrint signedByHtml={signedByHtml} absenceData={absenceData!} rtwData={savedRtwData!} />
    )
    const winPrint: any = window.open(
      '',
      '',
      'left=0,top=0,width=384,height=900,toolbar=0,scrollbars=0,status=0'
    )
    winPrint.document.write('<html><head>')
    winPrint.document.write('<title>Return To Work</title>')
    winPrint.document.write('<link rel="stylesheet" href="../../../print.css">')
    winPrint.document.write('</head><body>')
    winPrint.document.write(content)
    winPrint.document.write('</body></html>')
    setTimeout(() => {
      winPrint.print()
    }, 500)
    setTimeout(() => {
      winPrint.close()
    }, 700)

    onPrint()
  }

  const canPrintRtw = (): boolean =>
    absenceData.absenceStatus.name?.toLowerCase().replaceAll(' ', '') === 'awaitingrtw' ||
    absenceData.hasValidRtw

  return (
    <Grid container xl={12} rowSpacing={4} pr={4} pb={4}>
      {/* Left Column */}
      <Grid item xs={12} lg={6} display="flex">
        <Grid container xl={12} alignContent="flex-start">
          {/* Date of Absence */}
          <Grid item xs={12} display="flex" mt={4}>
            <Paragraph weight="bold" padding="0 0 5px 0">
              Date of Absence
            </Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Paragraph>
              {getLocalDateString(absenceData.startDate)}
              {' - '}
              {getLocalDateString(absenceData.endDate)}
            </Paragraph>
          </Grid>
          {/* Total Hours Lost */}
          <Grid item xs={12} mt={4}>
            <Paragraph weight="bold" padding="0 0 5px 0">
              Total hours absent
            </Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Paragraph>
              {absenceData.absenceDays.reduce((a: any, b: any) => a + b.hoursLost, 0)}
            </Paragraph>
          </Grid>
          {/* Total number of sick pay hours used */}
          <Grid item xs={12} mt={4}>
            <Paragraph weight="bold" padding="0 0 5px 0">
              Total number of sick pay hours used
            </Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Paragraph>
              {absenceData.absenceDays.reduce(
                (a: any, b: any) => a + (b.sickHoursToBePaid || 0),
                0
              )}
            </Paragraph>
          </Grid>
          {/* Absence Classification */}
          <Grid item xs={12} display="flex" mt={4}>
            <Paragraph weight="bold" padding="0 0 5px 0">
              Absence Classification
            </Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Paragraph>{absenceData.absenceDays[0].absenceType.reasonTypeDescription}</Paragraph>
          </Grid>
          {/* Absence Reason */}
          <Grid item xs={12} mt={4}>
            <Paragraph weight="bold" padding="0 0 5px 0">
              Absence Reason
            </Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Paragraph>{absenceData.reason}</Paragraph>
          </Grid>
        </Grid>
      </Grid>
      {/* Right Column */}
      <Grid item xs={12} lg={6}>
        <Grid container xl={12} alignContent="flex-start">
          {/* Return to work date */}
          <Grid item xs={12} mt={5}>
            <DatePicker
              label="Return to work date"
              value={new Date(rtwData.returnDate)}
              onChange={newDate => {
                setRtwData((current: any) => {
                  const { returnDate, ...rest } = current
                  return { returnDate: newDate, ...rest }
                })
              }}
            />
            <Divider sx={{ margin: '30px 0', paddingTop: '8px' }} />
          </Grid>
          {/* Has medical advice been sought? */}
          <Grid item xs={12}>
            <Paragraph>Has medical advice been sought?</Paragraph>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={rtwData.medicalPractitionerConsultedType}
              onChange={(e, checked) => {
                setRtwData((current: any) => {
                  const { medicalPractitionerConsultedType, ...rest } = current
                  return { medicalPractitionerConsultedType: checked, ...rest }
                })
              }}
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
              <FormControlLabel value="Na" control={<Radio />} label="N/A" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} mt={2}>
            <TextField
              id="medical advice-additional-comment"
              label="Additional comment"
              variant="outlined"
              value={rtwData.medicalAdviceComments}
              onChange={e => {
                setRtwData((current: any) => {
                  const { medicalAdviceComments, ...rest } = current
                  return { medicalAdviceComments: e.target.value, ...rest }
                })
              }}
              fullWidth
              sx={{ paddingBottom: '8px' }}
            />
            <Divider sx={{ margin: '30px 0' }} />
          </Grid>

          {/* Is there any indication the absence is work related? */}
          <Grid item xs={12}>
            <Paragraph>Is there any indication the absence is work related?</Paragraph>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={boolToString(rtwData.workRelatedAbsence)}
              onChange={(e, checked) => {
                setRtwData((current: any) => {
                  const { workRelatedAbsence, ...rest } = current
                  return { workRelatedAbsence: boolToString(checked), ...rest }
                })
              }}
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} mt={2}>
            <TextField
              id="work-related-additional-comment"
              label="Additional comment"
              variant="outlined"
              value={rtwData.workRelatedComments}
              onChange={e => {
                setRtwData((current: any) => {
                  const { workRelatedComments, ...rest } = current
                  return { workRelatedComments: e.target.value, ...rest }
                })
              }}
              fullWidth
              sx={{ paddingBottom: '8px' }}
            />
            <Divider sx={{ margin: '30px 0' }} />
          </Grid>
          {/* Have all procedures been followed? */}
          <Grid item xs={12}>
            <Paragraph>Have all procedures been followed?</Paragraph>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={boolToString(rtwData.proceduresFollowed)}
              onChange={(e, checked) => {
                setRtwData((current: any) => {
                  const { proceduresFollowed, ...rest } = current
                  return { proceduresFollowed: boolToString(checked), ...rest }
                })
              }}
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} mt={2}>
            <TextField
              id="procedures-additional-comment"
              label="Additional comment"
              variant="outlined"
              value={rtwData.proceduresComments}
              onChange={e => {
                setRtwData((current: any) => {
                  const { proceduresComments, ...rest } = current
                  return { proceduresComments: e.target.value, ...rest }
                })
              }}
              fullWidth
              sx={{ paddingBottom: '8px' }}
            />
            <Divider sx={{ margin: '30px 0' }} />
          </Grid>
          {/* Has this been recurrent? */}
          <Grid item xs={12}>
            <Paragraph>Has this been recurrent?</Paragraph>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={boolToString(rtwData.recurrent)}
              onChange={(e, checked) => {
                setRtwData((current: any) => {
                  const { recurrent, ...rest } = current
                  return { recurrent: boolToString(checked), ...rest }
                })
              }}
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          {/* Additional recurrent */}
          <Grid item xs={12} mt={2}>
            <TextField
              id="recurrent-additional-comment"
              label="Additional comment"
              variant="outlined"
              value={rtwData.recurrentComments}
              onChange={e => {
                setRtwData((current: any) => {
                  const { recurrentComments, ...rest } = current
                  return { recurrentComments: e.target.value, ...rest }
                })
              }}
              fullWidth
              sx={{ paddingBottom: '8px' }}
            />
            <Divider sx={{ margin: '30px 0' }} />
          </Grid>
          {/* AIs there anything Bet365 can do to prevent future occurrences? */}
          <Grid item xs={12}>
            <Paragraph>Is there anything bet365 can do to prevent (further) recurrence?</Paragraph>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={boolToString(rtwData.companyAction)}
              onChange={(e, checked) => {
                setRtwData((current: any) => {
                  const { companyAction, ...rest } = current
                  return { companyAction: boolToString(checked), ...rest }
                })
              }}
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} mt={2}>
            <TextField
              id="anything-bet365-additional-comment"
              label="Additional comment"
              variant="outlined"
              value={rtwData.companyActionComments}
              onChange={e => {
                setRtwData((current: any) => {
                  const { companyActionComments, ...rest } = current
                  return { companyActionComments: e.target.value, ...rest }
                })
              }}
              fullWidth
              sx={{ paddingBottom: '8px' }}
            />
            <Divider sx={{ margin: '30px 0' }} />
          </Grid>
          {/* Further comments */}
          <Grid item xs={12} mt={0}>
            <TextField
              id="further-comments"
              label="Further Comments"
              variant="outlined"
              value={rtwData.additionalComments}
              onChange={e => {
                setRtwData((current: any) => {
                  const { additionalComments, ...rest } = current
                  return { additionalComments: e.target.value, ...rest }
                })
              }}
              rows={3}
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6} display="flex" justifyContent="flex-start">
        <Button
          label="Print"
          color="secondary"
          variant="outlined"
          onClick={handlePrint}
          disabled={!canPrintRtw()}
        />
      </Grid>
      <Grid item sm={6} display="flex" gap={4} justifyContent="flex-end">
        <Button label="Cancel" color="secondary" variant="outlined" onClick={onCancel} />
        <Button
          label={absenceData.hasValidRtw ? 'Re-Submit' : 'Submit'}
          onClick={handleSubmit}
          disabled={!submissible}
        />
      </Grid>
    </Grid>
  )
}

export default CompleteRTW
