import styled from 'styled-components'
import { Box, Card as MuiCard } from '@mui/material'
import CalendarBg from '../../assets/CalendarBg.png'
import CalendarBgDashboard from '../../assets/CalendarBgDashboard.png'
import theme from '../../theme/theme'

type Props = {
  children?: JSX.Element | JSX.Element[]
  sx?: React.CSSProperties
  isComingUp?: boolean
}

const StyledCard = styled(MuiCard)({
  '&.MuiPaper-root': {
    display: 'inline-flex',
    position: 'relative',
    marginBottom: '32px',
  },
  '&.MuiPaper-root:not(.comingUpStyles)': {
    borderRadius: '16px',
    backgroundColor: '#fff',

    [theme.breakpoints.up('xl')]: {
      width: '24%',
    },
    [theme.breakpoints.between('md', 'xl')]: {
      width: '32%',
    },
    [theme.breakpoints.down('md')]: {
      width: '49%',
    },
  },
  '&.comingUpStyles': {
    width: '100%',
    boxShadow: 'none',
    margin: '0',
    backgroundColor: '#f8fdfe',
  },
})

const childStyle = {
  position: 'absolute',
  height: '100%',
  width: '100%',
}

const StyledImage = styled('img')({
  width: '100%',
})

function CalendarCard({ children, sx, isComingUp }: Props) {
  return (
    <StyledCard sx={sx} className={isComingUp ? 'comingUpStyles' : ''}>
      <StyledImage alt="" src={isComingUp ? CalendarBgDashboard : CalendarBg} />

      <Box sx={childStyle}>{children}</Box>
    </StyledCard>
  )
}

export default CalendarCard
