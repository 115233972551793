import { Box, Typography } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ProgressLabelBox, ProgressLabelNumber, ProgressLabelText } from './components'

type ProgressLabelProps = {
  val: number
  label: string
}

function ProgressLabel({ val, label }: ProgressLabelProps) {
  return (
    <ProgressLabelBox>
      <ProgressLabelNumber>{val.toFixed(2)}</ProgressLabelNumber>
      <ProgressLabelText sx={{ fontSize: 18 }}>{label}</ProgressLabelText>
    </ProgressLabelBox>
  )
}

export default ProgressLabel
