import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { PlottingCalendarDay } from './PlottingCalendarDay'
import { PlottingCalendarRequest, PlottingCalendarWeekProps } from './types'
import { PlottingCalendarRow } from './components'
import { PlottingCalendarDayContainer } from './PlottingCalendarDayContainer'

export function PlottingCalendarWeek({ days, onClick }: PlottingCalendarWeekProps) {
  return (
    <PlottingCalendarRow className="plottingCalendar_dateRow">
      {days.map(d => (
        <PlottingCalendarDayContainer
          eventType={d.eventType}
          isEventEnd={d.isEventEnd}
          isEventStart={d.isEventStart}
          isPending={d.isPending}
          isMultipleRequests={d.requests && d.requests.length > 1}
          isDisabled={d.isDisabled}
          showInCalendar={d.showInCalendar}
          isBankHoliday={d.isBankHoliday}
          isToday={d.isToday}
        >
          <PlottingCalendarDay
            date={d.date}
            showInCalendar={d.showInCalendar}
            requests={d.requests}
            onClick={onClick}
            isDisabled={d.isDisabled}
          />
        </PlottingCalendarDayContainer>
      ))}
    </PlottingCalendarRow>
  )
}
