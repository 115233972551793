import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../../redux/store'
import { appSettingsState } from '../../redux/reducers/appSettingsReducer'
import { MinMaxYearRange } from '../../api/absence/tempTypes/generalprops'

export function useBookableDateRanges() {
  const { departments, employeeDetails } = useSelector<RootStore, appSettingsState>(
    (state: RootStore) => state.appSettings
  )
  const isHtl = useMemo(() => {
    const userDep = departments.find(d => d.value === employeeDetails.departmentId)
    return userDep?.syndicateType === 'Hierarchical'
  }, [departments, employeeDetails.departmentId])

  const maxYearsAhead = useMemo(
    () => (isHtl ? MinMaxYearRange.HTL_FORWARD : MinMaxYearRange.HSS_FORWARD),
    [isHtl]
  )
  const currentYear = useMemo(() => new Date().getFullYear(), [])
  const maxYearsBack = useMemo(
    () => (isHtl ? MinMaxYearRange.HTL_BACKWARD : MinMaxYearRange.HSS_BACKWARD),
    [isHtl]
  )
  const minDate = useMemo(
    () => new Date(`${currentYear - maxYearsBack}-01-01`),
    [currentYear, maxYearsBack]
  )
  const maxDate = useMemo(
    () => new Date(`${currentYear + maxYearsAhead - 1}-12-31`),
    [currentYear, maxYearsAhead]
  )

  return [minDate, maxDate]
}
