import { useCallback, useContext, useState } from 'react'
import { Grid } from '@mui/material'
import { addMonths, differenceInMonths, lastDayOfMonth } from 'date-fns'
import { useSelector } from 'react-redux'
import { EventPopup } from '../../shared/UI/EventPopup'
import { PlottingCalendar } from './PlottingCalendar'
import { PlottingCalendarRequest, PlottingCalendarsProps } from './types'
import { PopupFields, popupFieldsDefault } from '../../shared/UI/EventPopup/types'
import ViewEditOccurrence from '../MyRequests/ViewEditOccurrence'
import { availabilityService } from '../../services/availabilityService'
import { DrawerComponentProps } from '../MyRequests/MyRequestsGrid/types'
import Drawer from '../../shared/UI/Drawer'
import { PageContext } from '../../context/MyRequestsPageContext'
import { statusTranslation, typeTranslation } from '../../shared/UI/EventPopup/utils'
import CalendarCard from './CalendarCard'
import { RootStore } from '../../redux/store'
import { StyledBox } from './components'

export function PlottingCalendars({
  requestDates,
  dateRange,
  showEndDates = false,
  newRequestData,
  disabledDates,
  onBlankDayClick,
  changingYear,
  isComingUp,
  isAttendanceCalendar,
}: PlottingCalendarsProps) {
  const [popupProps, setPopupProps] = useState<PopupFields[]>([popupFieldsDefault])
  const [drawerComponent, setDrawerComponent] = useState<DrawerComponentProps | null>(null)
  const [currentRequestId, setCurrentRequestId] = useState<number>()
  const [showViewEditDrawer, setShowViewEditDrawer] = useState<boolean>(false)
  const [closeDrawerLoading, setCloseDrawerLoading] = useState<boolean>(false)

  const { bankHolidays } = useSelector((state: RootStore) => state.appSettings)

  const { getRequestsData } = useContext(PageContext)

  const closePopup = useCallback(() => {
    setPopupProps([popupFieldsDefault])
  }, [])

  const openDrawer = (
    id: number,
    absenceType: string,
    eventStatus: string | undefined,
    requestType?: string | undefined
  ) => {
    setCurrentRequestId(id)
    setShowViewEditDrawer(true)
    const isCancellationExists = newRequestData?.some(res => res.id === id && res.isCancellation)

    setDrawerComponent({
      title: requestType ?? absenceType,
      component: (
        <ViewEditOccurrence
          id={id}
          requestType={requestType ?? absenceType}
          closeViewEditDrawer={async () => {
            setCloseDrawerLoading(true)
            await availabilityService.deleteRequestLock(id)
            setCloseDrawerLoading(false)
            setShowViewEditDrawer(false)
          }}
          isCancellation={isCancellationExists}
          closeLoading={closeDrawerLoading}
          isHolidayManualRequest={requestType === 'Holiday (M)'}
        />
      ),
      status: isCancellationExists ? 'Cancellation Requested' : eventStatus,
    })
  }

  const calculateStatusLabel = (found: PlottingCalendarRequest) => {
    if (!found.statusDescription) return

    if (found.isQueried) {
      return 'Queried'
    }

    if (found.statusDescription.toLowerCase() === 'change requested' && found.isCancellation) {
      return 'Cancellation Requested'
    }

    return statusTranslation(found.statusDescription)
  }

  const handleOnClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    date: Date,
    requests?: PlottingCalendarRequest[]
  ) => {
    if (requests?.length) {
      // A date was clicked with events on it
      const events: PopupFields[] = []
      requests.map(m =>
        events.push({
          open: true,
          anchorEl: e.currentTarget,
          id: m.id || 0,
          requestType: m.requestType,
          eventType: typeTranslation(m.requestType),
          hours: m.days.reduce((a, b) => a + b.hours, 0) || 0,
          eventStatus: m.isCancellation ? 'Cancellation Requested' : calculateStatusLabel(m),
        })
      )
      setPopupProps(events)
    } else {
      // No event, so bubble-up to HOC
      onBlankDayClick?.(date)
    }
  }
  return (
    <StyledBox onMouseLeave={closePopup} date-testid="dashboard_calendar">
      <EventPopup
        fields={popupProps}
        baseUrl="myavailability"
        newRequestData={newRequestData}
        onClose={closePopup}
        onDrawerOpen={(id, absenceType, status, requestType) => {
          closePopup()
          openDrawer(id, absenceType, status, requestType)
        }}
        isAttendanceCalendar={isAttendanceCalendar}
        isComingUp={isComingUp}
      />
      <Drawer
        isOpen={showViewEditDrawer}
        onClose={async () => {
          await availabilityService.deleteRequestLock(currentRequestId!)
          setCurrentRequestId(undefined)
          getRequestsData()
          setShowViewEditDrawer(false)
        }}
        title={drawerComponent?.title || ''}
        status={drawerComponent?.status || ''}
        showOptions={false}
        mobileHeight={
          drawerComponent?.title === 'Day Off' || drawerComponent?.title === 'Shift'
            ? '620px'
            : undefined
        }
      >
        <Grid item p={4}>
          {drawerComponent?.component}
        </Grid>
      </Drawer>
      {new Array(dateRange.length).fill(0).map((_m, idx) => {
        const currMonth = addMonths(
          new Date(dateRange.start.getFullYear(), dateRange.start.getMonth(), 1),
          idx
        )
        return (
          <CalendarCard
            key={`${currMonth.getMonth()}-${currMonth.getDay()}-${currMonth.getFullYear()}`}
            isComingUp={isComingUp}
            isAttendanceCalendar={isAttendanceCalendar}
          >
            <PlottingCalendar
              date={currMonth}
              requestDates={requestDates.filter(
                f =>
                  f.date.getFullYear() === currMonth.getFullYear() &&
                  f.date.getMonth() === currMonth.getMonth()
              )}
              showEndDates={showEndDates}
              disabledDates={disabledDates.filter(
                dd =>
                  dd.getFullYear() === currMonth.getFullYear() &&
                  dd.getMonth() === currMonth.getMonth()
              )}
              onClick={handleOnClick}
              onMouseEnter={closePopup}
              changingYear={changingYear}
              bankHolidays={bankHolidays}
              isComingUp={isComingUp}
            />
          </CalendarCard>
        )
      })}
    </StyledBox>
  )
}
