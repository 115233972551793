import { useMemo } from 'react'
import { Grid, SxProps } from '@mui/material'
import LoadingIndicator from '.'
import NoDataFound from '../NoDataFound'
import {
  ComponentContainer,
  ComponentLoadingContainer,
  GridLoadingComponent,
  LoadingComponent,
} from './components'

type Props = {
  children: JSX.Element | JSX.Element[]
  loading: boolean
  bgColor?: string
  noData?: boolean
  removeBorderRadius?: boolean
  loadingHeight?: string
  sx?: SxProps
  grid?: boolean
  loadingBoxShadow?: boolean
}

function FullComponentLoadingIcon({
  children,
  loading,
  bgColor,
  noData,
  removeBorderRadius,
  loadingHeight,
  sx,
  grid,
  loadingBoxShadow,
}: Props) {
  const loadingContent = useMemo(
    () => (
      <>
        <ComponentContainer hide={loading || Boolean(noData)}>{children}</ComponentContainer>
        {loading && (
          <ComponentLoadingContainer>
            <LoadingIndicator show alignItems="center" />
          </ComponentLoadingContainer>
        )}
        {noData && !loading && (
          <ComponentLoadingContainer>
            <NoDataFound show />
          </ComponentLoadingContainer>
        )}
      </>
    ),
    [children, loading, noData]
  )

  return (
    <>
      {grid ? (
        <GridLoadingComponent
          container
          bgColor={bgColor}
          removeBorderRadius={removeBorderRadius}
          loadingHeight={loading ? loadingHeight : undefined}
          sx={sx}
        >
          <Grid item xs={12}>
            {loadingContent}
          </Grid>
        </GridLoadingComponent>
      ) : (
        <LoadingComponent
          bgColor={bgColor}
          removeBorderRadius={removeBorderRadius}
          loadingHeight={loading ? loadingHeight : undefined}
          loadingBoxShadow={loading ? loadingBoxShadow : undefined}
          sx={sx}
        >
          {loadingContent}
        </LoadingComponent>
      )}
    </>
  )
}

export default FullComponentLoadingIcon
