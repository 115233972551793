import { httpServiceV1 } from './httpServiceV1'
import {
  ThresholdWeeks,
  ThresholdWeekIdRequest,
  HolidayRestrictionCreateRequest,
  LocationPutRequest,
} from '../components/SettingsOptions/settingsTypes'
import { apiRequestDefaultThresholds } from '../types/default-threshold-update-request'
import { SettingsResponse } from '../types/settings-response'
import { SettingsMaintenanceRequest } from '../types/settings-maintenance-request'
import { HolidayRestrictionResponse } from '../types/holiday-restrictions-response'
import { HolidayRestrictionCreateResponse } from '../types/holiday-restriction-create-response'
import { BaseResponse } from '../types/base-response'
import { DepartmentThresholdsResponse } from '../types/department-thresholds-response'
import { ThresholdWekInsertResponse } from '../types/threshold-week-insert-response'
import { AbsenceLocationsResponse } from '../types/absence-locations-response'

const requestsBaseURL = 'Settings'

const settingsService = {
  getSettings: async (deptId: number): Promise<SettingsResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/?DepartmentId=${deptId}`),

  putSettings: async (body: SettingsMaintenanceRequest): Promise<void> =>
    httpServiceV1.put(`${requestsBaseURL}`, body),

  getRestrictions: async (deptId: number): Promise<HolidayRestrictionResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/Restrictions?DepartmentId=${deptId}`),

  postRestriction: async (
    body: HolidayRestrictionCreateRequest
  ): Promise<HolidayRestrictionCreateResponse> =>
    httpServiceV1.post(`${requestsBaseURL}/Restrictions`, body),

  deleteRestriction: async (restrictionId: number): Promise<BaseResponse> =>
    httpServiceV1.del(
      `${requestsBaseURL}/Restrictions/DeleteHolidayRestriction?RestrictionId=${restrictionId}`
    ),

  getThresholds: async (deptId: number): Promise<DepartmentThresholdsResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/Thresholds?DepartmentId=${deptId}`),

  putThresholds: async (body: apiRequestDefaultThresholds): Promise<BaseResponse> =>
    httpServiceV1.put(`${requestsBaseURL}/Thresholds`, body),

  deleteThresholdWeek: async (body: ThresholdWeekIdRequest): Promise<BaseResponse> =>
    httpServiceV1.delWithBody(`${requestsBaseURL}/ThresholdWeek`, body),

  postThresholdWeek: async (body: ThresholdWeeks): Promise<ThresholdWekInsertResponse> =>
    httpServiceV1.post(`${requestsBaseURL}/ThresholdWeek`, body),

  getLocation: async (): Promise<AbsenceLocationsResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/Location`),

  putLocation: async (body: LocationPutRequest): Promise<BaseResponse> =>
    httpServiceV1.put(`${requestsBaseURL}/Location`, body),
}

export { settingsService }
