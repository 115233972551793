import { Grid, TextField } from '@mui/material'
import styled from 'styled-components'
import { DetailProps } from './types'

const StyledTextField = styled(TextField)`
  input.MuiInputBase-input {
    -webkit-text-fill-color: #747474;
  }
`

function HolidaysEmployeeRequestBulgariaDetail({ data }: DetailProps) {
  return (
    <>
      <Grid container mt={4} spacing={4} pr={4}>
        <Grid item xs={12}>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Job Title" value={data.jobTitle} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Department" value={data.department} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Team" value={data.team} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Request Type" value={data.requestType} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField
                  fullWidth
                  label="Request Dates"
                  value={data.requestDates}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField
                  fullWidth
                  label="Request Status"
                  value={data.requestStatus}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField
                  fullWidth
                  label="Request Submitted"
                  value={data.submitted}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default HolidaysEmployeeRequestBulgariaDetail
