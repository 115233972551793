import {
  DepartmentValuesResponse,
  Employee,
  SelectOption,
  SelectValues,
  Team,
  Department,
  CurrentEntitlementPeriodResponse,
  CalendarDetailsResponse,
  EntitlementDateValue,
} from '../../services/dashboardService'
import { BankHoliday } from '../../types/bank-holiday'
import { SettingsMaintenanceRequest } from '../../types/settings-maintenance-request'
import { SettingsResponse } from '../../types/settings-response'
import { EmployeeDetailsResponse } from '../../types/employee'
import { MyEntitlementResponse } from '../../types/my-entitlement-response'

export interface SetShowModalPayload {
  title: string
  message: string
  buttonLabel: string
  type: 'clash' | 'question'
  showModal: boolean
  isDisabled?: boolean
}

export interface appSettingsState {
  activeRoute: string
  sideBarExpanded: boolean
  modalProps: SetShowModalPayload
  showDrawer: boolean
  showAbsenceDrawer: boolean
  showHolidaySnapshotDrawer: boolean
  showMyRequestsDrawer: boolean
  showHeaderDrawer: boolean
  selectValues: SelectValues[]
  bankHolidays: BankHoliday[]
  employeeDetails: EmployeeDetailsResponse
  departments: SelectOption[]
  employees: Employee[]
  departmentTeams: Team[]
  departmentValuesResponse?: DepartmentValuesResponse
  currentEntitlementPeriodResponse?: CurrentEntitlementPeriodResponse
  entitlement?: { year: SelectOption; data: MyEntitlementResponse }[]
  calendarDetailsResponse?: CalendarDetailsResponse
  employeeDepartment?: Department | null
  selectedDepartment?: SelectOption | null
  selectedUserDepartment?: SelectOption | null
  selectedTeam: Team | null
  selectedTeams: Team[] | null
  allEmployees: Employee[]
  directReports: Employee[]
  allTeams: Team[]
  isLoading: boolean
  selectedSettings: SettingsResponse | null
  userSettings: SettingsResponse | null
  entitlementValues: EntitlementDateValue[] | null
  loggedInEmployeeDepartment: SelectOption | null
  loggedInEmployeeTeam: Team | null
}

export const initialState: appSettingsState = {
  activeRoute: '/dashboard',
  sideBarExpanded: false,
  modalProps: {
    title: '',
    message: '',
    buttonLabel: '',
    type: 'clash',
    showModal: false,
  },
  showDrawer: false,
  showAbsenceDrawer: false,
  showHolidaySnapshotDrawer: false,
  showMyRequestsDrawer: false,
  showHeaderDrawer: false,
  employeeDetails: {
    employeeForename: '',
    employeeSurname: '',
    employeeId: 0,
    departmentId: 0,
    teamId: 0,
    isContractor: false,
    isHtl: false,
    location: { id: 0, name: '' },
  },
  selectValues: [],
  bankHolidays: [],
  departments: [],
  employees: [],
  allTeams: [],
  allEmployees: [],
  directReports: [],
  departmentTeams: [],
  isLoading: false,
  selectedTeam: null,
  selectedTeams: null,
  selectedSettings: null,
  userSettings: null,
  entitlementValues: null,
  loggedInEmployeeDepartment: null,
  loggedInEmployeeTeam: null,
}

// Action Types
const SET_ACTIVE_ROUTE = 'SET_ACTIVE_ROUTE'
const SET_SIDEBAR_EXPANDED = 'SET_SIDEBAR_EXPANDED'
const SET_SHOW_MODAL = 'SET_SHOW_MODAL'
const HIDE_DRAWER = 'HIDE_DRAWER'
const HIDE_ABSENCE_DRAWER = 'HIDE_ABSENCE_DRAWER'
const HIDE_MY_REQUESTS_DRAWER = 'HIDE_MY_REQUESTS_DRAWER'
const SHOW_DRAWER = 'SHOW_DRAWER'
const DISPLAY_ABSENCE_DRAWER = 'DISPLAY_ABSENCE_DRAWER'
const SHOW_HOLIDAY_SNAPSHOT_DRAWER = 'SHOW_HOLIDAY_SNAPSHOT_DRAWER'
const HIDE_HOLIDAY_SNAPSHOT_DRAWER = 'HIDE_HOLIDAY_SNAPSHOT_DRAWER'
const SHOW_MY_REQUESTS_DRAWER = 'SHOW_MY_REQUESTS_DRAWER'
const HIDE_HEADER_DRAWER = 'HIDE_HEADER_DRAWER'
const SHOW_HEADER_DRAWER = 'SHOW_HEADER_DRAWER'
const SET_SELECT_VALUES = 'SET_SELECT_VALUES'
const SET_EMPLOYEE_DETAILS = 'SET_EMPLOYEE_DETAILS'
const SET_BANK_HOLIDAYS = 'SET_BANK_HOLIDAYS'
const SET_DEPARTMENTS = 'SET_DEPARTMENTS'
const SET_EMPLOYEES = 'SET_EMPLOYEES'
const SET_TEAMS = 'SET_TEAMS'
const SET_DEPARTMENTS_RESPONSE = 'SET_DEPARTMENTS_RESPONSE'
const SET_CURRENT_ENTITLEMENT_PERIOD_RESPONSE = 'SET_CURRENT_ENTITLEMENT_PERIOD_RESPONSE'
const SET_ENTITLEMENT = 'SET_ENTITLEMENT'
const SET_CALENDAR_DETAILS_RESPONSE = 'SET_CALENDAR_DETAILS_RESPONSE'
const SET_SELECTED_DEPARTMENT = 'SET_SELECTED_DEPARTMENT'
const SET_USER_SELECTED_DEPARTMENT = 'SET_USER_SELECTED_DEPARTMENT'
const SET_SELECTED_TEAM = 'SET_SELECTED_TEAM'
const SET_SELECTED_TEAMS = 'SET_SELECTED_TEAMS'
const SET_IS_LOADING = 'SET_IS_LOADING'
const SET_SELECTED_SETTINGS = 'SET_SELECTED_SETTINGS'
const SET_USER_SETTINGS = 'SET_USER_SETTINGS'
const SET_LOGGED_EMPLOYEE_DEPARTMENT = 'SET_LOGGED_EMPLOYEE_DEPARTMENT'
const SET_LOGGED_EMPLOYEE_TEAM = 'SET_LOGGED_EMPLOYEE_TEAM'

// Action Creators
interface SetActiveRouteAction {
  type: typeof SET_ACTIVE_ROUTE
  payload: string
}

interface SetSidebarExpanded {
  type: typeof SET_SIDEBAR_EXPANDED
  payload: boolean
}

interface hideDrawer {
  type: typeof HIDE_DRAWER
  payload: boolean
}

interface hideAbsenceDrawer {
  type: typeof HIDE_ABSENCE_DRAWER
  payload: boolean
}

interface hideMyRequestsDrawer {
  type: typeof HIDE_MY_REQUESTS_DRAWER
  payload: boolean
}

interface displayAbsenceDrawer {
  type: typeof DISPLAY_ABSENCE_DRAWER
  payload: boolean
}

interface showDrawer {
  type: typeof SHOW_DRAWER
  payload: boolean
}

interface showHolidaySnapshotDrawer {
  type: typeof SHOW_HOLIDAY_SNAPSHOT_DRAWER
  payload: boolean
}

interface hideHolidaySnapshotDrawer {
  type: typeof HIDE_HOLIDAY_SNAPSHOT_DRAWER
  payload: boolean
}

interface showMyRequestsDrawer {
  type: typeof SHOW_MY_REQUESTS_DRAWER
  payload: boolean
}

interface hideHeaderDrawerAction {
  type: typeof HIDE_HEADER_DRAWER
  payload: boolean
}

interface showHeaderDrawerAction {
  type: typeof SHOW_HEADER_DRAWER
  payload: boolean
}

interface SetShowModal {
  type: typeof SET_SHOW_MODAL
  payload: SetShowModalPayload
}

interface SetSelectValuesAction {
  type: typeof SET_SELECT_VALUES
  payload: SelectValues[]
}

interface SetEmployeeDetailsAction {
  type: typeof SET_EMPLOYEE_DETAILS
  payload: EmployeeDetailsResponse
}

interface SetBankHolidays {
  type: typeof SET_BANK_HOLIDAYS
  payload: BankHoliday[]
}

interface SetDepartmentsAction {
  type: typeof SET_DEPARTMENTS
  payload: SelectOption[]
}

interface SetEmployeesAction {
  type: typeof SET_EMPLOYEES
  payload: Employee[]
}

interface SetTeamsAction {
  type: typeof SET_TEAMS
  payload: Team[]
}

interface SetDepartmentsResponseAction {
  type: typeof SET_DEPARTMENTS_RESPONSE
  payload: DepartmentValuesResponse
}

interface SetCurrentEntitlementPeriodResponse {
  type: typeof SET_CURRENT_ENTITLEMENT_PERIOD_RESPONSE
  payload: CurrentEntitlementPeriodResponse
}

interface SetEntitlement {
  type: typeof SET_ENTITLEMENT
  payload: { year: SelectOption; data: MyEntitlementResponse }[]
}

interface SetCalendarDetailsResponse {
  type: typeof SET_CALENDAR_DETAILS_RESPONSE
  payload: CalendarDetailsResponse
}

interface SetSelectedDepartmentAction {
  type: typeof SET_SELECTED_DEPARTMENT
  payload: SelectOption | null
}

interface SetUserSelectedDepartmentAction {
  type: typeof SET_USER_SELECTED_DEPARTMENT
  payload: SelectOption | null
}

interface SetSelectedTeamAction {
  type: typeof SET_SELECTED_TEAM
  payload: Team | null
}

interface SetSelectedTeamsAction {
  type: typeof SET_SELECTED_TEAMS
  payload: Team[] | null
}

interface SetIsLoadingAction {
  type: typeof SET_IS_LOADING
  payload: boolean
}

interface SetSelectedSettingsAction {
  type: typeof SET_SELECTED_SETTINGS
  payload: SettingsResponse | null
}

interface SetUserSettingsAction {
  type: typeof SET_USER_SETTINGS
  payload: SettingsResponse | null
}

interface SetLoggedEmployeeDepartmentAction {
  type: typeof SET_LOGGED_EMPLOYEE_DEPARTMENT
  payload: SelectOption | null
}

interface SetLoggedEmployeeTeamAction {
  type: typeof SET_LOGGED_EMPLOYEE_TEAM
  payload: Team | null
}

type appSettingsDispatchtypes =
  | SetActiveRouteAction
  | SetSidebarExpanded
  | SetShowModal
  | hideDrawer
  | hideAbsenceDrawer
  | hideMyRequestsDrawer
  | hideHolidaySnapshotDrawer
  | showDrawer
  | displayAbsenceDrawer
  | showMyRequestsDrawer
  | showHolidaySnapshotDrawer
  | SetSelectValuesAction
  | SetEmployeeDetailsAction
  | SetBankHolidays
  | SetDepartmentsAction
  | SetEmployeesAction
  | SetTeamsAction
  | SetDepartmentsResponseAction
  | SetCurrentEntitlementPeriodResponse
  | SetEntitlement
  | SetCalendarDetailsResponse
  | SetSelectedTeamAction
  | SetSelectedDepartmentAction
  | SetUserSelectedDepartmentAction
  | SetIsLoadingAction
  | showHeaderDrawerAction
  | hideHeaderDrawerAction
  | SetSelectedTeamsAction
  | SetSelectedSettingsAction
  | SetUserSettingsAction
  | SetLoggedEmployeeDepartmentAction
  | SetLoggedEmployeeTeamAction

// Actions
export const setActiveRoute = (route: string): SetActiveRouteAction => ({
  type: SET_ACTIVE_ROUTE,
  payload: route,
})
export const setSidebarExpanded = (expanded: boolean): SetSidebarExpanded => ({
  type: SET_SIDEBAR_EXPANDED,
  payload: expanded,
})

export const hideDrawer = (): hideDrawer => ({
  type: HIDE_DRAWER,
  payload: false,
})

export const hideAbsenceDrawer = (): hideAbsenceDrawer => ({
  type: HIDE_ABSENCE_DRAWER,
  payload: false,
})

export const hideMyRequestsDrawer = (): hideMyRequestsDrawer => ({
  type: HIDE_MY_REQUESTS_DRAWER,
  payload: false,
})

export const hideHolidaySnapshotDrawer = (): hideHolidaySnapshotDrawer => ({
  type: HIDE_HOLIDAY_SNAPSHOT_DRAWER,
  payload: false,
})

export const showDrawer = (): showDrawer => ({
  type: SHOW_DRAWER,
  payload: true,
})

export const displayAbsenceDrawer = (): displayAbsenceDrawer => ({
  type: DISPLAY_ABSENCE_DRAWER,
  payload: true,
})

export const showMyRequestsDrawer = (): showMyRequestsDrawer => ({
  type: SHOW_MY_REQUESTS_DRAWER,
  payload: true,
})

export const showHolidaySnapshotDrawer = (): showHolidaySnapshotDrawer => ({
  type: SHOW_HOLIDAY_SNAPSHOT_DRAWER,
  payload: true,
})

export const hideHeaderDrawer = (): hideHeaderDrawerAction => ({
  type: HIDE_HEADER_DRAWER,
  payload: false,
})

export const showHeaderDrawer = (): showHeaderDrawerAction => ({
  type: SHOW_HEADER_DRAWER,
  payload: true,
})

export const showModalDialog = ({
  title,
  message,
  buttonLabel,
  type,
}: SetShowModalPayload): SetShowModal => ({
  type: SET_SHOW_MODAL,
  payload: {
    title,
    message,
    buttonLabel,
    type,
    showModal: true,
    isDisabled: false,
  },
})

export const hideModal = (): SetShowModal => ({
  type: SET_SHOW_MODAL,
  payload: {
    title: '',
    message: '',
    buttonLabel: '',
    type: 'clash',
    showModal: false,
  },
})

export const setSelectValues = (selectValues: SelectValues[]): SetSelectValuesAction => ({
  type: SET_SELECT_VALUES,
  payload: selectValues,
})

export const setEmployeeDetails = (
  employeeDetails: EmployeeDetailsResponse
): SetEmployeeDetailsAction => ({
  type: SET_EMPLOYEE_DETAILS,
  payload: employeeDetails,
})

export const setBankHolidays = (bankHolidays: BankHoliday[]): SetBankHolidays => ({
  type: SET_BANK_HOLIDAYS,
  payload: bankHolidays,
})

export const setDepartments = (departments: SelectOption[]): SetDepartmentsAction => ({
  type: SET_DEPARTMENTS,
  payload: departments,
})

export const setEmployees = (employees: Employee[]): SetEmployeesAction => ({
  type: SET_EMPLOYEES,
  payload: employees,
})

export const setTeamss = (teams: Team[]): SetTeamsAction => ({
  type: SET_TEAMS,
  payload: teams,
})

export const setDepartmentsResponse = (
  response: DepartmentValuesResponse
): SetDepartmentsResponseAction => ({
  type: SET_DEPARTMENTS_RESPONSE,
  payload: response,
})

export const setCurrentEntitlementPeriodResponse = (
  response: CurrentEntitlementPeriodResponse
): SetCurrentEntitlementPeriodResponse => ({
  type: SET_CURRENT_ENTITLEMENT_PERIOD_RESPONSE,
  payload: response,
})

export const setEntitlement = (
  response: { year: SelectOption; data: MyEntitlementResponse }[]
): SetEntitlement => ({
  type: SET_ENTITLEMENT,
  payload: response,
})

export const setCalendarDetails = (
  response: CalendarDetailsResponse
): SetCalendarDetailsResponse => ({
  type: SET_CALENDAR_DETAILS_RESPONSE,
  payload: response,
})

export const setSelectedDepartment = (
  departmentId: SelectOption | null
): SetSelectedDepartmentAction => ({
  type: SET_SELECTED_DEPARTMENT,
  payload: departmentId,
})

export const setUserSelectedDepartment = (
  departmentId: SelectOption | null
): SetUserSelectedDepartmentAction => ({
  type: SET_USER_SELECTED_DEPARTMENT,
  payload: departmentId,
})

export const setSelectedTeam = (teamId: Team | null): SetSelectedTeamAction => ({
  type: SET_SELECTED_TEAM,
  payload: teamId,
})

export const setSelectedTeams = (teams: Team[] | null): SetSelectedTeamsAction => ({
  type: SET_SELECTED_TEAMS,
  payload: teams,
})

export const setSelectedSettings = (
  checked: SettingsMaintenanceRequest | null
): SetSelectedSettingsAction => ({
  type: SET_SELECTED_SETTINGS,
  payload: checked,
})

export const setUserSettings = (
  checked: SettingsMaintenanceRequest | null
): SetUserSettingsAction => ({
  type: SET_USER_SETTINGS,
  payload: checked,
})

export const setIsLoading = (isLoading: boolean): SetIsLoadingAction => ({
  type: SET_IS_LOADING,
  payload: isLoading,
})

export const setLoggedEmployeeDepartment = (
  dept: SelectOption
): SetLoggedEmployeeDepartmentAction => ({
  type: SET_LOGGED_EMPLOYEE_DEPARTMENT,
  payload: dept,
})

export const setLoggedEmployeeTeam = (team: Team | null): SetLoggedEmployeeTeamAction => ({
  type: SET_LOGGED_EMPLOYEE_TEAM,
  payload: team,
})

// Reducer
export const appSettingsReducer = (
  state: appSettingsState = initialState,
  action: appSettingsDispatchtypes
): appSettingsState => {
  switch (action.type) {
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload }
    case SET_ACTIVE_ROUTE:
      return { ...state, activeRoute: action.payload }
    case SET_SIDEBAR_EXPANDED:
      return { ...state, sideBarExpanded: action.payload }
    case HIDE_DRAWER:
      return { ...state, showDrawer: action.payload }
    case HIDE_ABSENCE_DRAWER:
      return { ...state, showAbsenceDrawer: action.payload }
    case HIDE_MY_REQUESTS_DRAWER:
      return { ...state, showMyRequestsDrawer: action.payload }
    case HIDE_HOLIDAY_SNAPSHOT_DRAWER:
      return { ...state, showHolidaySnapshotDrawer: action.payload }
    case SHOW_DRAWER:
      return { ...state, showDrawer: action.payload }
    case DISPLAY_ABSENCE_DRAWER:
      return { ...state, showAbsenceDrawer: action.payload }
    case SHOW_MY_REQUESTS_DRAWER:
      return { ...state, showMyRequestsDrawer: action.payload }
    case SHOW_HOLIDAY_SNAPSHOT_DRAWER:
      return { ...state, showHolidaySnapshotDrawer: action.payload }
    case HIDE_HEADER_DRAWER:
      return { ...state, showHeaderDrawer: action.payload }
    case SHOW_HEADER_DRAWER:
      return { ...state, showHeaderDrawer: action.payload }
    case SET_SELECTED_SETTINGS:
      return { ...state, selectedSettings: action.payload }
    case SET_USER_SETTINGS:
      return { ...state, userSettings: action.payload }

    case SET_SHOW_MODAL:
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          title: action.payload.title,
          buttonLabel: action.payload.buttonLabel,
          message: action.payload.message,
          type: action.payload.type,
          showModal: action.payload.showModal,
          isDisabled: action.payload.isDisabled,
        },
      }
    case SET_SELECT_VALUES:
      return { ...state, selectValues: action.payload }
    case SET_BANK_HOLIDAYS:
      return { ...state, bankHolidays: action.payload }
    case SET_DEPARTMENTS:
      return { ...state, departments: action.payload }
    case SET_EMPLOYEES:
      return { ...state, employees: action.payload }
    case SET_EMPLOYEE_DETAILS:
      return { ...state, employeeDetails: action.payload }
    case SET_CURRENT_ENTITLEMENT_PERIOD_RESPONSE: {
      return { ...state, currentEntitlementPeriodResponse: action.payload }
    }
    case SET_ENTITLEMENT: {
      return { ...state, entitlement: action.payload }
    }
    case SET_CALENDAR_DETAILS_RESPONSE: {
      return { ...state, calendarDetailsResponse: action.payload }
    }
    case SET_DEPARTMENTS_RESPONSE: {
      const departments: SelectOption[] = action.payload.departments.map(department => ({
        displayValue: department.departmentName,
        value: department.departmentId,
        syndicateType: department.managementModel,
      }))

      const employees: Employee[] = action.payload.employees
        .map(employee => {
          const ed = action.payload.departments.find(d => d.departmentId === employee.departmentId)

          return {
            departmentId: employee.departmentId,
            employeeId: employee.employeeId,
            employeeName: employee.employeeName,
            teamId: employee.teamId ?? 0,
            entitlementPeriod: employee.entitlementPeriod,
            entitlementYearDates: action.payload.entitlementDates.find(
              d =>
                d.entitlementPeriod === employee.entitlementPeriod &&
                d.syndicateType === ed?.managementModel
            ),
            syndicateType: ed?.managementModel,
            locationGroupName: employee.locationGroupName,
            directReport: employee.isInHierarchy,
          }
        })
        .sort((a, b) => (a.employeeName.toLowerCase() > b.employeeName.toLowerCase() ? 0 : -1))

      const teams: Team[] = action.payload.teams.map(team => ({
        departmentId: team.departmentId,
        teamId: team.teamId,
        teamName: team.teamName,
      }))

      const directReports = employees
        .filter(emp => emp.directReport === true)
        .sort((a, b) => (a.employeeName.toLowerCase() > b.employeeName.toLowerCase() ? 0 : -1))

      return {
        ...state,
        departments,
        allEmployees: employees,
        allTeams: teams,
        directReports,
        entitlementValues: action.payload.entitlementDates,
      }
    }
    case SET_SELECTED_DEPARTMENT: {
      const teamsData = Array.from(
        new Set(state.allTeams.filter(employee => employee.departmentId === action.payload?.value))
      )
      return {
        ...state,
        departmentTeams: teamsData,
        selectedDepartment: action.payload,
        selectedTeam: null,
      }
    }
    case SET_USER_SELECTED_DEPARTMENT: {
      const teamsData = Array.from(
        new Set(state.allTeams.filter(employee => employee.departmentId === action.payload?.value))
      )
      return {
        ...state,
        departmentTeams: teamsData,
        selectedDepartment: action.payload,
        selectedTeam: null,
      }
    }

    case SET_SELECTED_TEAM: {
      const employeeData = Array.from(
        new Set(
          state.allEmployees.filter(
            employee =>
              employee.departmentId === state.selectedDepartment?.value &&
              employee.teamId === action.payload?.teamId
          )
        )
      )
      return { ...state, employees: employeeData, selectedTeam: action.payload }
    }
    case SET_SELECTED_TEAMS: {
      const teamIds = action.payload?.map(team => team.teamId)
      const employeeData = Array.from(
        new Set(
          state.allEmployees.filter(
            employee =>
              employee.departmentId === state.selectedDepartment?.value &&
              teamIds?.includes(employee.teamId)
          )
        )
      )
      return { ...state, employees: employeeData, selectedTeams: action.payload }
    }
    case SET_LOGGED_EMPLOYEE_DEPARTMENT:
      return { ...state, loggedInEmployeeDepartment: action.payload }
    case SET_LOGGED_EMPLOYEE_TEAM:
      return { ...state, loggedInEmployeeTeam: action.payload }
    default:
      return state
  }
}
