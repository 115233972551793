import { CircularProgress } from '@mui/material'
import React from 'react'
import { LoadingContainer } from './components'
import { LoadingIndicatorProps } from './types'

function LoadingIndicator({ show, alignItems = 'flex-start', margin = '' }: LoadingIndicatorProps) {
  return show ? (
    <LoadingContainer alignItems={alignItems} margin={margin}>
      <CircularProgress />
    </LoadingContainer>
  ) : null
}

export default LoadingIndicator
