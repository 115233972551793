import PersonIcon from '@mui/icons-material/Person'
import { Stack, useMediaQuery } from '@mui/material'
import { isMobileDown } from '../../../theme/deviceChecks'
import theme from '../../../theme/theme'
import Paragraph from '../Paragraph'
import StatusLabel from '../StatusLabel'
import BackButton from './BackButton'

type Props = {
  firstname?: string | null | undefined
  surname?: string | null | undefined
  displayName?: string | null | undefined
  status: string | undefined
  containerStyle?: object
  showBackButton?: boolean
  requestId?: number
  dateOfSubmittion?: Date
}

function InstanceStatusBanner({
  firstname,
  surname,
  displayName,
  status,
  containerStyle,
  showBackButton,
  dateOfSubmittion,
}: Props) {
  const fullName = displayName || firstname?.concat(' ', surname!)
  const formattedDate = dateOfSubmittion ? new Date(dateOfSubmittion).toLocaleDateString() : '-'
  const mobile = useMediaQuery(isMobileDown)

  return (
    <>
      {!mobile ? (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap={4}
          {...containerStyle}
        >
          {showBackButton && <BackButton isMobile={mobile} />}
          <Paragraph
            color={theme.palette.text.primary}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <PersonIcon sx={{ color: theme.palette.text.primary, marginRight: '8px' }} />
            {fullName}
          </Paragraph>
          <StatusLabel status={status} />
        </Stack>
      ) : (
        <>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            {...containerStyle}
            pl={1}
            pr={1}
            mt={2}
          >
            {showBackButton && <BackButton isMobile={mobile} />}
            <Paragraph color="#000" size="16px" weight="bold">
              {fullName}
            </Paragraph>
            <Paragraph style={{ marginBottom: '15px' }}>{`Submitted: ${formattedDate}`}</Paragraph>
            <StatusLabel status={status} />
          </Stack>
        </>
      )}
    </>
  )
}

export default InstanceStatusBanner
