import { Box, Chip, styled } from '@mui/material'
import { MyRequestsCustomChipProps, MyRequstsLegendsFilterProps } from './types'

export const MyRequestsLegendsStickyContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'width' && prop !== 'height' && prop !== 'margin',
})<Partial<MyRequstsLegendsFilterProps>>(({ width, height, margin }) => ({
  ...{
    width: width || 'auto',
    height: height || 'auto',
    margin: margin || '',
  },
})) as React.ComponentType<Partial<MyRequstsLegendsFilterProps>>

export const MyRequestsCustomChip = styled(Chip, {
  shouldForwardProp: prop =>
    prop !== 'backgroundColor' &&
    prop !== 'borderColor' &&
    prop !== 'color' &&
    prop !== 'showHatch',
})<MyRequestsCustomChipProps>(({ backgroundColor, borderColor, color, showHatch }) => ({
  ...{
    fontWeight: '400 !important',
    fontSize: '11px !important',
    letterSpacing: '0.2px !important',
    borderRadius: '4px',
    padding: '4px 0px',
    height: 'auto',
    backgroundColor: backgroundColor || '#b9b9b9',
    color: color || 'white',
    border: borderColor ? '1px solid' : 'unset',
    borderColor: borderColor || 'unset',
    backgroundImage: showHatch
      ? 'linear-gradient(135deg, transparent  37.50%, #d1d1d1 37.50%, #d1d1d1 50%, transparent  50%, transparent  87.50%, #d1d1d1 87.50%, #d1d1d1 100%)'
      : 'unset',
    backgroundSize: showHatch ? '10px 10px' : 'unset',
  },
})) as React.ComponentType<MyRequestsCustomChipProps>
