import { styled } from '@mui/material/styles'
import { Card } from '@mui/material'
import mySummaryBG from '../../../assets/mySummaryBG.svg'

export const MySummaryCard = styled(Card)`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  background: url(${mySummaryBG}) top left #daf3f8;
  background-repeat: no-repeat;
  overflow: visible;
  height: 88px;
  box-shadow: 1px 2px 10px #00000014;
  border-radius: 16px;
`

export const MySummaryImg = styled('img')`
  position: absolute;
  bottom: 0;
  left: 0;
  zindex: 1;
  height: 99px;
`
