import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DeskProps } from './types'
import { DeskStatusColor, DeskStatusID } from './enums'
import { Group } from './components'
import { RootStore } from '../../../redux/store'
import { getUserInitials, cleanUsername } from '../utils/utils'
import localCss from '../booking.module.css'
import {
  calculateAccessPosition,
  deskColour,
  getAvailability,
  getLabelCoords,
} from './deskRectangleLogic'
import { BookingWizardSteps } from '../BlockBooking/enums'
import { BookingAvailabilityColors } from '../consts'

function DeskRectangle({
  featureDetail,
  existingBookings,
  requestedSlot,
  isActive,
  availabilityColor,
  isBlockBooking,
  onActionMouseEnter,
}: DeskProps) {
  const dispatch = useDispatch()
  const objectRef = useRef<SVGGElement | null>(null)
  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)
  const userInfo = useSelector((state: RootStore) => state.userState.loggedInUser)
  const {
    selectedFeature,
    selectedAlternativeFeatureID,
    currentStep: currentWizardStep,
  } = useSelector((state: RootStore) => state.deskBookingWizard)

  const { submissionsInProgress } = useSelector((state: RootStore) => state.deskBookingStatus)

  // NOTE: No provision has yet been made for more than 1 port
  const PORT_BORDER_RADIUS = featureDetail.ports?.length
    ? Number(featureDetail.ports?.[0].radius) * 1.5
    : 0

  const thisBooking = Number(existingBookings?.length) > 0 && existingBookings?.[0] || false
  const thisEmployee = thisBooking && thisBooking.employeeId === employeeDetails.employeeId // Where the current user made the booking
  const thisBookingUsername = thisBooking && cleanUsername(thisBooking.displayName)
  const thisLoggedInUsername = cleanUsername(userInfo?.name)

  const isOwnBooking = () => thisBooking ? thisEmployee &&
    thisBookingUsername === thisLoggedInUsername // This is where the booking is made one's self
    : false
  const isOwnBookingFor = () => thisBooking ? thisEmployee &&
    thisBookingUsername !== thisLoggedInUsername // Where the booking is made for a third party
    : false

  const derivedPosition = calculateAccessPosition(featureDetail, PORT_BORDER_RADIUS)
  const bookingDetails = getAvailability(
    requestedSlot.bookingRange,
    employeeDetails.employeeId,
    existingBookings
  )

  const labelCoords = getLabelCoords(featureDetail, derivedPosition.position)

  const isBlockBookingAndNotSelectedFeature = () =>
    isBlockBooking && !selectedFeature
  const isBlockBookingAndSelectedFeatureMatch = () =>
    isBlockBooking && selectedFeature?.id === featureDetail.id
  const isOwnBookingDuringBlockBookingWizard = () =>
    isOwnBooking() && currentWizardStep === BookingWizardSteps.STEP_2_SELECT_DESK

  const getDeskFillColor = () => {

    if (
      isBlockBookingAndNotSelectedFeature() ||
      isBlockBookingAndSelectedFeatureMatch() ||
      isOwnBookingDuringBlockBookingWizard()
    ) {
      return DeskStatusColor.ACTIVE
    }

    return deskColour(featureDetail, isOwnBooking(), isOwnBookingFor())
  }

  const shouldHighlightDesk = () => {
    if (
      isBlockBooking &&
      currentWizardStep === BookingWizardSteps.STEP_2_SELECT_DESK &&
      selectedFeature?.id === featureDetail.id
    ) {
      return true
    }
    if (isBlockBooking && currentWizardStep === BookingWizardSteps.STEP_4_ALTERNATIVES) {
      if (selectedAlternativeFeatureID === featureDetail.id) {
        return true
      }
    }
    return false
  }

  const submissionInProgress = () =>
    submissionsInProgress.some(s =>
      s === featureDetail.id
    )

  const featureDetailLocationId = () =>
    featureDetail?.locationId === 1

  const derivedPositionX = derivedPosition.x
  const derivedPositionY = derivedPosition.y

  return (
    <>
      <Group
        id={`desk_${featureDetail.id}`}
        ref={objectRef}
        style={{ overflow: 'visible' }}
        opacity={submissionInProgress() ? 0.5 : 1}
        onClick={e => {
          onActionMouseEnter?.(
            e.currentTarget,
            bookingDetails,
            isOwnBooking() || false,
            availabilityColor,
            existingBookings
          )
        }}
        highlightColor={isOwnBooking() ? DeskStatusColor.OWN_BOOKING : availabilityColor}
      >
        <rect
          y={featureDetail.y}
          x={featureDetail.x}
          width={featureDetail.width}
          height={featureDetail.height}
          rx={featureDetail.borderRadius}
          fill={getDeskFillColor()}
          className={isActive ? '' : localCss.bookingZoneUnfocussedDesk}
        />
        {shouldHighlightDesk() && (
          <rect
            y={featureDetail.y}
            x={featureDetail.x}
            width={featureDetail.width}
            height={featureDetail.height}
            rx={featureDetail.borderRadius}
            fill={getDeskFillColor()}
            strokeWidth={4}
            stroke="#7D7D7D"
          />
        )}
        <text
          x={labelCoords.x}
          y={labelCoords.y}
          fontFamily="Poppins"
          fontSize="19px"
          fontWeight="bold"
          fill="white"
          style={{ userSelect: 'none' }}
        >
          {featureDetail.label}
        </text>
        {featureDetail.statusId === DeskStatusID.VACANT && Boolean(featureDetail.ports?.length) && (
          <g>
            <circle
              cx={derivedPositionX}
              cy={derivedPositionY}
              r={PORT_BORDER_RADIUS}
              fill="white"
            />
            <circle
              cx={derivedPositionX}
              cy={derivedPositionY}
              r={PORT_BORDER_RADIUS - 6}
              fill="white"
            />
            <circle
              cx={derivedPositionX}
              cy={derivedPositionY}
              r={featureDetail.ports?.[0].radius}
              fill={isOwnBooking() ? DeskStatusColor.OWN_BOOKING : availabilityColor}
              cursor={availabilityColor === BookingAvailabilityColors.NOT_ACTIVE ? '' : 'pointer'}
              className={isActive ? '' : localCss.bookingZoneUnfocussedDesk}
            />
            {!isOwnBooking() && Number(existingBookings?.length) === 1 && (
              <text
                x={derivedPositionX}
                y={derivedPositionY}
                textAnchor="middle"
                fill="white"
                alignmentBaseline="middle"
                fontFamily="Poppins"
                fontSize="13px"
                cursor={availabilityColor === BookingAvailabilityColors.NOT_ACTIVE ? '' : 'pointer'}
              >
                {isBlockBooking ? '' : getUserInitials(existingBookings?.[0].displayName || '')}
              </text>
            )}

            {!isBlockBooking && isOwnBooking() && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={featureDetailLocationId() ? '16' : '14'}
                height={featureDetailLocationId() ? '24' : '20'}
                viewBox="0 0 16.991 24.567"
                x={derivedPositionX - (featureDetailLocationId() ? 8 : 7)}
                y={derivedPositionY - (featureDetailLocationId() ? 12 : 10)}
              >
                <path
                  data-name="Path 2426"
                  d="M8.5,0A8.541,8.541,0,0,0,0,8.6c0,6.449,8.5,15.968,8.5,15.968s8.5-9.52,8.5-15.968A8.541,8.541,0,0,0,8.5,0m0,11.669A3.071,3.071,0,1,1,11.53,8.6,3.054,3.054,0,0,1,8.5,11.669"
                  fill="#fff"
                />
              </svg>
            )}
          </g>
        )}
      </Group>
    </>
  )
}

export default DeskRectangle
