import styled from 'styled-components'
import { Card as MuiCard } from '@mui/material'
import { isMobilePortrait } from '../../../theme/deviceChecks'

const StyledBirthdayCard = styled(MuiCard)`
  &.MuiCard-root {
    background-position: center 90%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 614px;
    border-radius: 16px;
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    box-sizing: border-box;
  }

  ${isMobilePortrait()} {
    &.MuiCard-root {
      border-radius: 0;
    }
  }
`

export default StyledBirthdayCard
