import { Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { RootStore } from '../../redux/store'
import { myExtrasService } from '../../services/myExtrasService'
import Button from '../../shared/UI/Button'
import DrawerFooter from '../../shared/UI/DrawerFooter'
import Paragraph from '../../shared/UI/Paragraph'
import { BaseResponse } from '../../types/base-response'
import { BuyHoursType } from '../../types/buy-hours-type'
import UserErrorMessage from '../../utils/errorFilter'

type Props = {
  closeDrawer: () => void
  hours: BuyHoursType | undefined
  drawerType?: string
}

function BuyRequest({ closeDrawer, hours, drawerType }: Props) {
  const [numberOfHours, setNumberOfHours] = useState<number | ''>(hours?.availableHours ? 1 : 0)
  const [fieldsTouched, setFieldsTouched] = useState({ hours: false })
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [shownOnce, setShownOnce] = useState<boolean>(false)

  const dispatch = useDispatch()

  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!currentEntitlementPeriodResponse) {
      return
    }

    if (
      Number(numberOfHours) > 0 &&
      hours?.availableHours !== undefined &&
      hours.availableHours >= Number(numberOfHours)
    ) {
      setSubmitLoading(true)
      myExtrasService
        .postBuyHolidayHours({
          year: currentEntitlementPeriodResponse.year,
          numberOfHours: Number(numberOfHours),
        })
        .then(response => {
          setSubmitLoading(false)
          if (response.status === 200 && response.errors.length > 0) {
            response.errors.forEach(error => {
              dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
            })
          } else {
            dispatch(showSuccessMessage('Buy hours request successful.'))
            closeDrawer()
          }
        })
        .catch(err => {
          setSubmitLoading(false)
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    }

    if (hours?.availableHours !== undefined && hours.availableHours < Number(numberOfHours)) {
      dispatch(
        showErrorMessage(`Not enough hours. You have ${hours.availableHours} hours available`)
      )
    }
  }

  const isFormValid = () => {
    setFieldsTouched({ ...fieldsTouched, hours: true })
    if (Number(numberOfHours) < 1) {
      return false
    }
    return true
  }
  const validateHours = (noOfHours = 40) => {
    const numOfHours = noOfHours === undefined ? 0 : noOfHours
    const value = numOfHours < 1 ? 1 : numOfHours

    if (hours?.availableHours !== undefined && value > hours.availableHours) {
      if (!shownOnce) {
        dispatch(
          showErrorMessage(
            `You ${hours.availableHours === 0 ? '' : 'only'} have ${
              hours.availableHours
            } hours available to buy.`
          )
        )
        setShownOnce(true)
      }
    }

    return value
  }

  return (
    <Grid
      component="form"
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (isFormValid()) {
          handleSubmit(event)
        } else {
          dispatch(showErrorMessage('Number of hours to buy must be at least 1'))
        }
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paragraph weight="bold">Details</Paragraph>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Number of hours to buy"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.trim() === '') {
                return setNumberOfHours('')
              }
              const validHours = validateHours(Number(e.target.value))
              setNumberOfHours(validHours)
            }}
            value={numberOfHours}
            onBlur={e => {
              if (e.target.value.trim() === '') {
                setNumberOfHours(0)
              }
              setFieldsTouched({ hours: true })
            }}
            type="number"
            InputProps={{
              inputProps: { step: 0.1 },
            }}
            error={fieldsTouched.hours && Number(numberOfHours) < 1}
            onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
            data-testid="MyExtras-Buy-InputBtn"
          />
        </Grid>
      </Grid>
      <DrawerFooter>
        <Button
          color="secondary"
          label="Cancel"
          onClick={closeDrawer}
          dataTestId={`MyExtras-${drawerType}-CancelBtn`}
        />
        <Button
          label="Submit"
          type="submit"
          disabled={numberOfHours === 0}
          loading={submitLoading}
          dataTestId={`MyExtras-${drawerType}-SubmitBtn`}
        />
      </DrawerFooter>
    </Grid>
  )
}

export default BuyRequest
