import { Grid, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { hideModal, SetShowModalPayload } from '../../../redux/reducers/appSettingsReducer'
import { showErrorMessage, showSuccessMessage } from '../../../redux/reducers/snackbarReducer'
import { RootStore, useAppDispatch } from '../../../redux/store'
import { manualRequestsService } from '../../../services/myActionsService'
import Button from '../../../shared/UI/Button'
import CheckBox from '../../../shared/UI/CheckBox'
import DateTimePicker from '../../../shared/UI/DateTimePicker'
import DrawerFooter from '../../../shared/UI/DrawerFooter'
import DropdownMenu from '../../../shared/UI/DropdownMenu'
import Modal from '../../../shared/UI/Modal'
import { AbsenceDay } from '../../../types/absence-day'
import { AbsencePutRequest } from '../../../types/absence-put-request'
import { BaseResponse } from '../../../types/base-response'
import { EmployeeResponse } from '../../../types/employee'
import { getSelectValuesByType } from '../../../utils/app-utils'
import { getLocalDateString } from '../../../utils/date-utils'
import UserErrorMessage from '../../../utils/errorFilter'
import { SelectOption } from '../../../services/dashboardService'
import { LocationGroups } from '../../../types/location-groups'
import CovidIsoPayAlert from '../Actions/CovidIsoPayAlert'

type Props = {
  absenceId: number
  absenceDay: AbsenceDay
  employeeResponse: EmployeeResponse
  expectedReturn: Date
  onClose: () => void
}

function EditOccurrence({
  absenceId,
  absenceDay,
  employeeResponse,
  expectedReturn,
  onClose,
}: Props) {
  const dispatch = useAppDispatch()
  const [selectedAbsenceTypeId, setSelectedAbsenceTypeId] = useState<number>(
    absenceDay.absenceType.absenceTypeId || 0
  )
  const [selectedReasonId, setSelectedReasonId] = useState<number>(
    absenceDay.absenceType.reasonTypeId || 0
  )
  const [selectedContactTypeId, setSelectedContactTypeId] = useState<number>(
    absenceDay.contactedByTypeId || 0
  )
  const [hoursLost, setHoursLost] = useState<string>(absenceDay.hoursLost.toString() || '0')
  const [contactDate, setContactDate] = useState<Date>(absenceDay.contactDateTime)
  const [toWorkBack, setToWorkBack] = useState<boolean>(absenceDay.toWorkBack)
  const [comments, setComments] = useState<string>(absenceDay.comments || '')
  const [contactComments, setContactComments] = useState<string>(absenceDay.contactComments || '')
  const [locationAbsenceTypesList, setLocationAbsenceTypesList] = useState<SelectOption[]>([])
  const { territoryAttendanceUK } = useSelector((state: RootStore) => state.featureToggles)

  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  useEffect(() => {
    const getLocationAbsenceTypesList = async () => {
      manualRequestsService.getAbsenceReasonsByEmployeeId(employeeResponse.id).then(response => {
        setLocationAbsenceTypesList(response.locationAbsenceReasons.options)
      })
    }

    switch (employeeResponse.locationGroupName) {
      case territoryAttendanceUK && LocationGroups.UK:
        getLocationAbsenceTypesList()
        break
      default:
        setLocationAbsenceTypesList(
          getSelectValuesByType('AbsenceType').filter(x => x.associatedValue === selectedReasonId)
        )
    }
  }, [employeeResponse, selectedReasonId, territoryAttendanceUK])

  const submitRequest = () => {
    const absenceContactType = getSelectValuesByType('ContactByType').find(
      x => x.value === selectedContactTypeId
    )

    const request: AbsencePutRequest = {
      absenceDayId: absenceDay.id,
      absenceId,
      absenceDate: absenceDay.date,
      shiftDescription: absenceDay.expectedShift.shiftString || '',
      absentHours: Number(hoursLost),
      absenceTypeId: selectedAbsenceTypeId,
      absenceReasonType: selectedReasonId,
      contactByType: absenceContactType?.displayValue.replace(/\s/g, '') || '',
      contactDateTime: contactDate!,
      toWorkBack,
      comments,
      contactComments,
      hasHadLunch: absenceDay.hasHadLunch,
    }

    manualRequestsService
      .editAbsence(request)
      .then(() => {
        dispatch(showSuccessMessage('Absence request has been sent'))
        onClose()
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    submitRequest()
  }

  return (
    <Grid container spacing={4} component="form" onSubmit={handleSubmit} noValidate pr={4}>
      <Grid item xs={12}>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <DropdownMenu
                label="Employee"
                id="employee"
                data={[
                  {
                    id: employeeResponse.id,
                    displayNAme: employeeResponse.displayName,
                  },
                ]}
                textField="displayNAme"
                valueField="id"
                value={String(employeeResponse.id)}
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                label="Date"
                value={getLocalDateString(absenceDay.date)}
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                label="Shift"
                value={absenceDay.expectedShift.shiftString}
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                label="Expected Return"
                value={getLocalDateString(expectedReturn)}
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <DropdownMenu
                label="Absence Type"
                id="absenceType"
                data={getSelectValuesByType('AbsenceReasonType')}
                textField="displayValue"
                valueField="value"
                name="absenceTypeId"
                value={selectedReasonId}
                onChange={e => {
                  setSelectedReasonId(Number(e.target.value))
                }}
                helperText="Required"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <DropdownMenu
                label="Reason for absence"
                id="absenceReason"
                data={locationAbsenceTypesList.filter(x => x.associatedValue === selectedReasonId)}
                textField="displayValue"
                valueField="value"
                name="absenceTypeReasonId"
                value={selectedAbsenceTypeId}
                onChange={e => {
                  setSelectedAbsenceTypeId(Number(e.target.value))
                }}
                helperText="Required"
              />
            </Grid>
            <CovidIsoPayAlert
              absenceCategoryId={selectedReasonId}
              absenceTypeId={selectedAbsenceTypeId}
            />
            <Grid item xs={12} lg={6}>
              <TextField
                id="hours-input"
                label="Hours"
                type="number"
                variant="outlined"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setHoursLost(e.target.value)
                }}
                fullWidth
                inputProps={{ step: '0.1', min: 0, max: 24 }}
                value={hoursLost}
              />
            </Grid>
            <Grid item xs={12} lg={6} display="flex" alignItems="center">
              <CheckBox
                label="To work back"
                checked={toWorkBack}
                onChange={(e, checked) => {
                  setToWorkBack(checked)
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <DropdownMenu
                label="Contact made by"
                id="contact-made-by"
                textField="displayValue"
                valueField="value"
                value={selectedContactTypeId}
                data={getSelectValuesByType('ContactByType')}
                onChange={e => {
                  setSelectedContactTypeId(Number(e.target.value))
                }}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              {(selectedContactTypeId === 1 || selectedContactTypeId === 2) && (
                <DateTimePicker
                  label="Contacted date"
                  value={contactDate}
                  onChange={e => {
                    if (e) {
                      setContactDate(e)
                    }
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                label="Contact Comments"
                value={contactComments}
                rows={5}
                multiline
                onChange={e => {
                  setContactComments(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                label="Comments"
                value={comments}
                rows={5}
                multiline
                onChange={e => {
                  setComments(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6} />
          </Grid>
        </Grid>
      </Grid>
      <DrawerFooter>
        <Button color="secondary" label="Cancel" onClick={onClose} />
        <Button label="Submit" type="submit" />
      </DrawerFooter>
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
        }}
        onClick={() => submitRequest()}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
      />
    </Grid>
  )
}

export default EditOccurrence
