import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../../redux/store'
import { BaseResponse } from '../../types/base-response'
import { setIsLoading } from '../../redux/reducers/appSettingsReducer'
import { requestsService } from '../../services/requestsService'
import UserErrorMessage from '../../utils/errorFilter'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import { manualRequestsService } from '../../services/myActionsService'

export const useCommonDependencies = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const activeRoute = useSelector<RootStore, string>(
    (state: RootStore) => state.appSettings.activeRoute
  )

  return { dispatch, navigate, activeRoute }
}

export const useErrorHandler = () => {
  const { dispatch, navigate, activeRoute } = useCommonDependencies()

  const handleError = (err: any) => {
    setIsLoading(false)
    navigate(activeRoute)
    dispatch(showErrorMessage('An error occurred while fetching the request details.'))
    const response: BaseResponse = err.response.data
    response.errors.forEach((error: any) => {
      dispatch(showErrorMessage(UserErrorMessage(error.name)))
    })
  }

  return handleError
}

export const useRequestChecker = () => {
  const { id: requestId } = useParams<{ id: string }>()
  const checkIfRequestExists = async () => requestsService.checkRequestExists(Number(requestId))
  return checkIfRequestExists
}

export const useNonExistentRequestHandler = () => {
  const { dispatch, navigate, activeRoute } = useCommonDependencies()
  const handleNonExistentRequest = () => {
    dispatch(showErrorMessage('The request does not exist as it has been altered by the user'))
    navigate(activeRoute)
  }

  return handleNonExistentRequest
}

export const useRequestDetailFetcher = () => {
  const { id: requestId } = useParams<{ id: string }>()
  const fetchRequestDetail = async () =>
    manualRequestsService.getHolidayRequestDetail(requestId as string)
  return fetchRequestDetail
}
