import { useEffect, useState, useRef } from 'react'
import { StyledSvgIcon, StyledSvgBox } from './components'
import ProgressLabel from './progressLabel'
import FullComponentLoadingIcon from '../LoadingIndicator/FullComponentLoadingIcon'

type CircularProgressBarProps = {
  dataSet: {
    val: number
    max: number
    color: string
    remaining: number
  }[]
  label: number
  loading?: boolean
}

function progressCalc(
  val: number | undefined,
  max: number | undefined,
  pathLength: number | undefined
) {
  if (val === undefined || max === undefined || pathLength === undefined) {
    return
  }

  if (max === 0) {
    return pathLength
  }

  const decimal = val > max ? 1 : val / max

  return pathLength - pathLength * decimal
}

function CircularProgressBar({ dataSet, label, loading }: CircularProgressBarProps) {
  const innerCircleRef = useRef<SVGPathElement>(null)
  const centreCircleRef = useRef<SVGPathElement>(null)
  const outerCircleRef = useRef<SVGPathElement>(null)

  const [innerCirclePathLength, setInnerCirclePathLength] = useState<number>()
  const [centreCirclePathLength, setCentreCirclePathLength] = useState<number>()
  const [outerCirclePathLength, setOuterCirclePathLength] = useState<number>()

  const [innerCircleProgress, setInnerCircleProgress] = useState<number>()
  const [centreCircleProgress, setCentreCircleProgress] = useState<number>()
  const [outerCircleProgress, setOuterCircleProgress] = useState<number>()

  useEffect(() => {
    if (!loading) {
      if (innerCircleRef.current) {
        setInnerCirclePathLength(innerCircleRef.current.getTotalLength())
      }
      if (centreCircleRef.current) {
        setCentreCirclePathLength(centreCircleRef.current.getTotalLength())
      }
      if (outerCircleRef.current) {
        setOuterCirclePathLength(outerCircleRef.current.getTotalLength())
      }
    }
  }, [centreCirclePathLength, innerCirclePathLength, loading, outerCirclePathLength])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (dataSet) {
        if (dataSet[0] && outerCirclePathLength) {
          setOuterCircleProgress(
            progressCalc(dataSet[0].val, dataSet[0].max, outerCirclePathLength)
          )
        }

        if (dataSet[1] && centreCirclePathLength) {
          setCentreCircleProgress(
            progressCalc(dataSet[1].val, dataSet[1].max, centreCirclePathLength)
          )
        }

        if (dataSet[2] && innerCirclePathLength) {
          setInnerCircleProgress(
            progressCalc(dataSet[2].val, dataSet[2].max, innerCirclePathLength)
          )
        }
      }
    }, 250)

    return () => {
      clearTimeout(timeout)
    }
  }, [centreCirclePathLength, dataSet, innerCirclePathLength, outerCirclePathLength])

  return (
    <FullComponentLoadingIcon loading={Boolean(loading)} noData={!dataSet}>
      <StyledSvgBox>
        <StyledSvgIcon
          outerCircleValue={outerCircleProgress}
          outerCircleMax={outerCirclePathLength}
          centreCircleValue={centreCircleProgress}
          centreCircleMax={centreCirclePathLength}
          innerCircleValue={innerCircleProgress}
          innerCircleMax={innerCirclePathLength}
          dataSet={dataSet}
          viewBox="0 0 1000 1000"
        >
          <g>
            <circle className="outerCircleBg" cx="500" cy="500" r="408.8" />
            <path
              ref={outerCircleRef}
              className="outerCircleProgress"
              d="M500,91.2c112.9,0,215.1,45.7,289.1,119.7S908.8,387.1,908.8,500S863,715.1,789.1,789.1S612.9,908.8,500,908.8S284.9,863,210.9,789.1S91.2,612.9,91.2,500s45.7-215.1,119.7-289.1S387.1,91.2,500,91.2z"
            />
          </g>
          {dataSet[1] && (
            <g>
              <circle className="centreCircleBg" cx="500" cy="500" r="282" />
              <path
                ref={centreCircleRef}
                className="centreCircleProgress"
                d="M491,216c77.8,0,148.3,31.6,199.4,82.6c51,51,82.6,121.5,82.6,199.4s-31.6,148.3-82.6,199.4c-51,51-121.5,82.6-199.4,82.6s-148.4-31.6-199.4-82.6S209,575.9,209,498s31.6-148.4,82.6-199.4S413.1,216,491,216z"
              />
            </g>
          )}
          {dataSet[2] && (
            <g>
              <circle className="innerCircleBg" cx="500" cy="500" r="152.9" />
              <path
                ref={innerCircleRef}
                className="innerCircleProgress"
                d="M491,345.1c42.2,0,80.5,17.1,108.1,44.8c27.7,27.7,44.8,65.9,44.8,108.1s-17.1,80.5-44.8,108.1s-65.9,44.8-108.1,44.8s-80.5-17.1-108.1-44.8s-44.8-65.9-44.8-108.1s17.1-80.4,44.8-108.1C410.5,362.2,448.8,345.1,491,345.1z"
              />
            </g>
          )}
        </StyledSvgIcon>
        {label !== undefined && dataSet.length === 1 && (
          <ProgressLabel val={label} label="Remaining" />
        )}
      </StyledSvgBox>
    </FullComponentLoadingIcon>
  )
}

export default CircularProgressBar
