import { format } from 'date-fns'
import { Grid } from '@mui/material'
import { DateRange } from '@mui/x-date-pickers-pro'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../../../../redux/store'

import Button from '../../../../shared/UI/Button'
import AutocompleteList from '../../../../shared/UI/AutocompleteList'
import DateRangePicker from '../../../../shared/UI/DateRangePicker'

import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { FormProps, Search } from './types'
import { SelectOption, Team } from '../../../../services/dashboardService'
import { EmployeeDetailsResponse } from '../../../../types/employee'

function HolidaysThresholdsRestrictionsSearchForm({
  resetForm,
  userDepartments,
  onSearch,
}: FormProps) {
  function getStartOfWeek() {
    const date = new Date()
    const today = date.getDate()
    const currentDay = date.getDay()
    const newDate = date.setDate(today - currentDay + 1)
    return new Date(newDate)
  }

  function getEndOfWeek() {
    const date = new Date()
    const today = date.getDate()
    const currentDay = date.getDay()
    const newDate = date.setDate(today - currentDay + 7)
    return new Date(newDate)
  }

  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse>(
    (state: RootStore) => state.appSettings.employeeDetails
  )

  const { allTeams } = useSelector((state: RootStore) => state.appSettings)
  const department = userDepartments.find(p => p.value === employeeDetails.departmentId)
  const team = allTeams.find(p => p.teamId === employeeDetails.teamId)
  const teams = allTeams.filter(p => userDepartments.map(r => r.value).includes(p.departmentId!))
  const [fromDateRange, setFromDateRange] = useState<DateRange<Date>>([
    getStartOfWeek(),
    getEndOfWeek(),
  ])
  const [selectedDepartments, setSelectedDepartments] = useState<SelectOption[]>(
    department !== undefined
      ? [{ displayValue: department!.displayValue, value: department!.value }]
      : []
  )
  const [selectedTeams, setSelectedTeams] = useState<Team[]>(team !== undefined ? [team] : [])

  const onHandleDepartmentsChange = (event: unknown, items: SelectOption[]) => {
    setSelectedDepartments(items)
  }

  const onHandleTeamsChange = (event: unknown, items: Team[]) => {
    setSelectedTeams(items)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const search: Search = {
      teamIds: selectedTeams?.map(p => p.teamId),
      departmentIds: selectedDepartments?.map(p => p.value),
      fromDateRangeFrom: fromDateRange[0] !== null ? format(fromDateRange[0], 'yyyy-MM-dd') : '',
      fromDateRangeTo: fromDateRange[1] !== null ? format(fromDateRange[1], 'yyyy-MM-dd') : '',
    }

    if (selectedTeams.length === 0) {
      search.teamIds = teams.map(p => p.teamId)
    }

    if (selectedDepartments.length === 0) {
      search.departmentIds = userDepartments.map(p => p.value)
    }

    onSearch(search)
  }

  useDidMountEffect(() => {
    setSelectedDepartments(
      department !== undefined
        ? [{ displayValue: department!.displayValue, value: department!.value }]
        : []
    )
    setSelectedTeams(team !== undefined ? [team] : [])
    setFromDateRange([getStartOfWeek(), getEndOfWeek()])
  }, [resetForm])

  return (
    <Grid container spacing={4} component="form" noValidate mt={0}>
      {/* Main Layout 4 Col */}
      <Grid item xs={12} lg={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DateRangePicker
              arrange="Horizontal"
              startText="Date from"
              endText="Date to"
              onChange={(date: DateRange<Date>, keyboardInputValue?: string | undefined): void =>
                setFromDateRange(date)
              }
              value={fromDateRange}
            />
          </Grid>
          {/* Main Layout 4 Col END */}
        </Grid>
      </Grid>
      {/* Main Layout 8 Col */}
      <Grid item xs={12} lg={8}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Grid item xs={12} style={{ flex: 1 }}>
              <AutocompleteList
                multiple
                id="departments"
                label="All Departments"
                data={userDepartments}
                textField="displayValue"
                value={selectedDepartments || null}
                onChange={onHandleDepartmentsChange}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid item xs={12} style={{ flex: 1 }}>
              <AutocompleteList
                multiple
                id="teams"
                label="All Teams"
                data={teams}
                textField="teamName"
                value={selectedTeams || null}
                onChange={onHandleTeamsChange}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Main Layout 8 Col END */}
      </Grid>
      <Grid item xs={12} pt={0} mt={0} display="flex" justifyContent="flex-end">
        <Button label="Search" type="submit" onClick={handleSubmit} />
      </Grid>
    </Grid>
  )
}

export default HolidaysThresholdsRestrictionsSearchForm
