import { format } from 'date-fns'
import { Grid } from '@mui/material'
import { DateRange } from '@mui/x-date-pickers-pro'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../../redux/store'

import DropdownMenu from '../../shared/UI/DropdownMenu'
import Button from '../../shared/UI/Button'
import AutocompleteList from '../../shared/UI/AutocompleteList'
import DateRangePicker from '../../shared/UI/DateRangePicker'

import { SelectOption, Team } from '../../services/dashboardService'
import {
  HolidayReportSearch,
  HolidaySearchFormProps,
  ManualRequestTypeData,
  RequestTypeData,
} from './types'
import { OptionValue } from '../../types/option-value'
import { getSelectValuesByType, getFilteredDepartmentsDropdownOptions } from '../../utils/app-utils'

import useDidMountEffect from '../../utils/useDidMountEffect'

interface HolidaySearchValidationErrors {
  dateFrom?: boolean
  dateTo?: boolean
}

function HolidaySearchForm({ resetForm, onSearch }: HolidaySearchFormProps) {
  const [fromDateRange, setFromDateRange] = useState<DateRange<Date>>([null, null])
  const [submittedDateRange, setSubmittedDateRange] = useState<DateRange<Date>>([null, null])

  const [requestStatusId, setRequestStatusId] = useState<number>(0) // All
  const [requestStatuses, setRequestStatuses] = useState<SelectOption[]>([])

  const { departments, allEmployees, allTeams, directReports, currentEntitlementPeriodResponse } =
    useSelector((state: RootStore) => state.appSettings)

  const [selectedDepartment, setSelectedDepartment] = useState<SelectOption | null>({
    displayValue: 'All',
    value: 0,
  })
  const [showTeamsDropDown, setShowTeamsDropDown] = useState<boolean>(false)
  const [showRequestType, setShowRequestType] = useState<boolean>(true)
  const [showManualRequestType, setShowManualRequestType] = useState<boolean>(true)
  const [selectedEmployee, setSelectedEmployee] = useState<SelectOption | null>({
    displayValue: 'All',
    value: 0,
  })
  const [employeeList, setEmployeeList] = useState<SelectOption[]>([])
  const [selectedManualRequestType, setSelectedManualRequestType] = useState<SelectOption | null>({
    displayValue: 'All',
    value: 0,
  })
  const [selectedRequestType, setSelectedRequestType] = useState<SelectOption | null>({
    displayValue: 'All',
    value: 0,
  })
  const [selectedActionedBy, setSelectedActionedBy] = useState<SelectOption | null>({
    displayValue: 'All',
    value: 0,
  })

  const [selectedTeam, setSelectedTeam] = useState<SelectOption | null>({
    displayValue: 'All',
    value: 0,
  })
  const [teamList, setTeamList] = useState<SelectOption[]>([])

  const [defaultDepartment, setDefaultDepartment] = useState<SelectOption>(selectedDepartment!)
  const requestTypesAllowed: string[] = useMemo(
    () => [
      'All',
      'Holiday',
      'LieuDay',
      'DayOff',
      'Shift',
      'WorkFromHome',
      'Buy',
      'Sell',
      'Birthday',
      'Manual',
    ],
    []
  )
  const entitlementDateRange: DateRange<Date> = useMemo(
    () => [
      new Date(currentEntitlementPeriodResponse!.startDate),
      new Date(currentEntitlementPeriodResponse!.endDate),
    ],
    [currentEntitlementPeriodResponse]
  )
  const [validationErrors, setValidationErrors] = useState<HolidaySearchValidationErrors>({})

  const onHandleDepartmentChange = (event: any, department: SelectOption | null) => {
    if (!department) {
      return
    }
    setSelectedDepartment(department)
  }

  const onHandleEmployeeChange = (event: any, employee: SelectOption | null) => {
    if (!employee) {
      return
    }
    setSelectedEmployee(employee)
  }

  const handleTeamChange = (event: any, team: SelectOption | null) => {
    if (!team) {
      return
    }
    setSelectedTeam(team)
  }

  const requestStatusResult = getSelectValuesByType('RequestStatus')
  if (!requestStatuses.length && requestStatusResult.length) {
    if (requestStatusResult.some((status: OptionValue) => status.value === 0)) {
      setRequestStatuses(requestStatusResult)
    } else {
      setRequestStatuses([{ value: 0, displayValue: 'All' }, ...requestStatusResult])
    }
  }

  const departmentsList = useMemo(
    () => getFilteredDepartmentsDropdownOptions(departments, allEmployees),
    [allEmployees, departments]
  )

  const getManualRequestTypeList = () => {
    const list: SelectOption[] = ManualRequestTypeData.map(item => {
      const option = { displayValue: item.displayValue, value: item.id } as SelectOption
      return option
    }) as SelectOption[]

    return list
  }

  const getRequestTypeList = () => {
    const list: SelectOption[] = RequestTypeData.filter(item => item.displayValue)
      .filter(option => requestTypesAllowed.includes(option.value))
      .map(item => {
        const option = { displayValue: item.displayValue, value: item.id } as SelectOption
        return option
      }) as SelectOption[]

    return list
  }

  const validateForm = useCallback((): boolean => {
    const errors: HolidaySearchValidationErrors = {}

    if (!fromDateRange[0]) {
      errors.dateFrom = true
    }

    if (!fromDateRange[1]) {
      errors.dateTo = true
    }

    setValidationErrors(errors)

    return !Object.keys(errors).length
  }, [fromDateRange])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!validateForm()) {
      return
    }

    const search: HolidayReportSearch = {
      departmentId: selectedDepartment ? selectedDepartment!.value : 0,
      teamId: selectedTeam ? selectedTeam!.value : 0,
      requestTypeId: selectedRequestType ? selectedRequestType!.value : 0,
      manualRequestTypeId: selectedManualRequestType ? selectedManualRequestType!.value : 0,
      actionBy: selectedActionedBy ? selectedActionedBy.value : 0,
      statusId: requestStatusId,
      employeeId: selectedEmployee ? selectedEmployee.value : 0,
      fromDateRangeFrom: fromDateRange[0] ? format(fromDateRange[0], 'yyyy-MM-dd') : '',
      fromDateRangeTo: fromDateRange[1] ? format(fromDateRange[1], 'yyyy-MM-dd') : '',
      submittedDateRangeFrom: submittedDateRange[0]
        ? format(submittedDateRange[0], 'yyyy-MM-dd')
        : '',
      submittedDateRangeTo: submittedDateRange[1]
        ? format(submittedDateRange[1], 'yyyy-MM-dd')
        : '',
    }

    onSearch(search)
  }

  useDidMountEffect(() => {
    setSelectedEmployee({ displayValue: 'All', value: 0 })
    setSelectedActionedBy({ displayValue: 'All', value: 0 })
    setSelectedDepartment(defaultDepartment)
    setSelectedRequestType({ displayValue: 'All', value: 0 })
    setSelectedManualRequestType({ displayValue: 'All', value: 0 })
    setSelectedTeam({ displayValue: 'All', value: 0 })
    setRequestStatusId(0)
    setFromDateRange(entitlementDateRange)
    setSubmittedDateRange([null, null])
    setValidationErrors({})
  }, [resetForm])

  useEffect(() => {
    if (currentEntitlementPeriodResponse) {
      setFromDateRange(entitlementDateRange)
    }

    let selectedEmployees = directReports

    if (selectedDepartment?.value !== 0) {
      selectedEmployees = directReports.filter(
        emp => emp.departmentId === selectedDepartment?.value
      )
      setShowTeamsDropDown(false)
    } else {
      setShowTeamsDropDown(true)
    }

    const selectAllItem: SelectOption = { displayValue: 'All', value: 0 }

    let list: SelectOption[] = selectedEmployees.map(item => ({
      displayValue: item.employeeName,
      value: item.employeeId,
    }))

    setEmployeeList([selectAllItem, ...list])

    let selectedTeams = allTeams

    if (selectedDepartment?.value !== 0) {
      selectedTeams = allTeams.filter(emp => emp.departmentId === selectedDepartment?.value)
    }

    list = selectedTeams.map(item => ({
      displayValue: item.teamName,
      value: item.teamId,
    }))

    setTeamList([selectAllItem, ...list])
  }, [
    selectedDepartment,
    currentEntitlementPeriodResponse,
    entitlementDateRange,
    allEmployees,
    allTeams,
  ])

  useEffect(() => {
    const selectAllItem: SelectOption = { displayValue: 'All', value: 0 }

    const list: SelectOption[] = directReports.map(item => ({
      displayValue: item.employeeName,
      value: item.employeeId,
    }))

    setEmployeeList([selectAllItem, ...list])
  }, [directReports])

  useEffect(() => {
    if (!selectedRequestType) {
      setSelectedRequestType(getRequestTypeList()[0])
    }
    if (!selectedManualRequestType) {
      setSelectedManualRequestType(getManualRequestTypeList()[0])
    }
    setShowRequestType(selectedManualRequestType?.value === 0)
    setShowManualRequestType(selectedRequestType?.value === 4)
  }, [selectedManualRequestType, selectedRequestType])

  const handleDateRangeChange = (date: DateRange<Date>) => {
    if (date[0] !== fromDateRange[0]) {
      setValidationErrors(current => ({ ...current, dateFrom: undefined }))
    }

    if (date[1] !== fromDateRange[1]) {
      setValidationErrors(current => ({ ...current, dateTo: undefined }))
    }
    setFromDateRange(date)
  }

  return (
    <Grid container spacing={4} component="form" noValidate mt={0}>
      {/* Main Layout 4 Col */}
      <Grid item xs={12} lg={4}>
        <Grid container spacing={2} style={{ border: '0px solid blue' }}>
          <Grid item xs={12} style={{ flex: 1, border: '0px solid red' }}>
            <AutocompleteList
              id="employee"
              label="Employee"
              data={employeeList}
              textField="displayValue"
              value={selectedEmployee ? selectedEmployee! : null}
              onChange={onHandleEmployeeChange}
              dataTestId="ReportingSearch-EmployeeDDL"
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <DateRangePicker
              arrange="Horizontal"
              startText="Date from"
              endText="Date to"
              onChange={(date: DateRange<Date>, keyboardInputValue?: string | undefined): void =>
                handleDateRangeChange(date)
              }
              value={fromDateRange}
              fromDateError={validationErrors.dateFrom}
              toDateError={validationErrors.dateTo}
              dataTestId="ReportingSearch-"
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <DateRangePicker
              arrange="Horizontal"
              startText="Submitted from"
              endText="Submitted to"
              onChange={(date: DateRange<Date>, keyboardInputValue?: string | undefined): void =>
                setSubmittedDateRange(date)
              }
              value={submittedDateRange}
              dataTestId="ReportingSearch-Submmited-"
            />
          </Grid>
          {/* Main Layout 4 Col END */}
        </Grid>
      </Grid>
      {/* Main Layout 8 Col */}
      <Grid item xs={12} lg={8}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Grid item xs={12} style={{ flex: 1, border: '0px solid red' }}>
              <AutocompleteList
                id="department"
                label="Department"
                data={departmentsList}
                textField="displayValue"
                value={selectedDepartment ? selectedDepartment! : null}
                onChange={onHandleDepartmentChange}
                dataTestId="ReportingSearch-DepartmentDDL"
              />
            </Grid>
            <Grid item xs={12} mt={3}>
              <AutocompleteList
                id="requestType"
                label="Request Type"
                data={getRequestTypeList()}
                textField="displayValue"
                value={selectedRequestType || null}
                disabled={!showRequestType}
                onChange={(e, newValue) => {
                  setSelectedRequestType(newValue)
                }}
                dataTestId="ReportingSearch-RequestTypeDDL"
              />
            </Grid>
            <Grid item xs={12} mt={3}>
              <DropdownMenu
                id="searchStatus"
                label="Status"
                data={requestStatuses}
                textField="displayValue"
                valueField="value"
                value={requestStatusId}
                onChange={e => setRequestStatusId(Number(e.target.value))}
                dataTestId="ReportingSearch-StatusDDL"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} style={{ flex: 1, border: '0px solid blue' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ flex: 1, border: '0px solid blue' }}>
                <AutocompleteList
                  id="team"
                  label="Teams"
                  data={teamList}
                  textField="displayValue"
                  value={selectedTeam}
                  onChange={handleTeamChange}
                  disabled={showTeamsDropDown}
                  dataTestId="ReportingSearch-TeamsDDL"
                />
              </Grid>
              <Grid item xs={12} mt={1}>
                <AutocompleteList
                  id="manualRequestType"
                  label="Manual Request Type"
                  data={getManualRequestTypeList()}
                  textField="displayValue"
                  value={selectedManualRequestType ? selectedManualRequestType! : null}
                  disabled={!showManualRequestType}
                  onChange={(e, newValue) => {
                    setSelectedManualRequestType(newValue)
                  }}
                  dataTestId="ReportingSearch-ManualRequestDDL"
                />
              </Grid>
              <Grid item xs={12} mt={1}>
                <AutocompleteList
                  id="actionedBy"
                  label="Actioned By"
                  data={employeeList}
                  textField="displayValue"
                  value={selectedActionedBy ? selectedActionedBy! : null}
                  onChange={(e, newValue) => {
                    setSelectedActionedBy(newValue)
                  }}
                  dataTestId="ReportingSearch-ActionedDDL"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Main Layout 8 Col END */}
      </Grid>
      <Grid item xs={12} pt={0} mt={0} display="flex" justifyContent="flex-end">
        <Button
          label="Search"
          type="submit"
          onClick={handleSubmit}
          dataTestId="ReportingSearch-SearchBtn"
        />
      </Grid>
    </Grid>
  )
}

export default HolidaySearchForm
