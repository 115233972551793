import { useState, useEffect } from 'react'
import { Button, Grid, styled as MuiStyled } from '@mui/material'
import TodayIcon from '@mui/icons-material/Today'
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import Card from '../../../shared/layout/Card'
import Alert from '../../../shared/UI/Alert/Alert'
import { AbsenceOverview } from '../../../types/absence-overview'
import { NotificationProps, SeverityLevels } from './types'
import NoDataFound from '../../../shared/UI/NoDataFound'
import LoadingIndicator from '../../../shared/UI/LoadingIndicator'

type CustomPickerDayProps = PickersDayProps<Date> & {
  fillDay: boolean
}

type Props = {
  absenceOverview: AbsenceOverview
  attendanceDays: any
  isLoading: boolean
  isData: boolean
}

const CustomPickersDay = MuiStyled(PickersDay, {
  shouldForwardProp: prop => prop !== 'fillDay',
})<CustomPickerDayProps>(({ theme, fillDay }) => ({
  ...(fillDay && {
    backgroundColor: '#ff5b5b',
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
})) as React.ComponentType<CustomPickerDayProps>

const buildRequest = (): any => ({
  DirectReportees: true,
  PendingOnly: false,
})

// TODO: This won't be required going forwards as the next
//  drop should have the threshhold levels availble from the API.
//  Threshold levels might be for example "5 absences in 13 weeks" = "Red Alert".
const thresholds = {
  WEEKS13_LATES: 5,
  WEEKS13_ABSENCES: 5,
  WEEKS13_DAYS: 5,
  WEEKS26_LATES: 10,
  WEEKS26_ABSENCES: 10,
  WEEKS26_DAYS: 10,
  WEEKS52_LATES: 20,
  WEEKS52_ABSENCES: 20,
  WEEKS52_DAYS: 20,
}

function Notifications({ absenceOverview, attendanceDays: absenceDays, isLoading, isData }: Props) {
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [datesBooked, setDatesBooked] = useState<any | null>(null)
  const [notificationsData, setNotificationsData] = useState<NotificationProps[] | null>(null)

  const datesBookedList = absenceDays && absenceDays.map((absence: any) => absence)

  useEffect(() => {
    if (datesBookedList) {
      setDatesBooked(datesBookedList)
    }
  }, [showCalendar])

  useEffect(() => {
    if (!absenceOverview) {
      return
    }
    let message = ''
    let severity: SeverityLevels = SeverityLevels.SUCCESS
    const formulateItem = (dataItem: any, field: string, wording: string, threshhold: number) => {
      message += `${dataItem?.[field]} ${wording} in ${dataItem?.weeks} weeks, `
      if (dataItem?.[field] > threshhold) {
        severity = SeverityLevels.ERROR
      }
    }
    const ao = absenceOverview
    const ov13 = ao.overview13Weeks
    const ov26 = ao.overview26Weeks
    const ov52 = ao.overview52Weeks
    const notificationsDataArray: NotificationProps[] = []
    let field
    let wording
    // Process lates
    field = 'lates'
    wording = 'Lates'
    message = ''
    if (ov13?.lates) {
      formulateItem(ov13, field, wording, thresholds.WEEKS13_LATES)
    }
    if (ov26?.lates) {
      formulateItem(ov26, field, wording, thresholds.WEEKS26_LATES)
    }
    if (ov52?.lates) {
      formulateItem(ov52, field, wording, thresholds.WEEKS52_LATES)
    }
    if (message) {
      message = message.endsWith(', ') ? message.substring(0, message.length - 2) : message
      notificationsDataArray.push({ id: 1, message, severity })
    }
    // Process absence occurences
    severity = SeverityLevels.SUCCESS
    field = 'absenceOccurrences'
    wording = 'absence occurrences'
    message = ''
    if (ov13?.absenceOccurrences) {
      formulateItem(ov13, field, wording, thresholds.WEEKS13_ABSENCES)
    }
    if (ov26?.absenceOccurrences) {
      formulateItem(ov26, field, wording, thresholds.WEEKS26_ABSENCES)
    }
    if (ov52?.absenceOccurrences) {
      formulateItem(ov52, field, wording, thresholds.WEEKS52_ABSENCES)
    }
    if (message) {
      message = message.endsWith(', ') ? message.substring(0, message.length - 2) : message
      notificationsDataArray.push({ id: 2, message, severity })
    }
    // Process absence days
    severity = SeverityLevels.SUCCESS
    field = 'absenceDays'
    wording = 'days absent'
    message = ''
    if (ov13?.absenceDays) {
      formulateItem(ov13, field, wording, thresholds.WEEKS13_DAYS)
    }
    if (ov26?.absenceDays) {
      formulateItem(ov26, field, wording, thresholds.WEEKS26_DAYS)
    }
    if (ov52?.absenceDays) {
      formulateItem(ov52, field, wording, thresholds.WEEKS52_DAYS)
    }
    if (message) {
      message = message.endsWith(', ') ? message.substring(0, message.length - 2) : message
      notificationsDataArray.push({ id: 3, message, severity })
    }
    setNotificationsData(notificationsDataArray)
  }, [absenceOverview])

  const renderWeekPickerDay = (
    day: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    if (!day) {
      return <PickersDay {...pickersDayProps} disableHighlightToday />
    }

    const datesAbsentArray = datesBooked?.map((date: any) =>
      new Date(new Date(date.date).toDateString()).getTime()
    )

    const fillDay = datesAbsentArray?.filter((e: any) => e === new Date(day).getTime()).length > 0

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        fillDay={fillDay}
        disableHighlightToday
      />
    )
  }

  return (
    <Card title="Notifications" style={{ overflow: 'visible' }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {!isLoading ? (
            <>
              {!isData ? (
                <NoDataFound show />
              ) : (
                absenceOverview &&
                absenceOverview.overview13Weeks &&
                notificationsData?.map(note => (
                  <Grid item key={note.id} mb={2}>
                    <Alert severity={note.severity} message={note.message} />
                  </Grid>
                ))
              )}
            </>
          ) : (
            <>
              <LoadingIndicator show />
            </>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

export default Notifications
