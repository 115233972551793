import { Box, styled } from '@mui/material'

export const BirthdayCakeContainer = styled(Box)`
  &.MuiBox-root {
    position: absolute;
    height: 45%;
    width: 100%;
    bottom: 0;
    background-color: #fff;
  }

  & img {
    height: 100%;
  }

  & > img {
    height: 100%;
    max-width: 100%;
    position: absolute;
    bottom: 53%;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const BirthdayBackground = styled(Box)`
  position: absolute;
  height: 55%;
  width: 100%;
  top: 0;
  left: 0;

  & img {
    min-height: 100%;
    min-width: 100%;
  }
`

export const YayImg = styled('img')`
  height: 45px;
  width: 82px;
  margin-top: 32px;
  opacity: 0;
  transform-origin: bottom center;

  @keyframes yay {
    from {
      transform: scale(0.5) translateY(100%);
      opacity: 0;
    }
    to {
      transform: scale(1) translateY(0);
      opacity: 1;
    }
  }

  animation: yay 0.5s ease forwards 1.4s;
`

export const BirthdayBookedContainer = styled(Box)`
  position: relative;
  display: flex;
  height: 100%;
  bottom: 53%;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  & > img {
    opacity: 0;
    height: 100%;
    width: 100%;
    animation: fadeIn 0.3s cubic-bezier(0.51, 0.59, 0.4, 1.01) forwards 1.4s;
  }
`

interface cakeProps {
  delay: number
}

const cakeFall = `@keyframes cakeFall {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}`

export const CakeLayer = styled(Box, {
  shouldForwardProp: prop => prop !== 'delay',
})<cakeProps>`
  ${cakeFall}
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;

  ${({ delay }) => `animation: cakeFall 0.3s cubic-bezier(.51,.59,.4,1.01) forwards ${delay}s`}
`

export const CakeLayerImg = styled('img')`
  height: 100%;
  width: 100%;
`
