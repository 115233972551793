import { AbsenceDayRequest } from '../../types/absence'
import { requiredFieldValidation } from '../../utils/app-utils'
import { REQUIRED_MESSAGE } from '../../utils/constants'
import { Validations } from '../../utils/useForm'

export interface AbsenceRequestModel {
  absenceId: number
  employeeId: number
  employeeName: string
  absenceDate: Date
  absenceFrom: Date
  endDate: Date
  shiftDescription: string
  expectedReturn: Date
  partDayAbsence: boolean
  absenceTypeId: number
  absentFrom: Date
  absenceCategoryId: number
  hasHadLunch: boolean
  contactedByTypeId: number
  contactDateTime: Date
  toWorkBack: boolean
  covidIsolationIndicator: boolean | undefined
  contactComments: string
  comments: string
  sendFdn: boolean
  days: AbsenceDayRequest[]
  overrideRequestDays: boolean
  startTime: Date
}

export const absenceRequestModelDefaultState: AbsenceRequestModel = {
  absenceId: 0,
  employeeId: 0,
  employeeName: '',
  absenceDate: new Date(),
  absenceFrom: new Date(),
  endDate: new Date(),
  shiftDescription: '9am - 5:30pm',
  expectedReturn: new Date(),
  partDayAbsence: false,
  absenceTypeId: 0,
  absentFrom: new Date(),
  absenceCategoryId: 0,
  hasHadLunch: false,
  contactedByTypeId: 0,
  toWorkBack: false,
  covidIsolationIndicator: undefined,
  contactComments: '',
  comments: '',
  sendFdn: true,
  contactDateTime: new Date(),
  days: [],
  overrideRequestDays: false,
  startTime: new Date(),
}

export const absenceComponentValidationSchema: Validations<AbsenceRequestModel> = {
  employeeId: requiredFieldValidation,
  absenceDate: requiredFieldValidation,
  endDate: requiredFieldValidation,
  shiftDescription: requiredFieldValidation,
  expectedReturn: requiredFieldValidation,
  absenceTypeId: requiredFieldValidation,
  absenceCategoryId: requiredFieldValidation,
  contactedByTypeId: {
    custom: { isValid: value => Number(value) >= 0, message: REQUIRED_MESSAGE },
  },
  contactComments: requiredFieldValidation,
  comments: requiredFieldValidation,
}

export interface AbsenceDrawerValidationErrors {
  employeeId?: boolean
  absenceTypeId?: boolean
  absenceTypeReasonId?: boolean
}
