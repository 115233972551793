import { ReactElement } from 'react'

export interface ErrorListProps {
  name: string
  message: string | ReactElement
  link: boolean
  alert?: boolean
}

export const ErrorList: ErrorListProps[] = [
  {
    name: 'Unknown',
    message: 'Unknown error.',
    link: false,
  },
  {
    name: 'NotificationCancelledBooking',
    message:
      'You cannot view a cancelled desk booking. Please rebook a desk or clear your notifications.',
    link: false,
  },
  {
    name: 'NotificationOldBooking',
    message:
      'You cannot view an old or expired desk booking. Please rebook a desk or clear your notifications.',
    link: false,
  },
]
