import React from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import styled from 'styled-components'

export interface DataProps {
  id: string
  name: string
}
interface DropdownProps {
  label: string
  id: string
  data: any
  textField: string
  valueField: string
  value?: any
  onChange?: (e: SelectChangeEvent) => void
  error?: boolean
  name?: string
  disabled?: boolean
  helperText?: string
  dataTestId?: string
}
const StyledDropdown = styled.div``

function DropdownMenu({
  label,
  id,
  data,
  textField,
  valueField,
  onChange,
  value,
  name,
  error = false,
  disabled = false,
  helperText,
  dataTestId,
}: DropdownProps) {
  return (
    <StyledDropdown>
      <FormControl fullWidth disabled={disabled}>
        <InputLabel id={id}>{label}</InputLabel>
        <Select
          name={name || id}
          labelId={id}
          id={id}
          value={value}
          label={label}
          onChange={onChange}
          error={error}
          disabled={disabled}
          data-testid={dataTestId}
        >
          {data.map((item: any) => (
            <MenuItem
              key={item[valueField]}
              value={item[valueField]}
              sx={{ height: '35px' }}
              data-testid={`${dataTestId}-${item[textField]}`}
            >
              {item[textField]}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText sx={{ color: error ? 'red' : '' }}>{helperText}</FormHelperText>
      </FormControl>
    </StyledDropdown>
  )
}
export default DropdownMenu
