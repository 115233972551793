import { useCallback, useEffect, useMemo, useState } from 'react'
import { Divider, Skeleton, Typography } from '@mui/material/'
import { useSelector } from 'react-redux'
import LogoutIcon from '@mui/icons-material/Logout'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { PlannerRoute, plannerRoutes } from '../../../utils/Routes'
import Navitem from '../Navitem'
import { RootStore, useAppDispatch } from '../../../redux/store'
import LogoIcon, {
  NavItemsContainer,
  StyledLink,
  StyledNavigationItemsContainer,
} from './components'
import { setActiveRoute } from '../../../redux/reducers/appSettingsReducer'
import { FeatureToggleType, hasUserAccessToRoute, isFeatureOn } from '../../../utils/app-utils'
import { StyledIcon } from '../../UI/NavIconButton'
import { EmployeeDetailsResponse } from '../../../types/employee'

interface NavigationItemsContainerProps {
  mobile: boolean
  closeDrawer?: () => void
}

function NavigationItemsContainer({ mobile, closeDrawer }: NavigationItemsContainerProps) {
  const userInfo = useSelector((state: RootStore) => state.userState.loggedInUser)
  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse>(
    (state: RootStore) => state.appSettings.employeeDetails
  )

  const dispatch = useAppDispatch()

  const activeRoute = useSelector<RootStore, string>(
    (state: RootStore) => state.appSettings.activeRoute
  )

  const expanded = useSelector<RootStore, boolean>(
    (state: RootStore) => state.appSettings.sideBarExpanded
  )

  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )
  const featureToggles = useSelector((state: RootStore) => state.featureToggles)
  const [routes, setRoutes] = useState<PlannerRoute[]>([])

  const getHref = useCallback(
    (route: PlannerRoute) => {
      const isSubItem = route.subItem?.find(item => item.href === activeRoute)
      if (isSubItem) {
        return isSubItem.href
      }
      return route.href
    },
    [activeRoute]
  )

  const checkAdminRoute = useCallback((item: PlannerRoute): boolean => {
    switch (item.href) {
      case '/bssadmin/featuretoggles':
        return isFeatureOn(FeatureToggleType.BssAdmin)
      case '/bssadmin/notificationlogs':
        return isFeatureOn(FeatureToggleType.NotificationLogsAdmin)
      default:
        return false
    }
  }, [])

  const getAdminDropdown = useCallback(
    (route: PlannerRoute): PlannerRoute[] => {
      if (!route.subItem) {
        return []
      }

      return route.subItem.filter(item => checkAdminRoute(item))
    },
    [checkAdminRoute]
  )

  const canViewReports = useMemo(
    () => userPermissions.includes('ReportsHoliday'),
    [userPermissions]
  )
  const canViewSearch = useMemo(() => userPermissions.includes('HistoryHoliday'), [userPermissions])
  const canUseBssAdmin = useMemo(() => userPermissions.includes('BSSAdmin'), [userPermissions])
  
  const { userSettings } = useSelector((state: RootStore) => state.appSettings)

  const getSubitems = useCallback(
    (route: PlannerRoute): PlannerRoute[] | undefined => {
      if (route.href.includes('bssadmin')) {
        return getAdminDropdown(route)
      }
      return route.subItem && !canViewSearch && !canViewReports ? [route.subItem[0]] : route.subItem
    },
    [canViewSearch, canViewReports, getAdminDropdown]
  )

  useEffect(() => {
    const mappedRoutes = plannerRoutes.map(route => ({
      activeIcon: route.activeIcon,
      href: route.href,
      title: route.title,
      show: route.show,
      hasAccess: hasUserAccessToRoute(
        route.href,
        userPermissions,
        employeeDetails.isContractor,
        userSettings,
        canUseBssAdmin
      ),
      isDropDown: route.isDropDown,
      subItem: getSubitems(route),
      isOnMobile: route.isOnMobile,
    }))

    setRoutes(mobile ? mappedRoutes.filter(r => r.isOnMobile) : mappedRoutes)
  }, [
    getHref,
    mobile,
    userPermissions,
    employeeDetails,
    getSubitems,
    canUseBssAdmin,
    featureToggles,
    userSettings
  ])

  return (
    <StyledNavigationItemsContainer mobile={mobile}>
      <NavItemsContainer sx={{ justifyContent: 'flex-start' }}>
        <StyledLink
          to="/dashboard"
          onClick={() => {
            dispatch(setActiveRoute('/dashboard'))
          }}
        >
          <LogoIcon expanded={expanded} mobile={mobile} />
        </StyledLink>
        {mobile && (
          <>
            <Typography variant="h5" sx={{ margin: '40px 0 0 16px' }}>
              {userInfo ? `Hi, ${userInfo?.given_name}` : <Skeleton width={300} height={40} />}
            </Typography>
            <Divider sx={{ marginLeft: '16px', marginRight: '15%', marginTop: '8px' }} />
          </>
        )}
        {routes.map(route => (
          <Navitem
            key={`${route.href}${route.title}`}
            href={route.href}
            title={route.title}
            icon={route.activeIcon}
            isActive={
              activeRoute === route.href || (activeRoute === '/' && route.href === '/dashboard')
            }
            activeRoute={activeRoute}
            expanded={expanded}
            show={route.show && route.hasAccess}
            isDropDown={route.isDropDown}
            subItem={route.subItem}
            mobile={mobile}
            closeDrawer={closeDrawer}
            dataTestId={route.title}
          />
        ))}
      </NavItemsContainer>
      <NavItemsContainer sx={{ justifyContent: 'flex-end' }}>
        <Navitem
          href="https://bet365-dwp.onbmc.com/dwp/app/#/knowledge/AGGAA5V0G1W07ARQEK0NRPEN9JOYMB/rkm"
          title="Help"
          icon={
            <StyledIcon disableRipple>
              <HelpOutlineIcon />
            </StyledIcon>
          }
          isActive={false}
          activeRoute=""
          expanded={expanded}
          show
          isDropDown={false}
          subItem={undefined}
          mobile={mobile}
          closeDrawer={undefined}
          externalLink="yes"
        />
        {mobile && (
          <Navitem
            href="#"
            title="Resync Okta"
            icon={
              <StyledIcon disableRipple>
                <LogoutIcon />
              </StyledIcon>
            }
            isActive={false}
            activeRoute="#"
            expanded={expanded}
            isDropDown={false}
            show
            mobile={mobile}
            closeDrawer={closeDrawer}
          />
        )}
        <div className={`navigation-panel__version ${!expanded && 'hide'}`} />
      </NavItemsContainer>
    </StyledNavigationItemsContainer>
  )
}

export default NavigationItemsContainer
