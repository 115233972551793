import { Typography as MuiTypography } from '@mui/material'
import styled from 'styled-components'

type Props = {
  title?: string | number
}

const StyledTypography = styled(MuiTypography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0.2px;
  }
`

function HeadingTwo({ title }: Props) {
  return <StyledTypography>{title}</StyledTypography>
}

export default HeadingTwo
