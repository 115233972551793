import { useState, useEffect, useCallback } from 'react'
import { Grid } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import InstanceStatusBanner from '../../shared/UI/InstanceStatusBanner'
import Card from '../../shared/layout/Card'
import Drawer from '../../shared/UI/Drawer'
import { getLocalDateString, getDateTime24HrFormat } from '../../utils/date-utils'
import AbsenceAndLate from '../AbsenceAndLate'
import {
  hideDrawer,
  hideModal,
  SetShowModalPayload,
  showDrawer,
  showModalDialog,
} from '../../redux/reducers/appSettingsReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import Notifications from '../Absence/Notifications'
import { manualRequestsService } from '../../services/myActionsService'
import { DrawerComponentProps, SummaryProps } from './types'
import EditLate from './EditLate'
import LateHistory from './LateHistory'
import { setFilteredAbsences } from '../../redux/reducers/timeOffRequestsReducer'
import Modal from '../../shared/UI/Modal'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import DropdownMenu from '../../shared/UI/DropdownMenu'
import { getSelectValuesByType } from '../../utils/app-utils'
import MyActionsPageHeader from '../../shared/UI/MyActionsPageHeader'
import SummaryLayout from './SummaryLayout'
import Button from '../../shared/UI/Button'
import OccurrenceSubNav from '../../shared/UI/OccurrenceSubNav'
import UserErrorMessage from '../../utils/errorFilter'
import { BaseResponse } from '../../types/base-response'
import { availabilityService } from '../../services/availabilityService'
import AttendanceCalendar from '../AttendanceCalendar'
import { MyActionsHistoryState } from '../../types/my-actions-history-state'

function Late() {
  const [summary, setSummary] = useState<Array<SummaryProps>>([])
  const [openDrawer, setOpenDrawer] = useState(false)
  const [drawerComponent, setDrawerComponent] = useState<DrawerComponentProps>({
    title: 'New Occurrence',
    component: <AbsenceAndLate />,
  })
  const [lateStatus, setLateStatus] = useState<number>()
  const [view, setView] = useState('Overview')
  const [lateData, setLateData] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isData, setIsData] = useState<boolean>(false)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  const { id: lateId } = useParams<{ id: string }>()

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const showSideDrawer = useSelector<RootStore, boolean>(
    (state: RootStore) => state.appSettings.showDrawer
  )

  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  const activeRoute = useSelector<RootStore, string>(
    (state: RootStore) => state.appSettings.activeRoute
  )

  const isComingFromMyActionsDashboard = useCallback((): boolean => {
    const payload = location.state as MyActionsHistoryState
    return payload && payload.previousLocation === '/dashboard' && payload.fromMyActionsDashboard
  }, [location.state])

  useEffect(() => {
    if (showSideDrawer) {
      return
    }

    if (window.location.pathname.includes('myactions') || isComingFromMyActionsDashboard()) {
      manualRequestsService
        .getLateOccurrence(parseInt(lateId as string, 10))
        .then((result: any) => {
          setLateData(result.lateOccurrence)
          setIsLoading(false)
          setIsData(true)
        })

        .catch(err => {
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
          setIsData(false)
          navigate(activeRoute)
          setIsLoading(false)
        })
    } else {
      availabilityService
        .getMyLateDetail(parseInt(lateId as string, 10))
        .then((result: any) => {
          setLateData(result.lateOccurrence)
          setIsLoading(false)
          setIsData(true)
        })

        .catch(err => {
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
          setIsData(false)
          navigate(activeRoute)
          setIsLoading(false)
        })
    }
  }, [dispatch, lateId, showSideDrawer])

  const fetchAllData = () => {
    if (lateData) {
      const summaryItemsData = [] as Array<SummaryProps>
      summaryItemsData.push({
        id: 1,
        title: 'Current Action Status',
        value: lateData.lateStatusStr,
      })
      summaryItemsData.push({ id: 2, title: 'Date', value: getLocalDateString(lateData.lateDate) })
      summaryItemsData.push({ id: 3, title: 'Minutes Lost', value: lateData.lateMinutes })
      summaryItemsData.push({ id: 4, title: 'Reason', value: lateData.reason })
      summaryItemsData.push({
        id: 5,
        title: 'Expected Shift',
        value: lateData.expectedShift.shiftString,
      })
      summaryItemsData.push({
        id: 6,
        title: 'To Work Back',
        value: lateData.toWorkBack ? 'Yes' : 'No',
      })

      summaryItemsData.push({
        id: 8,
        title: 'Department',
        value: lateData.departmentName,
      })
      summaryItemsData.push({
        id: 9,
        title: 'Position ',
        value: lateData.position?.positionName,
      })
      summaryItemsData.push({
        id: 10,
        title: 'Supervisor',
        value: lateData.supervisor?.displayName,
      })
      summaryItemsData.push({
        id: 11,
        title: 'Team Leader',
        value: lateData.teamLeader?.displayName,
      })
      summaryItemsData.push({
        id: 12,
        title: 'Contacted By',
        value:
          lateData.contactByType === 'NoContactMade' ? 'No Contact Made' : lateData.contactByType,
      })
      summaryItemsData.push({
        id: 13,
        title: 'Date/Time',
        value: getDateTime24HrFormat(new Date(lateData.contactDateTime), false, ' - '),
      })
      summaryItemsData.push({ id: 7, title: 'Comments', value: lateData.comments })
      summaryItemsData.push({ id: 14, title: 'Contact Comments', value: lateData.contactComments })
      setSummary(summaryItemsData)
    }
  }

  useEffect(() => {
    if (
      userPermissions.includes('ManageRequests') &&
      (window.location.href.includes('myactions') || isComingFromMyActionsDashboard())
    ) {
      setIsAdmin(true)
    }
  }, [isComingFromMyActionsDashboard, userPermissions])

  useEffect(() => {
    fetchAllData()
  }, [lateData])

  const deleteLateDialog = () => {
    dispatch(
      showModalDialog({
        title: 'Are you sure?',
        message: `Are you sure you would like to delete this late occurrence?`,
        buttonLabel: 'Delete',
        type: 'question',
        showModal: true,
      })
    )
  }

  const { filteredAbsences } = useSelector((state: RootStore) => state.timeOff)

  const deleteLateOccurrence = (lateToDelete: any) => {
    manualRequestsService
      .deleteLateQuery(lateToDelete)
      .then(e => {
        dispatch(
          setFilteredAbsences(
            filteredAbsences?.filter(absence => absence.id !== Number(lateToDelete))
          )
        )
        dispatch(hideModal())
        dispatch(showSuccessMessage('Late occurrence has been deleted'))
        navigate(activeRoute || '/myactions')
      })
      .catch(err => {
        dispatch(hideModal())
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  const changeLateStatus = async (statusTypeId: any) => {
    const changeRequest = {
      lateStatusType: statusTypeId,
      lateId: lateData?.id,
      employeeId: lateData?.employeeResponse?.id,
      lateDate: lateData?.lateDate,
      lateTypeId: lateData?.lateTypeId,
      lateMinutes: lateData?.lateMinutes || 1,
      shiftDescription: lateData?.expectedShift.shiftString,
      contactByType: lateData?.contactByType || 0,
      contactByTypeId: lateData?.contactByTypeId,
      contactDateTime: lateData?.lateDate,
      toWorkBack: lateData?.toWorkBack || true,
      contactComments: lateData?.contactComments || '',
      comments: lateData?.comments || '',
    }

    lateData.lateStatusStr = getSelectValuesByType('LateStatus').find(
      x => x.value === statusTypeId
    )?.displayValue

    manualRequestsService
      .editLate(changeRequest)
      .then(data => {
        dispatch(showSuccessMessage('Late status changed'))
        fetchAllData()
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  const canDeleteLate = userPermissions.includes('DeleteAttendance')

  const subNav = [
    {
      label: 'Overview',
      onClick: () => setView('Overview'),
    },
    {
      label: 'Calendar',
      onClick: () => setView('Calendar'),
    },
    {
      label: 'History',
      onClick: () => setView('History'),
    },
    {
      label: 'Edit',
      onClick: () => {
        dispatch(showDrawer())
        setDrawerComponent({
          title: 'Edit',
          component: <EditLate lateId={parseInt(lateId as string, 10)} />,
        })
      },
    },
    {
      label: 'Delete',
      onClick: () => deleteLateDialog(),
      hidden: !canDeleteLate,
    },
  ]

  const employeeSubNav = [
    {
      label: 'Overview',
      onClick: () => setView('Overview'),
    },
  ]

  return (
    <>
      <Drawer
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        title={drawerComponent?.title || ''}
        showOptions={false}
      >
        {drawerComponent?.component}
      </Drawer>
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
        }}
        onClick={() => deleteLateOccurrence(lateId)}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
      />

      <MyActionsPageHeader title="Late Occurrence" />

      <Grid container spacing={4} mb={3}>
        <Grid item xs={12} md={8}>
          {lateData && (
            <>
              <InstanceStatusBanner
                showBackButton
                displayName={lateData?.employeeResponse?.displayName}
                status={lateData?.lateStatusStr || ''}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={4} display="flex" justifyContent="flex-end" alignItems="center">
          {isAdmin ? (
            <OccurrenceSubNav navItems={subNav} />
          ) : (
            <OccurrenceSubNav navItems={employeeSubNav} />
          )}
        </Grid>
      </Grid>

      {view === 'Calendar' && <AttendanceCalendar attendanceEvent={lateData} />}
      {view === 'Overview' && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <SummaryLayout summary={summary} isLoading={isLoading} isData={isData} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Notifications
                      isLoading={isLoading}
                      isData={isData}
                      absenceOverview={lateData?.absenceOverview}
                      attendanceDays={lateData?.lateDays ? [lateData?.lateDays] : []}
                    />
                  </Grid>
                  {isAdmin ? (
                    <Grid item xs={12}>
                      <Card title="Actions">
                        <Grid container spacing={4}>
                          <Grid item xs={12} lg={8}>
                            <DropdownMenu
                              label="Select Status"
                              id="lateStatus"
                              data={getSelectValuesByType('LateStatus')}
                              textField="displayValue"
                              valueField="value"
                              onChange={e => {
                                setLateStatus(parseInt(e.target.value, 10))
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            lg={4}
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                          >
                            <Button
                              label="Update"
                              style={{ marginTop: '0' }}
                              onClick={() => {
                                changeLateStatus(lateStatus)
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {view === 'History' && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card title="History">
              <LateHistory />
            </Card>
          </Grid>
        </Grid>
      )}

      <Drawer
        isOpen={showSideDrawer}
        onClose={() => {
          dispatch(hideDrawer())
        }}
        title={drawerComponent?.title}
        showOptions={false}
      >
        {drawerComponent?.component}
      </Drawer>
    </>
  )
}

export default Late
