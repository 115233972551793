import { Box, Grid, Stack, styled } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { isMobileDown, isMobileLandscapeOnly, isMobilePortrait } from '../../../theme/deviceChecks'

export const StyledGrid = styled(Grid)({
  [isMobileDown()]: {
    backgroundColor: '#fff',
    paddingBottom: '16px',

    '& h1': {
      fontSize: '16px',
      fontWeight: 'bold',
    },
  },
})

export const StyledStack = styled(Stack)({
  height: '50px',
  flexDirection: 'row',
  position: 'fixed',
  top: '-1px',
  justifyContent: 'space-between',
  backgroundColor: '#fff',
  zIndex: '1000',
  transition: 'height 0.2s ease',
  width: '100%',
  boxShadow: '1px 2px 10px #00000014',

  '&.headerTall': {
    height: '70px',
    '& h1': {
      fontSize: '16px',
    },
  },

  '& .MuiBox-root': {
    display: 'inline-flex',
    alignItems: 'center',
  },

  '& .MuiButtonBase-root': {
    right: '12px',
    width: '50px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    height: '100%',
  },

  '& h1': {
    fontSize: '13px',
    fontWeight: 'bold',
    display: 'flex',
    transition: 'font-size 0.2s ease',
    width: '100%',
  },

  [isMobilePortrait()]: {
    '& .MuiBox-root': {},
  },
})

export const MobilePageHeaderBox = styled(Box)({
  '&.MuiBox-root': {
    position: 'absolute',
    height: '100%',
    width: '40%',
    left: '30%',
    textAlign: 'center',
  },
  '&.MuiBox-root .MuiTypography-root': {
    display: 'block',
  },
})

export const MobilePageHeaderIconBox = styled(Box)({
  '&.MuiBox-root .MuiSvgIcon-root': {
    height: '20px',
    width: 'auto',
    transition: 'all 0.3s ease',
  },

  '&.MuiBox-root .MuiIconButton-root:hover': {
    backgroundColor: 'transparent',
  },

  '&.MuiBox-root .MuiTouchRipple-root': {
    height: '50px',
    width: '50px',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    top: '50%',
  },

  '.headerTall &.MuiBox-root .MuiSvgIcon-root': {
    height: '24px',
  },
})

export const PageHeaderStructureBox = styled(Box)({
  '&.MuiBox-root': {
    height: '70px',
    width: '100%',
    marginBottom: '32px',
  },
})

export const PageHeaderGrid = styled(Grid)`
  &.MuiGrid-root {
    display: flex;
    padding: 0 32px 16px;
  }

  & button {
    margin: 0;
  }

  ${isMobileLandscapeOnly()} {
    &.MuiGrid-root {
      flex-direction: row;
      justify-content: space-around;
    }

    & button {
      min-width: 350px;
    }
  }

  ${isMobilePortrait()} {
    &.MuiGrid-root {
      flex-direction: column;
    }
  }
`
export const OpenBurgerMenuButton = styled(MenuIcon)`
  &.MuiSvgIcon-root {
    position: absolute;
    padding-left: 30px;
    transition: all 0.2s ease;
    height: 28px;
    width: 28px;
  }

  .headerShort &.MuiSvgIcon-root {
    height: 26px;
    width: 26px;
  }
`
