import { Card } from '@mui/material'
import styled from 'styled-components'
import { isMobilePortrait } from '../../../theme/deviceChecks'

export const WhoIsAwayCard = styled(Card)`
  &.MuiCard-root {
    border-radius: 8px;
    padding: 30px;
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
  }

  ${isMobilePortrait()} {
    &.MuiCard-root {
      border-radius: 0;
    }
  }
`
