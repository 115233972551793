import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { oktaAuth } from '../../App'
import oktaService from '../oktaService'
import localStorageService from '../localStorageService'

const getBaseApiUrl = () => {
  const currentLocation = window.location.hostname

  switch (currentLocation) {
    case 'localhost': {

      return 'https://planner365.bss.dev.pub.gcp'
    }
    default: {

      return `https://${currentLocation}`
    }
  }
}

const httpServiceInstance = axios.create()
axios.defaults.headers.common['x-api-version'] = '1.0'

httpServiceInstance.interceptors.request.use(
  async config => {
    const token = oktaAuth.getAccessToken()

    if (!token) {
      await oktaService.resyncOctaWithRedirect()
      return Promise.reject(oktaService.tokenValidationError())
    }

    localStorageService.setIsOktaRedirecting(false)
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'x-api-version': 1.0,
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

const response = <T>(axiosResponse: AxiosResponse<T>) => axiosResponse.data

const buildUrl = (partialUrl: string)=>{
  const currentLocation = window.location.hostname
  const deskBookingUrl = `${getBaseApiUrl()}${partialUrl}`
  const localBookingApiUrl = `${getBaseApiUrl()}:8058${partialUrl}`
  const localDeskBookingApiUrl = `${getBaseApiUrl()}:8059${partialUrl}`

  if (!currentLocation.includes('localhost') || !getBaseApiUrl().includes('localhost')) {
    return deskBookingUrl
  }
  
  return partialUrl.includes('desk-booking') && getBaseApiUrl().includes('localhost') ? localDeskBookingApiUrl : localBookingApiUrl
}

const httpService = {
  get: <T>(url: string, config?: AxiosRequestConfig) => httpServiceInstance.get<T>(buildUrl(url), config).then(response),
  post: <T>(url: string, body?: unknown) => httpServiceInstance.post<T>(buildUrl(url), body).then(response),
  put: <T>(url: string, body: unknown) => httpServiceInstance.put<T>(buildUrl(url), body).then(response),
  patch: <T>(url: string, body: unknown) => httpServiceInstance.patch<T>(buildUrl(url), body).then(response),
  del: (url: string) => httpServiceInstance.delete(buildUrl(url)).then(response),
  delWithBody: (url: string, body: unknown) =>
    httpServiceInstance.delete(url, { data: body }).then(response),
}

export default httpService
