import { useMediaQuery } from '@mui/material/'
import { useOktaAuth } from '@okta/okta-react'
import { format } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import {
  setBankHolidays,
  setCalendarDetails,
  setCurrentEntitlementPeriodResponse,
  setDepartmentsResponse,
  setEmployeeDetails,
  setLoggedEmployeeDepartment,
  setLoggedEmployeeTeam,
  setSelectValues,
  setSidebarExpanded,
} from '../../../redux/reducers/appSettingsReducer'
import { showErrorMessage } from '../../../redux/reducers/snackbarReducer'
import {
  clearLoggedInUser,
  setLoggedInUser,
  setUserPermissions,
} from '../../../redux/reducers/userReducer'
import store, { RootStore, useAppDispatch } from '../../../redux/store'
import { dashboardService } from '../../../services/dashboardService'
import { manualRequestsService } from '../../../services/myActionsService'
import { permissionsService } from '../../../services/permissionsService'
import { isMobileDown, isTabletDown } from '../../../theme/deviceChecks'
import { BaseResponse } from '../../../types/base-response'
import UserErrorMessage from '../../../utils/errorFilter'
import Drawer from '../../UI/Drawer'
import NavigationItemsContainer from './NavigationItemsContainer'
import { ExpandCollapseButton, SidebarPane } from './components'
import { featureToggleService } from '../../../services/featureToggleService'
import {
  setNotificationLogs,
  setTerritoryAttendanceUK,
  setBirthdayRequest,
} from '../../../redux/reducers/featureToggleReducer'
import { featureToggleKeys } from '../../../types/feature-toggles'

export const refreshFeatureToggles = () => {
  featureToggleService
    .getAllFeatureToggles()
    .then(data => {
      if (data.features.some(feat => feat.key === featureToggleKeys.TerrAttUK.toString())) {
        const terrAttenUK = data.features.find(
          feat => feat.key === featureToggleKeys.TerrAttUK.toString()
        )?.isOn
        store.dispatch(setTerritoryAttendanceUK(terrAttenUK ?? false))
      }
      if (data.features.some(feat => feat.key === featureToggleKeys.NotificationLogs.toString())) {
        const notificationLogs = data.features.find(
          feat => feat.key === featureToggleKeys.NotificationLogs.toString()
        )?.isOn
        store.dispatch(setNotificationLogs(notificationLogs ?? false))
      }
      if (data.features.some(feat => feat.key === featureToggleKeys.Birthday.toString())) {
        const birthdayRequest = data.features.find(
          feat => feat.key === featureToggleKeys.Birthday.toString()
        )?.isOn
        store.dispatch(setBirthdayRequest(birthdayRequest ?? false))
      }
    })
    .catch(err => {
      /* Ignore 401  */
    })
}

function Sidebar() {
  const { authState, oktaAuth } = useOktaAuth()
  const mobile = useMediaQuery(isMobileDown())
  const tabletDown = useMediaQuery(isTabletDown())

  const dispatch = useAppDispatch()

  const expanded = useSelector<RootStore, boolean>(
    (state: RootStore) => state.appSettings.sideBarExpanded
  )

  const { departments, allTeams, employeeDetails } = useSelector(
    (state: RootStore) => state.appSettings
  )

  const dispatchSidebarAction = useCallback(
    (sideBarExpanded: boolean) => {
      dispatch(setSidebarExpanded(sideBarExpanded))
    },
    [dispatch]
  )

  const closeDrawer = useCallback(() => {
    dispatchSidebarAction(false)
  }, [dispatchSidebarAction])

  useEffect(() => {
    if (tabletDown) {
      dispatch(setSidebarExpanded(false))
    } else {
      dispatch(setSidebarExpanded(true))
    }
  }, [dispatch, tabletDown])

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      dispatch(clearLoggedInUser())
    } else {
      oktaAuth.getUser().then(info => {
        dispatch(setLoggedInUser(info))
      })

      dashboardService.getEmployeeDetails().then(data => {
        dispatch(setEmployeeDetails(data))
      })

      dashboardService.getSelectValues().then(data => {
        dispatch(setSelectValues(data.selectValues))
      })

      dashboardService.getEntitlementPeriodResponse().then(response => {
        dispatch(setCurrentEntitlementPeriodResponse(response))
      })

      dashboardService.getSelectValuesDepartments().then(data => {
        dispatch(setDepartmentsResponse(data))
      })

      dashboardService.getCalendarDetails().then(data => {
        dispatch(setCalendarDetails(data))
      })

      permissionsService
        .getUserPermissions()
        .then(data => dispatch(setUserPermissions(data.permissions)))
        .catch(err => {
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })

      refreshFeatureToggles()

      const dateMinusOneYear = new Date(new Date().setFullYear(new Date().getFullYear() - 2))
      const datePlusOneYear = new Date(new Date().setFullYear(new Date().getFullYear() + 6))

      manualRequestsService
        .getBankHolidays([
          format(dateMinusOneYear, 'yyyy-MM-dd'),
          format(datePlusOneYear, 'yyyy-MM-dd'),
        ])
        .then(bankHols => {
          dispatch(setBankHolidays(bankHols?.holidays))
        })
    }
  }, [authState, oktaAuth, dispatch])

  useEffect(() => {
    if (!employeeDetails || !departments) {
      return
    }

    const userDepartment = departments.find(dep => dep.value === employeeDetails.departmentId)
    if (!userDepartment) {
      return
    }

    const userTeam = allTeams.find(team => team.teamId === employeeDetails.teamId) ?? null
    dispatch(setLoggedEmployeeDepartment(userDepartment))
    dispatch(setLoggedEmployeeTeam(userTeam))
  }, [allTeams, departments, dispatch, employeeDetails])

  if (!authState || !authState.isAuthenticated) {
    return null
  }

  return (
    <>
      {isMobile || mobile ? (
        <Drawer isOpen={expanded} onClose={closeDrawer} anchor="left" className="mobileNavDrawer">
          <NavigationItemsContainer mobile={isMobile || mobile} closeDrawer={closeDrawer} />
        </Drawer>
      ) : (
        <SidebarPane expanded={expanded} className="desktopNavDrawer">
          {!tabletDown && (
            <ExpandCollapseButton
              expanded={expanded}
              onClick={() => {
                dispatchSidebarAction(!expanded)
              }}
            />
          )}

          <NavigationItemsContainer mobile={mobile} />
        </SidebarPane>
      )}
    </>
  )
}

export default Sidebar
