import { Grid, Skeleton, Stack } from '@mui/material'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import Paragraph from '../../shared/UI/Paragraph'
import { EntitlementCard, StyledDescriptor, StyledNumbers } from './components'
import FullComponentLoadingIcon from '../../shared/UI/LoadingIndicator/FullComponentLoadingIcon'

type Props = {
  title?: string | number
  allocation?: number
  summerSplit?: number
  winterSplit?: number
  seasonalSplit?: boolean | undefined
  changingYear?: boolean
}

function MyRequestsEntitlementCard({
  title,
  allocation,
  summerSplit,
  winterSplit,
  seasonalSplit,
  changingYear = false,
}: Props) {
  return (
    <FullComponentLoadingIcon
      loading={seasonalSplit === undefined}
      sx={{ boxShadow: '1px 2px 10px #00000014' }}
    >
      <EntitlementCard hasEntitlementSplit={seasonalSplit}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <StyledDescriptor>{title}</StyledDescriptor>
              <StyledNumbers hasEntitlementSplit={Boolean(seasonalSplit)}>
                {changingYear || allocation === undefined ? (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    sx={{ height: '23px', margin: '5px 0', width: '75px' }}
                  />
                ) : (
                  allocation?.toFixed(2)
                )}
              </StyledNumbers>
            </Stack>
            {seasonalSplit ? (
              <>
                <Stack direction="row" justifyContent="space-between" mt="19px" alignItems="center">
                  <Stack direction="row" gap={1} alignItems="center">
                    <WbSunnyIcon sx={{ color: 'white', height: '13px' }} />
                    {changingYear || summerSplit === undefined ? (
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        sx={{ height: '13px', width: '30px' }}
                      />
                    ) : (
                      <Paragraph color="white" style={{ lineHeight: 0 }}>
                        {summerSplit?.toFixed(2)}
                      </Paragraph>
                    )}
                  </Stack>

                  <Stack direction="row" gap={1} alignItems="center">
                    <AcUnitIcon sx={{ color: 'white', height: '13px' }} />
                    {changingYear || winterSplit === undefined ? (
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        sx={{ height: '13px', width: '30px' }}
                      />
                    ) : (
                      <Paragraph color="white" style={{ lineHeight: 0 }}>
                        {winterSplit?.toFixed(2)}
                      </Paragraph>
                    )}
                  </Stack>
                </Stack>
              </>
            ) : null}
          </Grid>
        </Grid>
      </EntitlementCard>
    </FullComponentLoadingIcon>
  )
}

export default MyRequestsEntitlementCard
