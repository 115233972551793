import { AbsenceOccurrence } from "../../../../types/absence"
import { LocationGroups } from "../../../../types/location-groups"
import { getIsCoveredBySickNote } from "./actionsHelpers"

interface GetScaFunction {
    location: LocationGroups
    getScaText: (docName: string, absence: AbsenceOccurrence) => string
  }
  
export const getScaTextFunctions: GetScaFunction[] = [
    {
        location: LocationGroups.NONE,
        getScaText: (docName: string, absence: AbsenceOccurrence) => {
        if ((!absence.scaRequired || getIsCoveredBySickNote(absence))) {
            return `${docName}: Not Required`
        }
        if (absence.hasValidSca) {
            return `View ${docName}`
        }
        return `Awaiting ${docName}`
        }
    },
    {
        location: LocationGroups.UK,
        getScaText: (docName: string, absence: AbsenceOccurrence) => absence.hasValidSca ? `View ${docName}` : `Awaiting ${docName}`
    }
]