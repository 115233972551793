import { useState } from 'react'
import { StyledButton, StyledButtonGroup } from './components'

interface NavItem {
  label: string
  onClick: () => void
  hidden?: boolean
}

function OccurrenceSubNav({ navItems }: { navItems: NavItem[] }) {
  const [selectedLink, setSelectedLink] = useState('Overview')

  return (
    <StyledButtonGroup variant="contained" aria-label="outlined primary button group">
      {navItems.map(
        item =>
          !item.hidden && (
            <StyledButton
              onClick={() => {
                if (item.label !== 'Edit' && item.label !== 'Delete') {
                  setSelectedLink(item.label)
                }
                item.onClick()
              }}
              activeLink={selectedLink === item.label}
            >
              {item.label}
            </StyledButton>
          )
      )}
    </StyledButtonGroup>
  )
}

export default OccurrenceSubNav
