import { format } from 'date-fns'
import { Button, Collapse, Grid, IconButton, Stack } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import DownloadIcon from '@mui/icons-material/Download'
import styled from 'styled-components'

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStore, useAppDispatch } from '../../../../redux/store'

import theme from '../../../../theme/theme'
import HolidaysAvailabilitySearchForm from './HolidaysAvailabilitySearchForm'
import { Search } from './types'
import HolidaysAvailabilityGrid from './HolidaysAvailabilityGrid'
import LoadingIndicator from '../../../../shared/UI/LoadingIndicator'
import { BaseResponse } from '../../../../types/base-response'
import { showErrorMessage } from '../../../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../../../utils/errorFilter'

import { ssrsHolidayReportService } from '../../../../services/ssrsHolidayReportService'
import {
  HolidaysAvailabilityItem,
  HolidaysAvailabilityResponse,
} from '../../../../types/holidays-availability-response'
import RefreshButton from '../../../../shared/UI/RefreshButton'
import DownloadButton from '../../../../shared/UI/DownloadButton'
import FilterButton from '../../../../shared/UI/FilterButton'

export const LoadingIndicatorContainer = styled.div`
  padding-top: 70px;
  padding-bottom: 70px;
`

function HolidaysAvailabilitySearch() {
  const dispatch = useAppDispatch()
  const [resetForm, setResetForm] = useState(false)
  const [showHeaderButtons, setShowHeaderButtons] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(true)
  const [showGrid, setShowGrid] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<HolidaysAvailabilityItem[]>([])

  const [transformedCols, setTransformedCols] = useState<string[]>([])
  const [transformedRows, setTransformedRows] = useState<any[]>([])

  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)

  const handleSearch = (search: Search) => {
    setIsLoading(true)
    setShowGrid(false)

    setTimeout(() => {
      setShowSearch(false)
    }, 500)

    ssrsHolidayReportService
      .getHolidaysAvailabilityReports(
        search.fromDateRangeFrom !== ''
          ? search.fromDateRangeFrom!
          : format(new Date(currentEntitlementPeriodResponse!.startDate), 'yyyy-MM-dd'),
        search.fromDateRangeTo !== ''
          ? search.fromDateRangeTo!
          : format(new Date(currentEntitlementPeriodResponse!.endDate), 'yyyy-MM-dd'),
        search.directReportees!
      )
      .then((res: HolidaysAvailabilityResponse) => {
        const result: HolidaysAvailabilityItem[] = res.rows
          .map((row: HolidaysAvailabilityItem, index: number) => {
            const item = { ...row, id: index }
            return item
          })
          .filter(
            row =>
              row.requestStatus?.toLowerCase() !== 'buy' &&
              row.requestStatus?.toLowerCase() !== 'sell' &&
              row.requestStatus?.toLowerCase() !== 'adjustment'
          )

        setData(result)
        setShowHeaderButtons(true)
        setShowGrid(true)
        setIsLoading(false)
      })
      .catch(err => {
        setShowHeaderButtons(true)
        setShowGrid(true)
        setIsLoading(false)
        setShowSearch(true)

        const response: BaseResponse = err.response.data
        if (response.errors) {
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        } else {
          dispatch(showErrorMessage(<UserErrorMessage name="CurrentlyUnavailable" />))
        }
      })
  }

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowSearch(!showSearch)
  }

  const handleRefreshClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setResetForm(!resetForm)

    setShowSearch(true)
    setShowHeaderButtons(false)
    setShowGrid(false)
  }

  const makeCsv = async (filename: string) => {
    const headers: string[] = transformedCols

    const csvData: string[] = []

    for (let i = 0; i < transformedRows.length; i += 1) {
      const row: any = transformedRows[i]
      let csvLine: string = row.employeeName

      for (let ii = 1; ii < transformedCols.length; ii += 1) {
        const date: string = transformedCols[ii]
        const col: any = row[date]

        if (col.hasData) {
          csvLine += `,${col.requestStatus}`
        } else {
          csvLine += ','
        }
      }

      csvData.push(csvLine)
    }

    const csvContent: string = csvData.map((line: string) => line).join('\n')

    const csvHeaders: string = headers.join(',')

    const blob = new Blob([`${csvHeaders}\n`, csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')

    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const handleDownloadClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    makeCsv('HolidaysAvailability.csv')
  }

  const onGridLoad = (transformedGridCols: string[], transformedGridRows: any[]) => {
    setTransformedCols(transformedGridCols)
    setTransformedRows(transformedGridRows)
  }

  return (
    <>
      {showHeaderButtons && (
        <Grid container style={{ border: '0px red solid' }}>
          <Stack
            direction="row"
            style={{ border: '0px green solid', width: '100%' }}
            spacing={2}
            display="flex"
            justifyContent="flex-end"
          >
            <FilterButton onClick={handleFilterClick} title="Change Filters" />
            {data && data.length >= 1 && (
              <DownloadButton onClick={handleDownloadClick} title="Download Report" />
            )}
            <RefreshButton onClick={handleRefreshClick} title="Refresh" />
          </Stack>
        </Grid>
      )}
      <Collapse orientation="vertical" in={showSearch}>
        <HolidaysAvailabilitySearchForm resetForm={resetForm} onSearch={handleSearch} />
      </Collapse>
      <Grid item xs={12}>
        {!isLoading && showGrid && <HolidaysAvailabilityGrid data={data} onLoad={onGridLoad} />}
        {isLoading && (
          <LoadingIndicatorContainer>
            <LoadingIndicator show={isLoading} />
          </LoadingIndicatorContainer>
        )}
      </Grid>
    </>
  )
}

export default HolidaysAvailabilitySearch
