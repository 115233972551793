import styled from 'styled-components'

export const DrawerFooterContainer = styled.div`
  width: 100%;
  margin-top: 10px;

  & .MuiStack-root {
    justify-content: flex-end;
  }
`
