export interface featureToggleState {
  territoryAttendanceUK: boolean
  notificationLogs: boolean
  birthdayRequest: boolean
  enhancement?: boolean
}

export const initialState: featureToggleState = {
  territoryAttendanceUK: false,
  notificationLogs: false,
  birthdayRequest: false,
  enhancement: undefined,
}

// Action Types
const SET_TERRITORY_ATTENDANCE_UK = 'SET_TERRITORY_ATTENDANCE_UK'
const NOTIFICATION_LOGS = 'NOTIFICATION_LOGS'
const SET_BIRTHDAY_REQUEST = 'SET_BIRTHDAY_REQUEST'
const SET_ENHANCEMENT = 'SET_ENHANCEMENT'

// Action Creators
interface SetTerritoryAttendanceUK {
  type: typeof SET_TERRITORY_ATTENDANCE_UK
  payload: boolean
}

interface SetNotificationLogs {
  type: typeof NOTIFICATION_LOGS
  payload: boolean
}

interface SetBirthdayRequest {
  type: typeof SET_BIRTHDAY_REQUEST
  payload: boolean
}

interface SetEnhancement {
  type: typeof SET_ENHANCEMENT
  payload?: boolean
}

type featureToggleDispatchTypes =
  | SetTerritoryAttendanceUK
  | SetNotificationLogs
  | SetBirthdayRequest
  | SetEnhancement

// Actions
export const setTerritoryAttendanceUK = (isOn: boolean): SetTerritoryAttendanceUK => ({
  type: SET_TERRITORY_ATTENDANCE_UK,
  payload: isOn,
})

export const setNotificationLogs = (isOn: boolean): SetNotificationLogs => ({
  type: NOTIFICATION_LOGS,
  payload: isOn,
})

export const setBirthdayRequest = (isOn: boolean): SetBirthdayRequest => ({
  type: SET_BIRTHDAY_REQUEST,
  payload: isOn,
})

export const setEnhancement = (isOn?: boolean): SetEnhancement => ({
  type: SET_ENHANCEMENT,
  payload: isOn,
})

// Reducer
export const featureToggleReducer = (
  state: featureToggleState = initialState,
  action: featureToggleDispatchTypes
): featureToggleState => {
  switch (action.type) {
    case SET_TERRITORY_ATTENDANCE_UK:
      return { ...state, territoryAttendanceUK: action.payload }
    case NOTIFICATION_LOGS:
      return { ...state, notificationLogs: action.payload }
    case SET_BIRTHDAY_REQUEST:
      return { ...state, birthdayRequest: action.payload }
    case SET_ENHANCEMENT:
      return { ...state, enhancement: action.payload }
    default:
      return state
  }
}
