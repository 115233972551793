import styled from '@emotion/styled'
import { Box, Button } from '@mui/material'

const defaultPrimaryStepColour = '#20C5A0'

interface WizardStepContainer {
  primaryStepColour: string
}

export const WizardStepContainer = styled(Box)(
  ({ primaryStepColour = defaultPrimaryStepColour }:WizardStepContainer) => ({
    backgroundColor: `${primaryStepColour}19`,
    display: 'inline-block',
    borderRadius: '14px',
    padding: 0,
    height: '30px',
  })
)

interface WizardStepElement {
  selected: boolean
  primaryStepColour: string
  allowClick: boolean
}

export const WizardStepElement = styled(Button)(
  ({ selected, primaryStepColour = defaultPrimaryStepColour, allowClick }:WizardStepElement) => ({
    '&': {
      margin: '2px',
      padding: '3px 18px',
      backgroundColor: `${selected ? primaryStepColour : 'transparent'}`,
      borderRadius: '14px',
      fontSize: '11px',
      fontWeight: 'bold',
      color: `${selected ? 'white' : primaryStepColour}`,
      cursor: `${allowClick ? 'pointer' : 'default'}`,
    },
    '&:hover': {
      backgroundColor: `${selected ? primaryStepColour : 'transparent'}`,
    },
  })
)
