import { SelectOption } from '../../services/dashboardService'

export interface MyRequestsState {
  selectedYear: SelectOption | null
  filtersOff: string[]
}

export const myRequestsInitialState: MyRequestsState = {
  selectedYear: null,
  filtersOff: [],
}

const SET_SELECTED_YEAR = 'SET_SELECTED_YEAR'

interface SetSelectedYearAction {
  type: typeof SET_SELECTED_YEAR
  payload: SelectOption | null
}

type myRequestsDispatchtypes = SetSelectedYearAction

// Actions
export const setSelectedYear = (year: SelectOption | null): SetSelectedYearAction => ({
  type: SET_SELECTED_YEAR,
  payload: year,
})

// Reducer
export const myRequestsReducer = (
  state: MyRequestsState = myRequestsInitialState,
  action: myRequestsDispatchtypes
): MyRequestsState => {
  switch (action.type) {
    case SET_SELECTED_YEAR:
      return { ...state, selectedYear: action.payload }
    default:
      return state
  }
}
