import { useEffect, useState } from 'react'
import { LocationsType } from '../SettingsOptions/settingsTypes'
import { settingsService } from '../../services/settingsService'

export const useLocations = () => {
  const [locations, setLocations] = useState<LocationsType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  useEffect(() => {
    setIsLoading(true)
    settingsService
      .getLocation()
      .then(locs => {
        setLocations(locs.absenceLocations)
        setIsLoading(false)
      })
      .catch(err => {
        setError(err)
        setIsLoading(false)
      })
  }, [])
  return {
    locations,
    isLoading,
    error,
    setLocations,
  }
}
