import NoDataImg from '../../../assets/noData.svg'
import { NoDatFoundContainer, NoDataFoundImg } from './components'

interface NoDataFoundProps {
  show: boolean
}
function NoDataFound({ show }: NoDataFoundProps) {
  if (!show) return null
  return (
    <NoDatFoundContainer>
      <NoDataFoundImg src={NoDataImg} alt="No Data" />
    </NoDatFoundContainer>
  )
}

export default NoDataFound
