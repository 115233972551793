import { Grid, TextField } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'
import Button from '../../shared/UI/Button'
import DrawerFooter from '../../shared/UI/DrawerFooter'
import DatePicker from '../../shared/UI/DatePicker'
import { settingsService } from '../../services/settingsService'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { requestFieldsDefaultState, RequestFieldsModal } from '../../models'
import { BaseResponse } from '../../types/base-response'
import { DepartmentThresholdsResponse } from '../../types/department-thresholds-response'
import Paragraph from '../../shared/UI/Paragraph'
import UserErrorMessage from '../../utils/errorFilter'

type Props = {
  onClose: () => void
  refresh: () => void
  deptTeamId: number
  thresholds: DepartmentThresholdsResponse | undefined
}

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

function AddThresholdWeek({ onClose, refresh, deptTeamId, thresholds }: Props) {
  const dispatch = useDispatch()

  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [fromDate, setFromDate] = useState<Date | null>(null)
  const [toDate, setToDate] = useState<Date | null>(null)
  const [fieldsTouched, setFieldsTouched] = useState<RequestFieldsModal>(requestFieldsDefaultState)
  const [numberOfHours, setNumberOfHours] = useState<Record<string, number>>({
    Mon: 0,
    Tue: 0,
    Wed: 0,
    Thu: 0,
    Fri: 0,
    Sat: 0,
    Sun: 0,
  })

  const invalidDates = useMemo(
    () => thresholds?.thresholdWeeks.map(d => new Date(d.dateFrom)),
    [thresholds?.thresholdWeeks]
  )

  const anyDaysSet = useCallback(() => {
    const hoursValues = Object.values(numberOfHours)
    for (let i = 0; i < hoursValues.length; i += 1) {
      if (hoursValues[i] && !Number.isNaN(hoursValues[i])) {
        return true
      }
    }
    return false
  }, [numberOfHours])

  const anyHoursGreaterThanMax = useCallback(
    () => Object.values(numberOfHours).find(x => x > 9999),
    [numberOfHours]
  )

  const handleFromDateChange = useCallback((selectedDate: Date | null) => {
    if (selectedDate) {
      setFromDate(selectedDate)
      const date = new Date(selectedDate)
      date.setDate(date.getDate() + 6)
      setToDate(date)
    }
  }, [])

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      if (fromDate !== null && toDate !== null) {
        setSubmitLoading(true)
        settingsService
          .postThresholdWeek({
            departmentTeamId: deptTeamId,
            dateFrom: new Date(format(fromDate, 'yyyy-MM-dd')),
            dateTo: new Date(format(toDate, 'yyyy-MM-dd')),
            monHours: numberOfHours.Mon,
            tueHours: numberOfHours.Tue,
            wedHours: numberOfHours.Wed,
            thuHours: numberOfHours.Thu,
            friHours: numberOfHours.Fri,
            satHours: numberOfHours.Sat,
            sunHours: numberOfHours.Sun,
          })
          .then(e => {
            setSubmitLoading(false)
            dispatch(showSuccessMessage('Threshold week added.'))
            onClose()
            refresh()
          })
          .catch(err => {
            setSubmitLoading(false)
            const response: BaseResponse = err.response.data
            response.errors.forEach(error => {
              dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
            })
          })
      }
    },
    [deptTeamId, dispatch, fromDate, numberOfHours, onClose, refresh, toDate]
  )

  const isFormValid = useCallback(() => {
    setFieldsTouched({ ...fieldsTouched, date: true, time: true })

    if (!fromDate) {
      return 'noFromDate'
    }

    if (!anyDaysSet()) {
      return 'noDaysSet'
    }

    if (anyHoursGreaterThanMax()) {
      return 'greaterThanMax'
    }

    return 'valid'
  }, [anyDaysSet, anyHoursGreaterThanMax, fieldsTouched, fromDate])

  return (
    <Grid
      component="form"
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formStatus = isFormValid()

        if (formStatus === 'valid') {
          handleSubmit(event)
        }

        if (formStatus === 'noFromDate') {
          dispatch(showErrorMessage('Please select a date'))
        }

        if (formStatus === 'noDaysSet') {
          dispatch(showErrorMessage('At least one day must have a value'))
        }

        if (formStatus === 'greaterThanMax') {
          dispatch(showErrorMessage('Selected day(s) value must be between 1 and 9999'))
        }
      }}
    >
      <Grid container columnSpacing={4} rowSpacing={2}>
        <Grid item xs={12} pb={2}>
          <Paragraph weight="bold">Details</Paragraph>
        </Grid>
        <Grid item xs={12} lg={6} pb={2}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <DatePicker
                label="Date from"
                value={fromDate}
                onChange={e => {
                  setFieldsTouched({ ...fieldsTouched, date: true })
                  handleFromDateChange(e)
                }}
                error={fieldsTouched.date && fromDate === null}
                daysToDisable={[0, 2, 3, 4, 5, 6]}
                thresholdDates={invalidDates}
                helperText="Required"
              />
            </Grid>
            <Grid item xs={12} lg={6} sx={{ pointerEvents: 'none' }}>
              <DatePicker label="Date to" value={toDate} onChange={e => e} disabled />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={4}>
            {days.map(day => (
              <Grid item xs={12} key={day}>
                <TextField
                  fullWidth
                  label={day}
                  onChange={e => {
                    setFieldsTouched({ ...fieldsTouched, time: true })
                    setNumberOfHours({
                      ...numberOfHours,
                      [day]: +e.target.value,
                    })
                  }}
                  type="number"
                  onBlur={e => {
                    setFieldsTouched({ ...fieldsTouched, time: true })
                  }}
                  error={(fieldsTouched.time && !anyDaysSet()) || numberOfHours[day] > 9999}
                  InputProps={{ inputProps: { min: 0, max: 9999 } }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <DrawerFooter>
        <Button color="secondary" label="Cancel" onClick={onClose} />
        <Button label="Submit" type="submit" loading={submitLoading} />
      </DrawerFooter>
    </Grid>
  )
}

export default AddThresholdWeek
