import { LateRequestModel } from '../components/LateDrawerComponent/model'
import { BaseResponse } from '../types/base-response'
import { ComingUpResponse } from '../models/ComingUpResponse'
import {
  CreateDayOffRequest,
  CreateMultiDayRequest,
  CreateShiftRequest,
  RequestClashResponse,
} from '../models/request'
import { RequestSearch } from '../models/request-search'
import { httpServiceV1 } from './httpServiceV1'
import { AbsenceCreateRequest } from '../types/absence'
import {
  MyRequestDetailResponse,
  RequestDetailResponse,
  RequestUpdateRequest,
} from '../types/requests'
import { RotaShift } from '../types/rota-shift'

const requestsBaseURL = 'myactions/requests'

const requestsService = {
  createHolidayRequest: async (body: CreateMultiDayRequest): Promise<BaseResponse> =>
    httpServiceV1.post(`${requestsBaseURL}/holiday/create`, body),

  createDayoffRequest: async (body: CreateDayOffRequest): Promise<BaseResponse> =>
    httpServiceV1.post(`${requestsBaseURL}/dayoff/create`, body),

  createShiftRequest: async (body: CreateShiftRequest): Promise<BaseResponse> =>
    httpServiceV1.post(`${requestsBaseURL}/shift/create`, body),

  createLieuDayRequest: async (body: CreateMultiDayRequest): Promise<BaseResponse> =>
    httpServiceV1.post(`${requestsBaseURL}/lieuday/create`, body),

  createWFHRequest: async (body: CreateMultiDayRequest): Promise<BaseResponse> =>
    httpServiceV1.post(`${requestsBaseURL}/wfh/create`, body),

  createAbsenceRequest: async (
    body: AbsenceCreateRequest,
    forceCreate: boolean
  ): Promise<BaseResponse> =>
    httpServiceV1.post(`myactions/absence`, forceCreate ? { ...body, force: true } : body),
  createAbsenceUKRequest: async (
    body: AbsenceCreateRequest,
    forceCreate: boolean
  ): Promise<BaseResponse> =>
    httpServiceV1.post(`myactions/absenceuk`, forceCreate ? { ...body, force: true } : body),
  createLateRequest: async (body: LateRequestModel): Promise<BaseResponse | RequestClashResponse> =>
    httpServiceV1.post(`myactions/late`, body),
  search: async (body: RequestSearch): Promise<ComingUpResponse> =>
    httpServiceV1.post(`${requestsBaseURL}/search`, body),

  createRequest: async (): Promise<Request> =>
    httpServiceV1.get(`${requestsBaseURL}/manager/holidayrequest`),

  getNextScheduledShift: async (employeeId: number, date: string): Promise<Request> =>
    httpServiceV1.get(`myactions/nextScheduledShift?employeeid=${employeeId}&date=${date}`),

  getShiftDescription: async (employeeId: number, date: string): Promise<RotaShift> =>
    httpServiceV1.get(`myactions/shift?employeeid=${employeeId}&shiftDate=${date}`),

  createShiftDescription: async (employeeId: number, shift: string): Promise<Request> =>
    httpServiceV1.get(
      `${requestsBaseURL}/myactions/shift?employeeid=${employeeId}&shiftdate=${shift}`
    ),

  updateHolidayRequest: async (body: RequestUpdateRequest): Promise<BaseResponse> =>
    httpServiceV1.put(`myactions/request`, body),

  getMyRequestDetail: async (requestId: number): Promise<MyRequestDetailResponse> =>
    httpServiceV1.get(`myrequests/MyRequestDetail?RequestId=${requestId}`),

  checkRequestExists: async (requestId: number): Promise<boolean> =>
    httpServiceV1.get(`myactions/CheckIfRequestIdExists?requestId=${requestId}`),
}

export { requestsService }
