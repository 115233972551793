import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import Button from '../../../shared/UI/Button'
import Paragraph from '../../../shared/UI/Paragraph'
import { RootStore } from '../../../redux/store'
import { BookingWizardSteps } from './enums'
import { showComponents } from '../utils'
import {
  BookingBlockBookingFinalSelection,
  setBookingWizardFinalSelectionState,
  setBookingWizardSelectedAlternativeFeatureState,
  setBookingWizardStepState,
} from '../../../redux/reducers/deskBookingWizardReducer'
import { BookingBlockStatusProps } from './types'
import { BookingBookingPayload } from '../../../services/booking/types'
import { BookingChangeDeskButton } from './components'
import { setBookingFloorplanViewingDate } from '../../../redux/reducers/deskBookingReducer'
import { dateToNumber } from '../utils/utils'


export function BookingBlockStatus({ showLabels }: BookingBlockStatusProps) {
  const [wizardStepLabel, setWizardStepLabel] = useState('')
  const dispatch = useDispatch()

  const { floorplanViewingDate } = useSelector((state: RootStore) => state.deskBooking)
  const {
    currentStep: wizardCurrentStep,
    finalSelection,
    selectedFeature,
    selectedAlternativeFeatureID,
  } = useSelector((state: RootStore) => state.deskBookingWizard)
  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)

  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  const isManager = userPermissions.includes('BookingManager')

  const getIsManagerOrIsOtherEmployee = (thisConflict: BookingBookingPayload): boolean =>
    isManager ? true : thisConflict.employeeId !== employeeDetails.employeeId

  const thisConflictIsSelectedFeature = (thisConflict: BookingBookingPayload[]) =>
    thisConflict.filter(
      (d: BookingBookingPayload) =>
        d.featureId === selectedFeature?.id && getIsManagerOrIsOtherEmployee(d)
    )

  const deskFeatureDateActiveWithConflictForDiffeUserOrManager = (
    selection: BookingBlockBookingFinalSelection
  ): boolean =>
    selection.active &&
    selection.conflicts.length > 0 &&
    thisConflictIsSelectedFeature(selection.conflicts).length > 0

  const checkSomeDeskDatesHaveConflictForDiffeUserOrManager = (): boolean =>
    finalSelection.some(s => deskFeatureDateActiveWithConflictForDiffeUserOrManager(s))

  const getSummaryState = (): string => {
    if (checkSomeDeskDatesHaveConflictForDiffeUserOrManager()) {
      const numberOfConflicts =
        finalSelection[0] &&
        finalSelection.filter(f => deskFeatureDateActiveWithConflictForDiffeUserOrManager(f)).length

      return `Resolve ${numberOfConflicts > 1 ? `${numberOfConflicts} Conflicts` : 'Conflict'}`
    }

    const s = finalSelection[0] && finalSelection.filter(d => d.active)[1] ? 's' : ''
    const numberOfDesks = finalSelection[0] ? finalSelection.filter(d => d.active).length : 0

    return `Book ${numberOfDesks > 1 ? `${numberOfDesks} ` : ''} Desk${s}`
  }

  const getResolveState = (): string => {

    let resolved = finalSelection.filter(
      f => f.alternativeFeatureId &&
        deskFeatureDateActiveWithConflictForDiffeUserOrManager(f)
    ).length + 1
    
    const conflicts = finalSelection.filter(f =>
      deskFeatureDateActiveWithConflictForDiffeUserOrManager(f)
    ).length

    if (conflicts === 1) {
      resolved = 1
    }

    if (resolved === conflicts) {
      return `Resolve ${resolved} of ${conflicts} and Book`
    }

    return `Resolve ${resolved} of ${conflicts}`
  }

  useEffect(() => {
    const nextConflict = finalSelection.find(
      f => deskFeatureDateActiveWithConflictForDiffeUserOrManager(f) &&
        !f.alternativeFeatureId
    )
    if (nextConflict?.date) {
      dispatch(setBookingFloorplanViewingDate(nextConflict?.date))
    }
  }, [wizardStepLabel])

  useEffect(() => {
    switch (wizardCurrentStep) {
      case BookingWizardSteps.STEP_3_SUMMARY:
        setWizardStepLabel(getSummaryState())
        break
      case BookingWizardSteps.STEP_4_ALTERNATIVES:
        setWizardStepLabel(getResolveState())
        break
      case BookingWizardSteps.STEP_2_SELECT_DESK:
        setWizardStepLabel('Next')
        break
      case BookingWizardSteps.STEP_5_SUBMIT:
        setWizardStepLabel('Please wait...')
        break
      case BookingWizardSteps.STEP_3_UNAVAILABLE:
        setWizardStepLabel('Select a date')
        break
      case BookingWizardSteps.STEP_0_INACTIVE:
      case BookingWizardSteps.STEP_1_SEARCH:
      default:
        setWizardStepLabel('')
    }
  }, [wizardCurrentStep, finalSelection])

  const featureConflictClaimNotUserWithNoAltSet = (
    selection: BookingBlockBookingFinalSelection
  ): boolean =>
    !selection.alternativeFeatureId &&
    deskFeatureDateActiveWithConflictForDiffeUserOrManager(selection)

  const applyAlternativeFeatureAndFinish = () => {
    const conflict = finalSelection.find(
      f =>
        featureConflictClaimNotUserWithNoAltSet(f) &&
        f.dateNumber === dateToNumber(floorplanViewingDate)
    )
    if (conflict && selectedAlternativeFeatureID) {
      dispatch(
        setBookingWizardFinalSelectionState([
          ...(finalSelection.filter(
            f => f.id !== conflict?.id
          ) as BookingBlockBookingFinalSelection[]),
          {
            ...conflict,
            alternativeFeatureId: selectedAlternativeFeatureID,
          } as BookingBlockBookingFinalSelection,
        ])
      )
      dispatch(setBookingWizardSelectedAlternativeFeatureState(undefined))
      return !finalSelection.some(
        f =>
          featureConflictClaimNotUserWithNoAltSet(f) &&
          f.dateNumber !== dateToNumber(floorplanViewingDate)
      )
    }
    return true
  }

  const buttonDisabled = () => {
    if (wizardCurrentStep === BookingWizardSteps.STEP_5_SUBMIT) {
      return true
    }
    if (wizardCurrentStep === BookingWizardSteps.STEP_2_SELECT_DESK && !selectedFeature) {
      return true
    }
    if (wizardCurrentStep === BookingWizardSteps.STEP_3_UNAVAILABLE) {
      return true
    }
    return false
  }

  const resolvingDeskBooking = finalSelection.find(
    f => f.conflicts.length > 0 && f.active && !f.alternativeFeatureId
  )

  return (
    <Grid
      container
      xs={12}
      width="100%"
      height="92px"
      bgcolor="#F7F7F7"
      zIndex={1100}
      bottom={0}
      position="sticky"
    >
      <Grid item xs={6} display="flex" justifyContent="flex-start" alignItems="center" pl="32px">
        {showLabels ? (
          <>
            <Paragraph
              size="11px"
              color="white"
              padding="2px 10px"
              style={{
                backgroundColor: '#70BA82',
                display: 'inline-block',
                borderRadius: '3px',
                marginRight: '32px',
              }}
            >
              Available
            </Paragraph>
            <Paragraph
              size="11px"
              color="white"
              padding="2px 10px"
              style={{
                backgroundColor: '#DB8733',
                display: 'inline-block',
                borderRadius: '3px',
                marginRight: '32px',
              }}
            >
              Limited Availability
            </Paragraph>
            <Paragraph
              size="11px"
              color="white"
              padding="2px 10px"
              style={{
                backgroundColor: '#BA7070',
                display: 'inline-block',
                borderRadius: '3px',
              }}
            >
              No Availability
            </Paragraph>
          </>
        ) : (
          <p />
        )}
      </Grid>
      <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center" pr="32px">
        {wizardCurrentStep === BookingWizardSteps.STEP_2_SELECT_DESK && (
          <BookingChangeDeskButton
            onClick={() => {
              dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_1_SEARCH))
              showComponents(dispatch, { search: true, gridview: true, navbar: true, blockBookingWizard: false, floorplan: false })
            }}
          >
            Cancel
          </BookingChangeDeskButton>
        )}
        {(wizardCurrentStep === BookingWizardSteps.STEP_3_SUMMARY || wizardCurrentStep === BookingWizardSteps.STEP_3_UNAVAILABLE) && (
          <BookingChangeDeskButton
            onClick={() => {
              showComponents(dispatch, { blockBookingWizard: true, floorplan: true, search: true })
              dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_2_SELECT_DESK))
            }}
          >
            Change desk
          </BookingChangeDeskButton>
        )}
        {wizardCurrentStep === BookingWizardSteps.STEP_4_ALTERNATIVES && (
          <BookingChangeDeskButton
            onClick={() => {
              showComponents(dispatch, { blockBookingSummary: true, blockBookingWizard: true, search: true })
              dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_3_SUMMARY))
            }}
          >
            Back to summary
          </BookingChangeDeskButton>
        )}
        <Button
          label={wizardStepLabel}
          style={{ margin: 0, textTransform: 'none' }}
          disabled={buttonDisabled()}
          onClick={() => {
            switch (wizardCurrentStep) {
              case BookingWizardSteps.STEP_2_SELECT_DESK:
                showComponents(dispatch, { blockBookingSummary: true, blockBookingWizard: true, search: true })
                return dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_3_SUMMARY))
              case BookingWizardSteps.STEP_3_SUMMARY:
                if (!checkSomeDeskDatesHaveConflictForDiffeUserOrManager()) {
                  return dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_5_SUBMIT))
                }
                if (resolvingDeskBooking) {
                  dispatch(setBookingFloorplanViewingDate(new Date(resolvingDeskBooking.date)))
                }
                showComponents(dispatch, {
                  floorplan: true,
                  search: true,
                  blockBookingWizard: true,
                })
                return dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_4_ALTERNATIVES))
              case BookingWizardSteps.STEP_4_ALTERNATIVES:
                if (!selectedAlternativeFeatureID) {
                  break
                }
                if (applyAlternativeFeatureAndFinish()) {
                  dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_5_SUBMIT))
                }
                break
              case BookingWizardSteps.STEP_5_SUBMIT:
                break
              case BookingWizardSteps.STEP_0_INACTIVE:
              case BookingWizardSteps.STEP_1_SEARCH:
              default:
                break
            }
          }}
        />
      </Grid>
    </Grid>
  )
}
