import DraweButtonContainer from '../DrawerButtonContainer'
import { DrawerFooterContainer } from './components'

interface Props {
  children: any
}

function DrawerFooter({ children }: Props) {
  return (
    <DrawerFooterContainer>
      <DraweButtonContainer>{children}</DraweButtonContainer>
    </DrawerFooterContainer>
  )
}

export default DrawerFooter
