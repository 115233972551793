import React, { useEffect, useMemo, useRef, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Grid, TextField } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootStore } from '../../redux/store'
import Card from '../../shared/layout/Card'
import Paragraph from '../../shared/UI/Paragraph'
import {
  hideModal,
  SetShowModalPayload,
  showModalDialog,
} from '../../redux/reducers/appSettingsReducer'
import Modal from '../../shared/UI/Modal'
import { getLocalDateString } from '../../utils/date-utils'
import { AbsenceDetailProps } from './types'
import Row from './Row'
import DropdownMenu from '../../shared/UI/DropdownMenu'
import { getSelectValuesByType } from '../../utils/app-utils'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { manualRequestsService } from '../../services/myActionsService'
import { AbsenceDay } from '../../types/absence-day'
import { AbsenceDayDeleteRequest, AbsenceDeleteRequest } from '../../types/absence'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import NoDataFound from '../../shared/UI/NoDataFound'
import UserErrorMessage from '../../utils/errorFilter'
import { RequestType } from '../../models'
import { BaseResponse } from '../../types/base-response'

type ColumnProp = {
  column: AbsenceDay
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, type: RequestType) => any
  type: RequestType
}

export default function AbsenceDetail({
  absenceDays,
  absenceId,
  onEdit,
  onDelete,
  handleUpdate,
  isLoading,
  isData,
  viewOnly = false,
  handleViewClick,
}: AbsenceDetailProps) {
  const [selectedRow, setSelectedRow] = useState<AbsenceDay | null>(null)
  const [disabled, setIsDisabled] = useState(true)
  const [deleteReason, setDeleteReason] = useState('')
  const [deleteComment, setDeleteComment] = useState('')
  const [absenceReasonType, setAbsenceReasonType] = useState<string>()
  const [location, setLocation] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<any>({})

  const dispatch = useDispatch()
  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  const handleDeleteModal = (row: AbsenceDay) => {
    setSelectedRow(row)
  }

  const activeRoute = useSelector<RootStore, string>(
    (state: RootStore) => state.appSettings.activeRoute
  )

  const { absenceType } = absenceDays[0]

  const isFormValid = () => {
    setValidationErrors({})
    const errors: any = {}
    if (deleteReason === '') {
      errors.deleteReason = true
    }
    if (deleteComment === '') {
      errors.deleteComment = true
    }
    if (Object.keys(errors).length !== 0) {
      setValidationErrors(errors)
      return false
    }
    return true
  }

  useEffect(() => {
    setAbsenceReasonType(absenceType.reasonType)
  }, [absenceType])

  useEffect(() => {
    if (window.location.pathname.includes('myavailability')) {
      setLocation(false)
    } else {
      setLocation(true)
    }
  }, [absenceReasonType])

  const navigate = useNavigate()

  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current && selectedRow) {
      dispatch(
        showModalDialog({
          title: 'Are you sure?',
          message: `Are you sure you would like to delete this absence ${
            absenceDays.length > 1 ? 'day' : 'occurrence'
          } for the ${selectedRow && getLocalDateString(selectedRow.date)}?`,
          buttonLabel: 'Delete',
          type: 'question',
          showModal: true,
          isDisabled: disabled,
        })
      )
    }

    didMountRef.current = true
  }, [selectedRow])

  const calcEmptyTableCell = () => {
    if (!location) {
      return
    }
    if (absenceReasonType === 'NoShow' || absenceReasonType === 'NonSickness') {
      return false
    }

    return true
  }

  const submitRequest = () => {
    if (!selectedRow) {
      return
    }

    const absenceBody: AbsenceDeleteRequest = {
      absenceId,
      deleteReason,
      deleteDescription: deleteComment,
    }

    const absenceDayBody: AbsenceDayDeleteRequest = {
      absenceDayId: selectedRow.id,
      deleteReason: String(deleteReason),
      deleteDescription: deleteComment,
    }

    if (absenceDays.length > 1) {
      manualRequestsService
        .deleteAbsenceDay(absenceDayBody)
        .then(res => {
          dispatch(showSuccessMessage('Absence day deleted successfully!'))
          dispatch(hideModal())
          onDelete?.(selectedRow)
          setDeleteComment('')
        })
        .catch(err => {
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    } else {
      manualRequestsService
        .deleteAbsenceOccurrence(absenceBody)
        .then(res => {
          dispatch(showSuccessMessage('Absence occurrence deleted successfully!'))
          dispatch(hideModal())
          navigate(activeRoute || '/myactions')
        })
        .catch(err => {
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    }
  }

  useEffect(() => {
    if (deleteReason && deleteComment) {
      setIsDisabled(false)
    }
  }, [deleteReason, deleteComment])

  const isSickness = useMemo(
    () => absenceType.reasonType?.toLowerCase() === 'sickness',
    [absenceType.reasonType]
  )

  return (
    <>
      <Grid container>
        <Modal
          type={type}
          open={showModal}
          onClose={() => {
            setSelectedRow(null)
            dispatch(hideModal())
          }}
          onClick={() => {
            if (isFormValid()) {
              submitRequest()
            }
          }}
          title={title}
          message={message}
          buttonLabel={buttonLabel}
          isDisabled={disabled}
          maxWidth="xl"
          dropdown={
            <Grid container>
              <Grid item xs={12} md={12} spacing={4} mt={4}>
                <DropdownMenu
                  label="Reason"
                  id="reason"
                  data={getSelectValuesByType('AbsenceDeletionReason')}
                  textField="displayValue"
                  valueField="value"
                  onChange={e => {
                    const reasonStr = getSelectValuesByType('AbsenceDeletionReason').find(
                      x => x.value === Number(e.target.value)
                    )?.displayValue
                    setDeleteReason(reasonStr!)
                  }}
                  error={validationErrors.deleteReason}
                />
                <Grid mt={2}>
                  <TextField
                    label="Comments"
                    value={deleteComment}
                    fullWidth
                    multiline
                    rows={4}
                    onChange={e => {
                      setDeleteComment(e.target.value)
                    }}
                    error={validationErrors.deleteComment}
                  />
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Card title="Absence Detail">
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {calcEmptyTableCell() ? <TableCell /> : null}
                <TableCell align="left">
                  <Paragraph weight="bold">Date</Paragraph>
                </TableCell>
                <TableCell align="left">
                  <Paragraph weight="bold">Hours</Paragraph>
                </TableCell>
                {isSickness && (
                  <TableCell align="left">
                    <Paragraph weight="bold">Sick Note Added</Paragraph>
                  </TableCell>
                )}
                <TableCell align="left">
                  <Paragraph weight="bold">Absence Type</Paragraph>
                </TableCell>
                {isSickness && (
                  <>
                    <TableCell align="left">
                      <Paragraph weight="bold">Sick Pay Available</Paragraph>
                    </TableCell>
                    <TableCell align="left">
                      <Paragraph weight="bold">Sick Pay Taken</Paragraph>
                    </TableCell>
                  </>
                )}
                <TableCell align="left">
                  <Paragraph weight="bold">To Work Back</Paragraph>
                </TableCell>
                <TableCell align="left">&nbsp;</TableCell>
                <TableCell align="left">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            {!isLoading && isData && (
              <TableBody>
                {absenceDays &&
                  absenceDays.map(row => (
                    <Row
                      key={row.id}
                      row={row}
                      onEdit={onEdit}
                      onDelete={handleDeleteModal}
                      handleUpdate={handleUpdate}
                      viewOnly={viewOnly}
                      handleViewClick={handleViewClick}
                      isSickness={isSickness}
                    />
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {!isLoading ? (
          <>
            {!isData && (
              <Grid xs={12} mt={4}>
                <NoDataFound show />
              </Grid>
            )}
          </>
        ) : (
          <Grid xs={12} mt={4}>
            <LoadingIndicator show />
          </Grid>
        )}
      </Card>
    </>
  )
}
