import { Box } from '@mui/material'
import { ReactElement } from 'react'
import {
  ServiceHoursContainer,
  ServiceHoursIcon,
  ServiceHoursText,
  ServiceHoursSpan,
  ServiceHoursNumber,
  ServiceHoursOuterContainer,
} from './components'

type Props = {
  titleProps: string
  serviceHoursProps: number
  icon: ReactElement
  color: string
}

function ServiceHours({ serviceHoursProps, titleProps, icon, color }: Props) {
  return (
    <ServiceHoursOuterContainer>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '75%' }}>
        <ServiceHoursIcon color={color}>{icon}</ServiceHoursIcon>
        <ServiceHoursText color={color}>{titleProps}</ServiceHoursText>
      </Box>
      <ServiceHoursContainer>
        <ServiceHoursNumber>
          {serviceHoursProps}
          <ServiceHoursSpan>hours</ServiceHoursSpan>
        </ServiceHoursNumber>
      </ServiceHoursContainer>
    </ServiceHoursOuterContainer>
  )
}

export default ServiceHours
