import { Typography as MuiTypography } from '@mui/material'
import styled from 'styled-components'

type Props = {
  title?: string | number
  style?: any
}

const StyledTypography = styled(MuiTypography)`
  &.MuiTypography-root {
    font-weight: 200;
    font-size: 10px;
    color: #000000;
    letter-spacing: 0.2px;
  }
`

function HeadingSmall({ title, style = {} }: Props) {
  return <StyledTypography style={style}>{title}</StyledTypography>
}

export default HeadingSmall
