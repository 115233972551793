import styled from 'styled-components'
import { TextField } from '@mui/material'

export const HoursContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0 10px 54px;
`

interface RequestDaysTextFieldProps {
  bankHoliday: boolean
  checked: boolean
}
export const RequestDaysTextField = styled(TextField)<RequestDaysTextFieldProps>`
  & .MuiOutlinedInput-notchedOutline {
    ${({ checked, bankHoliday }) => checked && bankHoliday && `border-color:#2FB6F6;`}
`
