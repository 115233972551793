import {} from 'react-dom'
import { useMemo } from 'react'
import { PlottingCalendarDayContainerProps } from './types'
import { PlottingCalendarDayContainerDiv } from './components'
import { getPlottingStyle } from './utils'

export function PlottingCalendarDayContainer({
  children,
  eventType,
  isEventEnd,
  isEventStart,
  isPending,
  isMultipleRequests,
  isDisabled,
  showInCalendar,
  isBankHoliday,
  isToday,
}: PlottingCalendarDayContainerProps) {
  const styles = useMemo(
    () => getPlottingStyle(eventType, isEventStart, isEventEnd, isMultipleRequests),
    [eventType, isEventEnd, isEventStart, isMultipleRequests]
  )

  return (
    <PlottingCalendarDayContainerDiv
      plottingColour={styles.plottingColour}
      className={styles.class}
      isPending={isPending}
      isDisabled={isDisabled}
      showInCalendar={showInCalendar}
      isBankHoliday={isBankHoliday}
      isToday={isToday}
    >
      {children}
    </PlottingCalendarDayContainerDiv>
  )
}
