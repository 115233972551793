// QA: According to Stack Overflow, there is a bug in TS which wrongly thinks
// some enums are duplicate declarations.
// eslint-disable-next-line no-shadow
export enum WallChartSegments {
  MASTER_SEGMENT_WEEK = 7,
  MASTER_SEGMENT_TWOWEEK = 14,
  MASTER_SEGMENT_DAY = 24,
  SEGMENT_DIV_MONTH = 4,
  SEGMENT_DIV_WEEK = 4,
  SEGMENT_DIV_TWOWEEK = 4,
  SEGMENT_DIV_DAY = 2,
}
// eslint-disable-next-line no-shadow
export enum WallChartTimespans {
  DAY = 'Day',
  WEEK = 'Week',
  TWOWEEK = 'TwoWeek',
  MONTH = 'Month',
}

export interface WallChartMonth {
  masterSegments: number
  divisions: WallChartSegments.SEGMENT_DIV_MONTH
  timespan: WallChartTimespans.MONTH
}

export interface WallChartWeek {
  masterSegments: WallChartSegments.MASTER_SEGMENT_WEEK
  divisions: WallChartSegments.SEGMENT_DIV_WEEK
  timespan: WallChartTimespans.WEEK
}
export interface WallChartTwoWeek {
  masterSegments: WallChartSegments.MASTER_SEGMENT_TWOWEEK
  divisions: WallChartSegments.SEGMENT_DIV_WEEK
  timespan: WallChartTimespans.TWOWEEK
}

export interface WallChartDay {
  masterSegments: WallChartSegments.MASTER_SEGMENT_DAY
  divisions: WallChartSegments.SEGMENT_DIV_DAY
  timespan: WallChartTimespans.DAY
}

export interface WallChartSelection {
  timespanStartDate: Date
  timespanEndDate: Date
  timespanSegments: WallChartMonth | WallChartWeek | WallChartDay | WallChartTwoWeek
  dayIndexOffset?: number
}

export interface WallChartTimespanSelectorEvent extends WallChartSelection {
  directReport: boolean
}

export interface WallChartSelectedTimeSpan {
  directReport: boolean
  segments: WallChartDay | WallChartWeek | WallChartMonth | WallChartTwoWeek
  startDate: Date
  endDate: Date
  title: string
  rangeType: string
}

export interface WallChartSelectedTimespanProps {
  directReportEnabled: boolean
}
