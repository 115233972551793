import { UserClaims } from '@okta/okta-auth-js'

export interface userState {
  loggedInUser: UserClaims<Record<string, string | number | boolean>> | null
  permissions: string[]
}

export const initialState: userState = {
  loggedInUser: null,
  permissions: [],
}

// Action Types
const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER'
const CLEAR_LOGGED_IN_USER = 'CLEAR_LOGGED_IN_USER'
const SET_USSER_PERMISSIONS = 'SET_USSER_PERMISSIONS'

// Action Creators
interface SetLoggedInUserAction {
  type: typeof SET_LOGGED_IN_USER
  payload: UserClaims<Record<string, string | number | boolean>>
}

interface ClearLoggedInUserAction {
  type: typeof CLEAR_LOGGED_IN_USER
  payload: null
}

interface SetUserPermissionsAction {
  type: typeof SET_USSER_PERMISSIONS
  payload: string[]
}

type userDispatchtypes = SetLoggedInUserAction | ClearLoggedInUserAction | SetUserPermissionsAction

// Actions
export const setLoggedInUser = (
  userDetails: UserClaims<Record<string, string | number | boolean>>
): SetLoggedInUserAction => ({
  type: SET_LOGGED_IN_USER,
  payload: userDetails,
})

export const clearLoggedInUser = (): ClearLoggedInUserAction => ({
  type: CLEAR_LOGGED_IN_USER,
  payload: null,
})

export const setUserPermissions = (permissions: string[]): SetUserPermissionsAction => ({
  type: SET_USSER_PERMISSIONS,
  payload: permissions,
})

// Reducer
export const userReducer = (
  state: userState = initialState,
  action: userDispatchtypes
): userState => {
  switch (action.type) {
    case SET_LOGGED_IN_USER:
      return { ...state, loggedInUser: action.payload }
    case CLEAR_LOGGED_IN_USER:
      return { ...state, loggedInUser: null }
    case SET_USSER_PERMISSIONS:
      return { ...state, permissions: action.payload }
    default:
      return state
  }
}
