import { PlottingCalendarDay, PlottingCalendarDayLetterRow } from './components'

export function PlottingCalendarHeaderRow() {
  return (
    <PlottingCalendarDayLetterRow className="plottingCalendar_dayLetters">
      <PlottingCalendarDay>M</PlottingCalendarDay>
      <PlottingCalendarDay>T</PlottingCalendarDay>
      <PlottingCalendarDay>W</PlottingCalendarDay>
      <PlottingCalendarDay>T</PlottingCalendarDay>
      <PlottingCalendarDay>F</PlottingCalendarDay>
      <PlottingCalendarDay>S</PlottingCalendarDay>
      <PlottingCalendarDay>S</PlottingCalendarDay>
    </PlottingCalendarDayLetterRow>
  )
}
