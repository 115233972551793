import { Grid } from '@mui/material'
import Card from '../../../shared/layout/Card'
import FullComponentLoadingIcon from '../../../shared/UI/LoadingIndicator/FullComponentLoadingIcon'
import { WhosAwayResponse } from '../../../types/whos-away-type'
import { isNoData } from '../utils'
import { WhosAwayCardContent } from './WhosAwayCardContent'

export function WhosAwayContainer({
  children,
  isLoading,
  setActiveLink,
  whosAway,
}: {
  children: React.ReactNode
  isLoading: boolean
  setActiveLink: React.Dispatch<React.SetStateAction<string>>
  whosAway: WhosAwayResponse | undefined
}) {
  return (
    <Card
      title="Who's Away"
      icon={<WhosAwayCardContent whosAway={whosAway} setActiveLink={setActiveLink} />}
      style={{ boxSizing: 'border-box' }}
    >
      <FullComponentLoadingIcon loading={isLoading} removeBorderRadius>
        <Grid container direction="column" height="100%">
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </FullComponentLoadingIcon>
    </Card>
  )
}
