import { AbsenceOccurrence } from '../../types/absence'
import { getLocalDateString } from '../../utils/date-utils'

interface edResponse {
  nonWorkingDays?: number
  normalWeekWorkingDays?: number
  absenceReasonText?: string
  medicalPractitionerConsultedType: string
  medicalPractitionerComments?: string
  displayName: string
  date: string
  ip: string
}

type Props = {
  absenceOccurrence: AbsenceOccurrence
  ed: edResponse
}

function EDPrint({ absenceOccurrence, ed }: Props) {
  const totalHoursLost = () => {
    let result = 0
    absenceOccurrence.absenceDays.forEach(day => {
      result += day.hoursLost
    })
    return result
  }

  const hasMedicalPractitionerComment = () =>
    ed.medicalPractitionerConsultedType.toUpperCase() === 'YES'

  return (
    <div>
      <h1>Employee Declaration</h1>
      <hr />
      <p>
        This form should be completed upon your return to work following any period of absence of up
        to 7 days, and digitally signed using the provided controls. If you are returning to work
        after a period of more than 7 days absence you must also provide a supporting Medical
        Certificate to your Departmental Manager.
      </p>
      <br />
      <h3>Name</h3>
      <p>{absenceOccurrence && absenceOccurrence.employeeResponse.displayName}</p>
      <br />
      <h3>Period Of Absence (Including non-working days from the first day of absence)</h3>
      <p>
        {getLocalDateString(absenceOccurrence && absenceOccurrence.startDate)}
        {' - '}
        {getLocalDateString(absenceOccurrence && absenceOccurrence.endDate)}
      </p>
      <br />
      <h3>Total number of hours absent</h3>
      <p>{absenceOccurrence && totalHoursLost()}</p>
      <br />
      <h3>Total Number of days absent</h3>
      <p>{absenceOccurrence && absenceOccurrence.absenceDays.length}</p>
      <br />
      <h3>Of which resulted in absence from work</h3>
      <p>{absenceOccurrence && absenceOccurrence.absenceDays.length}</p>
      <br />
      <h3>Of which were during non-working days off</h3>
      <p>{ed && ed.nonWorkingDays}</p>
      <br />
      <h3>Usual number of days worked per week</h3>
      <p>{ed && ed.normalWeekWorkingDays}</p>
      <br />
      <h3>Absence Reason</h3>
      <p>{absenceOccurrence && absenceOccurrence.reason}</p>
      <br />
      <h3>Absence Classification</h3>
      <p>{absenceOccurrence && absenceOccurrence.absenceDays[0].absenceType.reasonType}</p>
      <br />

      <div className="line">
        <div className="item bold" style={{ flexGrow: 7 }}>
          Reason for absence
        </div>
        <div className="item" style={{ flexGrow: 2 }}>
          &nbsp;
        </div>
        <div className="item" style={{ flexGrow: 1 }} />
      </div>
      <div className="input long">{ed && ed.absenceReasonText}</div>
      <br />
      <br />
      <br />

      <div className="line">
        <div className="item bold" style={{ flexGrow: 7 }}>
          Has Medical Advice been sought?
        </div>
        <div className="item" style={{ flexGrow: 1 }}>
          &nbsp;
        </div>
        <div className="item" style={{ flexGrow: 2 }}>
          {ed && ed.medicalPractitionerConsultedType.toUpperCase()}
        </div>
      </div>
      {hasMedicalPractitionerComment() && (
        <div className="input long">{ed && ed.medicalPractitionerComments}</div>
      )}
      <br />
      <br />
      <br />

      <p>
        I certify that I was not fit for work on the dates stated above. I hereby confirm that the
        details that I have provided are true and accurate. I acknowledge that providing false
        information may result in disciplinary action.
      </p>
      <br />
      <br />
      <p>{`Digitally signed by ${ed.displayName} on ${ed.date} on machine ${ed.ip}`}</p>
    </div>
  )
}

export default EDPrint
