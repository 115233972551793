import { styled } from '@mui/material/styles'
import { WallChartEventBarStyleProps } from './types'
import { ColourSchemeProps } from '../../LegendsFooter/types'
import { getPalleteTypeByProp } from '../../../theme/palette'

const colorScheme = (eventType: string): ColourSchemeProps => {
  const palletObject = getPalleteTypeByProp(eventType.toLowerCase())
  if (!palletObject) {
    return { backgroundColour: 'lightgray', fontColour: 'gray' }
  }
  return { backgroundColour: palletObject.color, fontColour: palletObject.fontColor }
}

export const WallChartEventBar = styled(
  'div',
  {}
)<Partial<WallChartEventBarStyleProps>>(({ status, recordTypeStr, position, zIndex }) => ({
  ...{
    backgroundColor: colorScheme(recordTypeStr!).backgroundColour,
    height: '24px',
    width: position!.widthPercent,
    position: 'absolute',
    left: position!.startPercent,
    top: '17px',
    borderRadius: '20px',
    opacity: 1,
    zIndex,
  },
  ...(status?.toLowerCase() === 'pending' && {
    backgroundImage:
      'linear-gradient(135deg, transparent  37.50%, rgb(68 68 68 / 30%) 37.50%, rgb(68 68 68 / 30%) 50%, transparent  50%, transparent  87.50%, rgb(68 68 68 / 30%) 87.50%, rgb(68 68 68 / 30%) 100%)',
    backgroundSize: '10px 10px',
  }),
  ...(position!.extendedLeft && { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }),
  ...(position!.extendedRight && { borderTopRightRadius: 0, borderBottomRightRadius: 0 }),
})) as React.ComponentType<Partial<WallChartEventBarStyleProps>>

export const WallChartMultiEventBar = styled(
  'div',
  {}
)<Partial<WallChartEventBarStyleProps>>((props) => ({
  ...{
    backgroundColor: colorScheme('multipleRequests').backgroundColour,
    opacity: 1,
    height: '24px',
    position: 'absolute',
    left: props.position!.startPercent,
    width: props.position!.widthPercent,
    top: '17px',
    borderBottomRightRadius: !props.hasRightMultiRequestNeighbour ? '20px' : 0,
    borderTopRightRadius: !props.hasRightMultiRequestNeighbour ? '20px' : 0,
    borderBottomLeftRadius: !props.hasLeftMultiRequestNeighbour ? '20px' : 0,
    borderTopLeftRadius: !props.hasLeftMultiRequestNeighbour ? '20px' : 0,
    zIndex: props.zIndex,
  },
})) as React.ComponentType<Partial<WallChartEventBarStyleProps>>
