import { Button, ButtonGroup, styled } from '@mui/material'

interface ButtonProps {
  activeLink: boolean
}

export const StyledButtonGroup = styled(ButtonGroup)`
  &.MuiButtonGroup-root {
    border-radius: 50px;
    box-shadow: none;
    height: 28px;
    border-color: gray;
  }
`

export const StyledButton = styled(Button)<ButtonProps>`
  &.MuiButton-root:first-child {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  &.MuiButton-root:last-child {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  &.MuiButton-root.MuiButtonBase-root,
  &.MuiButton-root.MuiButtonBase-root:not(last-of-type) {
    text-transform: capitalize;
    border: none;
  }

  &.MuiButton-root {
    font-size: 13px;
    line-height: 13px;
    transition: none;
    ${({ activeLink }) =>
      `background: ${
        activeLink ? 'linear-gradient(109deg, #20C5A0 0%, #20C5A0 100%)' : '#dfdfdf'
      };`}
    ${({ activeLink }) => `color: ${activeLink ? '#fff' : '#000'};`}
  }

  &.MuiButton-root.MuiButtonBase-root:hover,
  &.MuiButton-root.MuiButtonBase-root:hover:not(last-of-type) {
    background: linear-gradient(109deg, #20c5a0 0%, #02acec 100%);
    color: #ffffff;
  }
`
