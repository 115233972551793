import { IconButton, SxProps, Tooltip } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'

interface DownloadButtonProps {
  dataTestId?: string
  title?: string
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void | (() => void)
  sx?: SxProps
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined
  color?:
    | 'action'
    | 'disabled'
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
}

function DownloadButton({
  title = 'Download',
  onClick,
  sx,
  placement = 'bottom',
  color = 'primary',
  dataTestId,
}: DownloadButtonProps) {
  return (
    <Tooltip title={title} placement={placement}>
      <IconButton onClick={onClick}>
        <DownloadIcon sx={sx} color={color} data-testid={dataTestId} />
      </IconButton>
    </Tooltip>
  )
}

export default DownloadButton
