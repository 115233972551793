import WbSunnyIcon from '@mui/icons-material/WbSunny'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import Paragraph from '../Paragraph'
import { EntitlementSplit } from '../../../types/entitlement-split'
import { RootStore } from '../../../redux/store'

type Props = {
  summaryItems: EntitlementSplit
  marginTop?: number
  marginBottom?: number
  titleMarginBottom?: number
}

function MySummaryEntitlementSplit({
  summaryItems,
  marginTop = 3,
  marginBottom = 2,
  titleMarginBottom = 0,
}: Props) {
  const { userSettings } = useSelector((state: RootStore) => state.appSettings)

  return (
    <>
      <Grid container mt={marginTop} mb={titleMarginBottom} columns={16}>
        <Grid item xs={16} display="flex" justifyContent="flex-end">
          <Grid item xs={3} display="flex" justifyContent="flex-end">
            <Paragraph weight="bold">Hours</Paragraph>
          </Grid>
          {userSettings?.hasEntitlementSeasonalSplit ? (
            <>
              <Grid item xs={3} display="flex" justifyContent="flex-end">
                <Paragraph weight="bold">
                  <WbSunnyIcon />
                </Paragraph>
              </Grid>
              <Grid item xs={3} display="flex" justifyContent="flex-end">
                <Paragraph weight="bold">
                  <AcUnitIcon />
                </Paragraph>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>
      <Grid container mb={marginBottom} rowSpacing={1} columns={16}>
        <Grid item xs={7} display="flex" justifyContent="flex-start">
          <Paragraph>Allowance</Paragraph>
        </Grid>
        <Grid
          item
          xs={userSettings?.hasEntitlementSeasonalSplit ? 3 : 9}
          display="flex"
          justifyContent="flex-end"
        >
          <Paragraph>
            {summaryItems.general.hours.toFixed(2) && summaryItems.general.hours.toFixed(2)}
          </Paragraph>
        </Grid>
        {userSettings?.hasEntitlementSeasonalSplit ? (
          <>
            <Grid item xs={3} display="flex" justifyContent="flex-end">
              <Paragraph>
                {summaryItems.general.summerHours?.toFixed(2) &&
                  summaryItems.general.summerHours.toFixed(2)}
              </Paragraph>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent="flex-end">
              <Paragraph>
                {summaryItems.general.winterHours?.toFixed(2) &&
                  summaryItems.general.winterHours.toFixed(2)}
              </Paragraph>
            </Grid>
          </>
        ) : null}

        <Grid item xs={7} display="flex" justifyContent="flex-start">
          <Paragraph>Remaining</Paragraph>
        </Grid>
        <Grid
          item
          xs={userSettings?.hasEntitlementSeasonalSplit ? 3 : 9}
          display="flex"
          justifyContent="flex-end"
        >
          <Paragraph>
            {summaryItems.remaining.hours.toFixed(2) && summaryItems.remaining.hours.toFixed(2)}
          </Paragraph>
        </Grid>
        {userSettings?.hasEntitlementSeasonalSplit ? (
          <>
            <Grid item xs={3} display="flex" justifyContent="flex-end">
              <Paragraph>
                {summaryItems.remaining.summerHours?.toFixed(2) &&
                  summaryItems.remaining.summerHours.toFixed(2)}
              </Paragraph>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent="flex-end">
              <Paragraph>
                {summaryItems.remaining.winterHours?.toFixed(2) &&
                  summaryItems.remaining.winterHours.toFixed(2)}
              </Paragraph>
            </Grid>
          </>
        ) : null}

        <Grid item xs={7} display="flex" justifyContent="flex-start">
          <Paragraph>Requested</Paragraph>
        </Grid>
        <Grid
          item
          xs={userSettings?.hasEntitlementSeasonalSplit ? 3 : 9}
          display="flex"
          justifyContent="flex-end"
        >
          <Paragraph>
            {summaryItems.requested.hours.toFixed(2) && summaryItems.requested.hours.toFixed(2)}
          </Paragraph>
        </Grid>
        {userSettings?.hasEntitlementSeasonalSplit ? (
          <>
            <Grid item xs={3} display="flex" justifyContent="flex-end">
              <Paragraph>
                {summaryItems.requested.summerHours?.toFixed(2) &&
                  summaryItems.requested.summerHours.toFixed(2)}
              </Paragraph>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent="flex-end">
              <Paragraph>
                {summaryItems.requested.winterHours?.toFixed(2) &&
                  summaryItems.requested.winterHours.toFixed(2)}
              </Paragraph>
            </Grid>
          </>
        ) : null}

        <Grid item xs={7} display="flex" justifyContent="flex-start">
          <Paragraph>Change Requests</Paragraph>
        </Grid>
        <Grid
          item
          xs={userSettings?.hasEntitlementSeasonalSplit ? 3 : 9}
          display="flex"
          justifyContent="flex-end"
        >
          <Paragraph>
            {summaryItems.changeRequests.hours.toFixed(2) &&
              summaryItems.changeRequests.hours.toFixed(2)}
          </Paragraph>
        </Grid>
        {userSettings?.hasEntitlementSeasonalSplit ? (
          <>
            <Grid item xs={3} display="flex" justifyContent="flex-end">
              <Paragraph>
                {summaryItems.changeRequests.summerHours?.toFixed(2) &&
                  summaryItems.changeRequests.summerHours.toFixed(2)}
              </Paragraph>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent="flex-end">
              <Paragraph>
                {summaryItems.changeRequests.winterHours?.toFixed(2) &&
                  summaryItems.changeRequests.winterHours.toFixed(2)}
              </Paragraph>
            </Grid>
          </>
        ) : null}

        <Grid item xs={7} display="flex" justifyContent="flex-start">
          <Paragraph>If Approved</Paragraph>
        </Grid>
        <Grid
          item
          xs={userSettings?.hasEntitlementSeasonalSplit ? 3 : 9}
          display="flex"
          justifyContent="flex-end"
        >
          <Paragraph>
            {summaryItems.ifApproved.hours && summaryItems.ifApproved.hours.toFixed(2)}
          </Paragraph>
        </Grid>
        {userSettings?.hasEntitlementSeasonalSplit ? (
          <>
            <Grid item xs={3} display="flex" justifyContent="flex-end">
              <Paragraph>
                {summaryItems.ifApproved.summerHours &&
                  summaryItems.ifApproved.summerHours.toFixed(2)}
              </Paragraph>
            </Grid>
            <Grid item xs={3} display="flex" justifyContent="flex-end">
              <Paragraph>
                {summaryItems.ifApproved.winterHours &&
                  summaryItems.ifApproved.winterHours.toFixed(2)}
              </Paragraph>
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  )
}

export default MySummaryEntitlementSplit
