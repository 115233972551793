import React, { useEffect } from 'react'
import { Grid, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import CheckBox from '../../shared/UI/CheckBox'
import { hideModal, hideDrawer, SetShowModalPayload } from '../../redux/reducers/appSettingsReducer'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import { manualRequestsService } from '../../services/myActionsService'
import Button from '../../shared/UI/Button'
import DatePicker from '../../shared/UI/DatePicker'
import DrawerFooter from '../../shared/UI/DrawerFooter'
import DropdownMenu from '../../shared/UI/DropdownMenu'
import Modal from '../../shared/UI/Modal'
import { getSelectValuesByType } from '../../utils/app-utils'
import { LateType } from './types'
import { useForm } from '../../utils/useForm'
import { LateComponentValidationSchema } from '../LateDrawerComponent/model'
import { LateUpdateRequest, LateDetailsResponse } from '../../types/late-occurrence'
import { BaseResponse } from '../../types/base-response'
import UserErrorMessage from '../../utils/errorFilter'
import { getLocalDateString } from '../../utils/date-utils'

const lateStateModelDefaultState: LateType = {
  lateId: 0,
  lateDate: new Date(),
  lateMinutes: 0,
  lateTypeId: 0,
  employeeId: 0,
  shiftDescription: '09.00 - 17:30',
  toWorkBack: false,
  contactDateTime: new Date(),
  contactByType: '',
  contactByTypeId: 0,
  comments: '',
  contactComments: '',
  employeeName: '',
  lateStatusType: '',
}

function EditLate({ lateId }: Partial<LateType>) {
  const dispatch = useAppDispatch()
  const contactTypeData = getSelectValuesByType('ContactByType')

  const updateBody = (formData: LateType): LateUpdateRequest => {
    const { employeeName, ...rest } = formData
    const updatedProps = { contactDateTime: formData.contactDateTime }
    const contactByType = contactTypeData.find(
      contact => contact.value === formData.contactByTypeId
    )
    return {
      ...rest,
      ...updatedProps,
      contactByTypeId: contactByType?.value || formData.contactByTypeId,
    }
  }
  const handleFormSubmit = (formData: LateType) => {
    if (!formData) {
      return
    }
    const body = updateBody(formData)
    manualRequestsService
      .editLate(body)
      .then(data => {
        dispatch(showSuccessMessage('Late edit has been sent'))
        dispatch(hideDrawer())
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }
  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  const {
    handleSubmit,
    handleChange,
    handleCheckboxChange,
    handleDateChange,
    data: formData,
    setData,
    errors,
  } = useForm<LateType>({
    initialValues: lateStateModelDefaultState,
    onSubmit: () => {
      handleFormSubmit(formData)
    },
    validations: {
      ...LateComponentValidationSchema,
    },
  })

  function getLateOccurrenceData() {
    if (!lateId) {
      return
    }
    manualRequestsService
      .getLateOccurrence(lateId)
      .then((result: LateDetailsResponse) => {
        const { lateOccurrence } = result
        setData({
          lateId: lateOccurrence?.id,
          employeeId: lateOccurrence?.employeeResponse?.id,
          employeeName: lateOccurrence?.employeeResponse?.displayName,
          lateDate: lateOccurrence?.lateDate,
          lateTypeId: lateOccurrence?.lateTypeId,
          lateMinutes: lateOccurrence?.lateMinutes,
          shiftDescription: lateOccurrence?.expectedShift.shiftString,
          contactByType: lateOccurrence?.contactByType,
          contactByTypeId: lateOccurrence?.contactByTypeId,
          contactDateTime: lateOccurrence?.contactDateTime,
          toWorkBack: lateOccurrence?.toWorkBack,
          contactComments: lateOccurrence?.contactComments || '',
          comments: lateOccurrence?.comments || '',
          lateStatusType: lateOccurrence?.lateStatus || '',
        })
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }
  useEffect(() => {
    getLateOccurrenceData()
  }, [])

  return (
    <Grid
      container
      spacing={4}
      component="form"
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        handleSubmit(event)
      }}
      p={4}
      pt={0}
    >
      <Grid item xs={12} mt={4}>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <DropdownMenu
                label="Employee"
                id="employee"
                data={[
                  {
                    id: formData?.employeeId,
                    displayName: formData?.employeeName,
                  },
                ]}
                textField="displayName"
                valueField="id"
                value={String(formData?.employeeId)}
                error={!!errors.employeeId}
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                label="Date"
                value={getLocalDateString(formData?.lateDate)}
                error={!!errors.lateDate}
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                fullWidth
                label="Shift"
                value={formData?.shiftDescription}
                name="shiftDescription"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <DropdownMenu
                label="Reason for lateness"
                id="lateReason"
                data={getSelectValuesByType('LateType')}
                textField="displayValue"
                valueField="value"
                value={formData?.lateTypeId || ''}
                name="lateTypeId"
                onChange={handleChange}
                error={!!errors.lateTypeId}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                label="Minutes Lost"
                value={formData?.lateMinutes}
                type="number"
                name="lateMinutes"
                onChange={handleChange}
                error={!!errors.lateMinutes}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <DropdownMenu
                label="Contact made by"
                id="contact-made-by"
                data={contactTypeData}
                textField="displayValue"
                valueField="value"
                value={formData?.contactByTypeId}
                name="contactByTypeId"
                onChange={handleChange}
                error={!!errors.contactByType}
              />
            </Grid>

            {(formData.contactByTypeId === 1 || formData.contactByTypeId === 2) && (
              <Grid item xs={12} lg={6}>
                <DatePicker
                  label="Contacted date"
                  value={formData.contactDateTime}
                  onChange={newDate => {
                    handleDateChange(newDate, 'contactDateTime')
                  }}
                  error={!!errors.contactDateTime}
                />
              </Grid>
            )}
            <Grid item xs={12} lg={6}>
              <CheckBox
                label="To work back"
                name="toWorkBack"
                onChange={handleCheckboxChange}
                checked={formData?.toWorkBack}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                label="Contact Comments"
                value={formData?.contactComments}
                rows={5}
                multiline
                name="contactComments"
                onChange={handleChange}
                error={!!errors.contactComments}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                label="Comments"
                value={formData?.comments}
                rows={5}
                multiline
                name="comments"
                onChange={handleChange}
                error={!!errors.comments}
              />
            </Grid>
            <Grid item xs={12} lg={6} />
          </Grid>
        </Grid>
      </Grid>
      <DrawerFooter>
        <Button
          color="secondary"
          label="Cancel"
          onClick={() => {
            dispatch(hideDrawer())
          }}
        />
        <Button label="Save" type="submit" />
      </DrawerFooter>
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
        }}
        onClick={() => handleFormSubmit(formData)}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
      />
    </Grid>
  )
}

export default EditLate
