import { Card, Dialog, DialogContentText, Slide, Stack, useMediaQuery } from '@mui/material'
import styled from 'styled-components'
import { TransitionProps } from '@mui/material/transitions'
import React from 'react'
import theme from '../../../theme/theme'
import Button from '../Button'
import clash from '../../../assets/clash.svg'
import question from '../../../assets/question.svg'
import HeadingTwo from '../HeadingTwo'
import { isMobileDown } from '../../../theme/deviceChecks'

export type ModalProps = {
  title: string
  message: string
  dropdown?: React.ReactElement
  type: 'clash' | 'question'
  buttonLabel: string
  onClick?: () => void
  open: boolean
  onClose: () => void
  isDisabled?: boolean
  maxWidth?: 'sm' | 'md' | 'lg' | 'xl' | undefined
  buttonLoading?: boolean
}

const StyledStack = styled(Stack)`
  ${theme.breakpoints.down('sm')} {
    flex-direction: column !important;
  }
`

const StyledDialogContentText = styled(DialogContentText)`
  ${theme.breakpoints.down('md')} {
    padding-top: 20px;
  }
  text-align: center;
  white-space: pre-wrap;
`

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
)

function Modal({
  title,
  message,
  dropdown,
  type,
  buttonLabel,
  onClick,
  open,
  onClose,
  isDisabled,
  maxWidth,
  buttonLoading = false,
}: ModalProps) {
  const mobile = useMediaQuery(isMobileDown)

  let image
  if (type === 'clash') {
    image = <img src={`${clash}`} alt={title} width="320px" />
  } else if (type === 'question') {
    image = <img src={`${question}`} alt={title} width="320px" />
  }

  return (
    <Card data-testid="modal">
      <Dialog open={open} onClose={onClose} TransitionComponent={Transition} maxWidth={maxWidth}>
        <StyledStack direction="row">
          {!mobile && image}
          <StyledStack
            direction="column"
            alignItems="center"
            justifyContent="space-evenly"
            pt={2}
            pb={2}
            pr={4}
            pl={4}
          >
            <HeadingTwo title={title} />
            <StyledDialogContentText id="alert-dialog-description">
              {message}
            </StyledDialogContentText>
            {dropdown}
            <Button
              label={buttonLabel}
              variant="outlined"
              color="primary"
              onClick={onClick}
              disabled={isDisabled}
              loading={buttonLoading}
              dataTestId="modal-confirm-button"
            />
            <Button
              label="Close"
              variant="text"
              color="primary"
              onClick={onClose}
              style={{ marginTop: mobile ? '20px' : '0px' }}
              dataTestId="modal-cancel-button"
            />
          </StyledStack>
        </StyledStack>
      </Dialog>
    </Card>
  )
}

export default Modal
