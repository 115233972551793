import { Grid } from '@mui/material'
import Tab from '../../shared/UI/Tab'
import AbsenceDrawerComponent from '../AbsenceDrawerComponent'
import LateDrawerComponent from '../LateDrawerComponent'

function AbsenceAndLate() {
  return (
    <>
      <Tab
        titleAndContent={[
          {
            label: 'Absence',
            tabContext: (
              <Grid container mt={4}>
                <AbsenceDrawerComponent />
              </Grid>
            ),
            disabled: false,
          },
          {
            label: 'Late',
            tabContext: <LateDrawerComponent />,
            disabled: false,
          },
        ]}
      />
    </>
  )
}

export default AbsenceAndLate
