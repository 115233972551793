import { Box, Grid } from '@mui/material'
import { addDays, format, isEqual } from 'date-fns'
import { WallChartSelection } from '../WallChartTimespanSelector/types'
import {
  WallChartHeaderSegment,
  WallChartHeaderSegmentTextBottom,
  WallChartHeaderSegmentTextTop,
} from './components'
import { WEEKDAYS, WEEKDAYS_SHORT } from '../../../utils/constants'
import { WallChartHeaderSegmentPosition } from './types'
import { formatDateWithTimeZone } from '../../../utils/date-utils'

export function WallChartHeader({
  timespanStartDate,
  timespanSegments,
}: Omit<WallChartSelection, 'timespanEndDate'>) {
  if (!timespanStartDate) {
    return null
  }

  const headerSegmentCount = 12 / timespanSegments.masterSegments
  let weekdays = WEEKDAYS
  switch (timespanSegments.timespan) {
    case 'Month':
      weekdays = WEEKDAYS_SHORT
      break
    case 'Week':
      weekdays = WEEKDAYS
      break
    case 'TwoWeek':
      weekdays = WEEKDAYS_SHORT
      break
    default:
      break
  }

  const headerPosition = (idx: number): WallChartHeaderSegmentPosition => {
    if (idx === 0) {
      return WallChartHeaderSegmentPosition.FIRST
    }
    if (idx === timespanSegments.masterSegments - 1) {
      return WallChartHeaderSegmentPosition.LAST
    }
    return WallChartHeaderSegmentPosition.MIDDLE
  }

  const getKey = (idx: number) => String(idx)

  return (
    <Grid container pl="100px">
      <Grid item xs={12}>
        <Grid container pr="6px">
          {new Array(timespanSegments.masterSegments).fill(0).map((day, idx) => (
            <WallChartHeaderSegment
              key={getKey(idx)}
              item
              xs={headerSegmentCount}
              position={headerPosition(idx)}
              isToday={
                timespanSegments.timespan !== 'Day' &&
                format(new Date(), 'yyyy-MM-dd') ===
                  format(addDays(timespanStartDate, idx), 'yyyy-MM-dd')
              }
              isTomorrow={
                timespanSegments.timespan !== 'Day' &&
                format(addDays(new Date(), 1), 'yyyy-MM-dd') ===
                  format(addDays(timespanStartDate, idx), 'yyyy-MM-dd')
              }
            >
              <Box height={65}>
                {timespanSegments.timespan === 'Day' && (
                  <>
                    <WallChartHeaderSegmentTextTop>
                      {`${String(idx).padStart(2, '0')}:00`}
                    </WallChartHeaderSegmentTextTop>
                    <WallChartHeaderSegmentTextBottom>
                      {idx < 12 ? 'AM' : 'PM'}
                    </WallChartHeaderSegmentTextBottom>
                  </>
                )}
                {timespanSegments.timespan === 'Week' && (
                  <>
                    <WallChartHeaderSegmentTextTop>
                      {weekdays[addDays(timespanStartDate, idx).getDay()]}
                    </WallChartHeaderSegmentTextTop>
                    <WallChartHeaderSegmentTextBottom>
                      {`${format(addDays(timespanStartDate, idx), 'dd/MM')}`}
                    </WallChartHeaderSegmentTextBottom>
                  </>
                )}
                {timespanSegments.timespan === 'TwoWeek' && (
                  <>
                    <WallChartHeaderSegmentTextTop>
                      {weekdays[addDays(timespanStartDate, idx).getDay()]}
                    </WallChartHeaderSegmentTextTop>
                    <WallChartHeaderSegmentTextBottom>
                      {`${format(addDays(timespanStartDate, idx), 'dd/MM')}`}
                    </WallChartHeaderSegmentTextBottom>
                  </>
                )}
                {timespanSegments.timespan === 'Month' && (
                  <>
                    <WallChartHeaderSegmentTextTop>
                      {`${weekdays[addDays(timespanStartDate, idx).getDay()]}`}
                    </WallChartHeaderSegmentTextTop>
                    <WallChartHeaderSegmentTextBottom>
                      {`${addDays(timespanStartDate, idx).getDate()}`}
                    </WallChartHeaderSegmentTextBottom>
                  </>
                )}
              </Box>
            </WallChartHeaderSegment>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
