import { Grid } from '@mui/material'
import { useState } from 'react'
import DropdownMenu from '../../../shared/UI/DropdownMenu'
import HolidaysEntitlementSearch from './HolidaysEntitlement/HolidaysEntitlementSearch'
import Alert from '../../../shared/UI/Alert/Alert'
import { Props, SSRSReportType } from './types'
import HolidaysAvailabilitySearch from './HolidaysAvailability/HolidaysAvailabilitySearch'
import HolidaysEmployeeRequestSearch from './HolidaysEmployeeRequest/HolidaysEmployeeRequestSearch'
import HolidaysEntitlementRemainingAllowanceSearch from './HolidaysEntitlementRemainingAllowance/HolidaysEntitlementRemainingAllowanceSearch'
import HolidaysRequestsCountSearch from './HolidaysRequestsCount/HolidaysRequestsCountSearch'
import HolidaysThresholdsRestrictionsSearch from './HolidaysThresholdsRestrictions/HolidaysThresholdsRestrictionsSearch'
import HolidayEntitlementBulgariaSearch from './HolidayEntitlementBulgaria/HolidayEntitlementBulgariaSearch'
import HolidaysEmployeeRequestBulgariaSearch from './HolidayEmployeeRequestBulgaria/HolidaysEmployeeRequestBulgariaSearch'

function HolidayReports({ reportData, userDepartments }: Props) {
  const [selectedReport, setSelectedReport] = useState<string>('')

  return (
    <>
      {reportData && reportData.length >= 1 && (
        <Grid container spacing={2}>
          <Grid item xs={6} mt={5} md={6}>
            <DropdownMenu
              label="Report"
              id="employee"
              data={reportData}
              textField="title"
              valueField="key"
              value={selectedReport}
              onChange={e => setSelectedReport(e.target.value)}
              dataTestId="ReportingHoliday-YearDDL"
            />
          </Grid>
          <Grid item xs={6} mt={5} md={6}>
            {selectedReport === '' && <Alert severity="info" message="Please select a Report" />}
          </Grid>
        </Grid>
      )}
      {selectedReport === SSRSReportType.HOLIDAYS_ENTITLEMENT_REPORT.toLocaleUpperCase().trim() && (
        <HolidaysEntitlementSearch userDepartments={userDepartments} />
      )}
      {selectedReport ===
        SSRSReportType.HOLIDAYS_AVAILABILITY_REPORT.toLocaleUpperCase().trim() && (
        <HolidaysAvailabilitySearch />
      )}
      {selectedReport ===
        SSRSReportType.HOLIDAYS_EMPLOYEE_REQUEST_REPORT.toLocaleUpperCase().trim() && (
        <HolidaysEmployeeRequestSearch />
      )}
      {selectedReport ===
        SSRSReportType.HOLIDAYS_ENTITLEMENT_REMAINING_ALLOWANCE_REPORT.toLocaleUpperCase().trim() && (
        <HolidaysEntitlementRemainingAllowanceSearch userDepartments={userDepartments} />
      )}
      {selectedReport ===
        SSRSReportType.HOLIDAYS_REQUESTS_COUNT_REPORT.toLocaleUpperCase().trim() && (
        <HolidaysRequestsCountSearch userDepartments={userDepartments} />
      )}
      {selectedReport ===
        SSRSReportType.HOLIDAY_THRESHOLDS_AND_RESTRICTIONS_REPORT.toLocaleUpperCase().trim() && (
        <HolidaysThresholdsRestrictionsSearch userDepartments={userDepartments} />
      )}
      {selectedReport ===
        SSRSReportType.HOLIDAYS_ENTITLEMENT_BULGARIA_REPORT.toLocaleUpperCase().trim() && (
        <HolidayEntitlementBulgariaSearch />
      )}
      {selectedReport ===
        SSRSReportType.HOLIDAYS_EMPLOYEE_REQUEST_BULGARIA_REPORT.toLocaleUpperCase().trim() && (
        <HolidaysEmployeeRequestBulgariaSearch />
      )}
    </>
  )
}

export default HolidayReports
