import { Typography } from '@mui/material'
import styled from 'styled-components'
import HeadingThree from '../HeadingThree'

export const NoDatFoundContainer = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const NoDataFoundLabel = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 13px;
    color: #000000;
    letter-spacing: 0.2px;
  }
`

export const NoDataFoundImg = styled('img')`
  max-height: 150px;
  height: 100%;
  width: auto;
`
