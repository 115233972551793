import { SubmitStatus } from '../../models/enhancement'

const readOnlyEmployeeEnhancementStatus = [
  SubmitStatus.APPROVED,
  SubmitStatus.AMENDED,
  SubmitStatus.DECLINED,
  SubmitStatus.SUBMITTED,
]
const readOnlyManagerEnhancementStatus = [SubmitStatus.DECLINED, SubmitStatus.SUBMITTED]

export const isReadOnlyForEmployeeEnhancement = (enhancementStatus: SubmitStatus) => readOnlyEmployeeEnhancementStatus.includes(enhancementStatus)

export const isReadOnlyForManagerEnhancement = (enhancementStatus: SubmitStatus) =>
  readOnlyManagerEnhancementStatus.includes(enhancementStatus)

export const disableDeclineEnhancement = (enhancementStatus: SubmitStatus) =>
  ![SubmitStatus.PENDING, SubmitStatus.AMENDED, SubmitStatus.APPROVED].includes(enhancementStatus)
