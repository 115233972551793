import { CurrentEntitlementPeriodResponse, SelectOption } from '../../services/dashboardService'
import { EnhancementDay, EnhancementGetResponse, SubmitStatus } from '../../models/enhancement'
import { MyRequestDetailResponse } from '../../types/requests'
import { enhancementTypes, EntitlementPeriodType } from '../../utils/constants'
import { enhancementsList } from '../../utils/SharedMethods/isEnhancement'
import {
  findLatestCommentByStatus,
  latestApproverComment,
} from '../../utils/SharedMethods/getCommentsFromStatusHistory'

const editableRequestTypes = ['Holiday', 'Work From Home', 'Birthday', 'Day Off']

const nonEditableAmendedRequests = [...enhancementsList, 'Shift', 'Lieu']
const nonDeletableApprovedRequests = [
  'Holiday (M)',
  'Lieu (M)',
  'Adjustment',
  'Buy',
  'Sell',
  ...enhancementsList,
]

export function setYear(
  year: number,
  entitlement: CurrentEntitlementPeriodResponse | undefined
): SelectOption | null {
  if (!entitlement) {
    return null
  }

  const yearValue: SelectOption = {
    value: year,
    displayValue:
      entitlement.entitlementPeriod === 'CalendarYear' ? String(year) : `${year}/${year + 1}`,
    entitlementPeriod: entitlement.entitlementPeriod as EntitlementPeriodType,
  }

  return yearValue
}

export const canEditPendingRequest = (requestType: string, requestStatus?: string): boolean => {
  const isAmended = requestStatus && requestStatus.toLowerCase() === 'amended'

  if (requestType === enhancementTypes.callOut) return false
  return (
    editableRequestTypes.includes(requestType) ||
    (nonEditableAmendedRequests.includes(requestType) && !isAmended)
  )
}

export const calculateTotalHours = (requestDays: EnhancementDay[]) => {
  let total = 0
  requestDays.forEach(day => {
    total += day.checked ? Number(day.hours) : 0
  })
  return total
}

export const transformEnhancementData = (
  data: EnhancementGetResponse
): MyRequestDetailResponse => ({
  currentStatus: data.currentStatus,
  requestId: data.id,
  type: data.enhancementType,
  employeeId: data.employeeDetails?.id ? data.employeeDetails.id : 0,
  dateFrom: data.dateRange.start!,
  dateTo: data.dateRange.end!,
  totalRequestedHours: calculateTotalHours(data.days!),
  requestDays: data.days!.map(({ day, hours, checked }, index) => ({
    day: day!,
    requestDayId: index + 1,
    hours: hours ? hours.toLocaleString() : '0',
    checked: checked!,
  })),
  requesterComments: findLatestCommentByStatus(data.statusHistory, SubmitStatus.PENDING),
  reviewerComments: latestApproverComment(data.statusHistory),
  errors: data.errors,
})

export const canDeleteApprovedRequest = (requestType: string): boolean =>
  !nonDeletableApprovedRequests.includes(requestType)
