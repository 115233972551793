import { useCallback, useEffect, useState } from 'react'
import { Box, Grid, IconButton, Skeleton, Typography, useMediaQuery } from '@mui/material'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { RootStore, useAppDispatch } from '../../../redux/store'
import {
  hideDrawer,
  setSidebarExpanded,
  showDrawer,
} from '../../../redux/reducers/appSettingsReducer'
import Logout from '../../UI/Logout'
import { canCreateRequest } from '../../../utils/app-utils'
import { PageHeaderProps } from './types'
import { useNewRequestStatus } from '../../../services/useNewRequestStatus'
import Notifications from '../../UI/Notifications'
import {
  StyledGrid,
  StyledStack,
  OpenBurgerMenuButton,
  PageHeaderStructureBox,
  MobilePageHeaderBox,
  MobilePageHeaderIconBox,
} from './components'
import { isMobileDown, isMobilePortrait } from '../../../theme/deviceChecks'
import Drawer from '../../UI/Drawer'
import NewRequest from '../../../components/NewRequest'

function PageHeader({
  title,
  leftComponent = null,
  button = null,
  subtitle = null,
  secondaryButton = null,
}: PageHeaderProps) {
  const [isNewRequestEnabled] = useNewRequestStatus()

  const showSideDrawer = useSelector<RootStore, boolean>(
    (state: RootStore) => state.appSettings.showDrawer
  )

  const userInfo = useSelector((state: RootStore) => state.userState.loggedInUser)
  const mobile = useMediaQuery(isMobileDown())
  const isMobPortrait = useMediaQuery(isMobilePortrait())

  const dispatch = useAppDispatch()

  const [headerClass, setHeaderClass] = useState<string>('headerTall')

  const scrollFunction = useCallback(() => {
    if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
      setHeaderClass('headerShort')
    } else {
      setHeaderClass('headerTall')
    }
  }, [])

  const dispatchSidebarAction = useCallback(
    (sideBarExpanded: boolean) => {
      dispatch(setSidebarExpanded(sideBarExpanded))
    },
    [dispatch]
  )

  useEffect(() => {
    if (mobile || isMobile) {
      window.onscroll = () => scrollFunction()

      return () => {
        window.onscroll = null
      }
    }
  }, [mobile, scrollFunction])

  return (
    <>
      {mobile || isMobile ? (
        <div className="mobilePageHeader">
          <StyledStack className={headerClass}>
            <Box>
              <OpenBurgerMenuButton
                fontSize="large"
                color="inherit"
                onClick={() => {
                  dispatchSidebarAction(true)
                }}
              />
            </Box>
            <MobilePageHeaderBox>
              <Typography variant="h1">{title}</Typography>
            </MobilePageHeaderBox>
            <MobilePageHeaderIconBox>
              <IconButton
                id="notifications-button"
                onClick={() => {
                  dispatch(showDrawer())
                }}
                disabled={!canCreateRequest() || !isNewRequestEnabled}
                sx={{
                  '&.Mui-disabled svg': {
                    opacity: 0.2,
                  },
                }}
              >
                <AddCircleOutlineIcon fontSize="large" color="action" />
              </IconButton>
              <Notifications />
            </MobilePageHeaderIconBox>
          </StyledStack>
          <PageHeaderStructureBox />
        </div>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} marginBottom={4} className="page-header">
            <StyledGrid container spacing={4}>
              <Grid
                item
                xs={6}
                md={6}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                {leftComponent}
                <Typography variant="h1">
                  {title ||
                    (userInfo ? (
                      `Welcome, ${userInfo?.given_name}`
                    ) : (
                      <Skeleton width={300} height={40} />
                    ))}
                </Typography>
                {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
                {secondaryButton}
              </Grid>

              <Grid
                item
                xs={3}
                md={6}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
              >
                {button}
                <IconButton
                  id="notifications-button"
                  onClick={() => {
                    dispatch(showDrawer())
                  }}
                  disabled={!canCreateRequest() || !isNewRequestEnabled}
                  sx={{
                    '&.Mui-disabled svg': {
                      opacity: 0.2,
                    },
                  }}
                  data-testid="Dashboard-NewRequestBtn"
                >
                  <AddCircleOutlineIcon fontSize="large" color="primary" />
                </IconButton>
                <Notifications />
                <Logout />
              </Grid>
            </StyledGrid>
          </Grid>
        </Grid>
      )}

      <Drawer
        isOpen={showSideDrawer}
        onClose={() => dispatch(hideDrawer())}
        showOptions={false}
        title="New Request"
      >
        <Box sx={{ p: isMobPortrait ? 0 : 4, pt: 0 }}>
          <NewRequest onClose={() => dispatch(hideDrawer())} />
        </Box>
      </Drawer>
    </>
  )
}

export default PageHeader
