/* tslint:disable */
/* eslint-disable */
/**
 * Planner 365 API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum RequestStatus {
  APPROVED = 1,
  APPROVED_CR = 2,
  AMENDED = 3,
  AMENDED_CR = 4,
  DECLINED = 5,
  PENDING = 6,
  CANCELLED = 7,
}
