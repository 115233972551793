import { useCallback, useMemo } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { Chip, Grid, Link, Typography } from '@mui/material'
import Card from '../../../shared/layout/Card'
import TypeLabel from '../../../shared/UI/TypeLabel'
import { getPalleteTypeByProp } from '../../../theme/palette'
import Button from '../../../shared/UI/Button'
import { StyledTableCell, StyledTableHead } from './style'
import { enhancementsContent } from '../../../utils/constants'
import { EnhancementStub, SubmissionPeriod } from '../../../models/enhancement'
import NoResult from '../../../shared/UI/NoResult'
import ButtonSmall from '../../../shared/UI/ButtonSmall'
import { filterBtnStyles } from '../../MyActions/MyActionsFilter/Styles'
import CounterLabel from '../../../shared/UI/CounterLable/CounterLabel'

const baseUrl = '/enhancements'
const testid = 'enhancement-summary-'
const resolveToPath = (type: string, id: number) => {
  const resolver: { [key: string]: string } = {
    'on call': 'oncallrequest',
    'call out': 'calloutrequest',
    night: 'nightrequest',
    overtime: 'overtimerequest',
  }
  return `${baseUrl}/${resolver[type.toLowerCase()]}/${id}`
}

export type EnhancementsSummaryTableProps = {
  data?: EnhancementStub[]
  title?: string
  currentPeriod?: SubmissionPeriod
  pendingEnhancements: number
  disableSubmitButton: boolean
  handleSubmitToPayroll: () => void
}

interface Column {
  id: 'displayName' | 'hours' | 'enhancementType' | 'comments'
  width: string
  label: string
}
const columns: Column[] = [
  { id: 'displayName', label: 'Employee', width: '25%' },
  { id: 'hours', label: 'Hours', width: '15%' },
  { id: 'enhancementType', label: 'Type', width: '15%' },
  { id: 'comments', label: 'Comments', width: '45%' },
]

const cellContent = (columnId: string, row: EnhancementStub) => {
  let cell: string | number | unknown
  switch (columnId) {
    case 'displayName':
      cell = (
        <Link component="button" variant="body2" textAlign="left">
          {row[columnId]}
        </Link>
      )
      break
    case 'enhancementType':
      cell = <TypeLabel label={getPalleteTypeByProp(row[columnId]).label} type={row[columnId]} />
      break
    default:
      cell = row[columnId as keyof EnhancementStub]
  }
  return cell
}

export function EnhancementsSummaryTable({
  data,
  title,
  currentPeriod,
  pendingEnhancements,
  disableSubmitButton,
  handleSubmitToPayroll,
}: EnhancementsSummaryTableProps) {
  const navigate = useNavigate()
  const handleRowClick = (type: string, id: number) => {
    if (currentPeriod?.year && currentPeriod?.period) {
      navigate(
        {
          pathname: baseUrl,
          search: `?${createSearchParams({
            year: currentPeriod?.year.toString(),
            period: currentPeriod?.period.toString(),
          })}`,
        },
        { replace: true }
      )
    }
    navigate(resolveToPath(type, id), {
      state: { previousLocation: baseUrl, params: { ...currentPeriod } },
    })
  }
  const renderRows = useMemo(
    () =>
      data?.map(row => (
        <TableRow
          hover
          sx={{ cursor: 'pointer' }}
          tabIndex={-1}
          key={row.enhancementId}
          data-testid={`${testid}row-${row.enhancementId}`}
          onClick={() => handleRowClick(row.enhancementType, row.enhancementId)}
        >
          {columns.map(column => (
            <TableCell
              key={column.id}
              width={column.width}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px',
              }}
            >
              {cellContent(column.id, row)}
            </TableCell>
          ))}
        </TableRow>
      )),
    [data]
  )

  const pendingCount = useCallback(
    () =>
      pendingEnhancements > 0 ? (
        <ButtonSmall
          label="Pending Requests"
          variant="outlined"
          disabled
          sx={{ minWidth: 'auto' }}
          dataTestId="pending-counter-button"
          endIcon={
            <Chip
              data-testid="pending-counter"
              sx={{ ...filterBtnStyles, background: '#fff', color: '#8D8D8D' }}
              label={<CounterLabel count={pendingEnhancements} />}
            />
          }
        />
      ) : (
        <></>
      ),
    [pendingEnhancements]
  )
  if (!data) {
    return <Typography variant="h5">{enhancementsContent.noData}</Typography>
  }

  return (
    <Card title={title} icon={pendingCount()} padding="24px 28px" noDivider>
      <TableContainer sx={{ maxHeight: 440, marginTop: '14px' }} data-testid={`${testid}table`}>
        <Table stickyHeader aria-label="enhancement summary table">
          <StyledTableHead>
            <TableRow>
              {columns.map(column => (
                <StyledTableCell key={column.id}>{column.label}</StyledTableCell>
              ))}
            </TableRow>
          </StyledTableHead>
          <TableBody>{renderRows}</TableBody>
        </Table>
      </TableContainer>
      {!data?.length ? <NoResult message="No Requests" showImage /> : <></>}
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button
          label={enhancementsContent.submitToPayroll}
          type="submit"
          className="freetext"
          dataTestId="submit-to-payroll"
          onClick={handleSubmitToPayroll}
          disabled={disableSubmitButton}
        />
      </Grid>
    </Card>
  )
}
