import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import Tab from '../../shared/UI/Tab'
import Search from '../Search'
import History from '../History'
import { RootStore } from '../../redux/store'

interface SearchAndHistoryTab {
  label: string
  tabContext: JSX.Element
  disabled: boolean
}

function SearchAndHistory() {
  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  const canViewSearch = userPermissions.includes('ViewMyActions' && 'ViewWhoAwayManager')
  const canViewAttendanceHistory = userPermissions.includes('AttendanceHistory')

  const getTabs = (): SearchAndHistoryTab[] => {
    const tabs: SearchAndHistoryTab[] = []

    if (canViewSearch) {
      tabs.push({
        label: 'Search',
        tabContext: <Search />,
        disabled: false,
      })
    }

    if (canViewAttendanceHistory) {
      tabs.push({
        label: 'History',
        tabContext: <History />,
        disabled: false,
      })
    }

    return tabs
  }

  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <Tab titleAndContent={getTabs()} />
      </Grid>
    </Grid>
  )
}

export default SearchAndHistory
