import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { styled as MuiStyled } from '@mui/material/styles'
import { Box, Popover } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

interface NavLinkProps {
  isActive?: boolean
}

const bounce = {
  '@keyframes bounce': {
    from: {
      width: '35%',
    },
    to: {
      width: '100%',
    },
  },
}

const psuedoStyles = {
  content: "''",
  position: 'absolute',
  top: 0,
  display: 'block',
  borderRadius: '8px',
  height: '100%',
  transition: 'background-color 0.35s ease, color 0.3s ease, width 0.3s ease',

  '.desktopNavDrawer &': {
    left: 0,
  },

  '.mobileNavDrawer &': {
    left: '-28%',
  },
}
export const NavLink = MuiStyled(Link)<NavLinkProps>({
  ...bounce,
  position: 'relative',
  height: '48px',
  backgroundColor: '#fff',
  color: '#20C5A0',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  margin: '0.5rem 0',

  transition: 'background-color 0.3s ease, color 0.3s ease',

  '.desktopNavDrawer &': {
    '&:before': {
      ...psuedoStyles,
      width: '35%',
      background: 'rgba(244, 245, 247, 0)',
    },
    '& button': {
      transition: 'all 0.3 ease',
    },
    '&.NavLinkActive': {
      '&:after': {
        animation: 'bounce 0.3s cubic-bezier(.37,1.45,.74,.88) forwards',
      },
      '&:before': {
        opacity: 0,
      },
    },
  },

  '&:after': {
    ...psuedoStyles,
    opacity: 0,
    background: 'rgba(32, 197, 160, 0)',
  },

  '&:not(.NavLinkActive)': {
    '&:hover': {
      '&:before': {
        width: '100%',
        background: 'rgba(244, 245, 247, 1)',
      },
    },
  },

  '&.NavLinkActive': {
    fontWeight: '700',
    '& button': {
      color: '#fff',
    },

    '&:after': {
      width: '100%',
      opacity: 1,
      background: 'rgba(32, 197, 160, 1)',
    },
  },
})

export const SubNavLink = styled(Link)<NavLinkProps>`
  position: relative;
  height: 48px;
  color: ${({ isActive }) => (isActive ? '#20C5A0' : 'white')};
  font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: color 0.3s ease;
  &:not(.popoverNavLink) {
    padding: 0 0 0 40px;
  }

  && button {
    color: ${({ isActive }) => (isActive ? 'white' : '#747474')};
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background: rgba(244, 245, 247, 0);
    width: 35%;
    height: 100%;
    border-radius: 8px;
    transition: all 0.3s ease;
  }

  .MuiPopover-root &:before {
    border-radius: unset;
  }

  &:hover {
    &:before {
      width: 100%;
      background: rgba(244, 245, 247, 1);
    }
  }
`

interface NavLinkTitleProps {
  isActive: boolean
}
export const NavLinkTitle = styled(Box)<NavLinkTitleProps>`
  font-weight: 500;
  margin-left: 1rem;
  font-family: Poppins;
  font-size: 13px;
  white-space: nowrap;
  z-index: 1;
  color: #747474;
  transition: all 0.3s ease;

  .NavLinkActive & {
    color: #fff;
  }
`
export const SubNavLinkTitle = styled(Box)<NavLinkTitleProps>`
  font-weight: 500;
  margin-left: 1rem;
  font-family: Poppins;
  font-size: 13px;
  color: ${({ isActive }) => (isActive ? '#20C5A0' : '#747474')};
  white-space: nowrap;
  z-index: 1;
  margin-left: 30px;
  transition: all 0.3s ease;
`

export const StyledPopover = styled(Popover)({
  '& .MuiPaper-root': {
    marginLeft: '60px',
    width: '200px',
    boxShadow: '2px 12px 11px 2px #00000010',
  },

  '& span': {
    marginLeft: '30px',
  },
})

export const StyledArrow = styled(ExpandMore)({
  margin: '0 0 0 50px',
  transition: 'transform 0.25s ease-out',

  '&.sideBarExpanded': {
    transform: 'rotate(-180deg)',
  },
})
