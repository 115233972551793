/* eslint-disable no-irregular-whitespace */
import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { LoadingIndicatorCustomProps } from './types'

interface ComponentProps {
  hide: boolean
}
interface LoadingProps {
  bgColor?: string
  removeBorderRadius?: boolean
  loadingHeight?: string
  loadingBoxShadow?: boolean
}

export const LoadingContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'alignItems' && prop !== 'margin',
})<LoadingIndicatorCustomProps>(({ alignItems, margin }) => ({
  ...{
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: alignItems || 'flex-start',
    margin,
  },
})) as React.ComponentType<LoadingIndicatorCustomProps>

export const LoadingComponent = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'bgColor' &&
    prop !== 'removeBorderRadius' &&
    prop !== 'loadingHeight' &&
    prop !== 'loadingBoxShadow',
})<LoadingProps>`
  position: relative;
  ${({ bgColor }) => `background-color: ${bgColor || '#fff'};`}
  ${({ removeBorderRadius }) => `border-radius: ${removeBorderRadius ? '0' : '16px'};`}
  width: 100%;
  ${({ loadingHeight }) => `height: ${loadingHeight || '100%'};`};
  ${({ loadingBoxShadow }) => loadingBoxShadow && `box-shadow: 1px 2px 10px #00000014;`};
`

export const GridLoadingComponent = styled(Grid, {
  shouldForwardProp: prop =>
    prop !== 'bgColor' && prop !== 'removeBorderRadius' && prop !== 'loadingHeight',
})<LoadingProps>`
  position: relative;
  ${({ bgColor }) => `background-color: ${bgColor || '#fff'};`}
  ${({ removeBorderRadius }) => `border-radius: ${removeBorderRadius ? '0' : '16px'};`}
  ${({ loadingHeight }) => `height: ${loadingHeight || 'unset'};`};
  width: 100%;
`

export const ComponentContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'hide',
})<ComponentProps>`
  transition: opacity 0.3s ease;
  ${({ hide }) => `opacity: ${hide ? '0' : '1'};`}
  height: 100%;
  width: 100%;
`

const fadeIn = `@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`

export const ComponentLoadingContainer = styled(Box)`
  ${fadeIn}
  position: absolute;
  height: 100%;
  max-height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 15px;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
`
