import { Grid } from '@mui/material'
import Paragraph from '../../../shared/UI/Paragraph'

interface SummaryDetailRowProps {
  title: string
  rowValue: string
}

function SummaryDetailRow({ title, rowValue }: SummaryDetailRowProps) {
  return (
    <>
      <Grid item xs={6}>
        <Paragraph>{title}</Paragraph>
      </Grid>
      <Grid item xs={6} display="flex" justifyContent="flex-end">
        <Paragraph>{rowValue}</Paragraph>
      </Grid>
    </>
  )
}

export default SummaryDetailRow
