import { Stack, TextField } from '@mui/material'
import { DefaultThresholds } from '../../types/default-thresholds'

type dayDefaultProps = {
  defaults: DefaultThresholds
  onChange?: (defaultThresholdId: number, field: string, value: number) => void
  disabled?: boolean
}

function SettingsThresholdsTeamDefaultHours({ defaults, onChange, disabled }: dayDefaultProps) {
  const validateHours = (val: number) => {
    if (val > 9999) {
      return 9999
    }
    if (val < 0) {
      return 0
    }
    return val
  }

  const weekDayHours = [
    {
      label: 'Mon',
      value: defaults.monHours,
      type: 'monHours',
    },
    {
      label: 'Tue',
      value: defaults.tueHours,
      type: 'tueHours',
    },
    {
      label: 'Wed',
      value: defaults.wedHours,
      type: 'wedHours',
    },
    {
      label: 'Thu',
      value: defaults.thuHours,
      type: 'thuHours',
    },
    {
      label: 'Fri',
      value: defaults.friHours,
      type: 'friHours',
    },
    {
      label: 'Sat',
      value: defaults.satHours,
      type: 'satHours',
    },
    {
      label: 'Sun',
      value: defaults.sunHours,
      type: 'sunHours',
    },
  ]

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
      {weekDayHours.map((weekDay, index) => (
        <TextField
          label={weekDay.label}
          type="number"
          InputProps={{
            name: `SettingsThresholdsTeamDefaultHours-${defaults.defaultThresholdId}-${index}`,
            inputProps: { min: 0, max: 9999 },
          }}
          value={weekDay.value}
          onChange={e => {
            onChange?.(
              defaults.defaultThresholdId,
              weekDay.type,
              validateHours(parseInt(e.target.value, 10))
            )
            setTimeout(() => {
              const name = e.target.getAttribute('name')
              const input = (document as any).querySelector(`input[name="${name}"]`)
              input.focus()
            }, 10)
          }}
          fullWidth
          disabled={disabled}
          key={`${weekDay.label}${weekDay.value}`}
          onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
        />
      ))}
    </Stack>
  )
}

export default SettingsThresholdsTeamDefaultHours
