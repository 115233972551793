import { Autocomplete, TextField, createFilterOptions } from '@mui/material'
import { useEffect, useState } from 'react'
import { BookingAutoComplete } from './types'
import { SelectOption } from '../../../services/dashboardService'

const filter = createFilterOptions<SelectOption>()

function EmployeeAutoComplete({
  label,
  id,
  data,
  textField,
  value,
  error,
  disabled,
  multiple,
  helperText,
  limitTags,
  disableClearable,
  onInputRef,
  onChange,
}: BookingAutoComplete) {
  return (
    <Autocomplete
      id={id}
      value={value}
      onChange={onChange}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        const { inputValue } = params
        const isExisting = options.some(option => inputValue === option.displayValue)
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            value: -1,
            displayValue: `Add "${inputValue}"`,
          })
        }

        return filtered
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={data}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option
        }
        return option.displayValue
      }}
      renderOption={(props, option) => <li {...props}>{option.displayValue}</li>}
      freeSolo
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          error={error}
          helperText={helperText}
          inputRef={input => {
            onInputRef(input)
          }}
        />
      )}
    />
  )
}

export default EmployeeAutoComplete
