import { Dispatch } from 'react'
import { ToDo } from '../../models/ToDo'

export interface todoState {
  todos: ToDo[]
}
export const initialState: todoState = {
  todos: [
    {
      id: 1,
      text: 'Hello there',
      completed: false,
    },
  ],
}

// Action Types
const ADD_TODO = 'ADD_TODO'
const REMOVE_TODO = 'REMOVE_TODO'

// Action Creators
interface AddToDoAction {
  type: typeof ADD_TODO
  payload: ToDo
}

interface RemoveToDoAction {
  type: typeof REMOVE_TODO
  payload: number
}

type toDoDispatchtypes = AddToDoAction | RemoveToDoAction

// Actions
export const addToDo = (todo: ToDo) => async (dispatch: Dispatch<toDoDispatchtypes>) => {
  dispatch({ type: ADD_TODO, payload: todo })
}

// Reducer
export const todosReducer = (
  state: todoState = initialState,
  action: toDoDispatchtypes
): todoState => {
  switch (action.type) {
    case 'ADD_TODO':
      return { ...state, todos: [...state.todos, action.payload] }
    case 'REMOVE_TODO':
      return state
    default:
      return state
  }
}
