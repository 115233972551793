import styled from 'styled-components'
import theme from '../../../theme/theme'

type Props = {
  children: JSX.Element | JSX.Element[]
  onClick?: ((event: any) => void) | undefined
}

const StyledActionIcon = styled.a`
  color: ${theme.palette.primary.main};
  cursor: pointer;
`

function ActionIcon({ children, onClick }: Props) {
  return <StyledActionIcon onClick={onClick}>{children}</StyledActionIcon>
}

export default ActionIcon
