import { InputAdornment, Stack, TextField } from '@mui/material'
import styled from 'styled-components'
import { DefaultThresholds } from '../../types/default-thresholds'

const StyledLowTextField = styled(TextField)`
  & .MuiInputLabel-root {
    color: #52b596 !important;
  }
  & .MuiInputLabel-root.Mui-focused {
    color: #52b596;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #52b596;
  }
`

const StyledMediumTextField = styled(TextField)`
  & .MuiInputLabel-root {
    color: #f1ab00 !important;
  }
  & .MuiInputLabel-root.Mui-focused {
    color: #f1ab00;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #f1ab00;
  }
`

const StyledHighTextField = styled(TextField)`
  & .MuiInputLabel-root {
    color: #e75e5e !important;
  }
  & .MuiInputLabel-root.Mui-focused {
    color: #e75e5e;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #e75e5e;
  }
`

type ThesholdPercentageProps = {
  defaults: DefaultThresholds
  onChange?: (defaultThresholdId: number, field: string, value: number | boolean) => void
  disabled?: boolean
  dataTestId?: string
}

function SettingsThresholdsPercentages({
  defaults,
  onChange,
  disabled,
  dataTestId,
}: ThesholdPercentageProps) {
  const validatePercentage = (val: number, type: string) => {
    if (val > 100) {
      return 100
    }
    if (val < 0) {
      return 0
    }
    return val
  }

  return (
    <Stack direction="row" gap={2}>
      <StyledLowTextField
        label="Low"
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
          inputProps: { min: 0, max: 100 },
        }}
        fullWidth
        value={defaults.warningPercentageLevel1}
        onChange={e => {
          const validated = validatePercentage(Number(e.target.value), 'low')
          onChange?.(defaults.defaultThresholdId!, 'warningPercentageLevel1', validated)
        }}
        disabled={disabled}
        onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
        data-testid={`${dataTestId}-LowWarningPct`}
      />
      <StyledMediumTextField
        label="Medium"
        type="number"
        inputMode="numeric"
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
          inputProps: { min: 0, max: 100 },
        }}
        value={defaults.warningPercentageLevel2}
        onChange={e => {
          const validated = validatePercentage(Number(e.target.value), 'med')
          onChange?.(defaults.defaultThresholdId!, 'warningPercentageLevel2', validated)
        }}
        fullWidth
        disabled={disabled}
        onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
        data-testid={`${dataTestId}-MedWarningPct`}
      />
      <StyledHighTextField
        label="High"
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
          inputProps: { min: 0, max: 100 },
        }}
        value={defaults.warningPercentageLevel3}
        onChange={e => {
          const validated = validatePercentage(Number(e.target.value), 'high')
          onChange?.(defaults.defaultThresholdId!, 'warningPercentageLevel3', validated)
        }}
        fullWidth
        disabled={disabled}
        onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
        data-testid={`${dataTestId}-HighWarningPct`}
      />
    </Stack>
  )
}

export default SettingsThresholdsPercentages
