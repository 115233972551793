import { ThemeProvider } from '@mui/material'
import { LicenseInfo } from '@mui/x-license-pro'
import { OktaAuth } from '@okta/okta-auth-js'
import { Provider } from 'react-redux'
import { AppBody } from './components/AppBody/AppBody'
import config from './authentication/oktaConfig'
import store from './redux/store'
import theme from './theme/theme'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY!)

export const oktaAuth = new OktaAuth(config.oidc)

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppBody />
      </ThemeProvider>
    </Provider>
  )
}

export default App
