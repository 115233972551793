import { format } from 'date-fns'
import { FormControlLabel, Grid } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../../../../redux/store'
import Button from '../../../../shared/UI/Button'
import AutocompleteList from '../../../../shared/UI/AutocompleteList'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { IOSStyleSwitch } from '../../../../shared/UI/IOSStyleSwitch/IOSStyleSwitch'
import { FormProps, Search } from './types'
import {
  CurrentEntitlementPeriodResponse,
  SelectOption,
} from '../../../../services/dashboardService'
import DropdownMenu from '../../../../shared/UI/DropdownMenu'
import { EmployeeDetailsResponse } from '../../../../types/employee'

function HolidaysEntitlementRemainingAllowanceSearchForm({
  resetForm,
  userDepartments,
  onSearch,
}: FormProps) {
  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)
  const { calendarDetails } = useSelector(
    (state: RootStore) => state.appSettings.calendarDetailsResponse!
  )
  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse>(
    (state: RootStore) => state.appSettings.employeeDetails
  )

  const department = userDepartments.find(p => p.value === employeeDetails.departmentId)
  const [remainingEntitlement, setRemainingEntitlement] = useState<boolean>(true)
  const [directReportees, setDirectReportees] = useState<boolean>(true)
  const [selectedYear, setSelectedYear] = useState<CurrentEntitlementPeriodResponse | null>(
    currentEntitlementPeriodResponse !== undefined ? currentEntitlementPeriodResponse : null
  )
  const [selectedDepartments, setSelectedDepartments] = useState<SelectOption[]>(
    department !== undefined
      ? [{ displayValue: department!.displayValue, value: department!.value }]
      : []
  )

  const onHandleDepartmentsChange = (event: unknown, items: SelectOption[]) => {
    setSelectedDepartments(items)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const search: Search = {
      departmentIds: selectedDepartments?.map(p => p.value),
      fromDateRangeFrom: format(new Date(selectedYear!.startDate), 'yyyy-MM-dd'),
      fromDateRangeTo: format(new Date(selectedYear!.endDate), 'yyyy-MM-dd'),
      remainingEntitlement,
      directReportees,
    }

    if (selectedDepartments.length === 0) {
      search.departmentIds = userDepartments.map(p => p.value)
    }

    onSearch(search)
  }

  useDidMountEffect(() => {
    setSelectedDepartments(
      department !== undefined
        ? [{ displayValue: department!.displayValue, value: department!.value }]
        : []
    )
    setSelectedYear(currentEntitlementPeriodResponse!)
    setRemainingEntitlement(true)
    setDirectReportees(true)
  }, [resetForm])

  return (
    <Grid container spacing={4} component="form" noValidate mt={0}>
      {/* Main Layout 4 Col */}
      <Grid item xs={12} lg={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropdownMenu
              label="Year"
              id="year"
              textField="year"
              valueField="year"
              value={selectedYear ? selectedYear!.year : null}
              data={calendarDetails}
              onChange={e => {
                setSelectedYear(calendarDetails.find(p => p.year === parseInt(e.target.value, 10))!)
              }}
            />
          </Grid>
          {/* Main Layout 4 Col END */}
        </Grid>
      </Grid>
      {/* Main Layout 8 Col */}
      <Grid item xs={12} lg={8}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Grid item xs={12} style={{ flex: 1 }}>
              <AutocompleteList
                multiple
                id="departments"
                label="All Departments"
                data={userDepartments}
                textField="displayValue"
                value={selectedDepartments || null}
                onChange={onHandleDepartmentsChange}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} style={{ flex: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ flex: 1 }}>
                <FormControlLabel
                  style={{
                    justifyContent: 'flex-end',
                    width: '100%',
                    margin: '0',
                    gap: '20px',
                  }}
                  control={<IOSStyleSwitch checked={remainingEntitlement} />}
                  label="Remaining Entitlement"
                  labelPlacement="start"
                  onClick={() => {
                    setRemainingEntitlement(!remainingEntitlement)
                  }}
                  sx={{ height: '50px' }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} style={{ flex: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ flex: 1 }}>
                <FormControlLabel
                  style={{
                    justifyContent: 'flex-end',
                    width: '100%',
                    margin: '0',
                    gap: '20px',
                  }}
                  control={<IOSStyleSwitch checked={directReportees} />}
                  label="Direct Reports"
                  labelPlacement="start"
                  onClick={() => {
                    setDirectReportees(!directReportees)
                  }}
                  sx={{ height: '50px' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Main Layout 8 Col END */}
      </Grid>
      <Grid item xs={12} pt={0} mt={0} display="flex" justifyContent="flex-end">
        <Button label="Search" type="submit" onClick={handleSubmit} />
      </Grid>
    </Grid>
  )
}

export default HolidaysEntitlementRemainingAllowanceSearchForm
