import { Drawer, styled } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import theme from '../../../theme/theme'
import { isMobileDown, isMobilePortrait, isMobilePortraitUp } from '../../../theme/deviceChecks'

type StyledDrawerProps = {
  narrow: boolean
  mobileHeight: string | undefined
}

export const CloseDrawerButton = styled(ChevronRightIcon)({
  width: '24px',
  height: '24px',
  padding: '2px',
  backgroundColor: 'white',
  color: theme.palette.text.primary,
  borderRadius: '50%',
  position: 'absolute',
  cursor: 'pointer',
  left: '-14px',
  top: '39px',
})

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'narrow' && prop !== 'mobileHeight',
})<StyledDrawerProps>(({ narrow, mobileHeight }) => ({
  ...{
    '& .MuiDrawer-paper': {
      width: narrow ? '570px' : '60%',
    },

    '&:not(.mobileNavDrawer)': {
      '.MuiDrawer-paper': {
        borderRadius: '16px 0 0 16px',
      },
    },

    '&.mobileNavDrawer > .MuiPaper-root > .MuiBox-root': {
      height: '100%',
      padding: '0 0 32px 0',
    },

    [isMobilePortrait()]: {
      '& .MuiDrawer-paper': {
        boxShadow: '0px 6px 0px #fff',
        paddingTop: 0,
      },

      '&:not(.mobileNavDrawer)': {
        '& .MuiDrawer-paper': {
          width: '100%',
          height: mobileHeight || '90%',
          maxHeight: '90%',
        },
      },

      '&.mobileNavDrawer .MuiDrawer-paper': {
        width: '75vw',
        maxWidth: '300px',
        height: '100%',
      },
    },

    [isMobilePortraitUp()]: {
      '&.mobileDrawer .MuiDrawer-paper': {
        width: '85%',
      },

      '&.mobileNavDrawer .MuiDrawer-paper': {
        width: '280px',
      },
    },

    [isMobileDown()]: {
      '&.MuiDrawer-root > .MuiDrawer-paper': {
        borderRadius: '0',
      },
    },
  },
}))
