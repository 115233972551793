import { Box, Stack, useMediaQuery } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Card from '../../shared/layout/Card'
import LegendItem from './LegendItem'
import palette from '../../theme/palette'
import { RootStore, useAppDispatch } from '../../redux/store'
import Button from '../../shared/UI/Button'
import { isMobilePortrait } from '../../theme/deviceChecks'
import { setActiveRoute, showDrawer } from '../../redux/reducers/appSettingsReducer'
import { Entitlement } from '../../models/entitlement'
import { boughtSoldType } from '../../types/summary-type'
import CircularProgressBar from '../../shared/UI/CircularProgressBar'
import InfoPopper from '../../shared/UI/InfoPopper'
import Drawer from '../../shared/UI/Drawer'
import BuyRequest from '../BuyRequest'
import { BuyHoursType } from '../../types/buy-hours-type'
import { SellHoursType } from '../../types/sell-hours-type'
import SellRequest from '../SellRequest'
import FullComponentLoadingIcon from '../../shared/UI/LoadingIndicator/FullComponentLoadingIcon'
import Link from '../../shared/UI/Link'
import Paragraph from '../../shared/UI/Paragraph'
import { MyEntitlementResponse } from '../../types/my-entitlement-response'

type Props = {
  title: string
  hideHolidays?: boolean
  buyHours?: BuyHoursType | undefined
  sellHours?: SellHoursType | undefined
  myEntitlement?: MyEntitlementResponse | undefined
  isLoadingEntitlement: boolean
  requestedSellHours?: number
  refreshEntitlement?: () => void
}

const StyledStack = styled(Stack)({
  [isMobilePortrait()]: {
    '&.styledStack': {
      flexDirection: 'column-reverse',
    },
  },
})

function AllowanceChart({
  title,
  hideHolidays = false,
  sellHours,
  buyHours,
  myEntitlement,
  isLoadingEntitlement,
  requestedSellHours,
  refreshEntitlement,
}: Props) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { userSettings } = useSelector((state: RootStore) => state.appSettings)

  const [holidayEntitlement, setHolidayEntitlement] = useState<Entitlement | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [boughtHours, setBoughtHours] = useState<boughtSoldType>()
  const [soldHours, setSoldHours] = useState<boughtSoldType>()
  const [isMyExtras, setIsMyExtras] = useState<boolean>(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [drawerType, setDrawerType] = useState('')

  const isMobPortrait = useMediaQuery(isMobilePortrait())

  useEffect(() => {
    if (window.location.pathname.includes('myextras')) {
      setIsMyExtras(true)
    } else {
      setIsMyExtras(false)
    }
  }, [])

  useEffect(() => {
    if (!isLoadingEntitlement && myEntitlement) {
      setHolidayEntitlement({
        totalHours: myEntitlement.totalHours,
        approvedHours: myEntitlement.approvedHours,
        remainingHours: myEntitlement.remainingHours,
        hostName: myEntitlement.hostName!,
        status: myEntitlement.status!,
      })
      setBoughtHours({
        hoursBoughtSold: myEntitlement.hoursBoughtToDate,
        maximumHoursAvailable: myEntitlement.maxHolidaysAvailableToBuy,
        hoursRemaining: myEntitlement.maxHolidaysAvailableToBuy - myEntitlement.hoursBoughtToDate,
      })
      setSoldHours({
        hoursBoughtSold: myEntitlement.hoursSoldToDate,
        maximumHoursAvailable: myEntitlement.maxHolidaysAvailableToSell,
        hoursRemaining: myEntitlement.maxHolidaysAvailableToSell - myEntitlement.hoursSoldToDate,
      })
    }
  }, [myEntitlement, isLoadingEntitlement])

  const hasBuyOrSell = useMemo(() => {
    if (userSettings) {
      return userSettings?.isBuyRequestAllowed || userSettings?.isSellRequestAllowed
    }
  }, [userSettings])

  const progBarDataSet = useMemo(() => {
    if (userSettings && holidayEntitlement && boughtHours && soldHours) {
      setIsLoading(false)
      const dataSet = []

      if (!hideHolidays) {
        dataSet.push({
          val: holidayEntitlement.approvedHours,
          max: holidayEntitlement.totalHours,
          color: '#00CEC9',
          remaining: holidayEntitlement.remainingHours,
        })
      }

      if (userSettings.isBuyRequestAllowed) {
        dataSet.push({
          val: boughtHours.hoursBoughtSold,
          max: boughtHours.maximumHoursAvailable,
          color: '#F879C7',
          remaining: boughtHours.hoursRemaining,
        })
      }

      if (userSettings.isSellRequestAllowed) {
        dataSet.push({
          val: soldHours.hoursBoughtSold,
          max: soldHours.maximumHoursAvailable,
          color: '#867CFF',
          remaining: soldHours.hoursRemaining,
        })
      }
      return dataSet
    }
    return [
      {
        val: 0,
        max: 0,
        color: '#',
        remaining: 0,
      },
    ]
  }, [boughtHours, holidayEntitlement, soldHours, userSettings, hideHolidays])

  const filterArray = useMemo(() => {
    const arr = []
    if (userSettings?.isBuyRequestAllowed) {
      arr.push('buy')
    }
    if (userSettings?.isSellRequestAllowed) {
      arr.push('sell')
    }
    return arr
  }, [userSettings?.isBuyRequestAllowed, userSettings?.isSellRequestAllowed])

  const popperText = useMemo(
    () => (
      <>
        <Paragraph>
          <span style={{ fontWeight: 'bold' }}>Buy: </span>
          <>
            You may buy up to 5 days additional holiday each holiday year as long as the total
            holiday taken does not exceed 30 days. Please refer to the bet365 Hub for further
            information regarding this policy.
          </>
        </Paragraph>
        <Paragraph padding="20px 0">
          <span style={{ fontWeight: 'bold' }}>Sell: </span>
          <>
            You may sell up to 5 days holiday in each holiday year. Please refer to the bet365 Hub
            for further information regarding this policy.
          </>
        </Paragraph>
      </>
    ),
    []
  )

  return (
    <>
      <Drawer
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        title={drawerType}
        showOptions={false}
        narrow
        mobileHeight="370px"
      >
        <Box sx={{ p: isMobPortrait ? 0 : 4 }}>
          {drawerType === 'Buy' ? (
            <BuyRequest
              closeDrawer={() => setOpenDrawer(false)}
              hours={buyHours}
              drawerType={drawerType}
            />
          ) : (
            <SellRequest
              closeDrawer={() => setOpenDrawer(false)}
              hours={sellHours}
              myEntitlement={myEntitlement}
              requestedSellHours={requestedSellHours}
              refreshEntitlement={refreshEntitlement}
              drawerType={drawerType}
            />
          )}
        </Box>
      </Drawer>
      <Card
        title={title}
        icon={isMyExtras ? <InfoPopper popperText={popperText} /> : undefined}
        noDivider
      >
        <FullComponentLoadingIcon
          loading={isLoading}
          noData={!(holidayEntitlement && holidayEntitlement.totalHours)}
          loadingHeight="457px"
        >
          <Grid
            container
            spacing={4}
            flexGrow="1"
            mt={0}
            height="100%"
            alignContent="space-between"
          >
            <Grid item xs={12} height="300px">
              <CircularProgressBar
                dataSet={progBarDataSet}
                loading={!userSettings || !holidayEntitlement || !boughtHours || !soldHours}
                label={progBarDataSet?.length === 1 ? progBarDataSet[0].remaining : 0}
              />
            </Grid>
            <Grid item xs={12}>
              {!hideHolidays && (
                <LegendItem
                  color={palette.type.holiday.color}
                  type={palette.type.holiday.label}
                  value={`${holidayEntitlement?.approvedHours.toFixed(
                    2
                  )} / ${holidayEntitlement?.totalHours.toFixed(2)}`}
                  divider
                  showLink={isMyExtras}
                />
              )}
              {hasBuyOrSell && (
                <>
                  {userSettings?.isBuyRequestAllowed && (
                    <LegendItem
                      color={palette.type.buy.color}
                      type="Bought"
                      value={`${(boughtHours && boughtHours.hoursBoughtSold.toFixed(2)) || 0}
                       / ${(boughtHours && boughtHours.maximumHoursAvailable.toFixed(2)) || 0}`}
                      divider
                      showLink={isMyExtras}
                      linkLabel="Buy"
                      url={() => {
                        setOpenDrawer(true)
                        setDrawerType('Buy')
                      }}
                      dataTestId="MyExtras-BuyBtn"
                    />
                  )}
                  {userSettings?.isSellRequestAllowed && (
                    <LegendItem
                      color={palette.type.sell.color}
                      type="Sold"
                      value={`${(soldHours && soldHours.hoursBoughtSold.toFixed(2)) || 0}
                     / ${(soldHours && soldHours.maximumHoursAvailable.toFixed(2)) || 0}`}
                      divider
                      showLink={isMyExtras}
                      linkLabel="Sell"
                      url={() => {
                        setOpenDrawer(true)
                        setDrawerType('Sell')
                      }}
                      dataTestId="MyExtras-SellBtn"
                    />
                  )}
                </>
              )}
              {userSettings && !hasBuyOrSell && (
                <StyledStack
                  direction="row"
                  justifyContent="space-between"
                  marginTop={2}
                  className="styledStack"
                >
                  <Button
                    label="Show Detail"
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      navigate('/myavailability')
                      dispatch(setActiveRoute('/myavailability'))
                    }}
                  />
                  <Button
                    variant="outlined"
                    label="New Request"
                    onClick={() => {
                      dispatch(showDrawer())
                    }}
                  />
                </StyledStack>
              )}

              {isMyExtras && hasBuyOrSell && (
                <Grid item xs={12} mt={6}>
                  <Link
                    linkText="Show All Requests"
                    onClick={() => {
                      dispatch(setActiveRoute(`/myavailability`))
                      navigate('/myavailability/', {
                        state: { calendar: false, gridFilters: filterArray },
                      })
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </FullComponentLoadingIcon>
      </Card>
    </>
  )
}

export default AllowanceChart
