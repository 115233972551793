export interface settingsPageState {
  departmentId: number | null
}

export const initialState: settingsPageState = {
  departmentId: 0,
}

const SET_DEPARTMENT = 'SET_DEPARTMENT'

interface SetDepartment {
  type: typeof SET_DEPARTMENT
  payload: number
}

type settingsPageDispatchtypes = SetDepartment

export const setDepartment = (departmentId: number): SetDepartment => ({
  type: SET_DEPARTMENT,
  payload: departmentId,
})

export const settingsPageReducer = (
  state: settingsPageState = initialState,
  action: settingsPageDispatchtypes
): settingsPageState => {
  switch (action.type) {
    case SET_DEPARTMENT:
      return { ...state, departmentId: action.payload }
    default:
      return state
  }
}
