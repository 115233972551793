import IconButton from '@mui/material/IconButton'
import {
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro'
import { isValidElement } from 'react'
import ExpandMore from '@mui/icons-material/ExpandMore'

export function RowExpansionToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
  const { id, value: isExpanded } = props
  const apiRef = useGridApiContext()

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector)

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = isValidElement(contentCache[id])

  return (
    <IconButton
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      data-testid={`row-expansion-toggle${id}`}
    >
      <ExpandMore
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: iconTheme =>
            iconTheme.transitions.create('transform', {
              duration: iconTheme.transitions.duration.shortest,
            }),
        }}
        fontSize="inherit"
      />
    </IconButton>
  )
}
