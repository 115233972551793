import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { GridColDef, DataGrid } from '@mui/x-data-grid'
import { manualRequestsService } from '../../services/myActionsService'
import { getDateTime, getDateTime24HrFormat, getLocalDateString } from '../../utils/date-utils'
import Card from '../../shared/layout/Card'
import { BaseResponse } from '../../types/base-response'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../utils/errorFilter'
import { useAppDispatch } from '../../redux/store'
import { LateAuditHistory } from './types'
import {
  AttendanceHistoryByDateItem,
  AttendanceHistoryByDateResponse,
} from '../../types/attendance-history'

function LateHistory() {
  const [pageSize, setPageSize] = useState<number>(10)

  const { id: lateId } = useParams<{ id: string }>()
  const [historyData, setHistoryData] = useState<LateAuditHistory[]>([])

  const dispatch = useAppDispatch()

  useEffect(() => {
    manualRequestsService
      .getAttendanceHistory(parseInt(lateId as string, 10), 'Late')
      .then((data: AttendanceHistoryByDateResponse) => {
        const res = data.items.map(
          (result: AttendanceHistoryByDateItem, idx: number): LateAuditHistory => ({
            id: `${result.id}_${idx}`,
            dateTime: getDateTime24HrFormat(new Date(result.timestamp), false, ' - '),
            changedBy: result.changedByEmployee?.displayName || '',
            item: result.actionDescription || '',
            value: result.details || '',
          })
        )
        setHistoryData(res)
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      headerClassName: 'header',
      type: 'date',
      flex: 1,
      maxWidth: 150,
    },
    {
      field: 'changedBy',
      headerName: 'Changed By',
      headerClassName: 'header',
      type: 'string',
      flex: 1,
      maxWidth: 180,
    },
    {
      field: 'item',
      headerName: 'Item',
      headerClassName: 'header',
      type: 'string',
      flex: 1,
      maxWidth: 180,
    },
    {
      field: 'value',
      headerName: 'Value',
      headerClassName: 'header',
      type: 'string',
      flex: 1,
      maxWidth: 5000,
    },
  ]
  return (
    <DataGrid
      getRowId={row => row.id}
      rows={historyData}
      columns={columns}
      disableSelectionOnClick
      rowsPerPageOptions={[10, 25, 50, 100]}
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      getRowHeight={() => 'auto'}
      autoHeight
      sx={{
        '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
          whiteSpace: 'pre-wrap',
          paddingTop: '5px',
        },
        '&.MuiDataGrid-root': { border: 'none' },
        '& .MuiDataGrid-cell': { paddingTop: '10px', paddingBottom: '10px' },
        '& .header': { backgroundColor: '#F4F5F7' },
      }}
    />
  )
}

export default LateHistory
