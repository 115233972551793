import { createContext } from 'react'
import { DrawerComponentProps } from '../components/MyRequests/MyRequestsGrid/types'

export const PageContext = createContext({
  getRequestsData: () => {
    /* void */
  },
  viewEvent: (component: DrawerComponentProps | null) => {
    /* void */
  },
  closeViewDrawer: () => {
    /* void */
  },
})
