import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import { Container, DrawerTypography, MoreOptionsIcon } from './components'
import StatusLabel from '../StatusLabel'

interface DrawerHeaderProps {
  title: string
  menuItems?: (typeof MenuItem)[]
  showOptions: boolean
  status?: string
}
function DrawerHeader({ title, showOptions, status }: DrawerHeaderProps) {
  return (
    <Container className="DrawerHeader">
      <DrawerTypography variant="h1">{title}</DrawerTypography>
      {status && <StatusLabel status={status} />}
      {showOptions && <MoreOptionsIcon fontSize="large" color="primary" />}
    </Container>
  )
}

export default DrawerHeader
