import HeadingTwo from '../HeadingTwo'
import HeadingSmall from '../HeadingSmall'

type Props = {
  title: string | number | undefined
  subTitle?: string | number | undefined
}

function CardTitleSub({ title, subTitle }: Props) {
  return (
    <>
      <HeadingTwo title={title} />
      {subTitle && (
        <HeadingSmall 
          title={subTitle}
          style={{
            fontSize: '14px',
            paddingTop: '5px',
          }}
        />
      )}
    </>
  )
}

export default CardTitleSub
