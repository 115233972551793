// QA: According to Stack Overflow, there is a bug in TS which wrongly thinks
// some enums are duplicate declarations.
// https://stackoverflow.com/questions/63961803/eslint-says-all-enums-in-typescript-app-are-already-declared-in-the-upper-scope#answers-header
// eslint-disable-next-line no-shadow
export enum RequestAction {
  REMOVE = 1,
  DECLINE = 2,
  APPROVE = 3,
  APPROVE_WITH_CHANGES = 4,
  REJECT = 5,
  ACCEPT = 6,
  RESET = 7,
  SAVE = 8,
}
// eslint-disable-next-line no-shadow
export enum RequestStatus {
  APPROVED = 1,
  APPROVED_CR = 2,
  AMENDED = 3,
  AMENDED_CR = 4,
  DECLINED = 5,
  PENDING = 6,
  CANCELLED = 7,
  CR_CANCELLED = 10,
}

export type OccurrenceRequestProps = {
  occurrenceType:
    | 'Holiday'
    | 'Lieu'
    | 'Work From Home'
    | 'Day Off'
    | 'Shift'
    | 'Maternity'
    | 'Paternity'
    | 'Twilight Shift Cover'
    | 'Night Shift Cover'
    | 'Course'
    | 'Adjustment'
    | 'Birthday'
    | 'Other'
    | 'Buy'
    | 'Sell'
}
