import { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Box, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ManualRequest from '../../../components/ManualRequest'
import AbsenceAndLate from '../../../components/AbsenceAndLate'
import ButtonSmall from '../ButtonSmall'
import { RootStore } from '../../../redux/store'
import {
  hideDrawer,
  hideHeaderDrawer,
  setSidebarExpanded,
  showHeaderDrawer,
} from '../../../redux/reducers/appSettingsReducer'
import Drawer from '../Drawer'
import Logout from '../Logout'
import { canCreateAttendance, canCreateRequest, canManageRequest } from '../../../utils/app-utils'
import Notifications from '../Notifications'
import { isMobileDown } from '../../../theme/deviceChecks'
import {
  StyledStack,
  OpenBurgerMenuButton,
  PageHeaderStructureBox,
  MobilePageHeaderBox,
  MobilePageHeaderIconBox,
} from '../../layout/PageHeader/components'
import { useNewRequestStatus } from '../../../services/useNewRequestStatus'
import NewRequest from '../../../components/NewRequest'

type Props = {
  title?: string | undefined
  button?: React.ReactElement | null
  subtitle?: string | null
  secondaryButton?: React.ReactElement | null
  hideNewRequest?: boolean
}

function MyActionsPageHeader({ title, hideNewRequest }: Props) {
  const dispatch = useDispatch()
  const [isNewRequestEnabled] = useNewRequestStatus()
  const showSideDrawer = useSelector<RootStore, boolean>(
    (state: RootStore) => state.appSettings.showHeaderDrawer
  )

  const mobile = useMediaQuery(isMobileDown())

  const [headerClass, setHeaderClass] = useState<string>('headerTall')

  const scrollFunction = useCallback(() => {
    if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
      setHeaderClass('headerShort')
    } else {
      setHeaderClass('headerTall')
    }
  }, [])

  useEffect(() => {
    if (mobile) {
      window.onscroll = () => scrollFunction()

      return () => {
        window.onscroll = null
      }
    }
  }, [mobile, scrollFunction])

  const [drawerType, setDrawerType] = useState<React.ReactElement>()
  const [labelType, setLabelType] = useState<string>('')

  const getDrawerType = (type: string) => {
    switch (type) {
      case 'manual':
        setLabelType('Manual Request')
        setDrawerType(<ManualRequest />)
        break
      case 'absence':
        setLabelType('New Occurrence')
        setDrawerType(<AbsenceAndLate />)
        break
      case 'newrequest':
        setLabelType('New Request')
        setDrawerType(
          <NewRequest
            onClose={() => {
              dispatch(hideHeaderDrawer())
              dispatch(hideDrawer())
            }}
          />
        )
        break
      default:
        return ''
    }
  }

  const dispatchSidebarAction = useCallback(
    (sideBarExpanded: boolean) => {
      dispatch(setSidebarExpanded(sideBarExpanded))
    },
    [dispatch]
  )

  return (
    <>
      {mobile || isMobile ? (
        <div className="mobilePageHeader">
          <StyledStack className={headerClass}>
            <Box>
              <OpenBurgerMenuButton
                fontSize="large"
                color="inherit"
                onClick={() => {
                  dispatchSidebarAction(true)
                }}
              />
            </Box>
            <MobilePageHeaderBox>
              {!title ? (
                <Typography variant="h1">My Actions</Typography>
              ) : (
                <Typography variant="h1">{title}</Typography>
              )}
            </MobilePageHeaderBox>
            <MobilePageHeaderIconBox>
              {!hideNewRequest && (
                <IconButton
                  id="notifications-button"
                  onClick={() => {
                    getDrawerType('newrequest')
                    dispatch(showHeaderDrawer())
                  }}
                  disabled={!canCreateRequest() || !isNewRequestEnabled}
                  sx={{
                    '&.Mui-disabled svg': {
                      opacity: 0.2,
                    },
                  }}
                >
                  <AddCircleOutlineIcon fontSize="large" color="action" />
                </IconButton>
              )}
              <Notifications />
            </MobilePageHeaderIconBox>
          </StyledStack>
          <PageHeaderStructureBox />
        </div>
      ) : (
        <Grid container spacing={4} marginBottom={4}>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            {!title ? (
              <Typography variant="h1">My Actions</Typography>
            ) : (
              <Typography variant="h1">{title}</Typography>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
          >
            {canCreateAttendance() && (
              <>
                <ButtonSmall
                  label="Absence &amp; Late"
                  onClick={() => {
                    getDrawerType('absence')
                    dispatch(showHeaderDrawer())
                  }}
                  disabled={!canCreateAttendance()}
                  sx={{
                    '&.MuiButtonBase-root.MuiButton-root': {
                      margin: 0,
                      height: '27px',
                    },
                  }}
                  dataTestId="MyActions-Absence&LateBtn"
                />
                <ButtonSmall
                  label="Manual Request"
                  onClick={() => {
                    getDrawerType('manual')
                    dispatch(showHeaderDrawer())
                  }}
                  disabled={!canManageRequest()}
                  sx={{
                    '&.MuiButtonBase-root.MuiButton-root': {
                      margin: 0,
                      height: '27px',
                    },
                  }}
                  dataTestId="MyActions-ManualRequestBtn"
                />
              </>
            )}
            <Notifications />
            <Logout />
          </Grid>
        </Grid>
      )}

      <Drawer
        isOpen={showSideDrawer}
        onClose={() => {
          dispatch(hideHeaderDrawer())
          dispatch(hideDrawer())
        }}
        title={labelType}
        showOptions={false}
      >
        <Box p={4} pt={0}>
          {drawerType}
        </Box>
      </Drawer>
    </>
  )
}

export default MyActionsPageHeader
