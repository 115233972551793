import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { dashboardService } from '../../services/dashboardService'
import { BaseResponse } from '../../types/base-response'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../utils/errorFilter'
import { MyPendingRequests } from '../../types/my-pending-requests'

export const useMyPendingRequests = () => {
  const dispatch = useDispatch()
  const [isMyPendingRequestsLoading, setIsMyPendingRequestsLoading] = useState<boolean>(true)
  const [myPendingRequests, setMyPendingRequests] = useState<MyPendingRequests>()

  useEffect(() => {
    dashboardService
      .getMyPendingRequests()
      .then(response => {
        setMyPendingRequests(response)
        setIsMyPendingRequestsLoading(false)
      })
      .catch(err => {
        setIsMyPendingRequestsLoading(false)
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [dispatch])

  return {
    myPendingRequests,
    isMyPendingRequestsLoading,
  }
}
