import { Box, Link, useMediaQuery } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro'
import { useState } from 'react'
import { GridProps } from './types'

import { isMobilePortrait } from '../../../../theme/deviceChecks'
import NoResult from '../../../../shared/UI/NoResult'

function NoRowsOverlay() {
  return <NoResult message="No Results" showImage />
}

function HolidayEntitlementBulgariaGrid({ data, onViewDetail }: GridProps) {
  const [pageSize, setPageSize] = useState<number>(100)
  const mobilePortrait = useMediaQuery(isMobilePortrait())

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Employee Name',
      headerClassName: 'header',
      type: 'string',
      width: 230,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      renderCell: params => (
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            onViewDetail(params.row)
          }}
        >
          {params.row.displayName}
        </Link>
      ),
    },
    {
      field: 'position',
      headerName: 'Role',
      headerClassName: 'header',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'team',
      headerName: 'Team',
      headerClassName: 'header',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'holidayAllowance',
      headerName: 'Holiday Entitlement',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'holidayTaken',
      headerName: 'Holiday Taken',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 180,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'holidayRemaining',
      headerName: 'Holiday Entitlement Remaining',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 250,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'holidayTakenPc',
      headerName: '% Taken',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      flex: 1,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
  ]

  const mobileColumns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Employee Name',
      headerClassName: 'header',
      type: 'string',
      width: 230,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      renderCell: params => (
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            onViewDetail(params.row)
          }}
        >
          {params.row.displayName}
        </Link>
      ),
    },
    {
      field: 'holidayAllowance',
      headerName: 'Holiday Entitlement',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'holidayTaken',
      headerName: 'Holiday Taken',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'holidayRemaining',
      headerName: 'Holiday Entitlement Remaining',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 250,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'holidayTakenPc',
      headerName: '% Taken',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      flex: 1,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
  ]

  return (
    <Box sx={{ width: '100%' }}>
      <DataGridPro
        getRowId={row => row.id}
        rows={data}
        columns={mobilePortrait ? mobileColumns : columns}
        disableSelectionOnClick
        rowsPerPageOptions={[10, 25, 50, 100]}
        pageSize={pageSize}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        rowHeight={50}
        autoHeight
        components={{ NoRowsOverlay }}
        initialState={{
          pinnedColumns: { left: ['displayName'] },
          sorting: {
            sortModel: [{ field: 'submitDateTime', sort: 'asc' }],
          },
        }}
        sx={{
          '&.MuiDataGrid-root': {
            border: 'none !important ',
            overflow: 'none',
            marginLeft: '-0.5rem',
            paddingTop: '40px',
          },
          '.header': {
            backgroundColor: 'rgb(247, 247, 247)',
          },
          '& .MuiDataGrid-virtualScrollerContent': {
            minHeight: '200px!important',
          },
          '& .MuiDataGrid-virtualScroller': {
            maxHeight: '360px',
          },
        }}
        componentsProps={{
          panel: {
            sx: {
              '& .MuiNativeSelect-select option': {
                font: '13px "Poppins", sans-serif',
              },
            },
          },
        }}
      />
    </Box>
  )
}

export default HolidayEntitlementBulgariaGrid
