import { Box, Link, useMediaQuery } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro'
import { useState } from 'react'

import { getLocalDateString } from '../../../../utils/date-utils'
import { GridProps } from './types'

import { isMobilePortrait } from '../../../../theme/deviceChecks'
import TypeLabel from '../../../../shared/UI/TypeLabel'
import StatusLabel from '../../../../shared/UI/StatusLabel'
import NoResult from '../../../../shared/UI/NoResult'

function NoRowsOverlay() {
  return <NoResult message="No Results" showImage />
}

function HolidaysEmployeeRequestBulgariaGrid({ data, onViewDetail }: GridProps) {
  const [pageSize, setPageSize] = useState<number>(100)
  const mobilePortrait = useMediaQuery(isMobilePortrait())

  const getType = (requestType: string) => <TypeLabel label={requestType} type={requestType} />

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Display Name',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      width: 210,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      renderCell: params => (
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            onViewDetail(params.row)
          }}
        >
          {params.row.rowNumber === 1 ? params.value : ''}
        </Link>
      ),
    },
    {
      field: 'jobTitle',
      headerName: 'Role',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      type: 'string',
      width: 150,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      renderCell: cellValues => (cellValues.row.rowNumber === 1 ? cellValues.value : ''),
    },
    {
      field: 'department',
      headerName: 'Department',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      type: 'string',
      width: 200,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      renderCell: cellValues => (cellValues.row.rowNumber === 1 ? cellValues.value : ''),
    },
    {
      field: 'team',
      headerName: 'Team',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      type: 'string',
      width: 200,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      renderCell: cellValues => (cellValues.row.rowNumber === 1 ? cellValues.value : ''),
    },
    {
      field: 'requestType',
      headerName: 'Request Type',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      type: 'string',
      width: 150,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      renderCell: cellValues => (cellValues.row.rowNumber === 1 ? getType(cellValues.value) : ''),
    },
    {
      field: 'requestDates',
      headerName: 'Request Dates',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      type: 'string',
      width: 300,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      renderCell: cellValues => (cellValues.row.rowNumber === 1 ? cellValues.value : ''),
    },
    {
      field: 'requestStatus',
      headerName: 'Request Status',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      type: 'string',
      width: 200,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      renderCell: cellValues =>
        cellValues.row.rowNumber === 1 ? <StatusLabel status={cellValues.value} /> : '',
    },
    {
      field: 'submitted',
      headerName: 'Date Submitted',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      type: 'date',
      width: 150,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      renderCell: cellValues =>
        cellValues.row.rowNumber === 1 ? getLocalDateString(cellValues.value) : '',
    },
    {
      field: 'day',
      headerName: 'Requested Days',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      type: 'string',
      width: 150,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'hours',
      headerName: 'Requested Hours',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      type: 'string',
      headerAlign: 'right',
      width: 150,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
  ]

  const mobileColumns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Display Name',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      width: 210,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      renderCell: params => (
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            onViewDetail(params.row)
          }}
        >
          {params.row.rowNumber === 1 ? params.value : ''}
        </Link>
      ),
    },
    {
      field: 'requestType',
      headerName: 'Request Type',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      type: 'string',
      width: 150,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      renderCell: cellValues => (cellValues.row.rowNumber === 1 ? cellValues.value : ''),
    },
    {
      field: 'requestDates',
      headerName: 'Request Dates',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      type: 'string',
      width: 300,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      renderCell: cellValues => (cellValues.row.rowNumber === 1 ? cellValues.value : ''),
    },
    {
      field: 'requestStatus',
      headerName: 'Request Status',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      type: 'string',
      width: 200,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      renderCell: cellValues => (cellValues.row.rowNumber === 1 ? cellValues.value : ''),
    },
    {
      field: 'submitted',
      headerName: 'Date Submitted',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      type: 'date',
      width: 150,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      renderCell: cellValues => (cellValues.row.rowNumber === 1 ? cellValues.value : ''),
    },
    {
      field: 'day',
      headerName: 'Requested Days',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      type: 'string',
      width: 150,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'hours',
      headerName: 'Requested Hours',
      headerClassName: 'header',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      type: 'string',
      headerAlign: 'right',
      width: 150,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
  ]

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <DataGridPro
          getRowId={row => row.index}
          rows={data}
          columns={mobilePortrait ? mobileColumns : columns}
          disableSelectionOnClick
          rowsPerPageOptions={[10, 25, 50, 100]}
          pagination
          pageSize={pageSize}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowHeight={50}
          autoHeight
          components={{ NoRowsOverlay }}
          initialState={{
            pinnedColumns: { left: ['displayName'] },
          }}
          sx={{
            '&.MuiDataGrid-root': {
              border: 'none !important ',
              marginLeft: '-0.5rem',
              paddingTop: '40px',
            },
            '.MuiDataGrid-row .notlast': {
              borderBottom: '0px solid rgba(224, 224, 224, 1)',
            },
            '.first': {
              justifyContent: 'flex-start',
              paddingLeft: '20px',
            },
            '.header': {
              backgroundColor: 'rgb(247, 247, 247)',
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              minHeight: '200px!important',
            },
            '& .MuiDataGrid-virtualScroller': {
              maxHeight: '360px',
            },
          }}
        />
      </Box>
    </>
  )
}

export default HolidaysEmployeeRequestBulgariaGrid
