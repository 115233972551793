import { toRelativeUrl } from '@okta/okta-auth-js'
import { oktaAuth } from '../App'
import localStorageService from './localStorageService'
import store from '../redux/store'
import { showErrorMessage } from '../redux/reducers/snackbarReducer'
import { BaseResponse } from '../types/base-response'

const redirectNowMessage = 'Okta token has expired, the site will refresh now...'

const oktaRedirectWithDelay = async (delay?: number) => {
  setTimeout(async () => {
    try {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin)
      oktaAuth.setOriginalUri(originalUri)
      await oktaAuth.signInWithRedirect()
    } catch (refreshError) {
      return Promise.reject(refreshError)
    }
  }, delay ?? 3000)
}

interface ValidationError extends BaseResponse {
  response: {
    data: BaseResponse
  }
}

const oktaService = {
  resyncOctaWithRedirect: async (delay?: number) => {
    if (localStorageService.isOktaRedirecting()) {
      return
    }

    const userId = store.getState().appSettings.employeeDetails.employeeId
    if (!userId) {
      localStorageService.setIsOktaRedirecting(false)
      return
    }

    localStorageService.setIsOktaRedirecting(true)
    store.dispatch(showErrorMessage(redirectNowMessage))
    await oktaRedirectWithDelay(delay)
  },
  tokenValidationError: (): ValidationError => ({
    errors: [],
    status: 401,
    response: {
      data: {
        errors: [],
        status: 401,
      },
    },
  }),
}

export default oktaService
