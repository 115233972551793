import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import React from 'react'
import { TimePicker as MuiTimePicker } from '@mui/lab'
import { TextField } from '@mui/material'
import locale from 'date-fns/locale/en-GB'

interface TimePickerProps {
  label?: string
  value: Date | null
  onChange: (selectedTime: Date | null, keyboardInputValue?: string) => void
  error?: boolean
  required?: boolean
  disabled?: boolean
}

function TimePicker({ label, value, onChange, error, required, disabled }: TimePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <MuiTimePicker
        label={label}
        value={value}
        onChange={newValue => {
          onChange(newValue)
        }}
        ampm={false}
        ampmInClock={false}
        renderInput={params => (
          <TextField {...params} fullWidth required={required} error={error} />
        )}
        disabled={disabled}
      />
    </LocalizationProvider>
  )
}

export default TimePicker
