import {
  NotificationLogsQuery,
  NotificationLogsResponse,
} from '../components/BSSAdmin/NotificationLogs/models'
import { BaseResponse } from '../types/base-response'
import { httpServiceV1 } from './httpServiceV1'

const requestsBaseURL = 'NotificationLogs'

const notificationLogsService = {
  getFailedLogs: async (): Promise<NotificationLogsResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/GetAllFailedNotifications`),
  getLogsByQuery: async (q: NotificationLogsQuery): Promise<NotificationLogsResponse> =>
    httpServiceV1.post(`${requestsBaseURL}/GetNotificationsByQuery`, q),
  retryByQuery: async (q: NotificationLogsQuery): Promise<BaseResponse> =>
    httpServiceV1.post(`${requestsBaseURL}/RetryByQuery`, q),
  retryAll: async (): Promise<BaseResponse> => httpServiceV1.get(`${requestsBaseURL}/RetryAll`),
}

export { notificationLogsService }
