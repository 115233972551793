import { Grid, TextField } from '@mui/material'
import styled from 'styled-components'
import Button from '../../../../shared/UI/Button'
import { DetailProps } from './types'

const StyledTextField = styled(TextField)`
  input.MuiInputBase-input {
    -webkit-text-fill-color: #747474;
  }
`

function HolidaysEntitlementRemainingAllowanceDetail({ row, onClose }: DetailProps) {
  if (!row) {
    return null
  }

  return (
    <>
      <Grid container style={{ paddingLeft: '30px' }} mt={4} spacing={4} pr={4}>
        <Grid item xs={12}>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Job Title" value={row.position} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Department" value={row.departmentName} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Team" value={row.team} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField
                  fullWidth
                  label="Holiday Entitlement"
                  value={row.holidayAllowance}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField
                  fullWidth
                  label="Holiday Entitlement Taken"
                  value={row.holidayTaken}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField
                  fullWidth
                  label="Holiday Entitlement Remaining"
                  value={row.holidayRemaining}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField
                  fullWidth
                  label="% Taken"
                  value={row.holidayTakenPercent}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField
                  fullWidth
                  label="WFH Allowance"
                  value={row.workFromHomeAllowance}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField
                  fullWidth
                  label="WFH Allowance Taken"
                  value={row.workFromHomeTaken}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField
                  fullWidth
                  label="WFH Allowance Remaining"
                  value={row.workFromHomeRemaining}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField
                  fullWidth
                  label="% WFH Taken"
                  value={row.workFromHomeTakenPercent}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        xl={12}
        pl={4}
        pr={4}
        style={{ position: 'fixed', bottom: '40px', right: '0px' }}
      >
        <Grid item xs={12} gap={4} display="flex" justifyContent="flex-end">
          <Button label="Close" color="secondary" variant="outlined" onClick={onClose} />
        </Grid>
      </Grid>
    </>
  )
}

export default HolidaysEntitlementRemainingAllowanceDetail
