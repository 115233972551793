import { useState, useEffect, useCallback, useMemo } from 'react'
import { Grid, Stack } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { useAppDispatch } from '../../redux/store'
import { manualRequestsService } from '../../services/myActionsService'
import { AttendanceCalendarItem, AttendanceCalendarResponse } from '../../types/calendar-items'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import { BaseResponse } from '../../types/base-response'
import UserErrorMessage from '../../utils/errorFilter'
import { PlottingCalendars } from '../PlottingCalendarCustom/PlottingCalendars'
import { PlottingCalendarDates, PlottingCalendarRequest } from '../PlottingCalendarCustom/types'
import { buildData } from '../PlottingCalendarCustom/utils'
import { AbsenceOccurrence } from '../../types/absence'
import LegendsFooter from '../LegendsFooter'
import { LegendProps } from '../LegendsFooter/types'
import Paragraph from '../../shared/UI/Paragraph'
import theme from '../../theme/theme'
import { ToggleButtonGroup } from '../../shared/UI/ToggleButton/ToggleButtonGroup'
import { ToggleButton } from '../../shared/UI/ToggleButton'
import { REQUEST_TYPES } from '../MyRequests/MyRequestsLegendsFilter/utils'
import { getPalleteTypeByProp } from '../../theme/palette'
import { anyMultiples, setDays } from './utils'
import MobileContainer from '../../shared/layout/MobileContainer'

interface Props {
  attendanceEvent: AbsenceOccurrence
}

function Absence({ attendanceEvent }: Props) {
  const [legends, setLegends] = useState<LegendProps[]>([])
  const [plottingDateRange, setPlottingDateRange] = useState<{
    start: Date
    end: Date
    length: number
  }>()
  const [plottingFilters, setPlottingFilters] = useState<string[]>([])
  const [plottingDates, setPlottingDates] = useState<PlottingCalendarDates[]>([])
  const [apiData, setApiData] = useState<PlottingCalendarRequest[]>()
  const [changingYear, setChangingYear] = useState<boolean>(true)
  const [attendanceYear, setAttendanceYear] = useState<number>(
    attendanceEvent?.startDate
      ? new Date(attendanceEvent?.startDate).getFullYear()
      : new Date().getFullYear()
  )

  const dispatch = useAppDispatch()

  const getAttendanceData = useCallback(async () => {
    manualRequestsService
      .getCalendarItems(attendanceEvent.employeeResponse.id, attendanceYear)
      .then((result: AttendanceCalendarResponse) => {
        const items = result.items.sort((a, b) => (a.dateFrom > b.dateFrom ? 0 : -1))

        setPlottingDateRange({
          start: new Date(attendanceYear, 0, 1),
          end: new Date(attendanceYear, 11, 31),
          length: 12,
        })

        if (items) {
          const requests: PlottingCalendarRequest[] = items.map((m: AttendanceCalendarItem) => ({
            id: m.id,
            submitDateTime: '',
            dateFrom: m.dateFrom,
            dateTo: m.dateTo || m.dateFrom,
            statusDescription: m.description,
            requestType: m.recordType,
            days: setDays(m),
          }))
          setPlottingFilters(requests.map(m => m.requestType))

          setApiData(requests)
        }
        setChangingYear(false)
      })
      .catch(err => {
        setChangingYear(false)
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [attendanceEvent.employeeResponse.id, attendanceYear, dispatch])

  useEffect(() => {
    if (!attendanceYear) {
      return
    }
    getAttendanceData()
  }, [attendanceYear, getAttendanceData])

  const isCurrentYear = useMemo(() => new Date().getFullYear() === attendanceYear, [attendanceYear])

  useEffect(() => {
    if (!apiData || !plottingFilters) {
      return
    }

    const dates = buildData({
      requests: apiData,
      filters: plottingFilters,
      year: attendanceYear,
    })

    setPlottingDates(dates)

    const attendanceTypes = REQUEST_TYPES.filter(rt => dates.some(d => d.eventType === rt))

    if (anyMultiples(apiData) && attendanceTypes.length) {
      attendanceTypes.unshift('multipleRequests')
    }

    setLegends(attendanceTypes.map(at => getPalleteTypeByProp(at)))
  }, [apiData, attendanceYear, plottingFilters])

  return (
    <MobileContainer>
      <Grid container>
        <Grid item xs={12} pt={0}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Paragraph size="16px" weight="bold" padding="4px 8px">
              {attendanceYear}
            </Paragraph>
            <ToggleButtonGroup
              value={null}
              exclusive
              bgColor={theme.palette.primary.main}
              onChange={(_e, advance) => {
                if (!attendanceYear) {
                  return
                }
                setChangingYear(true)
                setAttendanceYear(prevYear => (advance === 'Dec' ? prevYear - 1 : prevYear + 1))
              }}
            >
              <ToggleButton value="Dec" fixedColor="white">
                <ChevronLeft />
              </ToggleButton>
              <ToggleButton value="Inc" fixedColor="white">
                <ChevronRight />
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>

        <Grid item xs={12} pt={3}>
          <PlottingCalendars
            dateRange={plottingDateRange || { start: new Date(attendanceYear), length: 12 }}
            requestDates={plottingDates}
            showEndDates={false}
            disabledDates={[]}
            changingYear={changingYear}
            isAttendanceCalendar
          />
        </Grid>

        <Grid item xs={12} pt={0}>
          <LegendsFooter
            legendProps={legends}
            onSelected={item => undefined}
            sx={{ marginTop: '8px', marginBottom: '32px' }}
            isAttendanceCalendar
            isCurrentYear={isCurrentYear}
            changingYear={changingYear}
          />
        </Grid>
      </Grid>
    </MobileContainer>
  )
}

export default Absence
