import { Collapse, FormControlLabel, Grid, useMediaQuery } from '@mui/material'
import { DateRange } from '@mui/x-date-pickers-pro'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { addMonths, format } from 'date-fns'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { RequestFieldsModal, requestFieldsDefaultState } from '../../models'
import { setActiveRoute } from '../../redux/reducers/appSettingsReducer'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import { SelectOption } from '../../services/dashboardService'
import { myReportingService } from '../../services/myReportingService'
import AutocompleteList from '../../shared/UI/AutocompleteList'
import Buttons from '../../shared/UI/Button'
import DateRangePicker from '../../shared/UI/DateRangePicker'
import FilterButton from '../../shared/UI/FilterButton'
import { IOSStyleSwitch } from '../../shared/UI/IOSStyleSwitch/IOSStyleSwitch'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import RefreshButton from '../../shared/UI/RefreshButton'
import theme from '../../theme/theme'
import { AttendanceSearchItem } from '../../types/attendance-search'
import { BaseResponse } from '../../types/base-response'
import { getSelectValuesByType, getFilteredDepartmentsDropdownOptions } from '../../utils/app-utils'
import UserErrorMessage from '../../utils/errorFilter'
import SearchGrid from '../SearchGrid'
import { ReportingMonths } from '../../api/absence/tempTypes/generalprops'

export interface PaginationInfo {
  pageSize: number
  pageNumber: number
}

export interface ReportingAbsenceAndLateSearchFilters {
  previousLocation: string
  selectedDepartment: SelectOption | null
  selectedEmployee: SelectOption | null
  dateRange: DateRange<Date>
  employeeList: SelectOption[]
  includeAbsence: boolean
  includeLate: boolean
  absenceReason: SelectOption | null
  lateReason: SelectOption | null
  completeAbsenceType: SelectOption | null
  completeLateStatus: SelectOption | null
  localData: AttendanceSearchItem[] | null
  pagination: PaginationInfo | null
  localDataExpiration: Date | null
}

function Search() {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [fieldsTouched, setFieldsTouched] = useState<RequestFieldsModal>(requestFieldsDefaultState)
  const [completeAbsenceType, setCompleteAbsenceType] = useState<SelectOption | null>({
    displayValue: 'All',
    value: 0,
  })
  const [completeLateStatus, setCompleteLateStatus] = useState<SelectOption | null>({
    displayValue: 'All',
    value: 0,
  })
  const { departments, allEmployees, directReports, currentEntitlementPeriodResponse } =
    useSelector((state: RootStore) => state.appSettings)

  const [selectedDepartment, setSelectedDepartment] = useState<SelectOption | null>({
    displayValue: 'All',
    value: 0,
  })
  const [selectedEmployee, setSelectedEmployee] = useState<SelectOption | null>({
    displayValue: 'All',
    value: 0,
  })

  const startDate = addMonths(new Date(), ReportingMonths.Past)
  const endDate = addMonths(new Date(), ReportingMonths.Future)

  const [employeeList, setEmployeeList] = useState<SelectOption[]>([])
  const [dateRange, setDateRange] = useState<DateRange<Date>>([startDate, endDate])
  const [includeAbsence, setIncludeAbsence] = useState<boolean>(true)
  const [includeLate, setIncludeLate] = useState<boolean>(true)
  const [absenceReason, setAbsenceReason] = useState<SelectOption | null>({
    displayValue: 'All',
    value: 0,
  })
  const [lateReason, setLateReason] = useState<SelectOption | null>({
    displayValue: 'All',
    value: 0,
  })
  const [localData, setLocalData] = useState<AttendanceSearchItem[]>([])
  const [hasLocalData, setHasLocalData] = useState<boolean>(false)
  const [showFilters, setShowFilters] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showGrid, setShowGrid] = useState<boolean>(false)
  const [previousRecordPagination, setPreviousRecordPagination] = useState<PaginationInfo | null>()

  useEffect(() => {
    const previousState = location.state as ReportingAbsenceAndLateSearchFilters
    if (!previousState) {
      return
    }
    setSelectedDepartment(previousState.selectedDepartment)
    setSelectedEmployee(previousState.selectedEmployee)
    setEmployeeList(previousState.employeeList)
    setLateReason(previousState.lateReason)
    setAbsenceReason(previousState.absenceReason)
    setIncludeAbsence(previousState.includeAbsence)
    setIncludeLate(previousState.includeLate)
    setDateRange(previousState.dateRange)
    setCompleteAbsenceType(previousState.completeAbsenceType)
    setCompleteLateStatus(previousState.completeLateStatus)
    if (
      !previousState.localData ||
      previousState.localData.length === 0 ||
      (previousState.localDataExpiration && previousState.localDataExpiration < new Date())
    ) {
      location.state = undefined
      return
    }
    setShowFilters(false)
    setShowGrid(true)
    setLocalData(previousState.localData)
    setPreviousRecordPagination(previousState.pagination)
    location.state = undefined
  }, [location.state])

  const onHandleDepartmentChange = (event: unknown, department: SelectOption | null) => {
    if (!department) {
      return
    }
    setSelectedDepartment(department)
  }

  const onHandleEmployeeChange = (event: any, employee: SelectOption | null) => {
    if (!employee) {
      return
    }
    setSelectedEmployee(employee)
  }

  const onHandleSearch = () => {
    setIsLoading(true)
    setShowGrid(true)

    myReportingService
      .getMyReports(
        includeAbsence,
        includeLate,
        dateRange[0]
          ? format(dateRange[0], 'yyyy-MM-dd hh-mm-ss').substring(0, 10)
          : format(
              new Date(currentEntitlementPeriodResponse!.startDate),
              'yyyy-MM-dd hh-mm-ss'
            ).substring(0, 10),
        dateRange[1]
          ? format(dateRange[1], 'yyyy-MM-dd hh-mm-ss').substring(0, 10)
          : format(
              new Date(currentEntitlementPeriodResponse!.endDate),
              'yyyy-MM-dd hh-mm-ss'
            ).substring(0, 10),
        Number(absenceReason!.value),
        Number(completeAbsenceType!.value),
        Number(lateReason!.value),
        Number(completeLateStatus!.value),
        Number(selectedEmployee!.value),
        Number(selectedDepartment?.value)
      )
      .then(res => {
        setHasLocalData(true)
        setLocalData(res.attendanceSearchItems)
        setShowFilters(false)
        setIsLoading(false)
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  const getAbsences = () => localData

  const onHandleReset = () => {
    setShowGrid(false)
    setHasLocalData(false)
    setLocalData([])
    setIsLoading(false)
    setShowFilters(true)
    setSelectedDepartment({ displayValue: 'All', value: 0 })
    setSelectedEmployee({ displayValue: 'All', value: 0 })
    setDateRange([startDate, endDate])
    setAbsenceReason({ displayValue: 'All', value: 0 })
    setCompleteAbsenceType({ displayValue: 'All', value: 0 })
    setLateReason({ displayValue: 'All', value: 0 })
    setCompleteLateStatus({ displayValue: 'All', value: 0 })
    setIncludeAbsence(true)
    setIncludeLate(true)
  }

  const onHandleFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowFilters(!showFilters)
  }

  const departmentsList = useMemo(
    () => getFilteredDepartmentsDropdownOptions(departments, allEmployees),
    [allEmployees, departments]
  )

  const getAbsenceReasonList = () => {
    const selectAllItem: SelectOption = { displayValue: 'All', value: 0 }
    const list = getSelectValuesByType('AbsenceType')
    return [selectAllItem, ...list]
  }

  const getLateReasonList = () => {
    const selectAllItem: SelectOption = { displayValue: 'All', value: 0 }
    const list = getSelectValuesByType('LateType')
    return [selectAllItem, ...list]
  }

  const getAbsenceStatusList = () => {
    const selectAllItem: SelectOption = { displayValue: 'All', value: 0 }
    const list = getSelectValuesByType('AbsenceStatus')
    return [selectAllItem, ...list]
  }

  const getLateStatusList = () => {
    const selectAllItem: SelectOption = { displayValue: 'All', value: 0 }
    const list = getSelectValuesByType('LateStatus')
    return [selectAllItem, ...list]
  }

  useEffect(() => {
    let selectedEmployees = directReports

    if (selectedDepartment?.value !== 0) {
      selectedEmployees = directReports.filter(
        emp => emp.departmentId === selectedDepartment?.value
      )
    }

    const selectAllItem: SelectOption = { displayValue: 'All', value: 0 }

    let list: SelectOption[] = selectedEmployees.map(item => ({
      displayValue: item.employeeName,
      value: item.employeeId,
    }))

    list = [selectAllItem, ...list]

    setEmployeeList(list)

    dispatch(setActiveRoute('/reporting'))
  }, [selectedDepartment])

  useEffect(() => {
    const selectAllItem: SelectOption = { displayValue: 'All', value: 0 }

    let list: SelectOption[] = directReports.map(item => ({
      displayValue: item.employeeName,
      value: item.employeeId,
    }))

    list = [selectAllItem, ...list]

    setEmployeeList(list)
  }, [directReports])

  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  const getFiltersState = useCallback(
    (): ReportingAbsenceAndLateSearchFilters | null =>
      dateRange.length === 2 && dateRange[0] && dateRange[1]
        ? ({
            previousLocation: location.pathname,
            selectedDepartment,
            selectedEmployee,
            employeeList,
            dateRange: [new Date(dateRange[0]), new Date(dateRange[1])],
            includeAbsence,
            includeLate,
            absenceReason,
            lateReason,
            completeAbsenceType,
            completeLateStatus,
            localData,
          } as ReportingAbsenceAndLateSearchFilters)
        : null,
    [
      absenceReason,
      completeAbsenceType,
      completeLateStatus,
      dateRange,
      employeeList,
      location.pathname,
      includeAbsence,
      includeLate,
      lateReason,
      localData,
      selectedDepartment,
      selectedEmployee,
    ]
  )

  return (
    <Grid container spacing={4} component="form" noValidate>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <FilterButton
          title="Change Filters"
          onClick={onHandleFilters}
          dataTestId="Reporting-ChangeFilters"
        />
        <RefreshButton onClick={onHandleReset} dataTestId="Reporting-RestartBtn" />
      </Grid>
      <Collapse orientation="vertical" in={showFilters}>
        <Grid container display="flex" spacing={4} pl={4}>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ flex: 1 }}>
                <AutocompleteList
                  id="department"
                  label="Department"
                  data={departmentsList}
                  textField="displayValue"
                  value={selectedDepartment ? selectedDepartment! : null}
                  onChange={onHandleDepartmentChange}
                  dataTestId="Reporting-DepartmentDDL"
                />
              </Grid>
              <Grid item xs={12} mt={1}>
                <AutocompleteList
                  id="employee"
                  label="Employee"
                  data={employeeList}
                  textField="displayValue"
                  value={selectedEmployee ? selectedEmployee! : null}
                  onChange={onHandleEmployeeChange}
                  dataTestId="Reporting-EmployeeDDL"
                />
              </Grid>
              <Grid item xs={12} mt={1}>
                <DateRangePicker
                  value={dateRange}
                  onChange={newValue => {
                    setFieldsTouched({ ...fieldsTouched, date: true })
                    setDateRange(newValue)
                  }}
                  arrange={mobile ? 'Stack' : 'Horizontal'}
                  dataTestId="Reporting"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} display="flex" justifyContent="flex-start">
                    <FormControlLabel
                      style={{
                        justifyContent: 'flex-end',
                        gap: '20px',
                        width: '100%',
                        margin: '0',
                      }}
                      control={<IOSStyleSwitch checked={includeAbsence} />}
                      label="Include Absences"
                      labelPlacement="start"
                      onClick={() => {
                        setIncludeAbsence(!includeAbsence)
                      }}
                      sx={{ height: '50px' }}
                      data-testid="Reporting-AbsencesBtn"
                    />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <AutocompleteList
                      id="absenceReason"
                      label="Absence Reason"
                      data={getAbsenceReasonList()}
                      textField="displayValue"
                      value={absenceReason || null}
                      onChange={(e, newValue) => {
                        setAbsenceReason(newValue)
                      }}
                      disabled={!includeAbsence}
                      dataTestId="Reporting-AbsencesReason"
                    />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <AutocompleteList
                      id="absenceStatus"
                      label="Absence Status"
                      data={getAbsenceStatusList()}
                      textField="displayValue"
                      value={completeAbsenceType || null}
                      onChange={(e, newValue) => {
                        setCompleteAbsenceType(newValue)
                      }}
                      disabled={!includeAbsence}
                      dataTestId="Reporting-AbsencesStatus"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} display="flex" justifyContent="flex-start">
                    <FormControlLabel
                      style={{
                        justifyContent: 'flex-end',
                        width: '100%',
                        margin: '0',
                        gap: '20px',
                      }}
                      control={<IOSStyleSwitch checked={includeLate} />}
                      label="Include Lates"
                      labelPlacement="start"
                      onClick={() => {
                        setIncludeLate(!includeLate)
                      }}
                      sx={{ height: '50px' }}
                      data-testid="Reporting-LatesBtn"
                    />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <AutocompleteList
                      id="lateReason"
                      label="Late Reason"
                      data={getLateReasonList()}
                      textField="displayValue"
                      value={lateReason || null}
                      onChange={(e, newValue) => {
                        setLateReason(newValue)
                      }}
                      disabled={!includeLate}
                      dataTestId="Reporting-LateReason"
                    />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <AutocompleteList
                      id="lateStatus"
                      label="Late Status"
                      data={getLateStatusList()}
                      textField="displayValue"
                      value={completeLateStatus || null}
                      onChange={(e, newValue) => {
                        setCompleteLateStatus(newValue)
                      }}
                      disabled={!includeLate}
                      dataTestId="Reporting-LateStatus"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Buttons label="Search" onClick={onHandleSearch} dataTestId="Reporting-SearchBtn" />
          </Grid>
        </Grid>
      </Collapse>

      <Grid item xs={12}>
        <LoadingIndicator show={isLoading} />
        {!isLoading && showGrid && (
          <SearchGrid
            rows={getAbsences()}
            filtersState={getFiltersState()}
            pagination={previousRecordPagination}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default Search
