import React from 'react'
import { Link as MUILink } from '@mui/material'
import { StyledLink } from './components'

interface LinkProps {
  linkText?: string
  underline?: 'none' | 'hover' | 'always'
  href?: string
  onClick?: () => void
}
function Link({ linkText, underline = 'none', href = '#', onClick }: LinkProps) {
  return (
    <MUILink underline={underline} href={href} onClick={onClick}>
      <StyledLink>{linkText}</StyledLink>
    </MUILink>
  )
}

export default Link
