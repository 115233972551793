import styled from 'styled-components'
import { Typography } from '@mui/material'

export const EmployeeName = styled(Typography)``
export const JobRolw = styled(Typography)``
export { HoursContainer } from './HoursContainer'
export { TabItem } from '../components/TabItem'
export { TabItems } from './TabItems'
export { WhoIsAwayCard } from './WhosAwayCard'
export { CardHeaderContainer } from './CardHeaderContainer'
export { CustomFooterStatus } from './CustomFooterStatus'
export { WhosAwayContainer } from './WhosAwayContainer'
