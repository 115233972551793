import { Box, Grid, IconButton, Stack, Tooltip } from '@mui/material'
import {
  AccessTime as AccessTimeIcon,
  LocationOn as LocationOnIcon,
  DeleteOutline as DeleteOutlineIcon,
  LocationOnOutlined as LocationOnOutlinedIcon,
} from '@mui/icons-material'
import { format, isAfter, isToday } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { BookingContainerProps } from './types'
import Paragraph from '../../../shared/UI/Paragraph'
import { WEEKDAYS_SHORT } from '../../../utils/constants'
import { formatDateWithTimeZone } from '../../../utils/date-utils'
import { BookingAction } from '../Shared/enums'
import { setBookingFloorplanViewingDate } from '../../../redux/reducers/deskBookingReducer'
import { RootStore } from '../../../redux/store'
import { textIconStyle } from './styles'
import { dateToNumber } from '../utils/utils'
import theme from '../../../theme/theme'
import { setBookingWizardStepState } from '../../../redux/reducers/deskBookingWizardReducer'
import { BookingWizardSteps } from '../BlockBooking/enums'

const COLOR_TODAY = '#2CC5A0'
const COLOR_FONT_BEFORE_TODAY = '#E0E0E0'
const COLOR_FONT_AFTER_TODAY = '#2c2965'
const COLOR_TODAY_BG = '#FFFFFF'
const COLOR_NOT_TODAY_BG = '#FCFCFC'
const CONTAINER_BORDER_TODAY = '2px solid #2CC5A0'
const CONTAINER_BORDER_NOT_TODAY = '1px solid #E0E0E0'

const dayFontColor = (date: Date) => {
  if (isAfter(formatDateWithTimeZone(new Date()), formatDateWithTimeZone(date))) {
    return COLOR_FONT_BEFORE_TODAY
  }
  if (isAfter(formatDateWithTimeZone(date), formatDateWithTimeZone(new Date()))) {
    return COLOR_FONT_AFTER_TODAY
  }
}

const bookingWindow = (from: Date, to: Date) => {
  const fullDateFrom = new Date(format(from, 'yyyy-MM-dd HH:mm:ss'))
  const fullDateTo = new Date(format(to, 'yyyy-MM-dd HH:mm:ss'))
  if (fullDateTo.getHours() <= 12) {
    return 'Half day, First half'
  }
  if (fullDateFrom.getHours() >= 12) {
    return 'Half day, Second half'
  }
  return 'Full day'
}

export function BookingContainer({
  bookingID,
  date,
  dateFrom,
  dateTo,
  zone,
  invalidBooking,
  featureLabel,
  components,
  location,
  isCheckedIn,
  bookedFor = '',
  onCancel,
  onShowMe,
  ref
}: BookingContainerProps) {
  const [checkedIn, setCheckedIn] = useState<boolean>(isCheckedIn)
  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)

  const dispatch = useDispatch()

  const handleEvent = (action: BookingAction) => {
    switch (action) {
      case BookingAction.SHOW_ME:
        onShowMe()
        dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_0_INACTIVE))
        return dispatch(setBookingFloorplanViewingDate(date)) // maybe?
      case BookingAction.CHECK_IN:
        return setCheckedIn(true)
      case BookingAction.CANCEL:
        return onCancel?.({
          bookingID,
          deskName: featureLabel,
          location,
          byManager: false,
          floorPlanName: location,
          date: dateFrom,
          onCallBack: success => undefined,
        })
      default:
    }
  }

  const compareDayInt = dateToNumber(date)
  const todayInt = dateToNumber(new Date())
  const canCheckInOut = compareDayInt === todayInt

  const selectButtonLinks = () => {
    const links = []
    links.push({
      label: 'Show me',
      bookingID,
      action: BookingAction.SHOW_ME,
      icon: <LocationOnOutlinedIcon />,
    })
    links.push({
      label: 'Cancel',
      bookingID,
      action: BookingAction.CANCEL,
      icon: <DeleteOutlineIcon />,
    })
    return links
  }

  return (
    <Box
      ref={ref}
      id={`booking_${bookingID}`}
      border={isToday(date) ? CONTAINER_BORDER_TODAY : CONTAINER_BORDER_NOT_TODAY}
      bgcolor={isToday(date) ? COLOR_TODAY_BG : COLOR_NOT_TODAY_BG}
      borderRadius={4}
      mb={2}
      height="80px"
      display="flex"
      width="98%"
    >
      <Box display="flex" flexDirection="row" alignItems="center" width="100%">
        {/* Date Detail */}
        {!isToday(date) && (
          <Box
            display="flex"
            flexDirection="column"
            borderRight="solid 1px #E0E0E0"
            ml={1}
            minWidth="80px"
            justifyContent="center"
            height="54px"
          >
            <Paragraph
              size="16px"
              weight="bold"
              color={dayFontColor(date)}
              style={{ textAlign: 'center', lineHeight: 1 }}
            >
              {WEEKDAYS_SHORT[date.getDay()]}
            </Paragraph>
            <Paragraph
              size="35px"
              weight="bold"
              color={dayFontColor(date)}
              style={{ textAlign: 'center', lineHeight: 1.1 }}
            >
              {date.getDate()}
            </Paragraph>
          </Box>
        )}
        {isToday(date) && (
          <Box
            display="flex"
            flexDirection="column"
            borderRight="solid 1px #E0E0E0"
            ml={1}
            minWidth="80px"
            justifyContent="center"
            height="54px"
          >
            <Paragraph
              size="16px"
              weight="bold"
              color={COLOR_TODAY}
              style={{ textAlign: 'center' }}
            >
              Today
            </Paragraph>
          </Box>
        )}
        {/* Date Detail END */}
        {/* Booking Detail */}
        <Box display="flex" flexDirection="column" ml={1} flexGrow={1} overflow="hidden">
          <Stack direction="row" display="flex" alignItems="center">
            <AccessTimeIcon style={textIconStyle} />
            <Paragraph
              size="13px"
              color="#747474"
              style={{
                marginLeft: '8px',
              }}
            >
              {`${bookingWindow(dateFrom, dateTo)}`}
            </Paragraph>
          </Stack>
          <Stack direction="row" display="flex" alignItems="center">
            <LocationOnIcon style={textIconStyle} />
            <Paragraph
              color="#747474"
              style={{
                marginLeft: '8px',
                textWrap: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {`${location}, ${featureLabel}, ${zone}`}
            </Paragraph>
          </Stack>
        </Box>
        {/* Booking Detail END */}
        {/* Actions */}
        <Box display="flex" flexDirection="row" width="80px" mr={1} justifyContent="flex-end">
          {!invalidBooking && (
            <IconButton
              onClick={() => {
                handleEvent(BookingAction.SHOW_ME)
              }}
            >
              <Tooltip title="Show me" placement="top">
                <LocationOnOutlinedIcon sx={{ color: theme.palette.primary.main }} />
              </Tooltip>
            </IconButton>
          )}
          <IconButton
            onClick={() => {
              handleEvent(BookingAction.CANCEL)
            }}
          >
            <Tooltip title="Cancel desk" placement="top">
              <DeleteOutlineIcon sx={{ color: theme.palette.primary.main }} />
            </Tooltip>
          </IconButton>
        </Box>
        {/* Actions END */}
      </Box>
    </Box>
  )
}
