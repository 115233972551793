import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { Box, Grid } from '@mui/material'
import { format } from 'date-fns'
import {
  BookingBookingPayload,
  BookingEmployeeBooking,
  BookingFeature,
  BookingSearchPayload,
} from '../../../services/booking/types'
import { BookingAvailabilityColors } from '../consts'
import { BookingNewBookingRange, PopoverProps } from '../Popover/types'
import { RootStore } from '../../../redux/store'
import { showErrorMessage, showInfoMessage } from '../../../redux/reducers/snackbarReducer'
import { FloorplanView } from './components'
import DeskPopover from '../Popover/Popover'
import DeskRectangle from '../Desks/DeskRectangle'
import { BookingZoom } from './BookingZoom'
import {
  setDeskBookingLoading,
  setDeskBookingShowMeBookingID,
} from '../../../redux/reducers/deskBookingReducer'
import BookingErrorMessage from '../utils/BookingErrorMessage'
import { formatDatTimeWithTimeZone } from '../../../utils/date-utils'
import {
  BookingFloorplanProps,
  BookingCancellationProps,
  BookingAdditionalInfoProps,
  BookingDynamicSVGCustomProps,
  BookingWeekdays,
} from './types'
import { BookingFeatureTypes, BookingStatus } from './enums'
import { dateToNumber, getJson } from '../utils/utils'
import {
  getColor,
  getDatesFromBookings,
  getPortColor,
  handleSingleBooking,
  initialPopoverProps,
  sortFeatures,
} from './bookingFloorplanLogic'
import { setBookingWizardSelectedFeatureState } from '../../../redux/reducers/deskBookingWizardReducer'
import { BookingWizardSteps } from '../BlockBooking/enums'
import FullComponentLoadingIcon from '../../../shared/UI/LoadingIndicator/FullComponentLoadingIcon'
import { getDeskNameWithRow, handleServerError } from '../bookingLogic'
import {
  setDeskBookingSearchResults,
} from '../../../redux/reducers/deskBookingSearchReducer'
import { setSubmissionsInProgress } from '../../../redux/reducers/deskBookingStatusesReducer'
import { ErrorList } from '../../../utils/errorList'
import { bookingService } from '../../../services/booking/bookingService'
import { BaseResponse } from '../../../types/base-response'

const defaultViewBox = { width: 1000, height: 550 }

export default function BookingFloorplanView({ onCancel }: BookingFloorplanProps) {
  const [deskPoppoverProps, setDeskPoppoverProps] = useState<PopoverProps>(initialPopoverProps())
  const [zoom, setZoom] = useState<number>(900)
  const [viewboxX, setViewboxX] = useState<number>(0)
  const [viewboxY, setViewboxY] = useState<number>(0)
  const [mouseDown, setMouseDown] = useState<{
    down: boolean
    at: number
    pageX: number
    pageY: number
  }>({ down: false, at: Date.now(), pageX: 0, pageY: 0 })
  const [loading, setLoading] = useState<boolean>(true)
  const [activeZYCoords, setActiveZYCoords] = useState<{
    x: number
    y: number
    w: number
    h: number
  } | null>()

  const {
    locations,
    floorplans,
    zones,
    showMeBookingID,
    floorplanViewingDate,
    featuresForFloorPlan,
    focussedZoneID,
    dashboardResults,
    bookingIsLoading,
    bookingSliderPosition,
    floorplanManuallySelected,
  } = useSelector((state: RootStore) => state.deskBooking)
  const { searchResults, searchParams } = useSelector((state: RootStore) => state.deskBookingSearch)
  const { submissionsInProgress } = useSelector((state: RootStore) => state.deskBookingStatus)
  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )
  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)
  const userInfo = useSelector((state: RootStore) => state.userState.loggedInUser)
  const {
    existingBookings,
    weekdaysSelected,
    currentStep: wizardCurrentStep,
    selectedDates,
  } = useSelector((state: RootStore) => state.deskBookingWizard)

  const dispatch = useDispatch()

  const repairTimeFrom = (from: string) => (from === '00:00:01' ? '00:00:00' : from)
  const repairTimeTo = (to: string) => (to === '23:00:00' ? '23:59:59' : to)

  const establishCurrentBookings = (featureId: number) => {
    if (wizardCurrentStep === BookingWizardSteps.STEP_0_INACTIVE) {
      return searchResults?.filter(f =>
        f.featureId === featureId &&
        searchParams && (repairTimeFrom(searchParams.from) === repairTimeFrom(f.fromTime) ||
          repairTimeTo(searchParams.to) === repairTimeTo(f.toTime))
        )
    }
    if (wizardCurrentStep === BookingWizardSteps.STEP_4_ALTERNATIVES) {
      return existingBookings.filter(
        f =>
          f.featureId === featureId &&
          dateToNumber(f.fromDate) === dateToNumber(floorplanViewingDate)
      )
    }
    return existingBookings.filter(f => f.featureId === featureId)
  }

  const isDifferentDeskFeatureBookingForEmployeeOnThisDate = (
    selectedFeatureId: number,
    thisFeature: BookingEmployeeBooking,
    thisEmployeeId: number
  ) =>
    searchParams &&
    thisFeature.feature.id !== selectedFeatureId &&
    thisFeature.employeeId === thisEmployeeId && // thisFeature.zone?.id &&
    `${thisFeature.fromDate}` === `${format(searchParams!.date, `yyyy-MM-dd`)}`

  const findExistingBookingsForEmpployeeWithinTimeframe = (
    bookingTimeFrom: string,
    bookingTimeTo: string,
    selectedFeatureId: number,
    thisEmployeeId: number
  ) =>
    dashboardResults?.results.filter(
      f =>
        isDifferentDeskFeatureBookingForEmployeeOnThisDate(selectedFeatureId, f, thisEmployeeId) &&
        (repairTimeFrom(bookingTimeFrom) === repairTimeFrom(f.fromTime) ||
          repairTimeTo(bookingTimeTo) === repairTimeTo(f.toTime))
    ).length

  const findIfBookingsExistForEmployee = (featureId: number, employeeId: number) =>
    (searchParams &&
      findExistingBookingsForEmpployeeWithinTimeframe(
        searchParams.from,
        searchParams.to,
        featureId,
        employeeId
      )) ||
    false

  const featureBookingWithinTimeframe = (featureId: number, singleBooking: false | BookingBookingPayload | undefined) => {

    if (searchParams && singleBooking &&
      dashboardResults?.results.filter(f =>
        f.feature.id === featureId &&
        (repairTimeFrom(searchParams.from) === repairTimeFrom(f.fromTime) ||
          repairTimeTo(searchParams.to) === repairTimeTo(f.toTime))
      ).length
    ) {
      return [singleBooking]
    }

    if (searchParams && singleBooking && !(
      repairTimeFrom(searchParams.from) === repairTimeFrom(singleBooking.fromTime) ||
      repairTimeTo(searchParams.to) === repairTimeTo(singleBooking.toTime)
    )) {
      return []
    }

    return singleBooking ? [singleBooking] : establishCurrentBookings(featureId)

  }

  const resetPopoverProps = () => {
    setDeskPoppoverProps(current => ({ ...current, open: false }))
  }

  const applyPoppoverData = (
    e: SVGElement,
    floorplan: string,
    featureDetail: BookingFeature,
    isOwnBooking: boolean,
    isBlockBooking: boolean,
    isManager: boolean,
    availabilityColor: BookingAvailabilityColors
  ) => {
    if (!searchResults || !searchParams) {
      return
    }

    const featureId = featureDetail.id
    const featureIdIsAvailable = !searchResults.some(f => f.featureId === featureId)

    if (
      !isBlockBooking &&
      featureIdIsAvailable &&
      findIfBookingsExistForEmployee(featureId, employeeDetails.employeeId) &&
      !isManager
    ) {
      const alertObj = ErrorList.find(err => err.name === 'DeskAlreadyBookedForTheDay')
      dispatch(showInfoMessage(alertObj!.message))
      return
    }

    setDeskPoppoverProps({
      svgElement: e,
      open: true,
      floorplan,
      bookings: establishCurrentBookings(featureId),
      dateTimeFrom: formatDatTimeWithTimeZone(`${searchParams.date} ${searchParams.from}`),
      dateTimeTo: formatDatTimeWithTimeZone(`${searchParams.date} ${searchParams.to}`),
      featureDetail,
      isOwnBooking,
      isBlockBooking,
      isManager,
      availabilityColor,
    })
  }

  useEffect(() => {
    if (!showMeBookingID || showMeBookingID === undefined) {

      return
    }

    setTimeout(() => dispatch(setDeskBookingShowMeBookingID(undefined)), 2000)
  }, [dispatch, showMeBookingID && searchResults[0] && !bookingIsLoading])

  useEffect(() => {
    if (!featuresForFloorPlan || !searchParams) {
      return
    }

    setTimeout(() => {
      const heighestSVG = featuresForFloorPlan.sort((a, b) => {
        if (a.y < b.y) {
          return -1
        }
        if (a.y > b.y) {
          return 1
        }
        return 0
      })
      setViewboxY(heighestSVG[0].y + 200)

    }, 300)
  }, [dispatch, featuresForFloorPlan && searchResults[0]])

  useEffect(() => {
    resetPopoverProps()
  }, [])

  const loadingCheckOnce = useCallback(() => {
    setLoading(true)

    setTimeout(() => {
      if (searchResults !== undefined && !bookingIsLoading && dashboardResults?.results) {
        setLoading(false)
      }
    }, 300)
  }, [bookingIsLoading, searchResults, dashboardResults?.results])

  useEffect(() => {
    resetPopoverProps()

    loadingCheckOnce()
  }, [searchResults.length, dashboardResults?.results, bookingIsLoading])

  useEffect(() => {
    if (zones.length === 0 || !focussedZoneID) {
      return
    }

    setTimeout(() => {
      const zone = zones.find(f => f.id === focussedZoneID)

      if (!zone?.additionalInfo) {
        return
      }

      try {
        const json = getJson(zone?.additionalInfo)
        setViewboxX(json.svg[0].x)
        setViewboxY(json.svg[0].y)
      } catch (err) {
        /* empty - Ignore errors */
      }
    }, 13)
  }, [zones, focussedZoneID])

  const handleWheel = (e: React.WheelEvent<SVGSVGElement>) => {
    if (e.shiftKey) {
      if (e.deltaY > 0) {
        setViewboxX(viewboxX + e.screenX / 100)
      } else {
        setViewboxX(viewboxX - e.screenX / 100)
      }
      return
    }
    if (e.altKey) {
      if (e.deltaY > 0) {
        setViewboxY(viewboxY + e.screenX / 100)
      } else {
        setViewboxY(viewboxY - e.screenX / 100)
      }
      return
    }
    // deltaY is the amount of pixels that is moved in a vertical direction.
    // If you push the wheel forward, it's a negative number (-100) otherwise its a positive number (100).
    if (zoom + e.deltaY < -40) {
      // We have reached the largest zoom threshold allowed, so don't zoom in any more.
      return
    }
    setZoom(zoom + e.deltaY)
  }

  /*
      onMouseEnter={e => {
        if (!isZone || !feature.x || !feature.y || !feature.width || !feature.height) {
          return
        }
        setActiveZYCoords({ x: feature.x, y: feature.y, w: feature.width, h: feature.height })
      }}
  */

  const renderRect = (feature: Partial<BookingFeature>, isZone: boolean) => (
    <g id={String(feature?.id || 0)}>
      <rect
        id={`rect_${feature?.id}`}
        x={feature.x}
        y={feature.y}
        width={feature.width}
        height={feature.height}
        rx={feature.borderRadius}
        fill={getColor(feature as BookingFeature)}
      />
    </g>
  )

  const renderText = (feature: BookingFeature) => {
    let parsedJson: BookingAdditionalInfoProps | null = null
    try {
      parsedJson = getJson(feature.additionalInfo)
      if (!parsedJson) {
        return null
      }
    } catch (e) {
      return null
    }
    return (
      <text
        id={`text_${feature?.id}`}
        x={feature.x}
        y={feature.y}
        fontFamily={parsedJson?.fontStyle?.fontFamily}
        fontSize={parsedJson?.fontStyle?.fontSize}
        fill={feature.fill}
        style={{ userSelect: 'none' }}
      >
        {feature.label}
      </text>
    )
  }

  const renderSVGLine = (lineData: BookingDynamicSVGCustomProps) => (
    <line
      x1={lineData.x1}
      y1={lineData.y1}
      x2={lineData.x2}
      y2={lineData.y2}
      style={{ stroke: lineData.style?.stroke, strokeWidth: lineData.style?.strokeWidth }}
      strokeDasharray={lineData.strokeDasharray}
    />
  )

  const renderSVGCustom = (feature: BookingFeature) => {
    try {
      const json = getJson(feature.additionalInfo)
      return json.svg.map((m: BookingDynamicSVGCustomProps) => {
        switch (m.type.toLowerCase()) {
          case 'line':
            return renderSVGLine(m)
          default:
            return null
        }
      })
    } catch (ex) {
      return null
    }
  }

  const renderSVGType = (
    feature: string | BookingFeature,
    isZone: boolean
  ): (JSX.Element | null)[] => {
    switch ((feature as BookingFeature).typeId) {
      case BookingFeatureTypes.SVG_Rectangle:
        return [renderRect(feature as BookingFeature, isZone)]
      case BookingFeatureTypes.SVG_Text:
        return [renderText(feature as BookingFeature)]
      case BookingFeatureTypes.SVG_Generic:
        return [renderSVGCustom(feature as BookingFeature)]
      default:
        return [null]
    }
  }

  const datesFromBookings = () => {
    let localBookings: BookingBookingPayload[]
    let localWeekdays: BookingWeekdays[] | undefined
    if (wizardCurrentStep === BookingWizardSteps.STEP_0_INACTIVE) {
      localBookings = searchResults || []
    } else {
      localBookings = existingBookings || []
      localWeekdays = weekdaysSelected
    }
    return getDatesFromBookings(localBookings, localWeekdays)
  }

  if (!locations || !floorplans || !zones || !searchParams) {
    return null
  }

  const isPrivilegedUser =
    userPermissions.includes('BookingManager') || userPermissions.includes('IsSuperUser')

  const isBlockBooking = wizardCurrentStep !== BookingWizardSteps.STEP_0_INACTIVE
  const isFloorPlanView = bookingSliderPosition.buttonClick || floorplanManuallySelected
  const bookingZoomTopSingleFloorPlan = isFloorPlanView ? 5 : -20
  const bookingZoomTop = isBlockBooking ? -69 : bookingZoomTopSingleFloorPlan

  const controller = new AbortController()

  const handleSearchError = (err: string) => {
    dispatch(showErrorMessage(<BookingErrorMessage name={err} />))
  }

  const getByQuerySearchResults = (floorplanId: number, date: Date, selectedRange: BookingNewBookingRange) => {
    const selectedDate = format(date, 'yyyy-MM-dd')

    return bookingService
      .search(controller, {
        floorplanId,
        date: selectedDate,
        ...(deskPoppoverProps.dateTimeTo && {
          selectedDate,
        }),
        from: selectedRange.from,
        to: selectedRange.to,
      })
      .then(result => result)
      .catch(err => {
        const response: BaseResponse = handleServerError(err.response.data)
        if (response.status === 500) {
          dispatch(showErrorMessage(`Search Floorplan ${floorplanId} bookings failure`))
        }
        response.errors.forEach(error => {
          handleSearchError(error.name)
        })
  
        dispatch(setDeskBookingLoading(false))
      })
  }

  const isAlreadyBooked = (result: BookingSearchPayload, featureId: number) => result.bookings
    .filter((x: BookingBookingPayload) => x.featureId === featureId && x.statusId === BookingStatus.ACCEPTED).length > 0

  return (
    <FloorplanView container id="floorplan">
      <FullComponentLoadingIcon
        loading={loading}
        noData={!searchResults || !floorplans}
        bgColor="transparent"
      >
        <Grid item xs={12}>
          <BookingZoom
            top={bookingZoomTop}
            factor={250}
            onZoom={factor => {
              setZoom(zoom - factor)
            }}
          />
          <Box
            width="auto"
            height="100%"
            style={{ opacity: loading ? 0.25 : 1, cursor: mouseDown.down ? 'grab' : 'move' }}
          >
            {Boolean(featuresForFloorPlan?.length) && (
              <>
                <DeskPopover
                  svgElement={deskPoppoverProps.svgElement}
                  open={deskPoppoverProps.open}
                  featureDetail={deskPoppoverProps.featureDetail}
                  bookings={deskPoppoverProps.bookings}
                  isOwnBooking={deskPoppoverProps.isOwnBooking}
                  isBlockBooking={deskPoppoverProps.isBlockBooking}
                  isManager={deskPoppoverProps.isManager}
                  floorplan={deskPoppoverProps.floorplan}
                  dateTimeFrom={deskPoppoverProps.dateTimeFrom}
                  dateTimeTo={deskPoppoverProps.dateTimeTo}
                  availabilityColor={deskPoppoverProps.availabilityColor}
                  onClose={resetPopoverProps}
                  onSubmit={async (feature, deskName, { selectedRange, date }) => {
                    dispatch(setSubmissionsInProgress([...submissionsInProgress, feature.id]))
                    resetPopoverProps()
                    if (!searchResults || !userInfo?.name) {
                      return
                    }

                    const searchResultsResponse = await getByQuerySearchResults(feature.floorPlanId, date, selectedRange)

                    if (searchResultsResponse && isAlreadyBooked(searchResultsResponse, feature.id)) {
                      const alertObj = ErrorList.find(err => err.name === 'OtherAlreadyHasDeskBookedForTheDay')
                      dispatch(showErrorMessage(alertObj!.message))
                      dispatch(
                        setDeskBookingSearchResults([
                          ...searchResults.filter(f => f.statusId === BookingStatus.ACCEPTED),
                          ...searchResultsResponse.bookings.filter(f => f.statusId === BookingStatus.ACCEPTED),
                        ])
                      )
                      return
                    }
                    handleSingleBooking(
                      feature,
                      getDeskNameWithRow(feature),
                      { selectedRange, date },
                      locations,
                      floorplans,
                      employeeDetails.employeeId,
                      searchParams,
                      userInfo?.name,
                      floorplanViewingDate,
                      dispatch,
                      err => {
                        dispatch(showErrorMessage(<BookingErrorMessage name={err} />))
                      },
                      submissionsInProgress
                    )
                  }}
                  onCancel={({
                    bookingID,
                    deskName,
                    floorPlanName,
                    location,
                    onCallBack,
                    byManager,
                  }: BookingCancellationProps) => {
                    if (!searchResults) {
                      return
                    }
                    onCancel?.({
                      bookingID,
                      deskName,
                      floorPlanName,
                      location,
                      byManager,
                      date: floorplanViewingDate,
                      onCallBack: success => {
                        dispatch(
                          setDeskBookingSearchResults(searchResults.filter(f => f.id !== bookingID))
                        )
                        resetPopoverProps()
                        onCallBack(success)
                      },
                    })
                  }}
                />
                <svg
                  width="100%"
                  viewBox={`${viewboxX} ${viewboxY} ${defaultViewBox.width + zoom} ${
                    defaultViewBox.height + zoom
                  }`}
                  onMouseDown={e => {
                    setMouseDown({
                      down: true,
                      at: Date.now(),
                      pageX: viewboxX + e.pageX,
                      pageY: viewboxY + e.pageY,
                    })
                    if (
                      isBlockBooking &&
                      wizardCurrentStep !== BookingWizardSteps.STEP_4_ALTERNATIVES
                    ) {
                      return dispatch(setBookingWizardSelectedFeatureState(undefined))
                    }
                  }}
                  onMouseUp={e => setMouseDown(current => ({ ...current, down: false }))}
                  onMouseMove={e => {
                    if (mouseDown.down) {
                      setViewboxX(mouseDown.pageX - e.pageX)
                      setViewboxY(mouseDown.pageY - e.pageY)
                    }
                  }}
                  onWheel={handleWheel}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {searchResults &&
                    searchParams &&
                    zones
                      .filter(f => f.floorPlanId === searchParams.floorplanId)
                      .map(m => {
                        let json
                        try {
                          json = getJson(m.additionalInfo)
                        } catch (err) {
                          /* empty */
                        }
                        if (json && json.svg && json.svg.length > 0) {
                          return (json.svg as Partial<BookingFeature>[]).map(ai =>
                            renderRect(
                              {
                                id: m.id,
                                x: ai.x || 0,
                                y: ai.y || 0,
                                width: ai.width || 0,
                                height: ai.height || 0,
                                borderRadius: ai.borderRadius || 0,
                                fill: ai.fill || '0',
                              },
                              true
                            )
                          )
                        }
                        return renderSVGType(m.additionalInfo, true)
                      })}
                  {featuresForFloorPlan &&
                    sortFeatures(featuresForFloorPlan).map(feature => {
                      const singleBooking =
                        wizardCurrentStep === BookingWizardSteps.STEP_0_INACTIVE &&
                        searchResults?.find(f => f.featureId === feature.id)
                      const from = new Date(
                        `${format(floorplanViewingDate, 'yyyy-MM-dd')} 09:00:00`
                      )
                      const to = new Date(`${format(floorplanViewingDate, 'yyyy-MM-dd')} 17:30:00`)
                      const isInRenderCoords = () => {
                        if (
                          !activeZYCoords ||
                          !feature.x ||
                          !feature.y ||
                          !feature.width ||
                          !feature.height
                        ) {
                          return false
                        }
                        return (
                          feature.x > activeZYCoords?.x &&
                          feature.y > activeZYCoords.y &&
                          feature.x < Number(activeZYCoords?.x) + Number(activeZYCoords?.w) &&
                          feature.y < Number(activeZYCoords?.y) + Number(activeZYCoords?.h)
                        )
                      }

                      return (
                        <>
                          {feature.typeId === 1 && (
                            <DeskRectangle
                              featureDetail={feature}
                              isActive={focussedZoneID === feature.zone.id || isInRenderCoords()}
                              requestedSlot={{
                                bookingRange: [from, to],
                              }}
                              availabilityColor={
                                (feature.zone.id !== searchParams.zoneId &&
                                  feature.zone.id !== focussedZoneID) ||
                                feature.zone.id !== focussedZoneID
                                  ? BookingAvailabilityColors.NOT_ACTIVE
                                  : getPortColor(
                                      wizardCurrentStep === BookingWizardSteps.STEP_2_SELECT_DESK
                                        ? selectedDates.map(m => m.dateNumber)
                                        : [
                                            dateToNumber(
                                              floorplanViewingDate ||
                                                searchParams?.date ||
                                                new Date()
                                            ),
                                          ],
                                      datesFromBookings()
                                        .filter(f => f.featureId === feature.id &&
                                          (repairTimeFrom(searchParams.from) === repairTimeFrom(f.fromTime) ||
                                            repairTimeTo(searchParams.to) === repairTimeTo(f.toTime))
                                        )
                                        .map(m => m.date)
                                    )
                              }
                              existingBookings={
                                featureBookingWithinTimeframe(feature.id, singleBooking)
                              }
                              isBlockBooking={isBlockBooking}
                              onActionMouseEnter={(
                                e,
                                _availability,
                                isOwnBooking,
                                availabilityColor,
                                _bookings
                              ) => {
                                if (submissionsInProgress.some(s => s === feature.id)) {
                                  return resetPopoverProps()
                                }
                                if (mouseDown.down && Date.now() - mouseDown.at > 1000) {
                                  return
                                }
                                if (
                                  feature !== deskPoppoverProps.featureDetail &&
                                  deskPoppoverProps.open
                                ) {
                                  return resetPopoverProps()
                                }
                                // Stops you clicking desks in other zones that dont belong to you, even though dropdown lists allow you
                                /*
                                if (feature.zone.id !== searchParams.zoneId) {
                                  return
                                }
                                */
                                applyPoppoverData(
                                  e,
                                  floorplans.find(f => f.id === feature.floorPlanId)?.name || '',
                                  feature,
                                  isOwnBooking,
                                  isBlockBooking,
                                  isPrivilegedUser,
                                  availabilityColor
                                )
                              }}
                            />
                          )}
                          {(feature.typeId === BookingFeatureTypes.SVG_Rectangle ||
                            feature.typeId === BookingFeatureTypes.SVG_Text ||
                            feature.typeId === BookingFeatureTypes.SVG_Generic) &&
                            renderSVGType(feature, false)}
                        </>
                      )
                    })}
                </svg>
              </>
            )}
          </Box>
        </Grid>
      </FullComponentLoadingIcon>
    </FloorplanView>
  )
}
