import { format } from 'date-fns'
import { Grid } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../../../../redux/store'

import Button from '../../../../shared/UI/Button'

import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { FormProps, Search } from './types'
import { CurrentEntitlementPeriodResponse } from '../../../../services/dashboardService'
import DropdownMenu from '../../../../shared/UI/DropdownMenu'

function HolidayEntitlementBulgariaSearchForm({ resetForm, onSearch }: FormProps) {
  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)
  const { calendarDetails } = useSelector(
    (state: RootStore) => state.appSettings.calendarDetailsResponse!
  )

  const [selectedYear, setSelectedYear] = useState<CurrentEntitlementPeriodResponse | null>(
    currentEntitlementPeriodResponse !== undefined ? currentEntitlementPeriodResponse : null
  )

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const search: Search = {
      fromDateRangeFrom: format(new Date(selectedYear!.startDate), 'yyyy-MM-dd'),
    }

    onSearch(search)
  }

  useDidMountEffect(() => {
    setSelectedYear(currentEntitlementPeriodResponse!)
  }, [resetForm])

  return (
    <Grid container spacing={4} component="form" noValidate mt={0}>
      {/* Main Layout 4 Col */}
      <Grid item xs={12} lg={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropdownMenu
              label="Year"
              id="year"
              textField="year"
              valueField="year"
              value={selectedYear ? selectedYear!.year : null}
              data={calendarDetails}
              onChange={e => {
                setSelectedYear(calendarDetails.find(p => p.year === parseInt(e.target.value, 10))!)
              }}
            />
          </Grid>
          {/* Main Layout 4 Col END */}
        </Grid>
      </Grid>
      {/* Main Layout 8 Col */}
      <Grid item xs={12} pt={0} mt={0} display="flex" justifyContent="flex-end">
        <Button label="Search" type="submit" onClick={handleSubmit} />
      </Grid>
    </Grid>
  )
}

export default HolidayEntitlementBulgariaSearchForm
