import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../../redux/store'
import { employeeService } from '../../services/employeeService'
import { BaseResponse } from '../../types/base-response'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../utils/errorFilter'

export const useEmployeeDefaultShiftLength = () => {
  const dispatch = useDispatch()
  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)
  const [employeeDefaultShiftLength, setEmployeeDefaultShiftLength] = useState<number>(8)

  useEffect(() => {
    if (employeeDetails.employeeId) {
      employeeService
        .getEmployeeDefaultShiftLength(employeeDetails.employeeId)
        .then(response => {
          setEmployeeDefaultShiftLength(response.defaultShiftLength)
        })
        .catch(err => {
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    }
  }, [dispatch, employeeDetails.employeeId])

  return {
    employeeDefaultShiftLength,
  }
}
