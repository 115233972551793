const trueValue = 'true'
const falseValue = 'false'

const isRedirectingKey = 'isRedirecting'

const localStorageService = {
  setIsOktaRedirecting: (valueToSet: boolean) => {
    const isRedirecting = valueToSet ? trueValue : falseValue
    localStorage.setItem(isRedirectingKey, isRedirecting)
  },
  isOktaRedirecting: (): boolean => localStorage.getItem(isRedirectingKey) === trueValue,
  setValueForKey: (key: string, value: string): string | null => {
    const previousValue = localStorage.getItem(key)
    localStorage.setItem(key, value)
    return previousValue
  },
  valueForKeyExists: (key: string): boolean => localStorage.getItem(key) !== null,
}

export default localStorageService
