import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Desk as DeskIcon, Today as TodayIcon, Info as InfoIconIcon } from '@mui/icons-material'
import { BookingBlockComingUp, BookingBlockComingUpDays } from './types'
import { RootStore } from '../../../redux/store'
import { dashboardService, ComingUpCalendarRequest } from '../../../services/dashboardService'
import { BookingDateRange } from '../consts'
import {
  COMING_UP_MANUAL_TYPE_IDS,
  COMING_UP_REQUEST_TYPE_IDS,
  FlattenedRequestType,
} from '../../../utils/constants'
import { showErrorMessage } from '../../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../../utils/errorFilter'
import { BaseResponse } from '../../../types/base-response'
import { formatDateTimeWithTimeZoneBST } from '../../../utils/date-utils'
import { BookingWizardSteps } from './enums'
import { WorkingHoursAlias } from '../Shared/enums'
import {
  setBookingWizardStepState,
  BookingBlockBookingFinalSelection,
  setBookingWizardFinalSelectionState,
} from '../../../redux/reducers/deskBookingWizardReducer'
import NoResult from '../../../shared/UI/NoResult'
import LoadingIndicator from '../../../shared/UI/LoadingIndicator'
import Paragraph from '../../../shared/UI/Paragraph'
import { dateToNumber, getDeskName } from '../utils/utils'
import CheckBox from '../../../shared/UI/CheckBox'
import TypeLabel from '../../../shared/UI/TypeLabel'
import { getPalleteTypeByProp } from '../../../theme/palette'
import { BookingBookingPayload, BookingEmployeeBooking } from '../../../services/booking/types'
import { BlockBookingText } from './consts'
import { bookingTransformer, uniqueBookings } from '../bookingLogic'

function NoRowsOverlay() {
  return <NoResult message="Searching..." showImage />
}
function LoadingOverlay() {
  return <LoadingIndicator show alignItems="center" />
}
export function BookingBlockSummary() {
  const [comingUp, setComingUp] = useState<BookingBlockComingUp[]>()
  const [gridData, setGridData] = useState<BookingBlockBookingFinalSelection[]>([])
  const [conflictDates, setConflictDates] = useState<string[]>([])

  const [pageSize, setPageSize] = useState<number>(10)
  const {
    bookingRange,
    selectedDates,
    existingBookings,
    selectedFeature,
    weekdaysSelected,
    currentStep: wizardCurrentStep,
    finalSelection,
  } = useSelector((state: RootStore) => state.deskBookingWizard)
  const { dashboardResults } = useSelector((state: RootStore) => state.deskBooking)
  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)
  const { searchParams } = useSelector((state: RootStore) => state.deskBookingSearch)

  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  const isManager = userPermissions.includes('BookingManager')

  const dispatch = useDispatch()

  const finalSelectedDates: BookingBlockBookingFinalSelection[] = []

  const getConflictingDates = useCallback(
    (requests: ComingUpCalendarRequest[]) => {
      const result = requests.flatMap(request =>
        request.days.map(day => format(formatDateTimeWithTimeZoneBST(day.date), 'dd-MM-yyyy'))
      )

      setConflictDates(result)
    },
    [setConflictDates]
  )

  useEffect(() => {
    if (wizardCurrentStep !== BookingWizardSteps.STEP_3_SUMMARY) {
      return
    }
    dashboardService
      .getComingUpCalendar(
        bookingRange[BookingDateRange.FROM],
        bookingRange[BookingDateRange.TO],
        COMING_UP_REQUEST_TYPE_IDS,
        COMING_UP_MANUAL_TYPE_IDS
      )
      .then(res => {
        const maps: BookingBlockComingUp[] = res.requests.map(m => {
          let getDays = m.days

          if (m.flattenedRequestType === FlattenedRequestType.Birthday || !m.days.length) {
            getDays = [
              {
                date: formatDateTimeWithTimeZoneBST(m.dateFrom),
                hours: WorkingHoursAlias.FULL_DAY,
              },
            ]
          }

          return {
            dateFrom: formatDateTimeWithTimeZoneBST(m.dateFrom),
            dateTo: formatDateTimeWithTimeZoneBST(m.dateTo),
            dateFromNumber: dateToNumber(new Date(m.dateFrom)),
            dateToNumber: dateToNumber(m.dateTo),
            days: getDays,
            typeID: m.flattenedRequestTypeId,
            typeDesc: m.flattenedRequestType,
          }
        })

        setComingUp(maps || [])
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [])

  const deskBookingEmployeeId = useCallback(() => {
    const thisEmployeeId = employeeDetails?.employeeId
    const searchedEmployeeId = searchParams && searchParams?.employeeID
    const managerSelectedEmployee =
      isManager && searchedEmployeeId && thisEmployeeId !== searchedEmployeeId

    return managerSelectedEmployee ? searchedEmployeeId : thisEmployeeId
  }, [employeeDetails?.employeeId, searchParams?.employeeID, isManager])

  const checkConflictIsCurrentUser = useCallback(
    (conflicts: BookingBookingPayload[]) => {
      if (conflicts.length <= 0) {
        return false
      }

      if (isManager) {
        return false
      }

      const featureConflicts = conflicts.filter(
        (f: BookingBookingPayload) => selectedFeature?.id === f.featureId
      )
      const thisConflict = featureConflicts?.[0]

      return conflicts.filter(c => c.employeeId === deskBookingEmployeeId()).length > 0
    },
    [deskBookingEmployeeId]
  )

  const deskFeatureOtherDeskBookedByEmployee = (deskConflict: BookingBookingPayload[]) =>
    deskConflict.filter(
      d => d.featureId !== selectedFeature?.id && d.employeeId === deskBookingEmployeeId()
    )[0]

  const deskFeatureBookedForEmployee = (deskConflict: BookingBookingPayload[]) =>
    deskConflict.filter(
      d => d.featureId === selectedFeature?.id && d.employeeId === deskBookingEmployeeId()
    )[0]

  const deskFeatureBookedForOtherEmployee = (deskConflict: BookingBookingPayload[]) =>
    deskConflict.filter(
      d => d.featureId === selectedFeature?.id && d.employeeId !== deskBookingEmployeeId()
    )[0]

  const checkHalfDayAbsence = (days: BookingBlockComingUpDays[], dateNumber: number) => days.filter(day => day.hours <= WorkingHoursAlias.HALF_DAY && dateToNumber(day.date) === dateNumber)

  const deskFeatureBookedHalfDay = (deskConflict: BookingBlockBookingFinalSelection) => {
    if(!deskConflict.absence) {
      return false
    }

    return checkHalfDayAbsence(deskConflict.absence.days, deskConflict.dateNumber).length > 0
  }

  // TD - maybe replace with a switch statement to remove duplicate returns?
  const availabilityText = (rowData: BookingBlockBookingFinalSelection) => {
    const { conflicts } = rowData

    if (deskFeatureBookedHalfDay(rowData)) {
      return BlockBookingText.AVAILABLE_HALF
    }

    if (conflicts.length <= 0) {
      return BlockBookingText.AVAILABLE
    }

    if (deskFeatureBookedForEmployee(conflicts) && !deskFeatureBookedForOtherEmployee(conflicts)) {
      return BlockBookingText.ALREADY_BOOKED
    }

    if (deskFeatureOtherDeskBookedByEmployee(conflicts)) {
      return BlockBookingText.OTHER_BOOKED
    }

    if (!deskFeatureBookedForOtherEmployee(conflicts)) {
      return BlockBookingText.AVAILABLE
    }

    return BlockBookingText.NOT_AVAILABLE
  }

  const hasActiveStatus = useCallback(
    (absenceItems: BookingBlockComingUp[], bookings: BookingBookingPayload[], dateNumber: number) => {
      const thisEmployeeId = employeeDetails?.employeeId
      const theBookingEmployeeId = deskBookingEmployeeId()

      if (
        isManager &&
        thisEmployeeId !== searchParams?.employeeID &&
        !bookings.some(e => e.employeeId === searchParams?.employeeID)
      ) {
        return true
      }

      if (bookings.some(e => e.employeeId === thisEmployeeId) && !isManager) {
        return false
      }

      if (absenceItems[0] && checkHalfDayAbsence(absenceItems[0]?.days, dateNumber).length > 0) {
        return true
      }

      if (
        (!absenceItems[0] &&
          thisEmployeeId === searchParams?.employeeID &&
          !bookings.some(e => e.employeeId === theBookingEmployeeId))
      ) {
        return true
      }

      return false
    },
    [employeeDetails.employeeId, selectedFeature?.id]
  )

  const alreadyBeenBookedByUser = (conflicts: BookingBookingPayload[], CssClass: string) => {
    if (checkConflictIsCurrentUser(conflicts) && !isManager) {
      return 'bold alreadyBookedByUser'
    }

    return conflicts.filter(f => selectedFeature?.id === f.featureId)[0] ? ` ${CssClass}` : ''
  }

  useEffect(() => {
    if (!selectedFeature || !comingUp) {
      return
    }

    selectedDates
      .filter(f => weekdaysSelected.some(s => s.dayIdx === new Date(f.date).getDay()))
      .forEach((date, idx) => {

        const bookings = existingBookings.filter(
          f => dateToNumber(f.fromDate) === date.dateNumber && selectedFeature.id === f.featureId
        )
        const employeeBookings = (dashboardResults?.results ?? [])
          .filter(f => dateToNumber(f.fromDate) === date.dateNumber)
          .map(
            (booking: BookingEmployeeBooking) =>
              bookingTransformer(booking) as BookingBookingPayload
          )

        let thisAbsenceBlock: BookingBlockComingUp

        const absenceItems = comingUp?.filter(absence => {
          const absenceBlockExists =
            absence.days.filter(day => dateToNumber(day.date) === date.dateNumber).length > 0

          if (absenceBlockExists) {
            thisAbsenceBlock = absence
          }
          return absenceBlockExists
        })

        const employeeAbsenceBlock = () =>
          isManager && searchParams?.employeeID !== employeeDetails.employeeId
            ? undefined
            : thisAbsenceBlock || absenceItems[0]

        const combinedDeskBookings = [...bookings, ...(employeeBookings as BookingBookingPayload[])]
        const uniqueDeskBookings = uniqueBookings(combinedDeskBookings)

        finalSelectedDates.push({
          id: idx,
          date: date.date,
          dateNumber: date.dateNumber,
          active: hasActiveStatus(absenceItems, uniqueDeskBookings, date.dateNumber),
          conflicts: uniqueDeskBookings,
          absence: employeeAbsenceBlock(),
        })
      })

    setGridData(finalSelectedDates)
    dispatch(setBookingWizardFinalSelectionState(finalSelectedDates))

    const finalSelectedDateActive = finalSelectedDates.filter(f => f.active !== false)
    dispatch(
      setBookingWizardStepState(
        !finalSelectedDateActive.length
          ? BookingWizardSteps.STEP_3_UNAVAILABLE
          : BookingWizardSteps.STEP_3_SUMMARY
      )
    )
  }, [selectedDates, comingUp, selectedFeature, dispatch, weekdaysSelected, existingBookings])

  const rowInClashingDates = (row: BookingBlockBookingFinalSelection) =>
    conflictDates.includes(format(row.date, 'dd-MM-yyyy'))

  const rowDisabled = (row: BookingBlockBookingFinalSelection): boolean => {
    if (isManager) {
      return false
    }

    const checkAbsence = () => {
      if (!row.absence) {
        return false
      }

      if (row.absence && checkHalfDayAbsence(row.absence.days, dateToNumber(row.date)).length > 0) {
        return false
      }

      return true
    }

    return checkAbsence() || checkConflictIsCurrentUser(row.conflicts) || rowInClashingDates(row)
  }

  const rowHasStandardTypeLabel = (row: BookingBlockBookingFinalSelection) =>
    row.absence && (row.absence.typeDesc === FlattenedRequestType.WorkFromHome ||
     (row.absence.typeDesc !== FlattenedRequestType.WorkFromHome || rowInClashingDates(row)))

  const columns: GridColDef[] = [
    {
      field: 'checked',
      headerName: 'Select',
      flex: 1,
      maxWidth: 100,
      headerClassName: 'header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: params => (
        <CheckBox
          sx={{
            '& .MuiGrid-root': {
              paddingTop: 0,
            },
          }}
          checked={params.row.active && !rowDisabled(params.row)}
          disabled={!isManager && rowDisabled(params.row)}
          onChange={(_e, checked) => {
            const updatedItem = {
              ...gridData.find(f => f.id === params.row.id),
              active: checked,
            } as BookingBlockBookingFinalSelection
            const filteredItems = gridData.filter(f => f.id !== params.row.id)
            const sortedItems = [...filteredItems, updatedItem].sort((a, b) => {
              if (a.dateNumber < b.dateNumber) {
                return -1
              }
              if (a.dateNumber > b.dateNumber) {
                return 1
              }
              return 0
            })

            const sortedItemsActive = sortedItems.filter(f => f.active)

            setGridData(sortedItems)
            dispatch(setBookingWizardFinalSelectionState(sortedItemsActive))
            dispatch(
              setBookingWizardStepState(
                !sortedItemsActive.length
                  ? BookingWizardSteps.STEP_3_UNAVAILABLE
                  : BookingWizardSteps.STEP_3_SUMMARY
              )
            )
          }}
        />
      ),
    },
    {
      field: 'date',
      headerName: 'Date',
      headerClassName: 'header',
      type: 'date',
      flex: 1,
      cellClassName: params => alreadyBeenBookedByUser(params.row.conflicts, 'bold'),
      renderCell: params => format(params.value, 'EEE, do MMM'),
    },
    {
      field: 'availability',
      headerName: 'Availability',
      headerClassName: 'header',
      valueGetter: params => params.row.conflicts.length,
      flex: 1,
      type: 'string',
      cellClassName: params => alreadyBeenBookedByUser(params.row.conflicts, 'bold notAvailable'),
      renderCell: params => availabilityText(params.row),
      sortComparator: (a, b) => a - b,
    },
    {
      field: 'bookings',
      headerName: 'Clashes',
      headerClassName: 'header',
      flex: 1,
      cellClassName: params =>
        `textElipses${alreadyBeenBookedByUser(params.row.conflicts, 'bold')}`,
      renderCell: params => {
        if (rowHasStandardTypeLabel(params.row)) {
          return (
            <TypeLabel
              label={getPalleteTypeByProp(params.row.absence.typeDesc).label}
              type={params.row.absence.typeDesc}
            />
          )
        }

        const theBookingEmployeeId = deskBookingEmployeeId()

        const thisDeskNameCode = getDeskName(
          selectedFeature?.additionalInfo || '',
          selectedFeature?.label || ''
        )
        const thisRowConflicts = params.row.conflicts
        const featureConflicts = thisRowConflicts.filter(
          (f: BookingBookingPayload) => selectedFeature?.id === f.featureId
        )
        const featureConflictsLen = featureConflicts.length
        const featureConflictsDifferentForUser = thisRowConflicts.filter(
          (c: BookingBookingPayload) =>
            c.featureId !== selectedFeature?.id && c.employeeId === theBookingEmployeeId
        )
        const thisConflict = featureConflicts?.[0]
        const thisConflictIsDifferentUser = (conflict: BookingBookingPayload) =>
          conflict && conflict?.employeeId !== theBookingEmployeeId
        const multipleConflictsDifferentUser = thisRowConflicts.some((r: BookingBookingPayload) =>
          thisConflictIsDifferentUser(r)
        )

        let employeeDisplayName = 'You'

        if (thisConflictIsDifferentUser(thisConflict)) {
          employeeDisplayName = thisConflict?.displayName
        }

        if (featureConflictsLen >= 0 && thisConflict?.featureId === selectedFeature?.id) {
          if (
            featureConflictsLen > 1 &&
            checkConflictIsCurrentUser(featureConflicts) &&
            multipleConflictsDifferentUser
          ) {
            return (
              <span
                title={`This Desk ( ${thisDeskNameCode} ) is already split booked by You and another user for the day`}
              >
                Booked by You &amp; Another
              </span>
            )
          }

          if (featureConflictsLen > 1 && multipleConflictsDifferentUser) {
            return (
              <span
                title={`This Desk ( ${thisDeskNameCode} ) is booked by multiple users for the day`}
              >
                Booked by multiple
              </span>
            )
          }

          const otherConflictsBySameUser = featureConflictsDifferentForUser.some(
            (r: BookingBookingPayload) => !thisConflictIsDifferentUser(r)
          )
          const alreadyBookedDeskCheck = otherConflictsBySameUser
            ? `You have booked another desk for this day. `
            : ''

          return (
            <span
              title={`${alreadyBookedDeskCheck}This Desk ( ${thisDeskNameCode} ) is booked by ${employeeDisplayName} on this day`}
            >
              {`Desk Booked by ${employeeDisplayName}`}
            </span>
          )
        }

        if (featureConflictsDifferentForUser.length > 0) {
          return (
            <span
              title={`This Desk ( ${thisDeskNameCode} ) is available: ${employeeDisplayName} already booked another desk for this day`}
            >
              {`Other Booking by ${employeeDisplayName}`}
            </span>
          )
        }

        return 'None'
      },
    },
    {
      field: 'alternative',
      headerName: 'Alternative',
      headerClassName: 'header',
      type: 'string',
      flex: 1,
      cellClassName: params => alreadyBeenBookedByUser(params.row.conflicts, 'bold notAvailable'),
      renderCell: params =>
        params.row.conflicts.filter(
          (f: { featureId: number | undefined }) => selectedFeature?.id === f.featureId
        ).length > 0 && params.row.active
          ? BlockBookingText.RESOLVE_CONFLICTS_ON_NEXT_STEP
          : BlockBookingText.NOT_APPLICABLE,
    },
  ]

  return (
    <Box
      overflow="auto"
      m="0 32px"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .MuiDataGrid-cell': {
          color: '#000',
        },
        '& .textElipses span': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '& .bold': {
          fontWeight: 'bold',
        },
        '& .notAvailable': {
          color: '#BA7070',
        },
        '& .rowNotAvailable': {
          backgroundColor: '#f8f1f1',
        },
        '& .rowAbsent .MuiDataGrid-cell': {
          color: '#CCCCCC',
        },
        '& .alreadyBookedByUser': {
          color: '#CCCCCC',
        },
      }}
    >
      <Stack
        direction="row"
        bgcolor="#2C2965"
        mb="32px"
        height="43px"
        borderRadius="8px"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        p="0 16px"
      >
        <DeskIcon fontSize="small" style={{ color: 'white', height: '18px', width: '18px' }} />
        <Paragraph color="white" padding="0 10px">
          {getDeskName(selectedFeature?.additionalInfo || '', selectedFeature?.label || '')}
        </Paragraph>
        <TodayIcon
          style={{
            color: 'white',
            fontSize: '13px',
            paddingLeft: '24px',
            height: '18px',
            width: '18px',
          }}
        />
        <Paragraph color="white" padding="0 44px 0 10px">
          {`${format(bookingRange[BookingDateRange.FROM], 'MMM do')} - ${format(
            bookingRange[BookingDateRange.TO],
            'MMM do yyyy'
          )}`}
        </Paragraph>
        {finalSelection.some(
          s => s.conflicts.length > 0 && wizardCurrentStep !== BookingWizardSteps.STEP_3_UNAVAILABLE
        ) && (
          <>
            <InfoIconIcon
              style={{ color: 'white', fontSize: '13px', height: '18px', width: '18px' }}
            />
            <Paragraph color="white" padding="0 10px">
              The selected desk has limited availability, these are itemised in the results below.
            </Paragraph>
          </>
        )}
        {wizardCurrentStep === BookingWizardSteps.STEP_3_UNAVAILABLE && (
          <>
            <InfoIconIcon
              style={{ color: 'white', fontSize: '13px', height: '18px', width: '18px' }}
            />
            <Paragraph color="white" padding="0 10px">
              No Dates Selected or Available within Block Booking, due to Availability Clashes below.
            </Paragraph>
          </>
        )}
      </Stack>
      <DataGridPro
        getRowId={row => row.id}
        rows={gridData}
        columns={columns}
        disableSelectionOnClick
        pagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        pageSize={8}
        onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
        rowHeight={40}
        autoHeight
        loading={!gridData.length}
        getRowClassName={params => {
          if (params.row.absence && !isManager && rowDisabled(params.row)) {
            return 'rowAbsent'
          }

          return params.row.conflicts.length === 0 ||
            isManager ||
            (isManager && !checkConflictIsCurrentUser(params.row.conflicts))
            ? ''
            : 'rowBasic rowNotAvailable'
        }}
        components={{ NoRowsOverlay, LoadingOverlay }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'submitDateTime', sort: 'asc' }],
          },
        }}
        onRowClick={params => undefined}
        sx={{
          '&.MuiDataGrid-root': {
            border: 'none !important ',
          },
          '& .MuiDataGrid-virtualScrollerContent': {
            minHeight: '200px!important',
          },
          '.header': {
            backgroundColor: 'rgb(247, 247, 247)',
          },
          cursor: 'pointer',
        }}
      />
    </Box>
  )
}
