import { endOfMonth, getDaysInMonth, startOfMonth } from 'date-fns'
import {
  WallChartSelectedTimeSpan,
  WallChartSegments,
  WallChartTimespans,
} from '../../components/WallChart/WallChartTimespanSelector/types'
import { SelectOption, Employee, Team, Department } from '../../services/dashboardService'
import { MONTHS } from '../../utils/constants'
import { formatDateWithTimeZone } from '../../utils/date-utils'

/**
 * These are the available filters, they are what the user selects from
 */
export interface WallChartSelectableParams {
  availableEmployees: Employee[]
}

/**
 * These are the filter options selected by the user
 */
export interface WallChartSelectedParams {
  selectedDepartment: SelectOption | null
  selectedTeams: Team[]
  selectedEmployees: Employee[]
  selectedYear: SelectOption | null
  directReport: boolean
}

export interface WallChartState {
  selectableParams: WallChartSelectableParams
  selectedParams: WallChartSelectedParams
  selectedTimeSpan: WallChartSelectedTimeSpan
  lastSelectedDay?: WallChartSelectedTimeSpan
  lastSelectedWeek?: WallChartSelectedTimeSpan
  lastSelectedMonth?: WallChartSelectedTimeSpan
}

export const selectedParamsInitialState: WallChartSelectedParams = {
  selectedDepartment: null,
  selectedTeams: [],
  selectedEmployees: [],
  selectedYear: {
    value: new Date().getFullYear(),
    displayValue: new Date().getFullYear().toString(),
  },
  directReport: true,
}

export const selectableParamsInitialState: WallChartSelectableParams = {
  availableEmployees: [],
}

export const timeSpanInitialState: WallChartSelectedTimeSpan = {
  directReport: false,
  startDate: startOfMonth(formatDateWithTimeZone(new Date())),
  endDate: endOfMonth(formatDateWithTimeZone(new Date())),
  segments: {
    masterSegments: getDaysInMonth(new Date()),
    divisions: WallChartSegments.SEGMENT_DIV_MONTH,
    timespan: WallChartTimespans.MONTH,
  },
  title: MONTHS[new Date().getMonth()],
  rangeType: 'Month',
}

export const wallChartInitialState: WallChartState = {
  selectableParams: selectableParamsInitialState,
  selectedParams: selectedParamsInitialState,
  selectedTimeSpan: timeSpanInitialState,
}

const SET_SELECTABLE = 'SET_SELECTABLE'
const SET_SELECTED = 'SET_SELECTED'
const SET_SELECTED_TIMESTAMP = 'SET_SELECTED_TIMESTAMP'
const SET_LAST_SELECTED_DAY = 'SET_LAST_SELECTED_DAY'
const SET_LAST_SELECTED_WEEK = 'SET_LAST_SELECTED_WEEK'
const SET_LAST_SELECTED_MONTH = 'SET_LAST_SELECTED_MONTH'

interface SetSelectableParamsAction {
  type: typeof SET_SELECTABLE
  payload: WallChartSelectableParams
}
interface SetSelectedParamsAction {
  type: typeof SET_SELECTED
  payload: WallChartSelectedParams
}
interface SetSelectedTimestampAction {
  type: typeof SET_SELECTED_TIMESTAMP
  payload: WallChartSelectedTimeSpan
}
interface SetLastSelectedDayAction {
  type: typeof SET_LAST_SELECTED_DAY
  payload: WallChartSelectedTimeSpan | undefined
}
interface SetLastSelectedWeekAction {
  type: typeof SET_LAST_SELECTED_WEEK
  payload: WallChartSelectedTimeSpan | undefined
}
interface SetLastSelectedMonthAction {
  type: typeof SET_LAST_SELECTED_MONTH
  payload: WallChartSelectedTimeSpan | undefined
}

// Actions
export const setWallChartSelectable = (
  state: WallChartSelectableParams
): SetSelectableParamsAction => ({
  type: SET_SELECTABLE,
  payload: state,
})
export const setWallChartSelected = (state: WallChartSelectedParams): SetSelectedParamsAction => ({
  type: SET_SELECTED,
  payload: state,
})
export const setSelectedTimespan = (
  state: WallChartSelectedTimeSpan
): SetSelectedTimestampAction => ({
  type: SET_SELECTED_TIMESTAMP,
  payload: state,
})

export const setLastSelectedDayTimespan = (
  state: WallChartSelectedTimeSpan | undefined
): SetLastSelectedDayAction => ({
  type: SET_LAST_SELECTED_DAY,
  payload: state,
})
export const setLastSelectedWeekTimespan = (
  state: WallChartSelectedTimeSpan | undefined
): SetLastSelectedWeekAction => ({
  type: SET_LAST_SELECTED_WEEK,
  payload: state,
})
export const setLastSelectedMonthTimespan = (
  state: WallChartSelectedTimeSpan | undefined
): SetLastSelectedMonthAction => ({
  type: SET_LAST_SELECTED_MONTH,
  payload: state,
})

type setWallChartStateAction =
  | SetSelectableParamsAction
  | SetSelectedParamsAction
  | SetSelectedTimestampAction
  | SetLastSelectedDayAction
  | SetLastSelectedWeekAction
  | SetLastSelectedMonthAction

// Reducer
export const wallChartReducer = (
  state: WallChartState = wallChartInitialState,
  action: setWallChartStateAction
): WallChartState => {
  switch (action.type) {
    case SET_SELECTABLE:
      return { ...state, selectableParams: action.payload }
    case SET_SELECTED:
      return { ...state, selectedParams: action.payload }
    case SET_SELECTED_TIMESTAMP:
      return { ...state, selectedTimeSpan: action.payload }
    case SET_LAST_SELECTED_DAY:
      return { ...state, lastSelectedDay: action.payload }
    case SET_LAST_SELECTED_WEEK:
      return { ...state, lastSelectedWeek: action.payload }
    case SET_LAST_SELECTED_MONTH:
      return { ...state, lastSelectedMonth: action.payload }
    default:
      return state
  }
}
