import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  LocalizationProvider,
  DatePicker as MuiDatePicker,
  PickersDay,
  PickersDayProps,
  MobileDatePicker,
} from '@mui/x-date-pickers-pro'
import React, { useCallback } from 'react'
import { TextField, useMediaQuery } from '@mui/material'
import locale from 'date-fns/locale/en-GB'
import theme from '../../../theme/theme'

interface DatePickerProps {
  label: string
  value: Date | null
  onChange: (selectedDate: Date | null, keyboardInputValue?: string) => void
  error?: boolean
  required?: boolean
  disabled?: boolean
  helperText?: 'Required' | 'Optional'
  daysToDisable?: number[]
  thresholdDates?: Date[]
  isHTL?: boolean
  isHss?: boolean
  maxDate?: Date | undefined
  minDate?: Date | undefined
  disablePast?: boolean
  disabledDates?: string[]
  dataTestId?: string
}

function DatePicker({
  label,
  value,
  onChange,
  error,
  required,
  disabled = false,
  helperText,
  daysToDisable = [],
  thresholdDates = [],
  isHTL,
  maxDate,
  minDate,
  disablePast = false,
  disabledDates = [],
  dataTestId,
}: DatePickerProps) {
  const [open, setOpen] = React.useState(false)
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const renderDisabledDay = useCallback(
    (day: Date, selectedDays: Array<Date | null>, pickersDayProps: PickersDayProps<Date>) => {
      const thresholdAlreadyExists = thresholdDates?.find(d => d.getTime() === day.getTime())

      return (
        <PickersDay
          {...pickersDayProps}
          sx={
            thresholdAlreadyExists
              ? {
                  backgroundColor: theme.palette.error.contrastText,
                  '&.Mui-disabled': {
                    color: 'rgba(0,0,0,0.2)',
                  },
                  '&.MuiPickersDay-today.MuiButtonBase-root:not(.Mui-selected)': {
                    border: '1px solid rgba(32,197,160,0.3)',
                    backgroundColor: 'rgba(32,197,160,0.3)',
                  },
                }
              : {
                  '&.Mui-disabled': {
                    color: 'rgba(0,0,0,0.2)',
                  },
                  '&.MuiPickersDay-today.MuiButtonBase-root:not(.Mui-selected)': {
                    border: '1px solid rgba(32,197,160,0.3)',
                    backgroundColor: 'rgba(32,197,160,0.3)',
                  },
                }
          }
        />
      )
    },
    [thresholdDates]
  )

  const getDisabledDates = useCallback(
    (date: Date) => {
      let invalidDate = false
      let disabledDay = false
      let disabledDate = false

      if (thresholdDates.length) {
        invalidDate = thresholdDates.some(d => d.getTime() === date.getTime())
      }

      if (disabledDates.length) {
        disabledDate = disabledDates.some(d => date.toISOString() === new Date(d).toISOString())
      }

      if (daysToDisable.length) {
        disabledDay = daysToDisable.some(d => d === date.getDay())
      }

      return invalidDate || disabledDay || disabledDate
    },
    [daysToDisable, disabledDates, thresholdDates]
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
      {mobile ? (
        <MobileDatePicker
          label={label}
          inputFormat="dd/MM/yyyy"
          value={value}
          onChange={onChange}
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              required={required}
              error={error}
              autoComplete="off"
              onClick={() => setOpen(true)}
              helperText={helperText}
            />
          )}
          disableOpenPicker
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          open={open}
          shouldDisableDate={getDisabledDates}
          renderDay={renderDisabledDay}
          disablePast={disablePast}
        />
      ) : (
        <MuiDatePicker
          label={label}
          inputFormat="dd/MM/yyyy"
          value={value}
          onChange={onChange}
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              required={required}
              error={error}
              autoComplete="off"
              onClick={() => setOpen(true)}
              helperText={helperText}
              data-testid={dataTestId}
            />
          )}
          disableOpenPicker
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          open={open}
          shouldDisableDate={getDisabledDates}
          renderDay={renderDisabledDay}
          disablePast={disablePast}
        />
      )}
    </LocalizationProvider>
  )
}

export default DatePicker
