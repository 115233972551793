import { useDispatch, useSelector, batch } from 'react-redux'
import { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { addMonths, getMonth, isSameMonth, startOfMonth, format } from 'date-fns'
import { Box, Grid,Fade } from '@mui/material'
import { RootStore } from '../../../redux/store'
import { getDeskName, cleanUsername } from '../utils/utils'
import { BookingScrollContainer } from '../components'
import { BookingContainer } from '../MyBookings'
import CardTitle from '../../../shared/UI/CardTitle'
import { BookinGridViewProps } from './types'
import {
  BookingEmployeeBooking,
} from '../../../services/booking/types'
import { BookingListMaxMonths } from './enums'
import {
  setBookingSliderPosition,
  setDeskBookingDashboardResults,
  setDeskBookingShowFloorplan,
  setDeskBookingShowGridView,
  setDeskBookingFocussedZoneID,
  setBookingFloorplanViewingDate,
  setDeskBookingLoading,
} from '../../../redux/reducers/deskBookingReducer'
import noDesk from '../../../assets/NoDesk.svg'
import Paragraph from '../../../shared/UI/Paragraph'
import LoadingIndicator from '../../../shared/UI/LoadingIndicator'
import { NoDesk } from './components'
import { BookingSliderPosition, MONTHS } from '../../../utils/constants'
import {
  setDeskBookingSearchParams,
  setDeskBookingShowSearch,
} from '../../../redux/reducers/deskBookingSearchReducer'
import { showComponents, defaultZoneIdIfNoneSelected } from '../utils'
import { FIRST_FLOORPLAN_IN_ARRAY } from '../consts'

import {
  getDashboardBookings,
  onBookingGridViewDataLoaded,
} from '../bookingLogic'


export default function BookingScrollView({
  onCancel,
  onDataLoaded,
  onShowMeShared,
}: BookinGridViewProps) {
  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)
  const userInfo = useSelector((state: RootStore) => state.userState.loggedInUser)

  const dispatch = useDispatch()

  const { floorplans, zones, dashboardResults, bookingIsLoading, gridViewingDate } =
  useSelector((state: RootStore) => state.deskBooking)


  const employeeFloorplans = useMemo(
    () =>
      floorplans.filter(f => f.locationId === employeeDetails.location.id) ||
      floorplans[FIRST_FLOORPLAN_IN_ARRAY],
    [employeeDetails.location.id, floorplans]
  )

  const employeeZones = useMemo(
    () =>
      zones.filter(
        z =>
          employeeFloorplans.some(s => s.id === z.floorPlanId) &&
          z.belongsTo === employeeDetails.departmentId
      ),
    [employeeDetails.departmentId, employeeFloorplans, zones]
  )


  const [loading, setLoading] = useState<boolean>(true)
  const [monthNum, setMonthNum] = useState<number>(0) 

  const thisMonthName = MONTHS[gridViewingDate.getMonth()]

  const abortControllerRef = useRef<AbortController | null>(null)

  const singleDayBookingQuery = false

  const BOOKINGS_MAX_MONTHS:number = BookingListMaxMonths.MONTHS

  const observer = useRef(
    new IntersectionObserver(
        (entries) => {
            const first = entries[0]
            if (first.isIntersecting) {
              setMonthNum((no) => no + 1)
            }
        })
  )

  const lastElement = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()

      if (node) observer.current.observe(node)
    },
    [loading]
  )

  const fetchUserBookingsMonth = async () => {

    setLoading(true)

    const today = new Date()
    const monthShift = addMonths(today, monthNum)
    const theMonth = getMonth(monthShift)
    // const lastMonthLimit = getMonth(addMonths(today, BOOKINGS_MAX_MONTHS))
    // const isLastMonth = theMonth === lastMonthLimit

    await getDashboardBookings(
      employeeDetails.employeeId,
      isSameMonth(theMonth, today) ? today : startOfMonth(monthShift),
      dispatch,
      onBookingGridViewDataLoaded,
      singleDayBookingQuery,
      abortControllerRef
    ).then((bookings) => {

      if (bookings && dashboardResults) {

        dispatch(setDeskBookingDashboardResults([...dashboardResults?.results || [], ...bookings.bookings || []]))

        setLoading(false)
      }

    })

  }

  const isLastBooking = (bookingsResult: Array<BookingEmployeeBooking>, bookingsIndex: number) => {

    const bookingsLength = bookingsResult.length
    const adjustLastBookings = (bookingsLength > 5 ? 4 : 0)
    const seekLastBooking = (bookingsLength - adjustLastBookings)

    return seekLastBooking === bookingsIndex + 1 ? lastElement: null
  }

  const isNextMonth = (thisBooking: BookingEmployeeBooking, bookingResults: Array<BookingEmployeeBooking>, bookingsIndex: number) => {

    if (bookingsIndex === 0) return false

    const thisBookingFromDate = format(new Date(thisBooking.fromDate), "M")
    const thisPreviousBooking = bookingResults[bookingsIndex-1]
    const previousBookingFromDate = format(new Date(thisPreviousBooking.fromDate), "M")

    return bookingsIndex > 0 && thisBookingFromDate !== previousBookingFromDate
  }

  useEffect(() => {
      if (monthNum <= BOOKINGS_MAX_MONTHS) {
        fetchUserBookingsMonth()
      }
  }, [monthNum])


  useEffect(() => {
    setLoading(bookingIsLoading)

    if (!loading && !bookingIsLoading && dashboardResults && dashboardResults.results[0]) {
      onDataLoaded()
    }
  }, [dashboardResults, bookingIsLoading])

  return (
    <BookingScrollContainer id="gridview">
  
      <Fade in={!bookingIsLoading} appear={false} timeout={200}>
        <Box
          width="auto"
          height='100%'
        >
          {
           dashboardResults &&
           dashboardResults.results.map((m, index) => {
              const { feature } = m
              const featureZone = feature.zone
              const deskName = getDeskName(feature.additionalInfo, feature.label)
              const floorplan = floorplans.find(f => f.id === feature.floorPlanId)
              const fromDate = new Date(m.fromDate)

              const bookingResults = dashboardResults.results

              if (m.statusId !== 4) {
                return (
                  <div ref={isLastBooking(bookingResults, index)}>
                    {isNextMonth(m, bookingResults, index) && 
                    <Grid xs={12} sm={12} container display="flex" justifyContent="flex-end">
                      <Grid item xs display="flex" alignItems="center">
                        <Box flexGrow={1}>
                          <CardTitle title={format(fromDate,  "MMMM")} />
                        </Box>
                      </Grid>
                    </Grid>}
                    <BookingContainer
                      key={`${deskName}-${m.id}-`}
                      bookingID={m.id}
                      date={fromDate}
                      dateFrom={new Date(`${m.fromDate} ${m.fromTime}`)}
                      dateTo={new Date(`${m.fromDate} ${m.toTime}`)}
                      location={floorplan?.locationName || ''}
                      zone={featureZone.name || ''}
                      invalidBooking={true || !featureZone?.id}
                      featureLabel={deskName}
                      components={
                        feature.components ? feature.components?.map(c => c.name).join(', ') : ''
                      }
                      isCheckedIn={Boolean(m.checkInOut.length)}
                      bookedFor={
                        cleanUsername(m.displayName) !== cleanUsername(userInfo?.name)
                          ? m.displayName
                          : ''
                      }
                      onCancel={featureLabel => {
                        onCancel?.({
                          bookingID: m.id,
                          deskName,
                          floorPlanName: floorplan?.name || '',
                          location: floorplan?.locationName || '',
                          date: fromDate,
                          byManager: false,
                          onCallBack: success => {
                            if (success) {
                              dispatch(
                                setDeskBookingDashboardResults(
                                  dashboardResults.results.filter(f => f.id !== m.id)
                                )
                              )
                            }
                          },
                        })
                      }}
                      onShowMe={() => {
                        dispatch(
                          setBookingSliderPosition({
                            slidePosition: BookingSliderPosition.FLOOR_PLAN,
                            buttonClick: false,
                          })
                        )
                        dispatch(setDeskBookingDashboardResults([]))

                        const { fromTime, toTime } = m
                        const { locationId, floorPlanId, zone } = feature

                        const zoneId: number =
                          zone?.id ||
                          zones.find(
                            f =>
                              f.floorPlanId === floorPlanId
                          )?.id ||
                          defaultZoneIdIfNoneSelected(employeeZones) ||
                          0

                        const thisShowMeSearchParams = {
                          locationId,
                          floorplanId: floorPlanId,
                          date: fromDate,
                          from: fromTime,
                          to: toTime,
                          zoneId,
                          autoSearch: true,
                        }

                        batch(() => {
                          showComponents(dispatch, { floorplan: true, navbar: true, search: false, filter: true })

                          dispatch(setDeskBookingLoading(true))
                          dispatch(setDeskBookingFocussedZoneID(zoneId))
                          dispatch(setBookingFloorplanViewingDate(fromDate))
                          dispatch(setDeskBookingSearchParams(thisShowMeSearchParams))

                          dispatch(setDeskBookingShowSearch(false))
                          dispatch(setDeskBookingShowFloorplan(true))
                          dispatch(setDeskBookingShowGridView(false))
                        })

                        onShowMeShared(thisShowMeSearchParams)
                      }}
                    />
                  </div>
                )
              }

              return ''
            })
          }
        </Box>
      </Fade>

      {!bookingIsLoading && loading && <Paragraph size="16px" weight="bold" color="#2c2965">Loading next month...</Paragraph>}

      {(!dashboardResults || dashboardResults === null || !dashboardResults.results[0]) &&
        bookingIsLoading && (
          <Box display="flex" height="470px" flexDirection="column" justifyContent="top">
            <Box display="flex" alignItems="center" flexDirection="column">
              <LoadingIndicator show alignItems="center" margin="20px" />
              <Paragraph size="16px" weight="bold" color="#2c2965">
                Loading desk bookings for&nbsp;
                {thisMonthName}
                ...
              </Paragraph>
            </Box>
          </Box>
        )}
      {!dashboardResults ||
        (!dashboardResults.results[0] && !bookingIsLoading && (
          <Fade in appear timeout={400}>
            <Box display="flex" height="470px" flexDirection="column" justifyContent="center">
              <Box display="flex" alignItems="center" flexDirection="column">
                <NoDesk src={noDesk} />
                <Paragraph size="16px" weight="bold" color="#2c2965">
                  You don&apos;t have any desks booked for&nbsp;
                  {thisMonthName}
                </Paragraph>
              </Box>
            </Box>
          </Fade>
        ))}

    </BookingScrollContainer>
  )

}
