import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../redux/store'

export function useNewRequestStatus() {
  const { userSettings } = useSelector((state: RootStore) => state.appSettings)

  const isNewRequestEnabled: boolean = useMemo((): boolean => {
    if (!userSettings) {
      return false
    }
    if (userSettings.isHolidayRequestAllowed) {
      return true
    }
    if (userSettings.isDayOffRequestAllowed) {
      return true
    }
    if (userSettings.isShiftRequestAllowed) {
      return true
    }
    if (userSettings.isLieuDayRequestAllowed) {
      return true
    }
    if (userSettings.isWfhRequestAllowed) {
      return true
    }

    return false
  }, [userSettings])

  return [isNewRequestEnabled]
}
