import { Grid, TextField } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isEqual } from 'date-fns'
import {
  requestFieldsDefaultState,
  RequestFieldsModal,
  RequestType,
  RequestTypeNameString,
} from '../../models'
import { hideHeaderDrawer } from '../../redux/reducers/appSettingsReducer'
import {
  resetNewRequestState,
  setComments,
  setDate,
  setDefaultDateRange,
  setRequestType,
  timeOffRequestsState,
} from '../../redux/reducers/timeOffRequestsReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import Alert from '../../shared/UI/Alert/Alert'
import Button from '../../shared/UI/Button'
import DateTimePicker from '../../shared/UI/DateTimePicker'
import DrawerFooter from '../../shared/UI/DrawerFooter'
import Paragraph from '../../shared/UI/Paragraph'
import { formatRequestTypeString, getRestrictedDate } from '../../utils/app-utils'
import { formatDateWithTimeZone, getLocalDateString } from '../../utils/date-utils'

type Props = {
  disabledDates?: string[]
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  isHTL?: boolean
  onClose: (event?: React.FormEvent<HTMLFormElement>, reason?: string) => void
  submitLoading?: boolean
  requestTypeId: number
}
function ShiftRequest({
  handleSubmit,
  isHTL,
  disabledDates,
  onClose,
  submitLoading = false,
  requestTypeId,
}: Props) {
  const dispatch = useAppDispatch()

  const { selectedDate, defaultDateRange, comments, alerts } = useSelector<
    RootStore,
    timeOffRequestsState
  >((state: RootStore) => state.timeOff)

  const bankHolidays = useSelector<RootStore, any[] | null>(
    (state: RootStore) => state.appSettings.bankHolidays
  )

  const [bankHolidayDatesSelected, setBankHolidayDates] = useState<string[] | undefined>([])
  const [fieldsTouched, setFieldsTouched] = useState<RequestFieldsModal>(requestFieldsDefaultState)
  const [hasConflict, setHasConflict] = useState<boolean>(false)
  const [hasErrorConflict, setHasErrorConflict] = useState<boolean>(false)

  const { currentEntitlementPeriodResponse, calendarDetailsResponse } = useSelector(
    (state: RootStore) => state.appSettings
  )

  useEffect(() => {
    if (!defaultDateRange) {
      return
    }
    dispatch(setDate(defaultDateRange[0]))
  }, [defaultDateRange, dispatch])

  useEffect(() => {
    dispatch(setRequestType(RequestType.SHIFT))
    return () => {
      dispatch(resetNewRequestState())
    }
  }, [dispatch])

  useEffect(() => {
    if (!alerts) {
      setHasConflict(false)
      setHasErrorConflict(false)
    }
    if (alerts && selectedDate) {
      const conflictsExist = !!alerts
      setHasConflict(conflictsExist)

      const errorConflictExists = alerts
        .filter(f => f.conflictType.toLowerCase() === 'error')
        .some(alert =>
          alert.conflictDates.some(fdate =>
            isEqual(formatDateWithTimeZone(fdate), formatDateWithTimeZone(fdate))
          )
        )
      setHasErrorConflict(errorConflictExists)
    }
  }, [selectedDate, alerts])

  const checkBankHolidays = useCallback(() => {
    const bankHolidaysDates = bankHolidays?.map(x => new Date(x.holidayDate).toLocaleDateString())
    const checkedDay = selectedDate
    setBankHolidayDates(bankHolidaysDates?.filter(x => checkedDay?.toLocaleDateString() === x))
  }, [bankHolidays, selectedDate])

  const BHDatesToDisplay = () => bankHolidayDatesSelected?.join(' & ')

  useEffect(() => {
    if (selectedDate) {
      checkBankHolidays()
    }
  }, [checkBankHolidays, selectedDate])

  const isFormValid = () => {
    setFieldsTouched({ date: true, time: true, comments: true })

    if (!selectedDate || !comments || comments.length === 0) {
      return false
    }
    return true
  }
  const submitButtonTestId = `Dashboard-NewRequest-${RequestTypeNameString[requestTypeId]}Tab-SubmitBtn`
  const cancelButtonTestId = `Dashboard-NewRequest-${RequestTypeNameString[requestTypeId]}Tab-CancelBtn`
  const commentsTestId = `Dashboard-NewRequest-${RequestTypeNameString[requestTypeId]}Tab-Comments`
  const dateRangeTestId = `Dashboard-NewRequest-${RequestTypeNameString[requestTypeId]}Tab-Date`

  return (
    <Grid
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isFormValid()) {
          handleSubmit(e)
        }
      }}
    >
      <Grid container spacing={4} mt={0}>
        {hasConflict &&
          alerts &&
          alerts.map(alert => (
            <Grid item xs={12} key={`${alert.conflictType}-${Math.random()}`}>
              <Alert
                severity="warning"
                message={`You already have a ${
                  alert.requestType.toLowerCase() === 'manual'
                    ? formatRequestTypeString(alert.manualRequestType!)
                    : formatRequestTypeString(alert.requestType)
                } booked for this day ${getLocalDateString(alert.conflictDates[0])}`}
              />
            </Grid>
          ))}
        {bankHolidayDatesSelected && bankHolidayDatesSelected?.length > 0 && (
          <Grid item xs={12}>
            <Alert severity="info" message={`There are bank holidays on: ${BHDatesToDisplay()}`} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Paragraph weight="bold">Details</Paragraph>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <DateTimePicker
                label="Date/Time"
                value={selectedDate}
                disabledDates={disabledDates}
                isHTL={isHTL}
                maxDate={getRestrictedDate(
                  isHTL || false,
                  currentEntitlementPeriodResponse?.entitlementPeriod || 'FinancialYear',
                  selectedDate,
                  calendarDetailsResponse || []
                )}
                onChange={newValue => {
                  dispatch(setDefaultDateRange(undefined))
                  setFieldsTouched({ ...fieldsTouched, date: true })
                  dispatch(setDate(newValue))
                }}
                error={fieldsTouched.date && selectedDate === null}
                helperText="Required"
                dataTestId={dateRangeTestId}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Comments"
                rows={5}
                multiline
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(setComments(e.target.value.trim()))
                }}
                onKeyUp={() => {
                  setFieldsTouched({ ...fieldsTouched, comments: true })
                }}
                error={fieldsTouched.comments && !comments && comments.length === 0}
                helperText="Required"
                data-testid={commentsTestId}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DrawerFooter>
        <Button
          color="secondary"
          label="Cancel"
          onClick={() => {
            dispatch(resetNewRequestState())
            dispatch(hideHeaderDrawer())
            onClose()
          }}
          dataTestId={cancelButtonTestId}
        />
        <Button
          label="Submit"
          type="submit"
          disabled={hasErrorConflict}
          loading={submitLoading}
          dataTestId={submitButtonTestId}
        />
      </DrawerFooter>
    </Grid>
  )
}

export default ShiftRequest
