import { format } from 'date-fns'
import { Button, Collapse, Grid, IconButton, Stack } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import DownloadIcon from '@mui/icons-material/Download'
import styled from 'styled-components'

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStore, useAppDispatch } from '../../../../redux/store'

import theme from '../../../../theme/theme'
import HolidaysThresholdsRestrictionsSearchForm from './HolidaysThresholdsRestrictionsSearchForm'
import HolidaysThresholdsRestrictionsDailyGrid from './HolidaysThresholdsRestrictionsDailyGrid'
import LoadingIndicator from '../../../../shared/UI/LoadingIndicator'
import { BaseResponse } from '../../../../types/base-response'
import { showErrorMessage } from '../../../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../../../utils/errorFilter'

import { ssrsHolidayReportService } from '../../../../services/ssrsHolidayReportService'
import {
  Search,
  HolidaysThresholdsRestrictionsDailyCSV,
  HolidaysThresholdsRestrictionsCommentsCSV,
} from './types'
import {
  HolidayThresholdsRestrictionsItem,
  HolidayThresholdsRestrictionsResponse,
} from '../../../../types/holiday-thresholds-restrictions-response'
import Tab from '../../../../shared/UI/Tab'
import Drawer from '../../../../shared/UI/Drawer'
import { DrawerComponentProps } from '../../types'
import HolidaysThresholdsRestrictionsCommentsGrid from './HolidaysThresholdsRestrictionsCommentsGrid'
import HolidaysThresholdsRestrictionsDailyDetail from './HolidaysThresholdsRestrictionsDailyDetail'
import HolidaysThresholdsRestrictionsCommentsDetail from './HolidaysThresholdsRestrictionsCommentsDetail'
import { Props } from '../HolidaysEntitlement/types'
import RefreshButton from '../../../../shared/UI/RefreshButton'
import DownloadButton from '../../../../shared/UI/DownloadButton'
import FilterButton from '../../../../shared/UI/FilterButton'

export const LoadingIndicatorContainer = styled.div`
  padding-top: 70px;
  padding-bottom: 70px;
`

function HolidaysThresholdsRestrictionsSearch({ userDepartments }: Props) {
  const dispatch = useAppDispatch()
  const [resetForm, setResetForm] = useState(false)
  const [showHeaderButtons, setShowHeaderButtons] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(true)
  const [showGrid, setShowGrid] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<HolidayThresholdsRestrictionsItem[]>([])
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const { currentEntitlementPeriodResponse, departments } = useSelector(
    (state: RootStore) => state.appSettings
  )

  const handleClose = () => {
    setOpenDrawer(false)
  }

  const [drawerComponent, setDrawerComponent] = useState<DrawerComponentProps>({
    title: '',
    component: <HolidaysThresholdsRestrictionsDailyDetail data={null} onClose={handleClose} />,
  })

  const handleSearch = (search: Search) => {
    setIsLoading(true)
    setShowGrid(false)

    setTimeout(() => {
      setShowSearch(false)
    }, 500)

    ssrsHolidayReportService
      .getHolidaysThresholdsRestrictionsReports(
        search.teamIds!,
        search.departmentIds!,
        search.fromDateRangeFrom !== ''
          ? search.fromDateRangeFrom!
          : format(new Date(currentEntitlementPeriodResponse!.startDate), 'yyyy-MM-dd'),
        search.fromDateRangeTo !== ''
          ? search.fromDateRangeTo!
          : format(new Date(currentEntitlementPeriodResponse!.endDate), 'yyyy-MM-dd')
      )
      .then((res: HolidayThresholdsRestrictionsResponse) => {
        const result: HolidayThresholdsRestrictionsItem[] = res.rows.map(
          (
            row: HolidayThresholdsRestrictionsItem,
            index: number,
            arr: HolidayThresholdsRestrictionsItem[]
          ) => {
            let isFirst = false
            let isLast = false

            if (index === 0) {
              isFirst = true
            } else {
              isFirst = row.department! !== arr[index - 1].department!
            }

            if (index !== arr.length - 1) {
              isLast = row.department! !== arr[index + 1].department!
            } else {
              isLast = true
            }
            const totalThreshold =
              row.monday! +
              row.tuesday! +
              row.wednesday! +
              row.thursday! +
              row.friday! +
              row.saturday! +
              row.sunday!

            return { ...row, id: index, totalThreshold, isFirst, isLast }
          }
        )

        setData(result)
        setShowHeaderButtons(true)
        setShowGrid(true)
        setIsLoading(false)
      })
      .catch(err => {
        setShowHeaderButtons(true)
        setShowGrid(false)
        setIsLoading(false)
        setTimeout(() => {
          setShowSearch(true)
        }, 500)

        const response: BaseResponse = err.response.data
        if (response.errors) {
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        } else {
          dispatch(showErrorMessage(<UserErrorMessage name="CurrentlyUnavailable" />))
        }
      })
  }

  const handleTabChange = (index: number) => {
    setSelectedTab(index)
  }

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowSearch(!showSearch)
  }

  const handleRefreshClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setResetForm(!resetForm)

    setShowSearch(true)
    setShowHeaderButtons(false)
    setShowGrid(false)
  }

  const handleViewDetail = (row: any) => {
    if (selectedTab === 0) {
      setDrawerComponent({
        ...drawerComponent,
        title: `${row.team}`,
        component: <HolidaysThresholdsRestrictionsDailyDetail data={row} onClose={handleClose} />,
      })
    } else if (selectedTab === 1) {
      setDrawerComponent({
        ...drawerComponent,
        title: `${row.team}`,
        component: (
          <HolidaysThresholdsRestrictionsCommentsDetail data={row} onClose={handleClose} />
        ),
      })
    }

    setOpenDrawer(true)
  }

  const makeCsv = async (filename: string) => {
    let headers: string[] = [
      'Department',
      'Team',
      'DateFrom',
      'DateTo',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
      'TotalThreshold',
    ]

    let csvData:
      | HolidaysThresholdsRestrictionsDailyCSV[]
      | HolidaysThresholdsRestrictionsCommentsCSV[] = data.map(
      (row: HolidayThresholdsRestrictionsItem) => {
        const item: HolidaysThresholdsRestrictionsDailyCSV = {
          Department: row.isFirst ? row.department : '',
          Team: row.team,
          DateFrom: row.dateFrom,
          DateTo: row.dateTo,
          Monday: row.monday,
          Tuesday: row.tuesday,
          Wednesday: row.wednesday,
          Thursday: row.thursday,
          Friday: row.friday,
          Saturday: row.saturday,
          Sunday: row.sunday,
          TotalThreshold: row.totalThreshold,
        }
        return item
      }
    )

    if (selectedTab === 1) {
      headers = ['Department', 'Team', 'Restriction', 'DateFrom', 'DateTo', 'Comment']
      csvData = data.map((row: HolidayThresholdsRestrictionsItem) => {
        const item: HolidaysThresholdsRestrictionsCommentsCSV = {
          Department: row.isFirst ? row.department : '',
          Team: row.team,
          Restriction: row.restriction,
          DateFrom: row.dateFrom,
          DateTo: row.dateTo,
          Comment: row.comment,
        }
        return item
      })
    }

    const keys: string[] = Object.keys(csvData[0])

    const csvContent: string = csvData
      .map((row: any) =>
        keys
          .map(k => {
            let cell = row[k] === null || row[k] === undefined ? '' : row[k]

            cell =
              cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""')

            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`
            }
            return cell
          })
          .join(',')
      )
      .join('\n')

    const csvHeaders: string = headers.join(',')

    const blob = new Blob([`${csvHeaders}\n`, csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')

    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const handleDownloadClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const file: string =
      selectedTab === 0 ? 'DepartmentSummaryDaily.csv' : 'DepartmentSummaryComment.csv'

    makeCsv(file)
  }

  return (
    <>
      {showHeaderButtons && (
        <Grid container style={{ border: '0px red solid' }}>
          <Stack
            direction="row"
            style={{ width: '100%' }}
            spacing={2}
            display="flex"
            justifyContent="flex-end"
          >
            <FilterButton onClick={handleFilterClick} title="Change Filters" />
            {data && data.length >= 1 && (
              <DownloadButton onClick={handleDownloadClick} title="Download Report" />
            )}
            <RefreshButton onClick={handleRefreshClick} title="Refresh" />
          </Stack>
        </Grid>
      )}
      <Collapse orientation="vertical" in={showSearch}>
        <HolidaysThresholdsRestrictionsSearchForm
          resetForm={resetForm}
          userDepartments={userDepartments}
          onSearch={handleSearch}
        />
      </Collapse>
      {showGrid ? (
        <>
          {!isLoading && (
            <Tab
              onChange={handleTabChange}
              titleAndContent={[
                {
                  label: 'Daily Info',
                  tabContext: (
                    <Grid item xs={12}>
                      {!isLoading && showGrid && (
                        <HolidaysThresholdsRestrictionsDailyGrid
                          data={data}
                          onViewDetail={handleViewDetail}
                        />
                      )}
                    </Grid>
                  ),
                  disabled: false,
                },
                {
                  label: 'Comments',
                  tabContext: (
                    <Grid item xs={12}>
                      {!isLoading && showGrid && (
                        <HolidaysThresholdsRestrictionsCommentsGrid
                          data={data}
                          onViewDetail={handleViewDetail}
                        />
                      )}
                    </Grid>
                  ),
                  disabled: false,
                },
              ]}
            />
          )}
        </>
      ) : (
        <>
          {isLoading && (
            <LoadingIndicatorContainer>
              <LoadingIndicator show={isLoading} />
            </LoadingIndicatorContainer>
          )}
        </>
      )}
      <Drawer
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        title={drawerComponent?.title || ''}
        showOptions={false}
      >
        {drawerComponent?.component}
      </Drawer>
    </>
  )
}

export default HolidaysThresholdsRestrictionsSearch
