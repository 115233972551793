import { EnhancementDay } from '../../models/enhancement'
import { AbsenceDayRequest } from '../../types/absence'

const toNumberWithMax2DecimalPlaces = (num: string): number => {
  let result = num.slice(0, num.indexOf('.') + 3)

  const roundToQuater = (afterDedimal: string): string => {
    if (afterDedimal.length === 2) return afterDedimal
    return (Math.round(Number(afterDedimal) * 4) / 4).toFixed(2).toString()
  }

  if (result.includes('.')) {
    const afterDedimal = result.substring(result.indexOf('.') - 1, result.length)
    const upToDecimal = result.substring(0, result.indexOf('.') - 1)

    result = upToDecimal.concat(roundToQuater(afterDedimal))
  }

  return Number(result)
}

export const handleEnhancementHoursChange = (
  hours: string,
  selecteday: Date,
  enhancementDays: EnhancementDay[]
): EnhancementDay[] => {
  const updatedDays: EnhancementDay[] = [...enhancementDays]

  let val = toNumberWithMax2DecimalPlaces(hours)
  if (val > 24) val = 24
  if (val < 0) val = 0
  const updatedDay = enhancementDays.find(
    day => new Date(day.day!).getTime() === selecteday.getTime()
  )
  if (updatedDay) {
    updatedDay.hours = val
    if (!(Number(hours) > 0)) {
      // eslint-disable-next-line no-param-reassign
      updatedDay.checked = false
    }
  }

  return updatedDays
}

export const handleEnhancementHoursChangeAbsenceDayRequest = (
  hours: string,
  selecteday: Date,
  enhancementDays: AbsenceDayRequest[]
): AbsenceDayRequest[] => {
  const updatedDays: AbsenceDayRequest[] = [...enhancementDays]

  let val = toNumberWithMax2DecimalPlaces(hours)
  if (val > 24) val = 24
  if (val < 0) val = 0
  const updatedDay = enhancementDays.find(day => day.day!.getTime() === selecteday.getTime())
  if (updatedDay) {
    updatedDay.hours = val
    if (!(Number(hours) > 0)) {
      // eslint-disable-next-line no-param-reassign
      updatedDay.checked = false
    }
  }

  return updatedDays
}

export const handleAbsenceHoursChange = (
  hours: string,
  selectedDay: Date,
  selectedDays: AbsenceDayRequest[]
): AbsenceDayRequest[] => {
  if (selectedDays) {
    const updatedDay = selectedDays.find(day => day.day!.getTime() === selectedDay.getTime())
    if (updatedDay) {
      let hoursToFloat = parseFloat(hours)
      if (hoursToFloat < 0) {
        hoursToFloat = 0.0
      }
      if (hoursToFloat > 24) {
        hoursToFloat = 24.0
      }
      updatedDay.hours = hoursToFloat
    }
  }
  return selectedDays
}
