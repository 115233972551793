import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Security } from '@okta/okta-react'
import oktaConfig from './oktaConfig'

type OktaSSOProps = {
  children: React.ReactNode
}

export const oktaAuth = new OktaAuth(oktaConfig.oidc)

function OktaSSO({ children }: OktaSSOProps) {
  const navigate = useNavigate()

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  useEffect(
    () => () => {
      // return restoreOriginalUri to undefined on unmount
      oktaAuth.options.restoreOriginalUri = undefined
    },
    []
  )

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  )
}

export default OktaSSO
