import { Grid, Stack } from '@mui/material'
import { useState } from 'react'
import Card from '../../shared/layout/Card'
import { SummaryProps } from './types'
import Paragraph from '../../shared/UI/Paragraph'
import NoDataFound from '../../shared/UI/NoDataFound'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'

export type SummaryPropsLocal = {
  summary: Array<SummaryProps>
  isLoading: boolean
  isData: boolean
}
const singlePane = (item: any) => (
  <>
    {item.value !== undefined && (
      <>
        {!item.title.includes('Comments') ? (
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Paragraph weight="bold">{item.title}</Paragraph>
            <Paragraph>{item.value}</Paragraph>
          </Stack>
        ) : (
          <Stack direction="column" justifyContent="flex-start" mt={2}>
            <Paragraph weight="bold">{item.title}</Paragraph>
            <Paragraph style={{ marginTop: '8px' }}>{item.value}</Paragraph>
          </Stack>
        )}
      </>
    )}
  </>
)

function SummaryLayout({ summary, isLoading, isData }: SummaryPropsLocal) {
  return (
    <Card title="Summary">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {!isLoading ? (
            <>{!isData ? <NoDataFound show /> : summary?.map(item => singlePane(item))}</>
          ) : (
            <>
              <LoadingIndicator show />
            </>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

export default SummaryLayout
