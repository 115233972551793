import { httpServiceV1 } from './httpServiceV1'
import { AttendanceSearch } from '../types/attendance-search'
import { HolidaySearch } from '../types/holiday-search'
import { AttendanceHistoryByDateResponse } from '../types/attendance-history-by-date-response'

const requestsBaseURL = 'Reporting'

const myReportingService = {
  getMyReports: async (
    includeAbsences: boolean,
    includeLates: boolean,
    dateFrom: string,
    dateTo: string,
    absenceReason: number,
    absenceStatus: number,
    lateReason: number,
    lateStatus: number,
    employeeId: number,
    departmentId: number
  ): Promise<AttendanceSearch> => {
    let url = `${requestsBaseURL}/attendanceSearch?`

    url = `${url}&IncludeAbsences=${includeAbsences}`
    url = `${url}&IncludeLates=${includeLates}`
    url = `${url}&DateFrom=${dateFrom}`
    url = `${url}&DateTo=${dateTo}`
    url = `${url}&AbsenceReason=${absenceReason}`
    url = `${url}&AbsenceStatus=${absenceStatus}`
    url = `${url}&LateReason=${lateReason}`
    url = `${url}&LateStatus=${lateStatus}`
    url = `${url}&EmployeeId=${employeeId}`
    url = `${url}&DepartmentId=${departmentId}`

    return httpServiceV1.get(url)
  },
  getHolidaySearchReports: async (
    departmentId: number,
    teamId: number,
    employeeFilterId: number,
    actionedById: number,
    dateFrom: string,
    dateTo: string,
    submittedFrom: string,
    submittedTo: string,
    requestTypeId: number,
    manualTypeId: number,
    requestStatusId: number
  ): Promise<HolidaySearch> => {
    let url = `${requestsBaseURL}/HolidaySearch?`

    url += departmentId !== 0 ? `DepartmentId=${departmentId}&` : ''
    url += teamId !== 0 ? `TeamId=${teamId}&` : ''
    url += employeeFilterId !== 0 ? `EmployeeFilterId=${employeeFilterId}&` : ''
    url += actionedById !== 0 ? `ActionedById=${actionedById}&` : ''
    url += `DateFrom=${dateFrom}&`
    url += `DateTo=${dateTo}&`
    url += submittedFrom !== '' ? `SubmittedFrom=${submittedFrom}&` : ''
    url += submittedTo !== '' ? `SubmittedTo=${submittedTo}&` : ''
    url += requestTypeId !== 0 ? `RequestTypeId=${requestTypeId}&` : ''
    url += manualTypeId !== 0 ? `ManualTypeId=${manualTypeId}&` : ''
    url += requestStatusId !== 0 ? `RequestStatusId=${requestStatusId}&` : ''

    url = url.substring(0, url.length - 1)

    return httpServiceV1.get(url)
  },
  getAttendanceHistory: async (
    dateFrom: string,
    dateTo: string
  ): Promise<AttendanceHistoryByDateResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/AttendanceHistory?datefrom=${dateFrom}&dateto=${dateTo}`),
}

export { myReportingService }
