import { Box, Stack } from '@mui/material'
import Card from '../../../shared/layout/Card'
import Paragraph from '../../../shared/UI/Paragraph'
import { BookingGeneralDesksInfoProps } from './types'

export function BookingGeneralDesksInfo({
  backgroundColor,
  icon,
  header,
  label,
}: BookingGeneralDesksInfoProps) {
  return (
    <Card
      style={{
        boxShadow: 'none',
        backgroundColor,
        padding: 0,
        height: 180,
      }}
    >
      <Stack direction="column" justifyContent="center" alignItems="center" height="100%" gap={2}>
        {icon}
        <Stack direction="column" justifyContent="center" alignItems="center" textAlign="center">
          <Paragraph size="13px" color="#2C2965" weight="bold">
            {header}
          </Paragraph>
          <Paragraph size="35px" color="#2C2965" weight="bold">
            {label}
          </Paragraph>
        </Stack>
      </Stack>
    </Card>
  )
}
