import { DrawerButtonStack } from './components'

interface Props {
  children: any
}

function DraweButtonContainer({ children }: Props) {
  return (
    <DrawerButtonStack className="drawerFooterButtonStack" gap={4}>
      {children}
    </DrawerButtonStack>
  )
}

export default DraweButtonContainer
