import { GridSize, PopperPlacementType } from '@mui/material'
import { RequestDataType } from '../../../types/request-data-type'

// eslint-disable-next-line no-shadow
export enum EventTypes {
  ABSENCE = 'Absence',
  HOLIDAY = 'Holiday',
  LATE = 'Late',
  COURSE = 'Course',
  LIEU = 'Lieu',
  WORKFROMHOME = 'Work From Home',
  BIRTHDAY = 'Birthday',
  OTHER = 'Other',
  UNKNOWN = 'Unknown',
  HIDDEN = '',
}

export interface PopupFields {
  open: boolean
  id: number
  eventType: EventTypes | string
  requestType?: string
  hours: number
  anchorEl?: HTMLElement | undefined
  placement?: PopperPlacementType
  eventStatus?: string
  isCancellation?: boolean
  disableEdit?: boolean
}

export interface PopupFieldsProps {
  fields: PopupFields[]
  baseUrl: string
  onClose?: () => void
  onDrawerOpen?: (
    id: number,
    absenceType: string,
    eventStatus: string | undefined,
    requestType?: string | undefined
  ) => void
  newRequestData?: RequestDataType[]
  isAttendanceCalendar?: boolean
  isComingUp?: boolean
}

export const popupFieldsDefault = {
  open: false,
  id: 0,
  eventType: EventTypes.ABSENCE,
  hours: 0,
  dateFrom: '',
  dateTo: '',
}

export interface EventPopupProps extends Partial<PopupFields> {
  children: React.ReactNode
  container?: boolean
  item?: boolean
  xs?: GridSize
  width?: string
  multiEvent?: boolean
}
