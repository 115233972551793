import Card from '../../shared/layout/Card'
import PageHeader from '../../shared/layout/PageHeader/PageHeader'
import HolidayReportsTabs from './HolidayReportsTabs'

function Holiday() {
  return (
    <>
      <PageHeader title="Reporting" />
      <Card title="Holiday">
        <HolidayReportsTabs />
      </Card>
    </>
  )
}

export default Holiday
