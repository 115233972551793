import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { addDays, endOfISOWeek, endOfMonth, format, setYear, subDays, startOfMonth } from 'date-fns'
import {
  setWallChartSelected,
  setWallChartSelectable,
  setSelectedTimespan,
  setLastSelectedMonthTimespan,
  setLastSelectedWeekTimespan,
  timeSpanInitialState,
  setLastSelectedDayTimespan,
} from '../../../redux/reducers/wallChartReducer'
import { RootStore, useAppDispatch } from '../../../redux/store'
import {
  DepartmentValuesResponse,
  Employee,
  SelectOption,
  Team,
} from '../../../services/dashboardService'
import AutocompleteList from '../../../shared/UI/AutocompleteList'
import {
  getYearSelectOptions,
  getFilteredDepartmentsDropdownOptions,
  YearRequest,
} from '../../../utils/app-utils'
import {
  HSS_YEARS_IN_THE_PAST,
  HTL_YEARS_IN_THE_PAST,
  WEEKDAYS,
  HSS_YEARS_IN_THE_FUTURE,
  HTL_YEARS_IN_THE_FUTURE,
  MONTHS,
} from '../../../utils/constants'
import { WallChartFiltersProps } from './types'
import { setSelectedDepartment } from '../../../redux/reducers/appSettingsReducer'
import { WallChartTimespans } from '../WallChartTimespanSelector/types'
import RefreshButton from '../../../shared/UI/RefreshButton'

export function WallChartFilters({ directReportEnabled }: WallChartFiltersProps) {
  const [localDepartments, setLocalDepartments] = useState<SelectOption[]>([])
  const [teamsDisabled, setTeamsDisabled] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const { selectableParams, selectedParams, selectedTimeSpan } = useSelector(
    (state: RootStore) => state.wallChart
  )

  const {
    departmentTeams,
    calendarDetailsResponse,
    currentEntitlementPeriodResponse,
    directReports,
    allEmployees,
    departments,
    allTeams,
    loggedInEmployeeTeam,
  } = useSelector((state: RootStore) => state.appSettings)

  const userDepartment = useSelector<RootStore, SelectOption | null>(
    (state: RootStore) => state.appSettings.loggedInEmployeeDepartment
  )

  const departmentValuesResponse = useSelector<RootStore, DepartmentValuesResponse | undefined>(
    (state: RootStore) => state.appSettings.departmentValuesResponse
  )

  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  const isManager = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  ).includes('ViewWhoAwayManager')

  useEffect(() => {
    setLocalDepartments(getFilteredDepartmentsDropdownOptions(departments, allEmployees))
  }, [allEmployees, departmentValuesResponse, departments, userPermissions])

  const filterEmployeeList = useCallback(
    (department: SelectOption | null, teams?: Team[] | null) => {
      const departmentEmployees: Employee[] = []
      const teamsEmployees: Employee[] = []
      const employees = !isManager ? allEmployees : directReports
      if (department) {
        if (department?.value === 0) {
          // All departments are selected, so need to iterate through all and ignore teams selections
          employees.forEach(e =>
            departmentEmployees.push({
              employeeId: e.employeeId,
              employeeName: e.employeeName,
              departmentId: e.departmentId,
              teamId: 0,
            })
          )
        } else {
          if (!teams?.length) {
            // No teams are selected, so get employees from selected department
            const emps = employees.filter(e => e.departmentId === department.value)
            emps.forEach(e => {
              departmentEmployees.push({
                employeeId: e.employeeId,
                employeeName: e.employeeName,
                departmentId: e.departmentId,
                teamId: 0,
              })
            })
          }
          let filteredTeams
          if (teams?.length) {
            // Teams are selected, so filter the teams from the department then select teams > employees
            filteredTeams = allTeams.filter(t => teams.some(s => s.teamId === t.teamId))
          } else {
            // No teams are selected, so just search the department's teams > employees
            filteredTeams = allTeams.filter(t => t.departmentId === department.value)
          }
          if (filteredTeams) {
            const uniqueTeams = Array.from(
              new Set<number>(filteredTeams.map(t => t.teamId)).values()
            )
            employees.forEach(e => {
              if (uniqueTeams.includes(e.teamId)) {
                teamsEmployees.push({
                  employeeId: e.employeeId,
                  employeeName: e.employeeName,
                  departmentId: department.value,
                  teamId: 0,
                })
              }
            })
          }
        }
      }

      const uniqueEmployees = Array.from(
        new Set<number>(
          departmentEmployees.concat(teamsEmployees).map(emp => emp.employeeId)
        ).values()
      )

      dispatch(
        setWallChartSelectable({
          ...selectableParams,
          availableEmployees: employees
            .filter(emp => uniqueEmployees.includes(emp.employeeId))
            .sort((a, b) => (a.employeeName.toLowerCase() > b.employeeName.toLowerCase() ? 0 : -1)),
        })
      )
    },
    [allEmployees, allTeams, directReports, dispatch, isManager, selectableParams]
  )

  const handleDepartmentChange = useCallback(
    (event: unknown, department: SelectOption | null) => {
      if (department === selectedParams.selectedDepartment) {
        return
      }
      setTeamsDisabled(department?.value === 0)
      dispatch(setSelectedDepartment(department))
      dispatch(
        setWallChartSelected({
          ...selectedParams,
          selectedDepartment: department,
          selectedTeams: [],
          selectedEmployees: [],
        })
      )
      filterEmployeeList(department)
    },
    [dispatch, filterEmployeeList, selectedParams]
  )

  const handleTeamChange = (event: unknown, teams: Team[] | null) => {
    if (teams === selectedParams.selectedTeams) {
      return
    }
    dispatch(setWallChartSelected({ ...selectedParams, selectedTeams: teams || [] }))
    filterEmployeeList(selectedParams.selectedDepartment, teams)
  }

  const handleEmployeesChange = (event: unknown, chosenEmployees: Employee[] | null) => {
    dispatch(setWallChartSelected({ ...selectedParams, selectedEmployees: chosenEmployees || [] }))
  }

  useEffect(() => {
    const toSelect =
      localDepartments.length === 1
        ? localDepartments[0]
        : localDepartments.find(d => d.displayValue === 'All') ?? null
    handleDepartmentChange(null, toSelect)

    if (!isManager && loggedInEmployeeTeam !== null) {
      setTeamsDisabled(true)
      handleTeamChange(null, [loggedInEmployeeTeam])
    }
  }, [localDepartments])

  const ensurePreviousMonday = (date: Date) => {
    let localDate = new Date(date)
    localDate = localDate.getDay() === 0 ? subDays(localDate, 1) : localDate
    while (localDate.getDay() > 1) {
      localDate = subDays(localDate, 1)
    }
    return localDate
  }

  const checkEndDateWithinPermittedRange = (endDate: Date) => {
    if (!calendarDetailsResponse) {
      return { endDate }
    }

    const lastBookableDate = new Date(
      calendarDetailsResponse.calendarDetails[
        calendarDetailsResponse.calendarDetails.length - 1
      ].endDate
    )

    if (new Date(endDate) > new Date(lastBookableDate)) {
      switch (selectedTimeSpan.rangeType) {
        case WallChartTimespans.DAY:
          return {
            startDate: lastBookableDate,
            endDate: lastBookableDate,
            month: MONTHS[new Date(lastBookableDate).getDate()],
          }
        case WallChartTimespans.WEEK:
          return {
            startDate: ensurePreviousMonday(lastBookableDate),
            endDate: endOfISOWeek(lastBookableDate),
            month: WEEKDAYS[lastBookableDate.getDay()],
          }
        case WallChartTimespans.TWOWEEK:
          return {
            startDate: ensurePreviousMonday(lastBookableDate),
            endDate: addDays(ensurePreviousMonday(lastBookableDate), 14),
            month: WEEKDAYS[lastBookableDate.getDay()],
          }
        case WallChartTimespans.MONTH:
        default:
          return {
            startDate: startOfMonth(new Date(format(new Date(lastBookableDate), 'yyyy-MM-dd'))),
            endDate: endOfMonth(new Date(lastBookableDate)),
            month: MONTHS[lastBookableDate.getMonth()],
          }
      }
    }

    return { endDate }
  }

  const checkStartDateWithinPermittedRange = (startDate: Date) => {
    if (!calendarDetailsResponse) {
      return { startDate }
    }

    const firstBookableDate = new Date(calendarDetailsResponse.calendarDetails[0].startDate)

    if (new Date(startDate) < new Date(firstBookableDate)) {
      switch (selectedTimeSpan.rangeType) {
        case WallChartTimespans.DAY:
          return {
            startDate: firstBookableDate,
            endDate: firstBookableDate,
            month: MONTHS[new Date(firstBookableDate).getDate()],
          }
        case WallChartTimespans.WEEK:
          return {
            startDate: ensurePreviousMonday(firstBookableDate),
            endDate: endOfISOWeek(firstBookableDate),
            month: WEEKDAYS[firstBookableDate.getDay()],
          }
        case WallChartTimespans.TWOWEEK:
          return {
            startDate: ensurePreviousMonday(firstBookableDate),
            endDate: addDays(ensurePreviousMonday(firstBookableDate), 14),
            month: WEEKDAYS[firstBookableDate.getDay()],
          }
        case WallChartTimespans.MONTH:
        default:
          return {
            startDate: startOfMonth(new Date(format(new Date(firstBookableDate), 'yyyy-MM-dd'))),
            endDate: endOfMonth(new Date(firstBookableDate)),
            month: MONTHS[firstBookableDate.getMonth()],
          }
      }
    }

    return { startDate }
  }

  const handleYearChange = (event: unknown, year: SelectOption | null) => {
    if (!calendarDetailsResponse) {
      return
    }
    const yearDiff = Number(selectedParams?.selectedYear?.value) !== Number(year?.value)
    dispatch(setWallChartSelected({ ...selectedParams, selectedYear: year }))
    if (yearDiff) {
      let startDate = setYear(selectedTimeSpan.startDate, Number(year?.value))
      let endDate
      let dayOfWeek = selectedTimeSpan.title
      switch (selectedTimeSpan.rangeType) {
        case WallChartTimespans.DAY: {
          endDate = startDate
          dayOfWeek = WEEKDAYS[startDate.getDay()]
          break
        }
        case WallChartTimespans.WEEK: {
          startDate = ensurePreviousMonday(startDate)
          endDate = endOfISOWeek(startDate)
          dayOfWeek = WEEKDAYS[startDate.getDay()]
          break
        }
        case WallChartTimespans.TWOWEEK: {
          startDate = ensurePreviousMonday(startDate)
          endDate = addDays(startDate, 14)
          break
        }
        case WallChartTimespans.MONTH:
        default:
          endDate = endOfMonth(startDate)
          break
      }

      const lastDayCheck = checkEndDateWithinPermittedRange(endDate)
      const firstDayCheck = checkStartDateWithinPermittedRange(startDate)

      if (
        lastDayCheck.endDate.getFullYear() ===
        new Date(
          calendarDetailsResponse.calendarDetails[
            calendarDetailsResponse.calendarDetails.length - 1
          ].endDate
        ).getFullYear()
      ) {
        dispatch(
          setSelectedTimespan({
            ...selectedTimeSpan,
            startDate: lastDayCheck.startDate || startDate,
            endDate: lastDayCheck.endDate,
            title: lastDayCheck.month || dayOfWeek,
          })
        )
      } else {
        dispatch(
          setSelectedTimespan({
            ...selectedTimeSpan,
            startDate: firstDayCheck.startDate,
            endDate: firstDayCheck.endDate || endDate,
            title: firstDayCheck.month || dayOfWeek,
          })
        )
      }
    }

    dispatch(setLastSelectedDayTimespan(undefined))
    dispatch(setLastSelectedWeekTimespan(undefined))
    dispatch(setLastSelectedMonthTimespan(undefined))
  }

  useEffect(() => {
    if (!userDepartment || userPermissions.includes('ViewManagerDirectReport')) {
      return
    }
    handleDepartmentChange(null, userDepartment)
  }, [userDepartment])

  const getYears = () => {
    if (
      !selectedParams.selectedDepartment ||
      calendarDetailsResponse === undefined ||
      !currentEntitlementPeriodResponse
    ) {
      return []
    }

    const yearRequest: YearRequest = {
      entitlementPeriod: 'CalendarYear',
      yearsInPast:
        calendarDetailsResponse.calendarDetails.length > 4
          ? HTL_YEARS_IN_THE_PAST
          : HSS_YEARS_IN_THE_PAST,
      yearsInFuture:
        calendarDetailsResponse.calendarDetails.length > 4
          ? HTL_YEARS_IN_THE_FUTURE
          : HSS_YEARS_IN_THE_FUTURE,
    }

    return getYearSelectOptions(yearRequest, currentEntitlementPeriodResponse.entitlementPeriod)
  }

  const resetWallChartFilters = () => {
    if (userDepartment) {
      dispatch(
        setWallChartSelected({
          ...selectedParams,
          selectedDepartment: selectedParams.selectedDepartment,
          selectedTeams: [],
          selectedEmployees: [],
          selectedYear: {
            displayValue: String(new Date().getFullYear()),
            value: new Date().getFullYear(),
          },
        })
      )
    }
    dispatch(setSelectedTimespan(timeSpanInitialState))
    if (userPermissions.includes('ViewManagerDirectReport')) {
      const all = localDepartments.find(d => d.displayValue === 'All')
      if (all) {
        handleDepartmentChange(null, all)
      }
    }
  }

  return (
    <Grid container spacing={3}>
      {userPermissions.includes('ViewWallChartDepartments') && (
        <Grid item xs={12} md={3}>
          <AutocompleteList
            id="departments"
            label="Department"
            data={localDepartments}
            textField="displayValue"
            disabled={!directReportEnabled || localDepartments.length === 1}
            onChange={handleDepartmentChange}
            value={selectedParams.selectedDepartment}
            dataTestId="WallChart-DepartmentDDL"
          />
        </Grid>
      )}
      <Grid item xs={12} md={3}>
        <AutocompleteList
          id="teams"
          label="Team"
          data={departmentTeams}
          textField="teamName"
          disabled={!directReportEnabled || teamsDisabled}
          onChange={handleTeamChange}
          multiple
          value={selectedParams.selectedTeams}
          limitTags={1}
          dataTestId="WallChart-TeamsDDL"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <AutocompleteList
          multiple
          id="employees"
          label="Employee Search"
          data={selectableParams.availableEmployees}
          textField="employeeName"
          disabled={!directReportEnabled}
          onChange={handleEmployeesChange}
          value={selectedParams.selectedEmployees}
          limitTags={1}
          dataTestId="WallChart-EmployeeDDL"
        />
      </Grid>
      <Grid item xs={12} md={userPermissions.includes('ViewWallChartDepartments') ? 2 : 3}>
        <AutocompleteList
          id="years"
          label="Year"
          disableClearable
          data={getYears()}
          textField="displayValue"
          disabled={!directReportEnabled}
          onChange={handleYearChange}
          value={selectedParams.selectedYear}
          dataTestId="WallChart-YearDDL"
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={userPermissions.includes('ViewWallChartDepartments') ? 1 : 3}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <RefreshButton onClick={resetWallChartFilters} dataTestId="WallChart-Clear" />
      </Grid>
    </Grid>
  )
}
