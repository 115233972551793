import { applicationGuids, notificationEventTypes, platformTypes, templateTypes } from './models'

const unrecognised = 'Unrecognised'

export const getApplicationNameFrom = (guid: string): string =>
  applicationGuids[guid] ?? unrecognised

export const getTemplateTypeNameFrom = (id: string): string => templateTypes[id] ?? unrecognised

export const getPlatformTypeNameFrom = (id: string): string => platformTypes[id] ?? unrecognised

export const getEventTypeNameFrom = (id: string): string =>
  notificationEventTypes[id] ?? unrecognised
