import { useState, useEffect } from 'react'
import { Divider, Stack } from '@mui/material'
import MySummaryEntitlementSplit from '../../../shared/UI/MySummaryEntitlementSplit/MySummaryEntitlementSplit'
import { EntitlementSplit } from '../../../types/entitlement-split'
import {
  DashBoardMySummaryCard,
  MySummaryFooterBg,
  MySummaryFooterBgContainer,
  MySummaryFooterImg,
} from './components'
import mySummaryDesktopOverlay from '../../../assets/mySummaryDesktopOverlay.svg'
import mySummaryDashboardBG from '../../../assets/DashboardMySummaryBG.svg'
import FullComponentLoadingIcon from '../../../shared/UI/LoadingIndicator/FullComponentLoadingIcon'
import CardTitle from '../../../shared/UI/CardTitle'
import { MyEntitlementResponse } from '../../../types/my-entitlement-response'

type Props = {
  myEntitlement?: MyEntitlementResponse | undefined
  isLoadingEntitlement: boolean
}

function DashboardMySummary({ myEntitlement, isLoadingEntitlement }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [entitlementSplit, setEntitlementSplit] = useState<EntitlementSplit>()

  useEffect(() => {
    if (!isLoadingEntitlement && myEntitlement) {
      setEntitlementSplit({
        general: {
          hours: myEntitlement.totalHours,
          summerHours: myEntitlement.totalSummerHours,
          winterHours: myEntitlement.totalWinterHours,
        },
        remaining: {
          hours: myEntitlement.remainingHours,
          summerHours: myEntitlement.remainingSummerHours,
          winterHours: myEntitlement.remainingWinterHours,
        },
        requested: {
          hours: myEntitlement.requestedHours,
          summerHours: myEntitlement.requestedSummerHours,
          winterHours: myEntitlement.requestedWinterHours,
        },
        changeRequests: {
          hours: myEntitlement.changeRequestHours,
          summerHours: myEntitlement.changeRequestHoursSummer,
          winterHours: myEntitlement.changeRequestHoursWinter,
        },
        ifApproved: {
          hours: myEntitlement.ifApprovedHours,
          summerHours: myEntitlement.ifApprovedHoursSummer,
          winterHours: myEntitlement.ifApprovedHoursWinter,
        },
      })
      setIsLoading(false)
    }
  }, [isLoadingEntitlement, myEntitlement])

  return (
    <>
      <DashBoardMySummaryCard>
        <Stack direction="row" justifyContent="flex-start" gap={2} alignItems="center">
          <CardTitle title="My Summary" />
        </Stack>
        <Divider />
        <>
          <FullComponentLoadingIcon
            loading={isLoading}
            loadingHeight="285px"
            bgColor="transparent"
            sx={{
              '& .MuiCircularProgress-root': {
                marginBottom: '80px',
              },
            }}
          >
            <>
              {entitlementSplit && (
                <MySummaryEntitlementSplit
                  summaryItems={entitlementSplit}
                  marginTop={0}
                  titleMarginBottom={2}
                  marginBottom={14}
                />
              )}
            </>
          </FullComponentLoadingIcon>
          <MySummaryFooterBgContainer>
            <MySummaryFooterBg src={mySummaryDashboardBG} alt="my summary" />
          </MySummaryFooterBgContainer>
        </>
      </DashBoardMySummaryCard>

      <MySummaryFooterImg src={mySummaryDesktopOverlay} alt="my summary" />
    </>
  )
}

export default DashboardMySummary
