import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  CalendarPicker,
  CalendarPickerProps,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers-pro'
import { getPalleteTypeByProp } from '../../theme/palette'
import theme from '../../theme/theme'
import { ColourSchemeProps } from '../LegendsFooter/types'
import { CustomPickerDayProps } from './types'
import { isDesktopUp, isMobileDown } from '../../theme/deviceChecks'

export const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  height: '100%',
})

const colorScheme = (eventType: string): ColourSchemeProps => {
  const palletObject = getPalleteTypeByProp(eventType.toLowerCase())
  if (!palletObject) {
    return { backgroundColour: 'lightgray', fontColour: 'gray' }
  }
  return { backgroundColour: palletObject.color, fontColour: palletObject.fontColor }
}

export const CustomCalendarPicker = styled(CalendarPicker, {
  shouldForwardProp: prop => prop !== '',
})<CalendarPickerProps<Date>>(() => ({
  ...{
    '&.MuiCalendarPicker-root': {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    '.isComingUpStyles &.MuiCalendarPicker-root': {
      maxHeight: 'unset',
    },
    '&.MuiCalendarPicker-root > div:nth-of-type(1)': {
      display: 'none',
    },
    '&.MuiCalendarPicker-root .MuiCalendarPicker-viewTransitionContainer': {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    '&.MuiCalendarPicker-root .MuiCalendarPicker-viewTransitionContainer > div': {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    '.isComingUpStyles &.MuiCalendarPicker-root .MuiCalendarPicker-viewTransitionContainer > div': {
      overflow: 'hidden',
    },
    '&.MuiCalendarPicker-root .MuiCalendarPicker-viewTransitionContainer > div > div:not(.PrivatePickersSlideTransition-root)':
      {
        position: 'absolute',
        width: '85%',
        left: '7.5%',
        display: 'block',
        height: '11%',
      },
    '.isComingUpStyles &.MuiCalendarPicker-root .MuiCalendarPicker-viewTransitionContainer > div > div:not(.PrivatePickersSlideTransition-root)':
      {
        width: '100%',
        left: 0,
        top: '10%',
        height: '8.05%',
      },
    '&.MuiCalendarPicker-root div.PrivatePickersSlideTransition-root': {
      position: 'absolute',
      width: '100%',
      height: '89%',
      bottom: '0',
      minHeight: 'unset',
    },
    '.isComingUpStyles &.MuiCalendarPicker-root div.PrivatePickersSlideTransition-root': {
      height: '81.95%',
    },
    '&.MuiCalendarPicker-root div.PrivatePickersSlideTransition-root > div': {
      width: '85%',
      height: '100%',
      left: '7.5%',
    },
    '.isComingUpStyles &.MuiCalendarPicker-root div.PrivatePickersSlideTransition-root > div': {
      width: '100%',
      bottom: 0,
      left: 0,
    },
    '&.MuiCalendarPicker-root div.PrivatePickersSlideTransition-root div div': {
      height: '14.52%',
      display: 'flex',
      margin: 0,
    },
    '.isComingUpStyles &.MuiCalendarPicker-root div.PrivatePickersSlideTransition-root div div': {
      height: '16.27%',
    },
    '&.MuiCalendarPicker-root div.PrivatePickersSlideTransition-root .MuiPickersDay-root': {
      width: '14.285%',
      height: '100%',
      margin: 0,
    },
    '&.MuiCalendarPicker-root div.PrivatePickersFadeTransitionGroup-root div div span': {
      color: '#a7a7a7',
      height: '100%',
      display: 'inline-block',
      margin: 0,
      fontSize: '13px',
    },
    '&.MuiCalendarPicker-root div.PrivatePickersFadeTransitionGroup-root div div span.MuiTypography-root':
      {
        width: '14.285%',
      },
    '&.MuiSvgIcon-root': {
      display: 'none',
      disabled: true,
    },

    [isMobileDown()]: {
      '&.MuiCalendarPicker-root div.PrivatePickersFadeTransitionGroup-root div div span': {
        fontSize: '2.6vw',
      },
    },
    [theme.breakpoints.between('md', 'xl')]: {
      '&.MuiCalendarPicker-root div.PrivatePickersFadeTransitionGroup-root div div span': {
        fontSize: '0.9vw',
      },
    },
    [isDesktopUp()]: {
      '&.MuiCalendarPicker-root div.PrivatePickersFadeTransitionGroup-root div div span': {
        fontSize: '0.68vw',
      },
    },
  },
})) as React.ComponentType<CalendarPickerProps<Date>>

const psuedoStyles = {
  content: "''",
  fontSize: 0,
  position: 'absolute',
  height: '80%',
  top: '10%',
}

export const StandardPickersDay = styled(PickersDay, {
  shouldForwardProp: prop => prop !== '',
})<PickersDayProps<Date>>(({ disabled }) => ({
  ...{
    '&.MuiButtonBase-root': {
      width: '14.285%',
      height: '100%',
      margin: '0',
      color: disabled ? '#d1d1d1' : '#707070',
      zIndex: '1',
      fontSize: '13px',
    },
    '&.MuiPickersDay-root:hover': {
      '&.MuiPickersDay-root:before': {
        ...psuedoStyles,
        width: '80%',
        left: '10%',
        borderRadius: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '&.MuiPickersDay-root:not(.Mui-selected)': {
      '&.MuiPickersDay-today:before': {
        ...psuedoStyles,
        width: '80%',
        left: '10%',
        borderRadius: '100%',
        border: '1px solid rgba(32, 197, 160, 0.3)',
        boxSizing: 'border-box',
      },
    },
    '&.MuiPickersDay-today:before': {
      ...psuedoStyles,
      width: '80%',
      left: '10%',
      borderRadius: '100%',
      backgroundColor: 'rgba(32, 197, 160, .3)',
      borderColor: 'rgba(32, 197, 160, .3)',
    },
    [isMobileDown()]: {
      '&.MuiButtonBase-root': {
        fontSize: '2.6vw',
      },
    },
    [theme.breakpoints.between('md', 'xl')]: {
      '&.MuiButtonBase-root': {
        fontSize: '0.9vw',
      },
    },
    [isDesktopUp()]: {
      '&.MuiButtonBase-root': {
        fontSize: '0.68vw',
      },
    },
  },
})) as React.ComponentType<PickersDayProps<Date>>

export const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: prop =>
    prop !== 'dayIsBetween' &&
    prop !== 'isFirstDay' &&
    prop !== 'isLastDay' &&
    prop !== 'eventType' &&
    prop !== 'isComingUp',
})<CustomPickerDayProps>(
  ({ dayIsBetween, isFirstDay, isLastDay, eventType, hasCoincidingDates, isComingUp }) => ({
    ...{ zIndex: 120 },
    ...{
      '&:hover, &:focus': {
        fontWeight: 'bold',
        backgroundColor: 'transparent',
      },
      '&.Mui-selected:before': {
        ...psuedoStyles,
        backgroundColor: hasCoincidingDates ? '#b9b9b9' : colorScheme(eventType!).backgroundColour,
      },
      '&.Mui-selected.MuiPickersDay-root': {
        backgroundColor: 'transparent',
        color: '#fff',
      },
      '&.MuiPickersDay-root': {
        width: 'auto',
        height: 'auto',
      },
      '&.MuiButtonBase-root': {
        width: '14.285%',
        height: '100%',
        margin: '0',
        fontSize: '13px',
      },
      [isMobileDown()]: {
        '&.MuiButtonBase-root': {
          fontSize: '2.6vw',
        },
      },
      [theme.breakpoints.between('md', 'xl')]: {
        '&.MuiButtonBase-root': {
          fontSize: '0.9vw',
        },
      },
      [isDesktopUp()]: {
        '&.MuiButtonBase-root': {
          fontSize: '0.68vw',
        },
      },
    },
    ...(dayIsBetween && {
      color: theme.palette.common.white,
      '&:before': {
        ...psuedoStyles,
        width: '100%',
        left: 0,
        zIndex: '-1',
        borderRadius: 0,
        backgroundColor: hasCoincidingDates ? '#b9b9b9' : colorScheme(eventType!).backgroundColour,
      },
    }),
    ...(isFirstDay && {
      '&:before': {
        ...psuedoStyles,
        borderTopLeftRadius: hasCoincidingDates ? 0 : '100%',
        borderBottomLeftRadius: hasCoincidingDates ? 0 : '100%',
        width: hasCoincidingDates ? '100%' : '90%',
        left: hasCoincidingDates ? '0' : '10%',
        backgroundColor: hasCoincidingDates ? '#b9b9b9' : colorScheme(eventType!).backgroundColour,
        zIndex: '-1',
      },
    }),
    ...(isLastDay && {
      '&:before': {
        ...psuedoStyles,
        borderTopRightRadius: hasCoincidingDates ? 0 : '100%',
        borderBottomRightRadius: hasCoincidingDates ? 0 : '100%',
        width: hasCoincidingDates ? '100%' : '90%',
        left: 0,
        backgroundColor: hasCoincidingDates ? '#b9b9b9' : colorScheme(eventType!).backgroundColour,
        zIndex: '-1',
      },
    }),
    ...(isFirstDay &&
      isLastDay && {
        '&:before': {
          ...psuedoStyles,
          borderRadius: hasCoincidingDates ? 0 : '100%',
          width: hasCoincidingDates ? '100%' : '80%',
          left: hasCoincidingDates ? 0 : '10%',
          backgroundColor: hasCoincidingDates
            ? '#b9b9b9'
            : colorScheme(eventType!).backgroundColour,
          zIndex: '-1',
        },
      }),
    '&.selectedDate_pending:before': {
      backgroundImage:
        'linear-gradient(135deg, transparent  37.50%, rgb(68 68 68 / 30%) 37.50%, rgb(68 68 68 / 30%) 50%, transparent  50%, transparent  87.50%, rgb(68 68 68 / 30%) 87.50%, rgb(68 68 68 / 30%) 100%)',
      backgroundSize: '10px 10px',
    },
  })
) as React.ComponentType<CustomPickerDayProps>

export const CalendarLabel = styled('span')(() => ({
  position: 'relative',
  top: '8%',
  left: '11.4%',
  zIndex: '110',
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '13px',

  [isMobileDown()]: {
    fontSize: '2.6vw',
    '.isComingUpStyles &': {
      fontSize: '2.6vw',
      lineHeight: '2.4vw',
    },
    '.isComingUpStyles & svg': {
      height: '2.6vw',
    },
  },
  [theme.breakpoints.between('md', 'xl')]: {
    fontSize: '0.9vw',
    '.isComingUpStyles &': {
      fontSize: '1.2vw',
      lineHeight: '1vw',
    },
    '.isComingUpStyles & svg': {
      height: '1.2vw',
    },
  },
  [isDesktopUp()]: {
    fontSize: '0.68vw',
    '.isComingUpStyles &': {
      fontSize: '18px',
      lineHeight: '15px',
    },
    '.isComingUpStyles & svg': {
      height: '16px',
    },
  },

  '.isComingUpStyles &': {
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'flex-end',
    color: '#000',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '15px',
  },

  '.isComingUpStyles & svg': {
    width: 'auto',
    marginRight: '10px',
    height: '16px',
  },
}))

export const CalendarBox = styled(Box)(() => ({
  position: 'absolute',
  width: '100%',
  bottom: 0,
  height: '80%',
  '.isComingUpStyles &': {
    height: '100%',
  },
}))
