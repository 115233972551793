import { AllFeatureTogglesResponse } from '../types/all-feature-toggles-response'
import { BaseResponse } from '../types/base-response'
import { FeatureToggleUpdateRequest } from '../types/feature-toggle-update-request'
import { httpServiceV1 } from './httpServiceV1'

const featureToggleBaseUrl = 'featureToggle'

const featureToggleService = {
  getAllFeatureToggles: async (): Promise<AllFeatureTogglesResponse> =>
    httpServiceV1.get(`${featureToggleBaseUrl}/GetAll`),
  updateFeatures: async (featuresUpdate: FeatureToggleUpdateRequest): Promise<BaseResponse> =>
    httpServiceV1.post(`${featureToggleBaseUrl}/UpdateFeatures`, featuresUpdate),
}

export { featureToggleService }
