import { format } from 'date-fns'
import { SelectOption, Employee } from '../../../services/dashboardService'

export const getJson = (additionalInfo: string) => {
  try {
    return JSON.parse(additionalInfo)
  } catch (e) {
    return {}
  }
}

export const getDeskName = (additionalInfo: string, label: string) => {
  if (!additionalInfo) {
    return label
  }
  let json: { desc: { deskDesc: string } } | null = null
  json = getJson(additionalInfo)
  return `${json?.desc?.deskDesc ? `${json.desc.deskDesc.toUpperCase()}` : ''}${label}`
}

export const getUserInitials = (displayName: string) => {
  const splt = displayName.split(' ')
  return splt.map(m => m.substring(0, 1))
}

export const dateToNumber = (date: Date | string): number => {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    return Number(format(date as Date, 'yyyyMMdd'))
  }
  return Number(format(new Date(date), 'yyyyMMdd'))
}

export const extractEmployees = (allEmployees: Employee[]): SelectOption[] =>
  allEmployees.map(e => ({ value: e.employeeId, displayValue: e.employeeName }))

export const cleanUsername = (username: string | undefined) => username?.replace(/ /g, '') || ''
