import { useCallback, useEffect, useState } from 'react'
import { Grid, IconButton, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material'
import { DateRange } from '@mui/x-date-pickers-pro'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import { useSelector } from 'react-redux'
import { SelectOption, Team } from '../../../services/dashboardService'
import AutocompleteList from '../../../shared/UI/AutocompleteList'
import ButtonSmall from '../../../shared/UI/ButtonSmall'
import { IOSStyleSwitch } from '../../../shared/UI/IOSStyleSwitch/IOSStyleSwitch'
import Paragraph from '../../../shared/UI/Paragraph'
import { directReportsStyles } from './Styles'
import { MyActionsFilterProps, MyActionsFilterState } from './Types'
import DateRangePicker from '../../../shared/UI/DateRangePicker'
import { RootStore } from '../../../redux/store'
import DropdownMenu from '../../../shared/UI/DropdownMenu'

const testId = 'my-actions-filter'
function FilterPanel({
  selectionLists,
  applyFilters,
  handleClose,
}: MyActionsFilterProps & { handleClose: () => void }) {
  const { filterStates } = useSelector((state: RootStore) => state.myActions)

  /**
   * State is used so that user changes are reflected in the GUI
   * requestFilterSettings are updated with this call but does not tigger a rerender
   * dispatch is called in the parent when applyFilters is called
   * this is done so that the GUI is updated when the user clicks the Apply button and not before
   */
  const [filterState, setFilterState] = useState<MyActionsFilterState>(filterStates)
  const [teamOptions, setTeamOptions] = useState<Team[]>([])

  const [teamSelectionDisabled, setTeamSelectionDisabled] = useState<boolean>(
    selectionLists.current.departments.length > 1
  )

  const initialiseTeamOptions = useEffect(() => {
    const { departments, teams } = selectionLists.current

    const options = filterState.selectionState.department
      ? teams[filterState.selectionState.department.value]
      : teams[departments[0].value]
    if (options) setTeamSelectionDisabled(options.length === 0)
    setTeamOptions(options)
  }, [])

  const updateTeamOptions = useCallback((departmentOption?: SelectOption) => {
    const { teams } = selectionLists.current
    if (departmentOption && departmentOption?.value !== 0) {
      const options = teams[departmentOption.value]
      setTeamSelectionDisabled(options.length === 0)
      setTeamOptions(options)
    }
  }, [])

  /**
   * Filter values are reset and the GUI refresh triggerd in the parent page
   */
  const resetToDefaultSettings = useCallback(() => {
    const {
      requestParams,
      requestParamsDefaults,
      checkboxSettings,
      selectionState,
      selectionStateDefault,
    } = filterStates
    checkboxSettings.forEach(setting => {
      // eslint-disable-next-line no-param-reassign
      setting.checked = false
    })

    Object.entries(requestParamsDefaults).forEach(([key, value]) => {
      requestParams[key] = value
    })

    Object.entries(selectionStateDefault).forEach(([key, value]) => {
      selectionState[key] = value
    })

    applyFilters()
    handleClose()
  }, [])

  const handleCheckBoxChange = useCallback((type: string) => {
    const { checkboxSettings } = filterStates
    checkboxSettings.forEach(setting => {
      // eslint-disable-next-line no-param-reassign
      if (setting.type === type) setting!.checked = !setting.checked
    })

    setFilterState({ ...filterStates, checkboxSettings })
  }, [])

  const handleStatusSelection = useCallback((value: number) => {
    const { requestParams, selectionState } = filterStates
    requestParams.requestStatusId = value
    selectionState.status = value
    setFilterState({ ...filterStates, requestParams, selectionState })
  }, [])

  const handleTeamSelection = useCallback((team: Team | null) => {
    const { requestParams, selectionState } = filterStates
    requestParams.departmentTeamId = team ? team.teamId : undefined
    selectionState.team = team
    setFilterState({ ...filterStates, requestParams, selectionState })
  }, [])

  const handleDepartmentSelection = useCallback((departmentOption: SelectOption | null) => {
    /*
     * if departmentOption is null or 0:
     * disable team selection
     * remove current team selection
     */
    handleTeamSelection(null)
    if (!departmentOption || departmentOption.value === 0) {
      setTeamSelectionDisabled(true)
    } else {
      updateTeamOptions(departmentOption)
    }
    const { requestParams, selectionState } = filterStates
    requestParams.departmentId = departmentOption ? departmentOption.value : undefined
    selectionState.department = departmentOption
    setFilterState({ ...filterStates, requestParams, selectionState })
  }, [])

  const handleDateRangeSelection = useCallback((dateRange: DateRange<Date>) => {
    const [dateFrom, dateTo] = dateRange
    if (dateTo && dateFrom) {
      const { requestParams, selectionState } = filterStates
      requestParams.dateFrom = dateFrom
      requestParams.dateTo = dateTo
      selectionState.dateRange = dateRange
      setFilterState({ ...filterStates, requestParams, selectionState })
    }
  }, [])

  const handleDirectReporteesToggle = useCallback((directReportees: boolean) => {
    const { requestParams, selectionState } = filterStates
    requestParams.directReportees = directReportees
    selectionState.directReportees = directReportees
    setFilterState({ ...filterStates, requestParams, selectionState })
  }, [])

  const submitFilterParams = () => {
    applyFilters()
    handleClose()
  }

  return (
    <Grid container data-testid="filter-panel">
      {/* Left side filters */}
      <Grid item xs={12} md={4} p={3} pr={1}>
        <Grid item display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Paragraph weight="bold" style={{ marginLeft: '-8px' }}>
            Type Filters
          </Paragraph>
          <IconButton
            aria-label="delete"
            size="large"
            onClick={handleClose}
            sx={{ p: 0, display: { sm: 'none' } }}
          >
            <CloseOutlinedIcon fontSize="inherit" color="primary" />
          </IconButton>
        </Grid>
        <FormGroup>
          <Grid container>
            {filterState.checkboxSettings
              .filter(setting => setting?.count && setting?.count > 0)
              .map(({ displayName, type, checked, count }) => (
                <Grid item xs={4} md={12} gap={1}>
                  <FormControlLabel
                    control={<Checkbox checked={checked} defaultChecked={checked} sx={{ mr: 1 }} />}
                    label={`${displayName} (${count})`}
                    sx={{ mb: 1 }}
                    key={type}
                    data-testid={type}
                    onChange={() => handleCheckBoxChange(type)}
                  />
                </Grid>
              ))}
          </Grid>
        </FormGroup>
      </Grid>
      {/* Right side filters */}
      {!filterState.requestParams ? (
        <></>
      ) : (
        <Grid item xs={12} md={8} sx={{ backgroundColor: '#fafafa', p: 3 }}>
          <Paragraph weight="bold">Filters</Paragraph>
          <Grid item xs={12} mt={3}>
            <FormControlLabel
              control={
                <IOSStyleSwitch defaultChecked={filterState.requestParams.directReportees} />
              }
              label="Direct Reports"
              labelPlacement="start"
              onClick={() =>
                handleDirectReporteesToggle(!filterState.requestParams.directReportees)
              }
              data-testid="direct-reports"
              sx={directReportsStyles}
            />
          </Grid>
          <Grid
            container
            display="flex"
            direction="column"
            spacing={4}
            data-testid="my-actions-filter"
          >
            <Grid item xs={12}>
              <Grid container spacing={3} pt={2}>
                <Grid item xs={12}>
                  <AutocompleteList
                    id="department"
                    label="Department"
                    data={selectionLists.current.departments}
                    textField="displayValue"
                    value={filterState.selectionState.department}
                    onChange={(_e, department: SelectOption) =>
                      handleDepartmentSelection(department)
                    }
                    dataTestId={`${testId}-department-dropdown`}
                    disabled={selectionLists.current.departments.length === 1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteList
                    id="team"
                    label="Team"
                    data={teamOptions}
                    textField="teamName"
                    value={filterState.selectionState.team}
                    onChange={(_e, team: Team) => handleTeamSelection(team)}
                    dataTestId={`${testId}-team-dropdown`}
                    disabled={teamSelectionDisabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateRangePicker
                    arrange="Stack"
                    startText="Date from"
                    endText="Date to"
                    onChange={(date: DateRange<Date>) => handleDateRangeSelection(date)}
                    value={filterState.selectionState.dateRange || [null, null]}
                    dataTestId={`${testId}-date-range`}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownMenu
                    label="Status"
                    id="searchStatus"
                    data={selectionLists.current.statuses}
                    textField="displayValue"
                    valueField="value"
                    value={filterState.selectionState.status}
                    onChange={e => handleStatusSelection(Number(e.target.value))}
                    dataTestId="MyActions-Filters-StatusDDL"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  mt={-2}
                >
                  <Button
                    size="small"
                    onClick={resetToDefaultSettings}
                    startIcon={<RestartAltIcon />}
                    sx={{ mr: 4 }}
                  >
                    Reset all
                  </Button>
                  <ButtonSmall
                    label="Apply"
                    sx={{ minWidth: '90px', fontSize: '11px' }}
                    onClick={e => submitFilterParams()}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default FilterPanel
