import { createTheme, Link as MuiLink } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#20C5A0',
      light: 'rgba(32, 197, 160, .3)',
    },
    secondary: {
      main: '#E75E5E',
    },
    text: {
      primary: '#747474',
    },
    error: {
      main: '#d32f2f',
      contrastText: 'rgb(211, 47, 47, 0.3)',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    fontSize: 13,
    body1: {
      fontSize: '13px',
    },
    subtitle1: {
      fontSize: '13px',
    },
    subtitle2: {
      paddingTop: '4px',
      fontSize: '11px',
      color: 'rgba(0, 0, 0, 0.5)',
    },
    h1: {
      fontWeight: '500',
      fontSize: '22px',
      color: '#000000',
    },
    h4: {
      fontSize: '21px',
      color: '#000',
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: '500',
      fontSize: '13px',
      color: '#312E69',
    },
    h6: {
      fontWeight: '500',
      fontSize: '13px',
      color: '#A7A7A7',
    },
    caption: {
      fontWeight: '400',
      fontSize: '11px',
      color: '#A7A7A7',
      opacity: 1,
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiPickersDay-root:not(.Mui-selected)': {
            border: '0',
          },
          '&.MuiPickersDay-root:hover': {
            backgroundColor: 'transparent',
          },
          '&.MuiPickersDay-root:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            background: '#d3d3d3',
            color: '#747474',
            border: 'solid 3px #d3d3d3',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '& svg': {
            transition: 'opacity 0.3s ease',
          },
          '&': {
            transition: 'background-color 0.3s ease',
          },
          '&.Mui-disabled svg': {
            opacity: 0,
          },
        },
      },
      variants: [
        {
          props: { classes: 'enhancement-action' },
          style: {
            '&.Mui-disabled svg': {
              opacity: 1,
              color: '#b5b5b5',
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled:not(.MuiLoadingButton-loading)': {
            background: '#d3d3d3',
            color: '#747474',
            border: 'solid 3px #d3d3d3',
          },
          '&.freetext': {
            textTransform: 'none',
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            textTransform: 'capitalize',
            borderRadius: 50,
            padding: '12px 40px 12px 40px',
            border: 'solid 2px #20C5A0',
            fontWeight: 500,
            margin: '30px 0 0 0',
            fontSize: '13px',
            color: '#ffffff',
            background: 'linear-gradient(125deg, #20C5A0 0%, #20C5A0 100%)',
            '&:hover': {
              border: 'solid 2px transparent',
              fontWeight: 500,
              background: 'linear-gradient(109deg, #20C5A0 0%, #0a8ebf 100%)',
              color: 'white',
              backgroundOrigin: 'border-box',
            },
            '&.MuiLoadingButton-loading': {
              border: 'solid 2px #20C5A0',
            },
            '& .MuiCircularProgress-svg': {
              color: '#fff',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            color: '#20C5A0',
            background: 'linear-gradient(125deg, transparent 0%, transparent 100%)',
            '&:hover': {
              border: 'solid 2px transparent',
              fontWeight: 500,
              background: 'linear-gradient(109deg, #20C5A0 0%, #02ACEC 100%)',
              color: 'white',
              backgroundOrigin: 'border-box',
            },
            '&.MuiLoadingButton-loading': {
              border: 'solid 2px #20C5A0',
            },
            '& .MuiCircularProgress-svg': {
              color: '#20C5A0',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'info' },
          style: {
            color: '#2C2965',
            border: 'solid 2px transparent',
            background: 'linear-gradient(109deg, #DCF4FD 0%, #DCF4FD 100%)',
            fontWeight: 'bold',
            '&:hover': {
              border: 'solid 2px transparent',
              background: 'linear-gradient(109deg, #DCF4FD 0%, #A9E6FC 100%)',
              color: '#2C2965',
              backgroundOrigin: 'border-box',
              fontWeight: 'bold',
            },
            '&.MuiLoadingButton-loading': {
              border: 'solid 2px #DCF4FD',
            },
            '& .MuiCircularProgress-svg': {
              color: '#DCF4FD',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            color: '#e75e5e',
            border: 'solid 2px #e75e5e',
            background: 'linear-gradient(125deg, transparent 0%, transparent 100%)',
            '&:hover': {
              border: 'solid 2px transparent',
              fontWeight: 500,
              background: '#e75e5e',
              color: 'white',
              backgroundOrigin: 'border-box',
            },
            '&.MuiLoadingButton-loading': {
              border: 'solid 2px red',
            },
            '& .MuiCircularProgress-svg': {
              color: '#20C5A0',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            textTransform: 'none',
          },
        },
      ],
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '&.MuiDataGrid-root': {
            border: 'none',
          },
          '&.MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '.MuiDataGrid-columnHeaders': {
            borderBottom: 'none',
          },
          '.MuiDataGrid-columnHeaderTitle': {
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            color: '#000',
            fontSize: '13px',
          },
          '.MuiDataGrid-columnSeparator': {
            visibility: 'hidden',
          },
          '.MuiDataGrid-footerContainer': {
            borderTop: 'none',
          },
          '.MuiDataGrid-pinnedColumnHeaders': {
            boxShadow:
              '2px 3px 12px 15px rgb(0 0 0 / 4%), -4px 2px 2px 0px rgb(0 0 0 / 4%), 6px 1px 5px 0px rgb(0 0 0 / 4%)',
          },
          '.MuiDataGrid-pinnedColumns': {
            boxShadow:
              '2px 3px 12px 15px rgb(0 0 0 / 4%), -4px 2px 2px 0px rgb(0 0 0 / 4%), 6px 1px 5px 0px rgb(0 0 0 / 4%)',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiDrawer-paper': {
            overflow: 'visible',
            paddingTop: '9px',
            boxSizing: 'border-box',
          },
          '&.mobileNavDrawer .MuiDrawer-paper': {
            overflow: 'auto',
          },
          '& > .MuiDrawer-paper > .MuiBox-root': {
            overflow: 'auto',
          },
          '&.mobileNavDrawer > .MuiDrawer-paper > .MuiBox-root': {
            overflow: 'visible',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '16px 0',
          borderColor: '#E5E5E5',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '1px 2px 10px #00000014',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          background: '#20C5A01A',
          color: '#20C5A0',
        },
        deleteIcon: {
          color: '#20C5A0',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          padding: '10px 0',
          minWidth: '0',
          fontWeight: '500',
          textTransform: 'capitalize',
          borderRadius: '4px',
          minHeight: 'auto',
          '&:not(last-child)': {
            marginRight: '32px',
          },
          '&:last-child': {
            marginRight: '0px',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 'auto',
        },
        flexContainer: {
          borderWidth: '100%',
          boxSizing: 'border-box',
        },

        indicator: {
          height: '3px',
          boxSizing: 'border-box',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: '#20C5A0',
          fontSize: '13px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          '&selected': {
            backgroundColour: '#02ACEC',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          span: {
            fontSize: 13,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '13px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '13px',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          fontSize: '13px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '13px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          alignItems: 'center',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(247, 247, 247)',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '& .MuiPickersToolbar-root .MuiDateTimePickerToolbar-timeContainer + .MuiPickersToolbar-penIconButton':
            {
              position: 'static',
            },
        },
      },
    },
  },
})

export default theme
