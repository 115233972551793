import { ReactComponent as Down } from '../../../assets/trend/down.svg'
import { ReactComponent as Up } from '../../../assets/trend/up.svg'
import { ReactComponent as Even } from '../../../assets/trend/even.svg'

export function TrendIcon({ percentage, fill }: { percentage: number; fill: string }) {
  if (percentage > 0) {
    return <Up fill={fill} data-testid="trend-up-icon" />
  }
  if (percentage < 0) {
    return <Down fill={fill} data-testid="trend-down-icon" />
  }
  return <Even fill={fill} data-testid="trend-even-icon" />
}
