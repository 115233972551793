import { Box, Tab as MuiTab, Tabs as MuiTabs, tabsClasses, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import TabPanel from '../TabPanel'
import { setSelectedDays, setDateRange } from '../../../redux/reducers/timeOffRequestsReducer'
import { useAppDispatch } from '../../../redux/store'
import { isMobileLandscapeOnly, isMobilePortrait } from '../../../theme/deviceChecks'

export interface TabDetails {
  label: string
  tabContext: JSX.Element | null
  disabled?: boolean
}

interface TabProps {
  titleAndContent: TabDetails[]
  tabClass?: string
  tabChange?: number
  onChange?: (index: number) => void
  isOwnBooking?: boolean
  dataTestId?: string
}

function Tab({
  titleAndContent,
  onChange,
  tabClass = '',
  tabChange = 0,
  isOwnBooking,
  dataTestId,
}: TabProps) {
  const dispatch = useAppDispatch()
  const [selectedTab, setSelectedTab] = useState(tabChange)

  useEffect(() => {
    setSelectedTab(tabChange)
  }, [tabChange])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectedDays(null))
    setSelectedTab(newValue)
    dispatch(setDateRange([null, null]))

    if (onChange) {
      onChange(newValue)
    }
  }

  return (
    <>
      <Box>
        <MuiTabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          allowScrollButtonsMobile
          className={tabClass}
          sx={{
            '& .MuiTab-root': {
              paddingBottom: '8px',
            },
            [`& .${tabsClasses.scrollButtons}`]: {
              transition: 'opacity 0.3s ease',
              '&.Mui-disabled': {
                opacity: 0.3,
              },

              [isMobilePortrait()]: {
                '&.Mui-disabled': {
                  opacity: 0,
                },
              },
            },
            '&': {
              paddingBottom: isOwnBooking ? '21px' : '0',
            },
          }}
        >
          {titleAndContent.map((title, index) => {
            const thisId = `${dataTestId}-${title.label.replace(/\s/g, '')}`
            return (
              <MuiTab
                disableRipple
                key={`${thisId}-`}
                label={title.label}
                id={index.toString()}
                disabled={title.disabled}
                data-testid={`${thisId}Tab`}
              />
            )
          })}
        </MuiTabs>
      </Box>

      {titleAndContent.map((title, index) => (
        <TabPanel value={selectedTab} index={index} key={title.label}>
          {title.tabContext}
        </TabPanel>
      ))}
    </>
  )
}

export default Tab
