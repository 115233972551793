import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { dashboardService } from '../../services/dashboardService'
import { BaseResponse } from '../../types/base-response'
import { WhosAwayResponse } from '../../types/whos-away-type'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../utils/errorFilter'

export const useWhosAway = () => {
  const dispatch = useDispatch()
  const [whosAway, setWhosAway] = useState<WhosAwayResponse>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    dashboardService
      .getWhosAway()
      .then(e => {
        setWhosAway(e)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [dispatch])

  return {
    whosAway,
    isLoading,
  }
}
