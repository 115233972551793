import { useState } from 'react'
import { Grid, Tabs, Tab } from '@mui/material'
import { WhosAwayResponse } from '../../../types/whos-away-type'
import { getTabProps } from '../utils/getTabProps'
import { TabItems } from './TabItems'

interface Props {
  whosAway: WhosAwayResponse | undefined
  setActiveLink: React.Dispatch<React.SetStateAction<string>>
}

export function WhosAwayCardContent({ whosAway, setActiveLink }: Props) {
  const [value, setValue] = useState(0)

  const handleTabChange = (_e: React.SyntheticEvent, v: number) => setValue(v)

  return (
    <>
      <Grid container>
        {whosAway && (
          <Grid item justifyContent="flex-end">
            <TabItems>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="Who's Tabs"
                variant="scrollable"
                scrollButtons={false}
                allowScrollButtonsMobile
              >
                <Tab
                  label="Today"
                  data-testid="dashboard_tab_today"
                  {...getTabProps(0)}
                  onClick={() => {
                    setActiveLink('Today')
                  }}
                />
                <Tab
                  label="Tomorrow"
                  data-testid="dashboard_tab_tomorrow"
                  {...getTabProps(1)}
                  onClick={() => {
                    setActiveLink('Tomorrow')
                  }}
                />
              </Tabs>
            </TabItems>
          </Grid>
        )}
      </Grid>
    </>
  )
}
