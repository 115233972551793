// eslint-disable-next-line no-shadow
export enum BookingWizardSteps {
  STEP_0_INACTIVE = -2,
  STEP_1_SEARCH = -1,
  STEP_2_SELECT_DESK = 0,
  STEP_3_SUMMARY = 1,
  STEP_3_UNAVAILABLE = 2,
  STEP_4_ALTERNATIVES = 3,
  STEP_5_SUBMIT = 4,
}
