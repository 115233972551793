import { Grid } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageHeader from '../shared/layout/PageHeader/PageHeader'
import Birthday from './Birthday'
import { RootStore } from '../redux/store'
import { BuyHoursType } from '../types/buy-hours-type'
import { SellHoursType } from '../types/sell-hours-type'
import AllowanceChart from './AllowanceChart'
import AllocationExtras from './AllocationExtras'
import { AllocationExtrasType } from '../types/allocation-extras-type'
import FullComponentLoadingIcon from '../shared/UI/LoadingIndicator/FullComponentLoadingIcon'
import { useEntitlement } from './Dashboard/useEntitlement'
import MobileContainer from '../shared/layout/MobileContainer'
import { availabilityService } from '../services/availabilityService'
import { BaseResponse } from '../types/base-response'
import { showErrorMessage } from '../redux/reducers/snackbarReducer'
import UserErrorMessage from '../utils/errorFilter'

function MyExtras() {
  const [serviceHours, setServiceHours] = useState<AllocationExtrasType>({
    service: 0,
    fireMarshall: 0,
    firstAid: 0,
  })
  const [serivceHoursLoading, setServiceHoursLoading] = useState<boolean>(true)
  const [myExtrasLoading, setMyExtrasLoading] = useState<boolean>(true)
  const [buyHours, setBuyHours] = useState<BuyHoursType>()
  const [sellHours, setSellHours] = useState<SellHoursType>()
  const [requestedSellHours, setRequestedSellHours] = useState<number>(0)

  const dispatch = useDispatch()

  const { myEntitlement, isEntitlementLoading } = useEntitlement()

  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)

  const getAvailabilityData = useCallback(() => {
    if (!currentEntitlementPeriodResponse) {
      return
    }

    availabilityService
      .getMyAvailability(currentEntitlementPeriodResponse.year)
      .then(e => {
        const sellRequests = e.requests.filter(
          x => x.statusDescription === 'Pending' && x.requestType === 'Sell'
        )
        const sellRequested = sellRequests.map(req => req.hours ?? 0).reduce((x, y) => x + y, 0)
        setRequestedSellHours(sellRequested)
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [currentEntitlementPeriodResponse, dispatch])

  useEffect(() => {
    getAvailabilityData()
  }, [getAvailabilityData])

  useEffect(() => {
    if (!currentEntitlementPeriodResponse) {
      return
    }

    if (myEntitlement && !isEntitlementLoading) {
      setBuyHours({
        availableHours: myEntitlement.maxHolidaysAvailableToBuy,
        boughtHours: myEntitlement.hoursBoughtToDate,
        totalHours: myEntitlement.totalHours,
        errors: myEntitlement.errors,
      })
      setSellHours({
        availableHours: myEntitlement.maxHolidaysAvailableToSell,
        soldHours: myEntitlement.hoursSoldToDate,
        totalHours: myEntitlement.totalHours,
        errors: myEntitlement.errors,
      })
      setServiceHours(prevState => ({
        ...prevState,
        fireMarshall: myEntitlement.fireMarshalAllowance,
        firstAid: myEntitlement.firstAiderAllowance,
        service: myEntitlement.serviceHours,
      }))
      setServiceHoursLoading(false)
    }
  }, [currentEntitlementPeriodResponse, dispatch, isEntitlementLoading, myEntitlement])

  const { userSettings } = useSelector((state: RootStore) => state.appSettings)

  const hasBuy = useMemo(() => Boolean(userSettings?.isBuyRequestAllowed), [userSettings])
  const hasSell = useMemo(() => Boolean(userSettings?.isSellRequestAllowed), [userSettings])
  const hasBuyOrSell = useMemo(() => hasBuy || hasSell, [hasBuy, hasSell])
  const hasBuyAndSell = useMemo(() => hasBuy && hasSell, [hasBuy, hasSell])
  const isBirthdayAllowed = useMemo(
    () => Boolean(userSettings?.isBirthdayRequestAllowed),
    [userSettings]
  )

  const hasServiceHours = useMemo(
    () => serviceHours.fireMarshall > 0 || serviceHours.firstAid > 0 || serviceHours.service > 0,
    [serviceHours]
  )

  const allThreeCardsDisplayed = useMemo(
    () => hasServiceHours && hasBuyOrSell && isBirthdayAllowed,
    [hasBuyOrSell, hasServiceHours, isBirthdayAllowed]
  )

  const cardTitle = useMemo(() => {
    if (hasBuyAndSell) {
      return 'Buy & Sell'
    }
    if (hasBuy) {
      return 'Buy'
    }
    if (hasSell) {
      return 'Sell'
    }
    return ''
  }, [hasBuy, hasBuyAndSell, hasSell])

  useEffect(() => {
    if (!serivceHoursLoading && userSettings !== null) {
      setMyExtrasLoading(false)
      return
    }

    setMyExtrasLoading(true)
  }, [serivceHoursLoading, userSettings])

  return (
    <>
      <PageHeader title="My Extras" />
      <MobileContainer>
        <FullComponentLoadingIcon
          loading={myExtrasLoading}
          bgColor="transparent"
          sx={{ height: 'auto' }}
        >
          <Grid container spacing={4}>
            {/* Col One */}
            {hasBuyOrSell && (
              <Grid item xs={12} md={6} xl={4} display="flex">
                <AllowanceChart
                  title={cardTitle}
                  hideHolidays
                  buyHours={buyHours}
                  sellHours={sellHours}
                  myEntitlement={myEntitlement}
                  isLoadingEntitlement={isEntitlementLoading}
                  requestedSellHours={requestedSellHours}
                  refreshEntitlement={getAvailabilityData}
                />
              </Grid>
            )}
            {/* Col One End */}
            {/* Col Two */}
            {hasServiceHours && (
              <Grid item xs={12} md={6} xl={4} display="flex">
                <AllocationExtras serviceHours={serviceHours} isLoading={serivceHoursLoading} />
              </Grid>
            )}
            {/* Col Two End */}
            {/* Col Three */}
            {isBirthdayAllowed && (
              <Grid item xs={12} md={allThreeCardsDisplayed ? 12 : 6} xl={4}>
                <Birthday />
              </Grid>
            )}
            {/* Col Three End */}
          </Grid>
        </FullComponentLoadingIcon>
      </MobileContainer>
    </>
  )
}

export default MyExtras
