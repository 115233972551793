import { BookingAvailability } from './types'

// eslint-disable-next-line no-shadow
export enum BookingAvailabilityColors {
  NOT_ACTIVE = '#dddddd',
  AVAILABLE = '#70BA82',
  OWN_BOOKING = '#20c5a1',
  NOT_AVAILABLE = '#BA7070',
  PARTIALLY_AVAILABLE = '#DB8733',
}

// eslint-disable-next-line no-shadow
export const BookingAvability: BookingAvailability = {
  NO_AVAILABILITY: { text: 'No Availability', color: BookingAvailabilityColors.NOT_AVAILABLE },
  PARTIAL_AVAILABILITY: {
    text: 'Partial Availability',
    color: BookingAvailabilityColors.PARTIALLY_AVAILABLE,
  },
  AVAILABLE: { text: 'Available', color: BookingAvailabilityColors.AVAILABLE },
  OWN_BOOKING: { text: 'Own Booking', color: BookingAvailabilityColors.OWN_BOOKING },
}

// eslint-disable-next-line no-shadow
export enum BookingDateRange {
  FROM = 0,
  TO = 1,
}

// eslint-disable-next-line no-shadow
export enum ButtonLabelType {
  BOOK_NOW = 'Book Now',
  CANCEL_BOOKING = 'Cancel Booking',
  SELECT = 'Select',
}

// eslint-disable-next-line no-shadow
export enum ManagerBookingOptions {
  MYSELF = 1,
  EMPLOYEE = 2,
  VISITOR = 3,
}

export const FIRST_FLOORPLAN_IN_ARRAY = 0
export const FIRST_ZONE_IN_ARRAY = 0
