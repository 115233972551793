import { styled } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

interface GridProps {
  showHeaders?: boolean
}

export const DashboardDataGrid = styled(DataGrid)<GridProps>`
  & .MuiDataGrid-columnHeaders {
    ${({ showHeaders }) => `display: ${showHeaders ? 'block' : 'none'};`}
  }

  &.MuiDataGrid-root {
    border: none;
  }

  &.MuiDataGrid-root.MuiDataGrid-autoHeight {
    height: 100%;
  }

  & .MuiDataGrid-cell {
    border-bottom: none;
  }

  & .MuiDataGrid-columnHeader,
  & .MuiDataGrid-cell {
    padding-left: 0;
  }

  & .MuiDataGrid-virtualScroller {
    min-height: 156px;
  }

  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
  }

  &.MuiDataGrid-root .MuiDataGrid-row:hover {
    background: transparent;
  }
`
