import styled from 'styled-components'
import { styled as muiStyled, Popover, IconButton, Badge, Box } from '@mui/material'
import { isMobileDown, isTabletUp } from '../../../theme/deviceChecks'

export const StyledPopover = styled(Popover)`
  div.MuiPaper-root {
    background-color: transparent;
    border: 0px solid red;
    border-radius: 0px;
    width: 400px;
    box-shadow: 0px 0px 0px #00000014;
  }
  div.MuiPaper-root:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #20c5a0 transparent;
    border-width: 0 8px 14px;
    top: 0px;
    right: 15px;
  }
  div.MuiPaper-root > div {
    background-color: #20c5a0;
    color: #ffffff;
    border-radius: 8px;
    box-shadow: 1px 2px 10px #00000040;
    margin: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  div.MuiPaper-root div.header {
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 4;
    padding-left: 20px;

    span.spinner {
      width: 30px;
      height: 30px;
      float: right;
      margin-right: 20px;
      margin-top: 8px;

      span.MuiCircularProgress-root {
        width: 25px !important;
        height: 25px !important;
      }
    }

    span.button {
      float: right;
    }

    button {
      float: right;
      margin-top: 12px;
      margin-right: 12px;
      text-transform: none;
    }
  }
  div.MuiPaper-root div.item {
    background-color: white;
    border-top: 1px solid rgb(244, 245, 247);
    color: #20c5a0;
    padding-left: 18px;
    padding-top: 18px;
    padding-bottom: 18px;

    span.MuiCircularProgress-root {
      width: 25px !important;
      height: 25px !important;
      float: right;
      margin-right: 20px;
      margin-top: 10px;
    }

    button {
      float: right;
    }
  }
  div.MuiPaper-root div.itemlast {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  div.MuiPaper-root div.loading div.MuiGrid-item {
    text-align: center;

    span.MuiCircularProgress-root {
      width: 25px !important;
      height: 25px !important;
      float: none;
      margin-right: 0px;
      margin-top: 0px;
    }
  }
  div.MuiPaper-root div.empty p.MuiTypography-root {
    text-align: center;
  }
  div.items {
    overflow-y: auto;
    max-height: 254px;
    background-color: white;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  div.items::-webkit-scrollbar {
    width: 17px;
  }
  div.items::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    background-clip: content-box;
  }
`
const dingle = {
  '@keyframes dingle': {
    '0': {
      transform: 'rotate(0)',
    },
    '25%': {
      transform: 'rotate(10deg)',
    },
    '50%': {
      transform: 'rotate(0)',
    },
    '75%': {
      transform: 'rotate(-10deg)',
    },
    '100%': {
      transform: 'rotate(0)',
    },
  },
}

export const AnimatedIconButton = muiStyled(IconButton)({
  ...dingle,

  '&:hover svg': {
    animation: 'dingle .3s ease 3',
  },

  '& svg': {
    transformOrigin: 'top center',
  },

  [isMobileDown()]: {
    '& .MuiSvgIcon-root': {
      height: '20px',
      width: 'auto',
      transition: 'all 0.3s ease',
    },

    '.headerTall & .MuiSvgIcon-root': {
      height: '24px',
    },

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

export const AnimatedBox = muiStyled(Box)({
  ...dingle,
  '&.hasNotification .MuiSvgIcon-root': {
    animation: 'dingle .3s ease 3',
  },

  position: 'relative',
  transformOrigin: 'top center',
  display: 'flex',
})

const grow = {
  '@keyframes grow': {
    from: {
      transform: 'scale(0)',
    },
    to: {
      transform: 'scale(1)',
    },
  },
}

const growInner = {
  '@keyframes growInner': {
    '0%': {
      opacity: 1,
      transform: 'scale(0)',
    },
    '50%': {
      opacity: 0.7,
      transform: 'scale(1)',
    },
    '100%': {
      opacity: 0,
      transform: 'scale(2.5)',
    },
  },
}

export const AnimatedBadgeContainer = muiStyled('div')({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
})

export const AnimatedBadge = muiStyled('div')({
  ...grow,
  ...growInner,
  position: 'absolute',
  backgroundColor: '#0288d1',
  height: '12px',
  width: '12px',
  top: '-6px',
  right: '-6px',
  borderRadius: '100%',
  transition: 'all 0.3s ease',

  '.hasNotification &': {
    animation: 'grow 150ms ease-out forwards',
  },

  '.hasNotification &:before': {
    transform: 'scale(0)',
    content: "''",
    position: 'absolute',
    backgroundColor: '#28a8ee',
    top: 0,
    height: '12px',
    width: '12px',
    animation: 'growInner 250ms ease-out forwards',
    borderRadius: '50%',
    right: 0,
    transformOrigin: 'center',
    zIndex: 1,
  },

  '.headerTall &': {
    height: '10px',
    width: '10px',
    top: '-5px',
    right: '-5px',
  },

  '.headerShort &': {
    height: '8px',
    width: '8px',
    top: '-4px',
    right: '-4px',
  },

  '.headerTall &:before': {
    height: '10px',
    width: '10px',
  },

  '.headerShort &:before': {
    height: '8px',
    width: '8px',
  },
})
