export interface BookingSearchResultsState {
  submissionsInProgress: number[]
}

export const deskBookingStateInitialState: BookingSearchResultsState = {
  submissionsInProgress: [],
}

const SET_IS_SUBMIT_IN_PROG = 'SET_IS_SUBMIT_IN_PROG'

interface SetIsSubmissionInProgress {
  type: typeof SET_IS_SUBMIT_IN_PROG
  payload: number[]
}

type deskBookingStatusesReducerTypes = SetIsSubmissionInProgress

export const setSubmissionsInProgress = (inProgress: number[]): SetIsSubmissionInProgress => ({
  type: SET_IS_SUBMIT_IN_PROG,
  payload: inProgress,
})

export const deskBookingStatusesReducer = (
  state: BookingSearchResultsState = deskBookingStateInitialState,
  action: deskBookingStatusesReducerTypes
): BookingSearchResultsState => {
  switch (action.type) {
    case SET_IS_SUBMIT_IN_PROG:
      return { ...state, submissionsInProgress: action.payload }
    default:
      return state
  }
}
