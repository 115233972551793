import { Box, Grid, TextField, Typography, useMediaQuery } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import birthdayBg from '../../assets/MyExtras/BirthdayBg.svg'
import NoBookedCake from '../../assets/MyExtras/NoBookedCake.svg'
import PendingCake from '../../assets/MyExtras/PendingCake.svg'
import Cake from '../../assets/MyExtras/CakeLayers/Cake.svg'
import Flames from '../../assets/MyExtras/CakeLayers/Flames.svg'
import Present1 from '../../assets/MyExtras/CakeLayers/Present1.svg'
import Present2 from '../../assets/MyExtras/CakeLayers/Present2.svg'
import Present3 from '../../assets/MyExtras/CakeLayers/Present3.svg'
import Present4 from '../../assets/MyExtras/CakeLayers/Present4.svg'
import Present5 from '../../assets/MyExtras/CakeLayers/Present5.svg'
import YAY from '../../assets/YAY.svg'
import {
  hideModal,
  SetShowModalPayload,
  showModalDialog,
} from '../../redux/reducers/appSettingsReducer'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { RootStore } from '../../redux/store'
import { availabilityService } from '../../services/availabilityService'
import StyledBirthdayCard from '../../shared/layout/BirthdayCard'
import Button from '../../shared/UI/Button'
import Drawer from '../../shared/UI/Drawer'
import FullComponentLoadingIcon from '../../shared/UI/LoadingIndicator/FullComponentLoadingIcon'
import Modal from '../../shared/UI/Modal'
import { BaseResponse } from '../../types/base-response'
import UserErrorMessage from '../../utils/errorFilter'
import { MyExtrasHeadingContainer } from '../AllocationExtras/components'
import BirthdayRequest from '../BirthdayRequest'
import {
  BirthdayBackground,
  BirthdayBookedContainer,
  BirthdayCakeContainer,
  CakeLayer,
  CakeLayerImg,
  YayImg,
} from './components'
import { getLongMonthString, getOrdinal } from '../../utils/date-utils'
import { myExtrasService } from '../../services/myExtrasService'
import { setDate } from '../../redux/reducers/timeOffRequestsReducer'
import { RequestType, RequestTypeNameString } from '../../models'
import { isMobilePortrait } from '../../theme/deviceChecks'

type BirthdayType = {
  requestId: number
  requestStatus: string
  requestedDate: string
}

const birthdayStyles = {
  '&.MuiPaper-root': {
    backgroundColor: '#E9F9F9',
    height: '100%',
  },
}

const birthdayButtonStyles = {
  position: 'absolute',
  bottom: '22%',
  left: '50%',
  transform: 'translateX(-50%)',
  marginTop: '40px',
}

function Birthday() {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [myBirthdays, setMyBirthdays] = useState<BirthdayType[]>()
  const [deleteComments, setDeleteComments] = useState<string>('')
  const [fieldsTouched, setFieldsTouched] = useState({ deleteComments: false })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [disabled, setIsDisabled] = useState(true)

  const isMobPortrait = useMediaQuery(isMobilePortrait())

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const thisYearBirthday: BirthdayType | undefined = useMemo(() => {
    if (!myBirthdays) {
      return undefined
    }

    const thisYear = new Date().getFullYear()
    const thisYearBookings = myBirthdays?.filter(
      b => new Date(b.requestedDate).getFullYear() === thisYear
    )
    if (!thisYearBookings) {
      return undefined
    }

    if (thisYearBookings.length === 1) {
      return thisYearBookings[0]
    }

    const filtered = thisYearBookings.filter(
      b =>
        b.requestStatus !== 'Cancelled' &&
        b.requestStatus !== 'Declined' &&
        b.requestStatus !== 'ChangeRequested2'
    )
    if (filtered.length < 1) {
      return undefined
    }

    return filtered[0]
  }, [myBirthdays])

  const birthdayDateString = useMemo(() => {
    if (!thisYearBirthday?.requestedDate || thisYearBirthday?.requestedDate === '') {
      return ''
    }

    const date = new Date(thisYearBirthday.requestedDate)
    return `${getOrdinal(date.getDate())} ${getLongMonthString(date)}, ${date.getFullYear()}`
  }, [thisYearBirthday])

  const activeRoute = useSelector<RootStore, string>(
    (state: RootStore) => state.appSettings.activeRoute
  )

  const getAllBirthdays = useCallback(() => {
    setIsLoading(true)
    myExtrasService
      .getAllBirthdays()
      .then(resp => {
        setMyBirthdays(
          resp.birthdays.map(b => ({
            requestId: b.requestId,
            requestStatus: b.requestStatus,
            requestedDate: b.dateFrom,
          }))
        )
      })
      .catch(err => {
        navigate(activeRoute)
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [activeRoute, dispatch, navigate])

  useEffect(() => {
    getAllBirthdays()
  }, [getAllBirthdays])

  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  const isFormValid = () => {
    setFieldsTouched({ ...fieldsTouched, deleteComments: true })

    if (!deleteComments) {
      return false
    }

    return true
  }

  useEffect(() => {
    if (showModal === true) {
      setIsDisabled(!deleteComments)
    } else {
      setIsDisabled(false)
    }
  }, [deleteComments, showModal])

  const cancelBirthdayDayOff = () => {
    setButtonLoading(true)
    if (thisYearBirthday?.requestStatus === 'Pending') {
      availabilityService
        .deleteMyRequest(thisYearBirthday.requestId)
        .then(e => {
          setButtonLoading(false)
          dispatch(showSuccessMessage('Birthday booking cancelled'))
          getAllBirthdays()
          dispatch(hideModal())
        })
        .catch(err => {
          setButtonLoading(false)
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    } else if (thisYearBirthday?.requestStatus === 'Approved') {
      availabilityService
        .cancelApprovedRequest({
          requestId: thisYearBirthday.requestId,
          cancelComments: deleteComments,
        })
        .then(e => {
          setButtonLoading(false)
          dispatch(showSuccessMessage('Birthday booking cancelled'))
          getAllBirthdays()
          dispatch(hideModal())
        })
        .catch(err => {
          setButtonLoading(false)
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        })
    }
  }

  const deleteBirthday = useCallback(() => {
    dispatch(
      showModalDialog({
        title: 'Are you sure?',
        message: `Are you sure you would like to delete this birthday booking?`,
        buttonLabel: 'Cancel Request',
        type: 'question',
        showModal: true,
      })
    )
  }, [dispatch])

  const bookedCardData = useCallback(
    (birthdayStatus: string | undefined) => {
      switch (birthdayStatus) {
        case 'Pending':
        case 'Change Requested':
          return {
            titleComponent: (
              <MyExtrasHeadingContainer>
                <Typography variant="h4">Booking Pending!</Typography>
              </MyExtrasHeadingContainer>
            ),
            bodyComponent: (
              <BirthdayCakeContainer>
                <img src={PendingCake} alt="birthday booked" />
                {birthdayStatus === 'Pending' && (
                  <Button label="Cancel" onClick={deleteBirthday} style={birthdayButtonStyles} />
                )}
              </BirthdayCakeContainer>
            ),
          }
        case 'Approved':
          return {
            titleComponent: (
              <MyExtrasHeadingContainer>
                <Typography variant="h4">You booked your Birthday!</Typography>
                <Typography variant="subtitle2">{birthdayDateString}</Typography>
                <YayImg src={YAY} alt="YAY" />
              </MyExtrasHeadingContainer>
            ),
            bodyComponent: (
              <BirthdayCakeContainer>
                <BirthdayBookedContainer>
                  <img src={Flames} alt="birthday booked" />
                  <CakeLayer delay={0.75}>
                    <CakeLayerImg src={Present1} />
                  </CakeLayer>

                  <CakeLayer delay={0.85}>
                    <CakeLayerImg src={Present2} />
                  </CakeLayer>

                  <CakeLayer delay={0.95}>
                    <CakeLayerImg src={Present3} />
                  </CakeLayer>

                  <CakeLayer delay={1}>
                    <CakeLayerImg src={Present4} />
                  </CakeLayer>

                  <CakeLayer delay={1.1}>
                    <CakeLayerImg src={Present5} />
                  </CakeLayer>

                  <CakeLayer delay={0.5}>
                    <CakeLayerImg src={Cake} />
                  </CakeLayer>
                </BirthdayBookedContainer>

                <Button label="Cancel" onClick={deleteBirthday} style={birthdayButtonStyles} />
              </BirthdayCakeContainer>
            ),
          }
        default:
          return {
            titleComponent: (
              <MyExtrasHeadingContainer>
                <Typography variant="h4">
                  You haven&apos;t booked
                  <br />
                  your birthday yet
                </Typography>
              </MyExtrasHeadingContainer>
            ),
            bodyComponent: (
              <BirthdayCakeContainer>
                <img src={NoBookedCake} alt="birthday booked" />
                <Button
                  label="Book Birthday"
                  onClick={() => {
                    dispatch(setDate(null))
                    setOpenDrawer(true)
                  }}
                  style={birthdayButtonStyles}
                  dataTestId="MyExtras-BirthdayBtn"
                />
              </BirthdayCakeContainer>
            ),
          }
      }
    },
    [birthdayDateString, deleteBirthday, dispatch]
  )

  const onSubmit = () => {
    setOpenDrawer(false)
    dispatch(setDate(null))
    getAllBirthdays()
  }

  const birthdayState = useMemo(
    () => bookedCardData(thisYearBirthday?.requestStatus),
    [thisYearBirthday?.requestStatus, bookedCardData]
  )

  const tabDataId = `MyExtras-Birthday`

  return (
    <>
      <Drawer
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        title="Birthday Request"
        showOptions={false}
        narrow
        mobileHeight="530px"
      >
        <Box sx={{ p: isMobPortrait ? 0 : 4 }}>
          <BirthdayRequest
            type={RequestType.BIRTHDAY}
            closeDrawer={() => onSubmit()}
            requestTypeId={tabDataId}
            narrow
          />
        </Box>
      </Drawer>
      {thisYearBirthday?.requestStatus === 'Pending' ? (
        <Modal
          type={type}
          open={showModal}
          onClose={() => {
            dispatch(hideModal())
            setFieldsTouched({ ...fieldsTouched, deleteComments: false })
          }}
          onClick={cancelBirthdayDayOff}
          title={title}
          message={message}
          buttonLabel={buttonLabel}
          buttonLoading={buttonLoading}
        />
      ) : (
        <Modal
          type={type}
          open={showModal}
          onClose={() => {
            dispatch(hideModal())
            setDeleteComments('')
            setFieldsTouched({ ...fieldsTouched, deleteComments: false })
          }}
          onClick={() => {
            if (isFormValid()) {
              cancelBirthdayDayOff()
            }
          }}
          title={title}
          message={message}
          buttonLabel={buttonLabel}
          maxWidth="md"
          buttonLoading={buttonLoading}
          isDisabled={disabled}
          dropdown={
            <Grid container>
              <Grid item xs={12} md={12} spacing={4} mt={4}>
                <Grid mt={2}>
                  <TextField
                    label="Comments"
                    fullWidth
                    multiline
                    rows={4}
                    onChange={e => {
                      setDeleteComments(e.target.value)
                    }}
                    onBlur={() => setFieldsTouched({ ...fieldsTouched, deleteComments: true })}
                    error={fieldsTouched.deleteComments && deleteComments === ''}
                  />
                </Grid>
              </Grid>
            </Grid>
          }
        />
      )}

      <StyledBirthdayCard sx={birthdayStyles}>
        <FullComponentLoadingIcon loading={isLoading} noData={!myBirthdays} bgColor="transparent">
          <BirthdayBackground>
            <img src={birthdayBg} alt="birthday" />
          </BirthdayBackground>
          <Grid item pt={4} position="relative" zIndex="1">
            {birthdayState.titleComponent}
          </Grid>
          {birthdayState.bodyComponent}
        </FullComponentLoadingIcon>
      </StyledBirthdayCard>
    </>
  )
}

export default Birthday
