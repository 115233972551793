import { Grid, TextField } from '@mui/material'
import styled from 'styled-components'
import Button from '../../../../shared/UI/Button'
import { DetailProps } from './types'

const StyledTextField = styled(TextField)`
  input.MuiInputBase-input {
    -webkit-text-fill-color: #747474;
  }
`

function HolidaysRequestsCountDetail({ data, onClose }: DetailProps) {
  if (!data) {
    return null
  }

  return (
    <>
      <Grid container style={{ paddingLeft: '30px' }} mt={4} spacing={4} pr={4}>
        <Grid item xs={12}>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Job Title" value={data.jobTitle} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Team" value={data.team} disabled />
              </Grid>
              <Grid item xs={12} lg={4}>
                <StyledTextField
                  fullWidth
                  label="Days Off Requests"
                  value={data.daysOffRequests}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <StyledTextField
                  fullWidth
                  label="Days Off Approvals"
                  value={data.daysOffApprovals}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <StyledTextField
                  fullWidth
                  label="Days Off Declines"
                  value={data.daysOffDeclines}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <StyledTextField
                  fullWidth
                  label="Shift Requests"
                  value={data.shiftRequests}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <StyledTextField
                  fullWidth
                  label="Shift Approvals"
                  value={data.shiftApprovals}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <StyledTextField
                  fullWidth
                  label="Shift Declines"
                  value={data.shiftDeclines}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <StyledTextField
                  fullWidth
                  label="Total Requests"
                  value={data.totalRequests}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <StyledTextField
                  fullWidth
                  label="Total Approvals"
                  value={data.totalApprovals}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <StyledTextField
                  fullWidth
                  label="Total Declines"
                  value={data.totalDeclines}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        xl={12}
        pl={4}
        pr={4}
        style={{ position: 'fixed', bottom: '40px', right: '0px' }}
      >
        <Grid item xs={12} gap={4} display="flex" justifyContent="flex-end">
          <Button label="Close" color="secondary" variant="outlined" onClick={onClose} />
        </Grid>
      </Grid>
    </>
  )
}

export default HolidaysRequestsCountDetail
