// eslint-disable-next-line no-shadow
export enum BookingAction {
  SHOW_ME = 'SHOW_ME',
  CHECK_IN = 'CHECK_IN',
  CANCEL = 'CANCEL',
}

// eslint-disable-next-line no-shadow
export enum BookingNotificationError {
  CANCELLED = 'NotificationCancelledBooking',
  EXPIRED = 'NotificationOldBooking',
}

// eslint-disable-next-line no-shadow
export enum BookingStatus {
  PENDING = 1,
  ACCEPTED = 2,
  DECLINED = 3,
  CANCELLED = 4,
  CHECKEDIN = 5,
}

// eslint-disable-next-line no-shadow
export enum BookingFeatureTypes {
  Desk = 1,
  Meeting_Room = 2,
  Large_Desk = 3,
  SVG_Rectangle = 4,
  SVG_Text = 5,
  SVG_Generic = 6,
}

// eslint-disable-next-line no-shadow
export enum BookingSearchType {
  SINGLE = 0,
  BLOCK = 1,
}

// eslint-disable-next-line no-shadow
export enum BookingDeskPortPosition {
  TOP = 1,
  RIGHT = 2,
  BOTTOM = 3,
  LEFT = 4,
}

// eslint-disable-next-line no-shadow
export enum BookingSliderIndex {
  GRID_VIEW = 0,
  FLOORPLAN_VIEW = 1,
}

// eslint-disable-next-line no-shadow
export enum BookingWizardStepLabels {
  SELECT_DESK = 0,
  SUMMARY = 1,
  ALTERNATIVES = 2,
}

// eslint-disable-next-line no-shadow
export enum CheckInOutStatus {
  IN = 'in',
  OUT = 'out',
}

// eslint-disable-next-line no-shadow
export enum WorkingHoursAlias {
  HALF_DAY = 4,
  FULL_DAY = 8,
}

// eslint-disable-next-line no-shadow
export enum BookingListMaxMonths {
  MONTHS = 6,
}
