import { BookingSearchParams, BookingSearchRecentParams } from '../../components/Booking/types'
import { BookingBookingPayload } from '../../services/booking/types'

export interface BookingSearchState {
  searchParams?: BookingSearchParams | undefined
  searchResults: BookingBookingPayload[]
  showSearch: boolean
  searchRecentParams?: BookingSearchRecentParams | null
}

export const initialBookingSearchState: BookingSearchState = {
  searchResults: [],
  showSearch: true,
}

const SET_SHOW_SEARCH = 'SET_SHOW_SEARCH'
const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS'
const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'
const SET_SEARCH_RECENT_PARAMS = 'SET_SEARCH_RECENT_PARAMS'

interface SetDeskBookingShowSearch {
  type: typeof SET_SHOW_SEARCH
  payload: boolean
}

interface SetBookingSearchParams {
  type: typeof SET_SEARCH_PARAMS
  payload: BookingSearchParams | undefined
}

interface SetDeskBookingSearchResults {
  type: typeof SET_SEARCH_RESULTS
  payload: BookingBookingPayload[]
}

interface SetBookingSearchRecentParams {
  type: typeof SET_SEARCH_RECENT_PARAMS
  payload: BookingSearchRecentParams | null
}

type deskBookingSearchDespatchTypes =
  | SetDeskBookingShowSearch
  | SetBookingSearchParams
  | SetDeskBookingSearchResults
  | SetBookingSearchRecentParams

export const setDeskBookingShowSearch = (show: boolean): SetDeskBookingShowSearch => ({
  type: SET_SHOW_SEARCH,
  payload: show,
})

export const setDeskBookingSearchParams = (
  searchParams: BookingSearchParams | undefined
): SetBookingSearchParams => ({
  type: SET_SEARCH_PARAMS,
  payload: searchParams,
})

export const setDeskBookingSearchResults = (
  searchResults: BookingBookingPayload[]
): deskBookingSearchDespatchTypes => ({
  type: SET_SEARCH_RESULTS,
  payload: searchResults,
})

export const setBookingSearchRecentParams = (
  searchParams: BookingSearchRecentParams
): SetBookingSearchRecentParams => ({
  type: SET_SEARCH_RECENT_PARAMS,
  payload: searchParams,
})

export const deskBookingSearchReducer = (
  state: BookingSearchState = initialBookingSearchState,
  action: deskBookingSearchDespatchTypes
): BookingSearchState => {
  switch (action.type) {
    case SET_SHOW_SEARCH:
      return { ...state, showSearch: action.payload }
    case SET_SEARCH_RESULTS:
      return { ...state, searchResults: action.payload }
    case SET_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    case SET_SEARCH_RECENT_PARAMS:
      return { ...state, searchRecentParams: action.payload }
    default:
      return state
  }
}
