import { ReactElement } from 'react'
import { AbsenceDay } from '../../types/absence-day'
import { EmployeeResponse } from '../../types/employee'

export type SummaryProps = {
  id: number
  title: string
  value: any
}

export type DrawerComponentProps = {
  title: string
  component: ReactElement
  isNarrow?: boolean
}

export type DateRangeType = {
  dateFrom?: Date
  dateTo?: Date
}

export type Absence = {
  dateFrom: Date
  dateTo: Date
  displayName: string
  id: number
  requestType: string
  statusDescription: string
  submitDateTime: Date
}

export type Team = {
  value: number
  associatedValue: number
  displayValue: string
}

export interface AbsenceAuditHistory {
  id: string
  dateTime: string
  changedBy: string
  item: string
  value: string
}

// eslint-disable-next-line no-shadow
export enum AbsenceTypesEnum {
  Sickness = 1,
  NonSickness = 2,
  NoShow = 3
}

// eslint-disable-next-line no-shadow
export enum AbsenceReasonTypesEnum {
  NotSelected = 0,
  CovidIsolation = 22,
  NotRequiredNotDisclosed = 25
}

export const tabType = (typeOfTab: number) => {
  switch (typeOfTab) {
    case 1:
      return 'Holiday'
    case 5:
      return 'Lieu Day'
    case 6:
      return 'Work From Home'
    case 7:
      return 'Course'
    case 8:
      return 'Other'
    case 9:
      return 'Maternity'
    case 10:
      return 'Paternity'
    case 11:
      return 'Night Shift Cover'
    case 12:
      return 'Twilight Shift Cover'
    case 13:
      return 'Adjustment'
    case 14:
      return 'Holiday (M)'
    case 15:
      return 'Lieu Day (M)'
    case 16:
      return 'Birthday'
    case 17:
      return 'Overtime'
    case 18:
      return 'Night'
    case 19:
      return 'Overtime (M)'
    case 20:
      return 'Night (M)'
    case 21:
      return 'On Call'
    case 22:
      return 'On Call (M)'
    case 23:
      return 'Call Out'
    case 24:
      return 'Call Out (M)'
    default:
      return ''
  }
}

export interface EditOccurrenceFactoryProps {
  absenceId: number
  employeeResponse: EmployeeResponse
  absenceDay: AbsenceDay
  expectedReturn: Date
  onClose: () => void
}
