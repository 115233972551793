import { isEqual } from 'date-fns'
import { AttendanceCalendarItem } from '../../types/calendar-items'
import { formatDateWithTimeZone } from '../../utils/date-utils'
import { PlottingCalendarRequest } from '../PlottingCalendarCustom/types'

export function setDays(item: AttendanceCalendarItem) {
  if (item.days?.length) {
    return item.days
  }

  return [{ date: formatDateWithTimeZone(item.dateFrom).toISOString(), hours: 8 }]
}

export function anyMultiples(events: PlottingCalendarRequest[]) {
  const allDays: { date: string; hours: number }[] = []

  events.forEach(e => e.days?.forEach(d => allDays.push(d)))

  for (let i = 0; i < allDays.length; i += 1) {
    const testDate = formatDateWithTimeZone(allDays[i].date)
    if (allDays.filter(a => isEqual(testDate, formatDateWithTimeZone(a.date))).length > 1) {
      return true
    }
  }

  return false
}
