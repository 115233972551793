import { SxProps, Button } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'

interface ButtonSmallProps {
  dataTestId?: string
  variant?: 'text' | 'outlined' | 'contained' | undefined
  onClick?: ((event: unknown) => void) | undefined
  label?: string | React.ReactElement | undefined
  disabled?: boolean
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined
  type?: 'button' | 'submit' | 'reset' | undefined
  style?: React.CSSProperties
  minWidth?: string
  sx?: SxProps
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
}

const SmallButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'minWidth',
})<ButtonSmallProps>(({ minWidth }) => ({
  ...{
    display: 'flex',
    alignItems: 'center',
    padding: '0px 15px',
    minWidth: minWidth || '140px',
    margin: '13px 0',
  },
})) as React.ComponentType<ButtonSmallProps>

export default function ButtonSmall({
  dataTestId,
  variant = 'outlined',
  onClick,
  label,
  disabled,
  color = 'primary',
  type,
  style,
  minWidth,
  sx,
  startIcon,
  endIcon,
}: ButtonSmallProps) {
  return (
    <SmallButton
      data-testid={dataTestId}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      color={color}
      type={type}
      style={style}
      minWidth={minWidth}
      sx={sx}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {label}
    </SmallButton>
  )
}
