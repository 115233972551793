import { Dispatch, SetStateAction } from 'react'
import { manualRequestsService } from '../../services/myActionsService'
import { BaseResponse } from '../../types/base-response'
import { dateInYyyyMmDdHhMmSs } from '../../utils/date-utils'
import { AppDispatch } from '../../redux/store'
import UserErrorMessage from '../../utils/errorFilter'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import { AbsenceRequestModel } from './model'
import { setSelectedDays } from '../../redux/reducers/timeOffRequestsReducer'
import { AbsenceDayRequest } from '../../types/absence'

export interface ShiftData {
  hours: number
  startTime: Date
  desc: string
  success: boolean
}

export interface ShiftGetAbsentHoursResponse extends BaseResponse {
  shiftHours: number | undefined
  formattedShiftString: string | undefined
}

export const calculatePartDayAbsence = async (
  absenceData: AbsenceRequestModel,
  selectedDays: AbsenceDayRequest[] | null,
  dispatch: AppDispatch,
  setData: Dispatch<SetStateAction<AbsenceRequestModel>>,
  leftWorkAt: Date | undefined
): Promise<void> => {
  if (!selectedDays || selectedDays.length !== 1) {
    return
  }

  let leftAt: string | undefined

  if (leftWorkAt) {
    leftAt = dateInYyyyMmDdHhMmSs(leftWorkAt)
  }

  manualRequestsService
    .getShiftAbsentHours(
      absenceData.employeeId,
      dateInYyyyMmDdHhMmSs(absenceData.endDate).toString(),
      absenceData.partDayAbsence,
      leftAt,
      absenceData.hasHadLunch,
      absenceData.shiftDescription
    )
    .then(res => {
      if (res.shiftHours !== undefined) {
        const day = selectedDays[0]
        day.hours = res.shiftHours
        dispatch(setSelectedDays([day]))
      }
      if (res.formattedShiftString !== undefined) {
        setData({
          ...absenceData,
          shiftDescription: res.formattedShiftString,
        })
      }
    })
    .catch(err => {
      const response: BaseResponse = err.response.data
      response.errors.forEach(error => {
        dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
      })
    })
}
