import { Box, useMediaQuery } from '@mui/material'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { isMobileLandscapeOnly, isMobilePortrait } from '../../../theme/deviceChecks'

type Props = {
  children?: JSX.Element | JSX.Element[] | string
}

interface MobileContainerProps {
  addMobilePadding?: boolean
}

const StyledDiv = styled('div')<MobileContainerProps>`
  ${({ addMobilePadding }) => addMobilePadding && 'padding: 0 32px 32px'};
`

function MobileContainer({ children }: Props) {
  const isMobPortrait = useMediaQuery(isMobilePortrait())
  const isMobLandscapeOnly = useMediaQuery(isMobileLandscapeOnly())

  return (
    <StyledDiv addMobilePadding={(isMobile || isMobLandscapeOnly) && !isMobPortrait}>
      {children}
    </StyledDiv>
  )
}

export default MobileContainer
