import { combineReducers } from 'redux'
import { appSettingsReducer } from './appSettingsReducer'
import { snackBarReducer } from './snackbarReducer'
import { timeOffRequestsReducer } from './timeOffRequestsReducer'
import { todosReducer } from './todoReducer'
import { userReducer } from './userReducer'
import { settingsPageReducer } from './settingsPageReducer'
import { wallChartReducer } from './wallChartReducer'
import { myRequestsReducer } from './myRequestsReducer'
import { myActionsReducer } from './myActionsReducer'
import { deskBookingReducer } from './deskBookingReducer'
import { deskBookingWizardReducer } from './deskBookingWizardReducer'
import { featureToggleReducer } from './featureToggleReducer'
import { deskBookingSearchReducer } from './deskBookingSearchReducer'
import { deskBookingStatusesReducer } from './deskBookingStatusesReducer'
import { contentReducer } from './contentReducer'

const rootReducer = combineReducers({
  todos: todosReducer,
  appSettings: appSettingsReducer,
  userState: userReducer,
  snackBar: snackBarReducer,
  timeOff: timeOffRequestsReducer,
  settings: settingsPageReducer,
  wallChart: wallChartReducer,
  myRequests: myRequestsReducer,
  myActions: myActionsReducer,
  deskBooking: deskBookingReducer,
  deskBookingSearch: deskBookingSearchReducer,
  deskBookingWizard: deskBookingWizardReducer,
  deskBookingStatus: deskBookingStatusesReducer,
  featureToggles: featureToggleReducer,
  content: contentReducer,
})

export default rootReducer
