import { Autocomplete, TextField, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import { SelectOption } from '../../../services/dashboardService'

type AutoCompleteListProps = {
  label: string
  id: string
  data: any
  textField: string
  value?: any
  error?: boolean
  getOptionLabel?: any
  disabled?: boolean
  multiple?: boolean
  onChange: (e: any, newValue: any | null) => void
  helperText?: string
  limitTags?: number
  disableClearable?: boolean
  isOptionEqualToValue?: (
    option: SelectOption | null | undefined,
    value: SelectOption | null | undefined
  ) => boolean
  dataTestId?: string
}

const StyledPaper = styled(Paper)`
  font-size: 13px !important;
`

function AutocompleteList({
  id,
  label,
  data,
  disabled,
  getOptionLabel,
  textField,
  value,
  error,
  multiple = false,
  onChange,
  helperText,
  limitTags = 99,
  disableClearable = false,
  isOptionEqualToValue,
  dataTestId,
}: AutoCompleteListProps) {
  return (
    <Autocomplete
      data-testid={dataTestId}
      PaperComponent={StyledPaper}
      multiple={multiple}
      id={id}
      disabled={disabled}
      disableClearable={disableClearable}
      options={data}
      getOptionLabel={(option: any) => option[textField]}
      value={value}
      onChange={onChange}
      limitTags={limitTags}
      sx={{
        '& .MuiButtonBase-root': {
          margin: '0px',
        },
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  )
}

export default AutocompleteList
