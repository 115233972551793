import { Grid, Stack, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import Avatar from '../../../shared/UI/Avatar'
import Paragraph from '../../../shared/UI/Paragraph'
import TypeLabel from '../../../shared/UI/TypeLabel'
import { getPalleteTypeByProp } from '../../../theme/palette'
import { ParagraphWithEllipsis } from '../../../shared/UI/Paragraph/variants/ParagraphWithEllipsis'

const columnsManager: GridColDef[] = [
  {
    field: 'photo',
    headerName: '',
    width: 62,
    align: 'left',
    renderCell: cellValues => (
      <Grid item>
        <Avatar employee={cellValues.row.colleague} />
      </Grid>
    ),
  },
  {
    field: 'colleagueAndHours',
    headerName: 'Colleague',
    flex: 1,
    sortable: true,
    align: 'left',
    renderCell: cellValues => (
      <Stack direction="column" rowGap={0.5} sx={{ overflow: 'hidden' }}>
        <ParagraphWithEllipsis weight="bold">{cellValues.row.colleague}</ParagraphWithEllipsis>
        <ParagraphWithEllipsis>
          {cellValues.row.hoursVisible && `${cellValues.row.hours} hours`}
        </ParagraphWithEllipsis>
      </Stack>
    ),
  },
  {
    field: 'absenceType',
    headerName: 'Absence Type',
    type: 'string',
    width: 100,
    align: 'right',
    renderCell: cellValues => (
      <Grid item>
        <TypeLabel
          label={getPalleteTypeByProp(cellValues.row.absenceType).label}
          type={cellValues.row.absenceType}
        />
      </Grid>
    ),
  },
]

const columns: GridColDef[] = [
  {
    field: 'photo',
    headerName: '',
    width: 62,
    renderCell: cellValues => <Avatar employee={cellValues.row.colleague} />,
  },
  {
    field: 'colleague',
    headerName: 'Colleague',
    flex: 1,
    sortable: true,
    renderCell: cellValues => (
      <ParagraphWithEllipsis weight="bold">{cellValues.row.colleague}</ParagraphWithEllipsis>
    ),
  },
  {
    field: 'hours',
    headerName: 'hours',
    type: 'number',
    width: 100,
    renderCell: cellValues => (
      <Grid>
        <Paragraph>{cellValues.row.hoursVisible && `${cellValues.row.hours} hours`}</Paragraph>
      </Grid>
    ),
  },
]

export { columns, columnsManager }
