import { Grid } from '@mui/material'
import TypeLabel from '../../shared/UI/TypeLabel'
import { LegendsFooterProps } from './types'
import { MyRequestsCustomChip } from '../MyRequests/MyRequestsLegendsFilter/components'
import theme from '../../theme/theme'

function LegendsFooter({
  legendProps,
  onSelected,
  sx,
  isAttendanceCalendar,
  isCurrentYear,
  changingYear,
}: LegendsFooterProps) {
  if (!legendProps || (!legendProps?.length && !isAttendanceCalendar)) {
    return null
  }

  return (
    <Grid container sx={sx}>
      <Grid item xs={12} display="flex" justifyContent="flex-end" columnGap={2}>
        {!changingYear &&
          legendProps.map(legendProp => (
            <>
              {legendProp.label !== 'Unknown' && (
                <TypeLabel key={legendProp.type} label={legendProp.label} type={legendProp.type} />
              )}
            </>
          ))}
        {isAttendanceCalendar && (
          <>
            {!changingYear && isCurrentYear && (
              <MyRequestsCustomChip
                label="Today"
                backgroundColor={theme.palette.primary.light}
                borderColor={theme.palette.primary.light}
                color="black"
              />
            )}
            <MyRequestsCustomChip
              label="Bank Holiday"
              backgroundColor="white"
              borderColor="rgb(112, 112, 112)"
              color="black"
            />
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default LegendsFooter
