import { format } from 'date-fns'
import { BaseResponse, ConflictResponse } from '../types/base-response'
import {
  Absence,
  AbsenceCreateRequest,
  AbsenceCreateResponse,
  AbsenceDayDeleteRequest,
  AbsenceDeleteRequest,
  AbsenceDetailsResponse,
  AbsenceExpectedReturnUpdateRequest,
  AbsenceReasonsByLocationResponse,
  AbsenceSickHoursPutRequest,
  AbsenceStatusUpdateRequest,
  DiscountAbsenceDayInsertResponse,
  DiscountAbsenceRequest,
} from '../types/absence'
import { DeleteLate } from '../components/Late/types'
import { httpServiceV1 } from './httpServiceV1'
import { BankHolidaysResponse } from '../types/bank-holiday'
import { AbsenceSickNotePutRequest } from '../types/absence-sick-note'
import { ScaMaintenanceRequest, ScaResponse } from '../types/absence-sca'
import { RtwMaintenanceRequest, RtwResponse } from '../types/return-to-work'
import { LateDetailsResponse, LateOccurrence, LateUpdateRequest } from '../types/late-occurrence'
import { ManagerRequests } from '../types/manager-requests'
import { AttendanceCalendarResponse } from '../types/calendar-items'
import { AbsencePutRequest } from '../types/absence-put-request'
import {
  ManualRequestType1Request,
  ManualRequestType2Request,
  ManualRequestType3Request,
} from '../types/manual-request-types'
import {
  MyRequestDetailResponse,
  RequestDetailResponse,
  RequestUpdatePutRequest,
} from '../types/requests'
import RequestRangeConflictCheckRequest from '../types/request-range-conflict-check-request'
import { AttendanceHistoryByDateResponse } from '../types/attendance-history'
import { ChangeRequest } from '../types/change-request'
import { ShiftGetAbsentHoursResponse } from '../components/AbsenceDrawerComponent/AbsenceDrawerFunctions'

const requestsBaseURL = 'MyActions'

const manualRequestsService = {
  createManualRequest: async (
    body: ManualRequestType1Request | ManualRequestType2Request | ManualRequestType3Request,
    type: number | undefined
  ): Promise<BaseResponse> =>
    httpServiceV1.post(`${requestsBaseURL}/Requests/Manual/Type${type}/`, body),

  getAbsenceOccurrence: async (absenceId: number): Promise<AbsenceDetailsResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/GetAbsenceDetail?AbsenceId=${absenceId}`),

  getRequests: async (
    directReportees: boolean,
    dateFrom: Date,
    dateTo: Date,
    departmentId?: number,
    departmentTeamId?: number,
    requestStatusId = 6, // Pending
    abortController: AbortController | null = null
  ): Promise<ManagerRequests> => {
    let requestURL = `${requestsBaseURL}/Requests?DirectReportees=${directReportees}`
    if (departmentId) {
      requestURL = `${requestURL}&DepartmentId=${departmentId}`
    }
    if (departmentTeamId) {
      requestURL = `${requestURL}&DepartmentTeamId=${departmentTeamId}`
    }
    requestURL = `${requestURL}&DateFrom=${format(dateFrom, 'yyyy-MM-dd')}`
    requestURL = `${requestURL}&DateTo=${format(dateTo, 'yyyy-MM-dd')}`
    requestURL = `${requestURL}&RequestStatusId=${requestStatusId}`

    const config = abortController ? { signal: abortController.signal } : undefined
    return httpServiceV1.get(requestURL, config)
  },

  getLateOccurrence: async (lateId: number): Promise<LateDetailsResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/GetLateDetail?LateId=${lateId}`),

  getAttendanceHistory: async (
    absenceId: number,
    RecordType: string
  ): Promise<AttendanceHistoryByDateResponse> =>
    httpServiceV1.get(
      `${requestsBaseURL}/AttendanceHistory?Id=${absenceId}&RecordType=${RecordType}`
    ),

  getBankHolidays: async (dateRange: string[]): Promise<BankHolidaysResponse> =>
    httpServiceV1.get(
      `${requestsBaseURL}/BankHolidays?DateFrom=${dateRange[0]}&DateTo=${dateRange[1]}`
    ),

  editLate: async (body: LateUpdateRequest): Promise<void> =>
    httpServiceV1.put(`${requestsBaseURL}/Late`, body),

  editAbsence: async (body: AbsencePutRequest): Promise<void> =>
    httpServiceV1.put(`${requestsBaseURL}/Absence`, body),

  editUKAbsence: async (body: AbsencePutRequest): Promise<void> =>
    httpServiceV1.put(`${requestsBaseURL}/UKAbsence`, body),

  extendAbsence: async (body: AbsenceCreateRequest): Promise<AbsenceCreateResponse> =>
    httpServiceV1.post(`${requestsBaseURL}/Absence`, body),

  addSickNote: async (body: AbsenceSickNotePutRequest): Promise<void> =>
    httpServiceV1.put(`${requestsBaseURL}/SickNote`, body),

  getSCA: async (absenceId: number): Promise<ScaResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/SCA?AbsenceId=${absenceId}`),

  resubmitSCA: async (body: ScaMaintenanceRequest): Promise<void> =>
    httpServiceV1.put(`${requestsBaseURL}/SCA`, body),

  createSCA: async (body: ScaMaintenanceRequest): Promise<void> =>
    httpServiceV1.post(`${requestsBaseURL}/SCA`, body),

  getRTW: async (absenceId: number): Promise<RtwResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/RTW?AbsenceId=${absenceId}`),

  saveRTW: async (body: RtwMaintenanceRequest): Promise<void> =>
    httpServiceV1.put(`${requestsBaseURL}/RTW`, body),

  insertRTW: async (body: RtwMaintenanceRequest): Promise<void> =>
    httpServiceV1.post(`${requestsBaseURL}/RTW`, body),

  completeAbsence: async (body: AbsenceStatusUpdateRequest): Promise<void> =>
    httpServiceV1.put(`${requestsBaseURL}/Status`, body),

  deleteLate: async (body: DeleteLate): Promise<LateOccurrence> =>
    httpServiceV1.delWithBody(`${requestsBaseURL}/Late`, body),
  deleteLateQuery: async (lateId: number): Promise<LateOccurrence> =>
    httpServiceV1.del(`${requestsBaseURL}/Late?lateId=${lateId}`),

  deleteAbsenceOccurrence: async (body: AbsenceDeleteRequest): Promise<void> =>
    httpServiceV1.delWithBody(`${requestsBaseURL}/Absence`, body),

  deleteAbsenceDay: async (body: AbsenceDayDeleteRequest): Promise<void> =>
    httpServiceV1.delWithBody(`${requestsBaseURL}/AbsenceDay`, body),

  updateExpectedReturn: async (body: AbsenceExpectedReturnUpdateRequest): Promise<void> =>
    httpServiceV1.put(`${requestsBaseURL}/ExpectedReturnDate`, body),

  createDiscountAbsenceDay: async (
    body: DiscountAbsenceRequest
  ): Promise<DiscountAbsenceDayInsertResponse> =>
    httpServiceV1.post(`${requestsBaseURL}/DiscountAbsenceDay`, body),

  getCalendarItems: async (employeeId: number, year: number): Promise<AttendanceCalendarResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/CalendarItems?EmployeeId=${employeeId}&Year=${year}`),

  updateAbsenceSickHours: async (body: AbsenceSickHoursPutRequest) =>
    httpServiceV1.put(`${requestsBaseURL}/SickHours`, body),

  getHolidayRequestDetail: async (requestId: string): Promise<RequestDetailResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/HolidayRequestDetail?RequestId=${requestId}`),

  getHolidayRequestHistory: async (requestId: number): Promise<Absence> =>
    httpServiceV1.get(`${requestsBaseURL}/RequestHistory?RequestId=${requestId}`),
  updateRequest: async (body: RequestUpdatePutRequest): Promise<void> =>
    httpServiceV1.put(`${requestsBaseURL}/request`, body),

  getRequestRangeConflicts: async (
    body: RequestRangeConflictCheckRequest
  ): Promise<ConflictResponse> => {
    let url = `${requestsBaseURL}/RequestRangeConflicts?`
    if (body.employeeId) {
      url += `EmployeeId=${body.employeeId}`
    }
    if (body.dateFrom) {
      url += `&DateFrom=${format(body.dateFrom, 'yyyy-MM-dd')}`
    }
    if (body.dateTo) {
      url += `&DateTo=${format(body.dateTo, 'yyyy-MM-dd')}`
    }
    if (body.includeConsecutive) {
      url += `&IncludeConsecutive=${body.includeConsecutive}`
    }
    return httpServiceV1.get(url)
  },
  getShiftAbsentHours: async (
    employeeId: number,
    shiftDate: string,
    isPartDayAbsence: boolean,
    absentFrom: string | undefined,
    hasHadLunch: boolean | undefined,
    shiftString: string | undefined
  ): Promise<ShiftGetAbsentHoursResponse> => {
    let url = `MyActions/ShiftAbsentHours?EmployeeId=${employeeId}&ShiftDate=${shiftDate}`

    if (absentFrom && isPartDayAbsence) {
      url += `&AbsentFrom=${absentFrom}`
      url += `&HasHadLunch=${Boolean(hasHadLunch)}`
    }

    if (shiftString) {
      url += `&ShiftString=${shiftString}`
    }

    return httpServiceV1.get(url)
  },
  createQueriedRequest: async (body: ChangeRequest): Promise<void> =>
    httpServiceV1.put(`${requestsBaseURL}/ManualQuery`, body),

  getAbsenceReasonsByEmployeeId: async (
    employeeId: number
  ): Promise<AbsenceReasonsByLocationResponse> =>
    httpServiceV1.get(`SelectValues/AbsenceReasonsByEmployeeId?employeeId=${employeeId}`),
}

export { manualRequestsService }
