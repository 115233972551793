import { useSelector } from 'react-redux'
import { useAppDispatch, RootStore } from '../../redux/store'
import { setEmployeeSelected } from '../../redux/reducers/timeOffRequestsReducer'
import AutocompleteList from '../../shared/UI/AutocompleteList'
import { Employee } from '../../services/dashboardService'

export default function ManagerEmployeeDropDown() {
  const dispatch = useAppDispatch()

  const { directReports } = useSelector((state: RootStore) => state.appSettings)

  const { employeeSelected } = useSelector((state: RootStore) => state.timeOff)

  const handleEmployeeChange = (event: any, employee: Employee | null) => {
    dispatch(setEmployeeSelected(employee ? employee.employeeId : null))
  }

  return (
    <AutocompleteList
      id="employee"
      label="Employee"
      data={directReports}
      textField="employeeName"
      value={directReports.find(emp => emp.employeeId === employeeSelected)}
      onChange={handleEmployeeChange}
      dataTestId="ManualRequest-AdjustmentTab-EmployeeDDL"
    />
  )
}
