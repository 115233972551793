import { Box, Grid, Stack } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setActiveRoute } from '../../redux/reducers/appSettingsReducer'
import Link from '../../shared/UI/Link'
import FullComponentLoadingIcon from '../../shared/UI/LoadingIndicator/FullComponentLoadingIcon'
import NoResult from '../../shared/UI/NoResult'
import Paragraph from '../../shared/UI/Paragraph'
import TypeLabel from '../../shared/UI/TypeLabel'
import Card from '../../shared/layout/Card'
import { getPalleteTypeByProp } from '../../theme/palette'
import { PendingRequest } from '../../types/my-pending-requests'

type Props = {
  pendingRequests: PendingRequest[] | undefined
  isLoading: boolean
}

function PendingRequests({ pendingRequests, isLoading }: Props) {
  const [firstPendingRequest, setFirstPendingRequest] = useState<PendingRequest>()
  const [displayHours, setDisplayHours] = useState<string>('0')
  const [message, setMessage] = useState<string>('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading && pendingRequests) {
      const firstPendingReq = pendingRequests.sort(
        (a, b) => new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime()
      )[0]
      setFirstPendingRequest(firstPendingReq)

      if (!firstPendingReq) {
        return
      }

      const hours = firstPendingReq.hours
        ? firstPendingReq.hours?.toString()
        : firstPendingReq.requestDays
            .reduce((accumulator, day) => accumulator + day.hours, 0)
            .toString()

      switch (firstPendingReq.requestType?.toLowerCase()) {
        case 'shift':
          setDisplayHours(firstPendingReq.shiftStartTime?.toString() || '0')
          setMessage('Start Time')
          break
        default:
          setDisplayHours(hours || '0')
          setMessage(hours === '1' ? 'hour' : 'hours')
      }
    }
  }, [pendingRequests, isLoading])

  const shouldShowEndDate = () =>
    firstPendingRequest?.requestType.toLowerCase() === 'dayoff' ||
    firstPendingRequest?.requestType.toLowerCase() === 'shift' ||
    firstPendingRequest?.requestType.toLowerCase() === 'birthday' ||
    firstPendingRequest?.requestType.toLowerCase() === 'buy' ||
    firstPendingRequest?.requestType.toLowerCase() === 'sell'

  const noHours = useMemo(() => displayHours === '0', [displayHours])

  return (
    <Card title="Pending Requests" style={{ minHeight: '228px', boxSizing: 'border-box' }}>
      <Grid container spacing={4} sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginTop: `${noHours ? '8px' : '0px'}`,
            }}
          >
            <FullComponentLoadingIcon
              loading={isLoading}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              {firstPendingRequest ? (
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="column" justifyContent="center">
                    <Paragraph>
                      {`${new Date(firstPendingRequest!.dateFrom).toLocaleDateString()}`}
                      {!shouldShowEndDate() &&
                        ` - ${new Date(firstPendingRequest!.dateTo!).toLocaleDateString()}`}
                    </Paragraph>
                    {!noHours && (
                      <Paragraph style={{ marginTop: '8px' }}>
                        {`${displayHours} ${message}`}
                      </Paragraph>
                    )}
                  </Stack>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TypeLabel
                      label={getPalleteTypeByProp(firstPendingRequest!.requestType).label}
                      type={firstPendingRequest!.requestType}
                    />
                  </Box>
                </Stack>
              ) : (
                <Box height={102}>
                  <NoResult message="You have no requests pending" />
                </Box>
              )}
            </FullComponentLoadingIcon>
          </Grid>
          {firstPendingRequest! && (
            <Grid container xs={12} sx={{ height: '52px', alignContent: 'center' }}>
              <Link
                linkText="View All"
                onClick={() => {
                  dispatch(setActiveRoute('/myavailability'))
                  navigate('/myavailability/', {
                    state: {
                      previousLocation: '/dashboard',
                      calendar: false,
                      gridFilters: ['Pending'],
                    },
                  })
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

export default PendingRequests
