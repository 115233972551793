import { addDays, format, isEqual, subDays } from 'date-fns'
import { PlottingCalendarDataProps, PlottingCalendarDates, PlottingCalendarRequest } from './types'
import { formatDateWithTimeZone } from '../../utils/date-utils'
import { getPalleteTypeByProp } from '../../theme/palette'

export const request = (r: PlottingCalendarRequest): PlottingCalendarRequest => ({
  id: r.id,
  submitDateTime: r.submitDateTime,
  dateFrom: r.dateFrom,
  dateTo: r.dateTo,
  statusDescription: r.statusDescription,
  isCancellation: r.isCancellation,
  isQueried: r.isQueried,
  requestType: r.requestType,
  days: r.days,
})

export const isRequestTypeWithoutHours = (requestType: string): boolean =>
  requestType === 'Day Off' ||
  requestType === 'Birthday' ||
  requestType === 'Shift' ||
  requestType === 'Late'

export const buildData = ({
  requests,
  filters,
  year = new Date().getFullYear(),
  isFinancialYear,
}: PlottingCalendarDataProps) => {
  let dates: PlottingCalendarDates[] = []
  requests
    .filter(f => {
      let financialYearCheck = false
      let yearCheck = false
      let filterCheck = false

      if (isFinancialYear) {
        financialYearCheck =
          new Date(f.dateFrom).getFullYear() === year + 1 ||
          new Date(f.dateTo || f.dateFrom).getFullYear() === year + 1
      }

      yearCheck =
        new Date(f.dateFrom).getFullYear() === year ||
        new Date(f.dateTo || f.dateFrom).getFullYear() === year

      filterCheck = filters.some(s => s.toLowerCase() === f.requestType.toLowerCase())

      return (financialYearCheck || yearCheck) && filterCheck
    })
    .forEach(r => {
      let { days } = r
      if (days.length === 0) {
        days = [{ date: r.dateFrom, hours: 0 }]
      }

      days.forEach(d => {
        const selectedDate = dates.find(s => {
          const dd = formatDateWithTimeZone(d.date)
          const dateA = `${s.date.getFullYear()}${s.date.getMonth()}${s.date.getDate()}`
          const dateB = `${dd.getFullYear()}${dd.getMonth()}${dd.getDate()}`
          return dateA === dateB
        })
        if (selectedDate) {
          selectedDate.eventType += `,${r.requestType}`
          selectedDate.isPending =
            selectedDate.isPending || r.statusDescription.toLowerCase() === 'pending'
          selectedDate.requests.push(request(r))
        } else if (d.hours > 0 || isRequestTypeWithoutHours(r.requestType)) {
          dates.push({
            date: formatDateWithTimeZone(d.date),
            eventType: r.requestType,
            isPending: r.statusDescription.toLowerCase() === 'pending',
            showInCalendar: true,
            requests: [request(r)],
            isDisabled: false,
          })
        }
      })
    })
  dates = dates.sort((a, b) => {
    const aDate = format(a.date, 'yyyyMMdd')
    const bDate = format(b.date, 'yyyyMMdd')
    if (Number(aDate) < Number(bDate)) {
      return -1
    }
    if (Number(aDate) > Number(bDate)) {
      return 1
    }
    return 0
  })
  dates = dates.map(d => ({
    ...d,
    isEventStart: !dates.some(
      s =>
        isEqual(s.date, subDays(d.date, 1)) &&
        s.requests.find(f => d.requests.some(sr => sr.id === f.id))
    ),
    isEventEnd: !dates.some(
      s =>
        isEqual(s.date, addDays(d.date, 1)) &&
        s.requests.find(f => d.requests.some(sr => sr.id === f.id))
    ),
  }))
  return dates
}

interface PlottingCalendarButtonStyleProps {
  plottingColour: string | undefined
  class?: string
}

export const getPlottingStyle = (
  eventType: string | undefined,
  isEventStart: boolean | undefined,
  isEventEnd: boolean | undefined,
  isMultipleRequests: boolean | undefined
): PlottingCalendarButtonStyleProps => {
  const styleObj: PlottingCalendarButtonStyleProps = {
    plottingColour: undefined,
    class: '',
  }

  if (!eventType) {
    return styleObj
  }

  if (isMultipleRequests) {
    styleObj.plottingColour = '#b9b9b9'
  } else {
    const palette = getPalleteTypeByProp(eventType)
    styleObj.plottingColour = palette.color
  }

  let cls = ''

  if (isEventStart) {
    cls += 'isEventStart '
  }
  if (isEventEnd) {
    cls += 'isEventEnd'
  }
  styleObj.class = cls

  return styleObj
}
