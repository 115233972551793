import { format } from 'date-fns'

export const toInteger = (str: any, def: number) => {
  const parsed = parseInt(str, 10)
  if (Number.isNaN(parsed)) {
    return def
  }
  return parsed
}

export const stringifyDaysArray = (input: any) => {
  let res = ''
  if (input?.days) {
    input.days.forEach((day: string | number | Date) => {
      res += format(new Date(day), 'yyyy-MM-dd HH:mm:ss xx')
    })
  }
  return res
}
