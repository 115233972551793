import { Grid, Stack } from '@mui/material'
import Card from '../../shared/layout/Card'
import { SummaryProps } from '../Late/types'
import Paragraph from '../../shared/UI/Paragraph'

export type SummaryPropsLocal = {
  summaryItemsLeft: Array<SummaryProps>
  summaryItemsRight: Array<SummaryProps>
}

const singlePane = (item: any) => {
  if (!item.title.includes('Comments')) {
    return (
      <Grid item xs={12} key={item.id}>
        <Stack direction="row">
          <Grid item xs={12} display="flex" justifyContent="flex-start" mt={2}>
            <Paragraph weight="bold">{item.title}</Paragraph>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end" mt={2}>
            <Paragraph>{item.value}</Paragraph>
          </Grid>
        </Stack>
      </Grid>
    )
  }
  return (
    <Grid item xl={12} key={item.id}>
      <Grid item xs={12} display="flex">
        <Paragraph weight="bold">{item.title}</Paragraph>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Paragraph>{item.value}</Paragraph>
      </Grid>
    </Grid>
  )
}

function SummaryTwoColumn({ summaryItemsLeft, summaryItemsRight }: SummaryPropsLocal) {
  return (
    <Card>
      <Grid container columnSpacing={12}>
        <Grid item xs={12} xl={6}>
          {summaryItemsLeft?.map(item => singlePane(item))}
        </Grid>
        <Grid item xs={12} xl={6}>
          {summaryItemsRight?.map(item => singlePane(item))}
        </Grid>
      </Grid>
    </Card>
  )
}

export default SummaryTwoColumn
