import styled from 'styled-components'
import theme from '../../../theme/theme'

export const TabItems = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  ${theme.breakpoints.down('sm')} {
    align-items: flex-start;
  }
`
