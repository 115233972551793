import styled from 'styled-components'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, styled as MuiStyled, Drawer } from '@mui/material'
import { Link } from 'react-router-dom'
import theme from '../../../theme/theme'
import { isMobilePortrait, isMobilePortraitUp, isTabletDown } from '../../../theme/deviceChecks'
import clockOuter from '../../../assets/clockOuter.svg'

export const StyledLink = styled(Link)`
  .mobileNavDrawer & {
    margin-left: 16px;
    width: 65%;
  }

  ${isMobilePortrait()} {
    margin-top: 50px;
  }

  ${isMobilePortraitUp()} {
    .mobileNavDrawer & {
      margin-top: 40px;
    }

    .desktopNavDrawer & {
      margin-bottom: 40px;
      margin-left: 12px;
    }
  }
`

export const NavItemsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`

interface SidebarPaneProps {
  expanded: boolean
}
/* eslint-disable */
export const SidebarPane = styled.div<SidebarPaneProps>`
  left: 0;
  top: 0;
  flex: 0 0 auto;
  height: auto;
  background-color: white;
  border-radius: 0 16px 16px 0;
  z-index: 1200;
  transition: width 0.3s ease, transform 0.3s ease;
  height: 100vh;
  position: sticky;
  width: ${({ expanded }) => (expanded ? '280px' : '96px')};

  ${isTabletDown()} {
    border-radius: 0;
  }
`

export const MobileNavDrawer = styled(Drawer)`
  ${isMobilePortrait()} {
    & .MuiDrawer-paper {
      width: 75vw;
      max-width: 300px;
    }
  }
  ${isMobilePortraitUp()} {
    & .MuiDrawer-paper {
      width: 280px;
    }
  }
`

interface ExpandCollapseButtonProps {
  expanded: boolean
}
export const ExpandCollapseButton = MuiStyled(ChevronRightIcon, {
  shouldForwardProp: prop => prop !== 'expanded',
})<ExpandCollapseButtonProps>`
  && {
    width: 24px;
    height: 24px;
    padding: 2px;
    background-color: white;
    color: ${theme.palette.text.primary};
    border-radius: 50%;
    position: absolute;
    right: -14px;
    top: 40px;
    cursor: pointer;
    transform: ${({ expanded }) => (expanded ? 'rotate(180deg)' : '')};
    -webkit-transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    z-index: 1;
  }
`

type NavigationItemsContainerProps = {
  mobile: boolean
}

export const StyledNavigationItemsContainer = styled(Box)<NavigationItemsContainerProps>`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: space-between;
  margin: auto;
  height: 100%;
  box-sizing: border-box;

  .desktopNavDrawer & {
    overflow: hidden;
    padding: 41px 24px;
  }

  .mobileNavDrawer & {
    margin-left: 32px;
  }
`
const LogoComponent = MuiStyled(Box, {
  shouldForwardProp: prop => prop !== 'mobile',
})<NavigationItemsContainerProps>({
  display: 'flex',
  paddingRight: '10px',
  position: 'relative',
  maxWidth: '28px',

  '.mobileNavDrawer &': {
    '&.logoHidden': {
      transform: 'translateX(-100%)',
      transition: 'transform ease 0.3s',
    },
  },

  '& .minuteHand': {
    transform: 'rotate(90deg)',
    transformOrigin: 'bottom center',
    transition: 'transform ease 0.35s',
  },

  '&:hover .minuteHand': {
    transform: 'rotate(810deg)',
    transition: 'transform ease 1s',
  },

  '& .hourHand': {
    transform: 'rotate(0.01deg)',
    transformOrigin: 'bottom center',
    transition: 'transform ease 0.35s',
  },

  '&:hover .hourHand': {
    transform: 'rotate(360deg)',
    transition: 'transform ease 1s',
  },
})

const StyledImgClock = MuiStyled('img')({
  height: '100%',
  width: 'auto',
})

const StyledImgType = styled('img')`
  flex-grow: 1;
  opacity: 1;
  margin-left: 20px;

  &:not(.typeHidden) {
    transition: opacity 0.5s ease-out 0.25s;
  }

  .desktopNavDrawer & {
    height: 28px;
    &.typeHidden {
      opacity: 0;
    }
  }

  .mobileNavDrawer & {
    height: 22px;
    margin-left: 20px;
  }
`

const StyledDivMinuteHand = styled('div')`
  position: absolute;
  height: 60%;
  width: 12%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: rgb(252 175 37 / 70%);
  bottom: 50%
  left: 44%;

  &:before {
    content: "";
    position: absolute;
    height: 9.7%;
    width: 100%;
    bottom: -9.7%;
    background-color: rgb(252 175 37 / 70%);
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`

const StyledDivHourHand = styled('div')`
  position: absolute;
  height: 60%;
  width: 12%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: rgb(41 170 226 / 70%);
  bottom: 50%
  left: 44%;

  &:before {
    content: "";
    position: absolute;
    height: 9.7%;
    width: 100%;
    bottom: -9.7%;
    background-color: rgb(41 170 226 / 70%);
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`
const ClockContainer = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
`
const ClockOuterContainer = styled(Box)`
  position: relative;

  .desktopNavDrawer & {
    height: 25px;
  }

  .mobileNavDrawer & {
    height: 22px;
  }
`

interface LogoIconProps {
  expanded: boolean
  mobile: boolean
}

function LogoIcon({ expanded, mobile }: LogoIconProps) {
  return (
    <LogoComponent mobile={mobile} className={!expanded ? 'logoHidden' : ''}>
      <ClockOuterContainer>
        <StyledImgClock src={clockOuter} />
        <ClockContainer>
          <StyledDivMinuteHand className="minuteHand" />
          <StyledDivHourHand className="hourHand" />
        </ClockContainer>
      </ClockOuterContainer>

      <StyledImgType
        className={!expanded ? 'typeHidden' : ''}
        src="/icons/planner365-type.svg"
        alt="planner365"
      />
    </LogoComponent>
  )
}
export default LogoIcon
