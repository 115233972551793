import { styled } from '@mui/material/styles'
import { Box, Card } from '@mui/material'
import { isMobilePortrait } from '../../../theme/deviceChecks'

export const DashBoardMySummaryCard = styled(Card)`
  position: relative;
  box-shadow: 1px 2px 10px #00000014;
  border-radius: 16px;
  padding: 32px;
  height: 100%;
  box-sizing: border-box;

  ${isMobilePortrait()} {
    border-radius: 0;
  }
`

export const MySummaryFooterBgContainer = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
`

export const MySummaryFooterBg = styled('img')`
  height: 110px;
  display: block;
`

export const MySummaryFooterImg = styled('img')`
  position: absolute;
  bottom: -15px;
  left: 40px;
  height: 140px;
`
