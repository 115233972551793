import { DateRange } from '@mui/x-date-pickers-pro'

export const IsAbsentFromBetweenShiftTime = (
  absentFrom: Date | null,
  shiftStart: Date | undefined,
  shiftEnd: Date | undefined
): boolean => {
  if (!absentFrom) return false

  if (!shiftStart) return false

  if (!shiftEnd) return false

  return absentFrom >= shiftStart && absentFrom <= shiftEnd
}

export const CreateShiftTime = (shiftDate: Date | null, shiftTime: string): Date | undefined => {
  if (!shiftTime) return undefined

  if (!shiftDate) return undefined

  const time = shiftTime.split('.')

  if (time.length !== 2) return undefined

  return new Date(
    shiftDate.getFullYear(),
    shiftDate.getMonth(),
    shiftDate.getDate(),
    Number(time[0]),
    Number(time[1]),
    0
  )
}

export const validateAbsentFrom = (
  absentFrom: Date | null,
  shiftDescription: string,
  dateRange: DateRange<Date>,
  onSuccess: () => void,
  onError: (errorMessage: string) => void
) => {
  const times = shiftDescription.split(' ')[0].split('-')

  if (times.length !== 2) return undefined

  const startTime = CreateShiftTime(dateRange[0], times[0])
  const endTime = CreateShiftTime(dateRange[1], times[1])

  if (endTime! < startTime!) {
    endTime?.setDate(endTime.getDate() + 1)
  }

  if (IsAbsentFromBetweenShiftTime(absentFrom, startTime, endTime)) {
    onSuccess()
  } else {
    onError(
      `The absent from [${absentFrom?.toLocaleString()}]\nneeds to be between (${startTime?.toLocaleString()} and ${endTime?.toLocaleString()})`
    )
  }
}
