import { Grid, TextField } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isEqual } from 'date-fns'
import {
  requestFieldsDefaultState,
  RequestFieldsModal,
  RequestType,
  RequestTypeNameString,
} from '../../models'
import { hideDrawer } from '../../redux/reducers/appSettingsReducer'
import {
  resetNewRequestState,
  setAlerts,
  setComments,
  setDate,
  setDefaultDateRange,
  setRequestType,
  timeOffRequestsState,
} from '../../redux/reducers/timeOffRequestsReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import Alert from '../../shared/UI/Alert/Alert'
import Button from '../../shared/UI/Button'
import DatePicker from '../../shared/UI/DatePicker'
import DrawerFooter from '../../shared/UI/DrawerFooter'
import Paragraph from '../../shared/UI/Paragraph'
import { formatRequestTypeString, getRestrictedDate } from '../../utils/app-utils'
import { formatDatesToDelimitedString, formatDateWithTimeZone } from '../../utils/date-utils'

type Props = {
  disabledDates?: string[]
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => any
  isHTL?: boolean
  onClose: (event?: React.FormEvent<HTMLFormElement>, reason?: string) => void
  submitLoading?: boolean
  requestTypeId: number
}
function DayOffRequest({
  disabledDates,
  handleSubmit,
  isHTL,
  onClose,
  submitLoading = false,
  requestTypeId,
}: Props) {
  const dispatch = useAppDispatch()

  const { selectedDate, defaultDateRange, comments, alerts } = useSelector<
    RootStore,
    timeOffRequestsState
  >((state: RootStore) => state.timeOff)

  const bankHolidays = useSelector<RootStore, any[] | null>(
    (state: RootStore) => state.appSettings.bankHolidays
  )

  const [bankHolidayDatesSelected, setBankHolidayDates] = useState<string[] | undefined>([])
  const [fieldsTouched, setFieldsTouched] = useState<RequestFieldsModal>(requestFieldsDefaultState)
  const [hasConflict, setHasConflict] = useState<boolean>(false)
  const [hasErrorConflict, setHasErrorConflict] = useState<boolean>(false)
  const [displayDate, setDisplayDate] = useState<Date | null>(null)

  const { currentEntitlementPeriodResponse, calendarDetailsResponse } = useSelector(
    (state: RootStore) => state.appSettings
  )

  useEffect(() => {
    if (!defaultDateRange) {
      return
    }
    dispatch(setDate(defaultDateRange[0]))
  }, [defaultDateRange, dispatch])

  useEffect(() => {
    dispatch(setRequestType(RequestType.DAY_OFF))
    return () => {
      dispatch(resetNewRequestState())
    }
  }, [dispatch])

  useEffect(() => {
    if (!alerts) {
      setHasConflict(false)
      setHasErrorConflict(false)
    }

    if (alerts) {
      const hasConflicts = !!alerts
      setHasConflict(hasConflicts)
      const errorConflictExists = alerts
        .filter(f => f.conflictType.toLowerCase() === 'error')
        .some(alert =>
          alert.conflictDates.some(fdate =>
            isEqual(formatDateWithTimeZone(fdate), formatDateWithTimeZone(fdate))
          )
        )
      setHasErrorConflict(errorConflictExists)
    }
  }, [alerts])

  const resetConflicts = () => {
    setAlerts([])
    setHasConflict(false)
    setHasErrorConflict(false)
  }

  const isFormValid = () => {
    setFieldsTouched({ ...fieldsTouched, date: true, comments: true })
    if (!selectedDate || !comments || comments.length === 0) {
      return false
    }

    return true
  }

  const checkBankHolidays = useCallback(() => {
    const bankHolidaysDates = bankHolidays?.map(x => new Date(x.holidayDate).toLocaleDateString())
    const checkedDay = selectedDate
    setBankHolidayDates(bankHolidaysDates?.filter(x => checkedDay?.toLocaleDateString() === x))
  }, [bankHolidays, selectedDate])

  const BHDatesToDisplay = () => bankHolidayDatesSelected?.join(' & ')

  useEffect(() => {
    if (selectedDate) {
      checkBankHolidays()
      setDisplayDate(selectedDate)
    }
  }, [checkBankHolidays, selectedDate])

  const conflictMessage = (requestType: string, dates: Date[], manualRequestType?: string) => {
    const plural = dates.length > 1
    const requestTypeStr =
      requestType.toLowerCase() === 'manual'
        ? formatRequestTypeString(manualRequestType!)
        : formatRequestTypeString(requestType)

    return `You already have${plural ? '' : ' a'} ${requestTypeStr} booking${
      plural ? 's' : ''
    } for th${plural ? 'ese' : 'is'} date${plural ? 's' : ''} ${formatDatesToDelimitedString(
      dates
    )}`
  }
  const submitButtonTestId = `Dashboard-NewRequest-${RequestTypeNameString[requestTypeId]}submitBtn`
  const cancelButtonTestId = `Dashboard-NewRequest-${RequestTypeNameString[requestTypeId]}CancelBtn`
  const commentsTestId = `Dashboard-NewRequest-${RequestTypeNameString[requestTypeId]}Comments`
  const dateRangeTestId = `Dashboard-NewRequest-${RequestTypeNameString[requestTypeId]}DateRangePicker`

  return (
    <Grid
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isFormValid()) {
          setFieldsTouched(requestFieldsDefaultState)
          handleSubmit(e)
        }
      }}
    >
      <Grid container spacing={4} mt={0}>
        {hasConflict &&
          alerts &&
          alerts.map(alert => (
            <Grid item xs={12} key={`${alert.conflictType}-${Math.random()}`}>
              <Alert
                severity="error"
                message={conflictMessage(
                  alert.requestType,
                  alert.conflictDates,
                  alert.manualRequestType
                )}
              />
            </Grid>
          ))}
        {bankHolidayDatesSelected && bankHolidayDatesSelected?.length > 0 && (
          <Grid item xs={12}>
            <Alert severity="info" message={`There are bank holidays on: ${BHDatesToDisplay()}`} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Paragraph weight="bold">Details</Paragraph>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <DatePicker
                label="Date"
                value={displayDate}
                disabledDates={disabledDates}
                maxDate={getRestrictedDate(
                  isHTL || false,
                  currentEntitlementPeriodResponse?.entitlementPeriod || 'FinancialYear',
                  selectedDate,
                  calendarDetailsResponse || []
                )}
                onChange={(newValue: Date | null) => {
                  dispatch(setDefaultDateRange(undefined))
                  setFieldsTouched({ ...fieldsTouched, date: true })
                  setDisplayDate(newValue)
                  if (newValue !== null && newValue.getFullYear() >= 1900) {
                    dispatch(setDate(newValue))
                  }
                  resetConflicts()
                }}
                error={fieldsTouched.date && selectedDate === null}
                helperText="Required"
                dataTestId={dateRangeTestId}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Comments"
                rows={5}
                multiline
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldsTouched({ ...fieldsTouched, comments: true })
                  dispatch(setComments(e.target.value.trim()))
                  resetConflicts()
                }}
                onKeyUp={() => {
                  setFieldsTouched({ ...fieldsTouched, comments: true })
                }}
                error={fieldsTouched.comments && !comments && comments.length === 0}
                helperText="Required"
                data-testid={commentsTestId}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} />
      </Grid>
      <DrawerFooter>
        <Button
          color="secondary"
          label="Cancel"
          onClick={() => {
            dispatch(resetNewRequestState())
            dispatch(hideDrawer())
            onClose()
          }}
          dataTestId={cancelButtonTestId}
        />
        <Button
          label="Submit"
          type="submit"
          disabled={hasErrorConflict}
          loading={submitLoading}
          dataTestId={submitButtonTestId}
        />
      </DrawerFooter>
    </Grid>
  )
}

export default DayOffRequest
