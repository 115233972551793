import { Checkbox, Stack, TextField } from '@mui/material'
import { format } from 'date-fns'
import { RequestDayRequest, RequestDetailResponse } from '../../../types/requests'
import { FieldTouch } from '../../OccurrenceRequest'

interface Props {
  day: RequestDayRequest
  setRequestDetail: React.Dispatch<React.SetStateAction<RequestDetailResponse | null>>
  setRowRequestDayId: React.Dispatch<React.SetStateAction<number[]>>
  rowRequestDayId: number[]
  fieldsTouched: FieldTouch
  setFieldsTouched: React.Dispatch<React.SetStateAction<FieldTouch>>
  isDisabled: boolean
  checkboxRequired: boolean
}

export function CheckboxTextfield({
  day,
  setRequestDetail,
  setRowRequestDayId,
  rowRequestDayId,
  setFieldsTouched,
  isDisabled,
  fieldsTouched,
  checkboxRequired,
}: Props) {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { requestDayId } = day
    setRequestDetail(current => {
      const { requestDays, ...rest } = current as RequestDetailResponse
      const dayFound =
        requestDayId !== 0
          ? requestDays.find(d => d.requestDayId === requestDayId)
          : requestDays.find(d => d.day === day.day)
      if (dayFound) {
        dayFound.checked = e.target.checked
      }
      const checkedDays = requestDays.filter(requestDay => requestDay.checked)
      const totalRequestedHours = Object.values(checkedDays).reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.hours),
        0
      )
      rest.totalRequestedHours = totalRequestedHours
      const retVal = { requestDays, ...rest }
      return retVal
    })
  }

  const handleHoursChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { requestDayId } = day
    if (!e.target.value) {
      setRowRequestDayId([...rowRequestDayId, requestDayId])
    } else {
      const dayIds = rowRequestDayId
      const index = dayIds.indexOf(requestDayId)
      if (index > -1) {
        dayIds.splice(index, 1)
      }
      setRowRequestDayId(dayIds)
    }

    setRequestDetail(current => {
      const { requestDays, ...rest } = current as RequestDetailResponse
      const dayFound =
        requestDayId !== 0
          ? requestDays.find(d => d.requestDayId === requestDayId)
          : requestDays.find(d => d.day === day.day)
      if (dayFound) {
        dayFound.hours = e.target.value
        dayFound.checked = Number(e.target.value) > 0
      }

      const checkedDays = requestDays.filter(requestDay => requestDay.checked)
      const totalRequestedHours = Object.values(checkedDays).reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.hours),
        0
      )
      rest.totalRequestedHours = totalRequestedHours
      const retVal = { requestDays, ...rest }
      return retVal
    })
    setFieldsTouched({
      rows: !day.hours,
      reviewerComments: false,
    })
  }

  return (
    <Stack direction="row" gap={2}>
      {checkboxRequired && (
        <Checkbox
          sx={{ visibility: day.requestDayId === 0 ? 'unset' : '' }}
          checked={day.checked}
          onChange={e => handleCheckboxChange(e)}
          disabled={!day.checked && isDisabled}
        />
      )}
      <TextField
        type="number"
        InputProps={{
          inputProps: { step: '0.1', min: 0.1, max: 24 },
        }}
        fullWidth
        label={format(new Date(day.day), 'ccc dd/MM/yyyy')}
        defaultValue={day.hours}
        value={day.hours}
        key={day.day.toString()}
        onChange={e => handleHoursChanged(e)}
        onBlur={() =>
          setFieldsTouched({
            ...fieldsTouched,
            rows: true,
          })
        }
        error={rowRequestDayId.includes(day.requestDayId)}
        disabled={isDisabled || !day.checked}
        onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
      />
    </Stack>
  )
}
