import { LocationGroups } from "../../../../types/location-groups"
import store from '../../../../redux/store'

interface LocationDocType {
    location: LocationGroups,
    docType: string
  }
  
  const docTypes: LocationDocType[] = [
    {
      location: LocationGroups.NONE,
      docType: 'SCA'
    },
    {
      location: LocationGroups.UK,
      docType: 'ED'
    }
  ]
  
  const defaultDocType = docTypes.find(x => x.location === LocationGroups.NONE)!
  
  export function featureOnForLocation(location: LocationGroups) {
    const toggles = store.getState().featureToggles
  
    switch (location) {
      case LocationGroups.UK: return toggles.territoryAttendanceUK
      default: return false
    }
  }
  
  export function createDocType(location: LocationGroups) {
    const featureOn = featureOnForLocation(location)
    if (!featureOn) {
      return defaultDocType
    }
  
    const locationObject = docTypes.find(x => x.location === location)
    return locationObject ?? defaultDocType
  }