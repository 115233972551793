import { Dispatch, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { RootStore } from '../../redux/store'
import { myExtrasService } from '../../services/myExtrasService'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import useSellRequestValidation from './useSellValidation'
import { MyEntitlementResponse } from '../../types/my-entitlement-response'
import { SellHoursType } from '../../types/sell-hours-type'
import { ApiError, BaseResponse } from '../../types/base-response'
import UserErrorMessage from '../../utils/errorFilter'

function useSellRequestForm(
  dispatch: Dispatch<AnyAction>,
  closeDrawer: () => void,
  refreshEntitlement: (() => void) | undefined,
  hours: SellHoursType | undefined,
  myEntitlement: MyEntitlementResponse | undefined,
  requestedSellHours: number
) {
  const [numberOfHours, setNumberOfHours] = useState<number | ''>(0)
  const [fieldsTouched, setFieldsTouched] = useState({ hours: false })
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)

  const { maxToSell, validateHours, shouldPreventSubmit } = useSellRequestValidation(
    hours,
    myEntitlement,
    requestedSellHours,
    dispatch
  )

  const showErrors = useCallback(
    (errors: ApiError[]) => {
      errors.forEach(error => {
        dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
      })
    },
    [dispatch]
  )

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!currentEntitlementPeriodResponse) {
        return
      }

      const { shouldPreventSend, requestedHours } = shouldPreventSubmit(numberOfHours)
      if (shouldPreventSend || requestedHours === null) {
        return
      }

      setSubmitLoading(true)
      myExtrasService
        .postSellHolidayHours({
          year: currentEntitlementPeriodResponse.year,
          numberOfHours: requestedHours,
        })
        .then(response => {
          setSubmitLoading(false)
          if (response.status === 200 && response.errors.length > 0) {
            showErrors(response.errors)
          } else {
            dispatch(showSuccessMessage('Sell hours request successful.'))
            closeDrawer()
          }
          if (refreshEntitlement) {
            refreshEntitlement()
          }
        })
        .catch(err => {
          setSubmitLoading(false)
          const response: BaseResponse = err.response.data
          showErrors(response.errors)
        })
    },
    [
      currentEntitlementPeriodResponse,
      shouldPreventSubmit,
      refreshEntitlement,
      numberOfHours,
      closeDrawer,
      showErrors,
      dispatch,
    ]
  )

  const isFormValid = useCallback(() => {
    setFieldsTouched({ ...fieldsTouched, hours: true })
    return Number(numberOfHours) >= 1
  }, [fieldsTouched, numberOfHours])

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (isFormValid()) {
        handleSubmit(e)
      } else {
        dispatch(showErrorMessage('Number of hours to sell must be at least 1'))
      }
    },
    [dispatch, handleSubmit, isFormValid]
  )

  const textFieldOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNumberOfHours(e.target.value.trim() === '' ? '' : validateHours(Number(e.target.value)))
    },
    [validateHours]
  )

  const textFieldOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
      if (e.target.value.trim() === '') {
        setNumberOfHours(0)
      }
      setFieldsTouched({ hours: true })
    },
    []
  )

  const textFieldOnWheel = useCallback(
    (e: React.WheelEvent<HTMLDivElement>) => e.target instanceof HTMLElement && e.target.blur(),
    []
  )

  const disableSubmitButton = useCallback(
    (): boolean => numberOfHours === 0 || maxToSell === 0,
    [maxToSell, numberOfHours]
  )

  return {
    numberOfHours,
    fieldsTouched,
    submitLoading,
    onSubmit,
    textFieldOnChange,
    textFieldOnBlur,
    textFieldOnWheel,
    disableSubmitButton,
  }
}

export default useSellRequestForm
