import styled from 'styled-components'
import { Box, Card as MuiCard } from '@mui/material'
import { isMobileDown, isMobilePortrait } from '../../../theme/deviceChecks'

export const StyledCard = styled(MuiCard)`
  &.MuiCard-root {
    position: relative;
    background-color: #f8fdfe;
    border-radius: 16px;
    padding: 0;
    flex: 1 1 auto;
    overflow: visible;
  }

  ${isMobilePortrait()} {
    &.MuiCard-root {
      border-radius: 0;
    }
  }

  ${isMobileDown()} {
    &.MuiCard-root {
      height: 400px;
    }
  }
`

export const ComingupHeaderOuterContainer = styled(Box)`
  height: 19.5%;

  ${isMobileDown()} {
    & {
      height: 30%;
    }
  }
`

export const ComingupHeaderBgContainer = styled.div`
  position: absolute;
  background-color: #c8f0ec;
  overflow: hidden;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  height: 19.5%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  padding-left: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  ${isMobilePortrait()} {
    border-radius: 0;
  }

  ${isMobileDown()} {
    & {
      height: 30%;
    }
  }
`

export const ComingupHeaderImageContainer = styled.div`
  position: absolute;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  height: 19.5%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  & > p {
    padding: 64px 0 0 32px;
  }
  ${isMobilePortrait()} {
    border-radius: 0;
  }

  ${isMobileDown()} {
    & {
      height: 30%;
    }
  }
`

export const ComingUpImageBg = styled('img')`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
`

export const ComingUpImage = styled('img')`
  position: absolute;
  height: 90%;
  top: 5%;
  right: -10px;

  ${isMobilePortrait()} {
    right: 0;
  }
`

export const ComingupLowerContainer = styled(Box)`
  height: 80.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 32px 0;
  box-sizing: border-box;

  ${isMobileDown()} {
    & {
      height: 70%;
    }
  }
`

export const ComingupCalendarContainer = styled(Box)`
  height: 60%;
  padding-bottom: 32px;
`
