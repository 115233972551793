import { useEffect, useState } from 'react'
import { Box, Popper, PopperPlacementType, Stack } from '@mui/material'
import { Desk, Monitor, Phone, LocationOn, Event as EventIcon } from '@mui/icons-material'
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import Paragraph from '../../../shared/UI/Paragraph'
import NewBooking from './NewBooking'
import { BookingPopover } from './components'
import { BookingJsonType, BookingNewBookingRange, PopoverProps } from './types'
import ButtonSmall from '../../../shared/UI/ButtonSmall'
import { BookingBullet } from '../Shared/components'
import Tab, { TabDetails } from '../../../shared/UI/Tab'
import { RootStore } from '../../../redux/store'
import localCss from '../booking.module.css'
import { getJson } from '../utils/utils'
import { BookingAvailabilityColors, ButtonLabelType } from '../consts'
import {
  setBookingWizardSelectedFeatureState,
  setBookingWizardSelectedAlternativeFeatureState,
} from '../../../redux/reducers/deskBookingWizardReducer'
import { BookingWizardSteps } from '../BlockBooking/enums'

function DeskPopover({
  svgElement,
  floorplan,
  open,
  featureDetail,
  bookings,
  isOwnBooking,
  dateTimeFrom,
  isBlockBooking,
  isManager,
  availabilityColor,
  onClose,
  onSubmit,
  onCancel,
}: PopoverProps) {
  const dispatch = useDispatch()

  const [selectedTab, setSelectedTab] = useState<'booking' | 'features'>('booking')
  const [tabs, setTabs] = useState<TabDetails[]>([])
  const [newBookingSelectedRange, setNewBookingSelectedRange] = useState<BookingNewBookingRange>({
    from: '00:00:00',
    to: '23:59:00',
  })

  const { floorplanViewingDate, locations, floorplanManuallySelected, showFloorplan } = useSelector(
    (state: RootStore) => state.deskBooking
  )
  const { currentStep: currentWizardStep } = useSelector(
    (state: RootStore) => state.deskBookingWizard
  )
  const { searchResults, searchParams } = useSelector((state: RootStore) => state.deskBookingSearch)

  const singleBookingFloorplanSelected = !isBlockBooking && floorplanManuallySelected
  const singleBookingPopoverHeight = singleBookingFloorplanSelected ? 250 : 220

  const popoverWidth = 300
  const popoverHeight = (isOwnBooking || isBlockBooking) ? 200 : singleBookingPopoverHeight

  useEffect(() => {
    if (!searchResults || !searchParams) {
      return
    }

    setNewBookingSelectedRange({
      from: searchParams.from,
      to: searchParams.to,
    })
  }, [searchResults, searchParams])

  useEffect(() => {
    if (!showFloorplan) {
      setSelectedTab('booking')
      onClose?.()
    }
  }, [showFloorplan])

  const additionalInfo = getJson(featureDetail.additionalInfo)

  const popoverPosition = Number(featureDetail.ports?.[0].x) || 2

  const getPopoverPosition = (): PopperPlacementType => {
    switch (popoverPosition) {
      case 1:
        return 'top'
      case 2:
        return 'right'
      case 3:
        return 'bottom'
      case 4:
        return 'left'
      default:
        return 'right'
    }
  }

  const tabSelectionProps = () => {
    const contentArray: TabDetails[] = []
    if (isOwnBooking) {
      contentArray.push({
        label: 'My Desk',
        tabContext: (
          <Box height="40px" pt={2}>
            {selectedTab === 'booking' && Number(bookings?.length) > 0 && (
              <>
                <Paragraph
                  size="13px"
                  color="#8D8D8D"
                  padding="0"
                  style={{
                    textAlign: 'left',
                    marginBottom: '10px',
                  }}
                >
                  {floorplan}
                </Paragraph>
                <Paragraph
                  size="13px"
                  color="#898989"
                  padding="0"
                  weight="bold"
                  style={{
                    textAlign: 'left',
                    marginBottom: '10px',
                  }}
                >
                  {additionalInfo?.desc?.deskDesc || ''}
                  {featureDetail.label}
                </Paragraph>
              </>
            )}
          </Box>
        ),
      })
    } else {
      contentArray.push({
        label: `Desk ${additionalInfo?.desc?.deskDesc ? additionalInfo.desc.deskDesc : ''}${
          featureDetail.label
        }`,
        tabContext: (
          <Box p="12px 0">
            <Paragraph
              size="13px"
              color="#8D8D8D"
              padding="0 0 8px 0"
              style={{
                textAlign: 'left',
                marginBottom: '8px',
                borderBottom: '2px solid #ECECF0',
              }}
            >
              {floorplan}
            </Paragraph>
            {selectedTab === 'booking' && !isOwnBooking && Number(bookings?.length) > 0 && (
              <>
                <Stack direction="row" display="flex" alignItems="center">
                  <BookingBullet color={availabilityColor} />
                  <Paragraph
                    size="13px"
                    color={availabilityColor || '#BA7070'}
                    weight="bold"
                    style={{ textAlign: 'left', marginLeft: '8px' }}
                  >
                    Reserved By:
                  </Paragraph>
                  <Paragraph
                    size="13px"
                    color="#8D8D8D"
                    style={{ textAlign: 'left', marginLeft: '8px' }}
                  >
                    {Number(bookings?.filter(f => f.featureId === featureDetail.id).length) > 1
                      ? 'Multiple bookings'
                      : bookings?.[0].displayName}
                  </Paragraph>
                </Stack>
                {!isBlockBooking && bookings && Number(bookings?.length) === 1 && (
                  <Stack direction="row" mt="8px" alignItems="center">
                    <EventIcon fontSize="inherit" sx={{ marginRight: '8px' }} />
                    <Paragraph color="#8D8D8D" weight="medium">
                      {format(new Date(bookings[0].fromDate), 'MMMM, do yyyy')}
                    </Paragraph>
                  </Stack>
                )}
              </>
            )}
            {selectedTab === 'booking' && Number(bookings?.length) === 0 && (
              <>
                <Stack direction="row" display="flex" alignItems="center" pl="2px">
                  <BookingBullet color="#5BCF8B" />
                  <Paragraph
                    size="13px"
                    color="#5BCF8B"
                    weight="bold"
                    style={{ textAlign: 'left', marginLeft: '10px' }}
                  >
                    Available
                  </Paragraph>
                </Stack>
                {!isBlockBooking && (
                  <Stack direction="row" display="flex" alignItems="center">
                    <EventIcon fontSize="small" sx={{ marginRight: '5px', marginTop: '7px' }} />
                    <Paragraph
                      size="13px"
                      weight="medium"
                      style={{ textAlign: 'left', marginTop: '10px' }}
                    >
                      {format(new Date(floorplanViewingDate), 'MMMM, do yyyy')}
                    </Paragraph>
                  </Stack>
                )}
                {singleBookingFloorplanSelected && (
                  <NewBooking
                    onSelectedRange={newBooking => {
                      setNewBookingSelectedRange(newBooking.selectedRange)
                    }}
                  />
                )}
              </>
            )}
          </Box>
        ),
      })
    }
    if (featureDetail.components?.length === 0) {
      return contentArray
    }
    contentArray.push({
      label: 'Features',
      tabContext: (
        <Box
          sx={{
            paddingTop: '12px',
            display: 'flex',
            flexFlow: 'row wrap',
            height: isOwnBooking || isBlockBooking ? '60px' : '120px',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '17px',
            },
            '&::-webkit-scrollbar-thumb': {
              border: '5px solid transparent',
              backgroundClip: 'content-box',
            },
          }}
        >
          {selectedTab === 'features' &&
            featureDetail.components &&
            featureDetail.components.map(m => (
              <Paragraph
                size="13px"
                color="#8d8d8d"
                padding="5px 0"
                style={{
                  textAlign: 'left',
                  width: '50%',
                }}
              >
                {m.name.toLowerCase() === 'phone' && (
                  <Phone style={{ margin: '-5px 10px -5px 0', fill: 'black' }} />
                )}
                {m.name.toLowerCase() === 'highered desk' && (
                  <Desk style={{ margin: '-5px 10px -5px 0', fill: 'black' }} />
                )}
                {m.name.toLowerCase().indexOf('monitor') >= 0 && (
                  <Monitor style={{ margin: '-5px 10px -5px 0', fill: 'black' }} />
                )}
                {m.name}
              </Paragraph>
            ))}
        </Box>
      ),
    })
    return contentArray
  }

  useEffect(() => {
    if (!open) {
      setSelectedTab('booking')
    }
  }, [open])

  if (!open) {
    return null
  }

  const getSubmitLabel = () => {
    if (isBlockBooking) {
      return 'Select'
    }
    if (
      isOwnBooking ||
      (isManager && availabilityColor === BookingAvailabilityColors.NOT_AVAILABLE)
    ) {
      return 'Cancel Desk'
    }
    return 'Book Now'
  }

  const isBlockAndIsNotAvailable =
    isBlockBooking && availabilityColor === BookingAvailabilityColors.NOT_AVAILABLE

  const getSubmitDisabledState = () => {
    if (isBlockAndIsNotAvailable) {
      return true
    }
    if (
      (isManager && availabilityColor === BookingAvailabilityColors.NOT_AVAILABLE) ||
      availabilityColor === BookingAvailabilityColors.OWN_BOOKING
    ) {
      return false
    }
    if (!isBlockBooking && Number(bookings?.length) >= 1) {
      return !isOwnBooking
    }
    return false
  }

  const validateBlockBooking = () => {
    setSelectedTab('booking')
    onClose?.()
    if (isBlockBooking && currentWizardStep === BookingWizardSteps.STEP_4_ALTERNATIVES) {
      return dispatch(setBookingWizardSelectedAlternativeFeatureState(featureDetail.id))
    }
    return dispatch(
      setBookingWizardSelectedFeatureState({
        ...featureDetail,
        hasBookings: availabilityColor !== BookingAvailabilityColors.AVAILABLE,
      })
    )
  }

  const validateOwnBooking = (json: BookingJsonType | null) => {
    const locationName = locations.find(f => f.id === featureDetail.locationId)?.name || ''
    if ((Number(bookings?.length) === 1 && !bookings?.[0].id) || locationName === '') {
      return
    }
    return onCancel?.({
      bookingID: Number(bookings?.[0].id),
      deskName: `${json?.rowName ? `${json.rowName}-` : ''}${featureDetail.label}`,
      floorPlanName: floorplan,
      byManager: isManager && !isOwnBooking,
      location: locationName,
      date: dateTimeFrom,
      onCallBack: success => undefined,
    })
  }

  const onButtonClick = () => {
    let json: { rowName: string } | null = null
    json = additionalInfo

    if (isBlockBooking) {
      return validateBlockBooking()
    }

    if (
      isOwnBooking ||
      (isManager && availabilityColor === BookingAvailabilityColors.NOT_AVAILABLE)
    ) {
      return validateOwnBooking(json)
    }

    onSubmit?.(featureDetail, `${json?.rowName ? `${json.rowName}-` : ''}${featureDetail.label}`, {
      date: floorplanViewingDate,
      selectedRange: newBookingSelectedRange,
    })
  }

  return (
    <Popper
      open={open}
      anchorEl={svgElement}
      placement={getPopoverPosition()}
      style={{
        zIndex: 1200,
        height: `${popoverHeight + 20}px`,
        width: `${popoverWidth + 50}px`,
      }}
    >
      <BookingPopover
        popoverPosition={popoverPosition}
        popupWidth={popoverWidth}
        popupHeight={popoverHeight}
      >
        {isOwnBooking && (
          <div
            style={{
              backgroundColor: '#20C5A0',
              width: '100%',
              height: '65px',
              position: 'absolute',
              top: 0,
              left: 0,
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            }}
          >
            <LocationOn
              style={{
                color: 'white',
                fontSize: '30px',
                position: 'absolute',
                right: '22px',
                top: '15px',
              }}
            />
          </div>
        )}
        {isOwnBooking && (
          <div
            style={{
              backgroundColor: '#20C5A0',
              width: '100%',
              height: '65px',
              position: 'absolute',
              top: 0,
              left: 0,
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            }}
          >
            <LocationOn
              style={{
                color: 'white',
                fontSize: '30px',
                position: 'absolute',
                right: '22px',
                top: '15px',
              }}
            />
          </div>
        )}
        <Tab
          titleAndContent={tabSelectionProps()}
          tabClass={isOwnBooking ? localCss.bookingPopover : ''}
          onChange={idx => setSelectedTab(idx === 0 ? 'booking' : 'features')}
          isOwnBooking={isOwnBooking}
        />
        <Stack
          direction="row"
          gap={4}
          justifyContent="space-between"
          sx={{
            position: 'absolute',
            width: '100%',
            left: '0',
            height: '26px',
            paddingInline: '24px',
            boxSizing: 'border-box',
            bottom: '24px',
          }}
        >
          <ButtonSmall
            color="secondary"
            label="Close"
            minWidth="88px"
            sx={{ padding: '1.5px 30px', fontSize: '11px', margin: '0' }}
            onClick={() => {
              setSelectedTab('booking')
              onClose?.()
            }}
          />
          <ButtonSmall
            label={getSubmitLabel()}
            minWidth="88px"
            sx={{
              padding: '1.5px 30px',
              fontSize: '11px',
              margin: '0',
              opacity: getSubmitDisabledState() ? 0.5 : 1,
            }}
            disabled={getSubmitDisabledState()}
            onClick={onButtonClick}
          />
        </Stack>
      </BookingPopover>
    </Popper>
  )
}

export default DeskPopover
