import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../shared/UI/Button'
import { RootStore, useAppDispatch } from '../../redux/store'
import DrawerFooter from '../../shared/UI/DrawerFooter'
import { RequestType } from '../../models'
import DropdownMenu, { DataProps } from '../../shared/UI/DropdownMenu/index'
import {
  setComments,
  timeOffRequestsState,
  setIsIncrease,
  setHours,
  setFinancialYear,
  setRequestType,
  resetNewRequestState,
} from '../../redux/reducers/timeOffRequestsReducer'
import ManagerEmployeeDropDown from '../MultiDayRequest/ManagerEmployeeDropDown'
import Paragraph from '../../shared/UI/Paragraph'
import Alert from '../../shared/UI/Alert/Alert'
import {
  ADJUSTMENT_ALLOWED_YEARS_IN_PAST,
  ADJUSTMENT_ALLOWED_YEARS_IN_FUTURE,
  EntitlementPeriodType,
} from '../../utils/constants'
import { getYearSelectOptions, YearRequest } from '../../utils/app-utils'
import { employeeService } from '../../services/employeeService'
import { OptionValue } from '../../types/option-value'
import { BaseResponse } from '../../types/base-response'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../utils/errorFilter'

type Props = {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, type: RequestType) => any
  type: RequestType
  tabType: (type: number) => any
  handleClose: (event: React.FormEvent<HTMLFormElement>) => any
  doesIncludeSummary?: boolean
  isHidden?: boolean
  allowIsHidden: boolean
  submitLoading?: boolean
}

function Adjustment({
  handleSubmit,
  tabType,
  handleClose,
  doesIncludeSummary = true,
  type,
  isHidden = true,
  allowIsHidden = false,
  submitLoading = false,
}: Props) {
  const dispatch = useAppDispatch()
  const [validationErrors, setValidationErrors] = useState<any>({})

  const { employeeSelected, comments, adjustmentHours, financialYear } = useSelector<
    RootStore,
    timeOffRequestsState
  >((state: RootStore) => state.timeOff)

  const isFormValid = () => {
    setValidationErrors({})
    const errors: any = {}
    if (adjustmentHours <= 0) {
      errors.adjustmentHours = true
    }

    if (financialYear === '') {
      errors.financialYear = true
    }

    if (comments === '') {
      errors.comments = true
    }

    if (Object.keys(errors).length !== 0) {
      setValidationErrors(errors)
      return false
    }

    return true
  }

  useEffect(() => {
    dispatch(setRequestType(type))
    return () => {
      dispatch(resetNewRequestState())
    }
  }, [dispatch, type])

  const [yearData, setYearData] = useState<OptionValue[]>([])

  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)

  useEffect(() => {
    if (employeeSelected === null) {
      return
    }
    employeeService
      .getEmployeeDepartment(employeeSelected)
      .then(result => {
        const yearRequest: YearRequest = {
          entitlementPeriod: result.entitlementPeriod as EntitlementPeriodType,
          yearsInPast: ADJUSTMENT_ALLOWED_YEARS_IN_PAST,
          yearsInFuture: ADJUSTMENT_ALLOWED_YEARS_IN_FUTURE,
          entitlementYear: currentEntitlementPeriodResponse?.year,
        }
        setYearData(getYearSelectOptions(yearRequest))
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [dispatch, employeeSelected])

  return (
    <Grid
      component="form"
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (isFormValid()) {
          handleSubmit(event, RequestType.ADJUSTMENT)
        }
      }}
    >
      <Grid container spacing={4} mt={1}>
        <Grid item xs={12} lg={6}>
          <ManagerEmployeeDropDown />
        </Grid>
        <Grid item xs={12} lg={6}>
          {isHidden && <Alert severity="info" message="Please select an Employee" />}
        </Grid>
        {(!isHidden || !allowIsHidden) && (
          <>
            <Grid item xs={12}>
              <Paragraph weight="bold">Details</Paragraph>
            </Grid>
            <Grid item xs={12} lg={6}>
              <DropdownMenu
                label="Entitlement Year"
                id="financial-year-dropdown"
                textField="displayValue"
                valueField="value"
                data={yearData}
                onChange={(year: any) => dispatch(setFinancialYear(year.target.value))}
                error={validationErrors.financialYear}
                helperText="Required"
                dataTestId="ManualRequest-AdjustmentTab-EntitlementDDL"
              />
            </Grid>
            <Grid container columnSpacing={4} ml={0} mt={4} xs={12}>
              <Grid item xs={12} lg={6}>
                <TextField
                  id="hours-input"
                  label="Hours"
                  type="number"
                  variant="outlined"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(setHours(parseFloat(e.target.value)))
                  }}
                  fullWidth
                  error={validationErrors.adjustmentHours}
                  helperText="Required"
                  inputProps={{ step: '0.1', min: 0 }}
                  onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
                  data-testid="ManualRequest-AdjustmentTab-Hours"
                />
              </Grid>
              <Grid item xs={12} lg={6} display="flex" alignItems="center" flexDirection="column">
                <FormControl fullWidth>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    onChange={newValue => {
                      dispatch(setIsIncrease(newValue.target.value === 'increase'))
                    }}
                    sx={{ flexDirection: 'row', height: '51px' }}
                  >
                    <Stack direction="row">
                      <FormControlLabel
                        value="increase"
                        control={<Radio required style={{ padding: 0, margin: '8px' }} />}
                        label="Increase"
                        data-testid="ManualRequest-AdjustmentTab-Increase"
                      />
                      <FormControlLabel
                        value="decrease"
                        control={<Radio style={{ padding: 0, margin: '8px' }} />}
                        label="Decrease"
                        data-testid="ManualRequest-AdjustmentTab-Decrease"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={doesIncludeSummary ? 6 : 12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Comments"
                    rows={5}
                    multiline
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(setComments(e.target.value))
                    }}
                    error={validationErrors.comments}
                    helperText="Required"
                    data-testid="ManualRequest-AdjustmentTab-Comments"
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <DrawerFooter>
        {(!isHidden || !allowIsHidden) && (
          <>
            <Button
              color="secondary"
              label="Cancel"
              onClick={(event: React.FormEvent<HTMLFormElement>) => handleClose(event)}
              dataTestId="ManualRequest-AdjustmentTab-CancelBtn"
            />
            <Button
              label="Submit"
              type="submit"
              loading={submitLoading}
              dataTestId="ManualRequest-AdjustmentTab-submitBtn"
            />
          </>
        )}
      </DrawerFooter>
    </Grid>
  )
}

export default Adjustment
