import { Entitlement } from '../models/entitlement'
import { DefaultShiftLengthResponse } from '../types/DefaultShiftLengthResponse'
import { EmployeeDepartmentResponse } from '../types/select-values'
import { httpServiceV1 } from './httpServiceV1'

const employeeBaseURL = 'employees'

export const employeeService = {
  getHolidayEntitlement: async (windowsLogin: string, currentYear: number): Promise<Entitlement> =>
    httpServiceV1.get(`${employeeBaseURL}/${windowsLogin}/${currentYear}/GetHolidayEntitlement`),
  getEmployeeDepartment: async (employeeId: number): Promise<EmployeeDepartmentResponse> =>
    httpServiceV1.get(`SelectValues/EmployeeDepartment?EmployeeId=${employeeId}`),
  getEmployeeDefaultShiftLength: async (employeeId: number): Promise<DefaultShiftLengthResponse> =>
    httpServiceV1.get(`SelectValues/DefaultShiftLength/?EmployeeId=${employeeId}`),
}
