import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, TextField } from '@mui/material'
import { RootStore, useAppDispatch } from '../../../../redux/store'
import { AbsenceReasonTypesEnum, AbsenceTypesEnum, EditOccurrenceFactoryProps } from '../../types'
import { SelectOption } from '../../../../services/dashboardService'
import { SetShowModalPayload, hideModal } from '../../../../redux/reducers/appSettingsReducer'
import { manualRequestsService } from '../../../../services/myActionsService'
import { getSelectValuesByType } from '../../../../utils/app-utils'
import { AbsencePutRequest } from '../../../../types/absence-put-request'
import { showErrorMessage, showSuccessMessage } from '../../../../redux/reducers/snackbarReducer'
import { BaseResponse } from '../../../../types/base-response'
import UserErrorMessage from '../../../../utils/errorFilter'
import DropdownMenu from '../../../../shared/UI/DropdownMenu'
import { getLocalDateString } from '../../../../utils/date-utils'
import CheckBox from '../../../../shared/UI/CheckBox'
import DateTimePicker from '../../../../shared/UI/DateTimePicker'
import DrawerFooter from '../../../../shared/UI/DrawerFooter'
import Button from '../../../../shared/UI/Button'
import Modal from '../../../../shared/UI/Modal'
import CovidIsoPayAlert from '../../Actions/CovidIsoPayAlert'

interface ValidationErrors {
  absenceReason?: boolean
  hours?: boolean
}

function UKEditOccurrence({
  absenceId,
  employeeResponse,
  absenceDay,
  expectedReturn,
  onClose,
}: EditOccurrenceFactoryProps) {
  const dispatch = useAppDispatch()
  const [selectedAbsenceTypeId, setSelectedAbsenceTypeId] = useState<number>(
    absenceDay.absenceType.absenceTypeId || 0
  )
  const [selectedReasonId, setSelectedReasonId] = useState<number>(
    absenceDay.absenceType.reasonTypeId || 0
  )
  const [selectedContactTypeId, setSelectedContactTypeId] = useState<number>(
    absenceDay.contactedByTypeId || 0
  )
  const [covidIsolationIndicator, setCovidIsolationIndicator] = useState(
    selectedAbsenceTypeId === AbsenceReasonTypesEnum.CovidIsolation &&
      selectedReasonId === AbsenceTypesEnum.Sickness
  )
  const [hoursLost, setHoursLost] = useState<string>(absenceDay.hoursLost.toString() || '0')
  const [contactDate, setContactDate] = useState<Date>(absenceDay.contactDateTime)
  const [toWorkBack, setToWorkBack] = useState<boolean>(absenceDay.toWorkBack)
  const [comments, setComments] = useState<string>(absenceDay.comments || '')
  const [contactComments, setContactComments] = useState<string>(absenceDay.contactComments || '')
  const [locationAbsenceTypesList, setLocationAbsenceTypesList] = useState<SelectOption[]>([])
  const [validationErorrs, setValidationErrors] = useState<ValidationErrors>({})
  const { territoryAttendanceUK } = useSelector((state: RootStore) => state.featureToggles)
  const isNonSicknessOrNoShow = useMemo(
    () =>
      selectedReasonId === AbsenceTypesEnum.NonSickness ||
      selectedReasonId === AbsenceTypesEnum.NoShow,
    [selectedReasonId]
  )
  const isSickness = useMemo(
    () => selectedReasonId === AbsenceTypesEnum.Sickness,
    [selectedReasonId]
  )

  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  useEffect(() => {
    const getLocationAbsenceTypesList = async () => {
      manualRequestsService.getAbsenceReasonsByEmployeeId(employeeResponse.id).then(response => {
        setLocationAbsenceTypesList(response.locationAbsenceReasons.options)
      })
    }

    getLocationAbsenceTypesList()
  }, [employeeResponse, selectedReasonId, territoryAttendanceUK])

  const getAbsenceTypeToUse = () => {
    let newAbsenceTypeId = selectedAbsenceTypeId

    const wasCovidIsolationIndicator =
      absenceDay.absenceType.absenceTypeId === AbsenceReasonTypesEnum.CovidIsolation &&
      absenceDay.absenceType.reasonTypeId === AbsenceTypesEnum.Sickness
    const hasCovidIsolationIndicatorChanged = covidIsolationIndicator !== wasCovidIsolationIndicator
    const hasChangedToSickness =
      selectedReasonId === AbsenceTypesEnum.Sickness &&
      absenceDay.absenceType.reasonTypeId !== AbsenceTypesEnum.Sickness

    if (hasCovidIsolationIndicatorChanged || hasChangedToSickness) {
      newAbsenceTypeId = covidIsolationIndicator
        ? AbsenceReasonTypesEnum.CovidIsolation
        : AbsenceReasonTypesEnum.NotRequiredNotDisclosed
    }

    return newAbsenceTypeId
  }

  const submitRequest = () => {
    const absenceContactType = getSelectValuesByType('ContactByType').find(
      x => x.value === selectedContactTypeId
    )

    const request: AbsencePutRequest = {
      absenceDayId: absenceDay.id,
      absenceId,
      absenceDate: absenceDay.date,
      shiftDescription: absenceDay.expectedShift.shiftString || '',
      absentHours: Number(hoursLost),
      absenceTypeId: getAbsenceTypeToUse(),
      absenceReasonType: selectedReasonId,
      contactByType: absenceContactType?.displayValue.replace(/\s/g, '') || '',
      contactDateTime: contactDate!,
      toWorkBack,
      comments,
      contactComments,
      hasHadLunch: absenceDay.hasHadLunch,
    }

    manualRequestsService
      .editUKAbsence(request)
      .then(() => {
        dispatch(showSuccessMessage('Absence request has been sent'))
        onClose()
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const errors: ValidationErrors = {}
    setValidationErrors(errors)
    const selectedTypeClassification = locationAbsenceTypesList.find(
      t => t.value === selectedAbsenceTypeId
    )?.associatedValue

    const absenceReasonError =
      isNonSicknessOrNoShow && selectedTypeClassification !== selectedReasonId

    if (absenceReasonError) {
      errors.absenceReason = true
    }

    if (!hoursLost) {
      errors.hours = true
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors)
      return
    }

    submitRequest()
  }

  return (
    <Grid container spacing={4} component="form" onSubmit={handleSubmit} noValidate pr={4}>
      <Grid item xs={12}>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <DropdownMenu
                label="Employee"
                id="employee"
                data={[
                  {
                    id: employeeResponse.id,
                    displayNAme: employeeResponse.displayName,
                  },
                ]}
                textField="displayNAme"
                valueField="id"
                value={String(employeeResponse.id)}
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                label="Date"
                value={getLocalDateString(absenceDay.date)}
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                label="Shift"
                value={absenceDay.expectedShift.shiftString}
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                label="Expected Return"
                value={getLocalDateString(expectedReturn)}
                disabled
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <DropdownMenu
                label="Absence Type"
                id="absenceType"
                data={getSelectValuesByType('AbsenceReasonType')}
                textField="displayValue"
                valueField="value"
                name="absenceTypeId"
                value={selectedReasonId}
                onChange={e => {
                  setSelectedReasonId(Number(e.target.value))
                }}
                helperText="Required"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              {isSickness && (
                <CheckBox
                  label="Covid Indicator"
                  checked={covidIsolationIndicator}
                  onChange={(e, checked) => {
                    setCovidIsolationIndicator(checked)
                  }}
                />
              )}
              {isNonSicknessOrNoShow && (
                <DropdownMenu
                  label="Reason for absence"
                  id="absenceReason"
                  data={locationAbsenceTypesList.filter(
                    x => x.associatedValue === selectedReasonId
                  )}
                  textField="displayValue"
                  valueField="value"
                  name="absenceTypeReasonId"
                  value={selectedAbsenceTypeId}
                  onChange={e => {
                    setSelectedAbsenceTypeId(Number(e.target.value))
                  }}
                  helperText="Required"
                  error={validationErorrs.absenceReason}
                />
              )}
            </Grid>
            <CovidIsoPayAlert
              absenceCategoryId={selectedReasonId}
              absenceTypeId={selectedAbsenceTypeId}
              covidIsolationIndicator={covidIsolationIndicator}
            />
            <Grid item xs={12} lg={6}>
              <TextField
                id="hours-input"
                label="Hours"
                type="number"
                variant="outlined"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setHoursLost(e.target.value)
                }}
                fullWidth
                inputProps={{ step: '0.1', min: 0, max: 24 }}
                value={hoursLost}
                error={validationErorrs.hours}
              />
            </Grid>
            <Grid item xs={12} lg={6} display="flex" alignItems="center">
              <CheckBox
                label="To work back"
                checked={toWorkBack}
                onChange={(e, checked) => {
                  setToWorkBack(checked)
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <DropdownMenu
                label="Contact made by"
                id="contact-made-by"
                textField="displayValue"
                valueField="value"
                value={selectedContactTypeId}
                data={getSelectValuesByType('ContactByType')}
                onChange={e => {
                  setSelectedContactTypeId(Number(e.target.value))
                }}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              {(selectedContactTypeId === 1 || selectedContactTypeId === 2) && (
                <DateTimePicker
                  label="Contacted date"
                  value={contactDate}
                  onChange={e => {
                    if (e) {
                      setContactDate(e)
                    }
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                label="Contact Comments"
                value={contactComments}
                rows={5}
                multiline
                onChange={e => {
                  setContactComments(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                label="Comments"
                value={comments}
                rows={5}
                multiline
                onChange={e => {
                  setComments(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6} />
          </Grid>
        </Grid>
      </Grid>
      <DrawerFooter>
        <Button color="secondary" label="Cancel" onClick={onClose} />
        <Button label="Submit" type="submit" />
      </DrawerFooter>
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
        }}
        onClick={() => submitRequest()}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
      />
    </Grid>
  )
}

export default UKEditOccurrence
