import { CallOutMenuItems, SubmissionPeriod, PayPeriodResponse } from '../models/enhancement'
import { EnhancementApprovalViewResponse } from '../models/enhancement/enhancement-approval-view-response'

import { httpServiceV2 } from './httpServiceV2'

const requestsBaseURL = 'v2/enhancements'

export const enhancementService = {
  getApprovals: async (params?: SubmissionPeriod): Promise<EnhancementApprovalViewResponse> => {
    const requestURL =
      `${requestsBaseURL}/approvals` +
      `${params?.year ? `?year=${params.year}` : ``}` +
      `${params?.period ? `&period=${params.period}` : ``}`
    return httpServiceV2.get(requestURL)
  },

  postApprovalsSubmit: async (): Promise<EnhancementApprovalViewResponse> =>
    httpServiceV2.post(`${requestsBaseURL}/approvals/submit`),

  getCurrentPayPeriod: async (): Promise<PayPeriodResponse> =>
    httpServiceV2.get(`${requestsBaseURL}/currentperiod`),

  getCallOutMenuItems: async (): Promise<CallOutMenuItems> =>
    httpServiceV2.get(`${requestsBaseURL}/callout/menuitems`),
}
