import {
  RegenerateHrDocumentsRequest,
  RegenerateHrDocumentsResponse,
} from '../components/BSSAdmin/NotificationLogs/models'
import { httpServiceV1 } from './httpServiceV1'

const requestsBaseURL = 'AttendanceHrDocuments'

const hrDocumentsRegenerationService = {
  customResendHrDocumentsForAbsenceIds: async (
    request: RegenerateHrDocumentsRequest
  ): Promise<RegenerateHrDocumentsResponse> =>
    httpServiceV1.post(`${requestsBaseURL}/RegenerateDocuments`, request),
}

export { hrDocumentsRegenerationService }
