import { Collapse, Grid, Stack } from '@mui/material'
import styled from 'styled-components'
import { useCallback, useState } from 'react'
import { useAppDispatch } from '../../../../redux/store'
import HolidaysEntitlementRemainingAllowanceSearchForm from './HolidaysEntitlementRemainingAllowanceSearchForm'
import { HolidaysEntitlementRemainingAllowanceCSV, Props, Search } from './types'
import HolidaysEntitlementRemainingAllowanceGrid from './HolidaysEntitlementRemainingAllowanceGrid'
import LoadingIndicator from '../../../../shared/UI/LoadingIndicator'
import { BaseResponse } from '../../../../types/base-response'
import { showErrorMessage } from '../../../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../../../utils/errorFilter'
import { ssrsHolidayReportService } from '../../../../services/ssrsHolidayReportService'
import {
  HolidayEntitlementRemainingAllowanceItem,
  HolidayEntitlementRemainingAllowanceResponse,
} from '../../../../types/holiday-entitlement-remaining-allowance-response'
import { DrawerComponentProps } from '../../types'
import Drawer from '../../../../shared/UI/Drawer'
import HolidaysEntitlementRemainingAllowanceDetail from './HolidaysEntitlementRemainingAllowanceDetail'
import Tab from '../../../../shared/UI/Tab'
import RefreshButton from '../../../../shared/UI/RefreshButton'
import DownloadButton from '../../../../shared/UI/DownloadButton'
import FilterButton from '../../../../shared/UI/FilterButton'

export const LoadingIndicatorContainer = styled.div`
  padding-top: 70px;
  padding-bottom: 70px;
`

const headers: string[] = [
  'EmployeeName',
  'JobTitle',
  'Manager',
  'Department',
  'Team',
  'HolidayEntitlement',
  'HolidayEntitlementTaken',
  'HolidayEntitlementRemaining',
  'HolidayEntitlementTakenPc',
  'WFHAllowance',
  'WFHTaken',
  'WFHRemaining',
  'WFHTakenPc',
]

function HolidaysEntitlementRemainingAllowanceSearch({ userDepartments }: Props) {
  const dispatch = useAppDispatch()
  const [resetForm, setResetForm] = useState(false)
  const [showHeaderButtons, setShowHeaderButtons] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(true)
  const [showGrid, setShowGrid] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [finData, setFinData] = useState<HolidayEntitlementRemainingAllowanceItem[]>([])
  const [calData, setCalData] = useState<HolidayEntitlementRemainingAllowanceItem[]>([])
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const handleClose = useCallback(() => {
    setOpenDrawer(false)
  }, [])

  const [drawerComponent, setDrawerComponent] = useState<DrawerComponentProps>({
    title: '',
    component: <HolidaysEntitlementRemainingAllowanceDetail row={null} onClose={handleClose} />,
  })

  const handleSearch = useCallback(
    (search: Search) => {
      setIsLoading(true)
      setShowGrid(false)

      setTimeout(() => {
        setShowSearch(false)
      }, 500)

      ssrsHolidayReportService
        .getHolidaysEntitlementRemainingAllowanceReports(
          search.departmentIds!,
          search.fromDateRangeFrom!,
          search.fromDateRangeTo!,
          search.remainingEntitlement!,
          search.directReportees!
        )
        .then((res: HolidayEntitlementRemainingAllowanceResponse) => {
          const result: HolidayEntitlementRemainingAllowanceItem[] = res.entitlementReport.map(
            (row: HolidayEntitlementRemainingAllowanceItem, id: number) => ({ ...row, id })
          )

          setCalData(result.filter(p => p.yearType === 'CY'))
          setFinData(result.filter(p => p.yearType === 'FY'))
          setShowHeaderButtons(true)
          setShowGrid(true)
          setIsLoading(false)
        })
        .catch(err => {
          setShowHeaderButtons(true)
          setShowGrid(false)
          setIsLoading(false)
          setTimeout(() => {
            setShowSearch(true)
          }, 500)

          const response: BaseResponse = err.response.data
          if (response.errors) {
            response.errors.forEach(error => {
              dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
            })
          } else {
            dispatch(showErrorMessage(<UserErrorMessage name="CurrentlyUnavailable" />))
          }
        })
    },
    [dispatch]
  )

  const handleTabChange = useCallback((index: number) => {
    setSelectedTab(index)
  }, [])

  const handleFilterClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setShowSearch(!showSearch)
    },
    [showSearch]
  )

  const handleRefreshClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setResetForm(!resetForm)
      setShowSearch(true)
      setShowHeaderButtons(false)
      setShowGrid(false)
      setSelectedTab(0)
    },
    [resetForm]
  )

  const handleViewDetail = useCallback(
    (row: HolidayEntitlementRemainingAllowanceItem) => {
      setDrawerComponent({
        ...drawerComponent,
        title: `${row.displayName}`,
        component: <HolidaysEntitlementRemainingAllowanceDetail row={row} onClose={handleClose} />,
      })

      setOpenDrawer(true)
    },
    [drawerComponent, handleClose]
  )

  const makeCsv = useCallback(
    async (filename: string) => {
      const data: HolidayEntitlementRemainingAllowanceItem[] = selectedTab === 0 ? finData : calData
      const csvData: HolidaysEntitlementRemainingAllowanceCSV[] = data.map(row => ({
        EmployeeName: row.displayName,
        JobTitle: row.position,
        Manager: row.manager,
        Department: row.departmentName,
        Team: row.team,
        HolidayEntitlement: row.holidayAllowance,
        HolidayEntitlementTaken: row.holidayTaken,
        HolidayEntitlementRemaining: row.holidayRemaining,
        HolidayEntitlementTakenPc: row.holidayTakenPercent,
        WFHAllowance: row.workFromHomeAllowance,
        WFHTaken: row.workFromHomeTaken,
        WFHRemaining: row.workFromHomeRemaining,
        WFHTakenPc: row.workFromHomeTakenPercent,
      }))

      const keys: string[] = Object.keys(csvData[0])

      const csvContent: string = csvData
        .map((row: any) =>
          keys
            .map(k => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k]

              cell =
                cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""')

              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`
              }
              return cell
            })
            .join(',')
        )
        .join('\n')

      const csvHeaders: string = headers.join(',')

      const blob = new Blob([`${csvHeaders}\n`, csvContent], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')

      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    [calData, finData, selectedTab]
  )

  const handleDownloadClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const file: string =
        selectedTab === 0
          ? 'HolidaysEntitlementRemainingAllowanceFY.csv'
          : 'HolidaysEntitlementRemainingAllowanceCY.csv'
      makeCsv(file)
    },
    [makeCsv, selectedTab]
  )

  return (
    <>
      {showHeaderButtons && (
        <Grid container>
          <Stack
            direction="row"
            style={{ width: '100%' }}
            spacing={2}
            display="flex"
            justifyContent="flex-end"
          >
            <FilterButton onClick={handleFilterClick} title="Change Filters" />
            {((selectedTab === 0 && finData && finData.length >= 1) ||
              (selectedTab === 1 && calData && calData.length >= 1)) && (
              <DownloadButton onClick={handleDownloadClick} title="Download Report" />
            )}
            <RefreshButton onClick={handleRefreshClick} title="Refresh" />
          </Stack>
        </Grid>
      )}
      <Collapse orientation="vertical" in={showSearch}>
        <HolidaysEntitlementRemainingAllowanceSearchForm
          resetForm={resetForm}
          userDepartments={userDepartments}
          onSearch={handleSearch}
        />
      </Collapse>
      {showGrid ? (
        <>
          {!isLoading && (
            <Tab
              onChange={handleTabChange}
              titleAndContent={[
                {
                  label: 'Financial Year',
                  tabContext: (
                    <Grid item xs={12}>
                      {!isLoading && showGrid && (
                        <HolidaysEntitlementRemainingAllowanceGrid
                          data={finData}
                          onViewDetail={handleViewDetail}
                        />
                      )}
                    </Grid>
                  ),
                  disabled: false,
                },
                {
                  label: 'Calendar Year',
                  tabContext: (
                    <Grid item xs={12}>
                      {!isLoading && showGrid && (
                        <HolidaysEntitlementRemainingAllowanceGrid
                          data={calData}
                          onViewDetail={handleViewDetail}
                        />
                      )}
                    </Grid>
                  ),
                  disabled: false,
                },
              ]}
            />
          )}
        </>
      ) : (
        <>
          {isLoading && (
            <LoadingIndicatorContainer>
              <LoadingIndicator show={isLoading} />
            </LoadingIndicatorContainer>
          )}
        </>
      )}
      <Drawer
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        title={drawerComponent?.title || ''}
        showOptions={false}
      >
        {drawerComponent?.component}
      </Drawer>
    </>
  )
}

export default HolidaysEntitlementRemainingAllowanceSearch
