export const filterBtnStyles = {
  height: '20px',
  width: '20px',
  p: 0,
  textOverflow: 'unset',
  backgroundColor: 'white',
  '& .MuiChip-label': {
    color: 'primary',
    fontSize: '9px',
    lineHeight: 2,
    fontWeight: 'bold',
    px: 0,
  },
}
export const directReportsStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  color: 'rgb(116, 116, 116) !important;',
  margin: 0,
}
