import { Box, List, ListItemIcon, ListItemText } from '@mui/material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import FeedIcon from '@mui/icons-material/Feed'
import PeopleIcon from '@mui/icons-material/People'
import GradingIcon from '@mui/icons-material/Grading'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StyledCard, StyledListItemButton } from './components'
import { AbsenceOccurrence } from '../../../types/absence'
import { getSelectValuesByType } from '../../../utils/app-utils'
import { settingsService } from '../../../services/settingsService'
import { RootStore } from '../../../redux/store'
import { EmployeeDetailsResponse } from '../../../types/employee'
import { AbsenceLocation } from '../../../types/absence-location'
import NoDataFound from '../../../shared/UI/NoDataFound'
import LoadingIndicator from '../../../shared/UI/LoadingIndicator'
import { BaseResponse } from '../../../types/base-response'
import UserErrorMessage from '../../../utils/errorFilter'
import { showErrorMessage } from '../../../redux/reducers/snackbarReducer'
import {
  getScaText,
  getIsCoveredBySickNote,
  getScaDisabled,
} from './ActionsHelperFunctions/actionsHelpers'
import { LocationGroups } from '../../../types/location-groups'

type ActionProps = {
  absence: AbsenceOccurrence
  onCta?: (action: string) => void
  isLoading: boolean
  isData: boolean
  isScaComplete: boolean
}

export default function Actions({ absence, onCta, isLoading, isData }: ActionProps) {
  const [employeeLocation, setEmployeeLocation] = useState<AbsenceLocation>()
  const [isActionsLoading, setIsActionsLoading] = useState<boolean>(true)
  const [isActionsData, setIsActionsData] = useState<boolean>(false)

  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse>(
    (state: RootStore) => state.appSettings.employeeDetails
  )
  const { territoryAttendanceUK } = useSelector((state: RootStore) => state.featureToggles)

  const absenceCategories = getSelectValuesByType('AbsenceReasonType')

  const currentAbsenceType = useMemo(
    () =>
      getSelectValuesByType('AbsenceType').find(
        x =>
          x.displayValue === absence.reason &&
          x.value === absence.absenceDays[0].absenceType.absenceTypeId
      ),
    [absence.absenceDays, absence.reason]
  )
  const dispatch = useDispatch()

  const currentAbsenceCategory = useMemo(
    () => absenceCategories.find(x => x.value === currentAbsenceType?.associatedValue),
    [absenceCategories, currentAbsenceType?.associatedValue]
  )

  const isSickness = useMemo(
    () => currentAbsenceCategory?.displayValue === 'Sickness',
    [currentAbsenceCategory?.displayValue]
  )

  const isSCARequired = useMemo(
    () => employeeLocation?.scaRequired && isSickness,
    [employeeLocation?.scaRequired, isSickness]
  )

  const isCoveredBySickNote = useCallback(
    (): boolean => getIsCoveredBySickNote(absence),
    [absence.absenceDays, absence.hasValidSca]
  )

  const scaText = useCallback(
    () => getScaText(absence),
    [
      absence.employeeResponse.locationGroupName,
      absence.hasValidSca,
      absence.scaRequired,
      territoryAttendanceUK,
    ]
  )

  const rtwDisabled = () => {
    if (employeeLocation?.rtwRequired === false) {
      return true
    }

    if (absence.scaRequired) {
      return !absence.hasValidSca
    }

    return false
  }

  const notSicknessOrCoveredBySickNote = useMemo(
    () => !isSickness || isCoveredBySickNote(),
    [isCoveredBySickNote, isSickness]
  )

  const scaDisabled = () => getScaDisabled(absence)

  const rtwText = useCallback(() => {
    if (!employeeLocation?.rtwRequired) {
      return 'RTW: Not Required'
    }
    if (absence.hasValidRtw) {
      return 'View RTW'
    }
    return 'Complete RTW'
  }, [absence.hasValidRtw, employeeLocation?.rtwRequired])

  const employeeLocationId = absence.employeeResponse.locationId

  useEffect(() => {
    settingsService
      .getLocation()
      .then(location => {
        setEmployeeLocation(
          location.absenceLocations.find(l => l.locationId === employeeLocationId)
        )
        setIsActionsLoading(false)
        setIsActionsData(true)
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
        setIsActionsData(false)
        setIsActionsLoading(false)
      })
  }, [employeeLocationId])

  const actionItems = useMemo(
    () => [
      {
        id: 1,
        title: 'Extend Absence',
        icon: <DateRangeIcon color="primary" />,
        onClick: () => onCta?.('extendAbsence'),
      },
      {
        id: 2,
        title: 'Add Sick Note',
        icon: <NoteAddIcon color="primary" />,
        onClick: () => onCta?.('addSickNote'),
        disabled: notSicknessOrCoveredBySickNote,
      },
      {
        id: 3,
        title: scaText(),
        icon: <FeedIcon color="primary" />,
        onClick: () => onCta?.('addSCA'),
        secondary: absence.hasValidSca && <CheckCircleIcon color="primary" />,
        disabled: scaDisabled(),
      },
      {
        id: 4,
        title: rtwText(),
        icon: <PeopleIcon color="primary" />,
        onClick: () => onCta?.('completeRTW'),
        secondary: absence.hasValidRtw && <CheckCircleIcon color="primary" />,
        disabled: rtwDisabled(),
      },
      {
        id: 5,
        title: 'Complete Absence',
        icon: <GradingIcon color="primary" />,
        onClick: () => onCta?.('completeAbsence'),
        disabled: employeeLocation?.rtwRequired && !absence.hasValidRtw,
      },
    ],
    [
      absence.hasValidRtw,
      absence.hasValidSca,
      employeeLocation?.rtwRequired,
      isSCARequired,
      isSickness,
      onCta,
      rtwText,
      scaText,
    ]
  )

  return (
    <StyledCard title="Actions" aria-labelledby="Absence Actions">
      {!isLoading && !isActionsLoading ? (
        <>
          {!isData || !isActionsData ? (
            <NoDataFound show />
          ) : (
            <List>
              {actionItems.map(item => (
                <StyledListItemButton onClick={item.onClick} disabled={item.disabled}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title} />
                  {item.secondary && <Box display="inline-flex">{item.secondary}</Box>}
                </StyledListItemButton>
              ))}
            </List>
          )}
        </>
      ) : (
        <>
          <LoadingIndicator show />
        </>
      )}
    </StyledCard>
  )
}
