import {
  CreateDayOffRequest,
  CreateMultiDayRequest,
  CreateShiftRequest,
  RequestType,
} from '../../models'
import { BuilderArgs } from '../../utils/RequestBuilder/requestBuilder'
import {
  formatDateWithTimeZoneStr,
  formatDateWithTimeZone,
  formatTime,
} from '../../utils/date-utils'

const convertForRequest = (tabToConvert: RequestType) => {
  if (tabToConvert === RequestType.LIEU_DAY) return 'Lieu'
  if (tabToConvert === RequestType.WORK_FROM_HOME) return 'WorkFromHome'
  if (tabToConvert === RequestType.DAY_OFF) return 'DayOff'
  if (tabToConvert === RequestType.HOLIDAY) return 'Holiday'
  if (tabToConvert === RequestType.SHIFT) return 'Shift'
  return tabToConvert
}

export const buildCreateMultiDayRequest = (args: BuilderArgs): CreateMultiDayRequest => ({
  type: convertForRequest(args.requestType),
  comments: args.comments,
  conflictOverride:
    args.conflicts && args.alerts?.filter(alert => alert.conflictType === 'error').length === 0,
  dateFrom: formatDateWithTimeZoneStr(args.dateRange[0]!),
  dateTo: formatDateWithTimeZoneStr(args.dateRange[1]!),
  days: args.selectedDays
    ?.filter(selectedDay => selectedDay.checked)
    .map(x => ({
      day: formatDateWithTimeZone(x.day),
      hours: x.hours,
      checked: x.checked,
    })),
})

export const buildCreateShiftRequest = (args: BuilderArgs): CreateShiftRequest => ({
  type: convertForRequest(args.requestType),
  comments: args.comments,
  conflictOverride:
    args.conflicts && args.alerts?.filter(alert => alert.conflictType === 'error').length === 0,
  dateFrom: formatDateWithTimeZoneStr(args.selectedDate!, 'yyyy-MM-dd'),
  shiftStartTime: formatTime(args.selectedDate),
})

export const buildCreateDayOffRequest = (args: BuilderArgs): CreateDayOffRequest => ({
  type: convertForRequest(args.requestType),
  comments: args.comments,
  conflictOverride:
    args.conflicts && args.alerts?.filter(alert => alert.conflictType === 'error').length === 0,
  dateFrom: formatDateWithTimeZoneStr(args.selectedDate!, 'yyyy-MM-dd'),
})
