const bannerStylesConfig = {
  submitted: {
    bgColor: '#F2FFF2',
    textColor: '#1E4620',
    chevronColor: '#1E4620',
  },
  pending: {
    bgColor: '#FFF9EA',
    textColor: '#A27403',
    chevronColor: '#A27403',
  },
}

const bannerStyles = (bgColor: string) => ({
  padding: '2px 6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: bgColor,
  boxShadow: '1px 2px 10px #00000015',
  marginBottom: '28px',
  borderRadius: '8px',
})

const enabledChevronStyles = (chevronColor: string) => ({
  color: chevronColor,
})
const disabledChevronStyles = {
  color: '#1E462034',
}

export const getComponentStyles = (status: keyof typeof bannerStylesConfig) => {
  const { bgColor, textColor, chevronColor } = bannerStylesConfig[status]

  return {
    disabledChevronStyles,
    enabledChevronStyles: enabledChevronStyles(chevronColor),
    bannerStyles: bannerStyles(bgColor),
    textColor,
    bgColor,
  }
}

export const contentStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  width: '100%',
  position: 'relative',
}
