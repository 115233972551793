import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import styled from 'styled-components'
import React from 'react'
import { AccordionTitle } from './components'
import theme from '../../../theme/theme'

type Props = {
  title: string
  children: JSX.Element | JSX.Element[]
  disableGutters?: boolean
  onClick?: (e: React.SyntheticEvent) => void
  expanded?: boolean
  dataTestId?: string
}

const StyledAccordion = styled(Accordion)`
  & .Mui-expanded p {
    color: ${theme.palette.primary.main};
  }
`

const AccordionStyle = {
  '&:before': {
    backgroundColor: '#E5E5E5',
    top: 69,
  },
  '&.Mui-expanded:before': {
    opacity: '1',
  },
  boxShadow: 'none',
}

function AccordionBasic({ title, children, disableGutters, onClick, expanded, dataTestId }: Props) {
  return (
    <StyledAccordion
      sx={AccordionStyle}
      disableGutters={disableGutters}
      onChange={onClick}
      expanded={expanded}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon data-testid={`${dataTestId}DDL`} />}>
        <AccordionTitle data-testid={dataTestId}>{title}</AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <div>{children}</div>
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default AccordionBasic
