import styled from 'styled-components'
import theme from '../../../theme/theme'

export const CardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: flex-start;
  }
`
