import { useMediaQuery, Link, Box } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro'
import { useState } from 'react'
import { GridProps } from './types'

import { isMobilePortrait } from '../../../../theme/deviceChecks'
import NoResult from '../../../../shared/UI/NoResult'

function NoRowsOverlay() {
  return <NoResult message="No Results" showImage />
}

function HolidaysThresholdsRestrictionsDailyGrid({ data, onViewDetail }: GridProps) {
  const [pageSize, setPageSize] = useState<number>(100)
  const mobilePortrait = useMediaQuery(isMobilePortrait())

  const columns: GridColDef[] = [
    {
      field: 'department',
      headerName: 'Department',
      headerClassName: 'header',
      type: 'string',
      width: 230,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: params => (params.row.isFirst ? params.value : ''),
    },
    {
      field: 'team',
      headerName: 'Team',
      headerClassName: 'header',
      width: 178,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: params => (
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            onViewDetail(params.row)
          }}
        >
          {params.row.team}
        </Link>
      ),
    },
    {
      field: 'dateFrom',
      headerName: 'Date From',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 100,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'dateTo',
      headerName: 'Date To',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'monday',
      headerName: 'Monday',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'tuesday',
      headerName: 'Tuesday',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'wednesday',
      headerName: 'Wednesday',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'thursday',
      headerName: 'Thursday',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'friday',
      headerName: 'Friday',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'saturday',
      headerName: 'Saturday',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'sunday',
      headerName: 'Sunday',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'totalThreshold',
      headerName: 'Total Threshold',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 170,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
  ]

  const mobileColumns: GridColDef[] = [
    {
      field: 'department',
      headerName: 'Department',
      headerClassName: 'header',
      type: 'string',
      width: 200,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: params => (params.row.isFirst ? params.value : ''),
    },
    {
      field: 'team',
      headerName: 'Team',
      headerClassName: 'header',
      width: 200,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: params => (
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            onViewDetail(params.row)
          }}
        >
          {params.row.team}
        </Link>
      ),
    },
    {
      field: 'dateFrom',
      headerName: 'Date From',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'dateTo',
      headerName: 'Date To',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'monday',
      headerName: 'Monday',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'tuesday',
      headerName: 'Tuesday',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'wednesday',
      headerName: 'Wednesday',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'thursday',
      headerName: 'Thursday',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'friday',
      headerName: 'Friday',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'saturday',
      headerName: 'Saturday',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'sunday',
      headerName: 'Sunday',
      headerClassName: 'header',
      type: 'string',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      align: 'right',
      cellClassName: cellValues => (cellValues.row.isLast ? 'last' : 'notlast'),
      renderCell: cellValues => cellValues.value,
    },
  ]

  return (
    <Box sx={{ width: '100%' }}>
      <DataGridPro
        getRowId={row => row.id}
        rows={data}
        columns={mobilePortrait ? mobileColumns : columns}
        disableSelectionOnClick
        rowsPerPageOptions={[10, 25, 50, 100]}
        pagination
        pageSize={pageSize}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        rowHeight={50}
        autoHeight
        components={{ NoRowsOverlay }}
        initialState={{
          pinnedColumns: { left: ['department', 'team'] },
        }}
        sx={{
          '&.MuiDataGrid-root': {
            border: 'none !important ',
            marginLeft: '-0.5rem',
            paddingTop: '40px',
          },
          '.MuiDataGrid-row .notlast': {
            borderBottom: '1px solid rgba(244, 244, 244, 1)',
          },
          '.header': {
            backgroundColor: 'rgb(247, 247, 247)',
          },
          '& .MuiDataGrid-virtualScrollerContent': {
            minHeight: '200px!important',
          },
          '& .MuiDataGrid-virtualScroller': {
            maxHeight: '360px',
          },
        }}
      />
    </Box>
  )
}

export default HolidaysThresholdsRestrictionsDailyGrid
