import { Grid } from '@mui/material'
import Card from '../../shared/layout/Card'
import PageHeader from '../../shared/layout/PageHeader/PageHeader'
import SearchAndHistory from '../SearchAndHistory'

function Reporting() {
  return (
    <Grid item xs={12}>
      <PageHeader title="Reporting" />
      <Card title="Absence & Late Search">
        <SearchAndHistory />
      </Card>
    </Grid>
  )
}

export default Reporting
