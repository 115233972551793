import { Stack } from '@mui/material'
import { Map as MapIcon } from '@mui/icons-material'
import { useDispatch, useSelector, batch } from 'react-redux'
import { useState, useMemo } from 'react'
import Card from '../../../shared/layout/Card'
import Button from '../../../shared/UI/Button'
import Paragraph from '../../../shared/UI/Paragraph'
import { BookingShowMeProps } from './types'
import {
  setBookingFloorplanViewingDate,
  setBookingGridViewingDate,
  setDeskBookingLoading,
  setDeskBookingFocussedZoneID,
  setDeskBookingDashboardResults,
  setDeskBookingFloorplanManuallySelected,
} from '../../../redux/reducers/deskBookingReducer'
import { RootStore } from '../../../redux/store'
import {
  defaultFloorplanIdIfNoneSelected,
  defaultZoneIdIfNoneSelected,
  showComponents,
} from '../utils'
import { setBookingWizardStepState } from '../../../redux/reducers/deskBookingWizardReducer'
import { BookingWizardSteps } from '../BlockBooking/enums'
import { setDeskBookingSearchParams } from '../../../redux/reducers/deskBookingSearchReducer'
import { FIRST_FLOORPLAN_IN_ARRAY } from '../consts'
import { BookingEmployeeBooking } from '../../../services/booking/types'

export function BookingShowMe({ todaysBookingData, onShowMeShared }: BookingShowMeProps) {
  const dispatch = useDispatch()

  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)
  const { floorplans, zones } = useSelector((state: RootStore) => state.deskBooking)

  const [todaysBooking, setTodayBookingData] = useState<BookingEmployeeBooking | undefined>()

  if (JSON.stringify(todaysBookingData) !== JSON.stringify(todaysBooking)) {
    setTodayBookingData(todaysBookingData)
  }

  const employeeFloorplans = useMemo(
    () =>
      floorplans.filter(f => f.locationId === employeeDetails.location.id) ||
      floorplans[FIRST_FLOORPLAN_IN_ARRAY],
    [employeeDetails.location.id, floorplans]
  )

  const employeeZones = useMemo(
    () =>
      zones.filter(
        z =>
          employeeFloorplans.some(s => s.id === z.floorPlanId) &&
          z.belongsTo === employeeDetails.departmentId
      ),
    [employeeDetails.departmentId, employeeFloorplans, zones]
  )

  return (
    <>
      <Card
        style={{
          boxShadow: 'none',
          backgroundColor: '#20C5A04D',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between">
          <Stack direction="column" display="flex" alignItems="flex-start">
            <Stack direction="row" display="flex" alignItems="center" gap={1}>
              <MapIcon fontSize="medium" sx={{ color: '#2C2965' }} />
              <Paragraph size="13px" color="#2C2965" weight="bold">
                Floor Plan
              </Paragraph>
            </Stack>
            <Paragraph size="35px" color="#2C2965" weight="bold">
              Today
            </Paragraph>
          </Stack>
          <Button
            label="Show"
            style={{ margin: 0 }}
            onClick={() => 
              batch(() => {

                showComponents(dispatch, { floorplan: true, navbar: true, search: false, filter: true })
                dispatch(setBookingGridViewingDate(new Date()))
                dispatch(setBookingWizardStepState(BookingWizardSteps.STEP_0_INACTIVE))

                dispatch(setDeskBookingDashboardResults([]))

                let zoneId: number = (
                  employeeZones[0] || defaultZoneIdIfNoneSelected(employeeZones)
                ).id

                let thisShowMeSearchParams = {
                  locationId: employeeDetails.location.id,
                  floorplanId: defaultFloorplanIdIfNoneSelected(employeeZones, employeeFloorplans),
                  zoneId,
                  date: new Date(),
                  from: '00:00:00',
                  to: '23:59:59',
                  autoSearch: true,
                }

                if (todaysBooking && Number(todaysBooking?.id) > 0) {
                  const { fromTime, toTime, feature } = todaysBooking
                  const { locationId, floorPlanId, zone } = feature

                  zoneId =
                    zone?.id ||
                    zones.find(
                      f =>
                        // f.belongsTo === employeeDetails.departmentId &&
                        f.floorPlanId === floorPlanId
                    )?.id ||
                    defaultZoneIdIfNoneSelected(employeeZones) ||
                    0

                  thisShowMeSearchParams = {
                    locationId,
                    floorplanId: floorPlanId,
                    date: new Date(),
                    from: fromTime,
                    to: toTime,
                    autoSearch: true,
                    zoneId, // : todaysBooking?.feature.zone?.id,
                  }
                }

                dispatch(setDeskBookingLoading(true))
                dispatch(setDeskBookingFocussedZoneID(zoneId))

                dispatch(setDeskBookingSearchParams(thisShowMeSearchParams))

                onShowMeShared(thisShowMeSearchParams)
              })
            }
          />
        </Stack>
      </Card>
    </>
  )
}
