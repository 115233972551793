import { Box, ButtonGroup } from '@mui/material'
import { ZoomIn as ZoomInIcon, ZoomOut as ZoomInOut } from '@mui/icons-material'
import { BookingZoomButton } from './components'
import { BookingZoomProps } from './types'

export function BookingZoom({ factor, onZoom, top = 5 }: BookingZoomProps) {
  return (
    <Box id="zoom" position="absolute" right={12} top={top} zIndex={1500}>
      <ButtonGroup orientation="vertical" variant="contained">
        <BookingZoomButton onClick={() => onZoom(factor)}>
          <ZoomInIcon style={{ color: 'white' }} />
        </BookingZoomButton>
        <BookingZoomButton onClick={() => onZoom(-factor)}>
          <ZoomInOut style={{ color: 'white' }} />
        </BookingZoomButton>
      </ButtonGroup>
    </Box>
  )
}
