import { ListItemButton } from '@mui/material'
import styled from 'styled-components'
import Card from '../../../shared/layout/Card'

export const HorizontalRule = styled.hr`
  margin-bottom: 40px;
`
export const StyledCard = styled(Card)`
  display: flex;
  flex-flow: row;
  flex: 1 1 auto;
`
export const StyledListItemButton = styled(ListItemButton)`
  padding: 10px 10px !important;
`
