export type IndicatorTheme = {
  label: string
  fontColor: string
  bgColor: string
  borderColor: string
}
export const trendIncdicatorThemes = {
  overtime: {
    label: 'Overtime',
    fontColor: '#F0870C',
    bgColor: '#F0870C0D',
    borderColor: '#F0870C27',
  },

  night: {
    label: 'Night',
    fontColor: '#313057',
    bgColor: '#3130570D',
    borderColor: '#31305727',
  },

  onCall: {
    label: 'On Call',
    fontColor: '#401BBA',
    bgColor: '#401BBA0D',
    borderColor: '#401BBA27',
  },

  callOut: {
    label: 'Call Out',
    fontColor: '#1BBAA8',
    bgColor: '#26AFBC0D',
    borderColor: '#26AFBC26',
  },

  total: {
    label: 'Total',
    fontColor: '#555555',
    bgColor: '#FFFFFF',
    borderColor: '#55555524',
  },
}
export const titleStyles = {
  fontSize: '18px',
  color: '#000000',
  fontWeight: '600',
  lineHeight: '24px',
  letterSpacing: '-0.72px',
  marginBottom: '16px',
}
export const totalCountStyles = {
  fontSize: '28px',
  color: '#000000',
  fontWeight: '600',
  letterSpacing: '-1.4px',
  lineHeight: '28px',
  marginLeft: '16px',
  marginRight: '4px',
}

export const cardStyles = {
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  cursor: 'pointer',
  boxShadow: '1px 2px 10px #00000015',
  flex: 1,
  borderRadius: '16px',
}
