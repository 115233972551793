import { format } from 'date-fns'
import { Grid } from '@mui/material'
import { DateRange } from '@mui/x-date-pickers-pro'
import { useState } from 'react'

import Button from '../../../../shared/UI/Button'
import AutocompleteList from '../../../../shared/UI/AutocompleteList'
import DateRangePicker from '../../../../shared/UI/DateRangePicker'

import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { FormProps, Search } from './types'
import { SelectOption } from '../../../../services/dashboardService'

function HolidaysAvailabilitySearchForm({ resetForm, onSearch }: FormProps) {
  function getStartOfWeek() {
    const date = new Date()
    const today = date.getDate()
    const currentDay = date.getDay()
    const newDate = date.setDate(today - currentDay + 1)
    return new Date(newDate)
  }

  function getEndOfWeek() {
    const date = new Date()
    const today = date.getDate()
    const currentDay = date.getDay()
    const newDate = date.setDate(today - currentDay + 7)
    return new Date(newDate)
  }

  const [fromDateRange, setFromDateRange] = useState<DateRange<Date>>([
    getStartOfWeek(),
    getEndOfWeek(),
  ])
  const [selectedDirectReportees, setSelectedDirectReportees] = useState<SelectOption | null>({
    displayValue: 'Direct Reports',
    value: 1,
  })

  const allDirectReporteesOptions: SelectOption[] = [
    { displayValue: 'Direct Reports', value: 1 },
    { displayValue: 'Hierarchy', value: 2 },
    { displayValue: 'Team', value: 3 },
  ]

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const search: Search = {
      fromDateRangeFrom: fromDateRange[0] !== null ? format(fromDateRange[0], 'yyyy-MM-dd') : '',
      fromDateRangeTo: fromDateRange[1] !== null ? format(fromDateRange[1], 'yyyy-MM-dd') : '',
      directReportees:
        selectedDirectReportees?.displayValue === 'Direct Reports'
          ? 'DirectReportees'
          : selectedDirectReportees?.displayValue,
    }

    onSearch(search)
  }

  useDidMountEffect(() => {
    setSelectedDirectReportees({ displayValue: 'Direct Reports', value: 1 })
    setFromDateRange([getStartOfWeek(), getEndOfWeek()])
  }, [resetForm])

  return (
    <Grid container spacing={4} component="form" noValidate mt={0}>
      {/* Main Layout 4 Col */}
      <Grid item xs={12} lg={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DateRangePicker
              arrange="Horizontal"
              startText="Date from"
              endText="Date to"
              onChange={(date: DateRange<Date>, keyboardInputValue?: string | undefined): void =>
                setFromDateRange(date)
              }
              value={fromDateRange}
            />
          </Grid>
          {/* Main Layout 4 Col END */}
        </Grid>
      </Grid>
      {/* Main Layout 8 Col */}
      <Grid item xs={12} lg={8}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Grid item xs={12} style={{ flex: 1 }}>
              <AutocompleteList
                id="directreportees"
                label="Category"
                data={allDirectReporteesOptions}
                textField="displayValue"
                value={selectedDirectReportees ? selectedDirectReportees! : null}
                onChange={(e, newValue) => {
                  setSelectedDirectReportees(newValue)
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Main Layout 8 Col END */}
      </Grid>
      <Grid item xs={12} pt={0} mt={0} display="flex" justifyContent="flex-end">
        <Button label="Search" type="submit" onClick={handleSubmit} />
      </Grid>
    </Grid>
  )
}

export default HolidaysAvailabilitySearchForm
