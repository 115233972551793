import store from '../redux/store'
import {
  CurrentEntitlementPeriodResponse,
  Employee,
  SelectOption,
  SelectValues,
} from '../services/dashboardService'
import { HolidayRestrictionResponse } from '../types/holiday-restrictions-response'
import { OptionValue } from '../types/option-value'
import {
  CREATE_REQUEST,
  dashboardPermissions,
  MANAGE_REQUESTS,
  managerPermisssions,
  myAvailabilityPermissions,
  myExtrasPermissions,
  REQUIRED_MESSAGE,
  settingsPermissions,
  wallChartPermisssions,
  CREATE_ATTENDANCE,
  DEFAULT_ALLOWED_YEARS_IN_PAST,
  EntitlementPeriodType,
  DEFAULT_ALLOWED_YEARS_IN_FUTURE,
  myRequestsPermissions,
  ATTENDANCE_HISTORY,
  bookingPermisssions,
  enhancementPermissions,
} from './constants'
import { getDateArrayFromRange } from './date-utils'
import { RequestType } from '../models/request-type'
import { manualRequestsService } from '../services/myActionsService'
import { LocationGroups } from '../types/location-groups'
import { BaseResponse } from '../types/base-response'
import { SettingsResponse } from '../types/settings-response'

// eslint-disable-next-line no-shadow
export enum FeatureToggleType {
  Unspecified = 0,
  BssAdmin = 1,
  NotificationLogsAdmin = 2,
  TerritoryAttendanceUk = 3,
  Enhancement = 4,
}

export const isFeatureOn = (feature: FeatureToggleType): boolean => {
  switch (feature) {
    case FeatureToggleType.BssAdmin:
      return true
    case FeatureToggleType.NotificationLogsAdmin:
      return (
        isFeatureOn(FeatureToggleType.BssAdmin) && store.getState().featureToggles.notificationLogs
      )
    case FeatureToggleType.TerritoryAttendanceUk:
      return (
        isFeatureOn(FeatureToggleType.BssAdmin) &&
        store.getState().featureToggles.territoryAttendanceUK
      )
    case FeatureToggleType.Enhancement:
      return store.getState().featureToggles.enhancement
    case FeatureToggleType.Unspecified:
    default:
      return false
  }
}

export const hasUserAccessToRoute = (
  route: string,
  permissions: string[],
  contractor: boolean,
  userSettings: SettingsResponse | null,
  canUseBssAdmin?: boolean
) => {
  if (route === '/dashboard') {
    return permissions.some(permission => dashboardPermissions.includes(permission))
  }
  if (route === '/myavailability') {
    return permissions.some(permission => myAvailabilityPermissions.includes(permission))
  }
  if (route === '/myavailability') {
    return permissions.some(permission => myRequestsPermissions.includes(permission))
  }
  if (route === '/myextras') {
    if (contractor) {
      return false
    }
    return permissions.some(permission => myExtrasPermissions.includes(permission))
  }
  if (route === '/wallchart') {
    return permissions.some(permission => wallChartPermisssions.includes(permission))
  }
  if (route === '/booking' || route === '/booking/designer') {
    return permissions.some(permission => bookingPermisssions.includes(permission))
  }
  if (route === '/myactions') {
    return permissions.some(permission => managerPermisssions.includes(permission))
  }
  if (route === '/enhancements') {
    return (
      permissions.some(permission => enhancementPermissions.includes(permission)) &&
      userSettings?.hasEnhancements === true
    )
  }
  if (route === '/reporting') {
    return (
      permissions.some(permission => managerPermisssions.includes(permission)) ||
      permissions.includes(ATTENDANCE_HISTORY)
    )
  }
  if (route === '/settings') {
    return permissions.some(permission => settingsPermissions.includes(permission))
  }
  if (route === '/test') {
    return true
  }
  if (route === '/bssadmin') {
    return (canUseBssAdmin ?? false) && isFeatureOn(FeatureToggleType.BssAdmin)
  }
  if (route === '/bssadmin/featuretoggles') {
    return (canUseBssAdmin ?? false) && isFeatureOn(FeatureToggleType.BssAdmin)
  }
  if (route === '/bssadmin/notificationlogs') {
    return (canUseBssAdmin ?? false) && isFeatureOn(FeatureToggleType.NotificationLogsAdmin)
  }

  return false
}

export const getSelectValuesByType = (optionType: string) => {
  const { selectValues } = store.getState().appSettings
  const lookupValues = (selectValues as SelectValues[]).find(
    value => value.optionsType === optionType
  )
  if (lookupValues) {
    return lookupValues.options
  }
  return []
}

export const canCreateRequest = () => {
  const { permissions } = store.getState().userState
  return permissions.includes(CREATE_REQUEST)
}

export const canManageRequest = () => {
  const { permissions } = store.getState().userState
  return permissions.includes(MANAGE_REQUESTS)
}

export const canCreateAttendance = () => {
  const { permissions } = store.getState().userState
  return permissions.includes(CREATE_ATTENDANCE)
}

export const sicknessTypeSortedData = (employeeId: number) =>
  manualRequestsService.getAbsenceReasonsByEmployeeId(employeeId).then(e =>
    e.locationAbsenceReasons.options
      .filter(x => x.associatedValue === 1)
      .sort((a, b) => {
        const previousDisplayValue = a.displayValue.toLowerCase()
        const nextDisplayValue = b.displayValue.toLowerCase()

        if (previousDisplayValue < nextDisplayValue) {
          return -1
        }
        if (previousDisplayValue > nextDisplayValue) {
          return 1
        }

        return 0
      })
  )

const yearEndDate = (
  yearsIntoTheFuture: number,
  entitlementPeriod: string,
  dateRange: any,
  calendarDetails: any
) => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()

  if (entitlementPeriod === 'CalendarYear') {
    return new Date(currentYear + yearsIntoTheFuture, 11, 31)
  }

  const selectedYear =
    new Date(dateRange).toString() !== 'Invalid Date'
      ? new Date().getFullYear()
      : new Date(dateRange[0] || dateRange[1] || new Date()).getFullYear()

  const financialYear = calendarDetails.calendarDetails.filter(
    (e: CurrentEntitlementPeriodResponse) => new Date(e.endDate).getFullYear() === selectedYear
  )

  const endMonth = financialYear.length > 0 ? new Date(financialYear[0].endDate).getMonth() : 11
  const endDay = financialYear.length > 0 ? new Date(financialYear[0].endDate).getDate() : 31

  return new Date(currentYear + yearsIntoTheFuture, endMonth, endDay)
}

export const getRestrictedStartDate = (
  isHTL: boolean,
  entitlement: string,
  dateRange: any,
  calendarDetails: any
) => {
  if (isHTL) {
    return yearEndDate(-2, entitlement, dateRange, calendarDetails)
  }
  return yearEndDate(-1, entitlement, dateRange, calendarDetails)
}

export const getRestrictedDate = (
  isHTL: boolean,
  entitlement: string,
  dateRange: any,
  calendarDetails: any
) => {
  if (isHTL) {
    return yearEndDate(5, entitlement, dateRange, calendarDetails)
  }
  return yearEndDate(2, entitlement, dateRange, calendarDetails)
}

export const requiredFieldValidation = { required: { value: true, message: REQUIRED_MESSAGE } }

export interface YearRequest {
  entitlementPeriod: EntitlementPeriodType
  yearsInPast: number | null
  yearsInFuture: number
  entitlementYear?: number
}

export const getYearSelectOptions = (
  yearRequest: YearRequest,
  entitlementPeriod?: string
): OptionValue[] => {
  const currentYear = yearRequest.entitlementYear || new Date().getFullYear()
  const yearsInPast =
    yearRequest.yearsInPast !== null ? yearRequest.yearsInPast : DEFAULT_ALLOWED_YEARS_IN_PAST
  const yearsInFuture = yearRequest.yearsInFuture
    ? yearRequest.yearsInFuture
    : DEFAULT_ALLOWED_YEARS_IN_FUTURE
  const years: SelectOption[] = []
  const lastYearOffset = entitlementPeriod === 'FinancialYear' ? 1 : 0
  for (let i = yearsInPast; i <= yearsInFuture + lastYearOffset; i += 1) {
    const displayText =
      yearRequest.entitlementPeriod === 'CalendarYear'
        ? (currentYear + i).toString()
        : `${currentYear + i}/${currentYear + i + 1}`

    years.push({
      value: currentYear + i,
      displayValue: displayText,
    })
  }
  return years
}

export const getCalendarRestrictions = (
  deptRestrictions: HolidayRestrictionResponse,
  requestType: RequestType
) => {
  let restrictionType:
    | 'restrictShift'
    | 'restrictDayOff'
    | 'restrictHoliday'
    | 'restrictLieuDay'
    | 'restrictWFH'
  switch (requestType) {
    case RequestType.HOLIDAY:
    case RequestType.HOLIDAY_M:
      restrictionType = 'restrictHoliday'
      break
    case RequestType.SHIFT:
      restrictionType = 'restrictShift'
      break
    case RequestType.DAY_OFF:
      restrictionType = 'restrictDayOff'
      break
    case RequestType.LIEU_DAY:
    case RequestType.LIEU_DAY_M:
      restrictionType = 'restrictLieuDay'
      break
    case RequestType.WORK_FROM_HOME:
      restrictionType = 'restrictWFH'
      break
    default:
      break
  }
  const holidayRestrictions = deptRestrictions.holidayRestrictions.filter(
    restr => Boolean(restr[restrictionType]) === true
  )
  if (!holidayRestrictions.length) {
    return []
  }
  let restrictions: string[] = []
  holidayRestrictions.forEach(hol => {
    const res = getDateArrayFromRange(new Date(hol.dateFrom), new Date(hol.dateTo), 'yyyy-MM-dd')
    restrictions = restrictions.concat(res)
  })
  return restrictions
}

export const formatRequestTypeString = (requestType: string) => {
  switch (requestType.toLowerCase()) {
    case 'workfromhome':
      return 'Work From Home'
    case 'dayoff':
      return 'Day Off'
    case 'lieuday':
      return 'Lieu Day'
    default:
      return requestType
  }
}

export const dateOrdinal = (num: string | number): string => {
  const val = Number(num)
  if (val >= 4 && val <= 20) {
    return 'th'
  }
  if (String(val).endsWith('1')) {
    return 'st'
  }
  if (String(val).endsWith('2')) {
    return 'nd'
  }
  if (String(val).endsWith('3')) {
    return 'rd'
  }
  return 'th'
}

export const getJsonFromPath = (obj: string, textPath: string, del = '.') =>
  textPath.split(del).reduce((o, k: any) => o && o[k], obj)

const validHexRegex = /[0-9A-Fa-f]{6}/g
export const isHex = (input: string) => input.match(validHexRegex)

export const getScaEdDrawerTitle = (
  locationGroupName: string | undefined,
  territoryAttendanceUK: boolean
) => {
  let title = 'Self-Certification of Absence'
  if (territoryAttendanceUK) {
    switch (locationGroupName) {
      case LocationGroups.UK:
        title = 'Employee Declaration'
        break
      default:
        break
    }
  }
  return title
}

export const findEmployeesByDepartmentId = (departmentId: number): Employee[] => {
  const { allEmployees } = store.getState().appSettings
  return allEmployees.filter((e: { departmentId: number }) => e.departmentId === departmentId)
}

export const getFilteredDepartmentsDropdownOptions = (
  departments: SelectOption[],
  allEmployees: Employee[],
  includeAll = true
): SelectOption[] => {
  if (!departments || !allEmployees) {
    return []
  }

  const depIds = new Set<number>(allEmployees.map(e => e.departmentId))
  const filtered = Array.from(depIds.values())
    .map(id => departments.find(dep => dep.value === id)!)
    .filter(dep => dep.displayValue !== 'Unallocated')

  if (includeAll && filtered.length > 1) {
    filtered.unshift({ displayValue: 'All', value: 0 })
  }

  return filtered.sort((a, b) =>
    a.displayValue.toLowerCase() > b.displayValue.toLowerCase() ? 0 : -1
  )
}

export const sleep = (ms: number) =>
  new Promise<void>(resolve => {
    setTimeout(() => {
      resolve()
    }, ms)
  })

export const is500Error = (baseResponse: BaseResponse | undefined) =>
  baseResponse &&
  baseResponse?.status === 500 &&
  (baseResponse.errors === undefined || baseResponse.errors.length === 0)
