import { Stack } from '@mui/material'
import styled from 'styled-components'
import { isMobilePortrait } from '../../../theme/deviceChecks'

export const DrawerButtonStack = styled(Stack)`
  div&.drawerFooterButtonStack {
    flex-direction: row;
    justify-content: flex-end;
  }

  ${isMobilePortrait()} {
    div&.drawerFooterButtonStack {
      justify-content: center;
    }
  }
`
