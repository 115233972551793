import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const PeriodView = styled.div`
  @keyframes slideDown {
    0% {
      height: 0;
      padding-top: 0;
      opacity: 0;
    }
    100% {
      height: 52px;
      padding-top: 6px;
      opacity: 1;
    }
  }
  @keyframes slideUp {
    0% {
      height: 52px;
      padding-top: 6px;
      opacity: 1;
    }
    100% {
      height: 0;
      padding-top: 0;
      opacity: 0;
    }
  }
  & {
    height: 0;
    padding-top: 0;
    opacity: 0;
  }
  &.periodUp {
    animation: slideUp 250ms;
    overflow: hidden;
    height: 0;
  }
  &.periodDown {
    animation: slideDown 250ms;
    height: 52px;
    opacity: 1;
  }
`

export const BookingGridContainer = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 17px;
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    background-clip: content-box;
  }
  height: 470px;
  margin-top: 16px;
`


export const BookingScrollContainer = styled(Box)`
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #e0e0e0;
  width: 100%;

  height: 100%;
  margin-top: 16px;

  @media (max-height: 940px) {
    min-height: 790px;
    max-height: 790px;
    height: 100%;
  }

  @media (max-height: 700px) {
    min-height: 520px;
    max-height: 520px;
    height: 100%;
  }

  @media (max-height: 430px) {
    min-height: 280px;
    max-height: 280px;
    height: 100%;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    border-radius: 20px;
  }
`
