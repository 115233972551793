import styled from '@emotion/styled'

export const BookingDesignerPopover = styled.div<{
  width: string
  height: string
  x: string
  y: string
}>`
  ${props => `
    @keyframes bookingDesignerfadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }  
    & {
      animation: bookingDesignerfadeIn 500ms;
      background-color: white;
      width: ${props.width};
      min-height: ${props.height};
      position: absolute;
      left: ${props.x};
      top: ${props.y};
      border-radius: 5px;
      box-shadow: 0 0 5px 0 #000000;
      padding: 10px;
      z-index: 999;
      }
    `}
`
