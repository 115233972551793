import { Box, Grid, useMediaQuery } from '@mui/material'
import {
  differenceInCalendarMonths,
  eachDayOfInterval,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  startOfMonth,
  subDays,
} from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { PageContext } from '../../context/MyRequestsPageContext'
import {
  displayAbsenceDrawer,
  hideAbsenceDrawer,
  hideMyRequestsDrawer,
  setEntitlement,
  showMyRequestsDrawer,
} from '../../redux/reducers/appSettingsReducer'
import { setSelectedYear } from '../../redux/reducers/myRequestsReducer'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import { setDefaultDateRange } from '../../redux/reducers/timeOffRequestsReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import { availabilityService } from '../../services/availabilityService'
import { SelectOption } from '../../services/dashboardService'
import { useNewRequestStatus } from '../../services/useNewRequestStatus'
import Button from '../../shared/UI/Button'
import Drawer from '../../shared/UI/Drawer'
import FullComponentLoadingIcon from '../../shared/UI/LoadingIndicator/FullComponentLoadingIcon'
import MobileContainer from '../../shared/layout/MobileContainer'
import PageHeader from '../../shared/layout/PageHeader/PageHeader'
import { PageHeaderGrid } from '../../shared/layout/PageHeader/components'
import { isDesktopDown, isMobileDown, isMobilePortrait } from '../../theme/deviceChecks'
import { BaseResponse } from '../../types/base-response'
import { EmployeeDetailsResponse } from '../../types/employee'
import { HolidayRequest } from '../../types/holiday-request'
import { MyEntitlementResponse } from '../../types/my-entitlement-response'
import { MyRequests as MyRequestsType } from '../../types/my-requests'
import { RequestDataType } from '../../types/request-data-type'
import { canCreateRequest } from '../../utils/app-utils'
import { formatDateWithTimeZone } from '../../utils/date-utils'
import UserErrorMessage from '../../utils/errorFilter'
import MyRequestsEntitlementCard from '../MyRequestsEntitlementCard'
import NewRequest from '../NewRequest'
import { PlottingCalendars } from '../PlottingCalendarCustom/PlottingCalendars'
import { PlottingCalendarDates, PlottingCalendarRequest } from '../PlottingCalendarCustom/types'
import { buildData } from '../PlottingCalendarCustom/utils'
import { MyRequestsBanner } from './MyRequestsBanner'
import { MyRequestsGrid } from './MyRequestsGrid'
import { DrawerComponentProps } from './MyRequestsGrid/types'
import { MyRequestsLegendsFilter } from './MyRequestsLegendsFilter'
import { MyRequestsSummaryItems } from './MyRequestsSummaryItems'
import { MyRequestsToggles } from './MyRequestsToggles'
import { MyRequestsViewType } from './MyRequestsToggles/types'
import ViewEditOccurrence from './ViewEditOccurrence'
import { useEmployeeDefaultShiftLength } from './useEmployeeDefaultShiftLength'
import { setYear } from './utils'

let seasonalSplit: boolean | undefined
interface HistoryState {
  calendar?: boolean
  previousLocation?: string
  gridFilters?: string[] | undefined
  calendarFilters?: string[]
}

interface ViewState {
  isMobile: boolean
  isCalendar: boolean
}

function MyRequests() {
  const [viewType, setViewType] = useState<MyRequestsViewType>(MyRequestsViewType.CALENDAR)
  const [requestsData, setRequestsData] = useState<RequestDataType[]>([])
  const [disabledDates, setDisabledDates] = useState<Date[]>([])
  const [showViewDrawer, setShowViewDrawer] = useState<boolean>(false)
  const [drawerComponent, setDrawerComponent] = useState<DrawerComponentProps | null>(null)
  const [isData, setIsData] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showMySummaryDrawer, setShowMySummaryDrawer] = useState<boolean>(false)
  const [drawerTitle, setDrawerTitle] = useState<string>('')
  const [drawerType, setDrawerType] = useState<React.ReactElement>()
  const [closeDrawerLoading, setCloseDrawerLoading] = useState<boolean>(false)
  const [apiData, setApiData] = useState<PlottingCalendarRequest[]>()
  const [plottingDateRange, setPlottingDateRange] = useState<{
    start: Date
    end: Date
    length: number
  }>()
  const [plottingDates, setPlottingDates] = useState<PlottingCalendarDates[]>([])
  const [filteredPlottingDates, setFilteredPlottingDates] = useState<PlottingCalendarDates[]>([])
  const [plottingFilters, setPlottingFilters] = useState<string[]>([])
  const [definedFilters, setDefinedFilters] = useState<string[]>([])
  const [lastSingleFilter, setLastSingleFilter] = useState<string>('loading')
  const [entitlementRepo, setEntitlementRepo] = useState<
    { year: SelectOption; data: MyEntitlementResponse }[]
  >([])
  const [changingYear, setChangingYear] = useState<{
    calendarLoading: boolean
    textLoading: boolean
  }>({ calendarLoading: true, textLoading: true })
  const navigate = useNavigate()
  const location = useLocation()
  const { employeeDefaultShiftLength } = useEmployeeDefaultShiftLength()

  const { userSettings } = useSelector((state: RootStore) => state.appSettings)
  const enhancementsSettingOn = useMemo(() => userSettings?.hasEnhancements, [userSettings])
  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse | undefined>(
    (state: RootStore) => state.appSettings.employeeDetails
  )

  const isHTL = useMemo(() => employeeDetails?.isHtl ?? false, [employeeDetails?.isHtl])
  const isContractor = useMemo(
    () => employeeDetails?.isContractor ?? false,
    [employeeDetails?.isContractor]
  )

  const [isNewRequestEnabled] = useNewRequestStatus()

  useEffect(() => {
    setDrawerTitle('My Summary')
    setDrawerType(<MyRequestsSummaryItems onCancel={() => setShowMySummaryDrawer(false)} />)
  }, [])

  const isHtlContractor = useMemo(() => isHTL && isContractor, [isContractor, isHTL])
  const dispatch = useAppDispatch()
  const mobile = useMediaQuery(isMobileDown())
  const isBelowDesktop = useMediaQuery(isDesktopDown())
  const isMobPortrait = useMediaQuery(isMobilePortrait())

  const { refreshurlepoch } = useParams<{ refreshurlepoch: string }>()
  const { id: requestId } = useParams<{ id: string }>()

  const [viewStateHistory, setViewStateHistory] = useState<ViewState[]>([])

  useEffect(() => {
    if (mobile) {
      setViewType(MyRequestsViewType.GRID)
    } else {
      const desktopViewStates = viewStateHistory.filter(e => e.isMobile === false)
      if (desktopViewStates.length === 0) {
        setViewType(MyRequestsViewType.CALENDAR)
        return
      }

      const lastDesktopViewState = desktopViewStates.slice(-1)[0]
      setViewType(
        lastDesktopViewState.isCalendar ? MyRequestsViewType.CALENDAR : MyRequestsViewType.GRID
      )

      let lastDesktopIndex
      for (let i = 0; i < viewStateHistory.length; i += 1) {
        if (viewStateHistory[i].isMobile === false) {
          lastDesktopIndex = i
        }
      }

      if (!lastDesktopIndex) {
        return
      }

      const shortenedHistory = viewStateHistory.slice(lastDesktopIndex)
      setViewStateHistory(shortenedHistory)
    }
  }, [mobile])

  useEffect(() => {
    setViewStateHistory([
      ...viewStateHistory,
      { isMobile: mobile, isCalendar: viewType === MyRequestsViewType.CALENDAR },
    ])
  }, [viewType])

  useEffect(() => {
    if (location.state) {
      setViewType(
        (location.state as HistoryState).calendar
          ? MyRequestsViewType.CALENDAR
          : MyRequestsViewType.GRID
      )
    }
  }, [location])

  const {
    currentEntitlementPeriodResponse: entitlement,
    calendarDetailsResponse: financialYearCalendarDetails,
    showAbsenceDrawer,
    entitlement: entitlementStore,
    showDrawer,
  } = useSelector((state: RootStore) => state.appSettings)

  const showSideDrawer = useSelector<RootStore, boolean>(
    (state: RootStore) => state.appSettings.showMyRequestsDrawer
  )

  const { selectedYear } = useSelector((state: RootStore) => state.myRequests)

  useEffect(() => {
    if (entitlement && !selectedYear) {
      dispatch(setSelectedYear(setYear(entitlement.year, entitlement)))
    }
  }, [dispatch, entitlement, selectedYear])

  const getFinancialYearStartDate = useCallback(
    (year: number) => {
      const startDate = financialYearCalendarDetails?.calendarDetails?.find(
        details => details.year === year
      )?.startDate
      return formatDateWithTimeZone(startDate || entitlement!.startDate)
    },

    [entitlement, financialYearCalendarDetails?.calendarDetails]
  )

  const getFinancialYearEndDate = useCallback(
    (year: number) => {
      const endDate = financialYearCalendarDetails?.calendarDetails?.find(
        details => details.year === year
      )?.endDate
      return formatDateWithTimeZone(endDate || entitlement!.endDate)
    },
    [entitlement, financialYearCalendarDetails?.calendarDetails]
  )

  useEffect(() => {
    if (!apiData || !plottingFilters || !selectedYear || !entitlement) {
      return
    }

    const dates = buildData({
      requests: apiData,
      filters: plottingFilters,
      year: selectedYear.value,
      isFinancialYear: entitlement.entitlementPeriod === 'FinancialYear',
    })

    setPlottingDates(dates)
    setIsData(true)
  }, [apiData, entitlement, plottingFilters, selectedYear])

  const getRequestsData = useCallback(async () => {
    if (!selectedYear || !entitlement) {
      return
    }
    const financialYearStartDate = getFinancialYearStartDate(selectedYear.value)
    const financialYearEndDate = getFinancialYearEndDate(selectedYear.value)
    if (enhancementsSettingOn !== undefined) {
      const getMyAvailability = enhancementsSettingOn
        ? availabilityService.getMyAvailabilityV2(selectedYear.value)
        : availabilityService.getMyAvailability(selectedYear.value)
      getMyAvailability
        .then((data: MyRequestsType) => {
          if (data?.requests) {
            const requests: PlottingCalendarRequest[] = data.requests
              .filter(
                request =>
                  request.requestType?.toLowerCase() !== 'buy' &&
                  request.requestType?.toLowerCase() !== 'sell' &&
                  request.requestType?.toLowerCase() !== 'adjustment' &&
                  request.statusDescription?.toLowerCase() !== 'cancelled' &&
                  request.statusDescription?.toLowerCase() !== 'declined'
              )
              .map((m: HolidayRequest) => ({
                id: m.id,
                submitDateTime: m.submitDateTime,
                dateFrom: m.dateFrom,
                dateTo: m.dateTo || m.dateFrom,
                statusDescription: m.statusDescription,
                isCancellation: m.isCancellation || false,
                isQueried: m.isQueried || false,
                requestType: m.requestType,
                days: m.days.map(d => ({
                  date: format(formatDateWithTimeZone(d.date), 'yyyy-MM-dd'),
                  hours: d.hours,
                })),
              }))

            const possibleFilters = requests.map(m => m.requestType)
            const newFiltersFromData = [...plottingFilters].filter(f => possibleFilters.includes(f))
            setPlottingFilters(newFiltersFromData)
            setApiData(requests)
          }
          // TODO Tech Debt: this is being called twice on render
          let filteredAbsences = data.requests
          if (mobile) {
            filteredAbsences = data.requests.filter(
              t => t.requestType !== 'Absence' && t.requestType !== 'Late'
            )
          }
          const newRequestData: RequestDataType[] = filteredAbsences
            .map(request => ({
              id: request.id,
              type: request.requestType,
              dateFrom: request.dateFrom,
              dateTo: request.dateTo === '0001-01-01T00:00:00' ? request.dateFrom : request.dateTo,
              days: request.days,
              hours: request.hours || 0,
              dateSubmitted: request.submitDateTime,
              actionedBy: request.actionedBy,
              actionedDate:
                request.actionedDate === '0001-01-01T00:00:00' ? '' : request.actionedDate,
              status: request.statusDescription,
              requestTypeId: request.flattenedRequestTypeId,
              manualRequestType: request.manualRequestType,
              isCancellation: request.isCancellation,
              isQueried: request.isQueried,
            }))
            .filter(request => {
              if (
                entitlement.entitlementPeriod !== 'FinancialYear' ||
                request.type === 'Adjustment'
              ) {
                return true
              }

              if (request.days.length === 0) {
                request.days = [
                  { date: formatDateWithTimeZone(request.dateFrom), hours: request.hours || 0 },
                ]
              }

              return request.days.some(day => {
                const afterStartDate = isAfter(
                  formatDateWithTimeZone(day.date),
                  financialYearStartDate
                )
                const beforeEndDate = isBefore(
                  formatDateWithTimeZone(day.date),
                  financialYearEndDate
                )
                return afterStartDate && beforeEndDate
              })
            })

          setRequestsData(newRequestData)
          setPlottingDateRange(() => {
            if (entitlement.entitlementPeriod === 'CalendarYear') {
              return {
                start: new Date(selectedYear.value, 0, 1),
                end: new Date(selectedYear.value, 11, 31),
                length: 12,
              }
            }
            return {
              start: financialYearStartDate,
              end: financialYearEndDate,
              length: differenceInCalendarMonths(financialYearEndDate, financialYearStartDate) + 1,
            }
          })

          setIsLoading(false)
          setChangingYear(prev => ({ ...prev, calendarLoading: false }))
        })
        .catch(err => {
          setIsLoading(false)
          setIsData(false)
          if (err.response?.data) {
            const response: BaseResponse = err.response.data
            response.errors.forEach(error => {
              dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
            })
          } else {
            dispatch(showErrorMessage(<UserErrorMessage name={err.status} />))
          }
        })
    }
  }, [
    dispatch,
    entitlement,
    getFinancialYearEndDate,
    getFinancialYearStartDate,
    mobile,
    selectedYear,
    enhancementsSettingOn,
  ])

  useEffect(() => {
    const currentOccurrence = requestsData.filter(occurrence => occurrence.id === Number(requestId))
    const { status } = currentOccurrence[0] || ''

    setTimeout(() => {
      if (isData) {
        if (showAbsenceDrawer) {
          const type = requestsData.filter(e => e.id === Number(requestId))
          const requestType = type.length > 0 ? (type[0].type as string) : 'Holiday'

          setDrawerComponent({
            title: requestType,
            component: (
              <ViewEditOccurrence
                id={Number(requestId)}
                requestType={requestType}
                closeViewEditDrawer={async () => {
                  setCloseDrawerLoading(true)
                  await availabilityService.deleteRequestLock(Number(requestId))
                  setCloseDrawerLoading(false)
                  setShowViewDrawer(false)
                  dispatch(hideAbsenceDrawer())
                }}
                closeLoading={closeDrawerLoading}
              />
            ),
            status: status?.toString(),
          })
        }
      }
    }, 500)
  }, [
    closeDrawerLoading,
    dispatch,
    navigate,
    isData,
    refreshurlepoch,
    requestId,
    requestsData,
    showAbsenceDrawer,
  ])

  useEffect(() => {
    if (!plottingDateRange || entitlement?.entitlementPeriod !== 'FinancialYear') {
      return
    }
    let dates: Date[] = []
    let disabledDateStart
    let disabledDateEnd
    if (plottingDateRange.start.getDate() > 1) {
      // If first day of range is after the 1st,
      // then we need to disable those pre range-start dates
      disabledDateStart = startOfMonth(plottingDateRange.start)
      disabledDateEnd = subDays(plottingDateRange.start, 1)
      dates = eachDayOfInterval({
        start: disabledDateStart,
        end: disabledDateEnd,
      })
    }
    disabledDateEnd = endOfMonth(plottingDateRange.end)
    if (plottingDateRange.end.getDate() < disabledDateEnd.getDate()) {
      // If last day of range is before the last day of that month,
      // then we need to disable those post range-end dates
      const finalPlottingDate = plottingDateRange.end
      finalPlottingDate.setDate(finalPlottingDate.getDate() + 1)
      dates = dates.concat(eachDayOfInterval({ start: finalPlottingDate, end: disabledDateEnd }))
    }
    setDisabledDates(dates)
  }, [entitlement?.entitlementPeriod, plottingDateRange])

  useEffect(() => {
    if (!selectedYear) {
      return
    }
    availabilityService
      .getEntitlement(selectedYear.value)
      .then(entData => {
        setEntitlementRepo(prev => {
          const temp = prev.filter(x => x.year.value !== selectedYear.value)
          temp.push({ year: selectedYear, data: entData })
          return temp
        })

        setChangingYear(prev => ({ ...prev, textLoading: false }))
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
    getRequestsData()

    return () => {
      if (
        !location.pathname.includes('/myavailability') &&
        entitlement &&
        selectedYear?.value !== entitlement.year
      ) {
        dispatch(setSelectedYear(setYear(entitlement.year, entitlement)))
      }
    }
  }, [
    dispatch,
    entitlement,
    getFinancialYearEndDate,
    getFinancialYearStartDate,
    getRequestsData,
    location,
    selectedYear,
    showSideDrawer,
    showDrawer,
    enhancementsSettingOn,
  ])

  useEffect(() => {
    dispatch(setEntitlement(entitlementRepo))
  }, [dispatch, entitlementRepo])

  const selectedYearEntitlement = useMemo(() => {
    const store = entitlementStore?.filter(x => x.year.value === selectedYear?.value)[0]
    if (!seasonalSplit) {
      seasonalSplit = store?.data.hasEntitlementSeasonalSplit
    }

    if (store) {
      setChangingYear(prev => ({ ...prev, textLoading: false }))
    }
    return store
  }, [entitlementStore, selectedYear?.value])

  const isGrid = useCallback(() => viewType === MyRequestsViewType.GRID, [viewType])

  const viewEvent = useCallback(
    (component: DrawerComponentProps | null) => {
      setDrawerComponent(component)
      dispatch(displayAbsenceDrawer())
    },
    [dispatch]
  )

  const closeViewDrawer = useCallback(() => {
    dispatch(hideAbsenceDrawer())
  }, [dispatch])

  const contextData = useMemo(
    () => ({ getRequestsData, viewEvent, closeViewDrawer }),
    [closeViewDrawer, getRequestsData, viewEvent]
  )

  useEffect(() => {
    const filteredDates: PlottingCalendarDates[] = plottingDates.filter(d => {
      const eventTypes = d.eventType?.split(',')
      return plottingFilters.some(s => eventTypes?.includes(s))
    })
    setFilteredPlottingDates(filteredDates)
  }, [plottingDates, plottingFilters, selectedYear])

  const handleNoFilters = useCallback(() => {
    if (lastSingleFilter === 'loading') {
      return
    }
    if (lastSingleFilter === 'manual_all_off') {
      setPlottingFilters([])
    }
    setPlottingFilters([lastSingleFilter])
  }, [lastSingleFilter])

  const handleOneFilter = useCallback(() => {
    if (lastSingleFilter === plottingFilters[0]) {
      return
    }
    setLastSingleFilter(plottingFilters[0])
  }, [lastSingleFilter, plottingFilters])

  const didFlipTheFilterSwitch = useCallback(() => {
    if (plottingFilters.length !== 1) {
      return
    }
    setLastSingleFilter('manual_all_off')
  }, [plottingFilters.length])

  useEffect(() => {
    if (plottingFilters.length === 1) {
      handleOneFilter()
    }
    const difference: number = plottingFilters.length - definedFilters.length
    if (difference < -1) {
      setPlottingFilters(definedFilters)
      return
    }
    if (plottingFilters.length === 0) {
      handleNoFilters()
      return
    }
    setDefinedFilters(plottingFilters)
  }, [plottingFilters])

  return (
    <PageContext.Provider value={contextData}>
      <PageHeader title="My Availability" />
      {mobile && (
        <PageHeaderGrid item xs={12} pl={2} pr={2}>
          <Button
            label="My Summary"
            onClick={() => setShowMySummaryDrawer(true)}
            disabled={!canCreateRequest() || !isNewRequestEnabled}
          />
        </PageHeaderGrid>
      )}
      <Drawer
        isOpen={showAbsenceDrawer}
        onClose={async () => {
          await availabilityService.deleteRequestLock(Number(requestId))
          dispatch(hideAbsenceDrawer())
        }}
        title={drawerComponent?.title || ''}
        status={drawerComponent?.status || ''}
        showOptions={false}
      >
        <Grid item p={4} pr={0}>
          {drawerComponent?.component}
        </Grid>
      </Drawer>
      <MobileContainer>
        <Grid
          container
          spacing={2}
          flexDirection={(isBelowDesktop || isHtlContractor) && !isGrid() ? 'row-reverse' : 'row'}
        >
          <Grid item xs={12}>
            <MyRequestsToggles
              viewType={viewType}
              viewTypeChange={type => {
                setViewType(type)
              }}
              setChangingYear={setChangingYear}
              changingYear={changingYear.textLoading}
            />
          </Grid>
          {!mobile && !isHtlContractor && (
            <Grid item xs={8} md={12} xl={9}>
              <FullComponentLoadingIcon
                loading={isLoading && !seasonalSplit}
                bgColor="transparent"
                grid
              >
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <MyRequestsEntitlementCard
                      title="Allowance"
                      allocation={selectedYearEntitlement?.data.totalHours}
                      summerSplit={selectedYearEntitlement?.data.totalSummerHours}
                      winterSplit={selectedYearEntitlement?.data.totalWinterHours}
                      seasonalSplit={seasonalSplit}
                      changingYear={changingYear.textLoading}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <MyRequestsEntitlementCard
                      title="Remaining"
                      allocation={selectedYearEntitlement?.data.remainingHours}
                      summerSplit={selectedYearEntitlement?.data.remainingSummerHours}
                      winterSplit={selectedYearEntitlement?.data.remainingWinterHours}
                      seasonalSplit={seasonalSplit}
                      changingYear={changingYear.textLoading}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <MyRequestsEntitlementCard
                      title="Requested"
                      allocation={selectedYearEntitlement?.data.requestedHours}
                      summerSplit={selectedYearEntitlement?.data.requestedSummerHours}
                      winterSplit={selectedYearEntitlement?.data.requestedWinterHours}
                      seasonalSplit={seasonalSplit}
                      changingYear={changingYear.textLoading}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <MyRequestsEntitlementCard
                      title="If Approved"
                      allocation={selectedYearEntitlement?.data.ifApprovedHours}
                      summerSplit={selectedYearEntitlement?.data.ifApprovedHoursSummer}
                      winterSplit={selectedYearEntitlement?.data.ifApprovedHoursWinter}
                      seasonalSplit={seasonalSplit}
                      changingYear={changingYear.textLoading}
                    />
                  </Grid>
                </Grid>
              </FullComponentLoadingIcon>
            </Grid>
          )}
          {!mobile && (
            <>
              {isHtlContractor ? (
                <Grid
                  item
                  xs={isGrid() ? 6 : 4}
                  xl={3}
                  position={isGrid() ? 'unset' : 'sticky'}
                  top="0"
                  height="0"
                  overflow="visible"
                  zIndex={150}
                >
                  {!isGrid() && (
                    <MyRequestsLegendsFilter
                      eventTypes={apiData?.map(m => m.requestType)}
                      loading={!apiData}
                      onChange={setPlottingFilters}
                      predefinedFilters={(location.state as HistoryState)?.calendarFilters}
                      isHtlContractor={isHtlContractor}
                      didFlipTheFilterSwitch={didFlipTheFilterSwitch}
                    />
                  )}
                </Grid>
              ) : (
                <Grid
                  item
                  xs={isGrid() ? 6 : 4}
                  xl={3}
                  position={isGrid() ? 'unset' : 'sticky'}
                  top="0"
                  height="112px"
                  overflow="visible"
                  zIndex={150}
                >
                  {isGrid() && <MyRequestsBanner />}
                  {!isGrid() && (
                    <MyRequestsLegendsFilter
                      eventTypes={apiData?.map(m => m.requestType)}
                      loading={!apiData}
                      onChange={setPlottingFilters}
                      predefinedFilters={(location.state as HistoryState)?.calendarFilters}
                      isHtlContractor={isHtlContractor}
                      didFlipTheFilterSwitch={didFlipTheFilterSwitch}
                    />
                  )}
                </Grid>
              )}
            </>
          )}
          <Grid
            item
            xs={viewType === MyRequestsViewType.CALENDAR ? 8 : 12}
            xl={viewType === MyRequestsViewType.CALENDAR ? 9 : 12}
          >
            {!isGrid() && (
              <FullComponentLoadingIcon loading={!plottingDateRange} bgColor="transparent">
                <PlottingCalendars
                  dateRange={
                    plottingDateRange || { start: formatDateWithTimeZone(new Date()), length: 12 }
                  }
                  requestDates={filteredPlottingDates}
                  showEndDates={false}
                  disabledDates={disabledDates}
                  onBlankDayClick={(date: Date | null) => {
                    dispatch(setDefaultDateRange([date, date]))
                    dispatch(showMyRequestsDrawer())
                  }}
                  changingYear={changingYear.calendarLoading}
                />
              </FullComponentLoadingIcon>
            )}
            {isGrid() && (
              <MyRequestsGrid
                requestsData={requestsData}
                onItemUpdated={() => {
                  getRequestsData()
                }}
                filter={(location.state as HistoryState)?.gridFilters}
              />
            )}
          </Grid>
        </Grid>
      </MobileContainer>
      <Drawer
        isOpen={!isGrid() && showSideDrawer}
        onClose={() => {
          dispatch(setDefaultDateRange(undefined))
          dispatch(hideMyRequestsDrawer())
        }}
        showOptions={false}
        title="New Request"
      >
        <Box sx={{ p: 4, pt: 0 }}>
          <NewRequest
            onClose={() => {
              dispatch(setDefaultDateRange(undefined))
              dispatch(hideMyRequestsDrawer())
            }}
            propEmployeeDefaultShiftLength={employeeDefaultShiftLength}
          />
        </Box>
      </Drawer>
      {mobile && (
        <Drawer
          isOpen={showMySummaryDrawer}
          onClose={() => {
            setShowMySummaryDrawer(false)
          }}
          title={drawerTitle}
          showOptions={false}
          narrow
        >
          <Box sx={{ p: isMobPortrait ? 0 : 4, pt: 0 }}>{drawerType}</Box>
        </Drawer>
      )}
    </PageContext.Provider>
  )
}

export default MyRequests
