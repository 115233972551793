import { isWithinInterval } from 'date-fns'
import { BookingFeature, BookingBookingPayload } from '../../../services/booking/types'

import { isHex } from '../../../utils/app-utils'
import { BookingAvability } from '../consts'
import { BookingAvailabilityItem } from '../types'
import { DeskStatusColor, DeskStatusID } from './enums'
import { formatDateWithTimeZone } from '../../../utils/date-utils'
import { BookingDeskPortPosition } from '../Shared/enums'

export const getLabelCoords = (
  featureDetail: BookingFeature,
  derivedPosition: 'Top' | 'Right' | 'Bottom' | 'Left'
) => {
  if (featureDetail.floorPlanId !== 4) {
    // Any but Manchester / Spring Gardens 10th Floow
    return {
      x: featureDetail.x + (derivedPosition === 'Right' ? 10 : 40),
      y: featureDetail.y + featureDetail.height / 2 + 5,
    }
  }
  let y = featureDetail.y + featureDetail.height / 2 + 5
  let xAdd = 0
  switch (derivedPosition) {
    case 'Top':
    case 'Bottom':
      xAdd = 5
      break
    case 'Right':
      xAdd = 12
      break
    case 'Left':
    default:
      xAdd = 0
      break
  }
  let x = featureDetail.x + featureDetail.width / 2 - xAdd
  if (derivedPosition === 'Top') {
    y += 10
  } else if (derivedPosition === 'Bottom') {
    y -= 10
  }
  if (featureDetail.label.length > 1) {
    x -= 10
  }
  return { x, y }
}

export const deskColour = (
  featureDetail: BookingFeature,
  isOwnBooking: boolean,
  isOwnBookingFor: boolean
) => {
  if (featureDetail.statusId === DeskStatusID.UNAVAILABLE) {
    return DeskStatusColor.NOTACTIVE
  }
  if (isOwnBooking || isOwnBookingFor) {
    return DeskStatusColor.OWN_BOOKING
  }
  if (featureDetail.statusId === DeskStatusID.VACANT) {
    return isHex(featureDetail.fill) ? featureDetail.fill : DeskStatusColor.ACTIVE
  }
}

export const conflictState = (
  requestedRange: [Date, Date],
  existingBooking: BookingBookingPayload,
  currentEmployeeId: number
): BookingAvailabilityItem => {
  let returnColour: BookingAvailabilityItem | undefined
  const requestedFrom = formatDateWithTimeZone(requestedRange[0])
  const requestedTo = formatDateWithTimeZone(requestedRange[1])

  const bookingFrom = formatDateWithTimeZone(
    `${existingBooking.fromDate} ${existingBooking.fromTime}`
  )
  const bookingTo = formatDateWithTimeZone(
    `${
      existingBooking.fromDate > existingBooking.toDate
        ? existingBooking.fromDate
        : existingBooking.toDate
    } ${existingBooking.toTime}`
  )
  // Is current requested start within the booked range
  const requestedStartInRange = isWithinInterval(requestedFrom, {
    start: bookingFrom,
    end: bookingTo,
  })

  // Is current event's end date within the timespan?
  const requestedEndInRange = isWithinInterval(requestedTo, {
    start: bookingFrom,
    end: bookingTo,
  })

  // Does the requested date straddle the existing booking
  const requestStraddlesBooking = isWithinInterval(bookingFrom, {
    start: requestedFrom,
    end: requestedTo,
  })

  if (requestedStartInRange && requestedEndInRange) {
    returnColour =
      currentEmployeeId === existingBooking.employeeId
        ? BookingAvability.OWN_BOOKING
        : BookingAvability.NO_AVAILABILITY
  }
  if (
    (requestedStartInRange || requestedEndInRange || requestStraddlesBooking) &&
    returnColour !== BookingAvability.NO_AVAILABILITY
  ) {
    returnColour =
      currentEmployeeId === existingBooking.employeeId
        ? BookingAvability.OWN_BOOKING
        : BookingAvability.PARTIAL_AVAILABILITY
  }

  return returnColour || BookingAvability.AVAILABLE
}

export const getAvailability = (
  bookingRange: [Date, Date],
  employeeId: number,
  existingBookings?: BookingBookingPayload[]
): BookingAvailabilityItem => {
  if (!existingBookings || Number(existingBookings?.length) === 0) {
    return BookingAvability.AVAILABLE
  }
  if (Number(existingBookings?.length) > 1) {
    return BookingAvability.PARTIAL_AVAILABILITY
  }
  return conflictState(bookingRange, existingBookings?.[0], employeeId)
}

export const calculateAccessPosition = (
  featureDetail: BookingFeature,
  portBorderRadius: number
): {
  x: number
  y: number
  position: 'Top' | 'Right' | 'Bottom' | 'Left'
} => {
  if (portBorderRadius === 0) {
    return { x: 0, y: 0, position: 'Top' }
  }
  switch (Number(featureDetail.ports?.[0].x)) {
    case BookingDeskPortPosition.TOP:
      return { x: featureDetail.x + featureDetail.width / 2, y: featureDetail.y, position: 'Top' }
    case BookingDeskPortPosition.RIGHT:
      return {
        x: featureDetail.x + featureDetail.width,
        y: featureDetail.y + featureDetail.height / 2,
        position: 'Right',
      }
    case BookingDeskPortPosition.BOTTOM:
      return {
        x: featureDetail.x + featureDetail.width / 2,
        y: featureDetail.y + featureDetail.height,
        position: 'Bottom',
      }
    case BookingDeskPortPosition.LEFT:
    default:
      return {
        x: featureDetail.x,
        y: featureDetail.y + featureDetail.height / 2,
        position: 'Left',
      }
  }
}
