import styled from '@emotion/styled'

export const BookingChangeDeskButton = styled('button')`
  & {
    border: none;
    background-color: transparent;
    color: #2c2965;
    display: flex;
    font-family: Poppins;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 32px;
  }
`
