import { Grid, Stack } from '@mui/material'
import { format } from 'date-fns'
import { useState } from 'react'
import { showErrorMessage, showSuccessMessage } from '../../../../redux/reducers/snackbarReducer'
import { useAppDispatch } from '../../../../redux/store'
import { manualRequestsService } from '../../../../services/myActionsService'
import Button from '../../../../shared/UI/Button'
import DateRangePicker from '../../../../shared/UI/DateRangePicker'
import { AbsenceSickNotePutRequest } from '../../../../types/absence-sick-note'
import { BaseResponse } from '../../../../types/base-response'
import UserErrorMessage from '../../../../utils/errorFilter'
import { DateRangeType } from '../../types'

interface Props extends DateRangeType {
  absenceId: number
  onClose: () => void
  onCancel: (e: any) => void
}

function AddSickNote({
  absenceId,
  dateFrom = new Date(),
  dateTo = new Date(),
  onClose,
  onCancel,
}: Props) {
  const [from, setFrom] = useState<Date>(dateFrom)
  const [to, setTo] = useState<Date>(dateTo)

  const dispatch = useAppDispatch()

  const handleSickNote = () => {
    const body: AbsenceSickNotePutRequest = {
      absenceId,
      dateFrom: format(new Date(from), `yyyy-MM-dd`),
      dateTo: format(new Date(to), `yyyy-MM-dd`),
    }

    manualRequestsService
      .addSickNote(body)
      .then(result => {
        dispatch(showSuccessMessage('Sicknote has been successfully updated'))
        onClose()
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  return (
    <Grid container xl={12} rowSpacing={4} pr={4}>
      <Grid item sm={12}>
        <DateRangePicker
          value={[from, to]}
          onChange={dateRange => {
            if (dateRange && dateRange[0]) {
              setFrom(dateRange[0])
            }
            if (dateRange && dateRange[1]) {
              setTo(dateRange[1])
            }
          }}
          arrange="Horizontal"
        />
      </Grid>
      <Grid item sm={12}>
        <Stack direction="row" gap={4} justifyContent="flex-end">
          <Button label="Cancel" color="secondary" variant="outlined" onClick={onCancel} />
          <Button label="Submit" onClick={handleSickNote} />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default AddSickNote
