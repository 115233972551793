import { useCallback } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined'
import { SwitchContainer } from '../../AbsenceDrawerComponent/components'
import { IOSStyleSwitch } from '../../../shared/UI/IOSStyleSwitch/IOSStyleSwitch'

interface NotificationLogsHeader {
  disabled: boolean
  totalRows: number
  failedRows: number
  allLogs: boolean
  onRefreshClick: () => Promise<void>
  onAllLogsChange: () => void
}

const headerStyle = {
  width: '100%',
  height: 75,
  marginBottom: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const buttonsDivStyle = {
  ...headerStyle,
  width: 'auto',
}

function NotificationLogsHeader({
  onRefreshClick,
  onAllLogsChange,
  failedRows,
  totalRows,
  disabled,
  allLogs,
}: NotificationLogsHeader) {
  const formatTotalFailed = useCallback(
    (total: number, failed: number): string => {
      const t = total.toFixed(0)
      const f = failed.toFixed(0)

      if (failed === 0) {
        return `Total: ${t}`
      }

      return allLogs ? `Total: ${t}, Failed: ${f}` : `Failed: ${f}`
    },
    [allLogs]
  )

  return (
    <div style={headerStyle}>
      <Typography variant="h1">{formatTotalFailed(totalRows, failedRows)}</Typography>
      <div style={buttonsDivStyle}>
        <SwitchContainer>
          <Box display="flex" alignItems="center">
            <Typography align="center" variant="body1">
              Show All Logs
            </Typography>
          </Box>
          <div style={{ width: 10 }} />
          <IOSStyleSwitch onChange={onAllLogsChange} />
        </SwitchContainer>
        <IconButton disabled={disabled ?? false} onClick={onRefreshClick}>
          <LoopOutlinedIcon fontSize="large" color="action" />
        </IconButton>
      </div>
    </div>
  )
}

export default NotificationLogsHeader
