import { BirthdayRequest } from '../types/birthday-request'
import { BirthdayType } from '../types/birthday-type'
import { BuyHoursType } from '../types/buy-hours-type'
import { BuySellHoursType } from '../types/buy-sell-hours-type'
import { BuySellPostResponseType } from '../types/buy-sell-post-response'
import { MyBirthdaysResponse } from '../types/my-birthdays-response'
import { SellHoursType } from '../types/sell-hours-type'
import { ServiceHoursType } from '../types/service-hours-type'
import { httpServiceV1 } from './httpServiceV1'

const requestsBaseURL = 'MyExtras'

const myExtrasService = {
  getServiceHours: async (): Promise<ServiceHoursType> =>
    httpServiceV1.get(`${requestsBaseURL}/ServiceHours`),

  getBuyHolidayHours: async (year: number): Promise<BuyHoursType> =>
    httpServiceV1.get(`${requestsBaseURL}/Buy?Year=${year}`),

  postBuyHolidayHours: async (body: BuySellHoursType): Promise<BuySellPostResponseType> =>
    httpServiceV1.post(`${requestsBaseURL}/Buy`, body),

  getSellHolidayHours: async (year: number): Promise<SellHoursType> =>
    httpServiceV1.get(`${requestsBaseURL}/Sell?Year=${year}`),

  postSellHolidayHours: async (body: BuySellHoursType): Promise<BuySellPostResponseType> =>
    httpServiceV1.post(`${requestsBaseURL}/Sell`, body),

  getBirthday: async (year: number): Promise<BirthdayType> =>
    httpServiceV1.get(`${requestsBaseURL}/Birthday?Year=${year}`),

  postBirthday: async (body: BirthdayRequest): Promise<BuySellPostResponseType> =>
    httpServiceV1.post(`${requestsBaseURL}/Birthday`, body),

  getAllBirthdays: async (): Promise<MyBirthdaysResponse> =>
    httpServiceV1.get(`${requestsBaseURL}/GetAllBirthdays`),
}

export { myExtrasService }
