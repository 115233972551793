import { format } from 'date-fns'
import { tabType } from '../Absence/types'
import { formatDateWithTimeZoneStr } from '../../utils/date-utils'
import {
  ManualRequestType1Request,
  ManualRequestType2Request,
  ManualRequestType3Request,
} from '../../types/manual-request-types'
import { BuilderArgs, convertTabTypeForRequest } from '../../utils/RequestBuilder/requestBuilder'

export const buildRequestType1 = (args: BuilderArgs): ManualRequestType1Request => ({
  employeeId: args.employeeId,
  type: tabType(args.requestType),
  comments: args.comments,
  entitlementYear: args.financialYear,
  isIncrease: args.increase,
  hours: args.hours,
  overrideConflicts: args.overrideConflicts,
})

export const buildRequestType2 = (args: BuilderArgs): ManualRequestType2Request => ({
  employeeId: args.employeeId,
  comments: args.comments,
  type: convertTabTypeForRequest(tabType(args.requestType)),
  dateFrom: formatDateWithTimeZoneStr(args.dateRange[0]!),
  dateTo: args.dateRange[1] && formatDateWithTimeZoneStr(args.dateRange[1]),
  days:
    args.selectedDays &&
    args.selectedDays?.map(day => ({
      day: format(day.day, 'yyyy-MM-dd'),
      hours: day.hours,
      checked: day.checked,
    })),
  overrideConflicts:
    args.conflicts && args.alerts?.filter(alert => alert.conflictType === 'error').length === 0,
})

export const buildRequestType3 = (args: BuilderArgs): ManualRequestType3Request => ({
  employeeId: args.employeeId,
  comments: args.comments,
  type: convertTabTypeForRequest(tabType(args.requestType)),
  request: '',
  dateFrom: formatDateWithTimeZoneStr(args.dateRange[0]!),
  dateTo: args.dateRange[1] && formatDateWithTimeZoneStr(args.dateRange[1]),
  overrideConflicts:
    args.conflicts && args.alerts?.filter(alert => alert.conflictType === 'error').length === 0,
})
