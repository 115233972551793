import {
  addDays,
  differenceInCalendarDays,
  eachDayOfInterval,
  isWithinInterval,
} from 'date-fns'
import {WallChartItem, WallChartItems} from '../../types/wallchart'
import { ThresholdApiResponse, ThresholdDates, ThresholdLevels } from './types'
import { DepartmentThresholdsResponse } from '../../types/department-thresholds-response'
import { absoluteDate } from '../../utils/date-utils'
import { DefaultThresholds } from '../../types/default-thresholds'
import { WallChartMultiDayBar } from './WallChartRow/types'

export const compareToThreshold = (
  threshold: DefaultThresholds,
  absnetPcnt: number
): ThresholdLevels => {
  if (
    absnetPcnt >= Number(threshold.warningPercentageLevel1) &&
    absnetPcnt < Number(threshold.warningPercentageLevel2)
  ) {
    return ThresholdLevels.LOW
  }
  if (
    absnetPcnt >= Number(threshold.warningPercentageLevel2) &&
    absnetPcnt < Number(threshold.warningPercentageLevel3)
  ) {
    return ThresholdLevels.MEDIUM
  }
  if (absnetPcnt >= Number(threshold.warningPercentageLevel3)) {
    return ThresholdLevels.HIGH
  }
  return ThresholdLevels.NONE
}

export const buildThresholdsDayList = (
  thresholdApiData: ThresholdApiResponse[] | undefined,
  startDate: Date,
  endDate: Date,
  selectedTeamId: number,
  dtr: DepartmentThresholdsResponse
): ThresholdDates[] => {
  if (!thresholdApiData) {
    return []
  }
  const teamThreshold = dtr.defaultThresholds.find(th => th.departmentTeamId === selectedTeamId)
  const days = eachDayOfInterval({
    start: startDate,
    end: endDate,
  })
  const thd: ThresholdDates[] = []
  days.forEach(day => {
    const match = thresholdApiData.find(itd => absoluteDate(itd.date) === absoluteDate(day))
    const thresholdLevel = compareToThreshold(teamThreshold!, match?.percentage || 0)
    thd.push({
      date: day,
      threshold: thresholdLevel,
    })
  })
  return thd
}

const getEventsOnDay = (
  events: WallChartItem[],
  date: Date
) => {
  const itemsForDate = events
    .filter(event =>
      isWithinInterval(date, {
        start: event.dateFrom,
        end: event.dateTo,
      }))
  
  return itemsForDate.length > 1 ? {
      date,
      requestIds: itemsForDate.map(x => x.id),
      recordTypes: itemsForDate.map(x => x.recordTypeStr)
    } : null
}

/**
 * This function decides what overlaying dates there are and returns them as an array of date ranges with ids
 * This is a temporary measure until the WallChart is reviewed to allow smaller segments such as half-days etc
 * @param timespanStartDate - This is the start date of the current WC view
 * @param timespanEndDate - This is the end date of the current WC view
 * @param eventItem - This is the start date of the current WC view
 * @returns - { from: Date; to?: Date; ids: number[]; primaryId: number }[]
 */
export const buildMultiEventList = (
  timespanStartDate: Date,
  timespanEndDate: Date,
  eventItem: WallChartItems
): WallChartMultiDayBar[] => {
  const dayCount = differenceInCalendarDays(timespanEndDate, timespanStartDate) + 1
  const daysWithOverlaps = Array(dayCount)
    .fill(0)
    .map((_, idx) => getEventsOnDay(eventItem.items, addDays(timespanStartDate, idx)))
    .filter(x => x !== null)
    .map(x => ({
      from: x!.date,
      to: x!.date,
      ids: x!.requestIds,
      dayCount: x!.requestIds.length,
      primaryId: x!.requestIds[0],
      recordTypeStr: x!.recordTypes[0],
    }))
  
  return daysWithOverlaps
}
