import { EventTypes } from './types'

export const statusTranslation = (statusText: string) => {
  switch (statusText.toLowerCase()) {
    case 'changerequested2':
      return 'Change Requested'
    default:
      return statusText
  }
}

export const typeTranslation = (recordTypeStr: string | undefined) => {
  if (!recordTypeStr) {
    return EventTypes.HIDDEN
  }
  switch (recordTypeStr?.toLowerCase()) {
    case 'absence':
      return EventTypes.ABSENCE
    case 'holiday':
    case 'holiday (m)':
      return EventTypes.HOLIDAY
    case 'late':
      return EventTypes.LATE
    case 'course':
      return EventTypes.COURSE
    case 'lieu day':
    case 'lieu':
    case 'lieu (m)':
      return EventTypes.LIEU
    case 'work from home':
      return EventTypes.WORKFROMHOME
    case 'birthday':
      return EventTypes.BIRTHDAY
    case 'other':
      return EventTypes.OTHER
    default:
      return recordTypeStr
  }
}
