import { Grid, Stack, styled, TextField, useMediaQuery } from '@mui/material'
import { addDays, format } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RequestType } from '../../models'
import {
  hideModal,
  SetShowModalPayload,
  showModalDialog,
} from '../../redux/reducers/appSettingsReducer'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { RootStore, useAppDispatch } from '../../redux/store'
import { dashboardService } from '../../services/dashboardService'
import { employeeService } from '../../services/employeeService'
import { requestsService } from '../../services/requestsService'
import Card from '../../shared/layout/Card'
import Alert from '../../shared/UI/Alert/Alert'
import Button from '../../shared/UI/Button'
import CardTitle from '../../shared/UI/CardTitle'
import { statusTranslation } from '../../shared/UI/EventPopup/utils'
import InstanceStatusBanner from '../../shared/UI/InstanceStatusBanner'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import Modal from '../../shared/UI/Modal'
import MyActionsPageHeader from '../../shared/UI/MyActionsPageHeader'
import MySummaryEntitlementSplit from '../../shared/UI/MySummaryEntitlementSplit/MySummaryEntitlementSplit'
import NoDataFound from '../../shared/UI/NoDataFound'
import Paragraph from '../../shared/UI/Paragraph'
import TimePicker from '../../shared/UI/TimePicker'
import WfhEntitlementSplit from '../../shared/UI/WfhEntitlementSplit/WfhEntitlementSplit'
import { isMobileDown } from '../../theme/deviceChecks'
import { BaseResponse } from '../../types/base-response'
import { EmployeeDetailsResponse } from '../../types/employee'
import { EntitlementSplit } from '../../types/entitlement-split'
import {
  RequestDayRequest,
  RequestDetailResponse,
  RequestUpdateRequest,
} from '../../types/requests'
import { formatDateWithTimeZoneStr, formatTime, getLocalDateString } from '../../utils/date-utils'
import UserErrorMessage from '../../utils/errorFilter'
import { CheckboxTextfield } from '../Stacks'
import WallChart from '../WallChart'
import { WallChartSegments, WallChartTimespans } from '../WallChart/WallChartTimespanSelector/types'
import { OccurrenceRequestProps, RequestAction, RequestStatus } from './types'
import {
  useErrorHandler,
  useNonExistentRequestHandler,
  useRequestChecker,
  useRequestDetailFetcher,
} from './utils'

export const StyledGrid = styled(Grid)`
  &::-webkit-scrollbar {
    width: 17px;
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    background-clip: content-box;
  }
`
export interface FieldTouch {
  reviewerComments: boolean
  rows: boolean
}

function Occurrence({ occurrenceType }: OccurrenceRequestProps) {
  const { id: requestId } = useParams<{ id: string }>()
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [requestDetail, setRequestDetail] = useState<RequestDetailResponse | null>(null)
  const [initialRequestDetail, setInitialRequestDetail] = useState<RequestDetailResponse | null>(
    null
  )
  const [employeeId, setEmployeeId] = useState<number>()
  const [birthDay, setBirthDay] = useState<number>()
  const [birthMonth, setBirthMonth] = useState<number>()
  const [isBirthday, setIsBirthday] = useState<boolean>(false)
  const [hasChanged, setHasChanged] = useState<boolean>(false)
  const [hasBothDates, setHasBothDates] = useState<boolean>(true)
  const [entitlementSplit, setEntitlementSplit] = useState<EntitlementSplit>()
  const [wfhEntitlementSplit, setWfhEntitlementSplit] = useState<EntitlementSplit>()
  const [isWfhOccurrence, setIsWfhOccurrence] = useState<boolean>(false)
  const [fieldsTouched, setFieldsTouched] = useState<FieldTouch>({
    reviewerComments: false,
    rows: false,
  })
  const [rowRequestDayId, setRowRequestDayId] = useState<number[]>([])
  const [formattedShiftStartTime, setFormattedShiftStartTime] = useState<Date>()
  const [declineValidationErrors, setDeclineValidationErrors] = useState<any>({})
  const [requestedHoursAttributes, setRequestedHoursAttributes] = useState<{ title?: string }>({})
  const [requestedHoursNumberAttributes, setRequestedHoursNumberAttributes] = useState<{
    title: string | number | undefined | any
  }>({ title: undefined })
  const [isDeclinedOrCancelled, setIsDeclinedOrCancelled] = useState<boolean>(true)
  const [previousYear, setPreviousYear] = useState<boolean>(false)
  const [requestIsLocked, setRequestIsLocked] = useState<boolean>(true)
  const [approveLoading, setApproveLoading] = useState<boolean>(false)
  const [declineLoading, setDeclineLoading] = useState<boolean>(false)
  const [negativeHoursApproval, setNegativeHoursApproval] = useState<boolean>(false)
  const [boxChecked, setBoxChecked] = useState<RequestDayRequest[]>([])

  const bankHolidays = useSelector<RootStore, any[] | null>(
    (state: RootStore) => state.appSettings.bankHolidays
  )
  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)
  const userInfo = useSelector((state: RootStore) => state.userState.loggedInUser)

  const handleError = useErrorHandler()
  const checkIfRequestExists = useRequestChecker()
  const handleNonExistentRequest = useNonExistentRequestHandler()
  const fetchRequestDetail = useRequestDetailFetcher()

  const calculateHours = useCallback(() => {
    let total = 0
    requestDetail?.requestDays.forEach(day => {
      total += day.checked ? Number(day.hours) : 0
    })
    return total
  }, [requestDetail?.requestDays])

  useEffect(() => {
    if (!employeeId) {
      return
    }

    const isBirthDay = occurrenceType === 'Birthday'
    if (isBirthDay) {
      employeeService.getEmployeeDepartment(employeeId).then(res => {
        setBirthDay(res.employee.birthDay)
        setBirthMonth(res.employee.birthMonth)
      })
    }

    setIsBirthday(isBirthDay)
  }, [employeeId, occurrenceType])

  useEffect(() => {
    if (!requestDetail) {
      return
    }

    setBoxChecked(
      requestDetail.requestDays.filter(
        day => day.checked && (day.hours === null || day.hours === '0' || day.hours === '')
      )
    )
    if (requestDetail.dateFrom && requestDetail.shiftStartTime) {
      const dateTimeString = `${format(
        new Date(requestDetail.dateFrom),
        'yyyy-MM-dd hh-mm-ss'
      ).slice(0, 11)}${requestDetail.shiftStartTime!}`
      setFormattedShiftStartTime(new Date(dateTimeString))
    }
    setRequestedHoursAttributes(() => {
      if (!requestDetail.shiftStartTime && !requestDetail.totalRequestedHours) {
        return {
          title: 'Request',
        }
      }
      return {
        title: requestDetail.requestTypeId === 3 ? 'Shift Start Time' : 'Requested Hours',
      }
    })
    setRequestedHoursNumberAttributes(() => {
      if (!requestDetail.shiftStartTime && !requestDetail.totalRequestedHours) {
        return {
          title: undefined,
        }
      }
      return {
        title: calculateHours().toFixed(2),
      }
    })
    setIsDeclinedOrCancelled(
      requestDetail.requestStatusId === 5 || requestDetail.requestStatusId === 7
    )
  }, [requestDetail, employeeId, calculateHours])

  const minDate = useMemo(() => new Date('0001/01/01'), [])
  const mobile = useMediaQuery(isMobileDown)

  const activeRoute = useSelector<RootStore, string>(
    (state: RootStore) => state.appSettings.activeRoute
  )

  const isFormValid = (isDecline: boolean) => {
    setDeclineValidationErrors({})
    const declineErrors: any = {}

    if (hasChanged && requestDetail?.reviewerComments === '') {
      return false
    }

    if (isDecline && requestDetail?.reviewerComments === '') {
      declineErrors.reviewerComments = true
    }

    if (Object.keys(declineErrors).length !== 0) {
      setDeclineValidationErrors(declineErrors)
      return false
    }

    if (boxChecked && boxChecked.length > 0) {
      boxChecked.map(requestDay =>
        setRowRequestDayId([...rowRequestDayId, requestDay.requestDayId])
      )

      return false
    }

    return true
  }

  const checkForDates = useCallback(
    (requestDateTo: Date) => {
      setHasBothDates(new Date(requestDateTo) > minDate)
    },
    [minDate]
  )

  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  const navigate = useNavigate()

  const displayClashModal = () => {
    dispatch(
      showModalDialog({
        title: 'Are you sure?',
        message: `Are you sure you would like to decline this request?`,
        buttonLabel: 'Decline',
        type: 'question',
        showModal: true,
      })
    )
  }

  const requestDetailHasChanged = useCallback((): boolean => {
    if (requestDetail && initialRequestDetail) {
      return Object.keys(requestDetail).some(key => {
        if (key !== 'reviewerComments' || requestDetail.requestStatusId === RequestStatus.AMENDED) {
          return (
            requestDetail[key as keyof RequestDetailResponse] !==
            initialRequestDetail[key as keyof RequestDetailResponse]
          )
        }
        return false
      })
    }
    return false
  }, [initialRequestDetail, requestDetail])

  useEffect(() => {
    setHasChanged(requestDetailHasChanged())
  }, [requestDetailHasChanged])

  const transformBody = (requestAction: number): RequestUpdateRequest | undefined => {
    if (!requestDetail) {
      return
    }

    const requestDays =
      !requestDetail.isCancellation &&
      requestDetail.requestStatusId === 2 &&
      requestAction === RequestAction.APPROVE &&
      requestDetail.requestDaysCr
        ? requestDetail.requestDaysCr
        : requestDetail.requestDays

    return {
      requestId: requestDetail.requestId,
      requestStatusId: requestDetail.requestStatusId,
      requestTypeId: requestDetail.requestTypeId,
      manualTypeId: requestDetail.manualTypeId,
      employeeId: requestDetail.employeeId,
      applyChangesToRota: false,
      departmentId: requestDetail.departmentId,
      reviewerComments: requestDetail.reviewerComments,
      shiftStartTimeStr: requestDetail.shiftStartTime,
      days: requestDays.map(day => ({
        requestDayId: day.requestDayId,
        requestDayDate: day.day,
        hours: Number(day.hours) < 0 ? 0 : Number(day.hours),
        checked: day.checked,
      })),
      dateFrom: format(new Date(requestDetail.dateFrom.toString()), 'yyyy-MM-dd'),
      dateTo: format(new Date(requestDetail.dateTo.toString()), 'yyyy-MM-dd'),
      requestActionId: requestAction,
      isCancellation: requestDetail.isCancellation,
      hasBeenRejected: true,
      conflictOverride: true,
    }
  }

  const handleRequestData = (data: RequestDetailResponse) => {
    setRequestDetail(data)
    setEmployeeId(data.employeeId)
    setInitialRequestDetail(data)
    checkForDates(data.dateTo)
    setEntitlementSplit({
      general: {
        hours: data?.summary.totalHours,
        summerHours: data?.summary.totalSummerHours,
        winterHours: data?.summary.totalWinterHours,
      },
      remaining: {
        hours: data?.summary.remainingHours,
        summerHours: data?.summary.remainingSummerHours,
        winterHours: data?.summary.remainingWinterHours,
      },
      requested: {
        hours: data?.summary.requestedHours,
        summerHours: data?.summary.requestedSummerHours,
        winterHours: data?.summary.requestedWinterHours,
      },
      changeRequests: {
        hours: data?.summary.changeRequestHours,
        summerHours: data?.summary.changeRequestHoursSummer,
        winterHours: data?.summary.changeRequestHoursWinter,
      },
      ifApproved: {
        hours: data?.summary.ifApprovedHours,
        summerHours: data?.summary.ifApprovedHoursSummer,
        winterHours: data?.summary.ifApprovedHoursWinter,
      },
    })
    setWfhEntitlementSplit({
      general: {
        hours: data?.summary.wfhAllowance,
      },
      remaining: {
        hours: data?.summary.remainingWfhHours,
      },
      requested: {
        hours: data?.summary.requestedWfhHours,
      },
      changeRequests: {
        hours: data?.summary.changeRequestedWfhHours,
      },
      ifApproved: {
        hours: data?.summary.ifApprovedWfhHours,
      },
    })
    setIsWfhOccurrence(window.location.href.includes('workfromhomerequest'))
    setPreviousYear(
      currentEntitlementPeriodResponse?.entitlementPeriod.toLowerCase() === 'financialyear'
        ? new Date(data.dateFrom).getFullYear() < currentEntitlementPeriodResponse.year
        : new Date(data.dateFrom).getFullYear() < new Date().getFullYear()
    )
    setRequestIsLocked(
      data?.lockStatus.isLocked &&
        data?.lockStatus.lockedByUser?.trim().toLowerCase() !== userInfo?.name?.trim().toLowerCase()
    )
  }

  const fetchHolidayRequestDetail = useCallback(async () => {
    setIsLoading(true)
    try {
      const exists = await checkIfRequestExists()
      if (!exists) {
        handleNonExistentRequest()
        return
      }
      const data = await fetchRequestDetail()
      handleRequestData(data)
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [checkForDates, dispatch, requestId, navigate])

  const declineRequest = async () => {
    if (isFormValid(true)) {
      setIsLoading(true)

      const body = transformBody(RequestAction.DECLINE)

      if (!body) {
        return
      }

      if (body.requestStatusId === RequestStatus.APPROVED_CR) {
        body.requestStatusId = RequestStatus.APPROVED
      } else {
        body.requestStatusId = RequestStatus.DECLINED
      }
      setDeclineLoading(true)

      await requestsService
        .updateHolidayRequest(body)
        .then(data => {
          setDeclineLoading(false)
          if (data.status === 200 && data.errors.length > 0) {
            data.errors.forEach(error => {
              dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
            })
          } else {
            setIsLoading(false)
            dispatch(hideModal())
            dispatch(showSuccessMessage(`${occurrenceType} request declined`))
            navigate(activeRoute || '/myactions')
          }
        })
        .catch(err => {
          setDeclineLoading(false)
          const response: BaseResponse = err.response.data
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
          setIsLoading(false)
        })
    } else {
      setFieldsTouched({ ...fieldsTouched, reviewerComments: true })
    }
  }

  const canSetAmended = () => {
    switch (requestDetail?.requestTypeId) {
      case RequestType.HOLIDAY:
      case RequestType.HOLIDAY_M:
      case RequestType.WORK_FROM_HOME:
      case RequestType.LIEU_DAY:
      case RequestType.LIEU_DAY_M:
      case RequestType.SHIFT:
        return true
      default:
        return false
    }
  }

  const isChangeRequested = () => {
    switch (requestDetail?.requestTypeId) {
      case RequestType.HOLIDAY:
      case RequestType.HOLIDAY_M:
      case RequestType.WORK_FROM_HOME:
        if (requestDetail.requestStatusId === 2 && !requestDetail.isCancellation) {
          return true
        }
        return false
      default:
        return false
    }
  }

  const getRequestDayCrHours = (requestDayId: number) => {
    if (requestDetail?.requestDaysCr) {
      const requestDayCr: RequestDayRequest | undefined = requestDetail!.requestDaysCr.find(
        (x: RequestDayRequest) => x.requestDayId === requestDayId
      )
      return requestDayCr?.hours || 0
    }
  }

  const calculateRequestDayCrHours = () => {
    if (!requestDetail?.requestDaysCr) {
      return 0
    }
    let total = 0
    requestDetail?.requestDaysCr.forEach(day => {
      total += day.checked ? Number(day.hours) : 0
    })
    return total
  }

  const requestTypeHasMultiDays = useMemo(() => {
    if (requestDetail) {
      if (requestDetail.requestStatusId === 4) {
        // Manual request
        return requestDetail.manualTypeId !== 1
      }

      switch (requestDetail.requestTypeId) {
        case 1:
        case 5:
        case 6:
          return true
        default:
          return false
      }
    }
  }, [requestDetail])

  const approveRequest = async () => {
    if (isFormValid(false)) {
      setIsLoading(true)
      const body = transformBody(RequestAction.APPROVE)
      if (!body) {
        return
      }

      if (!requestTypeHasMultiDays || body.days.some(day => day.checked)) {
        if (body.isCancellation) {
          body.requestStatusId = RequestStatus.CR_CANCELLED
        } else if (hasChanged && canSetAmended()) {
          body.requestStatusId = RequestStatus.AMENDED
        } else {
          body.requestStatusId = RequestStatus.APPROVED
        }
        setApproveLoading(true)
        body.hasBeenRejected = false
        await requestsService
          .updateHolidayRequest(body)
          .then(response => {
            setApproveLoading(false)
            if (response.status === 200 && response.errors.length > 0) {
              response.errors.forEach(error => {
                dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
              })
            } else if (occurrenceType === 'Birthday' && body.isCancellation) {
              setIsLoading(false)
              dispatch(showSuccessMessage(`${occurrenceType} cancellation approved`))
              navigate(activeRoute || '/myactions')
            } else {
              setIsLoading(false)
              dispatch(showSuccessMessage(`${occurrenceType} request approved`))
              fetchHolidayRequestDetail()
            }
          })
          .catch(err => {
            setApproveLoading(false)
            const response: BaseResponse = err.response.data
            response.errors.forEach(error => {
              dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
            })
            setIsLoading(false)
          })
      } else {
        dispatch(
          showModalDialog({
            title: 'Cancel request?',
            message:
              'All days for this request have been unselected, which will cancel the request. Are you sure you want to proceed?',
            buttonLabel: 'Confirm',
            type: 'question',
            showModal: true,
          })
        )
        setIsLoading(false)
      }
    } else {
      setFieldsTouched({ ...fieldsTouched, reviewerComments: true })
    }
  }

  useEffect(() => {
    fetchHolidayRequestDetail()
  }, [fetchHolidayRequestDetail])

  const formattedDateFrom = requestDetail && getLocalDateString(new Date(requestDetail.dateFrom))
  const formattedDateTo = requestDetail && getLocalDateString(new Date(requestDetail.dateTo))
  const formattedActionDate =
    requestDetail?.actionedBy && getLocalDateString(new Date(requestDetail!.actionedDateTime))

  const submitDisabled = () => {
    const DISABLED = true
    if (
      requestDetail &&
      hasChanged &&
      (requestDetail.requestStatusId === RequestStatus.APPROVED ||
        requestDetail.requestStatusId === RequestStatus.AMENDED)
    ) {
      return !DISABLED
    }
    if (
      !requestDetail ||
      (fieldsTouched.reviewerComments &&
        requestDetail.reviewerComments === '' &&
        fieldsTouched.rows)
    ) {
      return DISABLED
    }
    if (
      requestDetail.requestStatusId === RequestStatus.AMENDED_CR &&
      requestDetail.isCancellation
    ) {
      return !DISABLED
    }
    return !(
      requestDetail.requestStatusId === RequestStatus.PENDING ||
      requestDetail.requestStatusId === RequestStatus.APPROVED_CR ||
      requestDetail.requestStatusId === RequestStatus.DECLINED
    )
  }
  const declineDisabled = () => {
    if (!requestDetail) {
      return true
    }
    if (
      requestDetail.requestStatusId === RequestStatus.AMENDED_CR &&
      requestDetail.isCancellation
    ) {
      return false
    }
    return !(
      requestDetail.requestStatusId === RequestStatus.PENDING ||
      requestDetail.requestStatusId === RequestStatus.AMENDED ||
      requestDetail.requestStatusId === RequestStatus.APPROVED_CR ||
      requestDetail.requestStatusId === RequestStatus.APPROVED
    )
  }

  const bankHolidaysToDisplay = () => {
    const bankHolidaysDates = bankHolidays?.map(x => getLocalDateString(new Date(x.holidayDate)))
    const holidaysDates = requestDetail!.requestDays?.map(x => getLocalDateString(new Date(x.day)))
    const datesToDisplay = bankHolidaysDates?.filter(x => holidaysDates?.includes(x))
    return datesToDisplay?.join(' & ')
  }

  const hasBankHolidaysToDisplay = () => {
    switch (requestDetail?.requestTypeId) {
      case RequestType.HOLIDAY:
      case RequestType.HOLIDAY_M:
      case RequestType.WORK_FROM_HOME:
      case RequestType.LIEU_DAY:
      case RequestType.LIEU_DAY_M:
      case RequestType.SHIFT: {
        const bankHolidaysDates = bankHolidays?.map(x =>
          getLocalDateString(new Date(x.holidayDate))
        )
        const holidaysDates = requestDetail!.requestDays?.map(x =>
          getLocalDateString(new Date(x.day))
        )
        const datesToDisplay = bankHolidaysDates?.filter(x => holidaysDates?.includes(x))
        return datesToDisplay?.length !== 0
      }
      default:
        return false
    }
  }

  const calculateStatusLabel = (prop: RequestDetailResponse) => {
    if (!prop?.status || !prop.statusBanner.requestStatus) return

    if (prop?.isQueried) {
      return 'Queried'
    }
    return statusTranslation(prop.statusBanner.requestStatus)
  }

  const isNegativeHoursApproval = () => {
    if (!entitlementSplit || !requestDetail) {
      return false
    }

    const requestStatusIdList: number[] = [RequestStatus.PENDING, RequestStatus.APPROVED_CR]

    const remainingHours = entitlementSplit?.remaining.hours ? entitlementSplit?.remaining.hours : 0
    let hours = remainingHours - requestDetail!.totalRequestedHours

    if (requestDetail.requestStatusId === RequestStatus.APPROVED_CR) {
      hours = remainingHours - (calculateRequestDayCrHours() - requestDetail!.totalRequestedHours)

      if (requestDetail.isCancellation) {
        hours = remainingHours + calculateHours()
      }
    }

    if (
      occurrenceType === 'Holiday' &&
      requestStatusIdList.includes(requestDetail.requestStatusId) &&
      hours < 0
    ) {
      return true
    }

    return false
  }

  useEffect(() => {
    setNegativeHoursApproval(isNegativeHoursApproval())
  }, [entitlementSplit, requestDetail])

  const isValidDay = () => isDeclinedOrCancelled || requestIsLocked

  return (
    <>
      <MyActionsPageHeader title={`${occurrenceType} Request`} hideNewRequest />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {requestDetail && (
            <>
              <InstanceStatusBanner
                showBackButton
                firstname={requestDetail.statusBanner.forename}
                surname={requestDetail.statusBanner.surname}
                status={
                  requestDetail.isCancellation
                    ? 'Cancellation Requested'
                    : calculateStatusLabel(requestDetail)
                }
                requestId={requestDetail.requestId}
              />
              {previousYear && (
                <Alert severity="warning" message="This request is within a previous year" />
              )}
            </>
          )}
        </Grid>
        {requestDetail && (
          <>
            {requestDetail.lockStatus.isLocked && (
              <Grid item xs={12} sx={{ '&.MuiGrid-item': { paddingTop: '16px' } }}>
                <Alert
                  severity="info"
                  message={`The request is currently locked by ${requestDetail.lockStatus.lockedByUser}`}
                />
              </Grid>
            )}
            {/* Column 1 */}
            <Grid item xs={12} lg={8}>
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  xl={6}
                  style={{
                    display: 'flex',
                    flexFlow: 'column',
                    flex: '1 1 auto',
                    paddingTop: mobile ? '16px' : '32px',
                  }}
                >
                  <Card
                    {...requestedHoursAttributes}
                    icon={
                      requestDetail.requestTypeId === 3 ? (
                        <TimePicker
                          value={formattedShiftStartTime!}
                          onChange={e => {
                            if (e) {
                              setRequestDetail(current => {
                                const { shiftStartTime, ...rest } = current as RequestDetailResponse
                                return { shiftStartTime: formatTime(e), ...rest }
                              })
                            }
                          }}
                          disabled={isDeclinedOrCancelled}
                        />
                      ) : (
                        <CardTitle {...requestedHoursNumberAttributes} />
                      )
                    }
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between">
                          <Paragraph weight="bold">Date from</Paragraph>
                          <Paragraph>{formattedDateFrom}</Paragraph>
                          {hasBothDates && (
                            <>
                              <Paragraph weight="bold">Date to</Paragraph>
                              <Paragraph>{formattedDateTo}</Paragraph>
                            </>
                          )}
                          {isBirthday ? (
                            <>
                              <Paragraph weight="bold">Birth Date</Paragraph>
                              <Paragraph>{`${birthDay}/${birthMonth}`}</Paragraph>
                            </>
                          ) : null}
                        </Stack>
                      </Grid>
                      {hasBankHolidaysToDisplay() && (
                        <Grid item xs={12}>
                          <Alert
                            severity="info"
                            message={`There are bank holidays on: ${bankHolidaysToDisplay()}`}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TextField
                          label="Requester comments"
                          fullWidth
                          multiline
                          rows={4}
                          disabled
                          defaultValue={requestDetail.requesterComments}
                          value={requestDetail.requesterComments}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  xl={6}
                  style={{ display: 'flex', flexFlow: 'column', flex: '1 1 auto' }}
                >
                  <Card title="Summary">
                    {entitlementSplit && wfhEntitlementSplit ? (
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          {isWfhOccurrence ? (
                            <WfhEntitlementSplit
                              marginTop={0}
                              marginBottom={0}
                              titleMarginBottom={1}
                              summaryItems={wfhEntitlementSplit}
                            />
                          ) : (
                            <MySummaryEntitlementSplit
                              marginTop={0}
                              marginBottom={0}
                              titleMarginBottom={1}
                              summaryItems={entitlementSplit}
                            />
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <NoDataFound show={!isLoading} />
                        <LoadingIndicator show={isLoading} />
                      </>
                    )}
                  </Card>
                </Grid>
                {!mobile && (
                  <Grid item xs={12}>
                    <WallChart
                      title="Who's Away"
                      isWallChartView={false}
                      filterPropertiesOverride={{
                        dateFrom: formatDateWithTimeZoneStr(requestDetail.dateFrom),
                        dateTo: formatDateWithTimeZoneStr(
                          addDays(new Date(requestDetail.dateFrom), 13)
                        ),
                        directReportees: true,
                        departmentTeamIds: [],
                        employees: [],
                        includeThresholds: true,
                        thresholdsForEmployee: requestDetail.employeeId,
                      }}
                      timeSpanPropertiesOverride={{
                        startDate: new Date(requestDetail.dateFrom),
                        endDate: addDays(new Date(requestDetail.dateFrom), 13),
                        directReport: true,
                        segments: {
                          masterSegments: 14,
                          divisions: WallChartSegments.SEGMENT_DIV_TWOWEEK,
                          timespan: WallChartTimespans.TWOWEEK,
                        },
                        title: '',
                        rangeType: 'TwoWeek',
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/* Column 1 END */}
            {/* Column 2 (Requested Days) */}
            <Grid item xs={12} lg={4}>
              <Card title="Approval">
                {requestDetail ? (
                  <Grid container spacing={4}>
                    {occurrenceType !== 'Day Off' && occurrenceType !== 'Shift' ? (
                      <>
                        {negativeHoursApproval && (
                          <>
                            <Grid
                              item
                              xs={12}
                              sx={{ '&.MuiGrid-item': { paddingTop: '13px', marginBottom: '1px' } }}
                            >
                              <Alert
                                severity="info"
                                message="Action will result in a negative entitlement for this user"
                              />
                            </Grid>
                          </>
                        )}
                        {Boolean(requestDetail.requestDays?.length) && (
                          <StyledGrid
                            item
                            xs={12}
                            sx={{
                              overflowY: 'auto',
                              maxHeight: '250px',
                              marginTop: '20px',
                            }}
                          >
                            {isChangeRequested() ? (
                              <Grid container gap={3}>
                                {requestDetail.requestDays?.map(day => (
                                  <>
                                    <Grid item xs={12}>
                                      <Stack direction="row" gap={2}>
                                        <TextField
                                          type="number"
                                          label={`Approved ${format(
                                            new Date(day.day),
                                            'ccc dd/MM/yyyy'
                                          )}`}
                                          defaultValue={day.hours}
                                          value={day.hours}
                                          disabled={isChangeRequested()}
                                          onWheel={event =>
                                            event.target instanceof HTMLElement &&
                                            event.target.blur()
                                          }
                                        />
                                        <TextField
                                          type="number"
                                          label={`Requested ${format(
                                            new Date(day.day),
                                            'ccc dd/MM/yyyy'
                                          )}`}
                                          defaultValue={getRequestDayCrHours(day.requestDayId)}
                                          value={getRequestDayCrHours(day.requestDayId)}
                                          disabled={isChangeRequested()}
                                          onWheel={event =>
                                            event.target instanceof HTMLElement &&
                                            event.target.blur()
                                          }
                                        />
                                      </Stack>
                                    </Grid>
                                  </>
                                ))}
                              </Grid>
                            ) : (
                              <Grid container gap={3}>
                                {requestDetail.requestDays?.map(day => (
                                  <>
                                    <Grid item xs={12}>
                                      <CheckboxTextfield
                                        checkboxRequired
                                        day={day}
                                        setRequestDetail={setRequestDetail}
                                        setRowRequestDayId={setRowRequestDayId}
                                        rowRequestDayId={rowRequestDayId}
                                        setFieldsTouched={setFieldsTouched}
                                        isDisabled={isValidDay()}
                                        fieldsTouched={fieldsTouched}
                                      />
                                    </Grid>
                                  </>
                                ))}
                              </Grid>
                            )}
                          </StyledGrid>
                        )}

                        <Grid item xs={12}>
                          {isChangeRequested() && (
                            <Stack direction="row">
                              <TextField
                                label="Change Comments"
                                fullWidth
                                multiline
                                rows={4}
                                disabled
                                defaultValue={requestDetail?.changeComments}
                                sx={{ paddingRight: '6px', paddingBottom: '20px' }}
                              />
                            </Stack>
                          )}
                          <Stack direction="row">
                            <TextField
                              label="Reviewer comments"
                              fullWidth
                              multiline
                              rows={4}
                              value={requestDetail.reviewerComments}
                              onChange={e => {
                                setRequestDetail(current => {
                                  const { reviewerComments, ...rest } =
                                    current as RequestDetailResponse
                                  return { reviewerComments: e.target.value, ...rest }
                                })
                              }}
                              onBlur={() => {
                                setFieldsTouched({ ...fieldsTouched, reviewerComments: true })
                              }}
                              error={
                                (hasChanged &&
                                  fieldsTouched.reviewerComments &&
                                  requestDetail.reviewerComments === '') ||
                                declineValidationErrors.reviewerComments
                              }
                              sx={{ paddingRight: '6px' }}
                              disabled={isDeclinedOrCancelled || requestIsLocked}
                            />
                          </Stack>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    {occurrenceType === 'Day Off' || occurrenceType === 'Shift' ? (
                      <Grid item xs={12}>
                        <TextField
                          label="Reviewer comments"
                          fullWidth
                          multiline
                          rows={4}
                          value={requestDetail.reviewerComments}
                          onChange={e => {
                            setRequestDetail(current => {
                              const { reviewerComments, ...rest } = current as RequestDetailResponse
                              return { reviewerComments: e.target.value, ...rest }
                            })
                          }}
                          onBlur={() =>
                            setFieldsTouched({ ...fieldsTouched, reviewerComments: true })
                          }
                          error={
                            fieldsTouched.reviewerComments && requestDetail.reviewerComments === ''
                          }
                          disabled={isDeclinedOrCancelled || requestIsLocked}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      direction="column"
                      justifyContent="center"
                      gap={1}
                      sx={{ paddingRight: '6px' }}
                    >
                      {isChangeRequested() && (
                        <>
                          <Stack direction="row" justifyContent="space-between">
                            <Paragraph weight="bold">Total Approved Hours</Paragraph>
                            <Paragraph>{calculateHours().toFixed(2)}</Paragraph>
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <Paragraph weight="bold">Total Requested Hours</Paragraph>
                            <Paragraph>{calculateRequestDayCrHours().toFixed(2)}</Paragraph>
                          </Stack>
                        </>
                      )}
                      {requestDetail.actionedBy && (
                        <>
                          <Stack direction="row" justifyContent="space-between">
                            <Paragraph weight="bold">Actioned Date</Paragraph>
                            <Paragraph>{formattedActionDate}</Paragraph>
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <Paragraph weight="bold">Actioned By</Paragraph>
                            <Paragraph>{requestDetail.actionedBy}</Paragraph>
                          </Stack>
                        </>
                      )}
                    </Grid>
                    {Boolean(boxChecked.length) && (
                      <Grid item xs={12}>
                        <Alert
                          severity="error"
                          message="Please enter a number in the checked field"
                        />
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={12}
                      direction={{ xs: 'column', md: 'row' }}
                      sx={{
                        paddingRight: '6px',
                        '&.MuiGrid-item': {
                          paddingTop: '0px',
                        },
                      }}
                    >
                      <Stack
                        direction={{ xs: 'column-reverse', md: 'row' }}
                        justifyContent="space-between"
                        gap={{ xs: 0, md: 4 }}
                      >
                        <Button
                          label="Decline"
                          variant="outlined"
                          color="secondary"
                          disabled={
                            isDeclinedOrCancelled ||
                            requestIsLocked ||
                            declineDisabled() ||
                            approveLoading
                          }
                          onClick={() => {
                            if (isFormValid(true)) {
                              displayClashModal()
                            } else {
                              setFieldsTouched({ ...fieldsTouched, reviewerComments: true })
                            }
                          }}
                        />
                        <Button
                          label={hasChanged ? 'Approve With Changes' : 'Approve'}
                          variant="outlined"
                          color="primary"
                          disabled={isDeclinedOrCancelled || requestIsLocked || submitDisabled()}
                          onClick={approveRequest}
                          loading={approveLoading}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <NoDataFound show={!isLoading && !requestDetail} />
                    <LoadingIndicator show={isLoading} />
                  </>
                )}
              </Card>
            </Grid>
            {/* Column 2 End */}
          </>
        )}
      </Grid>
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
        }}
        onClick={declineRequest}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
        buttonLoading={declineLoading}
      />
    </>
  )
}

export default Occurrence
