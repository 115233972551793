import { Checkbox, Grid, SxProps } from '@mui/material'
import Paragraph from '../Paragraph'

type Props = {
  name?: string
  label?: string
  checked?: boolean
  defaultChecked?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  color?: string
  sx?: SxProps
  disabled?: boolean
  dataTestId?: string
}

function CheckBox({
  name,
  label,
  checked,
  defaultChecked,
  onChange,
  color,
  sx,
  disabled,
  dataTestId,
}: Props) {
  return (
    <Grid container display="flex" alignContent="center" sx={sx}>
      {label && (
        <Grid item xs={8} display="flex" justifyContent="flex-start" pt={1}>
          <Paragraph weight="medium" color={color}>
            {label}
          </Paragraph>
        </Grid>
      )}
      <Grid item xs={4} display="flex" justifyContent="flex-end" pt={1}>
        <Checkbox
          name={name}
          checked={checked}
          defaultChecked={defaultChecked}
          onChange={e => onChange(e, e.target.checked)}
          disabled={disabled}
          sx={{
            color,
          }}
          disableRipple
          data-testid={dataTestId}
        />
      </Grid>
    </Grid>
  )
}

export default CheckBox
