import { Grid } from '@mui/material'
import Button from '../../../../shared/UI/Button'
import HolidaysEmployeeRequestDetail from './HolidaysEmployeeRequestDetail'
import HolidaysEmployeeRequestDetailGrid from './HolidaysEmployeeRequestDetailGrid'
import { DetailTabProps } from './types'
import Tab from '../../../../shared/UI/Tab'

function HolidaysEmployeeRequestDetailTab({ data, onClose }: DetailTabProps) {
  const tabs: any[] = [
    {
      label: 'Info',
      tabContext: <HolidaysEmployeeRequestDetail data={data} />,
      disabled: false,
    },
    {
      label: 'Requested Days',
      tabContext: <HolidaysEmployeeRequestDetailGrid data={data.groupInfo} />,
      disabled: false,
    },
  ]

  return (
    <div style={{ paddingLeft: '30px' }}>
      <Tab titleAndContent={tabs} />
      <Grid
        container
        xl={12}
        pl={4}
        pr={4}
        style={{ position: 'fixed', bottom: '40px', right: '0px' }}
      >
        <Grid item xs={12} gap={4} display="flex" justifyContent="flex-end">
          <Button label="Close" color="secondary" variant="outlined" onClick={onClose} />
        </Grid>
      </Grid>
    </div>
  )
}

export default HolidaysEmployeeRequestDetailTab
