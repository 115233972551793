import styled from 'styled-components'
import { Autorenew as AutorenewIcon } from '@mui/icons-material'

export interface DeskCircleProps {
  backgroundColor?: string
}

export const Group = styled.g<{ highlightColor: string }>`
   {
    @keyframes bookingGroupfadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 0.1;
      }
    }
    ${props =>
      `&:hover g circle:nth-child(2) {
      animation: bookingGroupfadeIn 100ms;
      fill: ${props.highlightColor};
    }`}
  }
`
