import React, { memo } from 'react'

const CounterLabel = memo(({ count }: { count: number }) => (
  <>
    {count > 99 ? (
      <>
        99
        <sup>+</sup>
      </>
      ) : (
        count
      )}
  </>
  ))

export default CounterLabel
