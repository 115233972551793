import { Department, Employee } from './employee'

export type AbsenceNotification = {
  id: number
  name: string
  severity: 1 | 2 | 3
}

export type AttendanceStatus = {
  id: number
  name: string
}

export interface Shift {
  shiftID?: number
  date?: Date
  description?: string | null
}

// eslint-disable-next-line no-shadow
export enum ReasonType {
  SICKNESS = 'Sickness',
  NON_SICKNESS = 'Non-Sickness',
  NO_SHOW = 'No Show',
}

export interface AbsenceReasonType {
  id: number
  name: ReasonType
}

export interface AbsenceType {
  id?: number
  reason?: string | null
  reasonType?: number
  encryptedReason?: string | null
  encryptedReasonType?: string | null
}

export interface ContactMadeByType {
  id: number
  name: string
}

export interface AbsenceReasonDataTypes {
  absenceTypes: AbsenceType[]
  reasonTypes: AbsenceReasonType[]
  contactMadeBy: ContactMadeByType[]
}

export type AbsenceStatus = {
  name: string
  id: number
  isComplete: boolean
}

export interface AbsenceDay {
  hasErrors?: boolean
  errors?: Error[] | null
  podHostName?: string | null
  id?: number
  absenceId?: number
  date?: Date
  expectedShift?: Shift
  hoursLost?: number
  comment?: string | null
  contactDateTime?: Date
  contactedByTypeId?: number
  contactComments?: string | null
  toWorkBack?: boolean
  discountedAbsence?: boolean
  sickPayHoursRemaining?: number
  sickHoursToBePaidOld?: number
  sickHoursToBePaid?: number
  sickPayAvailable?: boolean
  sickNoteAdded?: boolean
  createdBy?: string | null
  sickPayOverOSPAllowance?: boolean
  absentShift?: string | null
  absenceType?: AbsenceType
}

export type Absence = {
  absenceId: number
  absenceDate: Date
  employee: Employee
  department: Department
  status: AbsenceStatus
  notifications: AbsenceNotification[]
  expectedReturn: Date
  absenceDays: AbsenceDay[]
  dateFrom: Date
  dateTo: Date
  sickPayAllowed: boolean
  sickNoteAdded: boolean
  oSPAffected: boolean
}

// TODO: Guess work
// eslint-disable-next-line no-shadow
export enum ReasonForAbsenceType {
  'COLD/FLU' = 'Cold/Flu',
  'MIGRAINE' = 'Migraine',
  'INJURY' = 'Injury',
  'TUMMYBUG' = 'Tummy Bug',
}

// eslint-disable-next-line no-shadow
export enum MinMaxYearRange {
  HTL_FORWARD = 6,
  HTL_BACKWARD = 2,
  HSS_FORWARD = 3,
  HSS_BACKWARD = 1,
}

// eslint-disable-next-line no-shadow
export enum ReportingMonths {
  Past = -12,
  Future = 6,
}

export type ReasonForAbsence = ReasonForAbsenceType

// eslint-disable-next-line no-shadow
export enum DateFormats {
  DATE_AND_TIME = "yyyy-MM-dd'T'HH:mm:ss",
  Date = 'yyyy-MM-dd',
}
