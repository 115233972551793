import { Card, ClickAwayListener, IconButton, Popper } from '@mui/material'
import React, { ReactElement, useCallback, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import theme from '../../../theme/theme'

type Props = {
  popperText: ReactElement | string
  style?: React.CSSProperties
}

const popperStyle = {
  zIndex: 9999,
  width: '300px',
}

const cardStyle = {
  padding: '11px',
  fontFamily: theme.typography.fontFamily,
  fontSize: '11px',
  boxShadow: '0px 0px 10px #00000014',
  backgroundColor: '#f4f5f7',
}

function InfoPopper({ popperText, style }: Props) {
  const [popperOpen, setPopperOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setPopperOpen(!popperOpen)
      setAnchorEl(event.currentTarget)
    },
    [popperOpen]
  )

  return (
    <>
      <Popper open={popperOpen} anchorEl={anchorEl} style={popperStyle}>
        <Card style={cardStyle}>{popperText}</Card>
      </Popper>

      <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
        <IconButton size="small" onClick={handleClick} style={style}>
          <InfoIcon sx={{ color: theme.palette.primary.main }} />
        </IconButton>
      </ClickAwayListener>
    </>
  )
}

export default InfoPopper
