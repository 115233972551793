import { Button, Card, Grid } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useAppDispatch } from '../../../redux/store'
import { featureToggleService } from '../../../services/featureToggleService'
import { BaseResponse } from '../../../types/base-response'
import UserErrorMessage from '../../../utils/errorFilter'
import { showErrorMessage, showSuccessMessage } from '../../../redux/reducers/snackbarReducer'
import { FeatureToggle } from '../../../types/feature-toggle'
import { IOSStyleSwitch } from '../../../shared/UI/IOSStyleSwitch/IOSStyleSwitch'
import { refreshFeatureToggles } from '../../../shared/layout/Sidebar'

const divStyle = {
  marginTop: 20,
  marginBottom: 20,
  height: 50,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
}

const submitButtonStyle = {
  width: 250,
  height: 50,
  marginTop: 0,
  marginBottom: 15,
  marginRight: 75,
}

const gridSx = {
  '&& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
    whiteSpace: 'pre-wrap',
    paddingTop: '5px',
  },
  '&.MuiDataGrid-root': {
    border: 'none !important ',
    overflow: 'none',
  },
  '&& .MuiDataGrid-cell': {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  '& .header': {
    backgroundColor: '#F4F5F7',
  },
}

function FeatureToggleAdmin() {
  const [pageSize, setPageSize] = useState<number>(20)
  const [features, setFeatures] = useState<FeatureToggle[]>([])

  const dispatch = useAppDispatch()

  const getAllFeatures = useCallback(async () => {
    await featureToggleService
      .getAllFeatureToggles()
      .then(res => {
        setFeatures(res.features)
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [dispatch])

  useEffect(() => {
    getAllFeatures()
  }, [dispatch, getAllFeatures])

  const onSubmit = useCallback(async () => {
    const req = {
      updates: features.map(f => ({
        featureId: f.id,
        newState: f.isOn,
      })),
    }
    await featureToggleService
      .updateFeatures(req)
      .then(res => {
        refreshFeatureToggles()
        dispatch(showSuccessMessage('Feature toggles updated!'))
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [dispatch, features])

  const handleClick = useCallback(
    (row: FeatureToggle) => {
      if (!features.length) {
        return
      }

      const tempRows = [...features]
      const rowToChange = tempRows.find(e => e.id === row.id)
      if (!rowToChange) {
        return
      }

      rowToChange.isOn = !row.isOn
      tempRows[tempRows.indexOf(rowToChange)] = rowToChange
      setFeatures(tempRows)
    },
    [features]
  )

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'key',
        headerName: 'Key',
        type: 'string',
        width: 100,
        filterable: true,
        disableColumnMenu: false,
      },
      {
        field: 'name',
        headerName: 'Feature',
        type: 'string',
        width: 200,
        filterable: true,
        disableColumnMenu: false,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        type: 'string',
        filterable: true,
        disableColumnMenu: false,
      },
      {
        field: 'isOn',
        headerName: '',
        width: 75,
        renderCell: params => (
          <IOSStyleSwitch
            disabled={false}
            checked={params.row.isOn}
            onClick={() => handleClick(params.row)}
          />
        ),
        headerAlign: 'center',
        align: 'right',
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
      },
    ],
    [handleClick]
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <DataGrid
            getRowId={row => row.id}
            rows={features}
            autoHeight
            columns={columns}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            disableSelectionOnClick
            pageSize={pageSize}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            getRowHeight={() => 'auto'}
            density="comfortable"
            sx={gridSx}
          />
          <div style={divStyle}>
            <Button
              variant="outlined"
              disabled={false}
              style={submitButtonStyle}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        </Card>
      </Grid>
    </Grid>
  )
}

export default FeatureToggleAdmin
