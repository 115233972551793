import ClearIcon from '@mui/icons-material/Clear'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootStore, useAppDispatch } from '../../../redux/store'
import TypeLabel from '../../../shared/UI/TypeLabel'
import { setFilterStates } from '../../../redux/reducers/myActionsReducer'

function FilterChips({ filterRequests }: { filterRequests: () => void }) {
  const dispatch = useAppDispatch()
  const { filterStates } = useSelector((state: RootStore) => state.myActions)
  const removeFilter = (index: number) => {
    filterStates.checkboxSettings[index].checked = false
    dispatch(setFilterStates(filterStates))
    filterRequests()
  }
  if (!filterStates.checkboxSettings?.length) return null
  return (
    <Box mx={5} display={{ xs: 'none', md: 'inline' }}>
      {filterStates.checkboxSettings.map(
        (filter, index) =>
          filter.checked && (
            <span
              style={{ display: 'inline-block', margin: '0 8px 8px 0' }}
              key={filter.displayName}
            >
              <TypeLabel
                label={filter.type}
                testId={filter.displayName.replace(/\s/g, '')}
                type={filter.type}
                deleteIcon={<ClearIcon />}
                onDelete={() => removeFilter(index)}
              />
            </span>
          )
      )}
    </Box>
  )
}

export default FilterChips
