import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Tab from '../../shared/UI/Tab'
import HolidayReports from './SSRS/HolidayReports'
import HolidaySearch from './HolidaySearch'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import { RootStore, useAppDispatch } from '../../redux/store'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../utils/errorFilter'
import { BaseResponse } from '../../types/base-response'
import { HolidayReportsItem, SSRSReportType, SSRSReportTypeLabels } from './SSRS/types'
import { HolidayReportsResponse } from '../../types/holiday-reports-response'
import { ssrsHolidayReportService } from '../../services/ssrsHolidayReportService'
import {
  HolidayReportsUserDepartmentsItem,
  HolidayReportsUserDepartmentsResponse,
} from '../../types/holiday-reports-user-departments-response'
import { SelectOption } from '../../services/dashboardService'
import NoDataFound from '../../shared/UI/NoDataFound'

const LoadingContainer = styled.div`
  margin-top: 100px;
  height: 200px;
`

interface HolidayReportingTab {
  label: string
  tabContext: JSX.Element
  disabled: boolean
}

function HolidayReportsTabs() {
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [reportData, setReportData] = useState<any[]>([])
  const [showReportsData, setShowReportsData] = useState<boolean>(false)
  const [userDepartments, setUserDepartments] = useState<SelectOption[]>([])

  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  useEffect(() => {
    ssrsHolidayReportService
      .getHolidayReports()
      .then((res: HolidayReportsResponse) => {
        const plannerReportEndpoints: string[] = res.reports.map((p: any) =>
          p.plannerEndpoint.toLowerCase().trim()
        )

        const reportsList: HolidayReportsItem[] = [
          {
            title: SSRSReportTypeLabels.HOLIDAYS_ENTITLEMENT_REPORT,
            key: SSRSReportType.HOLIDAYS_ENTITLEMENT_REPORT,
          },
          {
            title: SSRSReportTypeLabels.HOLIDAYS_AVAILABILITY_REPORT,
            key: SSRSReportType.HOLIDAYS_AVAILABILITY_REPORT,
          },
          {
            title: SSRSReportTypeLabels.HOLIDAYS_EMPLOYEE_REQUEST_REPORT,
            key: SSRSReportType.HOLIDAYS_EMPLOYEE_REQUEST_REPORT,
          },
          {
            title: SSRSReportTypeLabels.HOLIDAYS_ENTITLEMENT_REMAINING_ALLOWANCE_REPORT,
            key: SSRSReportType.HOLIDAYS_ENTITLEMENT_REMAINING_ALLOWANCE_REPORT,
          },
          {
            title: SSRSReportTypeLabels.HOLIDAYS_REQUESTS_COUNT_REPORT,
            key: SSRSReportType.HOLIDAYS_REQUESTS_COUNT_REPORT,
          },
          {
            title: SSRSReportTypeLabels.HOLIDAY_THRESHOLDS_AND_RESTRICTIONS_REPORT,
            key: SSRSReportType.HOLIDAY_THRESHOLDS_AND_RESTRICTIONS_REPORT,
          },
          {
            title: SSRSReportTypeLabels.HOLIDAYS_ENTITLEMENT_BULGARIA_REPORT,
            key: SSRSReportType.HOLIDAYS_ENTITLEMENT_BULGARIA_REPORT,
          },
          {
            title: SSRSReportTypeLabels.HOLIDAYS_EMPLOYEE_REQUEST_BULGARIA_REPORT,
            key: SSRSReportType.HOLIDAYS_EMPLOYEE_REQUEST_BULGARIA_REPORT,
          },
        ]

        const reports: HolidayReportsItem[] = reportsList.filter(p =>
          plannerReportEndpoints.includes(p.key.toLowerCase().trim())
        )

        setReportData(reports)
        setShowReportsData(reports.length > 0)

        return ssrsHolidayReportService.getHolidayReportsUserDepartments()
      })
      .then((res: HolidayReportsUserDepartmentsResponse) => {
        const departments: SelectOption[] = res.rows.map((p: HolidayReportsUserDepartmentsItem) => {
          const item: SelectOption = {
            value: p.departmentId!,
            displayValue: p.department!,
          }
          return item
        })

        setUserDepartments(departments)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)

        const response: BaseResponse = err.response.data
        if (response.errors) {
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        } else {
          dispatch(showErrorMessage(<UserErrorMessage name="CurrentlyUnavailable" />))
        }
      })
  }, [])

  const canViewSearch = userPermissions.includes('HistoryHoliday')
  const canViewReports = userPermissions.includes('ReportsHoliday')

  const getTabs = (): HolidayReportingTab[] => {
    const tabs: HolidayReportingTab[] = []

    if (canViewReports) {
      tabs.push({
        label: 'Reports',
        tabContext: showReportsData ? (
          <HolidayReports reportData={reportData} userDepartments={userDepartments} />
        ) : (
          <NoDataFound show />
        ),
        disabled: false,
      })
    }

    if (canViewSearch) {
      tabs.push({
        label: 'Search',
        tabContext: <HolidaySearch />,
        disabled: false,
      })
    }

    return tabs
  }

  return (
    <>
      {isLoading && (
        <LoadingContainer>
          <LoadingIndicator show={isLoading} />
        </LoadingContainer>
      )}
      {!isLoading && <Tab titleAndContent={getTabs()} dataTestId="ReportingHoliday" />}
    </>
  )
}

export default HolidayReportsTabs
