import { Grid } from '@mui/material'
import Alert from '../../../../shared/UI/Alert/Alert'

const sicknessId = 1
const covidId = 22

interface CovidIsoPayAlertProps {
  absenceCategoryId: number
  absenceTypeId: number
  covidIsolationIndicator?: boolean | undefined
}

function CovidIsoPayAlert(props: CovidIsoPayAlertProps) {
  const shouldShowCovidInfoBanner =
    props.absenceCategoryId === sicknessId &&
    (props.absenceTypeId === covidId || props.covidIsolationIndicator === true)

  return shouldShowCovidInfoBanner ? (
    <Grid item xs={12} md={12}>
      <Alert
        severity="info"
        message="Please inform the employee that ISO pay has now ceased and standard sick pay rules apply"
      />
    </Grid>
  ) : null
}

export default CovidIsoPayAlert
