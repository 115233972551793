import { Grid, Popper, styled } from '@mui/material/'
import { EventPopupProps } from './types'

export const EventCustomPopup = styled(Popper, {
  shouldForwardProp: prop => prop !== '',
})<EventPopupProps>(() => ({
  ...{
    zIndex: 1200,
  },
})) as unknown as React.ComponentType<EventPopupProps>

export const EventPopupGrid = styled(Grid, {
  shouldForwardProp: prop => prop !== '',
})<EventPopupProps>(({ multiEvent }) => ({
  ...{
    textAlign: 'center',
    backgroundColor: 'white',
    padding: multiEvent ? '24px 0' : '24px',
    boxShadow: '1px 2px 10px #00000034',
    borderRadius: '8px',
  },
})) as React.ComponentType<EventPopupProps>
