import { Box } from '@mui/material'
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro'
import { useEffect, useState } from 'react'
import TypeLabel from '../../../../shared/UI/TypeLabel'

import { GridProps } from './types'
import NoResult from '../../../../shared/UI/NoResult'

function NoRowsOverlay() {
  return <NoResult message="No Results" showImage />
}

function HolidaysAvailabilityGrid({ data, onLoad }: GridProps) {
  const columnsDefault: GridColDef[] = [
    {
      field: 'employeeName',
      headerName: 'Employee',
      headerClassName: 'header',
      cellClassName: 'first',
      type: 'string',
      width: 180,
      filterable: false,
      hideable: false,
      pinnable: false,
      renderCell: cellValues => cellValues.value,
    },
  ]

  const columnsNoData: GridColDef[] = [
    ...columnsDefault,
    {
      field: '',
      headerClassName: 'header',
      cellClassName: 'first',
      flex: 1,
      filterable: false,
      hideable: false,
      pinnable: false,
      renderHeader: (params: GridColumnHeaderParams) => <>&nbsp;</>,
    },
  ]

  const [pageSize, setPageSize] = useState<number>(100)
  const [columns, setColumns] = useState<GridColDef[]>(columnsDefault)
  const [gridData, setGridData] = useState<any[]>([])

  const getType = (row: any, date: string) => {
    const col: any = row[date]

    if (col.hasData) {
      const item = (
        <>
          <TypeLabel label={col.requestStatus} type={col.requestStatus} />
        </>
      )

      return item
    }

    return ''
  }

  useEffect(() => {
    const dataDateList: string[] = data.map((p: any) => p.date.substring(0, 10))
    const uniqueDataDateList: string[] = dataDateList.filter(
      (item, i, ar) => ar.indexOf(item) === i
    )

    const dateColumns: GridColDef[] = uniqueDataDateList.map(
      (date: string, index: number, arr: string[]) => {
        const item: GridColDef = {
          field: date,
          headerName: new Date(date).toLocaleDateString(),
          headerClassName: 'header',
          type: 'string',
          flex: 1,
          sortable: false,
          filterable: false,
          hideable: false,
          pinnable: false,
          renderCell: cellValues => getType(cellValues.row, date),
        }

        if (index === arr.length - 1) {
          item.flex = 1
        } else {
          item.width = 170
        }

        return item
      }
    )

    const dataEmployeeList: string[] = data.map((p: any) => p.employeeName)
    const uniqueDataEmployeeList: string[] = dataEmployeeList.filter(
      (item, i, ar) => ar.indexOf(item) === i
    )

    const transformedData: any[] = []

    const processingData: any = {}

    for (let i = 0; i < uniqueDataEmployeeList.length; i += 1) {
      const employeeName: string = uniqueDataEmployeeList[i]
      processingData[employeeName] = data.filter((p: any) => p.employeeName === employeeName)
    }

    for (let i = 0; i < uniqueDataEmployeeList.length; i += 1) {
      const employeeName: string = uniqueDataEmployeeList[i]
      const item: any = { id: i, employeeName }

      for (let ii = 0; ii < dataDateList.length; ii += 1) {
        const date: string = dataDateList[ii]
        const row: any = processingData[employeeName].find(
          (p: any) => p.date.substring(0, 10) === date
        )

        item[date] = { hasData: false }
        if (row !== undefined && row.requestStatus !== '') {
          item[date].hasData = true
          item[date].hours = row.hours
          item[date].requestStatus = row.requestStatus
        }
      }
      transformedData.push(item)
    }

    setGridData(transformedData)

    const cols = columnsDefault.concat(dateColumns)

    setColumns(cols)

    if (onLoad) {
      onLoad(['Employee'].concat(uniqueDataDateList), transformedData)
    }
  }, [])

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <DataGridPro
          getRowId={row => row.id}
          rows={gridData}
          columns={gridData.length > 0 ? columns : columnsNoData}
          disableSelectionOnClick
          rowsPerPageOptions={[10, 25, 50, 100]}
          pagination
          pageSize={pageSize}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowHeight={50}
          autoHeight
          components={{ NoRowsOverlay }}
          pinnedColumns={gridData.length > 0 ? { left: ['employeeName'] } : {}}
          initialState={{
            sorting: {
              sortModel: [{ field: 'employeeName', sort: 'asc' }],
            },
          }}
          sx={{
            '&.MuiDataGrid-root': {
              border: 'none !important ',
              marginLeft: '-0.5rem',
              paddingTop: '40px',
            },
            '.MuiDataGrid-columnHeaderTitleContainer': {
              borderRight: '1px solid rgba(224, 224, 224, 1)',
              justifyContent: 'center',
            },
            '.MuiDataGrid-cell': {
              borderRight: '1px solid rgba(224, 224, 224, 1)',
              justifyContent: 'center',
            },
            '.first': {
              justifyContent: 'flex-start',
              paddingLeft: '20px',
            },
            '.header': {
              backgroundColor: 'rgb(247, 247, 247)',
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              minHeight: '200px!important',
            },
            '& .MuiDataGrid-virtualScroller': {
              maxHeight: '360px',
            },
          }}
        />
      </Box>
    </>
  )
}

export default HolidaysAvailabilityGrid
