import { Alert as MuiAlert } from '@mui/material'

type Props = {
  severity: 'success' | 'warning' | 'info' | 'error'
  message: string
  testId?: string
  onClose?: () => void
}

function Alert({ severity, message, testId, onClose }: Props) {
  return (
    <MuiAlert
      onClose={onClose}
      severity={severity.toLocaleLowerCase() as 'success' | 'error' | 'info' | 'warning'}
      data-testid={testId}
    >
      {message}
    </MuiAlert>
  )
}

export default Alert
