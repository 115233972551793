import { Box, Button, Grid, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import DeskIcon from '@mui/icons-material/Desk'
import { BookingToolbarDims } from './types'
import LabelledBox from '../Layout/LabelledBox'
import DrawDesks from './DrawDesk'

function Toolbar({ defaultDims, onDimsChange, onSelected }: BookingToolbarDims) {
  const [selectedButton, setSelectedButton] = useState()
  const [disabled, setDisabled] = useState<boolean>(false)
  const [floorSize, setFloorSize] = useState<{ width: number; height: number }>({
    width: defaultDims.floorWidth,
    height: defaultDims.floorHeight,
  })
  const [deskSize, setDeskSize] = useState<{ width: number; height: number }>({
    width: defaultDims.deskWidth,
    height: defaultDims.deskHeight,
  })
  const [showDrawDesks, setShowDrawDesks] = useState<boolean>(false)

  useEffect(() => {
    onDimsChange({
      floorWidth: floorSize.width,
      floorHeight: floorSize.height,
      deskWidth: deskSize.width,
      deskHeight: deskSize.height,
    })
  }, [floorSize, deskSize])

  const handleOnSelected = (cols: number, rows: number) => {
    onSelected(cols, rows)
    setTimeout(() => {
      setShowDrawDesks(false)
    }, 100)
  }

  return (
    <Grid container>
      <Grid item xs={2} display="flex" flexDirection="row" p="10px">
        <LabelledBox label="Floor Size:">
          <TextField
            id="floorWidth"
            label="Width"
            variant="outlined"
            value={floorSize.width}
            style={{ width: '75px', marginLeft: '13px' }}
            type="number"
            onChange={e => {
              setFloorSize(current => ({ ...current, width: Number(e.target.value) }))
            }}
          />
          <TextField
            id="floorHeight"
            label="Height"
            variant="outlined"
            value={floorSize.height}
            style={{ width: '75px', marginLeft: '13px' }}
            type="number"
            onChange={e => {
              setFloorSize(current => ({ ...current, height: Number(e.target.value) }))
            }}
          />
        </LabelledBox>
      </Grid>
      <Grid item xs={2} display="flex" flexDirection="row" p="10px">
        <LabelledBox label="Default Desk Size:">
          <TextField
            id="deskWidth"
            label="Width"
            variant="outlined"
            value={deskSize.width}
            style={{ width: '75px', marginLeft: '13px' }}
            type="number"
            onChange={e => {
              setDeskSize(current => ({ ...current, width: Number(e.target.value) }))
            }}
          />
          <TextField
            id="deskHeight"
            label="Height"
            variant="outlined"
            value={deskSize.height}
            style={{ width: '75px', marginLeft: '13px' }}
            type="number"
            onChange={e => {
              setDeskSize(current => ({ ...current, height: Number(e.target.value) }))
            }}
          />
        </LabelledBox>
      </Grid>
      <Grid item xs={8} display="flex" flexDirection="row" p="10px">
        <LabelledBox label="Desks">
          <Button
            variant="contained"
            style={{ margin: 0, padding: '0 20px' }}
            onMouseLeave={() => setShowDrawDesks(false)}
            onClick={() => setShowDrawDesks(true)}
          >
            <DeskIcon />
            {showDrawDesks && <DrawDesks onSelected={handleOnSelected} />}
          </Button>
        </LabelledBox>
      </Grid>
    </Grid>
  )
}

export default Toolbar
