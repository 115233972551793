import HeadingTwo from '../HeadingTwo'

type Props = {
  title: string | number | undefined | any
}

function CardTitle({ title }: Props) {
  return <HeadingTwo title={title} />
}

export default CardTitle
