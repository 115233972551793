// eslint-disable-next-line no-shadow
export enum DeskStatusColor {
  ACTIVE = '#BFCBC8',
  NOTACTIVE = '#ECECF0',
  OWN_BOOKING = '#20c5a1',
}

// eslint-disable-next-line no-shadow
export enum DeskStatusID {
  VACANT = 1,
  BOOKED = 2,
  UNAVAILABLE = 3,
}

// eslint-disable-next-line no-shadow
export enum DeskAccess {
  TOP = 'TOP',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  BOTTOM = 'BOTTOM',
}
