import { Box, Card, styled, Typography } from '@mui/material'

import { isDesktopUp } from '../../theme/deviceChecks'

interface StyledCardProps {
  hasEntitlementSplit: boolean | undefined
  loading?: boolean
}

export const EntitlementCard = styled(Card, {
  shouldForwardProp: prop => prop !== 'hasEntitlementSplit',
})<StyledCardProps>`
  &.MuiCard-root {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    overflow: visible;
    ${({ hasEntitlementSplit }) => {
      if (hasEntitlementSplit === undefined) {
        return `background: #fff;`
      }
      return `background: ${
        hasEntitlementSplit
          ? 'linear-gradient(to bottom, white 60%, #20c5a0 0%)'
          : 'linear-gradient(to right, white 55%, #20c5a0 0%)'
      };`
    }}
    padding: 10px 20px;
    height: 88px;
    border-radius: 16px;
    box-shadow: none;
  }
`

export const StyledNumbers = styled(Box, {
  shouldForwardProp: prop => prop !== 'hasEntitlementSplit',
})<StyledCardProps>`
  font-family: Poppins;
  font-weight: 500;
  ${({ hasEntitlementSplit }) => `color: ${hasEntitlementSplit ? '#20C5A0' : '#fff'};`}
  font-size: 22px;
  margin-right: 0px;
`

export const StyledDescriptor = styled(Typography)`
  &.MuiTypography-root {
    font-family: Poppins;
    font-weight: bold;
    color: #000;
    font-size: 13px;
    line-height: 1.5;
  }

  ${isDesktopUp()} {
    &.MuiTypography-root {
      font-size: 0.67vw;
    }
  }
`
