import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Stack, Checkbox, TextField, Grid } from '@mui/material'
import { EnhancementDay } from '../../../models/enhancement'
import { handleEnhancementHoursChange } from '../../../utils/SharedMethods/handleHoursChange'

interface DaysCheckboxListProps {
  days: EnhancementDay[]
  disableCheckbox?: boolean
  disableTextField?: boolean
  onDaysChange: (days: EnhancementDay[]) => void
}
export default function DaysCheckboxList({
  days,
  disableCheckbox,
  disableTextField,
  onDaysChange,
}: DaysCheckboxListProps) {
  const [enhancementDays, setEnhancementDays] = useState<EnhancementDay[]>(days)

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedDays = [...enhancementDays]
    updatedDays[index].checked = e.target.checked
    setEnhancementDays(updatedDays)
    onDaysChange(updatedDays)
  }

  const handleHoursChanged = (hours: string, day: EnhancementDay) => {
    const updatedDays = handleEnhancementHoursChange(hours, new Date(day.day), enhancementDays)
    setEnhancementDays(updatedDays as EnhancementDay[])
    onDaysChange(updatedDays as EnhancementDay[])
  }

  useEffect(() => {
    setEnhancementDays(days)
  }, [days])
  return (
    <Grid container gap={3}>
      {enhancementDays?.map((day, index) => (
        <Grid item xs={12} key={day?.day?.toString()}>
          <Stack direction="row" gap={2}>
            <Checkbox
              checked={day.checked}
              onChange={e => handleCheckboxChange(e, index)}
              disabled={disableCheckbox}
            />
            <TextField
              type="number"
              InputProps={{
                inputProps: { step: '0.25', min: 0.25, max: 24 },
              }}
              fullWidth
              label={format(new Date(day.day!), 'ccc dd/MM/yyyy')}
              defaultValue={day.hours}
              value={day.hours}
              onChange={e => handleHoursChanged(e.target.value, day)}
              disabled={disableTextField}
            />
          </Stack>
        </Grid>
      ))}
    </Grid>
  )
}
