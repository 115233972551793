/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/* eslint-disable @typescript-eslint/ban-types */
import { SelectChangeEvent } from '@mui/material'
import { DateRange } from '@mui/x-date-pickers-pro'
import { ChangeEvent, FormEvent, useState, useCallback } from 'react'

export interface Validation {
  required?: {
    value: boolean
    message: string
  }
  pattern?: {
    value: string
    message: string
  }
  custom?: {
    isValid: (value: any) => boolean
    message: string
  }
}

type ErrorRecord<T> = Partial<Record<keyof T, string>>

export type Validations<T extends {}> = Partial<Record<keyof T, Validation>>

export const useForm = <T extends Record<keyof T, any> = {}>(options?: {
  validations?: Validations<T>
  initialValues?: Partial<T>
  onSubmit?: () => void
}) => {
  const [data, setData] = useState<T>((options?.initialValues || {}) as T)
  const [errors, setErrors] = useState<ErrorRecord<T>>({})

  const isFormValid = useCallback(() => {
    const validations = options?.validations
    if (validations) {
      let valid = true
      const newErrors: ErrorRecord<T> = {}
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in validations) {
        const value = data[key]
        const validation = validations[key]

        if (validation?.required?.value && !value) {
          valid = false
          newErrors[key] = validation?.required?.message
        }

        const pattern = validation?.pattern
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          valid = false
          newErrors[key] = pattern.message
        }

        const custom = validation?.custom
        if (custom?.isValid && !custom.isValid(value)) {
          valid = false
          newErrors[key] = custom.message
        }
      }

      if (!valid) {
        setErrors(newErrors)
        return false
      }
    }

    setErrors({})
    return true
  }, [data, options?.validations])

  const handleDateRangeChange = useCallback(
    (
      date: DateRange<Date>,
      fromDateName: string,
      toDateName: string,
      keyboardInputValue?: string | undefined
    ) => {
      setData(currentData => ({
        ...currentData,
        [fromDateName]: date[0],
        [toDateName]: date[1],
      }))
    },
    []
  )

  const handleDateChange = useCallback(
    (selectedDate: Date | null, name: string, keyboardInputValue?: string) => {
      setData(currentData => ({
        ...currentData,
        [name]: selectedDate,
      }))
    },
    []
  )

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const { name } = event.target
      setData(currentData => ({
        ...currentData,
        [name]: checked,
      }))
    },
    []
  )

  const handleChange = useCallback(
    (
      e:
        | ChangeEvent<HTMLInputElement & HTMLSelectElement>
        | SelectChangeEvent<string>
        | SelectChangeEvent<number>
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target
      setData(currentData => ({
        ...currentData,
        [name]: value,
      }))
    },
    []
  )

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (isFormValid()) {
        if (options?.onSubmit) {
          options.onSubmit()
        }
      }
    },
    [isFormValid, options]
  )

  const updateState = useCallback((key: string, value: any) => {
    setData(currentData => ({
      ...currentData,
      [key]: value,
    }))
  }, [])

  return {
    data,
    setData,
    handleChange,
    handleSubmit,
    errors,
    handleDateChange,
    handleCheckboxChange,
    handleDateRangeChange,
    updateState,
  }
}
