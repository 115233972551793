import {
  addDays,
  eachDayOfInterval,
  format,
  isAfter,
  isEqual,
  lastDayOfMonth,
  subDays,
} from 'date-fns'
import { Skeleton } from '@mui/material'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { PlottingCalendarDayProps, PlottingCalendarProps } from './types'
import { PlottingCalendarHeaderRow } from './PlottingCalendarHeaderRow'
import { PlottingCalendarWeek } from './PlottingCalendarWeek'
import { MONTHS } from '../../utils/constants'
import { formatDateWithTimeZone } from '../../utils/date-utils'
import Paragraph from '../../shared/UI/Paragraph'
import {
  PlottingCalendarContainer,
  PlottingCalendarDatesContainer,
  PlottingCalendarMonth,
  PlottingCalendarMonthText,
} from './components'

export function PlottingCalendar({
  date,
  requestDates,
  showEndDates,
  disabledDates,
  onClick,
  changingYear,
  bankHolidays,
  isComingUp,
  onMouseEnter,
}: PlottingCalendarProps) {
  const lastDay = lastDayOfMonth(date)
  const blankLeadDays = date.getDay() === 0 ? 6 : date.getDay() - 1
  const blankSuffixDays = lastDay.getDay() === 0 ? 0 : 7 - lastDay.getDay()
  const dateFrom = subDays(date, blankLeadDays)
  const dateTo = addDays(lastDayOfMonth(date), blankSuffixDays)
  const nonEventDays: PlottingCalendarDayProps[] = eachDayOfInterval({
    start: dateFrom,
    end: dateTo,
  })
    .filter(
      f =>
        !requestDates.some(s => {
          const dateA = `${s.date.getFullYear()}${s.date.getMonth()}${s.date.getDate()}`
          const dateB = `${f.getFullYear()}${f.getMonth()}${f.getDate()}`
          return dateA === dateB
        })
    )
    .map(od => ({
      date: od,
      showInCalendar: od.getMonth() === date.getMonth() || showEndDates,
      isDisabled: disabledDates.some(dd => isEqual(dd, od)),
      onClick,
      isBankHoliday: bankHolidays?.some(bh => isEqual(new Date(bh.holidayDate!), od)),
      isToday: format(new Date(), 'yyyy-MM-dd') === format(od, 'yyyy-MM-dd'),
    }))

  const days = [...requestDates, ...nonEventDays].sort((a, b) => {
    const aDate = Number(format(a.date, 'yyyyMMdd'))
    const bDate = Number(format(b.date, 'yyyyMMdd'))
    if (aDate < bDate) {
      return -1
    }
    if (aDate > bDate) {
      return 1
    }
    return 0
  })

  return (
    <PlottingCalendarContainer className="plottingCalendar" onMouseEnter={onMouseEnter}>
      <PlottingCalendarMonth className="plottingCalendar_month">
        {isComingUp ? (
          <>
            <CalendarTodayIcon sx={{ color: '#000' }} />
            <PlottingCalendarMonthText>
              {`${MONTHS[date.getMonth()]}, ${date.getFullYear()}`}
            </PlottingCalendarMonthText>
          </>
        ) : (
          <PlottingCalendarMonthText>{MONTHS[date.getMonth()]}</PlottingCalendarMonthText>
        )}
      </PlottingCalendarMonth>
      <PlottingCalendarHeaderRow />
      {changingYear ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{
            position: 'absolute',
            bottom: '10%',
            left: '7.5%',
            height: '60.9%',
            width: '85%',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            '&.MuiSkeleton-wave:after': {
              animationDelay: '0ms',
            },
          }}
        />
      ) : (
        <PlottingCalendarDatesContainer className="plottingCalendar_dates">
          <PlottingCalendarWeek days={days.slice(0, 7)} onClick={onClick} />
          <PlottingCalendarWeek days={days.slice(7, 14)} onClick={onClick} />
          <PlottingCalendarWeek days={days.slice(14, 21)} onClick={onClick} />
          <PlottingCalendarWeek days={days.slice(21, 28)} onClick={onClick} />
          {days.length > 28 && <PlottingCalendarWeek days={days.slice(28, 35)} onClick={onClick} />}
          {days.length > 35 && <PlottingCalendarWeek days={days.slice(35)} onClick={onClick} />}
        </PlottingCalendarDatesContainer>
      )}
    </PlottingCalendarContainer>
  )
}
