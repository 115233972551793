import { toRelativeUrl } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { setActiveRoute } from '../redux/reducers/appSettingsReducer'
import { useAppDispatch } from '../redux/store'

function ProtectedRoute() {
  const { oktaAuth, authState } = useOktaAuth()
  const dispatch = useAppDispatch()
  const path = useLocation().pathname

  useEffect(() => {
    if (!authState) {
      return
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin)
      oktaAuth.setOriginalUri(originalUri)
      oktaAuth.signInWithRedirect()
    }
    const baseRoute = path.split('/').filter(element => element)
    dispatch(setActiveRoute(`/${baseRoute[0]}`))
  }, [oktaAuth, authState?.isAuthenticated, authState, path, dispatch])

  if (!authState || !authState?.isAuthenticated) {
    return null
  }

  return <Outlet />
}

export default ProtectedRoute
