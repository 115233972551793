import { useSelector } from 'react-redux'
import { LocationGroups } from '../../../../types/location-groups'
import { RootStore } from '../../../../redux/store'
import { EditOccurrenceFactoryProps } from '../../types'
import EditOccurrence from '..'
import UKEditOccurrence from './ukEditOccurrence'

function EditOccurrenceFactory({
  absenceId,
  employeeResponse,
  absenceDay,
  expectedReturn,
  onClose,
}: EditOccurrenceFactoryProps) {
  const { territoryAttendanceUK } = useSelector((state: RootStore) => state.featureToggles)

  switch (employeeResponse.locationGroupName) {
    case territoryAttendanceUK && LocationGroups.UK:
      return (
        <UKEditOccurrence
          absenceId={absenceId}
          employeeResponse={employeeResponse}
          absenceDay={absenceDay}
          expectedReturn={expectedReturn}
          onClose={onClose}
        />
      )
    default:
      return (
        <EditOccurrence
          absenceId={absenceId}
          employeeResponse={employeeResponse}
          absenceDay={absenceDay}
          expectedReturn={expectedReturn}
          onClose={onClose}
        />
      )
  }
}

export default EditOccurrenceFactory
