import { PermissionTypeResponse } from '../types/permission-types-response'
import { httpServiceV1 } from './httpServiceV1'

const permisssionsBaseURL = 'permissions'

const permissionsService = {
  getUserPermissions: async (): Promise<PermissionTypeResponse> =>
    httpServiceV1.get(permisssionsBaseURL),
}
export { permissionsService }
