import { Box, styled, SvgIcon, Typography } from '@mui/material'

interface StyledSvgIconProps {
  outerCircleValue?: number | undefined
  outerCircleMax?: number | undefined
  centreCircleValue?: number | undefined
  centreCircleMax?: number | undefined
  innerCircleValue?: number | undefined
  innerCircleMax?: number | undefined
  dataSet: {
    val: number
    max: number
    color: string
  }[]
}

export const StyledSvgBox = styled(Box)`
  &.MuiBox-root {
    position: relative;
    height: 100%;
    width: 100%;
  }
`

export const StyledSvgIcon = styled(SvgIcon, {
  shouldForwardProp: prop =>
    prop !== 'dataSet' &&
    prop !== 'outerCircleMax' &&
    prop !== 'outerCircleValue' &&
    prop !== 'centreCircleMax' &&
    prop !== 'centreCircleValue' &&
    prop !== 'innerCircleMax' &&
    prop !== 'innerCircleValue',
})<StyledSvgIconProps>`

  &.MuiSvgIcon-root {
    height: 100%;
    width: 100%;
  }

  & circle,
  & path {
    fill: none;
    stroke-width: 7.5%;
    stroke-linecap: round;
  }
  
  & circle {
    opacity: 0.2;
    filter: drop-shadow(3px 12px 14px rgb(0 0 0 / 0.5));
  }

  & .outerCircleBg {
    stroke: ${({ dataSet }) => `${dataSet[0].color};`}
    opacity: 0.2;
  }

  & .outerCircleProgress {
    stroke: ${({ dataSet }) => `${dataSet[0].color};`}
    stroke-dasharray: ${({ outerCircleMax }) => `${outerCircleMax} ${outerCircleMax};`}
    stroke-dashoffset: ${({ outerCircleMax, outerCircleValue }) =>
      `${outerCircleValue !== undefined ? outerCircleValue : outerCircleMax};`}
    opacity: ${({ outerCircleValue }) => (outerCircleValue !== undefined ? '1;' : '0;')}
    transition: ${({ outerCircleValue }) =>
      outerCircleValue !== undefined ? 'stroke-dashoffset 1s ease, opacity 0.5s ease;' : 'none;'}
  }

  & .centreCircleBg {
    stroke: ${({ dataSet }) => dataSet[1] && `${dataSet[1].color};`}
  }

  & .centreCircleProgress {
    stroke: ${({ dataSet }) => dataSet[1] && `${dataSet[1].color};`}
    stroke-dasharray: ${({ centreCircleMax }) => `${centreCircleMax} ${centreCircleMax};`}
    stroke-dashoffset: ${({ centreCircleMax, centreCircleValue }) =>
      `${centreCircleValue !== undefined ? centreCircleValue : centreCircleMax};`}
    opacity: ${({ centreCircleValue }) => (centreCircleValue !== undefined ? '1;' : '0;')}
    transition: ${({ centreCircleValue }) =>
      centreCircleValue !== undefined ? 'stroke-dashoffset 1s ease, opacity 0.5s ease;' : 'none;'}
  }

  & .innerCircleBg {
    stroke: ${({ dataSet }) => dataSet[2] && `${dataSet[2].color};`}
  }

  & .innerCircleProgress {
    stroke: ${({ dataSet }) => dataSet[2] && `${dataSet[2].color};`}
    stroke-dasharray: ${({ innerCircleMax }) => `${innerCircleMax} ${innerCircleMax};`}
    stroke-dashoffset: ${({ innerCircleMax, innerCircleValue }) =>
      `${innerCircleValue !== undefined ? innerCircleValue : innerCircleMax};`}
    opacity: ${({ innerCircleValue }) => (innerCircleValue !== undefined ? '1;' : '0;')}
    transition: ${({ innerCircleValue }) =>
      innerCircleValue !== undefined ? 'stroke-dashoffset 1s ease, opacity 0.5s ease;' : 'none;'}
  }
`

export const ProgressLabelBox = styled(Box)`
  &.MuiBox-root {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
  }
`

const slideIn = `@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}`

export const ProgressLabelNumber = styled(Typography)`
  &.MuiTypography-root {
    opacity: 0;
    font-size: 36px;
    line-height: 28px;
    color: #000;
    font-weight: bold;
    animation: slideIn 1s ease 0.25s forwards;
  }

  ${slideIn}
`

export const ProgressLabelText = styled(Typography)`
  &.MuiTypography-root {
    opacity: 0;
    font-size: 18px;
    animation: slideIn 1s ease forwards 0.4s;
  }

  ${slideIn}
`
