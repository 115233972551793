import { Box, Grid } from '@mui/material'
import {addDays, differenceInCalendarDays, format, isEqual} from 'date-fns'
import { useCallback, useState } from 'react'
import { WallChartEventBar, WallChartMultiEventBar } from './components'
import {WallChartEventBarProps, WallChartEventBarStylePositionProps, WallChartMultiDayBar} from './types'
import { WallChartItem } from '../../../types/wallchart'
import { WallChartTimespans } from '../WallChartTimespanSelector/types'
import { WallChartItemProps } from '../types'
import { formatDateWithTimeZoneStr } from '../../../utils/date-utils'
import { buildMultiEventList } from '../utils'
import { DateFormats } from "../../../api/absence/tempTypes/generalprops"

export function WallChartRow({
  timespanStartDate,
  timespanEndDate,
  timespanSegments,
  eventItem,
  popupUseOnClick = false,
  onItemInfo,
  onRowClick,
}: WallChartEventBarProps) {
  const [hoverDate, setHoverDate] = useState<Date | undefined>()
  const [multis, setMultis] = useState()

  const compareDates = useCallback((date1: Date, date2: Date) =>
    format(date1, DateFormats.Date) === format(date2, DateFormats.Date),
    []
  )

  const getKey = useCallback(
    (idx: number) => String(idx),
    []
  )

  const hasLeftNeighbour = useCallback(
    (dr: WallChartMultiDayBar, multiDays: WallChartMultiDayBar[]): boolean => {
      const dayBefore = addDays(dr.from, -1)
      const oneBefore = multiDays.find(x => compareDates(x.from, dayBefore))
      return oneBefore !== undefined
    },
    [compareDates]
  )

  const hasRightNeighbour = useCallback(
    (dr: WallChartMultiDayBar, multiDays: WallChartMultiDayBar[]): boolean => {
      const dayAfter = addDays(dr.from, 1)
      const oneAfter = multiDays.find(x => compareDates(x.from, dayAfter))
      return oneAfter !== undefined
    },
    [compareDates]
  )
  
  if (!timespanStartDate) {
    return null
  }

  const multiDayRanges = buildMultiEventList(timespanStartDate, timespanEndDate, eventItem)
  
  const plotBar = (item: WallChartItem): WallChartEventBarStylePositionProps => {
    const dateFrom = new Date(item.dateFrom)
    const dateTo = new Date(item.dateTo || item.dateFrom)
    const segmentPercent = (1 / timespanSegments.masterSegments) * 100
    const extendedLeft = format(dateFrom, 'yyyy-MM-dd') < format(timespanStartDate, 'yyyy-MM-dd')
    const extendedRight = format(dateTo, 'yyyy-MM-dd') > format(timespanEndDate, 'yyyy-MM-dd')

    if (extendedLeft && extendedRight) {
      return { startPercent: '0%', widthPercent: '100%', extendedLeft, extendedRight }
    }

    if (isEqual(timespanStartDate, timespanEndDate)) {
      // Viewing only a day
      if (
        !eventItem.items.some(is =>
          is.days.some(
            di =>
              format(new Date(di.date), 'yyyy-MM-dd') ===
              formatDateWithTimeZoneStr(timespanStartDate, 'yyyy-MM-dd')
          )
        )
      ) {
        return { startPercent: '0%', widthPercent: '0%', extendedLeft: false, extendedRight: false }
      }
    }

    let diff
    diff = differenceInCalendarDays(dateFrom, timespanStartDate)
    const startPercent = extendedLeft ? 0 : diff * segmentPercent
    let endPercent = extendedRight ? 100 - startPercent : -1
    if (!extendedRight) {
      diff = extendedLeft
        ? differenceInCalendarDays(dateTo, timespanStartDate) + 1
        : differenceInCalendarDays(dateTo, dateFrom) + 1
      endPercent =
        timespanSegments.timespan === WallChartTimespans.DAY ? 100 : diff * segmentPercent
    }

    return {
      startPercent: `${startPercent}%`,
      widthPercent: `${endPercent}%`,
      extendedLeft,
      extendedRight,
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            position="relative"
            data-testid={`${format(timespanStartDate, 'yyyy-MM-dd')} - ${format(
              timespanEndDate,
              'yyyy-MM-dd'
            )}`}
          >
            {eventItem.items
              .sort((a, b) => new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime())
              .map((wcItem: WallChartItemProps, idx: number) => (
                <WallChartEventBar
                  key={getKey(idx)}
                  className={
                    wcItem.status?.toLowerCase() === 'pending' ? 'selectedDate_pending' : ''
                  }
                  wcitem={JSON.stringify({
                    userDisplayName: eventItem.userDisplayName,
                    ...wcItem,
                  })}
                  recordTypeStr={
                    wcItem.recordTypeStr === 'Lieu Day' ? 'Lieu' : wcItem.recordTypeStr
                  }
                  status={wcItem.status}
                  zIndex={110 + idx}
                  position={plotBar(wcItem)}
                  onClick={e => {
                    if (!popupUseOnClick) {
                      return
                    }
                    onItemInfo?.(e.currentTarget, wcItem, eventItem.userDisplayName, hoverDate)
                  }}
                  onMouseMove={e => {
                    // Establish the corresponding date from the hover point of the mouse
                    const days = wcItem.days.filter(day => {
                      const compareDayInt = Number(format(new Date(day.date), `yyyyMMdd`))
                      const timespanStartInt = Number(format(timespanStartDate, `yyyyMMdd`))
                      const timespanEndInt = Number(format(timespanEndDate, `yyyyMMdd`))
                      return (
                        (compareDayInt === timespanStartInt || compareDayInt > timespanStartInt) &&
                        (compareDayInt === timespanEndInt || compareDayInt < timespanEndInt)
                      )
                    })
                    const width = e.currentTarget.offsetWidth || 0
                    const segs = width / days.length
                    const index = Math.floor(e.nativeEvent.offsetX / segs)
                    if (index < 0 || index >= days.length) {
                      return
                    }
                    if (days[index].date === hoverDate) {
                      return
                    }
                    setHoverDate(days[index].date)
                    onItemInfo?.(
                      e.currentTarget,
                      wcItem,
                      eventItem.userDisplayName,
                      days[index].date
                    )
                  }}
                  onMouseLeave={() => setHoverDate(undefined)}
                />
              ))}

            {multiDayRanges.map((dr, idx) => {
              const wcItem = {
                id: dr.primaryId,
                dateFrom: dr.from,
                dateTo: dr.to,
                recordTypeStr: dr.recordTypeStr,
                days: [],
                isCancellation: false,
                isQueried: false,
                status: '',
              }
              return (
                <WallChartMultiEventBar
                  key={`mb${getKey(idx)}`}
                  position={plotBar(wcItem)}
                  zIndex={1000}
                  onMouseMove={e => {
                    // Establish the corresponding date from the hover point of the mouse
                    const width = e.currentTarget.offsetWidth || 0
                    const segs = width / dr.dayCount
                    const index = Math.floor(e.nativeEvent.offsetX / segs)
                    if (index < 0 || index >= dr.dayCount) {
                      return
                    }
                    onItemInfo?.(e.currentTarget, wcItem, eventItem.userDisplayName, dr.from)
                  }}
                  onMouseLeave={() => setHoverDate(undefined)}
                  hasLeftMultiRequestNeighbour={hasLeftNeighbour(dr, multiDayRanges)}
                  hasRightMultiRequestNeighbour={hasRightNeighbour(dr, multiDayRanges)}
                />
              )
            })}

            <Box borderBottom="1px solid #d1d1d1" height="59px" width="100%" onClick={onRowClick} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
