import format from 'date-fns/format'
import { MyRequestDetailResponse, RequestDayRequest } from '../../types/requests'
import { PendingRequestUpdateRequest } from '../../types/pending-request-update-request'
import { ChangeRequest } from '../../types/change-request'

export const buildUpdatePendingRequestBody = (
  requestDetail: (MyRequestDetailResponse & { requestDaysCr?: RequestDayRequest[] }) | null,
  hasConflicts: boolean
): PendingRequestUpdateRequest | undefined => {
  if (!requestDetail) {
    return
  }
  const body = {
    requestId: requestDetail.requestId,
    days: requestDetail.requestDays.map(day => ({
      day: new Date(format(new Date(day.day), 'yyyy-MM-dd')),
      hours: Number(day.hours),
      checked: day.checked,
    })),
    conflictOverride: hasConflicts,
    comments: requestDetail.requesterComments,
    ...(requestDetail.shiftStartTime && { shiftStartTime: requestDetail.shiftStartTime }),
  }
  return body
}

export const buildCreateChangeRequestBody = (
  requestDetail: (MyRequestDetailResponse & { requestDaysCr?: RequestDayRequest[] }) | null,
  initialTotalHours: number | undefined
): ChangeRequest | undefined => {
  if (!requestDetail) {
    return
  }
  return {
    requestId: requestDetail.requestId,
    days: requestDetail.requestDays.map(day => ({
      requestDayId: day.requestDayId,
      day: format(new Date(day.day), 'yyyy-MM-dd'),
      hours: day.hours,
      checked: day.checked,
    })),
    requesterComments: requestDetail.requesterComments,
    employeeId: requestDetail.employeeId,
    requestStatusId: 2,
    shiftStartTime: requestDetail.shiftStartTime,
    previousHoursApproved: Number(initialTotalHours?.toFixed(2)),
  }
}
