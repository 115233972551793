import styled from 'styled-components'

interface TabItemProps {
  isActive: boolean
}
export const TabItem = styled.li<TabItemProps>`
  display: inline;
  padding: 10px 0;
  a {
    font-size: 13px;
    text-decoration: none;
    color: ${({ isActive }) => (isActive ? '#20c5a0;' : '#747474')};
    font-family: Poppins;
    font-weight: 500;
    padding-bottom: 10px;
    border-bottom: ${({ isActive }) => (isActive ? '4px solid #20c5a0' : 'none')};
  }
`
