import { Grid, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import Button from '../../shared/UI/Button'
import DrawerFooter from '../../shared/UI/DrawerFooter'
import { SellHoursType } from '../../types/sell-hours-type'
import Paragraph from '../../shared/UI/Paragraph'
import { MyEntitlementResponse } from '../../types/my-entitlement-response'
import useSellRequestForm from './useSellRequestForm'

type Props = {
  closeDrawer: () => void
  hours: SellHoursType | undefined
  requestedSellHours?: number
  myEntitlement?: MyEntitlementResponse
  refreshEntitlement?: () => void
  drawerType?: string
}

function SellRequest({
  closeDrawer,
  hours,
  myEntitlement = undefined,
  requestedSellHours = 0,
  refreshEntitlement,
  drawerType,
}: Props) {
  const dispatch = useDispatch()

  const {
    numberOfHours,
    fieldsTouched,
    submitLoading,
    onSubmit,
    textFieldOnChange,
    textFieldOnBlur,
    textFieldOnWheel,
    disableSubmitButton,
  } = useSellRequestForm(
    dispatch,
    closeDrawer,
    refreshEntitlement,
    hours,
    myEntitlement,
    requestedSellHours
  )

  return (
    <Grid component="form" onSubmit={onSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paragraph weight="bold">Details</Paragraph>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Number of hours to sell"
            onChange={textFieldOnChange}
            value={numberOfHours}
            onBlur={textFieldOnBlur}
            type="number"
            InputProps={{
              inputProps: { step: 0.1, min: 1, max: myEntitlement?.maxHolidaysAvailableToSell },
            }}
            error={fieldsTouched.hours && Number(numberOfHours) < 1}
            onWheel={textFieldOnWheel}
            data-testid="MyExtras-Buy-InputBtn"
          />
        </Grid>
      </Grid>
      <DrawerFooter>
        <Button
          color="secondary"
          label="Cancel"
          onClick={closeDrawer}
          dataTestId={`MyExtras-${drawerType}-CancelBtn`}
        />
        <Button
          label="Submit"
          type="submit"
          disabled={disableSubmitButton()}
          loading={submitLoading}
          dataTestId={`MyExtras-${drawerType}-SubmitBtn`}
        />
      </DrawerFooter>
    </Grid>
  )
}

export default SellRequest
