import { Box, FormLabel } from '@mui/material'
import { LabelledBoxProps } from './types'

function LabelledBox({ label, children }: LabelledBoxProps) {
  return (
    <Box display="flex" flexDirection="row">
      <FormLabel
        sx={{
          marginLeft: '0.71em',
          marginTop: '-0.71em',
          paddingLeft: '0.44em',
          paddingRight: '0.44em',
          zIndex: 2,
          backgroundColor: 'white',
          position: 'absolute',
          fontSize: '1em',
          width: 'auto',
        }}
      >
        {label}
      </FormLabel>
      <Box>
        <Box
          sx={{
            padding: '25px 10px 10px 5px',
            display: 'flex',
            flexDirection: 'row',
            gap: theme => theme.spacing(1),
            border: '1px solid #f1f1f1',
            minHeight: '50px',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default LabelledBox
