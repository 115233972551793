import { httpServiceV1 } from './httpServiceV1'
import { HolidayReportsResponse } from '../types/holiday-reports-response'
import { HolidayEntitlementResponse } from '../types/holiday-entitlement-response'
import { HolidaysAvailabilityResponse } from '../types/holidays-availability-response'
import { HolidaysEmployeeRequestResponse } from '../types/holidays-employee-request-response'
import { HolidayEntitlementRemainingAllowanceResponse } from '../types/holiday-entitlement-remaining-allowance-response'
import { HolidayRequestsCountResponse } from '../types/holiday-requests-count-response'
import { HolidayThresholdsRestrictionsResponse } from '../types/holiday-thresholds-restrictions-response'
import { HolidayEntitlementBulgariaResponse } from '../types/holiday-entitlement-bulgaria-response'
import { HolidaysEmployeeRequestBulgariaResponse } from '../types/holidays-employee-request-bulgaria-response'
import { HolidayReportsUserDepartmentsResponse } from '../types/holiday-reports-user-departments-response'
import { HolidayReportsUsersResponse } from '../types/holiday-reports-users-response'

const requestsBaseURL = 'Reporting'

const ssrsHolidayReportService = {
  getHolidayReports: async (): Promise<HolidayReportsResponse> => {
    const url = `${requestsBaseURL}/HolidayReports`

    return httpServiceV1.get(url)
  },
  getHolidaysEntitlementReports: async (
    departmentIds: number[],
    dateFrom: string,
    dateTo: string,
    remainingEntitlement: boolean
  ): Promise<HolidayEntitlementResponse> => {
    let url = `${requestsBaseURL}/HolidayEntitlement?`

    departmentIds.forEach(departmentId => {
      url = `${url}DepartmentIds=${departmentId}&`
    })

    url = `${url}StartDate=${dateFrom}&`
    url = `${url}EndDate=${dateTo}&`
    url = `${url}RemainingEntitlement=${remainingEntitlement}`

    return httpServiceV1.get(url)
  },
  getHolidaysAvailabilityReports: async (
    dateFrom: string,
    dateTo: string,
    directReportees: string
  ): Promise<HolidaysAvailabilityResponse> => {
    let url = `${requestsBaseURL}/HolidayAvailability?`

    url = `${url}StartDate=${dateFrom}&`
    url = `${url}EndDate=${dateTo}&`
    url = `${url}DirectReportees=${directReportees}`

    return httpServiceV1.get(url)
  },
  getHolidaysEmployeeRequestReports: async (
    employeeIds: number[],
    allEmployees: boolean,
    dateFrom: string,
    dateTo: string,
    selectDateFrom: string,
    selectDateTo: string
  ): Promise<HolidaysEmployeeRequestResponse> => {
    let url = `${requestsBaseURL}/HolidayRequests?`

    url = `${url}employeeIds=${employeeIds.toString()}&`
    url = `${url}allEmployees=${allEmployees}&`
    url = `${url}StartDate=${dateFrom}&`
    url = `${url}EndDate=${dateTo}&`
    url += selectDateFrom !== '' ? `StartDateSelect=${selectDateFrom}&` : ''
    url += selectDateTo !== '' ? `EndDateSelect=${selectDateTo}&` : ''

    url = url.substring(0, url.length - 1)

    return httpServiceV1.get(url)
  },
  getHolidaysEntitlementRemainingAllowanceReports: async (
    departmentIds: number[],
    dateFrom: string,
    dateTo: string,
    remainingEntitlement: boolean,
    directReportees: boolean
  ): Promise<HolidayEntitlementRemainingAllowanceResponse> => {
    let url = `${requestsBaseURL}/HolidayEntitlementHTL?`

    departmentIds.forEach(departmentId => {
      url = `${url}DepartmentIds=${departmentId}&`
    })

    url = `${url}StartDate=${dateFrom}&`
    url = `${url}EndDate=${dateTo}&`
    url = `${url}RemainingEntitlement=${remainingEntitlement}&`
    url = `${url}DirectReportees=${directReportees}`

    return httpServiceV1.get(url)
  },
  getHolidaysRequestsCountReports: async (
    departmentIds: number[],
    dateFrom: string,
    dateTo: string
  ): Promise<HolidayRequestsCountResponse> => {
    let url = `${requestsBaseURL}/EmployeeRequestCount?`

    departmentIds.forEach(departmentId => {
      url = `${url}DepartmentIds=${departmentId}&`
    })

    url = `${url}StartDate=${dateFrom}&`
    url = `${url}EndDate=${dateTo}`

    return httpServiceV1.get(url)
  },
  getHolidaysThresholdsRestrictionsReports: async (
    teamIds: number[],
    departmentIds: number[],
    dateFrom: string,
    dateTo: string
  ): Promise<HolidayThresholdsRestrictionsResponse> => {
    let url = `${requestsBaseURL}/HolidayThresholdsRestrictions?`

    teamIds.forEach(teamId => {
      url = `${url}TeamIds=${teamId}&`
    })

    departmentIds.forEach(departmentId => {
      url = `${url}DepartmentIds=${departmentId}&`
    })

    url = `${url}StartDate=${dateFrom}&`
    url = `${url}EndDate=${dateTo}`

    return httpServiceV1.get(url)
  },
  getHolidaysEntitlementBulgariaReports: async (
    dateStart: string
  ): Promise<HolidayEntitlementBulgariaResponse> => {
    let url = `${requestsBaseURL}/HolidayEntitlementBulgariaReport?`

    url = `${url}StartDate=${dateStart}`

    return httpServiceV1.get(url)
  },
  getHolidaysEmployeeRequesttBulgariaReports: async (
    dateFrom: string
  ): Promise<HolidaysEmployeeRequestBulgariaResponse> => {
    let url = `${requestsBaseURL}/HolidayRequestsBulgaria?`

    url = `${url}StartDate=${dateFrom}`

    return httpServiceV1.get(url)
  },
  getHolidayReportsUserDepartments: async (): Promise<HolidayReportsUserDepartmentsResponse> => {
    const url = `${requestsBaseURL}/HolidayReportsUserDepartments`

    return httpServiceV1.get(url)
  },
  getHolidayReportsUsers: async (): Promise<HolidayReportsUsersResponse> => {
    const url = `${requestsBaseURL}/GetHolidayReportsUsers`

    return httpServiceV1.get(url)
  },
}

export { ssrsHolidayReportService }
