import { useCallback, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Grid, Link, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import ActionIcon from '../../shared/UI/ActionIcon'
import Button from '../../shared/UI/Button'
import Paragraph from '../../shared/UI/Paragraph'
import { formatSickPayTakenHours, getLocalDateString } from '../../utils/date-utils'
import { AbsenceDay } from '../../types/absence-day'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../utils/errorFilter'
import { RootStore } from '../../redux/store'

type RowProps = {
  row: AbsenceDay
  onEdit?: (row: AbsenceDay) => void
  onDelete: (row: AbsenceDay) => void
  handleViewClick?: (row: AbsenceDay) => void
  handleUpdate: (
    absenceDayId: number,
    sickHoursToBePaid: number,
    absenceReasonType?: string
  ) => void
  viewOnly?: boolean
  isSickness: boolean
}

function Row({
  row,
  onEdit,
  onDelete,
  handleUpdate,
  viewOnly,
  handleViewClick,
  isSickness,
}: RowProps) {
  const [sickPayHoursRemaining, setSickPayHoursRemaining] = useState<number>(0)
  const [sickHours, setSickHours] = useState<number>(8)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [absenceReasonType, setAbsenceReasonType] = useState<string | undefined>()
  const [location, setLocation] = useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const [validationErrors, setValidationErrors] = useState<any>({})
  const dispatch = useDispatch()

  const boolToText = useCallback((b: boolean) => (b ? 'Yes' : 'No'), [])

  useEffect(() => {
    setSickHours(row.sickHoursToBePaid || 0)
    setSickPayHoursRemaining(row.sickPayHoursRemaining)
    setAbsenceReasonType(row.absenceType.reasonType)
  }, [row.sickHoursToBePaid, row.sickPayHoursRemaining, row.absenceType])

  useEffect(() => {
    setDisabled(row.sickHoursToBePaid === sickHours)
  }, [row.sickHoursToBePaid, sickHours])

  useEffect(() => {
    if (window.location.pathname.includes('myavailability')) {
      setLocation(false)
    } else {
      setLocation(true)
    }
  }, [absenceReasonType])

  const isValid = (): boolean => {
    setValidationErrors({})
    const errors: any = {}

    if (sickHours > row.sickPayHoursRemaining + (row.sickHoursToBePaid || 0)) {
      errors.sickPayHoursRemaining = true
      dispatch(showErrorMessage(<UserErrorMessage name="SickPayHoursRemaining" />))
    }

    if (sickHours > row.hoursLost) {
      errors.hoursLost = true
      dispatch(showErrorMessage(<UserErrorMessage name="HoursLost" />))
    }

    if (Object.keys(errors).length !== 0) {
      setValidationErrors(errors)
      return false
    }

    return true
  }

  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {location && isSickness ? (
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : null}

        <TableCell component="th" scope="row">
          {getLocalDateString(row.date)}
        </TableCell>
        <TableCell align="left">{row.hoursLost.toFixed(2)}</TableCell>
        {isSickness && <TableCell align="left">{boolToText(row.sickNoteAdded)}</TableCell>}
        <TableCell align="left">{row.absenceType.reasonTypeDescription}</TableCell>
        {isSickness && (
          <>
            <TableCell align="left">{boolToText(row.sickPayAvailable)}</TableCell>
            <TableCell align="left">{formatSickPayTakenHours(row.sickHoursToBePaid)}</TableCell>
          </>
        )}
        <TableCell align="left">{boolToText(row.toWorkBack)}</TableCell>
        {viewOnly && handleViewClick ? (
          <TableCell align="right">
            <ActionIcon onClick={() => handleViewClick(row)}>
              <Link href="#" component="button" variant="body2">
                View
              </Link>
            </ActionIcon>
          </TableCell>
        ) : (
          <>
            {userPermissions.includes('DeleteAttendance') ? (
              <>
                <TableCell align="right">
                  <ActionIcon>
                    <EditIcon onClick={() => onEdit?.(row)} />
                  </ActionIcon>
                </TableCell>
                <TableCell align="right">
                  <ActionIcon>
                    <DeleteIcon onClick={() => onDelete(row)} />
                  </ActionIcon>
                </TableCell>
              </>
            ) : (
              <>
                <TableCell align="right">
                  <></>
                </TableCell>
                <TableCell align="right">
                  <ActionIcon>
                    <EditIcon onClick={() => onEdit?.(row)} />
                  </ActionIcon>
                </TableCell>
              </>
            )}
          </>
        )}
      </TableRow>
      {location &&
      row.absenceType.reasonType !== 'NonSickness' &&
      row.absenceType.reasonType !== 'NoShow' ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Grid container xs={12} mt={1} display="inline-flex" flexDirection="row">
                  <Grid
                    item
                    xs={4}
                    lg={3}
                    xl={2}
                    display="inline-flex"
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <Paragraph weight="bold">Sick Hours Remaining</Paragraph>
                    <Paragraph style={{ marginBottom: '17px' }}>{sickPayHoursRemaining}</Paragraph>
                  </Grid>
                  <Grid item xs={4} lg={3} xl={2} display="inline-flex" flexDirection="column">
                    <Paragraph weight="bold">Hours to be paid</Paragraph>
                    <TextField
                      label="Adjust Hours"
                      value={sickHours}
                      type="number"
                      onChange={e => setSickHours(+e.target.value)}
                      InputProps={{
                        inputProps: { min: 0, step: 0.1 },
                      }}
                      sx={{ marginTop: '15px', paddingRight: '32px' }}
                      error={validationErrors.sickPayHoursRemaining || validationErrors.hoursLost}
                    />
                  </Grid>
                  <Grid item xs={4} lg={3} xl={2} display="inline-flex" alignItems="flex-end">
                    <Button
                      label="Update"
                      disabled={disabled}
                      onClick={() => {
                        if (isValid()) {
                          handleUpdate(row.id, Number(sickHours), row.absenceType.reasonType)
                        }
                      }}
                      style={{ marginTop: '15px', marginLeft: '32px' }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  )
}

export default Row
