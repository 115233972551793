// QA: According to Stack Overflow, there is a bug in TS which wrongly thinks
// some enums are duplicate declarations.
// This has been discussed previously.
// eslint-disable-next-line no-shadow
export enum MyRequestsViewType {
  CALENDAR = 'CALENDAR',
  GRID = 'GRID',
}
export interface MyRequestsTogglesProps {
  viewType?: MyRequestsViewType
  viewTypeChange?: (viewType: MyRequestsViewType) => void
  setChangingYear: React.Dispatch<
    React.SetStateAction<{
      calendarLoading: boolean
      textLoading: boolean
    }>
  >
  changingYear?: boolean
}
