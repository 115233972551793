/**
 * Content Reducer
 * to store reusable content fetched from backend
 */
import { CallOutMenuItems } from '../../models/enhancement'

export interface contentState {
  callOutMenuItems: CallOutMenuItems | null
}

export const initialState: contentState = {
  callOutMenuItems: null,
}

// Action Types
const SET_CALLOUT_MENU_ITEMS = 'SET_CALLOUT_MENU_ITEMS'

// Action Creators
interface SetCallOutMenuItemsAction {
  type: typeof SET_CALLOUT_MENU_ITEMS
  payload: CallOutMenuItems
}

type contentDispatchtypes = SetCallOutMenuItemsAction

// Actions
export const setCallOutMenuItems = (
  callOutMenuItems: CallOutMenuItems
): SetCallOutMenuItemsAction => ({
  type: SET_CALLOUT_MENU_ITEMS,
  payload: callOutMenuItems,
})

// Reducer
export const contentReducer = (
  state: contentState = initialState,
  action: contentDispatchtypes
): contentState => {
  switch (action.type) {
    case SET_CALLOUT_MENU_ITEMS:
      return { ...state, callOutMenuItems: action.payload }
    default:
      return state
  }
}
