import { Grid, Stack, TextField, useMediaQuery } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { addDays, format } from 'date-fns'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Card from '../../shared/layout/Card'
import CardTitle from '../../shared/UI/CardTitle'
import Paragraph from '../../shared/UI/Paragraph'
import Button from '../../shared/UI/Button'
import InstanceStatusBanner from '../../shared/UI/InstanceStatusBanner'
import { requestsService } from '../../services/requestsService'
import { RootStore, useAppDispatch } from '../../redux/store'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import LoadingIndicator from '../../shared/UI/LoadingIndicator'
import NoDataFound from '../../shared/UI/NoDataFound'
import {
  hideModal,
  SetShowModalPayload,
  showModalDialog,
} from '../../redux/reducers/appSettingsReducer'
import Modal from '../../shared/UI/Modal'
import { OccurrenceRequestProps, RequestAction, RequestStatus } from '../OccurrenceRequest/types'
import MyActionsPageHeader from '../../shared/UI/MyActionsPageHeader'
import {
  RequestDayRequest,
  RequestDetailResponse,
  RequestUpdateRequest,
} from '../../types/requests'
import { BaseResponse } from '../../types/base-response'
import { WallChartSegments, WallChartTimespans } from '../WallChart/WallChartTimespanSelector/types'
import { EntitlementSplit } from '../../types/entitlement-split'
import MySummaryEntitlementSplit from '../../shared/UI/MySummaryEntitlementSplit/MySummaryEntitlementSplit'
import { MyRequestsSummaryType } from '../../types/my-requests-summary-type'
import theme from '../../theme/theme'
import { RequestType } from '../../models'
import WallChart from '../WallChart'
import UserErrorMessage from '../../utils/errorFilter'
import Alert from '../../shared/UI/Alert/Alert'
import { statusTranslation } from '../../shared/UI/EventPopup/utils'
import { CheckboxTextfield } from '../Stacks'
import { formatDateWithTimeZoneStr, getLocalDateString } from '../../utils/date-utils'
import { manualRequestsService } from '../../services/myActionsService'

const StyledGrid = styled(Grid)`
  &::-webkit-scrollbar {
    width: 17px;
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    background-clip: content-box;
  }
`

function ManualOccurrence({ occurrenceType }: OccurrenceRequestProps) {
  const { id: requestId } = useParams<{ id: string }>()
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [requestDetail, setRequestDetail] = useState<RequestDetailResponse | null>(null)
  const [initialRequestDetail, setInitialRequestDetail] = useState<RequestDetailResponse | null>(
    null
  )
  const [hasChanged, setHasChanged] = useState<boolean>(false)
  const [rejectRequest, setIsRejectRequest] = useState<boolean>(false)
  const [hasBothDates, setHasBothDates] = useState<boolean>(true)
  const [entitlementSplit, setEntitlementSplit] = useState<EntitlementSplit>()
  const [summaryItems, setSummaryItems] = useState<MyRequestsSummaryType>()
  const [hasRequestDetail, setHasRequestDetail] = useState<boolean>(false)
  const [fieldsTouched, setFieldsTouched] = useState({ reviewerComments: false, rows: false })
  const [rowRequestDayId, setRowRequestDayId] = useState<number[]>([])
  const [isDeclinedOrCancelled, setIsDeclinedOrCancelled] = useState<boolean>(true)
  const [previousYear, setPreviousYear] = useState<boolean>(false)
  const [approveLoading, setApproveLoading] = useState<boolean>(false)
  const [declineLoading, setDeclineLoading] = useState<boolean>(false)
  const minDate = useMemo(() => new Date('0001/01/01'), [])

  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const activeRoute = useSelector<RootStore, string>(
    (state: RootStore) => state.appSettings.activeRoute
  )

  const navigate = useNavigate()

  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)

  const isFormValid = () => {
    if (hasChanged && requestDetail?.reviewerComments === '') {
      return false
    }

    const boxChecked =
      requestDetail &&
      requestDetail.requestDays.filter(
        day => day.checked && (day.hours === null || day.hours === '0' || day.hours === '')
      )

    if (boxChecked && boxChecked.length > 0) {
      boxChecked.map(requestDay =>
        setRowRequestDayId([...rowRequestDayId, requestDay.requestDayId])
      )

      return false
    }

    return true
  }

  const checkForDates = useCallback(
    (requestDateTo: Date) => {
      setHasBothDates(new Date(requestDateTo) > minDate)
    },
    [minDate]
  )

  useEffect(() => {
    if (requestDetail) {
      setIsDeclinedOrCancelled(
        requestDetail.requestStatusId === 5 || requestDetail.requestStatusId === 7
      )
    }
  }, [requestDetail])

  useEffect(() => {
    setIsLoading(true)
    manualRequestsService
      .getHolidayRequestDetail(requestId as string)
      .then(data => {
        setIsLoading(false)
        setRequestDetail(data)
        setHasRequestDetail(true)
        setInitialRequestDetail(data)
        checkForDates(data.dateTo)
        setEntitlementSplit({
          general: {
            hours: data?.summary.totalHours,
            summerHours: data?.summary.totalSummerHours,
            winterHours: data?.summary.totalWinterHours,
          },
          remaining: {
            hours: data?.summary.remainingHours,
            summerHours: data?.summary.remainingSummerHours,
            winterHours: data?.summary.remainingWinterHours,
          },
          requested: {
            hours: data?.summary.requestedHours,
            summerHours: data?.summary.requestedSummerHours,
            winterHours: data?.summary.requestedWinterHours,
          },
          changeRequests: {
            hours: data?.summary.changeRequestHours,
            summerHours: data?.summary.changeRequestHoursSummer,
            winterHours: data?.summary.changeRequestHoursWinter,
          },
          ifApproved: {
            hours: data?.summary.ifApprovedHours,
            summerHours: data?.summary.ifApprovedHoursSummer,
            winterHours: data?.summary.ifApprovedHoursWinter,
          },
        })
        setPreviousYear(
          currentEntitlementPeriodResponse?.entitlementPeriod.toLowerCase() === 'financialyear'
            ? new Date(data.dateFrom).getFullYear() < currentEntitlementPeriodResponse.year
            : new Date(data.dateFrom).getFullYear() < new Date().getFullYear()
        )
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
        setIsLoading(false)
        setRequestDetail(null!)
      })
  }, [])

  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  const displayClashModal = () => {
    dispatch(
      showModalDialog({
        title: 'Are you sure?',
        message: `Are you sure you would like to cancel this request?`,
        buttonLabel: 'Cancel Request',
        type: 'question',
        showModal: true,
      })
    )
  }

  const displayRejectRequestModal = () => {
    setIsRejectRequest(true)
    dispatch(
      showModalDialog({
        title: 'Are you sure?',
        message: `Are you sure you would like to reject this request?`,
        buttonLabel: 'Reject Request',
        type: 'question',
        showModal: true,
      })
    )
  }

  const requestDetailHasChanged = useCallback((): boolean => {
    if (requestDetail && initialRequestDetail) {
      return Object.keys(requestDetail).some(key => {
        if (key !== 'reviewerComments' || requestDetail.requestStatusId === RequestStatus.AMENDED) {
          return (
            requestDetail[key as keyof RequestDetailResponse] !==
            initialRequestDetail[key as keyof RequestDetailResponse]
          )
        }
        return false
      })
    }
    return false
  }, [initialRequestDetail, requestDetail])

  useEffect(() => {
    setHasChanged(requestDetailHasChanged())
  }, [requestDetailHasChanged])

  const transformBody = (): RequestUpdateRequest | undefined => {
    if (!requestDetail) {
      return
    }
    return {
      requestId: requestDetail.requestId,
      requestStatusId: requestDetail.requestStatusId,
      requestTypeId: requestDetail.requestTypeId,
      manualTypeId: requestDetail.manualTypeId,
      employeeId: requestDetail.employeeId,
      applyChangesToRota: false,
      departmentId: requestDetail.departmentId,
      reviewerComments: requestDetail.reviewerComments,
      days: requestDetail.requestDays.map(day => ({
        requestDayId: day.requestDayId,
        requestDayDate: day.day,
        hours: Number(day.hours) < 0 ? 0 : Number(day.hours),
        checked: day.checked,
      })),
      dateFrom: format(new Date(requestDetail.dateFrom.toString()), 'yyyy-MM-dd'),
      dateTo: format(new Date(requestDetail.dateTo.toString()), 'yyyy-MM-dd'),
      requestActionId: RequestStatus.DECLINED,
      isCancellation: false,
      hasBeenRejected: true,
      conflictOverride: true,
    }
  }

  const fetchHolidayRequestDetail = useCallback(async () => {
    setIsLoading(true)
    await manualRequestsService
      .getHolidayRequestDetail(requestId as string)
      .then(data => {
        setIsLoading(false)
        setRequestDetail(data)
        setInitialRequestDetail(data)
        checkForDates(data.dateTo)
      })
      .catch(err => {
        setIsLoading(false)
        setRequestDetail(null!)
        navigate(activeRoute)
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }, [checkForDates, dispatch, requestId])

  const declineRequest = async () => {
    setIsLoading(true)
    const body = transformBody()
    if (!body) {
      return
    }
    body.requestActionId = RequestAction.DECLINE
    body.requestStatusId = RequestStatus.CANCELLED
    body.hasBeenRejected = true
    setDeclineLoading(true)
    await requestsService
      .updateHolidayRequest(body)
      .then(data => {
        setDeclineLoading(false)
        setIsLoading(false)
        dispatch(hideModal())
        dispatch(showSuccessMessage(`${occurrenceType} request cancelled`))
        fetchHolidayRequestDetail()
        navigate(activeRoute || '/myactions')
      })
      .catch(err => {
        setDeclineLoading(false)
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
        setIsLoading(false)
      })
  }

  const rejectManualRequest = async () => {
    setIsLoading(true)
    const body = transformBody()
    if (!body) {
      return
    }
    body.requestActionId = RequestAction.REJECT
    body.requestStatusId = RequestStatus.APPROVED
    body.hasBeenRejected = true
    setDeclineLoading(true)
    await requestsService
      .updateHolidayRequest(body)
      .then(data => {
        setDeclineLoading(false)
        setIsLoading(false)
        dispatch(hideModal())
        dispatch(showSuccessMessage(`${occurrenceType} request rejected`))
        fetchHolidayRequestDetail()
        navigate(activeRoute || '/myactions')
        setIsRejectRequest(false)
      })
      .catch(err => {
        setDeclineLoading(false)
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
        setIsLoading(false)
      })
  }

  const canSetAmended = () => {
    switch (requestDetail?.requestTypeId) {
      case RequestType.HOLIDAY_M:
      case RequestType.LIEU_DAY_M:
      case RequestType.COURSE:
      case RequestType.OTHER:
      case RequestType.MANUAL:
        return true
      default:
        return false
    }
  }

  const approveRequest = async () => {
    if (isFormValid()) {
      setIsLoading(true)
      const body = transformBody()
      if (!body) {
        return
      }

      if (body.days.some(day => day.checked)) {
        if (hasChanged && canSetAmended()) {
          body.requestStatusId = RequestStatus.AMENDED
        } else {
          body.requestActionId = RequestAction.APPROVE
          body.requestStatusId = RequestStatus.APPROVED
        }
        body.hasBeenRejected = false
        setApproveLoading(true)
        await requestsService
          .updateHolidayRequest(body)
          .then(() => {
            setApproveLoading(false)
            setIsLoading(false)
            dispatch(showSuccessMessage(`${occurrenceType} request approved`))
            fetchHolidayRequestDetail()
            navigate(activeRoute || '/myactions')
          })
          .catch(err => {
            setApproveLoading(false)
            const response: BaseResponse = err.response.data
            response.errors.forEach(error => {
              dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
            })
            setIsLoading(false)
          })
      } else {
        dispatch(
          showModalDialog({
            title: 'Cancel request?',
            message:
              'All days for this request have been unselected, which will cancel the request. Are you sure?',
            buttonLabel: 'Confirm',
            type: 'question',
            showModal: true,
          })
        )
        setIsRejectRequest(false)
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchHolidayRequestDetail()
  }, [fetchHolidayRequestDetail])

  const formattedDateFrom = requestDetail && getLocalDateString(new Date(requestDetail.dateFrom))
  const formattedDateTo = requestDetail && getLocalDateString(new Date(requestDetail.dateTo))
  const formattedActionDate =
    requestDetail?.actionedBy && getLocalDateString(new Date(requestDetail!.actionedDateTime))

  const submitDisabled = () => {
    const DISABLED = true
    if (
      requestDetail &&
      hasChanged &&
      (requestDetail.requestStatusId === RequestStatus.APPROVED ||
        requestDetail.requestStatusId === RequestStatus.AMENDED)
    ) {
      return !DISABLED
    }
    if (
      !requestDetail ||
      (fieldsTouched.reviewerComments &&
        requestDetail.reviewerComments === '' &&
        fieldsTouched.rows)
    ) {
      return DISABLED
    }
    return !(
      requestDetail.requestStatusId === RequestStatus.PENDING ||
      requestDetail.requestStatusId === RequestStatus.APPROVED_CR ||
      requestDetail.requestStatusId === RequestStatus.DECLINED
    )
  }

  const declineDisabled = () => {
    if (!requestDetail) {
      return true
    }
    return !(
      requestDetail.requestStatusId === RequestStatus.PENDING ||
      requestDetail.requestStatusId === RequestStatus.AMENDED ||
      requestDetail.requestStatusId === RequestStatus.APPROVED_CR ||
      requestDetail.requestStatusId === RequestStatus.APPROVED
    )
  }

  const calculateHours = () => {
    let total = 0
    requestDetail?.requestDays.forEach(day => {
      total += day.checked ? Number(day.hours) : 0
    })
    return total.toFixed(2)
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, day: RequestDayRequest) => {
    const { requestDayId } = day
    setRequestDetail(current => {
      const { requestDays, ...rest } = current as RequestDetailResponse
      const dayFound = requestDays.find(d => d.requestDayId === requestDayId)
      if (dayFound) {
        dayFound.checked = e.target.checked
      }
      const checkedDays = requestDays.filter(requestDay => requestDay.checked)
      const totalRequestedHours = Object.values(checkedDays).reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.hours),
        0
      )
      rest.totalRequestedHours = totalRequestedHours
      const retVal = { requestDays, ...rest }
      return retVal
    })
  }

  const handleHoursChanged = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    day: RequestDayRequest
  ) => {
    const { requestDayId } = day
    if (!e.target.value) {
      setRowRequestDayId([...rowRequestDayId, requestDayId])
    } else {
      const dayIds = rowRequestDayId
      const index = dayIds.indexOf(requestDayId)
      if (index > -1) {
        dayIds.splice(index, 1)
      }
      setRowRequestDayId(dayIds)
    }
    setRequestDetail(current => {
      const { requestDays, ...rest } = current as RequestDetailResponse
      const dayFound = requestDays.find(d => d.requestDayId === requestDayId)
      if (dayFound) {
        dayFound.hours = e.target.value
      }
      const checkedDays = requestDays.filter(requestDay => requestDay.checked)
      const totalRequestedHours = Object.values(checkedDays).reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.hours),
        0
      )
      rest.totalRequestedHours = totalRequestedHours
      const retVal = { requestDays, ...rest }
      return retVal
    })
    setFieldsTouched({
      rows: !day.hours,
      reviewerComments: false,
    })
  }

  const showHoursForType = () => {
    switch (occurrenceType) {
      case 'Maternity':
      case 'Paternity':
      case 'Night Shift Cover':
      case 'Twilight Shift Cover':
        return false
      default:
        return true
    }
  }

  const mainCardTitle = () => {
    if (requestDetail?.requestTypeId === RequestType.SHIFT) {
      return 'Shift Start Time'
    }
    if (showHoursForType()) {
      return 'Requested Hours'
    }
    return ''
  }

  const calculateStatusLabel = (prop: RequestDetailResponse) => {
    if (!prop?.status || !prop.statusBanner.requestStatus) return

    if (prop?.isQueried) {
      return 'Queried'
    }
    return statusTranslation(prop.statusBanner.requestStatus)
  }

  return (
    <>
      <MyActionsPageHeader title={`${occurrenceType} Request`} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {requestDetail && (
            <>
              <InstanceStatusBanner
                showBackButton
                firstname={requestDetail.statusBanner.forename}
                surname={requestDetail.statusBanner.surname}
                status={
                  requestDetail.isCancellation
                    ? 'Cancellation Requested'
                    : calculateStatusLabel(requestDetail)
                }
              />
              {previousYear && (
                <Alert severity="warning" message="This request is within a previous year" />
              )}
            </>
          )}
        </Grid>
        {requestDetail && (
          <>
            {/* Column 1 */}
            <Grid item xs={12} lg={8}>
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  xl={6}
                  style={{ display: 'flex', flexFlow: 'column', flex: '1 1 auto' }}
                >
                  <Card
                    title={mainCardTitle()}
                    icon={
                      !showHoursForType() ? (
                        ''
                      ) : (
                        <CardTitle
                          title={
                            requestDetail.requestTypeId === RequestType.SHIFT
                              ? requestDetail.shiftStartTime
                              : calculateHours()
                          }
                        />
                      )
                    }
                  >
                    {requestDetail ? (
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Stack direction="row" justifyContent="space-between">
                            <Paragraph weight="bold">Date from</Paragraph>
                            <Paragraph>{formattedDateFrom}</Paragraph>
                            {hasBothDates && (
                              <>
                                <Paragraph weight="bold">Date to</Paragraph>
                                <Paragraph>{formattedDateTo}</Paragraph>
                              </>
                            )}
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Requester comments"
                            fullWidth
                            multiline
                            rows={4}
                            disabled
                            defaultValue={requestDetail.requesterComments}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <NoDataFound show={!isLoading && !requestDetail} />
                        <LoadingIndicator show={isLoading} />
                      </>
                    )}
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  xl={6}
                  style={{ display: 'flex', flexFlow: 'column', flex: '1 1 auto' }}
                >
                  <Card title="Summary">
                    {entitlementSplit ? (
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <MySummaryEntitlementSplit
                            marginTop={0}
                            marginBottom={0}
                            titleMarginBottom={1}
                            summaryItems={entitlementSplit}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <NoDataFound
                          show={!isLoading && !summaryItems?.entitlementSplit && !summaryItems}
                        />
                        <LoadingIndicator show={isLoading} />
                      </>
                    )}
                  </Card>
                </Grid>
                {!mobile && (
                  <Grid item xs={12}>
                    <WallChart
                      title="Who's Away"
                      isWallChartView={false}
                      filterPropertiesOverride={{
                        dateFrom: formatDateWithTimeZoneStr(requestDetail.dateFrom),
                        dateTo: formatDateWithTimeZoneStr(
                          addDays(new Date(requestDetail.dateFrom), 13)
                        ),
                        departmentId: requestDetail.departmentId,
                        directReportees: true,
                        departmentTeamIds: [],
                        employees: [],
                        includeThresholds: true,
                        thresholdsForEmployee: requestDetail.employeeId,
                      }}
                      timeSpanPropertiesOverride={{
                        startDate: new Date(requestDetail.dateFrom),
                        endDate: addDays(new Date(requestDetail.dateFrom), 13),
                        directReport: true,
                        segments: {
                          masterSegments: 14,
                          divisions: WallChartSegments.SEGMENT_DIV_TWOWEEK,
                          timespan: WallChartTimespans.TWOWEEK,
                        },
                        title: '',
                        rangeType: 'TwoWeek',
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/* Column 1 END */}
            {/* Column 2 (Requested Days) */}
            <Grid item xs={12} lg={4}>
              <Card title="Approval">
                {requestDetail ? (
                  <Grid container spacing={4}>
                    {occurrenceType !== 'Day Off' && occurrenceType !== 'Shift' ? (
                      <>
                        {showHoursForType() && (
                          <StyledGrid
                            item
                            xs={12}
                            sx={{
                              overflowY: 'auto',
                              maxHeight: '250px',
                              marginTop: '20px',
                            }}
                          >
                            <Grid container gap={3}>
                              {requestDetail.requestDays?.map(day => (
                                <>
                                  <Grid item xs={12}>
                                    <CheckboxTextfield
                                      checkboxRequired
                                      day={day}
                                      setRequestDetail={setRequestDetail}
                                      setRowRequestDayId={setRowRequestDayId}
                                      rowRequestDayId={rowRequestDayId}
                                      setFieldsTouched={setFieldsTouched}
                                      isDisabled={isDeclinedOrCancelled}
                                      fieldsTouched={fieldsTouched}
                                    />
                                  </Grid>
                                </>
                              ))}
                            </Grid>
                          </StyledGrid>
                        )}
                        <Grid item xs={12}>
                          <Stack direction="row">
                            <TextField
                              label="Reviewer comments"
                              fullWidth
                              multiline
                              rows={4}
                              value={requestDetail.reviewerComments}
                              onChange={e => {
                                setRequestDetail(current => {
                                  const { reviewerComments, ...rest } =
                                    current as RequestDetailResponse
                                  return { reviewerComments: e.target.value, ...rest }
                                })
                              }}
                              onBlur={() => {
                                setFieldsTouched({ ...fieldsTouched, reviewerComments: true })
                              }}
                              error={
                                hasChanged &&
                                fieldsTouched.reviewerComments &&
                                requestDetail.reviewerComments === ''
                              }
                              disabled={isDeclinedOrCancelled}
                              sx={{ paddingRight: '6px' }}
                            />
                          </Stack>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    {occurrenceType === 'Day Off' || occurrenceType === 'Shift' ? (
                      <Grid item xs={12}>
                        <TextField
                          label="Reviewer comments"
                          fullWidth
                          multiline
                          rows={4}
                          value={requestDetail.reviewerComments}
                          onChange={e => {
                            setRequestDetail(current => {
                              const { reviewerComments, ...rest } = current as RequestDetailResponse
                              return { reviewerComments: e.target.value, ...rest }
                            })
                          }}
                          onBlur={() =>
                            setFieldsTouched({ ...fieldsTouched, reviewerComments: true })
                          }
                          error={
                            fieldsTouched.reviewerComments && requestDetail.reviewerComments === ''
                          }
                          disabled={isDeclinedOrCancelled}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      direction="column"
                      justifyContent="center"
                      gap={1}
                      sx={{ paddingRight: '6px' }}
                    >
                      {requestDetail.actionedBy && (
                        <>
                          <Stack direction="row" justifyContent="space-between">
                            <Paragraph weight="bold">Actioned Date</Paragraph>
                            <Paragraph>{formattedActionDate}</Paragraph>
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <Paragraph weight="bold">Actioned By</Paragraph>
                            <Paragraph>{requestDetail.actionedBy}</Paragraph>
                          </Stack>
                        </>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      direction={{ xs: 'column', md: 'row' }}
                      sx={{ paddingRight: '6px' }}
                    >
                      <Stack
                        direction={{ xs: 'column-reverse', md: 'row' }}
                        justifyContent="space-between"
                        gap={{ xs: 0, md: 4 }}
                      >
                        {requestDetail.isQueried && requestDetail.manualTypeId === 9 && (
                          <Button
                            label="Reject"
                            variant="outlined"
                            color="secondary"
                            disabled={isDeclinedOrCancelled || declineDisabled() || approveLoading}
                            onClick={() => {
                              if (isFormValid()) {
                                displayRejectRequestModal()
                              } else {
                                setFieldsTouched({ ...fieldsTouched, reviewerComments: true })
                              }
                            }}
                          />
                        )}
                        <Button
                          label={requestDetail.isQueried ? 'Remove' : 'Decline'}
                          variant="outlined"
                          color="secondary"
                          disabled={isDeclinedOrCancelled || declineDisabled() || approveLoading}
                          onClick={() => {
                            if (isFormValid()) {
                              displayClashModal()
                            } else {
                              setFieldsTouched({ ...fieldsTouched, reviewerComments: true })
                            }
                          }}
                        />
                        <Button
                          label={hasChanged ? 'Approve With Changes' : 'Approve'}
                          variant="outlined"
                          color="primary"
                          disabled={!hasChanged || isDeclinedOrCancelled || submitDisabled()}
                          onClick={approveRequest}
                          loading={approveLoading}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <NoDataFound show={!isLoading && !requestDetail} />
                    <LoadingIndicator show={isLoading} />
                  </>
                )}
              </Card>
            </Grid>
            {/* Column 2 End */}
          </>
        )}
      </Grid>
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
        }}
        onClick={rejectRequest ? rejectManualRequest : declineRequest}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
        buttonLoading={declineLoading}
      />
    </>
  )
}

export default ManualOccurrence
