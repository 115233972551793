/* tslint:disable */
/* eslint-disable */

export enum RequestType {
  HOLIDAY = 1,
  DAY_OFF = 2,
  SHIFT = 3,
  MANUAL = 4,
  LIEU_DAY = 5,
  WORK_FROM_HOME = 6,
  COURSE = 7,
  OTHER = 8,
  MATERNITY = 9,
  PATERNITY = 10,
  NIGHT_SHIFT = 11,
  TWILIGHT_SHIFT = 12,
  ADJUSTMENT = 13,
  HOLIDAY_M = 14,
  LIEU_DAY_M = 15,
  BIRTHDAY = 16,
  OVERTIME = 17,
  NIGHT = 18,
  OVERTIME_M = 19,
  NIGHT_M = 20,
  ON_CALL = 21,
  ON_CALL_M = 22,
  CALL_OUT = 23,
  CALL_OUT_M = 24,
}

export const RequestTypeNameString: { [index: number]: string } = {
  1: 'Holiday',
  2: 'DayOff',
  3: 'Shift',
  4: 'Manual',
  5: 'LieuDay',
  6: 'WorkFromHome',
  7: 'Course',
  8: 'Other',
  9: 'Maternity',
  10: 'Paternity',
  11: 'NightShift',
  12: 'TwilightShift',
  13: 'Adjustment',
  14: 'HolidayM',
  15: 'LieuDayM',
  16: 'Birthday',
  17: 'Overtime',
  18: 'Night',
  21: 'OnCall',
  23: 'CallOut',
}
