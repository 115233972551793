import { Box } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Card from '../../../../shared/layout/Card'
import Paragraph from '../../../../shared/UI/Paragraph'

import { DetailGridProps } from './types'

function HolidaysEmployeeRequestDetailGrid({ data }: DetailGridProps) {
  return (
    <>
      <br />
      <br />
      <Card title="">
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Paragraph weight="bold">Requested Days</Paragraph>
                </TableCell>
                <TableCell align="left">
                  <Paragraph weight="bold">Requested Hours</Paragraph>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row: any) => (
                  <TableRow>
                    <TableCell align="left">{row.day}</TableCell>
                    <TableCell align="left">{row.hours}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  )
}

export default HolidaysEmployeeRequestDetailGrid
