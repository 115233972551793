import { Box, styled, Card } from '@mui/material'
import { isMobilePortrait } from '../../theme/deviceChecks'

export const AllocationExtrasTextContainer = styled(Box)`
  position: relative;
  display: flex;
  background-color: #fafafa;
  border-radius: 16px;
  height: 75px;
`

export const AllocationExtrasCard = styled(Card)`
  &.MuiCard-root {
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 72px;
    min-height: 614px;
    border-radius: 16px;
    padding: 0;
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    box-sizing: border-box;
  }

  ${isMobilePortrait()} {
    &.MuiCard-root {
      border-radius: 0;
    }
  }
`

export const MyExtrasHeadingContainer = styled(Box)`
  text-align: center;
  padding-top: 24px;
`
