import { useDispatch } from 'react-redux'
import Card from '@mui/material/Card'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns'
import { Button, Grid, List, Paper, TextField } from '@mui/material'
import NotificationLogsHeader from './notificationLogsHeader'
import { ErrorDetail, NotificationLogsQuery, NotificationRequestLog } from './models'
import {
  getApplicationNameFrom,
  getEventTypeNameFrom,
  getPlatformTypeNameFrom,
  getTemplateTypeNameFrom,
} from './helpers'
import { notificationLogsService } from '../../../services/notificationsLogsService'
import { showErrorMessage, showSuccessMessage } from '../../../redux/reducers/snackbarReducer'
import Modal from '../../../shared/UI/Modal'
import { hrDocumentsRegenerationService } from '../../../services/HrDocumentsRegenerationService'
import Paragraph from '../../../shared/UI/Paragraph'

const gridSx = {
  '&.MuiDataGrid-root': {
    border: 'none !important ',
    overflow: 'none',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    minHeight: '200px!important',
  },
  '.header': {
    backgroundColor: 'rgb(247, 247, 247)',
    height: '54px',
  },
  cursor: 'pointer',
}

const componentProps = {
  panel: {
    sx: {
      '& .MuiNativeSelect-select option': {
        font: '13px "Poppins", sans-serif',
      },
    },
  },
}

const buttonDiv = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
}

const buttonStyleBase = {
  height: '60%',
  width: '50%',
  marginTop: 0,
  marginBottom: 0,
}

const submitButtonStyle = {
  width: 250,
  height: 50,
  marginTop: 0,
  marginBottom: 15,
  marginLeft: 75,
  marginRight: 75,
}

const buttonDivStyle = {
  height: 75,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

function NotificationLogs() {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showModalErrors, setShowModalErrors] = useState<boolean>(false)
  const [customSubmissionErrors, setCustomSubmissionErrors] = useState<ErrorDetail[]>()
  const [absenceIds, setAbsenceIds] = useState<number[] | undefined>()
  const [activePage, setActivePage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [allLogs, setAllLogs] = useState<boolean>(false)
  const [logs, setLogs] = useState<NotificationRequestLog[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingStack, setIsLoadingStack] = useState<number[]>([])
  const [toRemoveStack, setToRemoveStack] = useState<number[]>([])

  const dispatch = useDispatch()

  const getLogs = useCallback(async () => {
    setIsLoading(true)
    if (allLogs) {
      await notificationLogsService
        .getLogsByQuery({} as NotificationLogsQuery)
        .then(response => {
          setLogs(response.notifications)
        })
        .catch(error => {
          dispatch(showErrorMessage(`Something went wrong: ${error.message}`))
        })
        .finally(() => {
          setIsLoading(false)
        })

      return
    }

    await notificationLogsService
      .getFailedLogs()
      .then(response => {
        setLogs(response.notifications)
      })
      .catch(error => {
        dispatch(showErrorMessage(`Something went wrong: ${error.message}`))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [allLogs, dispatch])

  const retryByQuery = useCallback(
    async (q: NotificationLogsQuery, thenCallback?: () => void, finallyCallback?: () => void) => {
      notificationLogsService
        .retryByQuery(q)
        .then(_ => {
          if (thenCallback) {
            thenCallback()
          }
        })
        .catch(error => {
          setIsLoading(false)
          dispatch(showErrorMessage(`Something went wrong: ${error.message}`))
        })
        .finally(() => {
          if (finallyCallback) {
            finallyCallback()
          }
        })
    },
    [dispatch]
  )

  const retryAll = useCallback(async () => {
    setIsLoading(true)
    notificationLogsService
      .retryAll()
      .then(_ => {
        setToRemoveStack(logs.map(x => x.notificationRequestLogId))
      })
      .catch(error => {
        dispatch(showErrorMessage(`Something went wrong: ${error.message}`))
      })
      .finally(() => {
        setIsLoading(false)
        dispatch(
          showSuccessMessage(
            'All notifications were re-submitted. Please come back after a few minutes and refresh the grid.'
          )
        )
      })
  }, [dispatch, logs])

  useEffect(() => {
    getLogs()
  }, [getLogs])

  useEffect(() => {
    if (toRemoveStack.length === 0) {
      return
    }

    const newLogs = logs.filter(log => !toRemoveStack.includes(log.notificationRequestLogId))
    setLogs(newLogs)
    setIsLoadingStack(isLoadingStack.filter(x => !toRemoveStack.includes(x)))
    setToRemoveStack([])
  }, [toRemoveStack])

  const buildResendButton = useCallback(
    (onClick: () => void, id: number) => {
      const style = {
        ...buttonStyleBase,
      }

      return (
        <div style={buttonDiv}>
          <Button
            variant="text"
            onClick={onClick}
            style={style}
            disabled={isLoadingStack.includes(id) || isLoading}
          >
            {isLoadingStack.includes(id) ? 'Loading...' : 'Resend'}
          </Button>
        </div>
      )
    },
    [isLoading, isLoadingStack]
  )

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Log ID',
        headerClassName: 'header',
        flex: 1,
        renderCell: cellValues => cellValues.value,
        sortable: true,
      },
      {
        field: 'applicationGuid',
        headerName: 'Application',
        headerClassName: 'header',
        flex: 1,
        renderCell: cellValues => cellValues.value,
        sortable: true,
      },
      {
        field: 'notificationDateTime',
        headerName: 'Date & Time',
        headerClassName: 'header',
        flex: 1,
        renderCell: cellValues => {
          const d = new Date(cellValues.value as Date)
          return `${format(d, 'dd/MM/yyyy')} ${format(d, 'HH:mm:SS')}`
        },
        sortable: true,
      },
      {
        field: 'notificationEventType',
        headerName: 'Event type',
        headerClassName: 'header',
        flex: 1,
        renderCell: cellValues => cellValues.value,
        sortable: true,
      },
      {
        field: 'notificationEventId',
        headerName: 'Event ID',
        headerClassName: 'header',
        flex: 1,
        renderCell: cellValues => cellValues.value,
        sortable: true,
      },
      {
        field: 'platformType',
        headerName: 'Platform Type',
        headerClassName: 'header',
        flex: 1,
        renderCell: cellValues => cellValues.value,
        sortable: true,
      },
      {
        field: 'templateType',
        headerName: 'Template Type',
        headerClassName: 'header',
        flex: 1,
        renderCell: cellValues => cellValues.value,
        sortable: true,
      },
      {
        field: 'onClick',
        headerName: 'Try Resend',
        headerClassName: 'header',
        flex: 1,
        renderCell: cellValues => {
          const onClick = cellValues?.value as () => void
          if (!onClick) {
            return 'N/A'
          }
          return buildResendButton(onClick, cellValues.row.id)
        },
        sortable: true,
      },
    ],
    [buildResendButton]
  )

  const rows = useMemo(
    () =>
      logs.map(log => {
        const onClickHandler = log.isSent
          ? undefined
          : () => {
              const query = {
                logIds: [log.notificationRequestLogId],
              } as NotificationLogsQuery
              const thenCall = () => {
                setToRemoveStack([...toRemoveStack, log.notificationRequestLogId])
              }
              const finallyCall = () => {
                setIsLoadingStack(isLoadingStack.filter(x => x !== log.notificationRequestLogId))
              }

              setIsLoadingStack([...isLoadingStack, log.notificationRequestLogId])
              retryByQuery(query, thenCall, finallyCall)
            }

        const mapped = {
          id: log.notificationRequestLogId,
          isSent: log.isSent,
          notificationEventId: log.notificationEventId,
          applicationGuid: getApplicationNameFrom(log.applicationGuid),
          notificationDateTime: log.notificationDateTime,
          notificationEventType: getEventTypeNameFrom(log.notificationEventType),
          platformType: getPlatformTypeNameFrom(log.platformType),
          templateType: getTemplateTypeNameFrom(log.templateType),
          onClick: onClickHandler,
        }

        return mapped
      }),
    [isLoadingStack, logs, retryByQuery, toRemoveStack]
  )

  const hasFailedLogs = useMemo(() => rows.some(x => !x.isSent), [rows])

  const getMainButtonLabel = useCallback(() => {
    if (isLoading) {
      return 'Loading...'
    }

    return rows.length !== 0 && hasFailedLogs ? 'Resend All Failed' : 'Nothing to resend'
  }, [hasFailedLogs, isLoading, rows.length])

  const disableSendAllButton = useCallback(
    () => isLoading || rows.length === 0 || !hasFailedLogs,
    [hasFailedLogs, isLoading, rows.length]
  )

  const submitAbsenceIds = useCallback(async () => {
    if (!absenceIds || absenceIds.length === 0 || isLoading) {
      return
    }

    setIsLoading(true)
    await hrDocumentsRegenerationService
      .customResendHrDocumentsForAbsenceIds({
        absenceIds,
      })
      .then(resp => {
        if (resp.resubmittedIds?.length === absenceIds.length && !resp.resubmissionErrors) {
          dispatch(showSuccessMessage('All notifications were re-submitted.'))
        } else {
          dispatch(showErrorMessage('Something went wrong!'))
          setCustomSubmissionErrors(resp.resubmissionErrors)
          setShowModalErrors(true)
        }
      })
      .catch(error => {
        dispatch(showErrorMessage(`Something went wrong: ${error.message}`))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [absenceIds, dispatch, isLoading])

  const decipherUserInput = useCallback((input: string) => {
    if (!input) {
      setAbsenceIds(undefined)
      return
    }

    const ids: number[] = []

    new Set(
      input
        .replace(/\D/g, ',')
        .split(',')
        .map(el => Number(el))
        .filter(el => !Number.isNaN(el) && el !== 0)
    ).forEach(val => {
      ids.push(val)
    })

    setAbsenceIds(ids)
  }, [])

  const modalTextField = useMemo(
    () => (
      <TextField
        id="non-working-days"
        label="paste IDs column from SQL"
        variant="outlined"
        fullWidth
        onChange={e => {
          decipherUserInput(e.target.value)
        }}
        placeholder="AbsenceId AbsenceID ..."
        disabled={false}
        error={false}
        InputLabelProps={{ shrink: true }}
      />
    ),
    [decipherUserInput]
  )

  const modalErrors = useCallback(() => {
    if (!customSubmissionErrors) {
      return
    }

    return (
      <Paper style={{ maxHeight: 250, overflow: 'auto' }}>
        <List>
          {customSubmissionErrors.map(x => (
            <Grid item xs={12}>
              <Paragraph style={{ marginTop: 10, marginBottom: 10 }}>
                {`${x.name} -> ${x.description}`}
              </Paragraph>
            </Grid>
          ))}
        </List>
      </Paper>
    )
  }, [customSubmissionErrors])

  const resubmitIdsButtonLabel = useCallback(
    (): string =>
      absenceIds && absenceIds.length > 0 ? `Resubmit ${absenceIds.length} IDs` : 'Resubmit',
    [absenceIds]
  )

  const modalOnClose = useCallback(() => {
    setShowModal(false)
    setAbsenceIds(undefined)
    setCustomSubmissionErrors(undefined)
  }, [])

  const modalOnCloseErrors = useCallback(() => {
    setShowModalErrors(false)
    setAbsenceIds(undefined)
    setCustomSubmissionErrors(undefined)
  }, [])

  return (
    <>
      <NotificationLogsHeader
        disabled={isLoading}
        totalRows={rows.length}
        failedRows={rows.filter(x => !x.isSent).length}
        allLogs={allLogs}
        onRefreshClick={async () => {
          setIsLoadingStack([])
          setToRemoveStack([])
          setLogs([])
          await getLogs()
        }}
        onAllLogsChange={() => {
          setAllLogs(!allLogs)
        }}
      />
      <Card>
        <DataGridPro
          page={activePage}
          getRowId={row => row.id}
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          rowsPerPageOptions={[10, 25, 50, 100]}
          pagination
          pageSize={pageSize}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          onPageChange={newPage => setActivePage(newPage)}
          rowHeight={50}
          autoHeight
          sx={gridSx}
          componentsProps={componentProps}
        />
        <div style={buttonDivStyle}>
          <Button
            variant="outlined"
            disabled={isLoading}
            style={submitButtonStyle}
            onClick={() => {
              setShowModal(true)
            }}
          >
            {isLoading ? 'Loading' : 'Custom Resend (IDs)'}
          </Button>
          <Button
            variant="outlined"
            disabled={disableSendAllButton() || showModal}
            style={submitButtonStyle}
            onClick={() => {
              retryAll()
            }}
          >
            {getMainButtonLabel()}
          </Button>
        </div>
      </Card>
      <Modal
        type="question"
        open={showModal}
        onClose={modalOnClose}
        onClick={() => {
          setIsLoading(true)
          submitAbsenceIds()
          modalOnClose()
        }}
        title="Resend HR Documents"
        message="Please paste in IDs of the absences to resend all relevant HR documents below:"
        buttonLabel={resubmitIdsButtonLabel()}
        dropdown={modalTextField}
        isDisabled={!absenceIds || absenceIds.length === 0}
      />
      <Modal
        type="clash"
        open={showModalErrors}
        onClose={modalOnCloseErrors}
        onClick={() => {
          modalOnCloseErrors()
        }}
        title="Errors!"
        message="Following errors were encountered:"
        buttonLabel="OK"
        dropdown={modalErrors()}
      />
    </>
  )
}

export default NotificationLogs
