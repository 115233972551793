import { Grid, Stack, Box, useTheme } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useDispatch, useSelector } from 'react-redux'
import { settingsService } from '../../services/settingsService'
import AccordionBasic from '../../shared/layout/AccordianBasic'
import AddRestriction from '../AddRestriction'
import ActionIcon from '../../shared/UI/ActionIcon'
import Button from '../../shared/UI/Button'
import { ApiHolidayRestrictions } from '../SettingsOptions/settingsTypes'
import {
  hideModal,
  SetShowModalPayload,
  showModalDialog,
} from '../../redux/reducers/appSettingsReducer'
import Modal from '../../shared/UI/Modal'
import { RootStore } from '../../redux/store'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import Drawer from '../../shared/UI/Drawer'
import { DepartmentThresholdsResponse } from '../../types/department-thresholds-response'
import { HolidayRestrictionResponse } from '../../types/holiday-restrictions-response'
import { DefaultThresholds } from '../../types/default-thresholds'
import UserErrorMessage from '../../utils/errorFilter'
import { BaseResponse } from '../../types/base-response'

type Props = {
  deptId: number
}

function SettingsRestrictions({ deptId }: Props) {
  const [apiThresholds, setApiThresholds] = useState<DepartmentThresholdsResponse>()
  const [apiRestrictions, setApiRestrictions] = useState<HolidayRestrictionResponse>()
  const [pageSize, setPageSize] = useState<number>(10)
  const [restrictionToDelete, setRestrictionToDelete] = useState<number>(-1)
  const [team, setTeam] = useState<string>('')
  const [openDrawer, setOpenDrawer] = useState(false)
  const [departmentTeamId, setDepartmentTeamId] = useState<number>(-1)
  const [rows, setRows] = useState<ApiHolidayRestrictions[]>([])
  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )
  const theme = useTheme()

  const isUserPermittedToEdit = useMemo(
    () => Boolean(userPermissions.find(x => x === 'EditRestrictions')),
    [userPermissions]
  )

  useEffect(() => {
    if (apiRestrictions) {
      setRows(apiRestrictions.holidayRestrictions.filter(e => e.teamName === team))
    }
    return () => {
      setRows([])
    }
  }, [team, apiRestrictions])

  const dispatch = useDispatch()

  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  const getData = useCallback(() => {
    settingsService.getThresholds(deptId).then(deptThresholds => setApiThresholds(deptThresholds))
    settingsService
      .getRestrictions(deptId)
      .then(deptRestrictions => setApiRestrictions(deptRestrictions))
  }, [deptId])

  useEffect(() => {
    if (deptId !== 0) {
      getData()
    }
  }, [deptId, getData])

  const showDeleteModal = useCallback(
    (rowId: number) => {
      setRestrictionToDelete(rowId)
      dispatch(
        showModalDialog({
          title: 'Are you sure?',
          message: `Are you sure you would like to delete this restriction?`,
          buttonLabel: 'Delete',
          type: 'question',
          showModal: true,
        })
      )
    },
    [dispatch]
  )

  const deleteRestriction = useCallback(() => {
    settingsService
      .deleteRestriction(restrictionToDelete)
      .then(e => {
        getData()
        dispatch(showSuccessMessage('Restriction deleted'))
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
    dispatch(hideModal())
  }, [dispatch, getData, restrictionToDelete])

  const columns: GridColDef[] = [
    {
      field: 'dateFrom',
      headerName: 'Date From',
      type: 'date',
      flex: 1,
      valueFormatter: params => new Date(params.value).toLocaleDateString(),
    },
    {
      field: 'dateTo',
      headerName: 'Date To',
      type: 'date',
      flex: 1,
      valueFormatter: params => new Date(params.value).toLocaleDateString(),
    },
    {
      field: 'holiday',
      headerName: 'Holiday',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: params => params.row.restrictHoliday && <CheckCircleIcon />,
    },
    {
      field: 'dayOff',
      headerName: 'Day Off',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: params => params.row.restrictDayOff && <CheckCircleIcon />,
    },
    {
      field: 'shift',
      headerName: 'Shift',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: params => params.row.restrictShift && <CheckCircleIcon />,
    },
    {
      field: 'lieuDay',
      headerName: 'Lieu Day',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: params => params.row.restrictLieuDay && <CheckCircleIcon />,
    },
    {
      field: 'wfh',
      headerName: 'Work From Home',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: params => params.row.restrictWFH && <CheckCircleIcon />,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 3,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'remove',
      headerName: '',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <ActionIcon onClick={e => showDeleteModal(params.row.restrictionId)}>
          <DeleteIcon />
        </ActionIcon>
      ),
      disableColumnMenu: true,
      sortable: false,
      hide: !isUserPermittedToEdit,
    },
  ]

  const closeDrawer = () => {
    setOpenDrawer(false)
  }

  return (
    <>
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
        }}
        onClick={() => deleteRestriction()}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
      />
      <Drawer
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        title="Add Restriction"
        showOptions={false}
      >
        <Box sx={{ p: 4 }}>
          <AddRestriction onClose={closeDrawer} refresh={getData} deptTeamId={departmentTeamId} />
        </Box>
      </Drawer>
      {deptId !== 0 &&
        apiThresholds?.defaultThresholds.map((e: DefaultThresholds) => (
          <AccordionBasic
            title={e.teamName}
            onClick={() => setTeam(team === e.teamName ? '' : e.teamName)}
            expanded={team === e.teamName}
            disableGutters
            key={e.defaultThresholdId}
            dataTestId={`Settings-Restrictions-${e.teamName}`}
          >
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <DataGrid
                  getRowId={row => row.restrictionId}
                  rows={rows}
                  autoHeight
                  getRowHeight={() => 'auto'}
                  density="standard"
                  columns={columns}
                  rowsPerPageOptions={[5, 10, 20]}
                  disableSelectionOnClick
                  pageSize={pageSize}
                  onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                  sx={{
                    '&& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
                      whiteSpace: 'pre-wrap',
                      paddingTop: '5px',
                    },
                    '&.MuiDataGrid-root': {
                      border: 'none !important ',
                      overflow: 'none',
                    },
                    '&& .MuiDataGrid-cell': {
                      paddingTop: '10px',
                      paddingBottom: '10px',
                    },
                    '& .header': {
                      backgroundColor: '#F4F5F7',
                    },
                  }}
                />
              </Grid>
              {isUserPermittedToEdit && (
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="flex-end">
                    <Button
                      label="Add new"
                      onClick={() => {
                        setDepartmentTeamId(e.departmentTeamId)
                        setOpenDrawer(true)
                      }}
                      dataTestId="Settings-Restrictions-AddNewBtn"
                    />
                  </Stack>
                </Grid>
              )}
            </Grid>
          </AccordionBasic>
        ))}
    </>
  )
}

export default SettingsRestrictions
