import { RequestType } from './request-type'
import { EmployeeDetails } from './employee-details'
import { ManualType } from './manual-type'
import { RequestAction } from './request-action'
import { RequestChange } from './request-change'
import { RequestDay, RequestDays, RequestDaysUpdate } from './request-day'
import { RequestStatus } from './request-status'
import { TimeSpan } from '../types/time-span'
import { DepartmentSetting } from '../types/department-setting'

export interface RequestFieldsModal {
  date: boolean
  time: boolean
  comments: boolean
}

export const requestFieldsDefaultState: RequestFieldsModal = {
  date: false,
  time: false,
  comments: false,
}

export type requestFieldsModelRestrictions = RequestFieldsModal & {
  shift: boolean
  dayOff: boolean
  holiday: boolean
  lieuDay: boolean
  wfh: boolean
}

export const requestFieldsStateRestrictions: requestFieldsModelRestrictions = {
  ...requestFieldsDefaultState,
  shift: false,
  dayOff: false,
  holiday: false,
  lieuDay: false,
  wfh: false,
}

export interface Clash {
  requestType: RequestType
  dates: Date[]
}

export interface BaseRequest {
  type: RequestType | string
  comments: string
  conflictOverride: boolean
}
export interface CreateMultiDayRequest extends BaseRequest {
  dateFrom?: string
  dateTo?: string
  days?: RequestDay[]
}

export interface CreateShiftRequest extends BaseRequest {
  dateFrom?: string
  shiftStartTime: string
}

export interface CreateDayOffRequest extends BaseRequest {
  dateFrom?: string
}

export interface RequestClashResponse {
  clashes: Clash[]
}

// DO NOT USE THIS MODEL
export interface Request {
  requestId?: number
  requestStatus?: RequestStatus
  callingEmployeeWindowsLogin?: string
  callingEmployeeId?: number
  requestTypeId?: RequestType
  manualRequestTypeId?: number
  hasErrors?: boolean
  errors?: Array<Error> | null
  podHostName?: string | null
  id?: number
  requestAction?: RequestAction
  actionedDate?: Date | null
  adjustmentHours?: number | null
  checked?: boolean
  dateFrom?: Date
  /**
   *
   * @type {Date}
   * @memberof Request
   */
  dateTo?: Date
  /**
   *
   * @type {Date}
   * @memberof Request
   */
  dateSubmitted?: Date
  /**
   *
   * @type {EmployeeDetails}
   * @memberof Request
   */
  employee?: EmployeeDetails
  actionedBy?: EmployeeDetails
  hasBeenRejected?: boolean
  isAction?: boolean
  isCancellation?: boolean
  isEditable?: boolean
  isQuery?: boolean
  manualType?: ManualType
  requesterComments?: string | null
  requestDays?: Array<RequestDay> | null
  reviewerComments?: string | null
  status?: RequestStatus
  previousStatus?: RequestStatus
  shiftStartTime?: TimeSpan
  shiftStartTimeText?: string | null
  type?: RequestType
  userName?: string | null
  calledByWindowsLogin?: string | null
  url?: string | null
  departmentSetting?: DepartmentSetting
  requestChange?: RequestChange
  changeHasCheckedDays?: boolean
  changeHasDays?: boolean
  hasCheckedDays?: boolean
  hasDays?: boolean
  isManualRequest?: boolean
  hasRequestChange?: boolean
}

export interface RequestDetailResponse {
  requestId: number
  dateFrom: Date
  dateTo: Date
  totalRequestedHours: number
  requesterComments: string
  reviewerComments: string
  requestDays: RequestDays[]
  requestDaysCr: RequestDays[]
  requestTypeId: number
  manualTypeId: number
  statusBanner: any
  summary: any
  requestStatusId: number
  actionedDateTime: Date
  actionedBy: string
  employeeId: number
  departmentId: number
}

export interface RequestExpectedReturn {
  nextShift: Date
}

export interface RequestDetailsUpdate {
  requestId: number
  requestStatusId: number
  requestTypeId: number
  manualTypeId: number
  employeeId: number
  applyChangesToRota: boolean
  departmentId: number
  reviewerComments: string
  requestDays: RequestDaysUpdate[]
  dateFrom: Date
  dateTo: Date
  requestActionId: number
  isCancellation: boolean
  hasBeenRejected: boolean
}
