
import { AbsenceOccurrence } from '../../../../types/absence'
import { LocationGroups } from '../../../../types/location-groups'
import { getScaDisabledFunctions } from './disabledScaButtonFactoryFunction'
import { createDocType, featureOnForLocation } from './documentFactoryFunctions'
import { getScaTextFunctions } from './locationAndFeatureFactoryFunctions'
import { ReasonType } from '../../../../api/absence/tempTypes/generalprops'

export const hasValidSca = (absence: AbsenceOccurrence) => absence.hasValidSca

export const doesNotHaveValidSca = (absence: AbsenceOccurrence) => !hasValidSca(absence)

export const getIsCoveredBySickNote = (absence: AbsenceOccurrence) => !absence.absenceDays.map(absDay => absDay.sickNoteAdded).includes(false)

export const isSickness = (absence: AbsenceOccurrence) => absence.absenceDays[0].absenceType.reasonType === ReasonType.SICKNESS

export const isNotSickness = (absence: AbsenceOccurrence) => !isSickness(absence)

export const isScaRequired = (absence: AbsenceOccurrence) => absence.scaRequired

const defaultGetScaFunction = getScaTextFunctions
  .find(x => x.location === LocationGroups.NONE)!

const defaultGetScaDisabledFunction = getScaDisabledFunctions
  .find(x => x.location === LocationGroups.NONE)!

export const getScaText = (absence: AbsenceOccurrence) => {
  const location = absence.employeeResponse.locationGroupName as LocationGroups
  const docName = createDocType(location)
  const featureOn = featureOnForLocation(location)
  const func = featureOn
    ? getScaTextFunctions.find(x => x.location === location)
      ?? defaultGetScaFunction
    : defaultGetScaFunction

  return func.getScaText(docName.docType, absence)
}

export const getScaDisabled = (absence: AbsenceOccurrence) => {
  const location = absence.employeeResponse.locationGroupName as LocationGroups
  const featureOn = featureOnForLocation(location)
  const func = featureOn
    ? getScaDisabledFunctions.find(x => x.location === location)
      ?? defaultGetScaDisabledFunction
    : defaultGetScaDisabledFunction

  return func.getScaDisabled(absence)
}