import { Grid, Stack } from '@mui/material'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { showErrorMessage, showSuccessMessage } from '../../../../redux/reducers/snackbarReducer'
import { RootStore, useAppDispatch } from '../../../../redux/store'
import { SelectOption } from '../../../../services/dashboardService'
import { manualRequestsService } from '../../../../services/myActionsService'
import AutocompleteList from '../../../../shared/UI/AutocompleteList'
import Button from '../../../../shared/UI/Button'
import { AbsenceOccurrence, AbsenceStatusUpdateRequest } from '../../../../types/absence'
import { BaseResponse } from '../../../../types/base-response'
import { getSelectValuesByType } from '../../../../utils/app-utils'
import UserErrorMessage from '../../../../utils/errorFilter'
import { DateRangeType } from '../../types'

interface Props extends DateRangeType {
  absence: AbsenceOccurrence
  onClose: () => void
}

const invalidStatuses = [
  'Awaiting Action',
  'Awaiting Further Details',
  'Awaiting RTW',
  'Awaiting SCA',
]

function CompleteAbsence({ absence, onClose }: Props) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const statuses = useMemo(
    () =>
      getSelectValuesByType('AbsenceStatus').filter(
        x => !invalidStatuses.find(y => y === x.displayValue)
      ),
    []
  )

  const defaultStatus =
    statuses.find(x => x.displayValue === absence.absenceStatus.name) ||
    statuses.find(x => x.displayValue === 'No Action Necessary')

  const [completeAbsenceType, setCompleteAbsenceType] = useState<SelectOption | undefined>(
    defaultStatus
  )

  const activeRoute = useSelector<RootStore, string>(
    (state: RootStore) => state.appSettings.activeRoute
  )

  const handleCompletion = () => {
    const absenceType = getSelectValuesByType('AbsenceStatus').find(
      x => x.value === completeAbsenceType?.value
    )

    if (absenceType === undefined) {
      return
    }

    const body: AbsenceStatusUpdateRequest = {
      absenceId: absence.id,
      status: absenceType.value,
    }
    manualRequestsService
      .completeAbsence(body)
      .then(result => {
        dispatch(showSuccessMessage('Absence marked as completed'))
        navigate(activeRoute || '/myactions')
        onClose()
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  return (
    <Grid container xl={12} rowSpacing={4} pr={4}>
      <Grid item xs={12}>
        <AutocompleteList
          id="select-action"
          label="Select Action"
          textField="displayValue"
          data={statuses}
          value={completeAbsenceType}
          onChange={(e, option) => {
            setCompleteAbsenceType(option)
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Stack direction="row" gap={4} justifyContent="flex-end">
          <Button label="Cancel" color="secondary" variant="outlined" onClick={onClose} />
          <Button label="Complete" onClick={handleCompletion} />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default CompleteAbsence
