import styled from 'styled-components'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Typography } from '@mui/material'
import { isMobilePortrait, isMobileDown, isMobileLandscapeOnly } from '../../../theme/deviceChecks'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 2rem;
  position: relative;

  ${isMobilePortrait()} {
    justify-content: center;
    align-items: center;
    padding-bottom: 24px;
  }
`

export const MoreOptionsIcon = styled(MoreVertIcon)`
  transform: rotate(90deg);
`

export const DrawerTypography = styled(Typography)`
${isMobileDown()} {
  &.MuiTypography-root {
    text-align: center
    font-size: 16px;
    font-weight: bold;
  }
}
`
