import { useEffect } from 'react'
import { ToggleButton, styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { ToggleButtonGroup } from '../../../shared/UI/ToggleButton'
import { BookingWeekdayProps, BookingWeekdays } from './types'
import { RootStore } from '../../../redux/store'
import { setBookingWizardWeekdaysSelectedState } from '../../../redux/reducers/deskBookingWizardReducer'
import { getDatesFromRange } from './bookingFloorplanLogic'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: '1px',
    '&.Mui-disabled': {
      border: 0,
    },
  },
}))

const days: BookingWeekdays[] = [
  { day: 'Mo', dayIdx: 1 },
  { day: 'Tu', dayIdx: 2 },
  { day: 'We', dayIdx: 3 },
  { day: 'Th', dayIdx: 4 },
  { day: 'Fr', dayIdx: 5 },
  { day: 'Sa', dayIdx: 6 },
  { day: 'Su', dayIdx: 0 },
]

const styleSelected = {
  border: '1px solid rgb(44, 41, 101)',
  backgroundColor: 'rgb(44, 41, 101)',
  color: '#fff',
}

const styleUnSelected = {
  border: '1px solid #747474',
  color: '#747474',
}

const styleDisabled = {
  border: '1px solid #E0E0E0',
  color: '#E0E0E0',
}

export default function BookingWeekdayPicker({
  disabled = false,
  onSelectDays,
  blockDates,
}: BookingWeekdayProps) {
  const { weekdaysSelected: wizardWeekdaysSelected } = useSelector(
    (state: RootStore) => state.deskBookingWizard
  )
  const dispatch = useDispatch()

  const datesToDisable = (d: BookingWeekdays) => {
    if (!blockDates[0] || !blockDates[1]) {
      return true
    }

    const dayList = getDatesFromRange(blockDates)
    return !dayList.some(s => s.date.getDay() === d?.dayIdx)
  }

  const handleDaysSelect = (event: React.MouseEvent<HTMLElement>, newDays: BookingWeekdays[]) => {
    const sorted = [...newDays].sort((a, b) => {
      if (a.dayIdx < b.dayIdx) {
        return -1
      }
      if (a.dayIdx > b.dayIdx) {
        return 1
      }
      return 0
    })
    dispatch(setBookingWizardWeekdaysSelectedState(sorted))
    onSelectDays?.(sorted)
  }

  useEffect(() => {
    dispatch(setBookingWizardWeekdaysSelectedState([]))
  }, [blockDates])

  return (
    <StyledToggleButtonGroup
      id="weekdaypicker"
      size="small"
      value={wizardWeekdaysSelected}
      onChange={handleDaysSelect}
      aria-label="text alignment"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        height: 'auto',
        padding: '1px',
      }}
    >
      {days.map(d => {

        const thisDisabled = disabled || datesToDisable(d)
        const thisSelected = wizardWeekdaysSelected.some(s => s === d) ? styleSelected : styleUnSelected

        return (
          <ToggleButton
            value={d}
            disabled={thisDisabled}
            aria-label="left aligned"
            sx={{
              '#weekdaypicker &': {
                backgroundColor: 'transparent',
                textTransform: 'unset',
                minWidth: '1px',
                padding: '0',
                margin: '0',
                height: '31px',
                width: '31px',
                boxSizing: 'border-box',
                borderRadius: '4px',
                transition: 'background-color 0.15s ease, color 0.15s ease',
                ...(thisDisabled ? styleDisabled : thisSelected),
              },
              '#weekdaypicker &:not(.Mui-selected):hover': {
                border: '1px solid #000',
              },
              '#weekdaypicker &.Mui-selected:hover': {
                backgroundColor: 'rgb(80 77 135)',
              },
            }}
          >
            {d.day}
          </ToggleButton>
        )
      })}
    </StyledToggleButtonGroup>
  )
}
