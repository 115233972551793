import { WallChartItem, WallChartQueryProps } from '../../types/wallchart'
import { WallChartSelectedTimeSpan } from './WallChartTimespanSelector/types'

export interface WallChartProps {
  /**
   * determines if the wallchart is being displayed in it's native component
   */
  isWallChartView?: boolean
  /**
   * Only used when not being displayed in native wallchart view
   */
  title?: string
  /**
   * Only used when not being displayed in native wallchart view
   */
  filterPropertiesOverride?: WallChartQueryProps
  /**
   * Only used when not being displayed in native wallchart view
   */
  timeSpanPropertiesOverride?: WallChartSelectedTimeSpan
}

export interface WallChartContainerProps {
  margin?: string | number | undefined
  onClick?: (e: HTMLElement) => void
  onMouseLeave?: (e: HTMLElement) => void
  title?: string
}

// eslint-disable-next-line no-shadow
export enum ThresholdLevels {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  NONE = 'NONE',
}

export interface ThresholdApiResponse {
  date: Date
  percentage: number
}

export interface ThresholdDates {
  date: Date
  threshold: ThresholdLevels
}

export interface WallChartItemProps extends WallChartItem {
  hours?: number
}

export interface WallChartItemsGrouped {
  userDisplayName: string
  employeeId: number
  items: WallChartItemProps[]
}
