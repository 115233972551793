import { FormControlLabel, Grid, Stack, useMediaQuery } from '@mui/material'
import React, { useState, useEffect } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  startOfMonth,
  getDay,
  getMonth,
  startOfISOWeek,
  subMonths,
  addMonths,
  addDays,
  subDays,
  getDaysInMonth,
  format,
  isWithinInterval,
} from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import theme from '../../../theme/theme'
import { IOSStyleSwitch } from '../../../shared/UI/IOSStyleSwitch/IOSStyleSwitch'
import { ToggleButton, ToggleButtonGroup } from '../../../shared/UI/ToggleButton'
import {
  WallChartDay,
  WallChartWeek,
  WallChartMonth,
  WallChartSegments,
  WallChartTimespans,
  WallChartSelectedTimeSpan,
  WallChartSelectedTimespanProps,
} from './types'
import Paragraph from '../../../shared/UI/Paragraph'
import { MONTHS, WEEKDAYS } from '../../../utils/constants'
import { RootStore } from '../../../redux/store'
import {
  setLastSelectedDayTimespan,
  setLastSelectedMonthTimespan,
  setLastSelectedWeekTimespan,
  setSelectedTimespan,
  setWallChartSelected,
} from '../../../redux/reducers/wallChartReducer'
import { formatDateWithTimeZone } from '../../../utils/date-utils'

export function WallChartTimespanSelector({ directReportEnabled }: WallChartSelectedTimespanProps) {
  const dispatch = useDispatch()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [timespanStartDate, setTimespanStartDate] = useState<Date>(
    formatDateWithTimeZone(new Date())
  )

  const { selectedTimeSpan, lastSelectedDay, lastSelectedMonth, lastSelectedWeek, selectedParams } =
    useSelector((state: RootStore) => state.wallChart)

  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  const { calendarDetailsResponse } = useSelector((state: RootStore) => state.appSettings)

  useEffect(() => {
    setTimespanStartDate(selectedTimeSpan.startDate)
  }, [selectedTimeSpan])

  const getTimeSpanSegment = (
    timespan: WallChartTimespans,
    newDate?: Date
  ): WallChartDay | WallChartWeek | WallChartMonth => {
    if (timespan === WallChartTimespans.WEEK) {
      return {
        masterSegments: WallChartSegments.MASTER_SEGMENT_WEEK,
        divisions: WallChartSegments.SEGMENT_DIV_WEEK,
        timespan: WallChartTimespans.WEEK,
      }
    }
    if (timespan === WallChartTimespans.MONTH && newDate) {
      return {
        masterSegments: getDaysInMonth(newDate),
        divisions: WallChartSegments.SEGMENT_DIV_MONTH,
        timespan: WallChartTimespans.MONTH,
      }
    }
    return {
      masterSegments: WallChartSegments.MASTER_SEGMENT_DAY,
      divisions: WallChartSegments.SEGMENT_DIV_DAY,
      timespan: WallChartTimespans.DAY,
    }
  }

  const setLastSelected = (
    thisRangeType: string,
    timespan: WallChartSelectedTimeSpan | undefined
  ) => {
    switch (thisRangeType) {
      case WallChartTimespans.DAY:
        dispatch(setLastSelectedDayTimespan(timespan))
        break
      case WallChartTimespans.WEEK:
        dispatch(setLastSelectedWeekTimespan(timespan))
        break
      case WallChartTimespans.MONTH:
        dispatch(setLastSelectedMonthTimespan(timespan))
        break
      default:
        break
    }
  }

  const handleRangeChange = (
    _event: React.MouseEvent<HTMLElement> | undefined,
    newRangeType: WallChartTimespans
  ) => {
    if (!newRangeType) {
      return
    }
    const today = new Date()
    today.setFullYear(selectedTimeSpan.startDate.getFullYear())
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)
    let timeSegment
    let timespanEndDate
    let title
    let newDate = timespanStartDate
    const isCurrentYear = new Date().getFullYear() === selectedTimeSpan.startDate.getFullYear()
    if (newRangeType === WallChartTimespans.DAY) {
      if (lastSelectedDay && newRangeType !== lastSelectedDay.rangeType) {
        return dispatch(setSelectedTimespan(lastSelectedDay))
      }
      if (today.getMonth() === selectedTimeSpan.startDate.getMonth() && isCurrentYear) {
        newDate = lastSelectedDay ? lastSelectedDay!.startDate : today
      } else if (lastSelectedWeek) {
        newDate = startOfISOWeek(lastSelectedWeek.startDate)
      } else {
        newDate = startOfMonth(selectedTimeSpan.startDate)
      }
      timespanEndDate = newDate
      timeSegment = getTimeSpanSegment(WallChartTimespans.DAY)
      title = WEEKDAYS[getDay(newDate)]
    } else if (newRangeType === WallChartTimespans.WEEK) {
      if (lastSelectedWeek && newRangeType !== lastSelectedWeek.rangeType) {
        // A previous week view was selected, so restore that
        return dispatch(setSelectedTimespan(lastSelectedWeek))
      }
      if (today.getMonth() === selectedTimeSpan.startDate.getMonth() && isCurrentYear) {
        newDate = startOfISOWeek(lastSelectedDay ? lastSelectedDay!.startDate : today)
      } else if (selectedTimeSpan.startDate.getFullYear() !== new Date().getFullYear()) {
        newDate = startOfISOWeek(newDate)
      } else {
        newDate = startOfISOWeek(startOfMonth(selectedTimeSpan.startDate))
      }
      timespanEndDate = addDays(newDate, 6)
      timeSegment = getTimeSpanSegment(WallChartTimespans.WEEK)
      title = WEEKDAYS[getDay(newDate)]
    } else {
      if (lastSelectedMonth && newRangeType !== lastSelectedMonth.rangeType) {
        // A previous month view was selected, so restore that
        return dispatch(setSelectedTimespan(lastSelectedMonth))
      }
      if (lastSelectedMonth && !isCurrentYear) {
        newDate = lastSelectedMonth.startDate
      } else {
        newDate = startOfMonth(timespanStartDate)
      }
      timespanEndDate = addDays(newDate, getDaysInMonth(newDate) - 1)
      timeSegment = getTimeSpanSegment(WallChartTimespans.MONTH, newDate)
      title = MONTHS[getMonth(newDate)]
    }
    setTimespanStartDate(newDate)
    const timespan = {
      title,
      directReport: selectedParams.directReport,
      rangeType: newRangeType,
      startDate: newDate,
      endDate: timespanEndDate,
      segments: timeSegment,
    }
    dispatch(setSelectedTimespan(timespan))
    setLastSelected(newRangeType, timespan)
  }

  const handleViewAdvanceClick = (
    _event: React.MouseEvent<HTMLElement>,
    newViewSelected: string
  ) => {
    if (!newViewSelected) {
      return
    }
    let timeSegment
    let timespanEndDate
    let title
    let newDate = timespanStartDate
    if (selectedTimeSpan.rangeType === WallChartTimespans.DAY) {
      newDate =
        newViewSelected === 'Inc' ? addDays(timespanStartDate, 1) : subDays(timespanStartDate, 1)
      timespanEndDate = newDate
      timeSegment = getTimeSpanSegment(WallChartTimespans.DAY)
      title = WEEKDAYS[getDay(newDate)]
      setLastSelected(WallChartTimespans.WEEK, undefined)
    } else if (selectedTimeSpan.rangeType === WallChartTimespans.WEEK) {
      newDate =
        newViewSelected === 'Inc' ? addDays(timespanStartDate, 7) : subDays(timespanStartDate, 7)
      timespanEndDate = addDays(newDate, 6)
      timeSegment = getTimeSpanSegment(WallChartTimespans.WEEK)
      title = WEEKDAYS[getDay(newDate)]
    } else {
      // MONTH
      const beginningOfMonth = startOfMonth(timespanStartDate)
      newDate =
        newViewSelected === 'Inc' ? addMonths(beginningOfMonth, 1) : subMonths(beginningOfMonth, 1)
      timespanEndDate = addDays(newDate, getDaysInMonth(newDate) - 1)
      timeSegment = getTimeSpanSegment(WallChartTimespans.MONTH, newDate)
      title = MONTHS[getMonth(newDate)]
      setLastSelected(WallChartTimespans.WEEK, undefined)
    }
    setTimespanStartDate(newDate)
    const timespan = {
      title,
      rangeType: selectedTimeSpan.rangeType,
      directReport: selectedParams.directReport,
      startDate: newDate,
      endDate: timespanEndDate,
      segments: timeSegment,
    }
    dispatch(setSelectedTimespan(timespan))
    setLastSelected(selectedTimeSpan.rangeType, timespan)
    if (newDate.getFullYear() !== selectedTimeSpan.startDate.getFullYear()) {
      dispatch(
        setWallChartSelected({
          ...selectedParams,
          selectedYear: {
            value: newDate.getFullYear(),
            displayValue: String(newDate.getFullYear()),
          },
        })
      )
    }
  }

  const mediaQueryWidth = useMediaQuery(theme.breakpoints.down('xl'))

  const checkValidYearLimit = (type: string) => {
    if (!calendarDetailsResponse) {
      return
    }

    const lastBookableDate = new Date(
      calendarDetailsResponse.calendarDetails[
        calendarDetailsResponse.calendarDetails.length - 1
      ].endDate
    )

    const selectedEndDate = new Date(selectedTimeSpan.endDate)

    const selectedStartDate = new Date(selectedTimeSpan.startDate)

    switch (type) {
      case 'inc':
        if (
          isWithinInterval(lastBookableDate, {
            start: selectedStartDate,
            end: new Date(format(addDays(selectedEndDate, 1), 'yyyy-MM-dd')),
          })
        ) {
          return true
        }
        return (
          format(new Date(selectedTimeSpan.endDate), `yyyy-MM-dd`) >=
          format(
            new Date(
              calendarDetailsResponse.calendarDetails[
                calendarDetailsResponse.calendarDetails.length - 1
              ].endDate
            ),
            `yyyy-MM-dd`
          )
        )
      case 'dec':
        return (
          format(new Date(selectedTimeSpan.startDate), `yyyy-MM-dd`) <=
          format(new Date(calendarDetailsResponse.calendarDetails[0].startDate), `yyyy-MM-dd`)
        )
      default:
        return false
    }
  }

  return (
    <Grid container mt={3}>
      <Grid item xs={12} lg={4} display="flex" alignItems="center">
        <Stack direction="row" justifyContent="flex-start" alignItems="center" columnGap={2}>
          <ToggleButtonGroup
            value={null}
            exclusive
            bgColor={theme.palette.primary.main}
            onChange={handleViewAdvanceClick}
            disabled={!selectedTimeSpan}
          >
            <ToggleButton value="Dec" fixedColor="white" disabled={checkValidYearLimit('dec')}>
              <ChevronLeftIcon />
            </ToggleButton>
            <ToggleButton value="Inc" fixedColor="white" disabled={checkValidYearLimit('inc')}>
              <ChevronRightIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <Paragraph weight="bold">
            {selectedTimeSpan.title}
            {selectedTimeSpan.segments.timespan === WallChartTimespans.WEEK && ' WC:'}
          </Paragraph>
          {selectedTimeSpan.segments.timespan === WallChartTimespans.DAY && (
            <Paragraph>{format(timespanStartDate, 'dd/MM/yyyy')}</Paragraph>
          )}
          {selectedTimeSpan.segments.timespan === WallChartTimespans.WEEK && (
            <Paragraph>{format(timespanStartDate, 'dd/MM/yyyy')}</Paragraph>
          )}
        </Stack>
      </Grid>
      <Grid
        item
        xs={6}
        lg={4}
        display="flex"
        alignItems="center"
        justifyContent={mediaQueryWidth ? 'unset' : 'center'}
      >
        <ToggleButtonGroup
          value={selectedTimeSpan.rangeType}
          exclusive
          bgColor={theme.palette.primary.main}
          onChange={handleRangeChange}
          disabled={!selectedTimeSpan}
          minWidth={mediaQueryWidth ? '75px' : '112px'}
        >
          <ToggleButton value={WallChartTimespans.DAY} data-testid="WallChart-DayFilter">
            {WallChartTimespans.DAY}
          </ToggleButton>
          <ToggleButton value={WallChartTimespans.WEEK} data-testid="WallChart-WeekFilter">
            {WallChartTimespans.WEEK}
          </ToggleButton>
          <ToggleButton value={WallChartTimespans.MONTH} data-testid="WallChart-MonthFilter">
            {WallChartTimespans.MONTH}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {userPermissions.includes('ViewManagerDirectReport') && (
        <Grid item xs={6} lg={4} display="flex" justifyContent="flex-end">
          <FormControlLabel
            style={{
              gap: '10px',
              margin: '5px',
            }}
            control={
              <IOSStyleSwitch
                disabled={!directReportEnabled}
                checked={selectedParams.directReport}
                data-testid="WallChart-DirectReports"
              />
            }
            label="Direct Reports"
            labelPlacement="start"
            onClick={() => {
              if (!directReportEnabled) {
                return
              }
              dispatch(
                setWallChartSelected({
                  ...selectedParams,
                  directReport: !selectedParams.directReport,
                })
              )
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}
