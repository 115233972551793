import { StatusResponse, SubmitStatus } from '../../models/enhancement'

const approvarStatusList = [
  SubmitStatus.APPROVED,
  SubmitStatus.DECLINED,
  SubmitStatus.AMENDED,
  SubmitStatus.CANCELLED,
]
export const findLatestCommentByStatus = (
  statusHistory: StatusResponse[],
  submitStatus: SubmitStatus
) =>
  [...statusHistory].reverse().find(status => status.submitStatus === submitStatus)?.comments || ''

export const latestApproverComment = (statusHistory: StatusResponse[]) => {
  if (statusHistory.length < 1) return ''
  const lastestStatus = statusHistory[statusHistory.length - 1]
  if (approvarStatusList.includes(lastestStatus.submitStatus) && lastestStatus.comments)
    return lastestStatus.comments
  return ''
}
