import { format } from 'date-fns'
import { Collapse, Grid, Stack } from '@mui/material'
import styled from 'styled-components'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStore, useAppDispatch } from '../../../../redux/store'
import HolidaysRequestsCountSearchForm from './HolidaysRequestsCountSearchForm'
import { Search, HolidaysRequestsCountCSV, Props } from './types'
import HolidaysRequestsCountGrid from './HolidaysRequestsCountGrid'
import LoadingIndicator from '../../../../shared/UI/LoadingIndicator'
import { BaseResponse } from '../../../../types/base-response'
import { showErrorMessage } from '../../../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../../../utils/errorFilter'
import Tab from '../../../../shared/UI/Tab'
import { ssrsHolidayReportService } from '../../../../services/ssrsHolidayReportService'
import {
  HolidayRequestsCountItem,
  HolidayRequestsCountResponse,
} from '../../../../types/holiday-requests-count-response'
import HolidaysRequestsCountDetail from './HolidaysRequestsCountDetail'
import { DrawerComponentProps } from '../../types'
import Drawer from '../../../../shared/UI/Drawer'
import RefreshButton from '../../../../shared/UI/RefreshButton'
import DownloadButton from '../../../../shared/UI/DownloadButton'
import FilterButton from '../../../../shared/UI/FilterButton'

export const LoadingIndicatorContainer = styled.div`
  padding-top: 70px;
  padding-bottom: 70px;
`

const headers: string[] = [
  'DisplayName',
  'JobTitle',
  'Team',
  'DaysOffRequests',
  'DaysOffApprovals',
  'DaysOffDeclines',
  'ShiftRequests',
  'ShiftApprovals',
  'ShiftDeclines',
  'TotalRequests',
  'TotalApprovals',
  'TotalDeclines',
]

function HolidaysRequestsCountSearch({ userDepartments }: Props) {
  const dispatch = useAppDispatch()
  const [resetForm, setResetForm] = useState(false)
  const [showHeaderButtons, setShowHeaderButtons] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(true)
  const [showGrid, setShowGrid] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [finData, setFinData] = useState<HolidayRequestsCountItem[]>([])
  const [calData, setCalData] = useState<HolidayRequestsCountItem[]>([])
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const handleClose = useCallback(() => {
    setOpenDrawer(false)
  }, [])

  const [drawerComponent, setDrawerComponent] = useState<DrawerComponentProps>({
    title: '',
    component: <HolidaysRequestsCountDetail data={null} onClose={handleClose} />,
  })

  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)

  const handleSearch = useCallback(
    (search: Search) => {
      setIsLoading(true)
      setShowGrid(false)

      setTimeout(() => {
        setShowSearch(false)
      }, 500)

      ssrsHolidayReportService
        .getHolidaysRequestsCountReports(
          search.departmentIds!,
          search.fromDateRangeFrom !== ''
            ? search.fromDateRangeFrom!
            : format(new Date(currentEntitlementPeriodResponse!.startDate), 'yyyy-MM-dd'),
          search.fromDateRangeTo !== ''
            ? search.fromDateRangeTo!
            : format(new Date(currentEntitlementPeriodResponse!.endDate), 'yyyy-MM-dd')
        )
        .then((res: HolidayRequestsCountResponse) => {
          const sheet1: HolidayRequestsCountItem[] = res.rows.filter(p => p.sheet === 1)
          const sheet2: HolidayRequestsCountItem[] = res.rows.filter(p => p.sheet === 2)
          setCalData(sheet2)
          setFinData(sheet1)
          setShowHeaderButtons(true)
          setShowGrid(true)
          setIsLoading(false)
        })
        .catch(err => {
          setShowHeaderButtons(true)
          setShowGrid(false)
          setIsLoading(false)
          setTimeout(() => {
            setShowSearch(true)
          }, 500)

          const response: BaseResponse = err.response.data
          if (response.errors) {
            response.errors.forEach(error => {
              dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
            })
          } else {
            dispatch(showErrorMessage(<UserErrorMessage name="CurrentlyUnavailable" />))
          }
        })
    },
    [currentEntitlementPeriodResponse, dispatch]
  )

  const handleFilterClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setShowSearch(!showSearch)
    },
    [showSearch]
  )

  const handleRefreshClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setResetForm(!resetForm)
      setShowSearch(true)
      setShowHeaderButtons(false)
      setShowGrid(false)
    },
    [resetForm]
  )

  const handleTabChange = useCallback((index: number) => {
    setSelectedTab(index)
  }, [])

  const handleViewDetail = useCallback(
    (row: HolidayRequestsCountItem) => {
      setDrawerComponent({
        ...drawerComponent,
        title: `${row.displayName}`,
        component: <HolidaysRequestsCountDetail data={row} onClose={handleClose} />,
      })

      setOpenDrawer(true)
    },
    [drawerComponent, handleClose]
  )

  const makeCsv = useCallback(
    async (filename: string) => {
      const data: HolidayRequestsCountItem[] = selectedTab === 0 ? finData : calData

      const csvData: HolidaysRequestsCountCSV[] = data.map((row: HolidayRequestsCountItem) => {
        const item: HolidaysRequestsCountCSV = {
          DisplayName: row.displayName,
          JobTitle: row.jobTitle,
          Team: row.team,
          DaysOffRequests: row.daysOffRequests,
          DaysOffApprovals: row.daysOffApprovals,
          DaysOffDeclines: row.daysOffDeclines,
          ShiftRequests: row.shiftRequests,
          ShiftApprovals: row.shiftApprovals,
          ShiftDeclines: row.shiftDeclines,
          TotalRequests: row.totalRequests,
          TotalApprovals: row.totalApprovals,
          TotalDeclines: row.totalDeclines,
        }
        return item
      })

      const keys: string[] = Object.keys(csvData[0])

      const csvContent: string = csvData
        .map((row: any) =>
          keys
            .map(k => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k]

              cell =
                cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""')

              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`
              }
              return cell
            })
            .join(',')
        )
        .join('\n')

      const csvHeaders: string = headers.join(',')

      const blob = new Blob([`${csvHeaders}\n`, csvContent], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')

      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    [calData, finData, selectedTab]
  )

  const handleDownloadClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const file: string =
        selectedTab === 0 ? 'HolidaysRequestCountReportFY.csv' : 'HolidaysRequestCountReportCY.csv'

      makeCsv(file)
    },
    [makeCsv, selectedTab]
  )

  return (
    <>
      {showHeaderButtons && (
        <Grid container style={{ border: '0px red solid' }}>
          <Stack
            direction="row"
            style={{ width: '100%' }}
            spacing={2}
            display="flex"
            justifyContent="flex-end"
          >
            <FilterButton onClick={handleFilterClick} title="Change Filters" />
            {((selectedTab === 0 && finData && finData.length >= 1) ||
              (selectedTab === 1 && calData && calData.length >= 1)) && (
              <DownloadButton onClick={handleDownloadClick} title="Download Report" />
            )}
            <RefreshButton onClick={handleRefreshClick} title="Refresh" />
          </Stack>
        </Grid>
      )}
      <Collapse orientation="vertical" in={showSearch}>
        <HolidaysRequestsCountSearchForm
          resetForm={resetForm}
          userDepartments={userDepartments}
          onSearch={handleSearch}
        />
      </Collapse>
      {showGrid ? (
        <>
          {!isLoading && (
            <Tab
              onChange={handleTabChange}
              titleAndContent={[
                {
                  label: 'Financial Year',
                  tabContext: (
                    <Grid item xs={12}>
                      {!isLoading && showGrid && (
                        <HolidaysRequestsCountGrid data={finData} onViewDetail={handleViewDetail} />
                      )}
                    </Grid>
                  ),
                  disabled: false,
                },
                {
                  label: 'Calendar Year',
                  tabContext: (
                    <Grid item xs={12}>
                      {!isLoading && showGrid && (
                        <HolidaysRequestsCountGrid data={calData} onViewDetail={handleViewDetail} />
                      )}
                    </Grid>
                  ),
                  disabled: false,
                },
              ]}
            />
          )}
        </>
      ) : (
        <>
          {isLoading && (
            <LoadingIndicatorContainer>
              <LoadingIndicator show={isLoading} />
            </LoadingIndicatorContainer>
          )}
        </>
      )}
      <Drawer
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        title={drawerComponent?.title || ''}
        showOptions={false}
      >
        {drawerComponent?.component}
      </Drawer>
    </>
  )
}

export default HolidaysRequestsCountSearch
