import { MoreTime } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import { useMemo } from 'react'
import LocalHospitalIcon from '@mui/icons-material/LocalHospitalOutlined'
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartmentOutlined'
import FullComponentLoadingIcon from '../../shared/UI/LoadingIndicator/FullComponentLoadingIcon'
import ServiceHours from '../ServiceHours'
import { AllocationExtrasType } from '../../types/allocation-extras-type'
import { MyAllocationImageContainer, ServiceHoursGrid } from '../ServiceHours/components'
import MyAllocationImage from '../../assets/MyExtras/MyAllocationImage.svg'
import MyAllocationBg from '../../assets/MyExtras/MyAllocationBg.svg'
import { AllocationExtrasCard, MyExtrasHeadingContainer } from './components'

interface Props {
  serviceHours: AllocationExtrasType
  isLoading: boolean
}

function AllocationExtras({ serviceHours, isLoading }: Props) {
  const hasFireMarshallHours = useMemo(() => Boolean(serviceHours.fireMarshall), [serviceHours])
  const hasFirstAidHours = useMemo(() => Boolean(serviceHours.firstAid), [serviceHours])

  const AllocationClass = useMemo(() => {
    let count = 1

    if (hasFireMarshallHours) {
      count += 1
    }

    if (hasFirstAidHours) {
      count += 1
    }

    return `allocation${count}`
  }, [hasFireMarshallHours, hasFirstAidHours])

  return (
    <AllocationExtrasCard
      className={AllocationClass}
      sx={{ backgroundImage: `url(${MyAllocationBg})`, backgroundColor: '#E9F9F9' }}
    >
      <FullComponentLoadingIcon loading={isLoading} bgColor="transparent">
        <Grid container spacing={4} mt={0} height="100%" alignContent="space-between">
          <Grid item xs={12}>
            <MyExtrasHeadingContainer>
              <Typography data-testid="my-allowance" variant="h4">
                My Allowance Extras!
              </Typography>
              <Typography variant="subtitle2">Your additional hours are itemised here</Typography>
            </MyExtrasHeadingContainer>
          </Grid>

          <ServiceHoursGrid item xs={12}>
            <MyAllocationImageContainer>
              <img alt="My Allocation Extras" src={MyAllocationImage} />
            </MyAllocationImageContainer>
            {serviceHours.service !== 0 && (
              <ServiceHours
                serviceHoursProps={serviceHours.service}
                titleProps="Additional Service"
                icon={<MoreTime />}
                color="#00D6CB"
              />
            )}
            {hasFirstAidHours && (
              <ServiceHours
                serviceHoursProps={serviceHours.firstAid}
                titleProps="First Aider"
                icon={<LocalHospitalIcon />}
                color="#567CC1"
              />
            )}
            {hasFireMarshallHours && (
              <ServiceHours
                serviceHoursProps={serviceHours.fireMarshall}
                titleProps="Fire Marshal"
                icon={<LocalFireDepartmentIcon />}
                color="#FE7D5C"
              />
            )}
          </ServiceHoursGrid>
        </Grid>
      </FullComponentLoadingIcon>
    </AllocationExtrasCard>
  )
}

export default AllocationExtras
