import { LocationGroups } from "../../../../types/location-groups"
import { AbsenceOccurrence } from "../../../../types/absence"
import { getIsCoveredBySickNote, hasValidSca, isNotSickness, isScaRequired } from "./actionsHelpers"
import { featureOnForLocation } from "./documentFactoryFunctions"

interface GetScaDisabledFunction {
    location: LocationGroups
    getScaDisabled: (absence: AbsenceOccurrence) => boolean
}

export const getScaDisabledFunctions: GetScaDisabledFunction[] = [
    {
        location: LocationGroups.NONE,
        getScaDisabled: (absence: AbsenceOccurrence) => {

            if (isNotSickness(absence)) {
                return true
            }

            if (hasValidSca(absence)) {
                return false
            }

            if (getIsCoveredBySickNote(absence)) {
                return true
            }

            return isScaRequired(absence)
        }
    },
    {
        location: LocationGroups.UK,
        getScaDisabled: (absence: AbsenceOccurrence) => {
            if (isNotSickness(absence)) {
                return true
            }

            const coveredBySickNoteOrNoValidSca = getIsCoveredBySickNote(absence) || !absence.hasValidSca
            const isUkAndUktaIsOn =
                absence.employeeResponse.locationGroupName === LocationGroups.UK && featureOnForLocation(absence.employeeResponse.locationGroupName as LocationGroups)
            const UktaActiveAndNoValidSca = featureOnForLocation(absence.employeeResponse.locationGroupName as LocationGroups) && !absence.hasValidSca


            if (UktaActiveAndNoValidSca || (!isUkAndUktaIsOn && coveredBySickNoteOrNoValidSca)) {
                return true
            }

            return (!isScaRequired(absence) || (absence.absenceStatus.name === 'Awaiting SCA' && !featureOnForLocation(absence.employeeResponse.locationGroupName as LocationGroups)))
        }
    }
]