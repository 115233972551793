import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { oktaAuth } from '../App'
import { BaseResponse } from '../types/base-response'
import { is500Error } from '../utils/app-utils'
import oktaService from './oktaService'
import localStorageService from './localStorageService'

const getBaseApiUrl = () => {
  const currentLocation = window.location.hostname

  switch (currentLocation) {
    case 'localhost': {
      return 'https://planner365.bss.dev.pub.gcp/api/'
    }
    default: {
      return `https://${window.location.hostname}/api`
    }
  }
}

const getToken = async () => {
  const token = oktaAuth.getAccessToken()

  if (!token) {
    await oktaService.resyncOctaWithRedirect()
    return Promise.reject(oktaService.tokenValidationError())
  }

  localStorageService.setIsOktaRedirecting(false)
  return token
}

const httpServiceInstance = axios.create({ baseURL: getBaseApiUrl() })

httpServiceInstance.interceptors.request.use(
  async config => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `Bearer ${await getToken()}`,
      Accept: 'application/json',
      'x-api-version': 1.0,
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

httpServiceInstance.interceptors.response.use(
  resp => resp,
  error => {
    const baseResponse = error?.response?.data as BaseResponse
    if (!is500Error(baseResponse)) {
      return Promise.reject(error)
    }
    // eslint-disable-next-line no-param-reassign
    error.response.data.errors = [
      {
        name: baseResponse.title ?? '',
        description: baseResponse.detail ?? '',
      },
    ]
    return Promise.reject(error)
  }
)

const response = <T>(axiosResponse: AxiosResponse<T>) => axiosResponse.data

export const httpServiceV1 = {
  get: <T>(url: string, config?: AxiosRequestConfig) =>
    httpServiceInstance.get<T>(url, config).then(response),
  post: <T>(url: string, body?: unknown, config?: AxiosRequestConfig) =>
    httpServiceInstance.post<T>(url, body, config).then(response),
  put: <T>(url: string, body: unknown, config?: AxiosRequestConfig) =>
    httpServiceInstance.put<T>(url, body, config).then(response),
  patch: <T>(url: string, body: unknown, config?: AxiosRequestConfig) =>
    httpServiceInstance.patch<T>(url, body, config).then(response),
  del: (url: string, config?: AxiosRequestConfig) =>
    httpServiceInstance.delete(url, config).then(response),
  delWithBody: (url: string, body: unknown) =>
    httpServiceInstance.delete(url, { data: body }).then(response),
}
