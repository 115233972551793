import { useState, useEffect } from 'react'
import { PayPeriodResponse } from '../../models/enhancement'
import { enhancementService } from '../../services/enhancementService'
import { useDefaultErrorHandler } from './useDefaultErrorHandler'

export const usePayPeriod = () => {
  const defaultErrorHandler = useDefaultErrorHandler()

  const [payPeriod, setPayPeriod] = useState<PayPeriodResponse>()

  /**
   *get the pay period
   */
  useEffect(() => {
    const getCurrentPayPeriod = async () => {
      enhancementService
        .getCurrentPayPeriod()
        .then(payPeriodResponse => {
          setPayPeriod(payPeriodResponse)
        })
        .catch(err => {
          defaultErrorHandler(err)
        })
    }
    getCurrentPayPeriod()
  }, [])

  return payPeriod
}
