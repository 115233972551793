import { Avatar as MuiAvatar } from '@mui/material/'

type Props = {
  employee: string
}

const hasWhiteSpace = (s: string) => /\s/g.test(s)
const splitName = (name: string) => `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  return color
}

const stringAvatar = (name: string) => ({
  sx: {
    bgcolor: stringToColor(name),
    lineHeight: '40px',
  },
  children: hasWhiteSpace(name) ? splitName(name) : name,
})

export default function Avatar({ employee }: Props) {
  return <MuiAvatar {...stringAvatar(employee)} />
}
