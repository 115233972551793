import { AnyAction } from 'redux'
import { Dispatch, useCallback, useEffect, useState } from 'react'
import { SellHoursType } from '../../types/sell-hours-type'
import { MyEntitlementResponse } from '../../types/my-entitlement-response'
import { showErrorMessage } from '../../redux/reducers/snackbarReducer'

export interface ShouldPreventSubmitResult {
  shouldPreventSend: boolean
  requestedHours: number | null
}

function useSellRequestValidation(
  hours: SellHoursType | undefined,
  myEntitlement: MyEntitlementResponse | undefined,
  requestedSellHours: number,
  dispatch: Dispatch<AnyAction>
) {
  const [maxToSell, setMaxToSell] = useState<number>(0)
  const [shownOnce, setShownOnce] = useState<boolean>(false)

  const formatNumber = useCallback((num: number) => {
    const decimalPlaces = Number.isInteger(num) ? 0 : 1
    return num.toFixed(decimalPlaces)
  }, [])

  useEffect(() => {
    if (!myEntitlement) {
      return
    }

    const leftLessThanMax = myEntitlement.maxHolidaysAvailableToSell > myEntitlement.remainingHours
    const requestedAndSold = requestedSellHours + (hours?.soldHours ?? 0)

    let maxAvailable = leftLessThanMax
      ? myEntitlement.remainingHours
      : myEntitlement.maxHolidaysAvailableToSell

    if (!leftLessThanMax) {
      maxAvailable -= requestedAndSold
    } else {
      const remainingToSell = myEntitlement.maxHolidaysAvailableToSell - requestedAndSold
      if (remainingToSell < maxAvailable) {
        maxAvailable = remainingToSell
      }
    }

    setMaxToSell(maxAvailable)
  }, [hours?.soldHours, myEntitlement, requestedSellHours])

  const isRequestExceedingAllowance = useCallback(
    (hoursRequested: number): boolean => {
      if (!myEntitlement || !hours) {
        return true
      }

      return maxToSell < hoursRequested
    },
    [hours, myEntitlement, maxToSell]
  )

  const validateHours = useCallback(
    (hoursRequested: number): number => {
      const isExceeding = isRequestExceedingAllowance(hoursRequested)

      if (!isExceeding) {
        return hoursRequested
      }

      if (!shownOnce) {
        let message =
          maxToSell === 0
            ? `You have no hours available to sell.`
            : `You only have ${formatNumber(maxToSell)} hours available to sell.`

        if (hours && hours.soldHours > 0) {
          message += ` Already sold ${formatNumber(hours.soldHours)} hours.`
        }

        if (requestedSellHours > 0) {
          message += ` ${formatNumber(requestedSellHours)} hours are pending approval.`
        }

        dispatch(showErrorMessage(message))
        setShownOnce(true)
      }

      return maxToSell
    },
    [
      isRequestExceedingAllowance,
      shownOnce,
      maxToSell,
      formatNumber,
      hours,
      requestedSellHours,
      dispatch,
    ]
  )

  const shouldPreventSubmit = useCallback(
    (hoursRequested: number | ''): ShouldPreventSubmitResult => {
      const requested = Number(hoursRequested)
      return Number.isNaN(requested)
        ? { shouldPreventSend: true, requestedHours: null }
        : { shouldPreventSend: isRequestExceedingAllowance(requested), requestedHours: requested }
    },
    [isRequestExceedingAllowance]
  )

  return {
    maxToSell,
    validateHours,
    shouldPreventSubmit,
  }
}

export default useSellRequestValidation
