import WbSunnyIcon from '@mui/icons-material/WbSunny'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import Paragraph from '../Paragraph'
import { EntitlementSplit } from '../../../types/entitlement-split'
import { RootStore } from '../../../redux/store'

type Props = {
  summaryItems: EntitlementSplit
  marginTop?: number
  marginBottom?: number
  titleMarginBottom?: number
}

function WfhEntitlementSplit({
  summaryItems,
  marginTop = 3,
  marginBottom = 2,
  titleMarginBottom = 0,
}: Props) {
  const { userSettings } = useSelector((state: RootStore) => state.appSettings)

  return (
    <>
      <Grid container mt={marginTop} mb={titleMarginBottom}>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Grid item xs={2} display="flex" justifyContent="flex-end">
            <Paragraph weight="bold">Hours</Paragraph>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mb={marginBottom} rowSpacing={1} xs={12}>
        <Grid item xs={6} display="flex" justifyContent="flex-start">
          <Paragraph>Allowance</Paragraph>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Paragraph>
            {summaryItems.general.hours && summaryItems.general.hours.toFixed(2)}
          </Paragraph>
        </Grid>

        <Grid item xs={6} display="flex" justifyContent="flex-start">
          <Paragraph>Remaining</Paragraph>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Paragraph>
            {summaryItems.remaining.hours && summaryItems.remaining.hours.toFixed(2)}
          </Paragraph>
        </Grid>

        <Grid item xs={6} display="flex" justifyContent="flex-start">
          <Paragraph>Requested</Paragraph>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Paragraph>
            {summaryItems.requested.hours && summaryItems.requested.hours.toFixed(2)}
          </Paragraph>
        </Grid>

        <Grid item xs={6} display="flex" justifyContent="flex-start">
          <Paragraph>Change Requests</Paragraph>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Paragraph>
            {summaryItems.changeRequests.hours && summaryItems.changeRequests.hours.toFixed(2)}
          </Paragraph>
        </Grid>

        <Grid item xs={6} display="flex" justifyContent="flex-start">
          <Paragraph>If Approved</Paragraph>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Paragraph>
            {summaryItems.ifApproved.hours && summaryItems.ifApproved.hours.toFixed(2)}
          </Paragraph>
        </Grid>
      </Grid>
    </>
  )
}

export default WfhEntitlementSplit
