import { SelectOption } from '../../../services/dashboardService'

// eslint-disable-next-line no-shadow
export enum SSRSReportType {
  HOLIDAYS_ENTITLEMENT_REPORT = 'HOLIDAYS_ENTITLEMENT',
  HOLIDAYS_AVAILABILITY_REPORT = 'AVAILABILITY',
  HOLIDAYS_EMPLOYEE_REQUEST_REPORT = 'EMPLOYEE_HOLIDAY_REQUESTS',
  HOLIDAYS_ENTITLEMENT_REMAINING_ALLOWANCE_REPORT = 'ENTITLEMENT_AND_REMAINING_ALLOWANCE',
  HOLIDAYS_REQUESTS_COUNT_REPORT = 'REQUEST_COUNT',
  HOLIDAY_THRESHOLDS_AND_RESTRICTIONS_REPORT = 'HOLIDAY_THRESHOLDS_AND_RESTRICTIONS',
  HOLIDAYS_ENTITLEMENT_BULGARIA_REPORT = 'HOLIDAY_ENTITLEMENT_BULGARIA',
  HOLIDAYS_EMPLOYEE_REQUEST_BULGARIA_REPORT = 'EMPLOYEE_REQUEST_BULGARIA',
}
// eslint-disable-next-line no-shadow
export enum SSRSReportTypeLabels {
  HOLIDAYS_ENTITLEMENT_REPORT = 'Holidays Entitlement Report',
  HOLIDAYS_AVAILABILITY_REPORT = 'Holidays Availability Report',
  HOLIDAYS_EMPLOYEE_REQUEST_REPORT = 'Holidays Employee Request Report',
  HOLIDAYS_ENTITLEMENT_REMAINING_ALLOWANCE_REPORT = 'Holidays Entitlement & Remaining Allowance Report',
  HOLIDAYS_REQUESTS_COUNT_REPORT = 'Holiday Requests Count Report',
  HOLIDAY_THRESHOLDS_AND_RESTRICTIONS_REPORT = 'Holiday Thresholds and Restrictions',
  HOLIDAYS_ENTITLEMENT_BULGARIA_REPORT = 'Holiday Entitlement Bulgaria Report',
  HOLIDAYS_EMPLOYEE_REQUEST_BULGARIA_REPORT = 'Employee Request Bulgaria',
}

export interface HolidayReportsItem {
  key: string
  title: string
}

export type Props = {
  reportData: any[]
  userDepartments: SelectOption[]
}
