import { Dispatch } from 'react'
import { AnyAction } from 'redux'
import { BookingFeature, BookingFloorPlan, BookingZone } from '../../services/booking/types'
import { BookingFeatureTypes } from './Shared/enums'
import { getJson } from './utils/utils'
import { BookingVisbility } from './Shared/types'
import {
  setDeskBookingShowBlockBookingSummary,
  setDeskBookingShowBlockBookingWizard,
  setDeskBookingShowFloorplan,
  setDeskBookingShowGridView,
  setDeskBookingShowNavBar,
  setDeskBookingShowSearchComponent,
  setDeskBookingShowFilterComponent,
} from '../../redux/reducers/deskBookingReducer'
import { FIRST_FLOORPLAN_IN_ARRAY, FIRST_ZONE_IN_ARRAY } from './consts'

export const buildFeatureUpdateBody = (
  feature: Partial<BookingFeature>
): Partial<BookingFeature> => {
  let jsonAdditionalInfo
  if (feature.additionalInfo) {
    jsonAdditionalInfo = getJson(feature.additionalInfo)
  }
  if (jsonAdditionalInfo && jsonAdditionalInfo.svg && jsonAdditionalInfo.svg.length > 0) {
    const getTypeID = (type: string): { type: string; typeID: number } => {
      switch (type) {
        case 'rect':
          return { typeID: BookingFeatureTypes.SVG_Rectangle, type: 'SVG Rectangle' }
        case 'text':
          return { typeID: BookingFeatureTypes.SVG_Text, type: 'SVG Text' }
        default:
          return { typeID: BookingFeatureTypes.SVG_Generic, type: 'SVG Generic' }
      }
    }
    const typeInfo = getTypeID(jsonAdditionalInfo.svg[0].type)
    return {
      id: feature.id,
      floorPlanId: feature.floorPlanId,
      floorPlanCategoryId: feature.floorPlanCategoryId,
      locationId: feature.locationId,
      typeId: typeInfo.typeID,
      type: typeInfo.type,
      statusId: feature.statusId,
      status: feature.status,
      label: feature.label,
      x: jsonAdditionalInfo.svg[0].x,
      y: jsonAdditionalInfo.svg[0].y,
      width: jsonAdditionalInfo.svg[0].width,
      height: jsonAdditionalInfo.svg[0].height,
      fill: jsonAdditionalInfo.svg[0].fill,
      borderRadius: jsonAdditionalInfo.svg[0].rx,
      ports: feature.ports,
      createdById: feature.createdById,
      components: feature.components,
      additionalInfo: '',
      zone: feature.zone,
    }
  }
  return {
    id: feature.id,
    floorPlanId: feature.floorPlanId,
    floorPlanCategoryId: feature.floorPlanCategoryId,
    locationId: feature.locationId,
    typeId: feature.typeId,
    type: feature.type,
    statusId: feature.statusId,
    status: feature.status,
    label: feature.label,
    x: feature.x,
    y: feature.y,
    width: feature.width,
    height: feature.height,
    fill: feature.fill,
    borderRadius: feature.borderRadius,
    ports: feature.ports,
    createdById: feature.createdById,
    components: feature.components,
    additionalInfo: feature.additionalInfo,
    zone: feature.zone,
  }
}

export const showComponents = (
  dispatch: Dispatch<AnyAction>,
  {
    floorplan = false,
    gridview = false,
    navbar = false,
    search = false,
    filter = false,
    blockBookingWizard = false,
    blockBookingSummary = false,
  }: BookingVisbility
) => {
  dispatch(setDeskBookingShowFloorplan(floorplan))
  dispatch(setDeskBookingShowGridView(gridview))
  dispatch(setDeskBookingShowNavBar(navbar))
  dispatch(setDeskBookingShowSearchComponent(search))
  dispatch(setDeskBookingShowFilterComponent(filter))
  dispatch(setDeskBookingShowBlockBookingWizard(blockBookingWizard))
  dispatch(setDeskBookingShowBlockBookingSummary(blockBookingSummary))
}

export const defaultFloorplanIdIfNoneSelected = (
  employeeZones: BookingZone[],
  employeesFloorplans: BookingFloorPlan[]
) =>
  employeeZones.length > 0
    ? employeeZones[FIRST_ZONE_IN_ARRAY].floorPlanId
    : employeesFloorplans[FIRST_FLOORPLAN_IN_ARRAY].id

export const defaultZoneIdIfNoneSelected = (employeeZones: BookingZone[]) =>
  employeeZones.length > 0 ? employeeZones[FIRST_ZONE_IN_ARRAY].id : 0
