import { useCallback, useMemo, useState } from 'react'
import { addMonths, differenceInCalendarMonths } from 'date-fns'
import { PlottingCalendarProps } from './types'
import { PlottingCalendar } from './PlottingCalendar'
import CalendarCard from './CalendarCard'
import { StyledBox } from './components'
import { RequestDataType } from '../../types/request-data-type'
import FullComponentLoadingIcon from '../../shared/UI/LoadingIndicator/FullComponentLoadingIcon'

export function PlottingCalendars({
  dates = [],
  defineRange,
  disableDates = [],
  onClickEvent,
  onDayClick,
  isComingUp,
  newRequestData,
  loading,
}: PlottingCalendarProps) {
  const [months, setSetMonths] = useState<Date[]>([])

  const setMonths = useCallback(() => {
    const jan = new Date(new Date().getFullYear(), 0, 1)
    const range = defineRange || [jan, addMonths(jan, 11)]
    const monthAdd: Date[] = []
    const monthCount = differenceInCalendarMonths(range[1], range[0]) + 1
    Array(monthCount)
      .fill(0)
      .forEach((_month, idx) => monthAdd.push(addMonths(range[0], idx)))
    setSetMonths(monthAdd)
  }, [defineRange])

  useMemo(() => {
    setMonths()
  }, [setMonths])

  return (
    <StyledBox className={isComingUp ? 'isComingUpStyles' : ''}>
      {months.map((month, index) => (
        <CalendarCard
          key={`${month.getMonth()}-${month.getDay()}-${month.getFullYear()}`}
          isComingUp={isComingUp}
        >
          <FullComponentLoadingIcon loading={Boolean(loading)} bgColor="transparent">
            <PlottingCalendar
              calendarDate={month}
              dates={dates}
              disableDates={disableDates}
              onClickEvent={onClickEvent}
              onDayClick={onDayClick}
              isComingUp={isComingUp}
              newRequestData={newRequestData}
            />
          </FullComponentLoadingIcon>
        </CalendarCard>
      ))}
    </StyledBox>
  )
}
