import { Grid, Checkbox } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import { settingsService } from '../../services/settingsService'
import { BaseResponse } from '../../types/base-response'
import UserErrorMessage from '../../utils/errorFilter'
import { LocationsType, LocationsTypeTransformed } from '../SettingsOptions/settingsTypes'
import { useLocations } from './useLocations'

function Locations() {
  const [pageSize, setPageSize] = useState<number>(10)
  const { locations, setLocations } = useLocations()
  const dispatch = useDispatch()

  const putLocation = (
    locationId: number,
    type: string,
    sca: boolean,
    rtw: boolean,
    locationName: string
  ) => {
    const locationType = type.toString().split('_')[0]
    settingsService
      .putLocation({
        locationId,
        type: locationType,
        required: locationType === 'rtw' ? rtw : sca,
      })
      .then(res => {
        const locationIndex = locations.findIndex(e => e.locationId === locationId)
        const localLocations = JSON.parse(JSON.stringify(locations))

        localLocations[locationIndex].rtwRequired = rtw
        localLocations[locationIndex].scaRequired = sca

        setLocations(localLocations)

        dispatch(
          showSuccessMessage(
            `${locationType.toUpperCase()} setting for ${locationName} set to ${
              locationType === 'rtw' ? rtw : sca
            }`
          )
        )
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(e => {
          dispatch(showErrorMessage(<UserErrorMessage name={e.name} />))
        })
      })
  }

  const columns: GridColDef[] = [
    {
      field: 'location',
      headerName: 'Location',
      type: 'string',
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'sca_required',
      headerName: 'SCA Required',
      flex: 1,
      renderCell: params => (
        <Checkbox
          checked={params.row.sca_required}
          onClick={() =>
            putLocation(
              params.row.id,
              params.field,
              !params.formattedValue,
              params.row.rtw_required,
              params.row.location
            )
          }
        />
      ),
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'rtw_required',
      headerName: 'RTW Required',
      flex: 1,
      renderCell: params => (
        <Checkbox
          checked={params.row.rtw_required}
          onClick={() =>
            putLocation(
              params.row.id,
              params.field,
              params.row.sca_required,
              !params.formattedValue,
              params.row.location
            )
          }
        />
      ),
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
    },
  ]

  const [rows, setRows] = useState<LocationsTypeTransformed[]>([])

  useEffect(() => {
    const mappedRows = locations?.map((loc: LocationsType) => ({
      id: loc.locationId,
      location: loc.name,
      rtw_required: loc.rtwRequired,
      sca_required: loc.scaRequired,
    }))

    setRows(mappedRows)
  }, [locations])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DataGrid
          getRowId={row => row.id}
          rows={rows}
          autoHeight
          columns={columns}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
          pageSize={pageSize}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          sx={{ border: 0 }}
        />
      </Grid>
    </Grid>
  )
}

export default Locations
