import { Box, Link, useMediaQuery } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro'
import { useState } from 'react'
import { GridProps } from './types'

import { isMobilePortrait } from '../../../../theme/deviceChecks'
import NoResult from '../../../../shared/UI/NoResult'

function NoRowsOverlay() {
  return <NoResult message="No Results" showImage />
}
function HolidaysRequestsCountGrid({ data, onViewDetail }: GridProps) {
  const [pageSize, setPageSize] = useState<number>(100)
  const mobilePortrait = useMediaQuery(isMobilePortrait())

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Display Name',
      headerClassName: 'header',
      cellClassName: 'first',
      width: 210,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      renderCell: params => (
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            onViewDetail(params.row)
          }}
        >
          {params.row.displayName}
        </Link>
      ),
    },
    {
      field: 'jobTitle',
      headerName: 'Role',
      headerClassName: 'header',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'team',
      headerName: 'Team',
      headerClassName: 'header',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'daysOffRequests',
      headerName: 'Days Off Requests',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'daysOffApprovals',
      headerName: 'Days Off Approvals',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'daysOffDeclines',
      headerName: 'Days Off Declines',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'shiftRequests',
      headerName: 'Shift Requests',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'shiftApprovals',
      headerName: 'Shift Approvals',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'shiftDeclines',
      headerName: 'Shift Declines',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'totalRequests',
      headerName: 'Total Requests',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'totalApprovals',
      headerName: 'Total Approvals',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'totalDeclines',
      headerName: 'Total Declines',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
  ]

  const mobileColumns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Display Name',
      headerClassName: 'header',
      cellClassName: 'first',
      width: 210,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      renderCell: params => (
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            onViewDetail(params.row)
          }}
        >
          {params.row.displayName}
        </Link>
      ),
    },
    {
      field: 'daysOffRequests',
      headerName: 'Days Off Requests',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'daysOffApprovals',
      headerName: 'Days Off Approvals',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'daysOffDeclines',
      headerName: 'Days Off Declines',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'shiftRequests',
      headerName: 'Shift Requests',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'shiftApprovals',
      headerName: 'Shift Approvals',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'shiftDeclines',
      headerName: 'Shift Declines',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'totalRequests',
      headerName: 'Total Requests',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'totalApprovals',
      headerName: 'Total Approvals',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
    {
      field: 'totalDeclines',
      headerName: 'Total Declines',
      headerClassName: 'header',
      headerAlign: 'right',
      type: 'string',
      width: 200,
      sortable: true,
      filterable: true,
      pinnable: false,
      hideable: false,
      align: 'right',
      renderCell: cellValues => cellValues.value,
    },
  ]

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <DataGridPro
          getRowId={row => row.employeeId}
          rows={data}
          columns={mobilePortrait ? mobileColumns : columns}
          disableSelectionOnClick
          rowsPerPageOptions={[10, 25, 50, 100]}
          pagination
          pageSize={pageSize}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowHeight={50}
          autoHeight
          components={{ NoRowsOverlay }}
          initialState={{
            pinnedColumns: { left: ['displayName'] },
          }}
          sx={{
            '&.MuiDataGrid-root': {
              border: 'none !important ',
              marginLeft: '-0.5rem',
              paddingTop: '40px',
            },
            '.MuiDataGrid-row .notlast': {
              borderBottom: '0px solid rgba(224, 224, 224, 1)',
            },
            '.first': {
              justifyContent: 'flex-start',
              paddingLeft: '20px',
            },
            '.header': {
              backgroundColor: 'rgb(247, 247, 247)',
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              minHeight: '200px!important',
            },
            '& .MuiDataGrid-virtualScroller': {
              maxHeight: '360px',
            },
          }}
          componentsProps={{
            panel: {
              sx: {
                '& .MuiNativeSelect-select option': {
                  font: '13px "Poppins", sans-serif',
                },
              },
            },
          }}
        />
      </Box>
    </>
  )
}

export default HolidaysRequestsCountGrid
