import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Card from '../../../shared/layout/Card'
import DeskBG from '../../../assets/desk-bg.svg'
import DeskIllustration from '../../../assets/deskIllustration.svg'
import NoDeskIllustration from '../../../assets/noDeskIllustration.svg'
import {
  BookingIllustrationContainer,
  BookingIllustrationImg,
  BookingNoIllustrationContainer,
} from './components'
import Button from '../../../shared/UI/Button'
import Paragraph from '../../../shared/UI/Paragraph'
import { BookingTodaysDeskProps } from './types'
import { getDeskName } from '../utils/utils'
import { RootStore } from '../../../redux/store'
import { setCheckedIn } from '../../../redux/reducers/deskBookingReducer'
import { CheckInOutStatus } from './enums'

export function BookingTodaysDesk({ todaysBooking, onCheckIn }: BookingTodaysDeskProps) {
  const deskName =
    todaysBooking && Number(todaysBooking?.id) > 0
      ? getDeskName(todaysBooking.feature.additionalInfo, todaysBooking.feature.label)
      : ''

  const { checkedIn } = useSelector((state: RootStore) => state.deskBooking)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!todaysBooking?.checkInOut) {
      return
    }

    const sorted = todaysBooking.checkInOut.sort((x1, x2) =>
      x1.actionDate > x2.actionDate ? -1 : 1
    )

    if (sorted.length === 0) {
      return
    }

    dispatch(setCheckedIn(sorted[0].type === CheckInOutStatus.IN))
  }, [dispatch, todaysBooking])

  return (
    <>
      <Card
        style={{
          boxShadow: 'none',
          padding: 0,
          position: 'relative',
          backgroundImage: `url(${DeskBG})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          overflow: 'visible',
          opacity: todaysBooking ? 1 : 0.5,
          height: 200,
        }}
      >
        <>
          {todaysBooking && Number(todaysBooking?.id) > 0 && (
            <>
              <BookingIllustrationContainer>
                <BookingIllustrationImg src={DeskIllustration} />
              </BookingIllustrationContainer>
              <Paragraph
                style={{ margin: '32px 0 0 32px', zIndex: 1 }}
                size="13px"
                color="white"
                weight="bold"
              >
                Today&apos;s Desk
              </Paragraph>
              <Paragraph
                style={{ margin: '5px 0 0 32px', zIndex: 1 }}
                size="35px"
                color="white"
                weight="bold"
              >
                {deskName}
              </Paragraph>
              <Button
                label={checkedIn ? 'Checked In' : 'Check me in!'}
                type="button"
                color="info"
                variant="outlined"
                style={{
                  width: '172px',
                  marginLeft: '32px',
                  textTransform: 'none',
                  marginTop: 8,
                }}
                disabled={checkedIn}
                onClick={() => {
                  onCheckIn(todaysBooking.id, deskName)
                  dispatch(setCheckedIn(true))
                }}
              />
            </>
          )}
          {todaysBooking && Number(todaysBooking?.id) === 0 && (
            <>
              <BookingNoIllustrationContainer>
                <BookingIllustrationImg src={NoDeskIllustration} />
              </BookingNoIllustrationContainer>
              <Paragraph
                style={{ margin: '24px 0 16px 0', textAlign: 'center', paddingBottom: '8px' }}
                size="16px"
                color="white"
                weight="bold"
              >
                You don&apos;t have a desk today
              </Paragraph>
            </>
          )}
        </>
      </Card>
    </>
  )
}
