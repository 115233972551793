import { FormControl, InputLabel, ListSubheader, MenuItem, Select } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BookingNewBookingProps } from './types'
import { RootStore } from '../../../redux/store'
import { BookingPeriods } from './enums'

function NewBooking({ onSelectedRange }: BookingNewBookingProps) {
  const { floorplanViewingDate } = useSelector((state: RootStore) => state.deskBooking)

  useEffect(() => {
    onSelectedRange({
      selectedRange: { from: '00:00:00', to: '23:59:59' },
      date: floorplanViewingDate,
    })
  }, [])

  return (
    <>
      <FormControl sx={{ mt: '16px', width: '100%' }} size="small">
        <InputLabel htmlFor="grouped-select">Booking Length</InputLabel>
        <Select
          defaultValue="1"
          id="grouped-select"
          label="Booking Length"
          MenuProps={{
            disableScrollLock: true,
            PaperProps: {
              sx: {
                '& .MuiMenuItem-root': {},
                '& .MuiListSubheader-root': {
                  fontWeight: 'bold',
                },
                '& .MuiButtonBase-root:not(:first-child)': {
                  paddingLeft: '25px',
                },
              },
            },
          }}
          onChange={e => {
            const fromTo = { from: '', to: '' }
            switch (String(e.target.value)) {
              case BookingPeriods.FULL_DAY:
                fromTo.from = '00:00:00'
                fromTo.to = '23:59:59'
                break
              case BookingPeriods.HALF_DAY_FIRST_HALF:
                fromTo.from = '00:00:00'
                fromTo.to = '11:59:59'
                break
              case BookingPeriods.HALF_DAY_SECOND_HALF:
                fromTo.from = '12:00:00'
                fromTo.to = '23:59:59'
                break
              default:
                break
            }
            onSelectedRange({
              selectedRange: fromTo,
              date: floorplanViewingDate,
            })
          }}
        >
          <MenuItem value={1}>Full Day</MenuItem>
          <ListSubheader>Half Day:</ListSubheader>
          <MenuItem value={2}>First Half</MenuItem>
          <MenuItem value={3}>Second Half</MenuItem>
        </Select>
      </FormControl>
    </>
  )
}

export default NewBooking
