/* eslint-disable no-param-reassign */
import { Grid } from '@mui/material'
import { MyActionsGrid } from '../MyActionsGrid'
import MyActionsPageHeader from '../../shared/UI/MyActionsPageHeader'
import MobileContainer from '../../shared/layout/MobileContainer'

function MyActions() {
  return (
    <>
      <MyActionsPageHeader />
      <MobileContainer>
        <Grid container spacing={4}>
          <Grid item xs>
            <MyActionsGrid />
          </Grid>
        </Grid>
      </MobileContainer>
    </>
  )
}

export default MyActions
