import { httpServiceV1 } from './httpServiceV1'
import {
  NotificationAllByStateRequest,
  NotificationResponse,
  NotificationSingleRequest,
} from '../shared/UI/Notifications/type'

const requestsBaseURL = 'Dashboard'

const notificationService = {
  getNotifications: async (): Promise<NotificationResponse> => {
    let url = `${requestsBaseURL}/Notifications?`
    url = `${url}&NotificationStateIds=0`

    return httpServiceV1.get(url)
  },

  clearNotification(
    notificationId: number,
    recipientId: number,
    changedById: number
  ): Promise<void> {
    const url = `${requestsBaseURL}/Notifications/Single`
    const body: NotificationSingleRequest = {
      notificationId,
      recipientId,
      newStateId: 1,
      changedById,
    }

    return httpServiceV1.put(url, body)
  },

  clearAllNotifications(recipientId: number, changedById: number): Promise<void> {
    const url = `${requestsBaseURL}/Notification/AllByState`
    const body: NotificationAllByStateRequest = {
      recipientId,
      currentStateId: 0,
      newStateId: 1,
      changedById,
      maxNotificationId: 2147483647,
    }

    return httpServiceV1.put(url, body)
  },

  getNewNotifications: async (lastNotificationId: number): Promise<NotificationResponse> => {
    let url = `${requestsBaseURL}/Notifications/New?`
    url = `${url}&LastNotificationId=${lastNotificationId}`

    return httpServiceV1.get(url)
  },
}

export { notificationService }
