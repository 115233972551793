import { Grid, TextField } from '@mui/material'
import styled from 'styled-components'
import Button from '../../../../shared/UI/Button'
import { DetailProps } from './types'

const StyledTextField = styled(TextField)`
  input.MuiInputBase-input {
    -webkit-text-fill-color: #747474;
  }
`

function HolidaysEntitlementDetail({ row, onClose }: DetailProps) {
  if (!row) {
    return null
  }

  return (
    <>
      <Grid container style={{ paddingLeft: '30px' }} mt={4} spacing={4} pr={4}>
        <Grid item xs={12}>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Department" value={row.department} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="JobTitle" value={row.position} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Team" value={row.team} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField
                  fullWidth
                  label="Has Booked Birthday"
                  value={row.hasBookedBirthday}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField
                  fullWidth
                  label="Employment Start Date"
                  value={row.employmentStartDate}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField
                  fullWidth
                  label="Employment End Date"
                  value={row.employmentEndDate}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <StyledTextField
                  fullWidth
                  label="Default Shift Length"
                  value={row.defaultShiftLength}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <StyledTextField
                  fullWidth
                  label="Holiday Entitlement"
                  value={row.holidayEntitlement}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <StyledTextField
                  fullWidth
                  label="Default Allowance"
                  value={row.defaultAllowance}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <StyledTextField fullWidth label="Service Days" value={row.serviceDays} disabled />
              </Grid>
              <Grid item xs={12} lg={3}>
                <StyledTextField
                  fullWidth
                  label="Fire Marshall"
                  value={row.fireMarshall}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <StyledTextField fullWidth label="First Aider" value={row.firstAider} disabled />
              </Grid>
              <Grid item xs={12} lg={3}>
                <StyledTextField fullWidth label="Adjustment" value={row.adjustment} disabled />
              </Grid>
              <Grid item xs={12} lg={3}>
                <StyledTextField
                  fullWidth
                  label="Holidays Bought"
                  value={row.holidaysBought}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <StyledTextField
                  fullWidth
                  label="Holidays Sold"
                  value={row.holidaysSold}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <StyledTextField
                  fullWidth
                  label="Holiday Taken"
                  value={row.holidayTaken}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <StyledTextField
                  fullWidth
                  label="Holiday Remaining"
                  value={row.holidayRemaining}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <StyledTextField fullWidth label="% Taken" value={row.holidayTakenPc} disabled />
              </Grid>
              <Grid item xs={12} lg={6} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        xl={12}
        pl={4}
        pr={4}
        style={{ position: 'fixed', bottom: '40px', right: '0px' }}
      >
        <Grid item xs={12} gap={4} display="flex" justifyContent="flex-end">
          <Button label="Close" color="secondary" variant="outlined" onClick={onClose} />
        </Grid>
      </Grid>
    </>
  )
}

export default HolidaysEntitlementDetail
