import { httpServiceV1 } from './httpServiceV1'
import { WallChartResponseBody, WallChartQueryProps } from '../types/wallchart'

const wallChartService = {
  getWallChartData: async (
    queryParams: WallChartQueryProps,
    abortController?: AbortController
  ): Promise<WallChartResponseBody> => {
    let url = `/WallChart?DateFrom=${queryParams.dateFrom}&DateTo=${queryParams.dateTo}`
    if (queryParams.departmentId && queryParams.departmentId > 0) {
      url = `${url}&departmentId=${queryParams.departmentId}`
    }
    if (queryParams.directReportees) {
      url = `${url}&directReportees=${queryParams.directReportees}`
    }
    if (queryParams.includeThresholds && queryParams.thresholdsForEmployee) {
      url = `${url}&includeThresholds=${queryParams.includeThresholds}&thresholdsForEmployee=${queryParams.thresholdsForEmployee}`
    }
    queryParams.departmentTeamIds?.forEach(teamId => {
      url = `${url}&departmentTeamIds=${teamId}`
    })
    queryParams.employees?.forEach(emp => {
      url = `${url}&employeeIds=${emp.employeeId}`
    })

    const config = abortController ? { signal: abortController.signal } : undefined
    return httpServiceV1.get(url, config)
  },
}

export { wallChartService }
