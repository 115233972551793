import { Grid, styled } from '@mui/material'
import { ThresholdLevels } from '../types'
import { WallChartHeaderSegmentPosition } from '../WallChartHeader/types'
import { WallChartRowSegmentProps } from './types'

const getBorder = (position: WallChartHeaderSegmentPosition): object => {
  switch (position) {
    case WallChartHeaderSegmentPosition.FIRST:
      return { borderLeft: '2px solid #CECECE' }
    case WallChartHeaderSegmentPosition.LAST:
      return { borderLeft: '1px solid #CECECE', borderRight: '2px solid #CECECE' }
    default:
      return { borderLeft: '1px solid #CECECE' }
  }
}

export const WallChartRowSegment = styled(
  Grid,
  {}
)<WallChartRowSegmentProps>(({ position, isWeekend, thresholdLevel, bankHoliday, isManager }) => ({
  ...(position && getBorder(position)),
  ...{ height: '100%', textAlign: 'center', borderBottom: '1px solid #CECECE' },
  ...(isWeekend && { backgroundColor: '#f2f2f2' }),
  ...(thresholdLevel === ThresholdLevels.LOW &&
    isManager && { backgroundColor: 'rgba(19, 241, 0, 0.15)' }),
  ...(thresholdLevel === ThresholdLevels.MEDIUM &&
    isManager && { backgroundColor: 'rgba(253, 242, 217, 1)' }),
  ...(thresholdLevel === ThresholdLevels.HIGH &&
    isManager && { backgroundColor: 'rgba(251, 231, 231, 1)' }),
  ...(bankHoliday && { backgroundColor: '#f2f2f2' }),
})) as React.ComponentType<WallChartRowSegmentProps>

export const WallChartRowSegmentCell = styled(
  Grid,
  {}
)<WallChartRowSegmentProps>(({ position }) => ({
  ...{
    '&:last-child': {
      borderRight: 'none',
    },
  },
  ...(position && getBorder(position)),
  ...{
    textAlign: 'center',
    borderRight: '0.1rem solid #EEEEEE',
    height: '100%',
    alignItems: 'center',
  },
})) as React.ComponentType<WallChartRowSegmentProps>
