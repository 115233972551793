import { GridSize } from '@mui/material'

// eslint-disable-next-line no-shadow
export enum WallChartHeaderSegmentPosition {
  FIRST = 'first',
  LAST = 'last',
  MIDDLE = 'middle',
}
export interface WallChartHeaderSegmentProps {
  item: boolean
  xs: boolean | GridSize | undefined
  position?: WallChartHeaderSegmentPosition
  isToday?: boolean
  isTomorrow?: boolean
}

export interface WallChartHeaderSegmentTextProps {
  text?: string
}
