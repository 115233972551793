import { useState } from 'react'
import { WizardStepContainer, WizardStepElement } from './components'
import { WizardStepsProps } from './types'

export function WizardSteps({ steps, value, primaryStepColour = "", allowClick = false, onChange }: WizardStepsProps) {
  return (
    <WizardStepContainer primaryStepColour={primaryStepColour}>
      {steps.map((step, idx) => (
        <WizardStepElement
          disableRipple
          disableFocusRipple
          selected={step.index === value}
          primaryStepColour={primaryStepColour}
          allowClick={allowClick}
          onClick={_e => {
            if (allowClick) {
              onChange?.(idx)
            }
          }}
        >
          {step.label}
        </WizardStepElement>
      ))}
    </WizardStepContainer>
  )
}
