import { Box, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { ToggleButtonSlider, ToggleTextButton } from './components'
import { ToggleButtonsProps } from './types'
import Paragraph from '../../../shared/UI/Paragraph'

function SlideButton({ buttons, setIdx, width = '295px', disabled, onSelect }: ToggleButtonsProps) {
  const [slidingButtonClass, setSlidingButtonClass] = useState<string>('')
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const handleSelected = (idx: number) => {
    setSlidingButtonClass(`slider ${idx > 0 && 'slidermove'}`)
    setSelectedIndex(idx)
    onSelect(idx)
  }

  useEffect(() => {
    if (setIdx === undefined) {
      return
    }
    setSlidingButtonClass(`slider ${setIdx > 0 && 'slidermove'}`)
    setSelectedIndex(setIdx)
  }, [setIdx])

  return (
    <Box
      bgcolor="#F4F5F7"
      p="4px"
      borderRadius="4px"
      position="relative"
      width={width}
      height="40px"
      style={{ cursor: 'pointer' }}
    >
      <ToggleButtonSlider className={slidingButtonClass} x={2} width={100 / buttons.length - 2} />
      {buttons.map((m, idx) => (
        <ToggleTextButton
          key={`${m.label}-${m.x}-`}
          disabled={disabled}
          width={100 / buttons.length}
          opacity={selectedIndex === idx ? 1 : 0.5}
          onClick={() => handleSelected(idx)}
        >
          <Stack direction="row" justifyContent="center" alignItems="center">
            {m.icon}
            <Paragraph color="black">{m.label}</Paragraph>
          </Stack>
        </ToggleTextButton>
      ))}
    </Box>
  )
}

export default SlideButton
