import { useEffect, useMemo, useState } from 'react'
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { settingsService } from '../../services/settingsService'
import { showErrorMessage, showSuccessMessage } from '../../redux/reducers/snackbarReducer'
import Button from '../../shared/UI/Button'
import { SettingsCheckboxType } from './settingsTypes'
import { SettingsMaintenanceRequest } from '../../types/settings-maintenance-request'
import { RootStore } from '../../redux/store'
import { setSelectedSettings, setUserSettings } from '../../redux/reducers/appSettingsReducer'
import UserErrorMessage from '../../utils/errorFilter'
import { BaseResponse } from '../../types/base-response'
import { SelectOption } from '../../services/dashboardService'
import { setEnhancement } from '../../redux/reducers/featureToggleReducer'

const initialRequestTypes: SettingsMaintenanceRequest = {
  errors: [],
  departmentId: null,
  isHolidayRequestAllowed: false,
  isDayOffRequestAllowed: false,
  isShiftRequestAllowed: false,
  hasEmailNotification: false,
  hasEntitlementSeasonalSplit: false,
  isLieuDayRequestAllowed: false,
  isWfhRequestAllowed: false,
  applyHolidayChangesToRota: false,
  isBirthdayRequestAllowed: false,
  isBuyRequestAllowed: false,
  isSellRequestAllowed: false,
  areWeekendsWorked: false,
  hasEnhancements: false
}

function SettingsOptions() {
  const dispatch = useDispatch()
  const [requestTypes, setRequestTypes] = useState<SettingsMaintenanceRequest>(initialRequestTypes)
  const [checkboxData, setCheckboxData] = useState<SettingsCheckboxType[]>([])
  const [updateLoading, setUpdateLoading] = useState<boolean>(false)
  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  const isUserPermittedToEdit = useMemo(
    () => Boolean(userPermissions.find(x => x === 'EditSetting')),
    [userPermissions]
  )

  const { selectedSettings, selectedDepartment } = useSelector(
    (state: RootStore) => state.appSettings
  )

  const userDepartment = useSelector<RootStore, SelectOption | null>(
    (state: RootStore) => state.appSettings.loggedInEmployeeDepartment
  )

  useEffect(() => {
    if (!selectedSettings || !selectedDepartment) return
    setRequestTypes({ ...selectedSettings, departmentId: selectedDepartment.value })
  }, [selectedSettings, selectedDepartment])

  useEffect(() => {
    setCheckboxData([
      {
        label: 'Holiday request allowed',
        typevalue: requestTypes.isHolidayRequestAllowed,
        type: 'isHolidayRequestAllowed',
      },
      {
        label: 'Day off request allowed',
        typevalue: requestTypes.isDayOffRequestAllowed,
        type: 'isDayOffRequestAllowed',
      },
      {
        label: 'Shift request allowed',
        typevalue: requestTypes.isShiftRequestAllowed,
        type: 'isShiftRequestAllowed',
      },
      {
        label: 'Birthday request allowed',
        typevalue: requestTypes.isBirthdayRequestAllowed,
        type: 'isBirthdayRequestAllowed',
      },
      {
        label: 'Buy request allowed',
        typevalue: requestTypes.isBuyRequestAllowed,
        type: 'isBuyRequestAllowed',
      },
      {
        label: 'Sell request allowed',
        typevalue: requestTypes.isSellRequestAllowed,
        type: 'isSellRequestAllowed',
      },
      {
        label: 'Lieu day request allowed',
        typevalue: requestTypes.isLieuDayRequestAllowed,
        type: 'isLieuDayRequestAllowed',
      },
      {
        label: 'Work from home request allowed',
        typevalue: requestTypes.isWfhRequestAllowed,
        type: 'isWfhRequestAllowed',
      },
      {
        label: 'Has email notification',
        typevalue: requestTypes.hasEmailNotification,
        type: 'hasEmailNotification',
      },
      {
        label: 'Has entitlement seasonal split',
        typevalue: requestTypes.hasEntitlementSeasonalSplit,
        type: 'hasEntitlementSeasonalSplit',
      },
      {
        label: 'Has payroll enhancements',
        typevalue: requestTypes.hasEnhancements,
        type: 'hasEnhancements'
      }
    ])
  }, [requestTypes])

  const setDepartmentData = (requestBody: SettingsMaintenanceRequest) => {
    if (!selectedDepartment) {
      return
    }
    setUpdateLoading(true)
    settingsService
      .putSettings({ ...requestBody, departmentId: selectedDepartment.value })
      .then(res => {
        setUpdateLoading(false)
        dispatch(showSuccessMessage('Settings updated'))
        dispatch(setSelectedSettings(requestBody))

        if (selectedDepartment?.value === userDepartment?.value) {
          settingsService.getSettings(userDepartment?.value).then(response => {
            const requestType: SettingsMaintenanceRequest = {
              errors: [],
              departmentId: userDepartment?.value,
              isHolidayRequestAllowed: response.isHolidayRequestAllowed,
              isDayOffRequestAllowed: response.isDayOffRequestAllowed,
              isShiftRequestAllowed: response.isShiftRequestAllowed,
              hasEmailNotification: response.hasEmailNotification,
              hasEntitlementSeasonalSplit: response.hasEntitlementSeasonalSplit,
              isLieuDayRequestAllowed: response.isLieuDayRequestAllowed,
              isWfhRequestAllowed: response.isWfhRequestAllowed,
              applyHolidayChangesToRota: response.applyHolidayChangesToRota,
              isBirthdayRequestAllowed: response.isBirthdayRequestAllowed,
              isBuyRequestAllowed: response.isBuyRequestAllowed,
              isSellRequestAllowed: response.isSellRequestAllowed,
              areWeekendsWorked: response.areWeekendsWorked,
              hasEnhancements: response.hasEnhancements
            }
            dispatch(setUserSettings(requestType))
          })
        }
      })
      .catch(err => {
        setUpdateLoading(false)
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
  }

  const checkboxObject = (checkboxDatum: SettingsCheckboxType) => (
    <FormControlLabel
      control={
        <Checkbox
          checked={checkboxDatum.typevalue}
          onChange={() => {
            const requestBody = {
              ...requestTypes,
              ...JSON.parse(`{"${checkboxDatum.type}": ${!checkboxDatum.typevalue}}`),
            }
            setRequestTypes(requestBody)
          }}
          disabled={!isUserPermittedToEdit}
          style={{ margin: '9px' }}
        />
      }
      label={checkboxDatum.label}
      key={checkboxDatum.label}
    />
  )

  return (
    <Grid container spacing={4} display="flex" direction="column">
      <Grid item xs={12}>
        <FormGroup
          sx={{ flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }}
        >
          <Grid item display="flex" direction="column">
            {checkboxData.slice(0, 5).map(e => checkboxObject(e))}
          </Grid>
          <Grid item display="flex" direction="column">
            {checkboxData.slice(5, 10).map(e => checkboxObject(e))}
          </Grid>
          <Grid item display="flex" direction="column">
            {checkboxData.slice(10, 15).map(e => checkboxObject(e))}
          </Grid>
        </FormGroup>
      </Grid>
      {isUserPermittedToEdit && (
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Button
            label="Update"
            onClick={() => requestTypes && setDepartmentData(requestTypes)}
            loading={updateLoading}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default SettingsOptions
