import styled from 'styled-components'
import { Box, Grid, Typography } from '@mui/material'
import { isMobilePortrait, isTabletDown } from '../../theme/deviceChecks'

interface ServiceHoursProps {
  color: string
}

export const ServiceHoursOuterContainer = styled(Box)`
  position: relative;
  display: flex;
  background-color: #fafafa;
  border-radius: 8px;
  height: 84px;
  margin: 0 32px 16px;
`

export const ServiceHoursGrid = styled(Grid)`
  &.MuiGrid-root {
    position: relative;
    padding-bottom: 16px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .allocation1 & {
    height: 190px;
  }

  .allocation2 & {
    height: 248px;
  }

  .allocation3 & {
    height: 348px;
  }
`

export const MyAllocationImageContainer = styled(Box)`
  position: absolute;
  right: 32px;
  top: 0;
  height: 100%;
  width: 100%;

  & img {
    position: absolute;
    right: 0;
  }

  .allocation1 & img {
    height: 200px;
    bottom: 165px;
  }

  .allocation2 & img {
    height: 180px;
    bottom: 230px;
  }

  .allocation3 & img {
    height: 150px;
    bottom: 328px;
  }
`

export const ServiceHoursIcon = styled(Box)<ServiceHoursProps>`
  height: 42px;
  min-width: 42px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;

  & .MuiSvgIcon-root {
    height: 30px;
    width: 30px;
  }

  ${isTabletDown()} {
    & .MuiSvgIcon-root {
      height: 25px;
      width: 25px;
    }
  }
`

export const ServiceHoursContainer = styled(Box)`
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.2px;
  color: #000;
  font-family: 'Poppins';
  font-weight: bold;
  margin-right: 16px;
`

export const ServiceHoursAmount = styled('div')`
  background-color: #00cec9;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;

  ${isMobilePortrait()} {
    margin-right: 10%;
  }
`

export const ServiceHoursText = styled(Typography)<ServiceHoursProps>`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.2px;
    ${color => color && `color: ${color}`}
  }

  ${isTabletDown()} {
    &.MuiTypography-root {
      font-size: 11px;
    }
  }
`

export const ServiceHoursNumber = styled(Box)`
  font-size: 35px;

  ${isTabletDown()} {
    font-size: 30px;
  }
`

export const ServiceHoursSpan = styled('span')`
  font-size: 18px;
  margin-left: 4px;

  ${isTabletDown()} {
    font-size: 16px;
  }
`
