import styled from 'styled-components'
import { Card as MuiCard, Divider, Stack, useMediaQuery } from '@mui/material'
import CardTitle from '../../UI/CardTitle'
import { isMobilePortrait } from '../../../theme/deviceChecks'

type Props = {
  title?: string | number | undefined
  children?: JSX.Element | JSX.Element[]
  padding?: string
  icon?: React.ReactElement | string
  style?: React.CSSProperties
  isDefault?: boolean
  titleIcon?: React.ReactElement
  noDivider?: boolean
  testId?: string
}

interface StyledCardProps {
  padding?: string
}

const StyledCard = styled(MuiCard)<StyledCardProps>`
  &.MuiCard-root {
    padding: ${({ padding }) => padding || '32px'};
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    border-radius: 16px;
  }

  ${isMobilePortrait()} {
    &.MuiCard-root {
      border-radius: 0;
    }
  }
`

function Card({
  title,
  children,
  icon,
  padding,
  style,
  isDefault,
  titleIcon,
  noDivider,
  testId,
}: Props) {
  return (
    <StyledCard padding={padding} style={style} data-testid={testId}>
      {!isDefault ? (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" justifyContent="flex-start" gap={2} alignItems="center">
              {titleIcon}
              <CardTitle title={title} />
            </Stack>
            <Stack>{icon}</Stack>
          </Stack>
          {title && (
            <Divider
              sx={{
                '&.MuiDivider-root': {
                  display: noDivider ? 'none' : 'visible',
                },
              }}
            />
          )}
        </>
      ) : (
        <></>
      )}
      {children}
    </StyledCard>
  )
}

export default Card
