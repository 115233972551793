import { Typography } from '@mui/material'
import styled from 'styled-components'

export const LinkWithEllipsis = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
