import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useState } from 'react'
import { format } from 'date-fns'
import { AttendanceHistoryByDateItem } from '../../types/attendance-history-by-date-response'
import NoResult from '../../shared/UI/NoResult'
import TypeLabel from '../../shared/UI/TypeLabel'

export interface HistoryGridProps {
  items: AttendanceHistoryByDateItem[]
}

function NoRowsOverlay() {
  return <NoResult message="No Results" showImage />
}

function HistoryGrid(props: HistoryGridProps) {
  const [pageSize, setPageSize] = useState<number>(10)

  const parseActionType = (actionType: string) => {
    switch (actionType) {
      case 'Unspecified':
        return 'Unspecified'
      case 'AddedRecord':
        return 'Added Record'
      case 'EditedRecord':
        return 'Edited Record'
      case 'DeletedRecord':
        return 'Deleted Record'
      case 'CompletedSca':
        return 'Completed Sca'
      case 'CompletedRtw':
        return 'Completed Rtw'
      case 'ChangedRecordStatus':
        return 'Changed Record Status'
      case 'ExtendedAbsence':
        return 'Extended Absence'
      case 'AddedSickNote':
        return 'Added Sick Note'
      case 'UpdatedExpectedReturnDate':
        return 'Updated Expected Return Date'
      case 'DiscountedAbsence':
        return 'Discounted Absence'
      case 'AddedSickPay':
        return 'Added Sick Pay'
      case 'SearchedForAbsenceRecords':
        return 'Searched For Absence Records'
      case 'ViewedAbsenceRecord':
        return 'Viewed Absence Record'
      case 'ViewedLateRecord':
        return 'Viewed Late Record'
      case 'DeletedDayRecord':
        return 'Deleted Day Record'
      default:
        return actionType
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'recordType',
      headerName: 'Item Type',
      headerClassName: 'header',
      flex: 0.3,
      renderCell: cellValues => (
        <TypeLabel label={cellValues.row.recordType} type={cellValues.row.recordType} />
      ),
    },
    {
      field: 'actionType',
      headerName: 'Action Type',
      headerClassName: 'header',
      flex: 0.7,
      renderCell: cellValues => parseActionType(cellValues.row.actionType),
    },
    {
      field: 'timestamp',
      headerName: 'Date',
      headerClassName: 'header',
      flex: 0.8,
      renderCell: cellValues => format(new Date(cellValues.value), 'dd-MM-yyyy HH:mm:ss xx'),
    },
    {
      field: 'changedByEmployee',
      headerName: 'Employee',
      headerClassName: 'header',
      flex: 0.5,
    },
    {
      field: 'details',
      headerName: 'Value',
      headerClassName: 'header',
      flex: 2,
    },
  ]

  return (
    <DataGrid
      rows={props.items}
      columns={columns}
      getRowId={row => row.id}
      disableSelectionOnClick
      rowsPerPageOptions={[10, 25, 50, 100]}
      getRowHeight={() => 'auto'}
      density="standard"
      autoHeight
      components={{ NoRowsOverlay }}
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      initialState={{
        sorting: {
          sortModel: [{ field: 'timestamp', sort: 'desc' }],
        },
      }}
      sx={{
        '&.MuiDataGrid-root': {
          border: 'none',
          overflow: 'none',
        },
        '.header': {
          backgroundColor: 'rgb(247, 247, 247)',
        },
        '& .MuiDataGrid-virtualScrollerContent': {
          minHeight: '200px!important',
        },
        '.headerEmpty': {
          backgroundColor: 'rgb(247, 247, 247)',
        },
        '.headerEmpty .MuiDataGrid-columnHeaderTitle': {
          color: 'rgb(247, 247, 247)',
        },
        '&& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
          whiteSpace: 'pre-wrap',
          paddingTop: '5px',
        },
        '&& .MuiDataGrid-cell': {
          paddingTop: '10px',
          paddingBottom: '10px',
        },
      }}
      componentsProps={{
        panel: {
          sx: {
            '& .MuiNativeSelect-select option': {
              font: '13px "Poppins", sans-serif',
            },
          },
        },
      }}
    />
  )
}

export default HistoryGrid
