import { WhosAwayResponse } from '../../../types/whos-away-type'
import { getTargetDateString } from './getTargetDateString'

export const getFilteredRows = ({
  activeLink,
  whosAway,
}: {
  activeLink: string
  whosAway: WhosAwayResponse
}) => {
  const today = new Date().toDateString()
  const targetDateString = activeLink === 'Tomorrow' ? getTargetDateString(1) : today

  const filteredDates = whosAway.dates.filter(
    (x: { date: string | number | Date }) => new Date(x.date).toDateString() === targetDateString
  )
  return filteredDates
    .map(({ employees }) =>
      employees.map(({ name, dateTo, hoursOff, hoursVisible, absenceType }) => ({
        colleague: name,
        hours: hoursOff,
        hoursVisible,
        dateTo,
        absenceType,
      }))
    )
    .flat()
}
