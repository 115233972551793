import React, { useEffect, useState } from 'react'
import { Alert, AlertTitle, Snackbar } from '@mui/material'
import { useSelector } from 'react-redux'
import {
  clearSnackbarMessage,
  SnackBar as SnackBarModel,
  snackBarMessage,
} from '../../../redux/reducers/snackbarReducer'
import { useAppDispatch } from '../../../redux/store'

function SnackBar() {
  const [openSnack, setSnackOpen] = useState(false)
  const [snackBar, setSnackBar] = useState<SnackBarModel | null>(null)
  const snackBarState = useSelector(snackBarMessage)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (snackBarState) {
      setSnackBar(snackBarState)
      setSnackOpen(true)
    }
  }, [snackBarState])

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(clearSnackbarMessage())
    setSnackOpen(false)
  }
  return (
    <>
      {snackBar && (
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          message=""
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity={snackBar.severity} onClose={handleClose}>
            <AlertTitle>{snackBar.severity.toString()}</AlertTitle>
            {snackBar.message}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default SnackBar
