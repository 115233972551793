import { Box, Grid, styled } from '@mui/material'
import { WallChartContainerProps } from './types'

export const WallChartContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'margin',
})<WallChartContainerProps>(({ margin }) => ({
  ...{ margin },
})) as React.ComponentType<WallChartContainerProps>

export const WallChartRowsBoxContainer = styled(Box)`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 400px;
  position: relative;
  &::-webkit-scrollbar {
    width: 6px;
  }
`

export const WallChartRowItem = styled(Box)``

export const RowName = styled('span', {
  shouldForwardProp: prop => prop !== 'margin',
})<WallChartContainerProps>(({ margin }) => ({
  ...{
    margin: margin || '15px 0 0 0',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 600,
  },
})) as React.ComponentType<WallChartContainerProps>
