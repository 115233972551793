import { Box, Divider, Stack } from '@mui/material'
import ButtonSmall from '../../../shared/UI/ButtonSmall'
import Paragraph from '../../../shared/UI/Paragraph'

type Props = {
  color: string
  type: string
  value: string | undefined
  divider?: boolean
  showLink?: boolean
  linkLabel?: string
  url?: () => void
  dataTestId?: string
}

function LegendItem({
  color,
  type,
  value = '0.00/0.00',
  divider,
  showLink,
  linkLabel,
  url,
  dataTestId,
}: Props) {
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack
          width={showLink ? '33%' : '50%'}
          direction="row"
          justifyContent="flex-start"
          gap={2}
          alignItems="center"
        >
          <Box sx={{ background: color, borderRadius: '4px' }} width={15} height={15} />
          <Paragraph weight="bold">{type}</Paragraph>
        </Stack>
        <Stack
          width={showLink ? '66%' : '50%'}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Paragraph>{value}</Paragraph>
          {showLink && (
            <ButtonSmall
              label={linkLabel}
              onClick={url}
              sx={{ margin: '0 0 0 32px', lineHeight: '18px', minWidth: '60px' }}
              dataTestId={dataTestId}
            />
          )}
        </Stack>
      </Stack>
      <Divider sx={{ margin: '10px 0px', display: divider ? 'visible' : 'none' }} />
    </>
  )
}

export default LegendItem
