import { Grid, TextField } from '@mui/material'
import styled from 'styled-components'
import Button from '../../../../shared/UI/Button'
import { DetailProps } from './types'

const StyledTextField = styled(TextField)`
  input.MuiInputBase-input {
    -webkit-text-fill-color: #747474;
  }
`

function HolidaysThresholdsRestrictionsDailyDetail({ data, onClose }: DetailProps) {
  return (
    <>
      <Grid container style={{ paddingLeft: '30px' }} mt={4} spacing={4} pr={4}>
        <Grid item xs={12}>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Department" value={data.department} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                &nbsp;
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Date From" value={data.dateFrom} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Date To" value={data.dateTo} disabled />
              </Grid>

              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Monday" value={data.monday} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Tuesday" value={data.tuesday} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Wednesday" value={data.wednesday} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Thursday" value={data.thursday} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Friday" value={data.friday} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Saturday" value={data.saturday} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField fullWidth label="Sunday" value={data.sunday} disabled />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StyledTextField
                  fullWidth
                  label="TotalThreshold"
                  value={data.totalThreshold}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        xl={12}
        pl={4}
        pr={4}
        style={{ position: 'fixed', bottom: '40px', right: '0px' }}
      >
        <Grid item xs={12} gap={4} display="flex" justifyContent="flex-end">
          <Button label="Close" color="secondary" variant="outlined" onClick={onClose} />
        </Grid>
      </Grid>
    </>
  )
}

export default HolidaysThresholdsRestrictionsDailyDetail
