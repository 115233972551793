import { Box, Divider, Grid, Stack } from '@mui/material'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GridColDef, DataGrid } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import ActionIcon from '../../shared/UI/ActionIcon'
import { showSuccessMessage, showErrorMessage } from '../../redux/reducers/snackbarReducer'
import { settingsService } from '../../services/settingsService'
import AccordionBasic from '../../shared/layout/AccordianBasic'
import Button from '../../shared/UI/Button'
import Drawer from '../../shared/UI/Drawer'
import AddThresholdWeek from '../AddThresholdWeek'
import { ThresholdWeeks } from '../SettingsOptions/settingsTypes'
import SettingsThresholdsPercentages from '../SettingsThresholdsPercentages'
import SettingsThresholdsTeamDefaultHours from '../SettingsThresholdsTeamDefaultHours'
import Modal from '../../shared/UI/Modal'
import {
  hideModal,
  SetShowModalPayload,
  showModalDialog,
} from '../../redux/reducers/appSettingsReducer'
import { RootStore } from '../../redux/store'
import Paragraph from '../../shared/UI/Paragraph'
import { apiRequestDefaultThresholds } from '../../types/default-threshold-update-request'
import { DepartmentThresholdsResponse } from '../../types/department-thresholds-response'
import { DefaultThresholds } from '../../types/default-thresholds'
import UserErrorMessage from '../../utils/errorFilter'
import { BaseResponse } from '../../types/base-response'

type Props = {
  deptId: number
}

function SettingsThresholds({ deptId }: Props) {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [thresholds, setThresholds] = useState<DepartmentThresholdsResponse>()
  const [initialThresholds, setInitialThresholds] = useState<DepartmentThresholdsResponse>()
  const [pageSize, setPageSize] = useState<number>(10)
  const [weekToDelete, setWeekToDelete] = useState<number>(-1)
  const [deptTeamId, setDeptTeamId] = useState<number>(-1)
  const [filteredThresholds, setFilteredThresholds] = useState<ThresholdWeeks[]>()
  const [teamId, setTeamId] = useState<number>()
  const [disabled, setDisabled] = useState<boolean>(true)
  const [updateLoading, setUpdateLoading] = useState<boolean>(false)

  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  const dispatch = useDispatch()

  const { showModal, title, message, type, buttonLabel } = useSelector<
    RootStore,
    SetShowModalPayload
  >((state: RootStore) => state.appSettings.modalProps)

  const isUserPermittedToEdit = useMemo(
    () => Boolean(userPermissions.find(x => x === 'EditThresholds')),
    [userPermissions]
  )

  const isThresholdAtDefaultValues = useCallback(
    (newThresholds: DefaultThresholds, oldThresholds: DefaultThresholds) => {
      if (!isUserPermittedToEdit) {
        return true
      }

      if (
        newThresholds.monHours !== oldThresholds.monHours ||
        newThresholds.tueHours !== oldThresholds.tueHours ||
        newThresholds.wedHours !== oldThresholds.wedHours ||
        newThresholds.thuHours !== oldThresholds.thuHours ||
        newThresholds.friHours !== oldThresholds.friHours ||
        newThresholds.satHours !== oldThresholds.satHours ||
        newThresholds.sunHours !== oldThresholds.sunHours
      ) {
        return false
      }
      return true
    },
    [isUserPermittedToEdit]
  )

  useEffect(() => {
    setFilteredThresholds(
      thresholds?.thresholdWeeks.filter((e: ThresholdWeeks) => e.departmentTeamId === teamId)
    )
    const newThreshold = thresholds?.defaultThresholds.find(
      (dt: DefaultThresholds) => dt.departmentTeamId === teamId
    )
    const initialThreshold = initialThresholds?.defaultThresholds.find(
      (dt: DefaultThresholds) => dt.departmentTeamId === teamId
    )
    if (newThreshold && initialThreshold) {
      setDisabled(isThresholdAtDefaultValues(newThreshold, initialThreshold))
    }
  }, [teamId, thresholds, initialThresholds, isThresholdAtDefaultValues])

  const getData = useCallback(() => {
    settingsService.getThresholds(deptId).then(deptThresholds => {
      setThresholds(deptThresholds)
      setInitialThresholds(deptThresholds)
    })
  }, [deptId])

  useEffect(() => {
    if (deptId !== 0) {
      getData()
      setTeamId(undefined)
    }
  }, [deptId, getData])

  const handleThresholdChange = (
    defaultThresholdId: number,
    field: string,
    value: number | boolean
  ) => {
    setThresholds((current: DepartmentThresholdsResponse | undefined) => {
      const thresholdsCopy = JSON.parse(JSON.stringify(current))
      const newThreshold = thresholdsCopy.defaultThresholds.find(
        (dt: apiRequestDefaultThresholds) => dt.defaultThresholdId === defaultThresholdId
      )
      const initialThreshold = initialThresholds?.defaultThresholds.find(
        (dt: apiRequestDefaultThresholds) => dt.defaultThresholdId === defaultThresholdId
      )
      newThreshold[field] = value
      setDisabled(isThresholdAtDefaultValues(newThreshold, initialThreshold!))
      return thresholdsCopy
    })
  }

  const validateSteppedValues = (defaults: DefaultThresholds) => {
    let warningMessage = ''
    let value = true

    if (
      (defaults.warningPercentageLevel1 &&
        defaults.warningPercentageLevel1 >= Number(defaults.warningPercentageLevel2)) ||
      (defaults.warningPercentageLevel2 &&
        defaults.warningPercentageLevel2 <= Number(defaults.warningPercentageLevel1))
    ) {
      warningMessage = 'Low % must be less than Medium %'
      value = false
    }
    if (
      (defaults.warningPercentageLevel2 &&
        defaults.warningPercentageLevel2 >= Number(defaults.warningPercentageLevel3)) ||
      (defaults.warningPercentageLevel3 &&
        defaults.warningPercentageLevel3 <= Number(defaults.warningPercentageLevel2))
    ) {
      warningMessage = 'Medium % must be less than High %'
      value = false
    }
    if (value === false) {
      dispatch(showErrorMessage(warningMessage))
      return false
    }
    return true
  }

  const updateDefaults = (defaultThresholdId: number) => {
    const defaultThreshold = thresholds!.defaultThresholds.find(
      (thresh: apiRequestDefaultThresholds) => thresh.defaultThresholdId === defaultThresholdId
    )
    if (validateSteppedValues(defaultThreshold!)) {
      const updateBody = defaultThreshold && {
        defaultThresholdId,
        monHours: defaultThreshold.monHours,
        tueHours: defaultThreshold.tueHours,
        wedHours: defaultThreshold.wedHours,
        thuHours: defaultThreshold.thuHours,
        friHours: defaultThreshold.friHours,
        satHours: defaultThreshold.satHours,
        sunHours: defaultThreshold.sunHours,
        warningPercentageLevel1: defaultThreshold.warningPercentageLevel1,
        warningPercentageLevel2: defaultThreshold.warningPercentageLevel2,
        warningPercentageLevel3: defaultThreshold.warningPercentageLevel3,
      }
      if (updateBody !== undefined) {
        setUpdateLoading(true)
        settingsService
          .putThresholds(updateBody)
          .then(e => {
            setUpdateLoading(false)
            dispatch(showSuccessMessage('Default thresholds updated'))
          })
          .catch(err => {
            setUpdateLoading(false)
            const response: BaseResponse = err.response.data
            response.errors.forEach(error => {
              dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
            })
          })
      }
    }
  }

  const showDeleteModal = (rowToDelete: ThresholdWeeks) => {
    if (rowToDelete.thresholdWeekId !== undefined) {
      setWeekToDelete(rowToDelete.thresholdWeekId)
    }
    dispatch(
      showModalDialog({
        title: 'Are you sure?',
        message: `Are you sure you would like to delete this threshold week beginning ${rowToDelete.dateFrom.toLocaleDateString()}?`,
        buttonLabel: 'Delete',
        type: 'question',
        showModal: true,
      })
    )
  }

  const deleteWeek = () => {
    settingsService
      .deleteThresholdWeek({ thresholdWeekId: weekToDelete })
      .then(e => {
        getData()
        dispatch(showSuccessMessage('Threshold week deleted'))
      })
      .catch(err => {
        const response: BaseResponse = err.response.data
        response.errors.forEach(error => {
          dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
        })
      })
    dispatch(hideModal())
  }

  const weeksData =
    filteredThresholds?.map((week: ThresholdWeeks) => ({
      dateFrom: new Date(week.dateFrom),
      dateTo: new Date(week.dateTo),
      monHours: week.monHours,
      tueHours: week.tueHours,
      wedHours: week.wedHours,
      thuHours: week.thuHours,
      friHours: week.friHours,
      satHours: week.satHours,
      sunHours: week.sunHours,
      departmentTeamId: week.departmentTeamId,
      thresholdWeekId: week.thresholdWeekId,
    })) || []

  const columns: GridColDef[] = [
    {
      field: 'dateFrom',
      headerName: 'Date From',
      type: 'date',
      flex: 1,
    },
    {
      field: 'dateTo',
      headerName: 'Date To',
      type: 'date',
      flex: 1,
    },
    {
      field: 'monHours',
      headerName: 'Mon',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'tueHours',
      headerName: 'Tue',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'wedHours',
      headerName: 'Wed',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'thuHours',
      headerName: 'Thu',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'friHours',
      headerName: 'Fri',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'satHours',
      headerName: 'Sat',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'sunHours',
      headerName: 'Sun',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'remove',
      headerName: 'Remove',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <ActionIcon onClick={e => showDeleteModal(params.row)}>
          <DeleteIcon />
        </ActionIcon>
      ),
      disableColumnMenu: true,
      sortable: false,
      hide: !isUserPermittedToEdit,
    },
  ]

  const closeDrawer = () => {
    setOpenDrawer(false)
  }

  const openAddDrawer = (Id: number) => {
    setDeptTeamId(Id)
    setOpenDrawer(true)
  }

  return (
    <>
      <Modal
        type={type}
        open={showModal}
        onClose={() => {
          dispatch(hideModal())
        }}
        onClick={() => deleteWeek()}
        title={title}
        message={message}
        buttonLabel={buttonLabel}
      />
      <Drawer
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        title="Add Threshold Week"
        showOptions={false}
      >
        <Box sx={{ p: 4 }}>
          <AddThresholdWeek
            onClose={closeDrawer}
            refresh={getData}
            deptTeamId={deptTeamId}
            thresholds={thresholds}
          />
        </Box>
      </Drawer>
      {deptId !== 0 &&
        thresholds?.defaultThresholds.map((threshold: DefaultThresholds) => (
          <AccordionBasic
            title={threshold.teamName}
            onClick={() => {
              setTeamId(
                teamId === threshold.departmentTeamId ? undefined : threshold.departmentTeamId
              )
            }}
            expanded={teamId === threshold.departmentTeamId}
            disableGutters
            key={threshold.defaultThresholdId}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Stack direction="column" gap={2} mt={4}>
                  <Paragraph weight="bold">Warning Percentages</Paragraph>
                  <SettingsThresholdsPercentages
                    defaults={threshold}
                    onChange={handleThresholdChange}
                    disabled={!isUserPermittedToEdit}
                    dataTestId="Threshold"
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={8}>
                <Stack direction="column" gap={2} mt={4}>
                  <Paragraph weight="bold">Team default hours</Paragraph>
                  <SettingsThresholdsTeamDefaultHours
                    defaults={threshold}
                    onChange={handleThresholdChange}
                    disabled={!isUserPermittedToEdit}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {isUserPermittedToEdit ? (
                  <Stack direction="row" justifyContent="flex-end">
                    <Button
                      label="Update defaults"
                      onClick={() => updateDefaults(threshold.defaultThresholdId)}
                      disabled={disabled}
                      loading={updateLoading}
                      dataTestId="Settings-ThresholdUpdateDefaultsBtn"
                    />
                  </Stack>
                ) : (
                  <></>
                )}
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <DataGrid
                  getRowId={row => row.thresholdWeekId || 1}
                  rows={weeksData}
                  autoHeight
                  columns={columns}
                  rowsPerPageOptions={[5, 10, 20]}
                  disableSelectionOnClick
                  pageSize={pageSize}
                  onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                  sx={{ border: 0 }}
                />
              </Grid>
              {isUserPermittedToEdit && (
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="flex-end">
                    <Button
                      label="Add"
                      onClick={() => openAddDrawer(threshold.departmentTeamId)}
                      dataTestId="Settings-ThresholdUpdateAddBtn"
                    />
                  </Stack>
                </Grid>
              )}
            </Grid>
          </AccordionBasic>
        ))}
    </>
  )
}

export default SettingsThresholds
