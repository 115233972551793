import { format } from 'date-fns'
import { Button, Collapse, Grid, IconButton, Stack } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import DownloadIcon from '@mui/icons-material/Download'
import styled from 'styled-components'

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStore, useAppDispatch } from '../../../../redux/store'

import theme from '../../../../theme/theme'
import HolidaysEmployeeRequestSearchForm from './HolidaysEmployeeRequestSearchForm'
import { HolidaysEmployeeRequestCSV, Search } from './types'
import HolidaysEmployeeRequestGrid from './HolidaysEmployeeRequestGrid'
import LoadingIndicator from '../../../../shared/UI/LoadingIndicator'
import { BaseResponse } from '../../../../types/base-response'
import { showErrorMessage } from '../../../../redux/reducers/snackbarReducer'
import UserErrorMessage from '../../../../utils/errorFilter'

import { ssrsHolidayReportService } from '../../../../services/ssrsHolidayReportService'
import {
  HolidaysEmployeeRequestResponse,
  HolidaysEmployeeRequestItem,
} from '../../../../types/holidays-employee-request-response'
import HolidaysEmployeeRequestDetailTab from './HolidaysEmployeeRequestDetailTab'
import { DrawerComponentProps } from '../../types'
import Drawer from '../../../../shared/UI/Drawer'
import {
  HolidayReportsUsersItem,
  HolidayReportsUsersResponse,
} from '../../../../types/holiday-reports-users-response'
import { SelectOption } from '../../../../services/dashboardService'
import RefreshButton from '../../../../shared/UI/RefreshButton'
import DownloadButton from '../../../../shared/UI/DownloadButton'
import FilterButton from '../../../../shared/UI/FilterButton'

export const LoadingIndicatorContainer = styled.div`
  padding-top: 70px;
  padding-bottom: 70px;
`

function HolidaysEmployeeRequestSearch() {
  const dispatch = useAppDispatch()
  const [resetForm, setResetForm] = useState(false)
  const [showHeaderButtons, setShowHeaderButtons] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(true)
  const [showGrid, setShowGrid] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<HolidaysEmployeeRequestItem[]>([])
  const [openDrawer, setOpenDrawer] = useState(false)
  const [employees, setEmployees] = useState<SelectOption[]>([])

  const handleClose = () => {
    setOpenDrawer(false)
  }

  const [drawerComponent, setDrawerComponent] = useState<DrawerComponentProps>({
    title: '',
    component: <HolidaysEmployeeRequestDetailTab data={null} onClose={handleClose} />,
  })

  const { currentEntitlementPeriodResponse } = useSelector((state: RootStore) => state.appSettings)

  const handleSearch = (search: Search) => {
    setIsLoading(true)
    setShowGrid(false)

    setTimeout(() => {
      setShowSearch(false)
    }, 500)

    ssrsHolidayReportService
      .getHolidaysEmployeeRequestReports(
        search.employeeIds!,
        search.allEmployees,
        search.fromDateRangeFrom !== ''
          ? search.fromDateRangeFrom!
          : format(new Date(currentEntitlementPeriodResponse!.startDate), 'yyyy-MM-dd'),
        search.fromDateRangeTo !== ''
          ? search.fromDateRangeTo!
          : format(new Date(currentEntitlementPeriodResponse!.endDate), 'yyyy-MM-dd'),
        search.selectDateRangeFrom,
        search.selectDateRangeTo
      )
      .then((res: HolidaysEmployeeRequestResponse) => {
        let groupId = 0

        const result: HolidaysEmployeeRequestItem[] = res.rows
          .filter(p => p.hours !== 0)
          .map(
            (
              row: HolidaysEmployeeRequestItem,
              index: number,
              arr: HolidaysEmployeeRequestItem[]
            ) => {
              let isLast = false

              if (row.rowNumber === 1) {
                groupId = index
              }

              if (index !== arr.length - 1) {
                isLast = arr[index + 1].rowNumber! === 1
              } else {
                isLast = true
              }

              const item: any = { ...row, index, isLast, groupId }

              return item
            }
          )

        /* eslint-disable no-param-reassign */
        result.forEach((item: HolidaysEmployeeRequestItem) => {
          if (item.rowNumber === 1) {
            const rows: HolidaysEmployeeRequestItem[] = result.filter(
              (p: HolidaysEmployeeRequestItem) => p.groupId === item.groupId
            )

            item.groupInfo = rows.map((p: HolidaysEmployeeRequestItem, index: number) => {
              const info: any = {
                id: index,
                day: p.day,
                hours: p.hours,
              }

              return info
            })
          }
        })

        setData(result)
        setShowHeaderButtons(true)
        setShowGrid(true)
        setIsLoading(false)
      })
      .catch(err => {
        setData([])
        setShowHeaderButtons(true)
        setShowGrid(true)
        setIsLoading(false)

        setTimeout(() => {
          setShowSearch(true)
        }, 500)

        const response: BaseResponse = err.response.data
        if (response.errors) {
          response.errors.forEach(error => {
            dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
          })
        } else {
          dispatch(showErrorMessage(<UserErrorMessage name="CurrentlyUnavailable" />))
        }
      })
  }

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowSearch(!showSearch)
  }

  const handleRefreshClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setResetForm(!resetForm)

    setShowSearch(true)
    setShowHeaderButtons(false)
    setShowGrid(false)
  }

  const handleViewDetail = (row: any) => {
    setDrawerComponent({
      ...drawerComponent,
      title: `${row.displayName}`,
      component: <HolidaysEmployeeRequestDetailTab data={row} onClose={handleClose} />,
    })

    setOpenDrawer(true)
  }

  const makeCsv = async (filename: string) => {
    const headers: string[] = [
      'DisplayName',
      'JobTitle',
      'Department',
      'Team',
      'RequestType',
      'RequestDates',
      'RequestStatus',
      'RequesterComments',
      'DateSubmitted',
      'RequestedDays',
      'RequestedHours',
    ]

    const csvData: HolidaysEmployeeRequestCSV[] = data.map((row: HolidaysEmployeeRequestItem) => {
      const item: HolidaysEmployeeRequestCSV = {
        DisplayName: row.rowNumber === 1 ? row.displayName : '',
        JobTitle: row.rowNumber === 1 ? row.jobTitle : '',
        Department: row.rowNumber === 1 ? row.department : '',
        Team: row.rowNumber === 1 ? row.team : '',
        RequestType: row.rowNumber === 1 ? row.requestType : '',
        RequestDates: row.rowNumber === 1 ? row.requestDates : '',
        RequestStatus: row.rowNumber === 1 ? row.requestStatus : '',
        RequesterComments: row.rowNumber === 1 ? row.requesterComments : '',
        DateSubmitted: row.rowNumber === 1 ? row.submitted : '',
        RequestedDays: row.day,
        RequestedHours: row.hours,
      }
      return item
    })

    const keys: string[] = Object.keys(csvData[0])

    const csvContent: string = csvData
      .map((row: any) =>
        keys
          .map(k => {
            let cell = row[k] === null || row[k] === undefined ? '' : row[k]

            cell =
              cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""')

            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`
            }
            return cell
          })
          .join(',')
      )
      .join('\n')

    const csvHeaders: string = headers.join(',')

    const blob = new Blob([`${csvHeaders}\n`, csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')

    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const handleDownloadClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    makeCsv('HolidaysEmployeeRequest.csv')
  }

  useEffect(() => {
    if (employees.length === 0) {
      ssrsHolidayReportService
        .getHolidayReportsUsers()
        .then((res: HolidayReportsUsersResponse) => {
          const employeesList: SelectOption[] = res.rows.map((p: HolidayReportsUsersItem) => {
            const item: SelectOption = {
              value: p.employeeId!,
              displayValue: p.displayName!,
            }
            return item
          })

          setEmployees(employeesList)
        })
        .catch(err => {
          const response: BaseResponse = err.response.data
          if (response.errors) {
            response.errors.forEach(error => {
              dispatch(showErrorMessage(<UserErrorMessage name={error.name} />))
            })
          } else {
            dispatch(showErrorMessage(<UserErrorMessage name="CurrentlyUnavailable" />))
          }
        })
    }
  }, [])

  return (
    <>
      {showHeaderButtons && (
        <Grid container style={{ border: '0px red solid' }}>
          <Stack
            direction="row"
            style={{ border: '0px green solid', width: '100%' }}
            spacing={2}
            display="flex"
            justifyContent="flex-end"
          >
            <FilterButton onClick={handleFilterClick} title="Change Filters" />
            {data && data.length >= 1 && (
              <DownloadButton onClick={handleDownloadClick} title="Download Report" />
            )}
            <RefreshButton onClick={handleRefreshClick} title="Refresh" />
          </Stack>
        </Grid>
      )}
      <Collapse orientation="vertical" in={showSearch}>
        <HolidaysEmployeeRequestSearchForm
          resetForm={resetForm}
          employees={employees}
          onSearch={handleSearch}
        />
      </Collapse>
      <Grid item xs={12}>
        {!isLoading && showGrid && (
          <HolidaysEmployeeRequestGrid data={data} onViewDetail={handleViewDetail} />
        )}
        {isLoading && (
          <LoadingIndicatorContainer>
            <LoadingIndicator show={isLoading} />
          </LoadingIndicatorContainer>
        )}
      </Grid>
      <Drawer
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        title={drawerComponent?.title || ''}
        showOptions={false}
      >
        {drawerComponent?.component}
      </Drawer>
    </>
  )
}

export default HolidaysEmployeeRequestSearch
