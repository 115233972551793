import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import styled from 'styled-components'

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F4F5F7',
    color: '#000000',
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}))

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& th:first-child': {
    borderTopLeftRadius: '8px',
  },
  '& th:last-child': {
    borderTopRightRadius: '8px',
  },
}))
