import { RequestParams } from '../../models/enhancement'
import {
  CalendarDetailsResponse,
  CurrentEntitlementPeriodResponse,
  SelectOption,
} from '../../services/dashboardService'
import { FlattenedRequest } from '../../types/flattened-requests'
import { enhancementTypes } from '../../utils/constants'
import { CheckboxFilterSettings, SelectionState } from '../MyActions/MyActionsFilter/Types'

const getDateFrom = (currentEntitlementPeriodResponse: CurrentEntitlementPeriodResponse): Date =>
  new Date(
    new Date(currentEntitlementPeriodResponse.startDate).getFullYear(),
    new Date(currentEntitlementPeriodResponse.startDate).getMonth(),
    new Date(currentEntitlementPeriodResponse.startDate).getDate()
  )

const getDateTo = (calendarDetailsResponse: CalendarDetailsResponse): Date =>
  new Date(
    new Date(
      calendarDetailsResponse.calendarDetails[
        calendarDetailsResponse.calendarDetails.length - 1
      ].endDate
    ).getFullYear(),
    new Date(
      calendarDetailsResponse.calendarDetails[
        calendarDetailsResponse.calendarDetails.length - 1
      ].endDate
    ).getMonth(),
    new Date(
      calendarDetailsResponse.calendarDetails[
        calendarDetailsResponse.calendarDetails.length - 1
      ].endDate
    ).getDate()
  )

export const getInitialRequestParams = (
  calendarDetailsResponse?: CalendarDetailsResponse,
  selectedDepartmentFilter?: SelectOption | null,
  currentEntitlementPeriodResponse?: CurrentEntitlementPeriodResponse
): RequestParams | undefined => {
  if (!currentEntitlementPeriodResponse || !calendarDetailsResponse) return
  return {
    directReportees: true,
    dateFrom: getDateFrom(currentEntitlementPeriodResponse),
    dateTo: getDateTo(calendarDetailsResponse),
    departmentId: selectedDepartmentFilter?.value,
    departmentTeamId: undefined,
    requestStatusId: 97,
  }
}

export const getInitialSelectionStates = (
  calendarDetailsResponse?: CalendarDetailsResponse,
  selectedDepartmentFilter?: SelectOption | null,
  currentEntitlementPeriodResponse?: CurrentEntitlementPeriodResponse
): SelectionState | undefined => {
  if (!currentEntitlementPeriodResponse || !calendarDetailsResponse) return
  return {
    team: null,
    status: 97,
    department: selectedDepartmentFilter,
    dateRange: [null, null],
    directReportees: true,
  }
}

const getCount = (absenceArray: FlattenedRequest[], absenceType?: string) =>
  absenceArray?.filter((absence: FlattenedRequest) => absence.requestType === absenceType).length

export const addCountsToFilterSettings = (
  absenceArray: FlattenedRequest[],
  settings: CheckboxFilterSettings[]
) => {
  settings.forEach(setting => {
    const count = getCount(absenceArray, setting.type)
    // eslint-disable-next-line no-param-reassign
    setting.count = count
    // eslint-disable-next-line no-param-reassign
    if (count === 0) setting.checked = false
  })
}

export const getInitialCheckboxSettings = (
  enhancementsSettingOn?: boolean
): CheckboxFilterSettings[] => {
  let settings: CheckboxFilterSettings[] = [
    { type: 'Holiday', displayName: 'Holidays' },
    { type: 'Absence', displayName: 'Absences' },
    { type: 'Late', displayName: 'Late' },
    { type: 'Day Off', displayName: 'Days off' },
    { type: 'Work From Home', displayName: 'WFH' },
    { type: 'Lieu', displayName: 'Lieu' },
    { type: 'Shift', displayName: 'Shift' },
    { type: 'Birthday', displayName: 'Birthday' },
    { type: 'Sell', displayName: 'Sell' },
    { type: 'Buy', displayName: 'Buy' },
    { type: 'Adjustment', displayName: 'Adjustments' },
    { type: 'Holiday (M)', displayName: 'Holiday (M)' },
    { type: 'Lieu (M)', displayName: 'Lieu (M)' },
    { type: 'Course', displayName: 'Course' },
    { type: 'Paternity', displayName: 'Paternity' },
    { type: 'Maternity', displayName: 'Maternity' },
    { type: 'Twilight Shift Cover', displayName: 'Twilight Shift Cover' },
    { type: 'Night Shift Cover', displayName: 'Night Shift Cover' },
    { type: 'Other', displayName: 'Other' },
  ]

  if (enhancementsSettingOn) {
    const enhancementsRequestFilters: CheckboxFilterSettings[] = [
      { type: enhancementTypes.overtime, displayName: 'Overtime' },
      { type: enhancementTypes.night, displayName: 'Nights' },
      { type: enhancementTypes.onCall, displayName: 'On call' },
      { type: enhancementTypes.callOut, displayName: 'Call outs' },
    ]
    settings = [...settings, ...enhancementsRequestFilters]
  }

  settings.forEach(setting => {
    // eslint-disable-next-line no-param-reassign
    setting.checked = false
  })

  return settings
}

export const filter = (
  mobile: boolean,
  requestFilterSettings: CheckboxFilterSettings[],
  absenceArray: FlattenedRequest[],
  requests?: FlattenedRequest[]
): FlattenedRequest[] => {
  const arrayToUse = requests || absenceArray
  let result

  if (mobile) {
    result = arrayToUse?.filter((absence: FlattenedRequest) => absence.requestType === 'Holiday')
  } else {
    const requestTypes: string[] = []

    requestFilterSettings.forEach(setting => {
      if (setting.checked) requestTypes.push(setting.type)
    })

    if (requestTypes.length === 0) {
      result = arrayToUse
    } else {
      result = arrayToUse?.filter((absence: FlattenedRequest) =>
        absence.requestType ? requestTypes.includes(absence.requestType) : false
      )
    }
  }
  return result
}
