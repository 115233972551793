import { useDispatch } from 'react-redux'
import { applyMiddleware, createStore, Store } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

export const store: Store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export type RootStore = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export default store
