import { styled } from '@mui/material/styles'
import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButton as MuiToggleButton,
} from '@mui/material'
import { CustomToggleButtonProps, CustomToggleButtonGroupProps } from './types'

export const CustomToggleButtonGroup = styled(MuiToggleButtonGroup, {
  shouldForwardProp: prop => prop !== 'bgColor' && prop !== 'minWidth',
})<CustomToggleButtonGroupProps>(({ bgColor = 'unset', minWidth = '50px' }) => ({
  ...{
    '&.MuiToggleButtonGroup-root': {
      backgroundColor: bgColor,
      borderRadius: '8px',
      height: '28px',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    '&.MuiToggleButtonGroup-root button': {
      minWidth,
    },
    '&.MuiToggleButtonGroup-root button:nth-of-type(1n)': {
      borderRight: '2px solid #ffffffa1',
    },
    '&.MuiToggleButtonGroup-root button:last-child': {
      borderRight: 'unset',
    },
  },
})) as React.ComponentType<CustomToggleButtonGroupProps>

export const CustomToggleButton = styled(MuiToggleButton, {
  shouldForwardProp: prop =>
    prop !== 'bgColor' && prop !== 'fixedColor' && prop !== 'width' && prop !== 'inactive',
})<CustomToggleButtonProps>(({ bgColor, fixedColor = '#91e3d0', width, inactive }) => ({
  ...{
    '&.MuiButtonBase-root': {
      backgroundColor: inactive ? '#29A78A' : bgColor,
      border: 'none',
      padding: '5px 0',
      color: inactive ? '#91e3d0 !important' : fixedColor,
      height: inactive ? '28px' : '',
      marginTop: inactive ? '0' : '',
      width,
      textTransform: 'capitalize !important',
    },
    '&.MuiButtonBase-root p': {
      color: inactive ? '#91E3D0 !important' : fixedColor,
    },
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: 'unset',
    },
  },
  ...(fixedColor && {
    '&.MuiToggleButtonGroup-root': {
      color: { fixedColor },
    },
  }),
})) as React.ComponentType<CustomToggleButtonProps>
