import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import WaterfallChartOutlinedIcon from '@mui/icons-material/WaterfallChartOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import LogoDevOutlinedIcon from '@mui/icons-material/LogoDevOutlined'
import WebStoriesOutlinedIcon from '@mui/icons-material/WebStoriesOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import DeskIcon from '@mui/icons-material/Desk'
import AddTaskIcon from '@mui/icons-material/AddTask'
import { StyledIcon } from '../shared/UI/NavIconButton'

export interface PlannerRoute {
  title: string
  activeIcon: JSX.Element | null
  href: string
  show: boolean
  hasAccess: boolean
  isDropDown: boolean
  subItem?: PlannerRoute[]
  isOnMobile: boolean
}

export const plannerRoutes: PlannerRoute[] = [
  {
    title: 'Dashboard',
    activeIcon: (
      <StyledIcon disableRipple>
        <DashboardOutlinedIcon data-testid="Dashboard" />
      </StyledIcon>
    ),
    href: '/dashboard',
    show: true,
    hasAccess: false,
    isDropDown: false,
    isOnMobile: true,
  },
  {
    title: 'My Availability',
    activeIcon: (
      <StyledIcon disableRipple>
        <EventOutlinedIcon data-testid="MyAvailability" />
      </StyledIcon>
    ),
    href: '/myavailability',
    show: true,
    hasAccess: false,
    isDropDown: false,
    isOnMobile: true,
  },
  {
    title: 'My Extras',
    activeIcon: (
      <StyledIcon disableRipple>
        <PersonAddAltOutlinedIcon data-testid="MyExtras" />
      </StyledIcon>
    ),
    href: '/myextras',
    show: true,
    hasAccess: false,
    isDropDown: false,
    isOnMobile: true,
  },
  {
    title: 'Wall Chart',
    activeIcon: (
      <StyledIcon disableRipple>
        <WaterfallChartOutlinedIcon
          style={{ transform: 'rotate(-90deg)' }}
          data-testid="WallChart"
        />
      </StyledIcon>
    ),
    href: '/wallchart',
    show: true,
    hasAccess: false,
    isDropDown: false,
    isOnMobile: false,
  },
  {
    title: 'Desk Booking',
    activeIcon: (
      <StyledIcon disableRipple>
        <DeskIcon data-testid="Desk Booking" />
      </StyledIcon>
    ),
    href: '/booking',
    show: true,
    hasAccess: true,
    isDropDown: false,
    isOnMobile: false,
  },
  {
    title: 'My Actions',
    activeIcon: (
      <StyledIcon disableRipple>
        <ManageAccountsOutlinedIcon data-testid="MyActions" />
      </StyledIcon>
    ),
    href: '/myactions',
    show: true,
    hasAccess: false,
    isDropDown: false,
    isOnMobile: true,
  },
  {
    title: 'Enhancements',
    activeIcon: (
      <StyledIcon disableRipple>
        <AddTaskIcon data-testid="Enhancements" />
      </StyledIcon>
    ),
    href: '/enhancements',
    show: true,
    hasAccess: false,
    isDropDown: false,
    isOnMobile: true,
  },
  {
    title: 'Reporting',
    activeIcon: (
      <StyledIcon disableRipple>
        <QueryStatsIcon data-testid="Reporting" />
      </StyledIcon>
    ),
    href: '/reporting',
    show: true,
    hasAccess: false,
    isDropDown: true,
    isOnMobile: false,
    subItem: [
      {
        title: 'Absence',
        activeIcon: null,
        href: '/absenceandlate',
        show: true,
        hasAccess: false,
        isDropDown: false,
        isOnMobile: false,
      },
      {
        title: 'Holiday',
        activeIcon: null,
        href: '/holiday',
        show: true,
        hasAccess: false,
        isDropDown: false,
        isOnMobile: false,
      },
    ],
  },
  {
    title: 'Settings',
    activeIcon: (
      <StyledIcon>
        <SettingsOutlinedIcon data-testid="Settings" />
      </StyledIcon>
    ),
    href: '/settings',
    show: true,
    hasAccess: false,
    isDropDown: false,
    isOnMobile: false,
  },
  {
    title: 'BSS Admin',
    activeIcon: (
      <StyledIcon disableRipple>
        <LogoDevOutlinedIcon />
      </StyledIcon>
    ),
    href: '/bssadmin',
    show: true,
    hasAccess: true,
    isDropDown: true,
    isOnMobile: false,
    subItem: [
      {
        title: 'Feature Toggles',
        activeIcon: null,
        href: '/bssadmin/featuretoggles',
        show: true,
        hasAccess: false,
        isDropDown: false,
        isOnMobile: false,
      },
      {
        title: 'Notification Logs',
        activeIcon: null,
        href: '/bssadmin/notificationlogs',
        show: true,
        hasAccess: false,
        isDropDown: false,
        isOnMobile: false,
      },
    ],
  },
]
