import { useMediaQuery, Stack } from '@mui/material'
import { GridFooterContainer, GridFooter } from '@mui/x-data-grid'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { setActiveRoute } from '../../../redux/reducers/appSettingsReducer'
import { StyledLink } from '../../../shared/UI/Link/components'
import { isMobileDown } from '../../../theme/deviceChecks'
import theme from '../../../theme/theme'

export function CustomFooterStatus() {
  const mobile = useMediaQuery(isMobileDown())
  const dispatch = useDispatch()
  return (
    <GridFooterContainer>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '100%' }}
      >
        <>
          {!mobile && (
            <Link
              to="/wallchart"
              onClick={() => {
                dispatch(setActiveRoute('/wallchart'))
              }}
              style={{ textDecoration: 'none', color: theme.palette.primary.main }}
            >
              <StyledLink data-testid="dashboard_away_link">View Wall Chart</StyledLink>
            </Link>
          )}
        </>
        <GridFooter />
      </Stack>
    </GridFooterContainer>
  )
}
