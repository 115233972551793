import { useRef } from 'react'
import { DeskProps } from '../../Desks/types'
import { DeskStatusColor } from '../../Desks/enums'
import { Group } from '../../Desks/components'
import { BookingDeskPortPosition } from '../../Shared/enums'

function DesignerDesk({ featureDetail }: DeskProps) {
  const objectRef = useRef<SVGGElement | null>(null)

  const STATUS_AVAILABLE = 1
  const STATUS_UNAVAILABLE = 2

  // NOTE: No provision has yet been made for more than 1 port
  const PORT_BORDER_RADIUS = featureDetail.ports?.length
    ? Number(featureDetail.ports?.[0].radius) * 1.5
    : 0

  const calculateAccessPosition = (): { x: number; y: number } => {
    if (PORT_BORDER_RADIUS === 0) {
      return { x: 0, y: 0 }
    }
    switch (Number(featureDetail.ports?.[0].x)) {
      case BookingDeskPortPosition.TOP:
        return { x: featureDetail.x + featureDetail.width / 2, y: featureDetail.y }
      case BookingDeskPortPosition.RIGHT:
        return {
          x: featureDetail.x + featureDetail.width,
          y: featureDetail.y + featureDetail.height / 2,
        }
      case BookingDeskPortPosition.BOTTOM:
        return {
          x: featureDetail.x + featureDetail.width / 2,
          y: featureDetail.y + featureDetail.height,
        }
      case BookingDeskPortPosition.LEFT:
      default:
        return { x: featureDetail.x, y: featureDetail.y + featureDetail.height / 2 }
    }
  }

  const position = calculateAccessPosition()

  // TODO: Will eventually be handled by feature grouping
  const establishGrouping = (): { row: string; label: string } => {
    try {
      return JSON.parse(featureDetail.label)
    } catch (err) {
      return { row: '', label: '' }
    }
  }

  const label = establishGrouping()

  return (
    <Group ref={objectRef} highlightColor="">
      <rect
        y={featureDetail.y}
        x={featureDetail.x}
        width={featureDetail.width}
        height={featureDetail.height}
        rx={featureDetail.borderRadius}
        fill={DeskStatusColor.ACTIVE}
      />
      {label.label === 'A' && (
        <text
          x={featureDetail.x + 10}
          y={featureDetail.y - 5}
          fill="Grey"
          fontFamily="Poppins"
          fontSize="22px"
          fontWeight="bold"
        >
          {label.row}
        </text>
      )}
      {Boolean(featureDetail.ports?.length) && (
        <g>
          <circle cx={position.x} cy={position.y} r={PORT_BORDER_RADIUS} fill="white" />
          <circle cx={position.x} cy={position.y} r={PORT_BORDER_RADIUS - 6} fill="white" />
          <circle
            cx={position.x}
            cy={position.y}
            r={featureDetail.ports?.[0].radius}
            fill={featureDetail.ports?.[0].fill}
          />
        </g>
      )}
    </Group>
  )
}

export default DesignerDesk
