import styled from 'styled-components'
import { IconButton } from '@mui/material'

export const StyledIcon = styled(IconButton)`
  && {
    height: 32px;
    width: 32px;
    font-size: 13pt;
    margin-left: 0.5rem;
    z-index: 1;
    transition: all 0.3s ease;
  }

  &.MuiIconButton-root:hover {
    background-color: unset;
  }
`
