import styled from 'styled-components'
import { Box, Typography } from '@mui/material'
import { isDesktopUp, isMobileDown } from '../../theme/deviceChecks'
import theme from '../../theme/theme'

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
  user-select: none;
`

export const PlottingCalendarContainer = styled.div`
  &.plottingCalendar {
    font-family: 'Poppins';
    font-size: 13px;
    height: 100%;
    box-sizing: border-box;
    justify-content: space-between;
  }

  ${isMobileDown()} {
    &.plottingCalendar,
    &.plottingCalendar button {
      font-size: 1.5vw;
    }
  }

  ${theme.breakpoints.between('md', 'xl')} {
    &.plottingCalendar,
    &.plottingCalendar button {
      font-size: 0.8vw;
    }
    .attendanceCalendarStyles &.plottingCalendar,
    .attendanceCalendarStyles &.plottingCalendar button {
      font-size: 0.9vw;
    }
  }

  ${isDesktopUp()} {
    &.plottingCalendar,
    &.plottingCalendar button {
      font-size: 0.68vw;
    }
    .attendanceCalendarStyles &.plottingCalendar,
    .attendanceCalendarStyles &.plottingCalendar button {
      font-size: 0.6vw;
    }
  }

  .comingUpStyles &.plottingCalendar,
  .comingUpStyles &.plottingCalendar button {
    font-size: 13px;
  }
`

export const PlottingCalendarMonth = styled.div`
  &.plottingCalendar_month {
    position: relative;
    height: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .MuiSvgIcon-root {
    height: 18px;
    width: 14.285%;
    margin-left: 0;
  }
`

export const PlottingCalendarMonthText = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 11.4%;
    margin-top: 2.8%;
    font-size: 13px;
    font-weight: bold;
    color: #000;
  }

  .comingUpStyles &.MuiTypography-root {
    margin: 0;
    font-size: 16px;
  }
`

export const PlottingCalendarDatesContainer = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.plottingCalendar_dates {
    position: absolute;
    bottom: 0;
    left: 7.5%;
    height: 70.9%;
    width: 85%;
    opacoty: 0;
    animation: fadeIn 0.3s ease forwards;
  }
  .comingUpStyles &.plottingCalendar_dates {
    left: 0;
    width: 100%;
  }
`

export const PlottingCalendarDayLetterRow = styled.div`
  &.plottingCalendar_dayLetters {
    position: absolute;
    width: 85%;
    left: 7.5%;
    height: 9.1%;
  }

  .comingUpStyles &.plottingCalendar_dayLetters {
    left: 0;
    width: 100%;
  }
`

export const PlottingCalendarRow = styled.div`
  & {
    display: flex;
    height: 14.5%;
  }
`

export const PlottingCalendarDay = styled.div`
  & {
    display: inline-flex;
    flex-direction: row;
    width: 14.285%;
    justify-content: center;
    color: rgb(112, 112, 112);
  }
`
interface PlottingCalendarDayContainerProps {
  plottingColour?: string
  isPending?: boolean
  isDisabled?: boolean
  showInCalendar?: boolean
  isBankHoliday?: boolean
  isToday?: boolean
}

const defaultPseudoStyles = `    
  content: "";
  position: absolute;
  font-size: 0px;
`

const XYCenter = `
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`

const comingUpHeight = `height: 28px;`

const comingUpWidth = `width: 28px;`

const comingUpDimensions = `
  ${comingUpHeight}
  ${comingUpWidth}
`

export const PlottingCalendarDayContainerDiv = styled.div<PlottingCalendarDayContainerProps>`
  & {
    flex-direction: row;
    width: 14.285%;
    text-align: center;
    position: relative;
  }

  ${props =>
    props.plottingColour &&
    `
      & button {
        color: #fff;
      }
      
      &::before {
        ${defaultPseudoStyles}
        height: 80%;
        transform: translateY(-50%);
        top: 50%;
        left: 0;
        width: 100%;
        background-color: ${props.plottingColour};
        color: #fff;
      }

      .comingUpStyles &::before {
        ${comingUpHeight}
      }

      &.isEventStart::before {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }

      .comingUpStyles &.isEventStart::before {
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
      }

      &.isEventEnd::before {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }

      .comingUpStyles &.isEventEnd::before {
        border-top-right-radius: 14px;
        border-bottom-right-radius: 14px;
      }

      &.isEventStart:not(.isEventEnd)::before {
        width: 90%;
        right: 0;
        left: unset;
      }

      .comingUpStyles &.isEventStart:not(.isEventEnd)::before {
        width: calc(50% + 14px);
      }

      &.isEventEnd:not(.isEventStart)::before {
        width: 90%;
        left: 0;
        right: unset;
      }

      .comingUpStyles &.isEventEnd:not(.isEventStart)::before {
        width: calc(50% + 14px);
      }

      &.isEventEnd.isEventStart::before {
        width: 80%;
        left: 10%;
        right: 10%;
      }
      
      .comingUpStyles &.isEventEnd.isEventStart::before {
        ${comingUpWidth}
        ${XYCenter}
        right: unset;
      }
      
      &:hover button {
        font-weight: bold;
      }
    `}

  ${props =>
    props.isBankHoliday &&
    props.showInCalendar &&
    ` 
      &::before {
        ${defaultPseudoStyles}
        ${XYCenter}
        height: 80%;
        width: 80%;
        background-color: transparent;
        border-radius: 100%;
        border: 1px solid rgb(112, 112, 112);
        box-sizing: border-box;
      }

      .comingUpStyles &::before {
        ${comingUpDimensions}
      }
    `}

    
  ${props =>
    props.isToday &&
    props.showInCalendar &&
    ` 
      &::before {
        ${defaultPseudoStyles}
        ${XYCenter}
        height: 80%;
        width: 80%;
        background-color: transparent;
        border-radius: 100%;
        border: 1px solid rgba(32, 197, 160, 0.3);
        background-color: rgba(32, 197, 160, 0.3);
        box-sizing: border-box;
      }

      .comingUpStyles &::before {
        ${comingUpDimensions}
      }
      
    `}

  ${props =>
    props.isPending &&
    `
      &::before {
        background-image: linear-gradient(135deg, transparent  37.50%, rgb(68 68 68 / 30%) 37.50%, rgb(68 68 68 / 30%) 50%, transparent  50%, transparent  87.50%, rgb(68 68 68 / 30%) 87.50%, rgb(68 68 68 / 30%) 100%);
        background-size: 10px 10px;
      }
    `}

  ${props =>
    !props.isDisabled &&
    !props.plottingColour &&
    props.showInCalendar &&
    `
      .MuiPaper-root:not(.attendanceCalendarStyles) &:hover::after {
        ${defaultPseudoStyles}
        ${XYCenter}
        height: 80%;
        width: 80%;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 100%;
      }

      .comingUpStyles.MuiPaper-root &:hover::after {
        ${comingUpDimensions}
      }
    `}
`

interface PlottingCalendarButtonProps {
  isPlotted?: boolean
}

export const PlottingCalendarButton = styled.button<PlottingCalendarButtonProps>`
  & {
    position: relative;
    font-family: 'Poppins';
    border: none;
    background-color: transparent;
    height: 100%;
    width: 100%;
    line-height: 0;
    cursor: pointer;
    z-index: 1;
    color: rgb(112, 112, 112);
    padding: 0;
  }

  .attendanceCalendarStyles & {
    ${props => `cursor: ${props.isPlotted ? 'pointer' : 'auto'};`}
  }

  &:disabled {
    color: #d1d1d1;
    cursor: auto;
  }
`
