import {Grid} from '@mui/material'
import React from 'react'
import {useDispatch, useSelector, batch} from 'react-redux'
import {useEffect, useState, useMemo} from 'react'
import { Box, Fade } from '@mui/material'
import {LoopOutlined as LoopOutlinedIcon} from '@mui/icons-material'
import {RootStore} from '../../../redux/store'
import CardTitle from '../../../shared/UI/CardTitle'
import {BookingFloorPlan, BookingLocation, BookingZone} from '../../../services/booking/types'
import DropdownMenu from '../../../shared/UI/DropdownMenu'
import Button from '../../../shared/UI/Button'
import Paragraph from '../../../shared/UI/Paragraph'
import LoadingIndicator from '../../../shared/UI/LoadingIndicator'
import {
    setBookingFloorplanViewingDate,
    setDeskBookingFocussedZoneID,
    setDeskBookingLoading,
} from '../../../redux/reducers/deskBookingReducer'
import {bookingService} from '../../../services/booking/bookingService'
import {BaseResponse, ApiError} from '../../../types/base-response'
import {showErrorMessage} from '../../../redux/reducers/snackbarReducer'
import BookingErrorMessage from '../utils/BookingErrorMessage'
import { getJson } from '../utils/utils'
import { FIRST_FLOORPLAN_IN_ARRAY } from '../consts'
import { defaultFloorplanIdIfNoneSelected, defaultZoneIdIfNoneSelected } from '../utils'
import {BookingSearchType} from './enums'
import {BookingWizardSteps} from '../BlockBooking/enums'
import {BookinResetSearch, FloorplanView} from './components'
import {
    setDeskBookingSearchParams,
    setDeskBookingSearchResults,
} from '../../../redux/reducers/deskBookingSearchReducer'
import {
  handleSearch,
  handleServerError,
} from '../bookingLogic'


interface FormFieldsSelectedCheck {
  isLocationSelected: boolean
  isFloorplanSelected: boolean
  isZoneSelected: boolean
}

export default function BookingSearch() {

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [selectedLocation, setSelectedLocation] = useState<BookingLocation>()
  const [selectedFloorplan, setSelectedFloorplan] = useState<BookingFloorPlan>()
  const [selectedZone, setSelectedZone] = useState<BookingZone>()

  const dispatch = useDispatch()

  const handleSearchError = (err: string) => {
    dispatch(showErrorMessage(<BookingErrorMessage name={err} />))
  }

  const {
    locations,
    floorplans,
    zones,
    featuresForFloorPlan,
    showGridView,
    bookingIsLoading,
    focussedZoneID,
  } = useSelector((state: RootStore) => state.deskBooking)
  const { searchResults, searchParams, searchRecentParams } = useSelector(
    (state: RootStore) => state.deskBookingSearch
  )

  const { currentStep: wizardCurrentStep, weekdaysSelected: wizardWeekdaysSelected } = useSelector(
    (state: RootStore) => state.deskBookingWizard
  )

  const { employeeDetails } = useSelector((state: RootStore) => state.appSettings)
  const userInfo = useSelector((state: RootStore) => state.userState.loggedInUser)

  const searchType:BookingSearchType = BookingSearchType.SINGLE

  const zonesForDropdown = () => {
    if (!zones || !selectedFloorplan) {
      return []
    }
    return zones
      .filter(f => f.floorPlanId === selectedFloorplan?.id)
      .filter(m => {
        const json = getJson(m.additionalInfo)
        return !json?.ui || json?.ui?.showInDropdown === true
      })
  }

  const employeeFloorplans =
  useMemo(() =>
    floorplans.filter(f =>
      f.locationId === employeeDetails.location.id) ||
      floorplans[FIRST_FLOORPLAN_IN_ARRAY]
  , [employeeDetails.location.id, floorplans])

const employeeZones =
  useMemo(() =>
    zones.filter(z =>
      employeeFloorplans.some(s => s.id === z.floorPlanId) &&
      z.belongsTo === employeeDetails.departmentId
    )
  , [employeeDetails.departmentId, employeeFloorplans, zones])



  const [formFieldsSelectedCheck, setFormFieldsSelectedCheck] = useState<FormFieldsSelectedCheck>({
    isLocationSelected: false,
    isFloorplanSelected: false,
    isZoneSelected: false,
  })

  useEffect(() => {

    const isLocationSelected = selectedLocation !== undefined && selectedLocation !== null
    const isFloorplanSelected = selectedFloorplan !== undefined && selectedFloorplan !== null
    const isZoneSelected = selectedZone !== undefined && selectedZone !== null

    setFormFieldsSelectedCheck({
      isLocationSelected,
      isFloorplanSelected,
      isZoneSelected
    })

  }, [selectedLocation, selectedFloorplan, selectedZone])


  const resetFilterFloorplan = async () => {


    if (!selectedLocation || !selectedFloorplan || !searchParams) {

      return
    }

    const selectedData = {
      selectedFromTo: {
        from: searchParams.from,
        to: searchParams.to,
      },
      searchType
    }

    const zoneId: number = (employeeZones[0] || defaultZoneIdIfNoneSelected(employeeZones)).id

    dispatch(setDeskBookingFocussedZoneID(defaultZoneIdIfNoneSelected(employeeZones)))

    dispatch(
      setDeskBookingSearchParams({
        locationId: employeeDetails.location.id,
        floorplanId: defaultFloorplanIdIfNoneSelected(
          employeeZones,
          employeeFloorplans
        ),
        zoneId,
        date: new Date(),
        from: searchParams?.from || '00:00:00',
        to:  searchParams?.to ||'23:59:59',
        autoSearch: true,
      })
    )

    if (wizardCurrentStep === BookingWizardSteps.STEP_0_INACTIVE) {

      if (!selectedDate) {

        return
      }

      dispatch(setBookingFloorplanViewingDate(selectedDate))

      if (!selectedLocation || !selectedFloorplan) {

        return
      }

      const singleQuery = true
      await handleSearch(
        dispatch,
        handleSearchError,
        singleQuery,
        zones,
        featuresForFloorPlan,
        employeeDetails,
        userInfo,
        selectedData,
        employeeDetails.location.id, 
        defaultFloorplanIdIfNoneSelected(
          employeeZones,
          employeeFloorplans
        ),
        null,
        searchParams.date
      )

    }

  }

  useEffect(() => {

    setSelectedLocation(locations.find(f => f.id === employeeDetails.location.id))
  }, [locations, employeeDetails.location.id])

  useEffect(() => {

    if (!searchRecentParams || !searchResults) {
      return
    }

    bookingService
      .queryRecent(searchRecentParams)
      .then(result => {
        dispatch(
          setDeskBookingSearchResults([
            ...searchResults.filter(f => f.statusId === 2),
            ...result.bookings.filter(f => f.statusId === 2),
          ])
        )

      })
      .catch(err => {
        const response: BaseResponse = handleServerError(err.response.data)
        response.errors.forEach(error => {
          handleSearchError(error.name)
        })
        dispatch(setDeskBookingLoading(false))
      })

  }, [searchRecentParams])

  useEffect(() => {

    if (!searchParams || !zones || !searchResults || !employeeDetails) {
      return
    }

    const populateSearchBoxes = (floorplan: BookingFloorPlan) => batch(() => {

      setSelectedDate(searchParams.date || new Date())
      setSelectedLocation(locations.find(f => f.id === employeeDetails.location.id))
      if (searchParams?.locationId) {
        setSelectedLocation(locations.find(f => f.id === searchParams?.locationId))
      }

      setSelectedFloorplan(floorplan)
      if (searchParams.zoneId) {
        setSelectedZone(zones.find(f => f.id === searchParams.zoneId))
      }
      if (!searchParams.zoneId && focussedZoneID) {
        setSelectedZone(zones.find(f => f.id === focussedZoneID))
      }

    })

    const floorplan = floorplans.find(f => f.id === searchParams?.floorplanId)
    if (!floorplan) {
      return
    }

    populateSearchBoxes(floorplan)

  }, [dispatch, searchParams, zones && floorplans && locations, focussedZoneID])


  const disableFind = () => {

    if (
      wizardCurrentStep !== BookingWizardSteps.STEP_0_INACTIVE &&
      wizardWeekdaysSelected.length === 0
    ) {
      return true
    }

    if (!showGridView && bookingIsLoading) {
      return true
    }

    const {
      isFloorplanSelected,
      isLocationSelected,
      isZoneSelected
    } = formFieldsSelectedCheck

    return !(isLocationSelected && isFloorplanSelected && isZoneSelected)
  }

  const [showFilterLoading, setShowFilterLoading] = useState(false)

  const filterDataNotAvailable = !locations?.length || !floorplans?.length || !zones?.length

  useEffect(() => {

    if (!filterDataNotAvailable) {

      return
    }

    const showLoadingTimeId = setTimeout(() => {

      setShowFilterLoading(true)
    }, 3000)

    return () => {
      clearTimeout(showLoadingTimeId)
    }
  }, [])

  if (filterDataNotAvailable) {

    if (!showFilterLoading) {
      return null
    }

    return (
      <Box display="flex" height="470px" flexDirection="column" justifyContent="top">
        <Box display="flex" alignItems="center" flexDirection="column">
          <LoadingIndicator show alignItems="center" margin="20px" />
          <Paragraph size="16px" weight="bold" color="#2c2965">
            Loading filter data...
          </Paragraph>
        </Box>
      </Box>
    )
  }

  const employeeLocationId = locations.find(l => l.id === employeeDetails.location.id)?.id

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} mb={1} paddingBottom={3}>
          <CardTitle title="Filters" />
        </Grid>
      </Grid>
      <Grid container spacing={2} height="540px" alignContent="flex-start">

        <Grid item xs={12}>
          <DropdownMenu
            label="Location"
            data={locations}
            textField="name"
            valueField="id"
            id="location"
            value={selectedLocation?.id || employeeLocationId ||''}
            onChange={e => {

              if (selectedLocation?.id === Number(e.target.value)) {

                return
              }

              setSelectedLocation(locations.find(f => f.id === (Number(e.target.value) || employeeLocationId)))

              setSelectedFloorplan(undefined)
              setSelectedZone(undefined)

            }}
          />
        </Grid>

        <Grid item xs={12}>
          <DropdownMenu
            label="Floor"
            data={floorplans.filter(f => f.locationId === (selectedLocation?.id || employeeLocationId))}
            textField="name"
            valueField="id"
            id="floorplan"
            value={selectedFloorplan?.id || ''}
            onChange={e => {

              const thisSelectedFloorPlanId = Number(e.target.value)
              if (selectedFloorplan?.id === thisSelectedFloorPlanId) {

                return
              }

              setSelectedFloorplan(floorplans.find(f => f.id === thisSelectedFloorPlanId))

              setSelectedZone(undefined)
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <DropdownMenu
            label="Zone"
            data={zonesForDropdown()}
            textField="name"
            valueField="id"
            disabled={!selectedFloorplan}
            id="zone"
            value={selectedZone?.id || ''}
            onChange={e => {

              if (selectedZone?.id === Number(e.target.value)) {

                return
              }

              const zone = zones.find(f => f.id === Number(e.target.value))
              setSelectedZone(zone)

            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          zIndex: 1000,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            label="Apply"
            disabled={disableFind()}
            fullWidth
            style={{ margin: 0 }}
            onClick={e => batch(async () => {
              if (!selectedLocation || !selectedFloorplan || !searchParams) {

                return
              }

              const selectedData = {
                selectedFromTo: {
                  from: searchParams.from,
                  to: searchParams.to,
                },
                searchType
              }

              dispatch(
                setDeskBookingSearchParams({
                  ...searchParams,
                  locationId: selectedLocation?.id,
                  floorplanId: selectedFloorplan?.id,
                  zoneId: selectedZone?.id
                })
              )

              dispatch(setDeskBookingFocussedZoneID(selectedZone?.id || 0))

              if (wizardCurrentStep === BookingWizardSteps.STEP_0_INACTIVE) {

                if (!selectedDate) {

                  return
                }

                dispatch(setBookingFloorplanViewingDate(selectedDate))

                if (!selectedLocation || !selectedFloorplan) {

                  return
                }

                const singleQuery = true
                await handleSearch(
                  dispatch,
                  handleSearchError,
                  singleQuery,
                  zones,
                  featuresForFloorPlan,
                  employeeDetails,
                  userInfo,
                  selectedData,
                  selectedLocation.id,
                  selectedFloorplan.id,
                  null,
                  searchParams.date
                )

              }
            })}
          />

          <BookinResetSearch id="reset" onClick={() => resetFilterFloorplan()}>
            <LoopOutlinedIcon style={{ width: '18px', marginRight: '5px' }} />
            Reset
          </BookinResetSearch>
        </Grid>
      </Grid>
    </>
  )
}
