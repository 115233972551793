import { BookingWizardSteps } from '../../components/Booking/BlockBooking/enums'
import { BookingBlockComingUp } from '../../components/Booking/BlockBooking/types'
import { BookingWeekdays } from '../../components/Booking/Shared/types'
import { BookingBookingPayload, BookingFeature } from '../../services/booking/types'

export interface BookingBlockBookingFinalSelection {
  id?: number
  date: Date
  dateNumber: number
  active: boolean
  conflicts: BookingBookingPayload[]
  absence?: BookingBlockComingUp
  alternativeFeatureId?: number
}
export interface BookingWizardState {
  currentStep: BookingWizardSteps
  bookingRange: [Date, Date]
  selectedDates: { date: Date; dateNumber: number }[]
  weekdaysSelected: BookingWeekdays[]
  existingBookings: BookingBookingPayload[]
  selectedFeature: (BookingFeature & { hasBookings?: boolean }) | undefined
  finalSelection: BookingBlockBookingFinalSelection[]
  selectedAlternativeFeatureID?: number | undefined
}

export const bookingWizardInitialState: BookingWizardState = {
  currentStep: BookingWizardSteps.STEP_0_INACTIVE,
  bookingRange: [new Date(), new Date()],
  selectedDates: [],
  weekdaysSelected: [],
  existingBookings: [],
  selectedFeature: undefined,
  finalSelection: [],
}

const SET_WIZARD_STEP = 'SET_WIZARD_STEP'
const SET_WIZARD_BOOKING_RANGE = 'SET_WIZARD_BOOKING_RANGE'
const SET_WIZARD_SELECTED_DATES = 'SET_WIZARD_SELECTED_DATES'
const SET_WIZARD_WEEKDAYS_SELECTED = 'SET_WIZARD_WEEKDAYS_SELECTED'
const SET_WIZARD_BOOKINGS = 'SET_WIZARD_BOOKINGS'
const SET_WIZARD_SELECTED_FEATURE = 'SET_WIZARD_SELECTED_FEATURE'
const SET_WIZARD_FINAL_SELECTION = 'SET_WIZARD_FINAL_SELECTION'
const SET_WIZARD_SELECTED_ALTERNATIVE_FEATURE =
  'SET_WISET_WIZARD_SELECTED_ALTERNATIVE_FEATUREZARD_FINAL_SELECTION'

interface SetBookingWizardStepState {
  type: typeof SET_WIZARD_STEP
  payload: BookingWizardSteps
}

interface SetBookingWizardBookingRangeState {
  type: typeof SET_WIZARD_BOOKING_RANGE
  payload: [Date, Date]
}

interface SetBookingWizardSelectedDatesState {
  type: typeof SET_WIZARD_SELECTED_DATES
  payload: { date: Date; dateNumber: number }[]
}

interface SetBookingWizardWeekdaysSelectedState {
  type: typeof SET_WIZARD_WEEKDAYS_SELECTED
  payload: BookingWeekdays[]
}

interface SetBookingWizardBookingsState {
  type: typeof SET_WIZARD_BOOKINGS
  payload: BookingBookingPayload[]
}

interface SetBookingWizardSelectedFeatureState {
  type: typeof SET_WIZARD_SELECTED_FEATURE
  payload: BookingFeature | undefined
}

interface SetBookingWizardFinalSelectionState {
  type: typeof SET_WIZARD_FINAL_SELECTION
  payload: BookingBlockBookingFinalSelection[]
}

interface SetBookingWizardSelectedAlternativeFeatureState {
  type: typeof SET_WIZARD_SELECTED_ALTERNATIVE_FEATURE
  payload: number | undefined
}

type bookingWizardDespatchTypes =
  | SetBookingWizardBookingRangeState
  | SetBookingWizardSelectedDatesState
  | SetBookingWizardStepState
  | SetBookingWizardWeekdaysSelectedState
  | SetBookingWizardBookingsState
  | SetBookingWizardSelectedFeatureState
  | SetBookingWizardFinalSelectionState
  | SetBookingWizardSelectedAlternativeFeatureState

export const setBookingWizardStepState = (step: BookingWizardSteps): SetBookingWizardStepState => ({
  type: SET_WIZARD_STEP,
  payload: step,
})

export const setBookingWizardBookingRangeState = (
  range: [Date, Date]
): SetBookingWizardBookingRangeState => ({
  type: SET_WIZARD_BOOKING_RANGE,
  payload: range,
})

export const setBookingWizardSelectedDatesState = (
  dates: { date: Date; dateNumber: number }[]
): SetBookingWizardSelectedDatesState => ({
  type: SET_WIZARD_SELECTED_DATES,
  payload: dates,
})

export const setBookingWizardWeekdaysSelectedState = (
  weekdays: BookingWeekdays[]
): SetBookingWizardWeekdaysSelectedState => ({
  type: SET_WIZARD_WEEKDAYS_SELECTED,
  payload: weekdays,
})

export const setBookingWizardBookingsState = (
  bookings: BookingBookingPayload[]
): SetBookingWizardBookingsState => ({
  type: SET_WIZARD_BOOKINGS,
  payload: bookings,
})

export const setBookingWizardSelectedFeatureState = (
  feature: (BookingFeature & { hasBookings?: boolean }) | undefined
): SetBookingWizardSelectedFeatureState => ({
  type: SET_WIZARD_SELECTED_FEATURE,
  payload: feature,
})

export const setBookingWizardFinalSelectionState = (
  selections: BookingBlockBookingFinalSelection[]
): SetBookingWizardFinalSelectionState => ({
  type: SET_WIZARD_FINAL_SELECTION,
  payload: selections,
})

export const setBookingWizardSelectedAlternativeFeatureState = (
  featureId: number | undefined
): SetBookingWizardSelectedAlternativeFeatureState => ({
  type: SET_WIZARD_SELECTED_ALTERNATIVE_FEATURE,
  payload: featureId,
})

export const deskBookingWizardReducer = (
  state: BookingWizardState = bookingWizardInitialState,
  action: bookingWizardDespatchTypes
): BookingWizardState => {
  switch (action.type) {
    case SET_WIZARD_STEP:
      return { ...state, currentStep: action.payload }
    case SET_WIZARD_BOOKING_RANGE:
      return { ...state, bookingRange: action.payload }
    case SET_WIZARD_SELECTED_DATES:
      return { ...state, selectedDates: action.payload }
    case SET_WIZARD_WEEKDAYS_SELECTED:
      return { ...state, weekdaysSelected: action.payload }
    case SET_WIZARD_BOOKINGS:
      return { ...state, existingBookings: action.payload }
    case SET_WIZARD_SELECTED_FEATURE:
      return { ...state, selectedFeature: action.payload }
    case SET_WIZARD_FINAL_SELECTION:
      return { ...state, finalSelection: action.payload }
    case SET_WIZARD_SELECTED_ALTERNATIVE_FEATURE:
      return { ...state, selectedAlternativeFeatureID: action.payload }
    default:
      return state
  }
}
