// eslint-disable-next-line no-shadow
export enum SeverityLevels {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error',
}

export type NotificationProps = {
  id: number
  severity: SeverityLevels
  message: string
}
