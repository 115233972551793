import { Grid, useMediaQuery } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ComingUp from './Comingup'
import DashboardMyActions from './DashboardMyActions'
import WhoIsAway from '../WhoIsAway'
import { RootStore } from '../../redux/store'
import { setActiveRoute } from '../../redux/reducers/appSettingsReducer'
import PendingRequests from '../PendingRequests'
import DashboardMySummary from './DashboardMySummary'
import AllowanceChart from '../AllowanceChart'
import FullComponentLoadingIcon from '../../shared/UI/LoadingIndicator/FullComponentLoadingIcon'
import { useEntitlement } from './useEntitlement'
import { useMyPendingRequests } from './useMyPendingRequests'
import MobileContainer from '../../shared/layout/MobileContainer'
import { EmployeeDetailsResponse } from '../../types/employee'
import { isMobilePortrait } from '../../theme/deviceChecks'

function Dashboard() {
  const dispatch = useDispatch()
  const employeeDetails = useSelector<RootStore, EmployeeDetailsResponse | undefined>(
    (state: RootStore) => state.appSettings.employeeDetails
  )

  const isMobPortrait = useMediaQuery(isMobilePortrait())

  const isHTL = useMemo(() => employeeDetails?.isHtl ?? false, [employeeDetails?.isHtl])
  const isContractor = useMemo(
    () => employeeDetails?.isContractor ?? false,
    [employeeDetails?.isContractor]
  )

  // TODO: should refactor this - pull data calls out from each component and create custom hook
  const { myEntitlement, isEntitlementLoading } = useEntitlement()
  const { myPendingRequests, isMyPendingRequestsLoading } = useMyPendingRequests()

  const userPermissions = useSelector<RootStore, string[]>(
    (state: RootStore) => state.userState.permissions
  )

  useEffect(() => {
    dispatch(setActiveRoute('/dashboard'))
  }, [dispatch])

  const isManager = userPermissions.includes('ViewMyActions' && 'ViewWhoAwayManager')

  const isHtlContractor = useMemo(() => isHTL && isContractor, [isContractor, isHTL])

  return (
    <MobileContainer>
      <FullComponentLoadingIcon loading={isHTL === null} bgColor="transparent">
        <Grid container spacing={4}>
          {isHtlContractor ? (
            <Grid item xs={12} md={6} xl={4} display="flex">
              <Grid container spacing={4}>
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <WhoIsAway />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <PendingRequests
                    pendingRequests={myPendingRequests?.requests}
                    isLoading={isMyPendingRequestsLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              {/* Col One */}
              <Grid item xs={12} md={6} xl={4} style={{ display: 'flex' }}>
                <Grid container spacing={4}>
                  <Grid item xs={12} style={{ display: 'flex' }}>
                    <AllowanceChart
                      title="My Holidays"
                      myEntitlement={myEntitlement}
                      isLoadingEntitlement={isEntitlementLoading}
                    />
                  </Grid>
                  {!isMobPortrait && (
                    <Grid item xs={12} style={{ display: 'flex' }}>
                      <PendingRequests
                        pendingRequests={myPendingRequests?.requests}
                        isLoading={isMyPendingRequestsLoading}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {/* Col One End */}
              {/* Col Two */}
              <Grid
                item
                xs={12}
                md={12}
                xl={4}
                order={{ xs: 3, xl: 2 }}
                style={{ display: 'flex' }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6} xl={12} style={{ display: 'flex' }}>
                    <WhoIsAway />
                  </Grid>
                  <Grid item xs={12} md={6} xl={12} style={{ display: 'flex' }}>
                    <FullComponentLoadingIcon
                      loading={!userPermissions.length}
                      loadingBoxShadow
                      removeBorderRadius={isMobPortrait}
                    >
                      <>
                        {isManager ? (
                          <DashboardMyActions />
                        ) : (
                          <DashboardMySummary
                            myEntitlement={myEntitlement}
                            isLoadingEntitlement={isEntitlementLoading}
                          />
                        )}
                      </>
                    </FullComponentLoadingIcon>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {/* Col Two End */}
          {/* Col Three */}
          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            order={{ xs: 1, xl: 3 }}
            style={{ display: 'flex', height: isHtlContractor ? '800px' : 'auto' }}
          >
            <ComingUp />
          </Grid>
          {/* Col Three End */}
          {isMobPortrait && !isHtlContractor && (
            <Grid item xs={12} order={{ xs: 3 }} style={{ display: 'flex' }}>
              <PendingRequests
                pendingRequests={myPendingRequests?.requests}
                isLoading={isMyPendingRequestsLoading}
              />
            </Grid>
          )}
        </Grid>
      </FullComponentLoadingIcon>
    </MobileContainer>
  )
}

export default Dashboard
