import OpenInBrowserOutlinedIcon from '@mui/icons-material/OpenInBrowserOutlined'
import { Link, useMediaQuery } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setActiveRoute } from '../../redux/reducers/appSettingsReducer'
import { useAppDispatch } from '../../redux/store'
import NoResult from '../../shared/UI/NoResult'
import StatusLabel from '../../shared/UI/StatusLabel'
import TypeLabel from '../../shared/UI/TypeLabel'
import theme from '../../theme/theme'
import { AttendanceSearchItem } from '../../types/attendance-search'
import { getLocalDateString } from '../../utils/date-utils'
import { PaginationInfo, ReportingAbsenceAndLateSearchFilters } from '../Search'

export type MySearchGridPropsLocal = {
  rows: AttendanceSearchItem[]
  filtersState?: ReportingAbsenceAndLateSearchFilters | null
  pagination?: PaginationInfo | null
}

function NoRowsOverlay() {
  return <NoResult message="No Results" showImage />
}

function SearchGrid(props: MySearchGridPropsLocal) {
  const dispatch = useAppDispatch()
  const [pageSize, setPageSize] = useState<number>(props.pagination?.pageSize ?? 10)
  const [pageNumber, setPageNumber] = useState<number>(props.pagination?.pageNumber ?? 0)
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()

  const onHandleNavigation = (type: string, id: number) => {
    const stateWithData = !props.filtersState
      ? null
      : ({
          ...props.filtersState,
          localData: props.rows,
          localDataExpiration: new Date(new Date().getTime() + 3 * 60000),
          pagination: { pageNumber, pageSize } as PaginationInfo,
        } as ReportingAbsenceAndLateSearchFilters)

    let path: string
    let route: string

    switch (type.toLocaleLowerCase()) {
      case 'absence':
        path = `/myactions/absenceoccurrence/${id}`
        route = '/myactions'
        break
      case 'late':
        path = `/myactions/lateoccurrence/${id}`
        route = '/myactions'
        break
      default:
        return
    }

    if (stateWithData) {
      navigate(path, { state: stateWithData })
    } else {
      navigate(path)
    }

    dispatch(setActiveRoute(route))
  }

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Name',
      headerClassName: 'header',
      flex: 1,
      renderCell: params => (
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            onHandleNavigation(params.row.absenceType, params.row.attendanceId)
          }}
        >
          {params.row.displayName}
        </Link>
      ),
    },
    {
      field: 'absenceType',
      headerName: 'Type',
      headerClassName: 'header',
      flex: 1,
      renderCell: cellValues => (
        <TypeLabel label={cellValues.row.absenceType} type={cellValues.row.absenceType} />
      ),
    },
    {
      field: 'departmentName',
      headerName: 'Department',
      headerClassName: 'header',
      flex: 1,
    },
    {
      field: 'dateFrom',
      headerName: 'Date From',
      headerClassName: 'header',
      type: 'date',
      flex: 1,
      renderCell: cellValues => getLocalDateString(cellValues.value),
    },
    {
      field: 'reason',
      headerName: 'Reason',
      headerClassName: 'header',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'header',
      flex: 1,
      renderCell: cellValues => <StatusLabel status={cellValues.value} />,
    },
    {
      field: 'icons',
      headerName: '_',
      headerClassName: 'headerEmpty',
      flex: 0.5,
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: params => (
        <IconButton>
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              onHandleNavigation(params.row.absenceType, params.row.attendanceId)
            }}
          >
            <OpenInBrowserOutlinedIcon color="primary" />
          </Link>
        </IconButton>
      ),
    },
  ]

  const mobileColumns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Name',
      headerClassName: 'header',
      flex: 1,
      renderCell: params => (
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            onHandleNavigation(params.row.absenceType, params.row.attendanceId)
          }}
        >
          {params.row.displayName}
        </Link>
      ),
    },
    {
      field: 'absenceType',
      headerName: 'Type',
      headerClassName: 'header',
      flex: 1,
      renderCell: cellValues => (
        <TypeLabel label={cellValues.row.absenceType} type={cellValues.row.absenceType} />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'header',
      flex: 1,
      renderCell: cellValues => <StatusLabel status={cellValues.value} />,
    },
  ]
  return (
    <DataGrid
      rows={props.rows}
      columns={mobile ? mobileColumns : columns}
      getRowId={row => row.attendanceId}
      disableSelectionOnClick
      rowsPerPageOptions={[10, 25, 50, 100]}
      rowHeight={50}
      autoHeight
      components={{ NoRowsOverlay }}
      pageSize={pageSize}
      page={pageNumber}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      onPageChange={newPageNumber => setPageNumber(newPageNumber)}
      initialState={{
        sorting: {
          sortModel: [{ field: 'submitDateTime', sort: 'asc' }],
        },
      }}
      sx={{
        '&.MuiDataGrid-root': {
          border: 'none',
        },
        '.header': {
          backgroundColor: 'rgb(247, 247, 247)',
        },
        '& .MuiDataGrid-virtualScrollerContent': {
          minHeight: '200px!important',
        },
        '.headerEmpty': {
          backgroundColor: 'rgb(247, 247, 247)',
        },
        '.headerEmpty .MuiDataGrid-columnHeaderTitle': {
          color: 'rgb(247, 247, 247)',
        },
      }}
      componentsProps={{
        panel: {
          sx: {
            '& .MuiNativeSelect-select option': {
              font: '13px "Poppins", sans-serif',
            },
          },
        },
      }}
    />
  )
}

export default SearchGrid
