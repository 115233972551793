import { Box } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import Paragraph from '../Paragraph'
import NoResultImg from '../../../assets/noResult.svg'

type Props = {
  message: string
  showImage?: boolean
  top?: number
}

function NoResult({ message, showImage, top = 0 }: Props) {
  return (
    <>
      {showImage ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height={200}
          mt={2}
        >
          <img src={NoResultImg} alt="No Result" height={120} style={{ marginBottom: 16 }} />
          <Paragraph>{message}</Paragraph>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: 'auto', width: '100%', position: 'absolute', top: { top }, bottom: '0px' }}
        >
          <SearchIcon sx={{ marginRight: 1, color: '#e5e5e5' }} />
          <Paragraph color="#c6c6c6">{message}</Paragraph>
        </Box>
      )}
    </>
  )
}

export default NoResult
